import * as c from '../constants'

const initialState = {
  availableBrands: null,
  itemPrices: null,
  xlsx: null,
  isLoading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API_GET_PRODUCT_CATALOG:
      action.payload.availableBrands.splice(0, 0, {
        key: 0,
        value: 'common__pleaseSelect'
      })
      action.payload.availableParameters.splice(0, 0, 'common__all')
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    case c.API_GET_PRODUCT_CATALOG_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case c.API_GET_PRODUCT_CATALOG_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case c.API_EXPORT_XL_PRODUCTCATALOG_REQUEST:
      return {
        ...state,
        xlsx: null
      }
    case c.API_EXPORT_XL_PRODUCTCATALOG_SUCCESS:
      return {
        ...state,
        xlsx: action.payload
      }
    default:
  }
  return state
}
