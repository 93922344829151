////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cloneDeep, filter, get, some, orderBy } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import styles from './styles'
import Dialog from 'material-ui/Dialog'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton'
import PageLoad from '../../components/PageLoad'
import { PageHeader } from '../../components/sharedComponents'
import MyAddressesTable, { searchDomaine, columns } from '../../components/Sematable/Tables/MyAddressesTable'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import { apiGetMyCarriers, apiAddAddress, apiEditAddress } from '../../actions/myCarriers'
import { apiGetCountries } from '../../actions/countries'
/////////ASSETS/////////
import Alert from 'material-ui/svg-icons/alert/warning'

/////////STYLED/////////
const FormsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  width: calc(66% - 10px);
  & > * {
    flex: 1 1 25%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1460px) {
    & > * {
      flex: 1 1 100%;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
  }
`
const FormWrapped = styled.form`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  @media (max-width: 1460px) {
    justify-content: space-between;
  }
`

class MyAddresses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      myAddresses: [],
      searchTermDomain: 'All',
      searchTerm: '',
      openAddressModal: false,
      address: {
        type: '',
        addMode: true,
        readonly: false
      },
      sortBy: null,
      lastSortBy: null,
      sortOrderBy: ['asc']
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    if (this.props.iDSociete) this.props.apiGetMyCarriers({ iDSociete: this.props.iDSociete }) 
    this.props.apiGetCountries()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.iDSociete !== this.props.iDSociete && nextProps.iDSociete) this.props.apiGetMyCarriers({ iDSociete: nextProps.iDSociete })
    if (nextProps.myAddresses !== this.props.myAddresses) {
      const newData = nextProps.myAddresses.map(d => ({ ...d, companyName: nextProps.companyName }))
      this.setState({ myAddresses: newData })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelSortable = index => {
    if (columns[index]) this.setState({ lastSortBy: this.state.sortBy, sortBy: index }, this.handleFilterTableItems)
  }

  handleEditModal = (editRow = null, displayOnly = false) => {
    if (editRow) {
      this.setState({
        address: {
          ...editRow,
          type: editRow.type.toLowerCase() === 'invoice' ? 1 : 2,
          countryCodeAlpha2: editRow.country,
          phoneNumber: editRow.telephone,
          readonly: displayOnly,
          ligne1: editRow.ligne_1,
          ligne2: editRow.ligne_2
        },
        openAddressModal: true
      })
    } else {
      this.setState({
        openAddressModal: true,
        address: {
          addMode: true
        }
      })
    }
  }

  handleCloseAwb = () => {
    this.setState({ openAddressModal: false, stateAwbEnter: false })
  }

  handleConfirmAwb = () => {
    const {
      address: {
        type,
        label,
        ligne1,
        ligne2,
        postalCode,
        city,
        countryCodeAlpha2,
        contact2,
        telephone,
        email,
        localAddress,
        comment,
        iD_Adresse,
        addMode,
        readonly,
        name
      }
    } = this.state
    if (readonly) return this.setState({ openAddressModal: false })
    if (type !== '') {
      this.setState({ openAddressModal: false })
      const model = {
        idaddress: null,
        idexterne: null,
        idcompany: this.props.iDSociete,
        type,
        label,
        ligne1,
        ligne2,
        ligne3: '',
        ligne4: '',
        ligne5: '',
        postalCode,
        city,
        countryCodeAlpha2,
        contact2,
        telephone,
        email,
        localAddress,
        comment,
        name
      }
      if (addMode) this.props.apiAddAddress({ iDSociete: this.props.iDSociete, model })
      else this.props.apiEditAddress({ iDSociete: this.props.iDSociete, model: {...model, idaddress: iD_Adresse }, idAddress: iD_Adresse })
    }
  }

  modalHandleChange(field, newValue) {
    this.setState({ address: { ...this.state.address,[field]: newValue }})
  }

  handleResetFilter() {
    this.setState({ searchTermDomain: 'All', searchTerm: '' }, this.handleFilterTableItems)
  }

  handleFilter(e, index, value, name) {
    this.setState({ [name]: value }, this.handleFilterTableItems)
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, this.handleFilterTableItems)
  }

  handleFilterTableItems() {
    const { myAddresses } = this.props
    const { searchTermDomain, searchTerm, sortBy, lastSortBy, sortOrderBy } = this.state
    let articles = cloneDeep(myAddresses)
    articles = articles.map(d => ({ ...d, companyName: this.props.companyName }))
    const term = searchTerm.toString().toLowerCase()
    // filter by search term
    if (term) {
      //filter by searchTermDomain
      if (searchTermDomain !== 'All') {
        articles = filter(articles, o => {
          const getSearchTermDomain = get(o, searchTermDomain, false)
          const searchableItem = getSearchTermDomain
          return (
            searchableItem &&
            searchableItem
              .toString()
              .toLowerCase()
              .includes(term)
          )
        })
      } else {
        articles = filter(articles, o => {
          return some(searchDomaine, sc => {
            const getSearchTermDomain = get(o, sc.key, false)
            const searchableItem = getSearchTermDomain
            return searchableItem
              ? searchableItem
                .toString()
                .toLowerCase()
                .includes(term)
              : false
          })
        })
      }
    }
    let newSortByOrder = ['asc']
    if (sortBy > 0) {
      if (sortBy === lastSortBy) newSortByOrder = sortOrderBy[0] === 'asc' ? ['desc'] : ['asc']
      articles = orderBy(articles, columns[sortBy].key, newSortByOrder)
    }
    this.setState({ myAddresses: articles, sortOrderBy: newSortByOrder })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, countries, iDSociete, companyName } = this.props
    const { myAddresses, address, address: { readonly } } = this.state

    //cosnt columns
    if (isLoading) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    const actions = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={this.handleCloseAwb}>
        {trl.common__cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleConfirmAwb}>
        {trl.myaddresses_modal_btn_Save}
      </SC.ButtonPA_S>
    ]
    const actions2 = [<FlatButton label={trl.common__ok} primary={true} keyboardFocused={true} onClick={this.handleConfirmAwb} />]
    return (
      <div>
        <PageHeader title={trl.myaddresses_Title} />
        <SC.PageGutter>
          <SC.Sky grey>
            <SC.PageGutter>
              {/* ADVANCED SEARCH AND FILTER */}
              {/* 5-1,2 SEARCH & DOMAIN CHOICE */}
              <SearchContainerStyled>
                <FormsWrapper>
                  <FormWrapped onKeyDown={(e) => { if (e.key === "Enter" || e.keyCode === 13) { e.preventDefault(); } }}>
                    <TextLeftStyled>{trl.common__search} :</TextLeftStyled>
                    <div>
                      <TextField
                        type="text"
                        style={{ width: 300 }}
                        name="searchTerm"
                        value={this.state.searchTerm}
                        onChange={e => this.handleChange(e)}
                      />
                    </div>
                  </FormWrapped>
                  <FormWrapped>
                    <span style={{ fontSize: theme.font.fontSize, padding: '0 10px' }}>{trl.common__by} : </span>
                    <SelectField
                      style={{ fontSize: theme.font.fontSize }}
                      hintText={trl.common__search_by || ' '}
                      value={this.state.searchTermDomain}
                      onChange={(e, index, value) => this.handleFilter(e, index, value, 'searchTermDomain')}
                    >
                      <MenuItem style={{ fontSize: theme.font.fontSize }} value="All" primaryText={trl.common__all} />
                      {searchDomaine.map(field => (
                        <MenuItem style={{ fontSize: theme.font.fontSize }} value={field.key} key={field.key} primaryText={field.header} />
                      ))}
                    </SelectField>
                  </FormWrapped>
                </FormsWrapper>
              </SearchContainerStyled>
              <br />
              <SC.ButtonR_M onClick={() => this.handleResetFilter()}>
                {trl.common__filter_reset_btn}
              </SC.ButtonR_M>
            </SC.PageGutter>
          </SC.Sky>
        </SC.PageGutter>
        <SC.PageGutter>
          <div>
            <p style={{ paddingBottom: 20 }}>
              <span style={{ fontSize: theme.font.fontSize, transform: 'translateY(5px)', marginRight: 5, display: 'inline-block' }}>
                <Alert style={{ color: theme.colors.red }} />
              </span>
              {trl.myaddresses_infoMessage}
            </p>

            {myAddresses.length ? (
              <div>
                <div id="deliveryFollowUpTable">
                  <MyAddressesTable
                    data={myAddresses}
                    companyName={companyName}
                    handleOpenEdit={this.handleEditModal}
                    iDSociete={iDSociete}
                    className="OrdersTable"
                    handelSortable={this.handelSortable}
                  />
                </div>
              </div>
            ) : (
              <h2 style={{ textAlign: 'center', marginTop: 80, fontSize: '15px' }}>{'There is no Element to display.'}</h2>
            )}
            <div style={{ textAlign: 'center' }}>

              <SC.ButtonPA_M
                onClick={() => this.handleEditModal()}
              >
                {trl.myaddresses_btn_AddCarrier}
              </SC.ButtonPA_M>
            </div>
          </div>
        </SC.PageGutter>
        <Dialog
          title={address.addMode ? trl.myaddresses_modal_Title : trl.myaddresses_modal_EditTitle}
          actions={readonly ? actions2 : actions}
          modal={false}
          open={this.state.openAddressModal}
          onRequestClose={this.handleCloseAwb}
          autoScrollBodyContent={true}
        >
          <div className="formElementGrid mb12 mt12">
            <label className="formElementLabel">{trl.myaddresses_modal_type}</label>
            {readonly ? (
              <div>
                {address.type == 1 && <span style={{ fontSize: theme.font.fontSize }}>Delivery</span>}
                {address.type == 2 && <span style={{ fontSize: theme.font.fontSize }}>Invoice</span>}
              </div>
            ) : (
              <SelectField
                fullWidth={true}
                onChange={(event, index, newValue) => this.modalHandleChange('type', newValue)}
                value={address.type}
                style={styles.select}
                className="formElementText"
                name="type"
                underlineStyle={{ display: 'none' }}
                inputStyle={{
                  paddingLeft: '3%'
                }}
              >
                <MenuItem style={{ fontSize: theme.font.fontSize }} value={2} primaryText={'Delivery'} />
                <MenuItem style={{ fontSize: theme.font.fontSize }} value={1} primaryText={'Invoice'} />
              </SelectField>
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_name}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}>{address.name}</span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('name', newValue)}
                value={address.name}
                className="formElementText"
                style={styles.input}
                name="name"
                underlineStyle={{ display: 'none' }}
                inputStyle={{
                  paddingLeft: '3%'
                }}

              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_street1}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.ligne1} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('ligne1', newValue)}
                value={address.ligne1}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="ligne1"
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_street2}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.ligne2} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('ligne2', newValue)}
                value={address.ligne2}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="ligne2"
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_postCode}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.postalCode} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('postalCode', newValue)}
                value={address.postalCode}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                type="number"
                maxLength={5}
                size={5}
                max={99999}
                name="postalCode"
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_city}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.city} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('city', newValue)}
                value={address.city}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="city"
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_country}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.countryCodeAlpha2} </span>
            ) : (
              <SelectField
                style={{ fontSize: theme.font.fontSize , ...styles.select}}
                fullWidth={true}
                onChange={(event, index, newValue) => this.modalHandleChange('countryCodeAlpha2', newValue)}
                value={address.countryCodeAlpha2}
                className="formElementText"
                name="countryCodeAlpha2"
                underlineStyle={{ display: 'none' }}
                inputStyle={{
                  paddingLeft: '3%'
                }}
              >
                {countries.map(countrie => (
                  <MenuItem style={{ fontSize: theme.font.fontSize }}
                    key={countrie.country_alpha_2_code}
                    value={countrie.country_alpha_2_code}
                    primaryText={countrie.country_name}
                  />
                ))}
              </SelectField>
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_contact}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.contact2} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('contact2', newValue)}
                value={address.contact2}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="contact2"
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_phone}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.telephone} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('telephone', newValue)}
                value={address.telephone}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="telephone"
                type="number"
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_email}</label>
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.email} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('email', newValue)}
                value={address.email}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="email"
                type="email"
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_localLangageAddress}</label>
          </div>
          <div className="formElementGrid mb12">
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.localAddress || 'N/A'} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('localAddress', newValue)}
                value={address.localAddress}
                multiLine={true}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="localAddress"
                rows={4}
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
          <div className="formElementGrid mb12">
            <label className="formElementLabel">{trl.myaddresses_modal_specialRequest}</label>
          </div>
          <div className="formElementGrid mb12">
            {readonly ? (
              <span style={{ fontSize: theme.font.fontSize }}> {address.comment || 'N/A'} </span>
            ) : (
              <TextField
                fullWidth={true}
                onChange={(event, newValue) => this.modalHandleChange('comment', newValue)}
                value={address.comment}
                className="formElementText"
                style={styles.input}
                underlineStyle={{ display: 'none' }}
                name="comment"
                rows={4}
                multiLine={true}
                inputStyle={{
                  paddingLeft: '3%'
                }}
              />
            )}
          </div>
        </Dialog>
      </div>
    )
  }
}

function mapStateToProps({ countries, auth, apiCallStateManager, me, myCarriers }) {
  return {
    role: auth.user_role,
    apiCallStateManager,
    isLoading: myCarriers.isLoading,
    iDSociete: me.iD_Societe,
    myCarriers: myCarriers.list.modes_Livraison,
    myAddresses: myCarriers.list.listAddress,
    companyName: myCarriers.list.name,
    countries: countries.countries
  }
}

MyAddresses.propTypes = {
  deliveries: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired
}
MyAddresses.defaultProps = {
  deliveries: {}
}

export default connect(
  mapStateToProps,
  { apiGetMyCarriers, apiGetCountries, apiAddAddress, apiEditAddress }
)(MyAddresses)

const SearchContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
`

const TextLeftStyled = styled.p`
  padding-right: 15px;
  transform: translateY(6px);
`
