////////LIBRARY/////////
import React from 'react'
///////COMPONENTS///////
import TextField from 'material-ui/TextField'

const ContainerTextFiled = ({ label, value, disabled, type = 'number', onChange, onBlur, name, errorText }) => {

  ///////////////////////////////// RENDER ///////////////////////////////////////
  
  return (
    <div style={{ flex: '0 1 auto', marginLeft: 8 }}>
      {label && <span style={{ fontWeight: '600' }}>{label}</span>}
      <TextField
        errorStyle={{
          bottom: '-20px'
        }}
        disabled={disabled}
        fullWidth={true}
        hintStyle={{
          paddingTop: 5,
          paddingLeft: 5
        }}
        inputStyle={{
          borderRadius: 0,
          border: errorText === undefined ? '1px solid #ced4da' : '1px solid red',
          padding: '0 12px',
          width: '100%',
          height: '100%',
          position: 'relative',
          top: '10px'
        }}
        underlineShow={false}
        value={value}
        type={type}
        onChange={(event, newValue) => onChange(type === 'number' ? parseFloat(newValue) : newValue)}
        onBlur={onBlur}
        name={name}
        errorText={errorText}
      />
    </div>
  )
}

export default ContainerTextFiled
