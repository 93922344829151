////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form'
import { RadioButton, SelectField, MenuItem, FlatButton } from 'material-ui'
import { extend, findIndex, get, orderBy } from 'lodash'
import { hashHistory } from 'react-router'
import styled from 'styled-components'
import { Toggle } from 'redux-form-material-ui'
///////COMPONENTS///////
import { translate } from '../../../containers/HOC/Translation'
import theme from '../../../components/styledComponents/theme'
import { renderCheckbox, renderSelectField, renderRadioGroup } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import CircularProgress from 'material-ui/CircularProgress'
import * as c from '../../../constants'
import DisplayAddresses from '../../../components/DisplayAddresses'
import HelpComponent from '../../../components/sharedComponents/HelpComponent'
import './OrderDeliveryDetailsForm.css'
import * as SC from '../../../components/styledComponents'

/////////STYLED/////////
const FormContainerStyled = styled.div`
  max-width: 1300px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
/////////STYLED/////////

const validate = (values, props) => {
  const errors = {}
  const { trl } = props
  if (!values.iD_Mode_Livraison) errors.iD_Mode_Livraison = `${trl.order_delivery__delivery_method_required_error}` || ' '
  if (!values.iD_Adresse_Livraison) errors.iD_Adresse_Livraison = `${trl.order_delivery__delivery_address_required_error}` || ' '
  if (!values.ID_BillingAddress) errors.ID_BillingAddress = `${trl.order_delivery__billing_address_required_error}` || ' '
  return errors
}

const choseyourOwnId = 999
class OrderDeliveryDetailsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showHelpComponent: false,
      choseyourOwn: null,
      DeliveryMode: false
    }
    this.handleChangeChoseyourOwn = this.handleChangeChoseyourOwn.bind(this)
    this.handleChangeDeliveryMode = this.handleChangeDeliveryMode.bind(this)
    this.runSimulation = this.runSimulation.bind(this)
    this.handleChangeDeliveryAdress = this.handleChangeDeliveryAdress.bind(this)
    this.handleChangeInvoiceAdress = this.handleChangeInvoiceAdress.bind(this)
    this.handleChangePartialDelivery = this.handleChangePartialDelivery.bind(this)
    this.getChoseYourOwn = this.getChoseYourOwn.bind(this)
    this.getCompaniesModLivraison = this.getCompaniesModLivraison.bind(this)
    this.allowOwnCarriers = this.allowOwnCarriers.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    const { order } = this.props
    const choseyourOwn = this.getChoseYourOwn(order)
    this.setState({ choseyourOwn, DeliveryMode: order.iD_Mode_Livraison === null })
  }

  componentWillReceiveProps(nextPorops) {
    const { order, dispatch } = this.props
    if (order.iD_Mode_Livraison !== nextPorops.order.iD_Mode_Livraison) {
      const iD_Mode_Livraison = nextPorops.order.iD_Mode_Livraison
      const getChoseYourOwn = this.getChoseYourOwn(nextPorops.order)
      const ID_BillingAddress = nextPorops.order.iD_Billing_Adresse
      dispatch(
        initialize('OrderDeliveryDetailsForm', {
          ...nextPorops.order,
          iD_Mode_Livraison: getChoseYourOwn ? choseyourOwnId : iD_Mode_Livraison,
          ID_BillingAddress
        })
      )
      this.setState({
        DeliveryMode: nextPorops.order.iD_Mode_Livraison === null
      })
    }
  }

  componentDidMount() {
    const { order, dispatch } = this.props
    let iD_Mode_Livraison = order.iD_Mode_Livraison
    if (this.allowOwnCarriers()) iD_Mode_Livraison = this.getChoseYourOwn(order) ? choseyourOwnId : order.iD_Mode_Livraison
    const ID_BillingAddress = order.iD_Billing_Adresse
    dispatch(initialize('OrderDeliveryDetailsForm', { ...order, iD_Mode_Livraison, ID_BillingAddress }))
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  allowOwnCarriers() {
    const { order } = this.props
    return get(order, 'available_DeliveryModes.allowOwnCarriers', false)
  }

  getCompaniesModLivraison() {
    const allowOwnCarriers = this.allowOwnCarriers()
    const { order } = this.props
    const availableOwnCarriers = get(order, 'available_DeliveryModes.availableOwnCarriers', [])
    const companiesModLivraison = allowOwnCarriers && availableOwnCarriers ? availableOwnCarriers.filter(mode => mode.iD_Mode_Livraison !== -1) : []
    return orderBy(companiesModLivraison, ['transporteur_Name'], ['asc'])
  }

  getChoseYourOwn(order) {
    const companiesModLivraison = this.getCompaniesModLivraison()
    return findIndex(companiesModLivraison, company => {
      return company.iD_Mode_Livraison === order.iD_Mode_Livraison
    }) >= 0
      ? order.iD_Mode_Livraison
      : null
  }

  onSubmit = formValues => {
    const { iD_Mode_Livraison, iD_Adresse_Livraison, ID_BillingAddress, allow_Partial_Delivery, isDraft } = formValues
    const { choseyourOwn } = this.state
    const { iD_Commande_Externe_Faconnier } = this.props.order
    // the deliveryAddress will be an object if no address is selected, and if chosen it will be a number
    const deliveryAddress = iD_Adresse_Livraison.iD_Adresse || iD_Adresse_Livraison
    const userModel = {
      iD_Commande: parseInt(this.props.orderId, 10),
      iD_Commande_Externe_Faconnier,
      iD_Printshop: null, // TODO : this.props.order.printshop : once we will have the printshop ID.
      Allow_Partial_Delivery: allow_Partial_Delivery ? allow_Partial_Delivery : false,
      ID_Delivery_Mode: iD_Mode_Livraison === choseyourOwnId ? this.state.choseyourOwn : iD_Mode_Livraison,
      ID_Delivery_Address: deliveryAddress,
      ID_BillingAddress,
      Order_Delivery_Validated: isDraft // if nothing checked, it will return undefined, if something checked, it will return true
    }
    if (choseyourOwn === null && iD_Mode_Livraison === 999) this.setState({ DeliveryMode: true })
    // setTimeout(() => {
    //   this.props.apiOrderSapSimulate()
    // }, 100)
    // TODO check if user can call Request URL: http://localhost:3000/api/order/1146/deliverydetails
    // if not do not call api
    return this.props.apiUpdateOrderDeliveryDetails(userModel, this.props.orderId, isDraft)
  }

  handleChangeChoseyourOwn(event, index, value) { this.setState({ choseyourOwn: value, DeliveryMode: false }) }

  handleChangeDeliveryMode(event, index, value) {

    const { order } = this.props
    const availableOwnCarriers = get(order, 'available_DeliveryModes.availableOwnCarriers', []) || []
    order.totalTransport = ''
    this.setState({ DeliveryMode: false })
    if (choseyourOwnId === 999) this.setState({ choseyourOwn: availableOwnCarriers[1] ? availableOwnCarriers[1].iD_Mode_Livraison : null })
    setTimeout(() => {
      this.runSimulation()
    })
  }

  handleChangeDeliveryAdress(event, index, value) {
    setTimeout(() => {
      this.runSimulation()
    })
  }

  handleChangeInvoiceAdress(event, index, value) {
    /* setTimeout(() => {
      this.runSimulation()
    }) */
    this.handleChangeDeliveryAdress(event, index, value)
  }

  handleChangePartialDelivery(event, index, value) {
    /* setTimeout(() => {
      this.runSimulation()
    }) */
    this.handleChangeDeliveryAdress(event, index, value)
  }

  runSimulation() {
    const { iD_Mode_Livraison, iD_Adresse_Livraison, ID_BillingAddress, allow_Partial_Delivery, isDraft } = this.props.addressForm.values
    const { order } = this.props
    const { iD_Commande_Externe_Faconnier } = order
    const deliveryAddress = iD_Adresse_Livraison.iD_Adresse || iD_Adresse_Livraison

    const userModel = {
      iD_Commande: parseInt(this.props.orderId, 10),
      iD_Commande_Externe_Faconnier,
      iD_Printshop: null, // TODO : this.props.order.printshop : once we will have the printshop ID.
      Allow_Partial_Delivery: allow_Partial_Delivery ? allow_Partial_Delivery : false,
      ID_Delivery_Mode: iD_Mode_Livraison === choseyourOwnId ? this.state.choseyourOwn : iD_Mode_Livraison,
      ID_Delivery_Address: deliveryAddress,
      ID_BillingAddress,
      //ID_BillingAddress: this.props.order.ID_BillingAddress,
      Order_Delivery_Validated: isDraft // if nothing checked, it will return undefined, if something checked, it will return true
    }
    this.props.apiUpdateOrderDeliveryDetails(userModel, this.props.orderId, isDraft, false)
    // setTimeout(() => {
    //   this.props.apiOrderSapSimulate()
    // }, 300)
  }

  displayDeliveryMethodForm = isDisabled => {

    const { order, trl } = this.props
    const { choseyourOwn, DeliveryMode } = this.state

    const allowOwnCarriers = this.allowOwnCarriers()
    const idModeLivraisonValue =
      this.props.addressForm && this.props.addressForm.values && this.props.addressForm.values.iD_Mode_Livraison

    const companies2ModLivraison = [
      ...get(order, 'available_DeliveryModes.availableCTCDeliveryMode', []) || [],
      allowOwnCarriers && {
        iD_Mode_Livraison: choseyourOwnId,
        transporteur_Name: 'Choose your own carrier'
      }
    ].filter(i => i)
    // TODO: check update object
    const companiesModLivraison = this.getCompaniesModLivraison()
    let currencySign = ''
    if (order.commande_Articles) {
      const caKeys = Object.keys(order.commande_Articles)
      currencySign = caKeys[0] && order.commande_Articles[caKeys[0]].currencySign
    }

    ///////////////////////////////// RENDER ///////////////////////////////////////

    return (
      <div className="deliveryRadioButton">
        <div className="col1Delivery">
          <Field name="iD_Mode_Livraison" component={renderRadioGroup} className="deliveryRadio">
            {companies2ModLivraison.map((mode, key) => {
              return (
                <RadioButton
                  onClick={this.handleChangeDeliveryMode}
                  disabled={isDisabled}
                  key={mode.iD_Mode_Livraison}
                  value={mode.iD_Mode_Livraison}
                  label={`${mode.transporteur_Name}  ${mode.iD_Mode_Livraison === idModeLivraisonValue && currencySign && order.totalTransport !== ''
                    ? order.totalTransport + ' ' + currencySign
                    : ''
                    } `}
                />
              )
            })}
          </Field>
          <div style={{ color: theme.colors.red }}>
            {!DeliveryMode ? '' : translate('order_shippInfo_TextAlert_DeliveryModeEmpty')}
          </div>
          {this.allowOwnCarriers() && (
            <SelectField
              style={{ fontSize: theme.font.fontSize }}
              floatingLabelText={trl.order_shippInfo_chkbx_SelectCarrier}
              value={choseyourOwn}
              onChange={this.handleChangeChoseyourOwn}
              disabled={idModeLivraisonValue !== choseyourOwnId}
            >
              {companiesModLivraison.map((mode, key) => {
                return <MenuItem style={{ fontSize: theme.font.fontSize }} value={mode.iD_Mode_Livraison} primaryText={mode.transporteur_Name} />
              })}
            </SelectField>
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      pristine,
      handleSubmit,
      submitting,
      error,
      trl,
      submitSucceeded,
      allowPartialDeliveryValue,
      isDraftValue,
      deliveryAddressValue,
      billingAddressValue,
      order,
      handleDeliveryModeDisplay,
      showDeliveryMethodForm,
      isDisabled
    } = this.props
    const allowOwnCarriers = this.allowOwnCarriers()
    const exceptionMessageTradKey = get(order, 'available_DeliveryModes.exceptionMessage_TradKey', '')

    return (
      <FormContainerStyled>
        <form onSubmit={handleSubmit(this.onSubmit).bind(this)}>
          <SC.PageGutter>
            <div className="row">
              {/* CHOOSE DELIVER ADDRESS */}
              <div className="col-xs-6">
                <h3 style={{ fontWeight: 'bold', fontSize: '12px' }}>{trl.order_delivery__delivery_address_title}</h3>
                <Field
                  onChange={this.handleChangeDeliveryAdress}
                  name="iD_Adresse_Livraison"
                  component={renderSelectField}
                  label={trl.order_delivery__delivery_address}
                  disabled={isDisabled}
                  fullWidth={false}
                >
                  {order.possible_Delivery_Addresses &&
                    order.possible_Delivery_Addresses.map((a, index) => (
                      <MenuItem style={{ fontSize: theme.font.fontSize }} key={a.iD_Adresse} value={a.iD_Adresse} primaryText={a.name} />
                    ))}
                </Field>
                <div>&nbsp;</div>
                {order.possible_Delivery_Addresses && (
                  <DisplayAddresses
                    path={
                      order.possible_Delivery_Addresses.filter(
                        a => a.iD_Adresse === parseInt(deliveryAddressValue, 10)
                      )[0] || order.delivery_Address
                    }
                    trl={trl}
                  />
                )}
              </div>
              {/* CHOOSE INVOICING ADDRESS */}
              <div className="col-xs-6">
                <h3 style={{ fontWeight: 'bold', fontSize: '12px' }}>{trl.order_shippInfo_Text_InvoiceAddress}</h3>
                <Field
                  onChange={this.handleChangeInvoiceAdress}
                  name="ID_BillingAddress"
                  component={renderSelectField}
                  label={trl.order_delivery__billing_address}
                  disabled={isDisabled}
                  fullWidth={false}
                >
                  {order.possible_Billing_Addresses &&
                    order.possible_Billing_Addresses.map((a, index) => (
                      <MenuItem style={{ fontSize: theme.font.fontSize }} key={a.iD_Adresse} value={a.iD_Adresse} primaryText={a.name} />
                    ))}
                </Field>
                <div>&nbsp;</div>
                <DisplayAddresses
                  path={
                    order.possible_Billing_Addresses.filter(
                      a => a.iD_Adresse === parseInt(billingAddressValue, 10)
                    )[0] || order.billing_Address
                  }
                  trl={trl}
                />
              </div>
            </div>

            <br />
            <br />
            <p>{trl.order_shippInfo_Text_AlertAddAddress}</p>
            <p>
              <SC.ButtonS
                onClick={() => this.context.router.push(c.myAddresses)}
              >
                {trl.order_shippInfo_Btn_GoToMyAddresses}
              </SC.ButtonS>
            </p>

            <br />

            <h3 style={{ marginBottom: 30, textTransform: 'uppercase', fontWeight: 'bold', fontSize: '12px' }}>
              {trl.order_delivery__delivery_method_title}
            </h3>

            {/* INCOTERM SECTION */}
            <SC.P style={extend(styles.labelWrapper, { fontSize: '12px' })}>
              <div style={styles.delivreyLabel}>{trl.common__incoterm}:</div>
              <div style={styles.deliveryValue}>
                <strong>{order.incoterm} &nbsp;</strong>
              </div>
            </SC.P>
            <SC.P sstyle={extend(styles.labelWrapper, { fontSize: '12px' })}>
              <div style={extend(styles.delivreyLabel, { fontSize: '12px' })}>{trl.common__total_estimated_weight}:</div>
              <div style={extend(styles.deliveryValue, { fontSize: '12px' })}>
                {' '}
                <strong>{order.weight_Total} &nbsp;</strong>
              </div>
            </SC.P>
            <div style={{ position: 'relative' }}>
              <div style={{ width: '100%', paddingLeft: 450, height: 100, position: 'absolute', fontSize: '12px' }}>
                {exceptionMessageTradKey && (
                  <div style={{ width: 450, border: '3px solid red', padding: 15 }}>{trl[exceptionMessageTradKey]}</div>
                )}
              </div>
              <div style={extend(styles.labelWrapperToggle, { fontSize: '12px' })} className="normalLabel">
                <Field
                  onChange={this.handleChangePartialDelivery}
                  name="allow_Partial_Delivery"
                  component={Toggle}
                  thumbStyle={{ backgroundColor: theme.colors.red }}
                  trackStyle={{ backgroundColor: '#ff9d9d' }}
                  thumbSwitchedStyle={{ backgroundColor: theme.colors.green }}
                  trackSwitchedStyle={{ backgroundColor: theme.colors.lightGreen }}
                  disabled={isDisabled}
                  label={trl.common__allow_partial_delivery || ''}
                  default={order.allow_Partial_Delivery}
                />
                <span className="normalLabelValue">{allowPartialDeliveryValue ? trl.common__yes : trl.common__no}</span>
                <br />
                <br />
              </div>
            </div>
            {/* CHOOSE A DELIVERY MODE */}
            {this.displayDeliveryMethodForm(isDisabled)}
            {!isDisabled &&
              allowOwnCarriers &&
              !showDeliveryMethodForm && (
                <div>
                  <FlatButton
                    style={{ marginTop: 20 }}
                    secondary={true}
                    label={trl.delivery_mode__create_btn}
                    onClick={handleDeliveryModeDisplay}
                  />
                </div>
              )}
            {showDeliveryMethodForm && <div style={{ marginBottom: 280 }}>&nbsp;</div>}

            {/* HELPER ICON */}
            <div style={{ position: 'relative' }}>
              <br />
              <br />
              <img
                src={require('../../../assets/centre_appel.png')}
                alt="call center"
                style={{ marginBottom: 30, display: 'block', cursor: 'pointer', width: 50 }}
                onMouseEnter={() => this.setState({ showHelpComponent: true })}
                onMouseLeave={() => this.setState({ showHelpComponent: false })}
              />
              {this.state.showHelpComponent && <HelpComponent />}
            </div>

            <ButtonWrapper>
              {/* LEft */}
              <div>
                <SC.ButtonR_L
                  onClick={() => hashHistory.push(`${c.orderDetail}${order.iD_Commande}`)}
                >
                  {trl.order_shippInfo_Btn_Back}
                </SC.ButtonR_L>
              </div>
              {/* Middle */}
              {/* Right */}
              <div>
                <div>
                  {!isDisabled && (
                    <Field
                      name="isDraft"
                      component={renderCheckbox}
                      label={trl.common__save_as_draft_btn}
                      fullWidth={false}
                    />
                  )}
                  {error &&
                    !showDeliveryMethodForm && (
                      <div className="row">
                        <div className="col-xs-8 offset-2">
                          <p style={styles.loginError}>{error}</p>
                        </div>
                      </div>
                    )}
                </div>
                {submitting &&
                  !showDeliveryMethodForm && (
                    <CircularProgress style={styles.circularProgress} size={36} thickness={4} />
                  )}
                {!isDisabled && (
                  <SC.ButtonPA_L
                    style={{ ...styles.loginBtn, float: 'none', marginTop: 30 }}
                    disabled={submitting || (submitSucceeded && pristine) || order.iD_Mode_Livraison === null}
                  >
                    {isDraftValue ? `${trl.order_delivery__draft_btn}` : `${trl.order_delivery__validate_btn}`}
                  </SC.ButtonPA_L>
                )}
                {isDisabled && (
                  <div style={{ display: 'flex' }}>
                    <div>
                      <SC.ButtonPA_L
                        onClick={() => hashHistory.push(`${c.orderDetail}${order.iD_Commande}${c.confirmOrder}`)}
                      >
                        {trl.order_delivery__validate_btn_review}
                      </SC.ButtonPA_L>
                    </div>
                  </div>
                )}
              </div>
            </ButtonWrapper>
          </SC.PageGutter>
        </form>
      </FormContainerStyled>
    )
  }
}

OrderDeliveryDetailsForm.propTypes = {
  trl: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  apiUpdateOrderDeliveryDetails: PropTypes.func.isRequired,
  // apiOrderSapSimulate: PropTypes.func,
  handleDeliveryModeDisplay: PropTypes.func.isRequired,
  showDeliveryMethodForm: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired
}
OrderDeliveryDetailsForm.contextTypes = {
  router: PropTypes.object.isRequired
}

OrderDeliveryDetailsForm = reduxForm({
  form: 'OrderDeliveryDetailsForm',
  validate,
  touchOnBlur: false
})(OrderDeliveryDetailsForm)

const selector = formValueSelector('OrderDeliveryDetailsForm')
OrderDeliveryDetailsForm = connect(state => {
  const allowPartialDeliveryValue = selector(state, 'allow_Partial_Delivery')
  const isDraftValue = selector(state, 'isDraft')
  const deliveryAddressValue = selector(state, 'iD_Adresse_Livraison')
  const billingAddressValue = selector(state, 'ID_BillingAddress')
  const addressForm = state.form.OrderDeliveryDetailsForm

  return {
    allowPartialDeliveryValue,
    isDraftValue,
    deliveryAddressValue,
    billingAddressValue,
    addressForm
  }
})(OrderDeliveryDetailsForm)

export default OrderDeliveryDetailsForm
