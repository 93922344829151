import moment from 'moment'

//=====================ACTIONS=======================
// AUTH
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN = 'LOGIN'
export const UPDATE_AUTH = 'UPDATE_AUTH'
export const RENEWTOKEN_REQUEST = 'RENEWTOKEN_REQUEST'
export const RENEWTOKEN_SUCCESS = 'RENEWTOKEN_SUCCESS'
export const RENEWTOKEN_FAILURE = 'RENEWTOKEN_FAILURE'
export const LOGOUT = 'LOGOUT'
// USERS
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_USERS = 'FETCH_USERS'
// USER
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const FETCH_USER = 'FETCH_USER'
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'
export const EDIT_USER = 'EDIT_USER'
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const ADD_USER_TO_USERS = 'ADD_USER_TO_USERS'
export const REMOVE_USER_FROM_STATE = 'REMOVE_USER_FROM_STATE'
export const TOGGLE_USER_LOCKOUT = 'TOGGLE_USER_LOCKOUT'
// LANGUAGES
export const FETCH_LANGUAGES_REQUEST = 'FETCH_LANGUAGES_REQUEST'
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS'
export const FETCH_LANGUAGES_FAILURE = 'FETCH_LANGUAGES_FAILURE'
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES'
// LANGUAGE
export const CREATE_LANGUAGE_REQUEST = 'CREATE_LANGUAGE_REQUEST'
export const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS'
export const CREATE_LANGUAGE_FAILURE = 'CREATE_LANGUAGE_FAILURE'
// base language
export const FETCH_BASE_LANGUAGE_REQUEST = 'FETCH_BASE_LANGUAGE_REQUEST'
export const FETCH_BASE_LANGUAGE_SUCCESS = 'FETCH_BASE_LANGUAGE_SUCCESS'
export const FETCH_BASE_LANGUAGE_FAILURE = 'FETCH_BASE_LANGUAGE_FAILURE'
export const ADD_LANGUAGE_TO_BASE_LANGUAGE = 'ADD_LANGUAGE_TO_BASE_LANGUAGE'
export const DELETE_BASE_LANGUAGE = 'DELETE_BASE_LANGUAGE'
// target language
export const FETCH_TARGET_LANGUAGE_REQUEST = 'FETCH_TARGET_LANGUAGE_REQUEST'
export const FETCH_TARGET_LANGUAGE_SUCCESS = 'FETCH_TARGET_LANGUAGE_SUCCESS'
export const FETCH_TARGET_LANGUAGE_FAILURE = 'FETCH_TARGET_LANGUAGE_FAILURE'
export const ADD_LANGUAGE_TO_TARGET_LANGUAGE = 'ADD_LANGUAGE_TO_TARGET_LANGUAGE'
// other lanugage stuff
export const SET_PREFERRED_LANGUAGE_ID = 'SET_PREFERRED_LANGUAGE_ID'
export const DELETE_TARGET_AND_BASE_LANGUAGES = 'DELETE_TARGET_AND_BASE_LANGUAGES'
export const TOGGLE_LANGUAGE_ACTIVATION_STATE = 'TOGGLE_LANGUAGE_ACTIVATION_STATE'
export const CHANGE_TARGET_LANGUAGE_ROW = 'CHANGE_TARGET_LANGUAGE_ROW'
export const TOGGLE_LANGUAGE_LOCKOUT = 'TOGGLE_LANGUAGE_LOCKOUT'
export const ADD_SITLE_LANGUAGE_FROM_LS = 'ADD_SITLE_LANGUAGE_FROM_LS'
export const DELETE_PREFERRED_LANGUAGE_ID = 'DELETE_PREFERRED_LANGUAGE_ID'
export const SET_SITE_LANGUAGE_USING_ID_REQUEST = 'SET_SITE_LANGUAGE_USING_ID_REQUEST'
export const SET_SITE_LANGUAGE_USING_ID_SUCCESS = 'SET_SITE_LANGUAGE_USING_ID_SUCCESS'
export const SET_SITE_LANGUAGE_USING_ID_FAILURE = 'SET_SITE_LANGUAGE_USING_ID_FAILURE'
export const SET_SITE_LANGUAGE_USING_ID = 'SET_SITE_LANGUAGE_USING_ID'
export const VERSION_IS_UP_TO_DATE = 'VERSION_IS_UP_TO_DATE'
// ME
export const FETCH_ME_REQUEST = 'FETCH_ME_REQUEST'
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS'
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE'
export const FETCH_ME = 'FETCH_ME'
export const SET_MY_PREFERRED_LANGUAGE_REQUEST = 'SET_MY_PREFERRED_LANGUAGE_REQUEST'
export const SET_MY_PREFERRED_LANGUAGE_SUCCESS = 'SET_MY_PREFERRED_LANGUAGE_SUCCESS'
export const SET_MY_PREFERRED_LANGUAGE_FAILURE = 'SET_MY_PREFERRED_LANGUAGE_FAILURE'
export const SET_MY_PREFERRED_LANGUAGE = 'SET_MY_PREFERRED_LANGUAGE'
export const UPDATE_ME = 'UPDATE_ME'
export const REQUEST_ACCOUNT_REQUEST = 'REQUEST_ACCOUNT_REQUEST'
export const REQUEST_ACCOUNT_SUCCESS = 'REQUEST_ACCOUNT_SUCCESS'
export const REQUEST_ACCOUNT_FAILURE = 'REQUEST_ACCOUNT_FAILURE'
// APP ALERTS
export const ADD_ALERT = 'ADD_ALERT'
export const DELETE_ALERT = 'DELETE_ALERT'

export const ADD_ALERT_DIALOG = 'ADD_ALERT_DIALOG'
export const DELETE_ALERT_DIALOG = 'DELETE_ALERT_DIALOG'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
// ROLES
export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST'
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'
export const FETCH_ROLES = 'FETCH_ROLES'
// LOGS
export const FETCH_LOGS_REQUEST = 'FETCH_LOGS_REQUEST'
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS'

export const FETCH_LOGS_FAILURE = 'FETCH_LOGS_FAILURE'
export const FETCH_LOGS = 'FETCH_LOGS'
// UTILS
export const MAINTENANCE_CHECK = 'MAINTENANCE_CHECK'
// COMPANIES
export const API_GET_COMPANIES_REQUEST = 'API_GET_COMPANIES_REQUEST'
export const API_GET_COMPANY_FILE_ORDER_FORMATS_REQUEST = 'API_GET_COMPANY_FILE_ORDER_FORMATS_REQUEST'
export const API_GET_COMPANY_FILE_ORDER_FORMATS_SUCCESS = 'API_GET_COMPANY_FILE_ORDER_FORMATS_SUCCESS'
export const API_GET_COMPANY_FILE_ORDER_FORMATS_FAILURE = 'API_GET_COMPANY_FILE_ORDER_FORMATS_FAILURE'
export const API_ADD_STANDARD_FIXED_REQUEST = 'API_ADD_STANDARD_FIXED_REQUEST'
export const API_ADD_STANDARD_FIXED_SUCCESS = 'API_ADD_STANDARD_FIXED_SUCCESS'
export const API_ADD_STANDARD_FIXED_FAILURE = 'API_ADD_STANDARD_FIXED_FAILURE'
export const API_SAVE_FILE_ORDER_FORMAT_REQUEST = 'API_SAVE_FILE_ORDER_FORMAT_REQUEST'
export const API_SAVE_FILE_ORDER_FORMAT_SUCCESS = 'API_SAVE_FILE_ORDER_FORMAT_SUCCESS'
export const API_SAVE_FILE_ORDER_FORMAT_FAILURE = 'API_SAVE_FILE_ORDER_FORMAT_FAILURE'
export const API_GET_COMPANIES_SUCCESS = 'API_GET_COMPANIES_SUCCESS'
export const API_GET_COMPANIES_FAILURE = 'API_GET_COMPANIES_FAILURE'
export const API_GET_COMPANIES = 'API_GET_COMPANIES'
export const API_GET_COMPANIES_ID = 'API_GET_COMPANIES_ID'
export const API_GET_COMPANIES_ID_REQUEST = 'API_GET_COMPANIES_ID_REQUEST'
export const API_GET_COMPANIES_ID_SUCCESS = 'API_GET_COMPANIES_ID_SUCCESS'
export const API_GET_COMPANIES_ID_FAILURE = 'API_GET_COMPANIES_ID_FAILURE'
export const API_GET_COMPANY_BRAND_PARTNER_REQUEST = 'API_GET_COMPANY_BRAND_PARTNER_REQUEST'
export const API_GET_COMPANY_BRAND_PARTNER_SUCCESS = 'API_GET_COMPANY_BRAND_PARTNER_SUCCESS'
export const API_GET_COMPANY_BRAND_PARTNER_FAILURE = 'API_GET_COMPANY_BRAND_PARTNER_FAILURE'
export const API_GET_COMPANY_BRAND_PARTNER = 'API_GET_COMPANY_BRAND_PARTNER'
export const API_UPDATE_COMPANY_REQUEST = 'API_UPDATE_COMPANY_REQUEST'
export const API_UPDATE_COMPANY_SUCCESS = 'API_UPDATE_COMPANY_SUCCESS'
export const API_UPDATE_COMPANY_FAILURE = 'API_UPDATE_COMPANY_FAILURE'
export const API_UPDATE_COMPANY = 'API_UPDATE_COMPANY'
export const API_GET_COMPANY_BY_TYPE = 'API_GET_COMPANY_BY_TYPE'
export const API_GET_COMPANY_BY_TYPE_REQUEST = 'API_GET_COMPANY_BY_TYPE_REQUEST'
export const API_GET_COMPANY_BY_TYPE_SUCCESS = 'API_GET_COMPANY_BY_TYPE_SUCCESS'
export const API_GET_COMPANY_BY_TYPE_FAILURE = 'API_GET_COMPANY_BY_TYPE_FAILURE'
export const RESET_ORDER_COMPANY_BY_TYPE = 'RESET_ORDER_COMPANY_BY_TYPE'

// PRODUCT CATALOG
export const API_GET_PRODUCT_CATALOG = 'API_GET_PRODUCT_CATALOG'
export const API_GET_PRODUCT_CATALOG_REQUEST = 'API_GET_PRODUCT_CATALOG_REQUEST'
export const API_GET_PRODUCT_CATALOG_SUCCESS = 'API_GET_PRODUCT_CATALOG_SUCCESS'
export const API_GET_PRODUCT_CATALOG_FAILURE = 'API_GET_PRODUCT_CATALOG_FAILURE'

// PRODUCT FOLLOW UP
export const API_GET_PRODUCTION_FOLLOW_UP = 'API_GET_PRODUCTION_FOLLOW_UP'
export const API_GET_PRODUCTION_FOLLOW_UP_REQUEST = 'API_GET_PRODUCTION_FOLLOW_UP_REQUEST'
export const API_GET_PRODUCTION_FOLLOW_UP_SUCCESS = 'API_GET_PRODUCTION_FOLLOW_UP_SUCCESS'
export const API_GET_PRODUCTION_FOLLOW_UP_FAILURE = 'API_GET_PRODUCTION_FOLLOW_UP_FAILURE'
export const API_EXPORT_XL_PRODUCTION_FOLLOW_UP_REQUEST = 'API_EXPORT_XL_PRODUCTION_FOLLOW_UP_REQUEST'
export const API_EXPORT_XL_PRODUCTION_FOLLOW_UP_SUCCESS = 'API_EXPORT_XL_PRODUCTION_FOLLOW_UP_SUCCESS'
export const API_EXPORT_XL_PRODUCTION_FOLLOW_UP_FAILURE = 'API_EXPORT_XL_PRODUCTION_FOLLOW_UP_FAILURE'
export const API_PRODUCTION_FOLLOW_UP_RESET_OP = 'API_PRODUCTION_FOLLOW_UP_RESET_OP'
export const API_PRODUCTION_FOLLOW_UP_RESET_OP_REQUEST = 'API_PRODUCTION_FOLLOW_UP_RESET_OP_REQUEST'
export const API_PRODUCTION_FOLLOW_UP_RESET_OP_SUCCESS = 'API_PRODUCTION_FOLLOW_UP_RESET_OP_SUCCESS'
export const API_PRODUCTION_FOLLOW_UP_RESET_OP_FAILURE = 'API_PRODUCTION_FOLLOW_UP_RESET_OP_FAILURE'
export const API_PRODUCTION_FOLLOW_UP_CLOSE_OP = 'API_PRODUCTION_FOLLOW_UP_CLOSE_OP'
export const API_PRODUCTION_FOLLOW_UP_CLOSE_OP_REQUEST = 'API_PRODUCTION_FOLLOW_UP_CLOSE_OP_REQUEST'
export const API_PRODUCTION_FOLLOW_UP_CLOSE_OP_SUCCESS = 'API_PRODUCTION_FOLLOW_UP_CLOSE_OP_SUCCESS'
export const API_PRODUCTION_FOLLOW_UP_CLOSE_OP_FAILURE = 'API_PRODUCTION_FOLLOW_UP_CLOSE_OP_FAILURE'

// ITEM FOLLOW UP
export const API_GET_ITEM_FOLLOW_UP = 'API_GET_ITEM_FOLLOW_UP'
export const API_GET_ITEM_FOLLOW_UP_REQUEST = 'API_GET_ITEM_FOLLOW_UP_REQUEST'
export const API_GET_ITEM_FOLLOW_UP_SUCCESS = 'API_GET_ITEM_FOLLOW_UP_SUCCESS'
export const API_GET_ITEM_FOLLOW_UP_FAILURE = 'API_GET_ITEM_FOLLOW_UP_FAILURE'
export const API_GET_ITEM_FOLLOW_UP_ID = 'API_GET_ITEM_FOLLOW_UP_ID'
export const API_GET_ITEM_FOLLOW_UP_ID_REQUEST = 'API_GET_ITEM_FOLLOW_UP_ID_REQUEST'
export const API_GET_ITEM_FOLLOW_UP_ID_SUCCESS = 'API_GET_ITEM_FOLLOW_UP_ID_SUCCESS'
export const API_GET_ITEM_FOLLOW_UP_ID_FAILURE = 'API_GET_ITEM_FOLLOW_UP_ID_FAILURE'
export const API_EXPORT_XL_ITEM_FOLLOW_UP_REQUEST = 'API_EXPORT_XL_ITEM_FOLLOW_UP_REQUEST'
export const API_EXPORT_XL_ITEM_FOLLOW_UP_SUCCESS = 'API_EXPORT_XL_ITEM_FOLLOW_UP_SUCCESS'
export const API_EXPORT_XL_ITEM_FOLLOW_UP_FAILURE = 'API_EXPORT_XL_ITEM_FOLLOW_UP_FAILURE'

// STOCK FOLLOW UP
export const API_GET_STOCK_FOLLOW_UP = 'API_GET_STOCK_FOLLOW_UP'
export const API_GET_STOCK_FOLLOW_UP_ID = 'API_GET_STOCK_FOLLOW_UP_ID'
export const API_GET_STOCK_FOLLOW_UP_REQUEST = 'API_GET_STOCK_FOLLOW_UP_REQUEST'
export const API_GET_STOCK_FOLLOW_UP_SUCCESS = 'API_GET_STOCK_FOLLOW_UP_SUCCESS'
export const API_GET_STOCK_FOLLOW_UP_FAILURE = 'API_GET_STOCK_FOLLOW_UP_FAILURE'
export const API_EXPORT_XL_STOCK_FOLLOW_UP_REQUEST = 'API_EXPORT_XL_STOCK_FOLLOW_UP_REQUEST'
export const API_EXPORT_XL_STOCK_FOLLOW_UP_SUCCESS = 'API_EXPORT_XL_STOCK_FOLLOW_UP_SUCCESS'
export const API_EXPORT_XL_STOCK_FOLLOW_UP_FAILURE = 'API_EXPORT_XL_STOCK_FOLLOW_UP_FAILURE'
export const API_GET_STOCK_FOLLOW_UP_ID_REQUEST = 'API_GET_STOCK_FOLLOW_UP_ID_REQUEST'
export const API_GET_STOCK_FOLLOW_UP_ID_SUCCESS = 'API_GET_STOCK_FOLLOW_UP_ID_SUCCESS'
export const API_GET_STOCK_FOLLOW_UP_ID_FAILURE = 'API_GET_STOCK_FOLLOW_UP_ID_FAILURE'

// PRINTSHOPS
export const FETCH_PRINTSHOPS_REQUEST = 'FETCH_PRINTSHOPS_REQUEST'
export const FETCH_PRINTSHOPS_SUCCESS = 'FETCH_PRINTSHOPS_SUCCESS'
export const FETCH_PRINTSHOPS_FAILURE = 'FETCH_PRINTSHOPS_FAILURE'
export const FETCH_PRINTSHOPS = 'FETCH_PRINTSHOPS'
export const EDIT_PRINTSHOP = 'EDIT_PRINTSHOP'

export const PUT_PRINTSHOPS_REQUEST = 'PUT_PRINTSHOPS_REQUEST'
export const PUT_PRINTSHOPS_SUCCESS = 'PUT_PRINTSHOPS_SUCCESS'
export const PUT_PRINTSHOPS_FAILURE = 'PUT_PRINTSHOPS_FAILURE'
export const PUT_PRINTSHOPS = 'PUT_PRINTSHOPS'
// COUNTRIES
export const API_GET_COUNTRIES_REQUEST = 'API_GET_COUNTRIES_REQUEST'
export const API_GET_COUNTRIES_SUCCESS = 'API_GET_COUNTRIES_SUCCESS'
export const API_GET_COUNTRIES_FAILURE = 'API_GET_COUNTRIES_FAILURE'
export const API_GET_COUNTRIES = 'API_GET_COUNTRIES'
export const API_UPDATE_COUNTRY_LABELLIST_REQUEST = 'API_UPDATE_COUNTRY_LABELLIST_REQUEST'
export const API_UPDATE_COUNTRY_LABELLIST_SUCCESS = 'API_UPDATE_COUNTRY_LABELLIST_SUCCESS'
export const API_UPDATE_COUNTRY_LABELLIST_FAILURE = 'API_UPDATE_COUNTRY_LABELLIST_FAILURE'
export const API_UPDATE_COUNTRY_LABELLIST = 'API_UPDATE_COUNTRY_LABELLIST'

export const API_UPDATE_COUNTRY_PRINTSHOP_PE_REQUEST = 'API_UPDATE_COUNTRY_PRINTSHOP_PE_REQUEST'
export const API_UPDATE_COUNTRY_PRINTSHOP_PE_SUCCESS = 'API_UPDATE_COUNTRY_PRINTSHOP_PE_SUCCESS'
export const API_UPDATE_COUNTRY_PRINTSHOP_PE_FAILURE = 'API_UPDATE_COUNTRY_PRINTSHOP_PE_FAILURE'
export const API_UPDATE_COUNTRY_PRINTSHOP_PE = 'API_UPDATE_COUNTRY_PRINTSHOP_PE'
export const API_UPDATE_COUNTRY_PRINTSHOP_PP_REQUEST = 'API_UPDATE_COUNTRY_PRINTSHOP_PP_REQUEST'
export const API_UPDATE_COUNTRY_PRINTSHOP_PP_SUCCESS = 'API_UPDATE_COUNTRY_PRINTSHOP_PP_SUCCESS'
export const API_UPDATE_COUNTRY_PRINTSHOP_PP_FAILURE = 'API_UPDATE_COUNTRY_PRINTSHOP_PP_FAILURE'
export const API_UPDATE_COUNTRY_PRINTSHOP_PP = 'API_UPDATE_COUNTRY_PRINTSHOP_PP'
//MISC
export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST'
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE'
export const CHANGE_MY_PASSWORD_REQUEST = 'CHANGE_MY_PASSWORD_REQUEST'
export const CHANGE_MY_PASSWORD_SUCCESS = 'CHANGE_MY_PASSWORD_SUCCESS'
export const CHANGE_MY_PASSWORD_FAILURE = 'CHANGE_MY_PASSWORD_FAILURE'
export const CHECK_SITE_LANGUAGE_VERSION_REQUEST = 'CHECK_SITE_LANGUAGE_VERSION_REQUEST'
export const CHECK_SITE_LANGUAGE_VERSION_SUCCESS = 'CHECK_SITE_LANGUAGE_VERSION_SUCCESS'
export const CHECK_SITE_LANGUAGE_VERSION_FAILURE = 'CHECK_SITE_LANGUAGE_VERSION_FAILURE'
//ORDERS

export const UPDATE_ORDER = 'UPDATE_ORDER'

export const GET_ORDER_AND_ORDER_ARTICLES_AND_ORDER_ARTICLE_WITH_DETAILS_ACTIONS =
  'GET_ORDER_AND_ORDER_ARTICLES_AND_ORDER_ARTICLE_WITH_DETAILS_ACTIONS'
export const GET_ORDER_ARTICLE_ACTIONS = 'GET_ORDER_ARTICLE_ACTIONS'

export const API_UPDATE_ORDER_STATUS_CANCEL_REQUEST = 'API_UPDATE_ORDER_STATUS_CANCEL_REQUEST'
export const API_UPDATE_ORDER_STATUS_CANCEL_SUCCESS = 'API_UPDATE_ORDER_STATUS_CANCEL_SUCCESS'
export const API_UPDATE_ORDER_STATUS_CANCEL_FAILURE = 'API_UPDATE_ORDER_STATUS_CANCEL_FAILURE'

export const API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_REQUEST = 'API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_REQUEST'
export const API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_SUCCESS = 'API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_SUCCESS'
export const API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_FAILURE = 'API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_FAILURE'

export const API_GET_ORDERS_AND_ORDER_ARTICLES_REQUEST = 'API_GET_ORDERS_AND_ORDER_ARTICLES_REQUEST'
export const API_GET_ORDERS_AND_ORDER_ARTICLES_SUCCESS = 'API_GET_ORDERS_AND_ORDER_ARTICLES_SUCCESS'
export const API_GET_ORDERS_AND_ORDER_ARTICLES_FAILURE = 'API_GET_ORDERS_AND_ORDER_ARTICLES_FAILURE'
export const API_GET_ORDERS_AND_ORDER_ARTICLES_RESET = 'API_GET_ORDERS_AND_ORDER_ARTICLES_RESET'
export const GET_ORDERS_AND_ORDER_ARTICLES = 'GET_ORDERS_AND_ORDER_ARTICLES'

export const API_UPDATE_ORDER_ARTICLE_STATIC_REQUEST = 'API_UPDATE_ORDER_ARTICLE_STATIC_REQUEST'
export const API_UPDATE_ORDER_ARTICLE_STATIC_SUCCESS = 'API_UPDATE_ORDER_ARTICLE_STATIC_SUCCESS'
export const API_UPDATE_ORDER_ARTICLE_STATIC_FAILURE = 'API_UPDATE_ORDER_ARTICLE_STATIC_FAILURE'

export const API_UPDATE_ORDER_STATUS_QUALIFIED_REQUEST = 'API_UPDATE_ORDER_STATUS_QUALIFIED_REQUEST'
export const API_UPDATE_ORDER_STATUS_QUALIFIED_SUCCESS = 'API_UPDATE_ORDER_STATUS_QUALIFIED_SUCCESS'
export const API_UPDATE_ORDER_STATUS_QUALIFIED_FAILURE = 'API_UPDATE_ORDER_STATUS_QUALIFIED_FAILURE'
export const API_UPDATE_ORDER_STATUS_QUALIFIED = 'API_UPDATE_ORDER_STATUS_QUALIFIED'

export const API_UPDATE_ORDER_PO_FAC_REQUEST = 'API_UPDATE_ORDER_PO_FAC_REQUEST'
export const API_UPDATE_ORDER_PO_FAC_SUCCESS = 'API_UPDATE_ORDER_PO_FAC_SUCCESS'
export const API_UPDATE_ORDER_PO_FAC_FAILURE = 'API_UPDATE_ORDER_PO_FAC_FAILURE'
export const API_UPDATE_ORDER_PO_FAC = 'API_UPDATE_ORDER_PO_FAC'

export const API_GET_ORDER_ARTICLE_REQUEST = 'API_GET_ORDER_ARTICLE_REQUEST'
export const API_GET_ORDER_ARTICLE_SUCCESS = 'API_GET_ORDER_ARTICLE_SUCCESS'
export const API_GET_ORDER_ARTICLE_FAILURE = 'API_GET_ORDER_ARTICLE_FAILURE'

export const RESET_ORDER_ARTICLE = 'RESET_ORDER_ARTICLE'

export const API_GET_SKU_LIST_REQUEST = 'API_GET_SKU_LIST_REQUEST'
export const API_GET_SKU_LIST_SUCCESS = 'API_GET_SKU_LIST_SUCCESS'
export const API_GET_SKU_LIST_FAILURE = 'API_GET_SKU_LIST_FAILURE'
export const API_GET_SKU_LIST = 'API_GET_SKU_LIST'

export const API_SHOW_MODAL_REASSIGN = 'API_SHOW_MODAL_REASSIGN'
export const API_SHOW_MODAL_REASSIGN_CONFIRM = 'API_SHOW_MODAL_REASSIGN_CONFIRM'
export const API_SET_ORDER_ID = 'API_SET_ORDER_ID'

export const API_GET_REASSIGN_ORDER = 'API_GET_REASSIGN_ORDER'
export const API_GET_REASSIGN_ORDER_REQUEST = 'API_GET_REASSIGN_ORDER_REQUEST'
export const API_GET_REASSIGN_ORDER_SUCCESS = 'API_GET_REASSIGN_ORDER_SUCCESS'
export const API_GET_REASSIGN_ORDER_FAILURE = 'API_GET_REASSIGN_ORDER_FAILURE'

export const API_PUT_REASSIGN_ORDER = 'API_PUT_REASSIGN_ORDER'
export const API_PUT_REASSIGN_ORDER_REQUEST = 'API_PUT_REASSIGN_ORDER_REQUEST'
export const API_PUT_REASSIGN_ORDER_SUCCESS = 'API_PUT_REASSIGN_ORDER_SUCCESS'
export const API_PUT_REASSIGN_ORDER_FAILURE = 'API_PUT_REASSIGN_ORDER_FAILURE'

export const API_RESET_REASSIGN_UPDATED = 'API_RESET_REASSIGN_UPDATED'
export const RESET_SKU_MASS_EDIT_ERROR = 'RESET_SKU_MASS_EDIT_ERROR'

export const API_GET_ORDER_ARTICLE_OFU_REQUEST = 'API_GET_ORDER_ARTICLE_OFU_REQUEST'
export const API_GET_ORDER_ARTICLE_OFU_SUCCESS = 'API_GET_ORDER_ARTICLE_OFU_SUCCESS'
export const API_GET_ORDER_ARTICLE_OFU_FAILURE = 'API_GET_ORDER_ARTICLE_OFU_FAILURE'

export const API_CREATE_ORDER_REQUEST = 'API_CREATE_ORDER_REQUEST'
export const API_CREATE_ORDER_SUCCESS = 'API_CREATE_ORDER_SUCCESS'
export const API_CREATE_ORDER_FAILURE = 'API_CREATE_ORDER_FAILURE'

export const API_UPDATE_ORDER_ARTICLE_DVCS = 'API_UPDATE_ORDER_ARTICLE_DVCS'
export const API_UPDATE_ORDER_ARTICLE_DVCS_REQUEST = 'API_UPDATE_ORDER_ARTICLE_DVCS_REQUEST'
export const API_UPDATE_ORDER_ARTICLE_DVCS_SUCCESS = 'API_UPDATE_ORDER_ARTICLE_DVCS_SUCCESS'
export const API_UPDATE_ORDER_ARTICLE_DVCS_FAILURE = 'API_UPDATE_ORDER_ARTICLE_DVCS_FAILURE'

export const API_UPDATE_DVC_ONFOCUSOUT_REQUEST = 'API_UPDATE_DVC_ONFOCUSOUT_REQUEST'
export const API_UPDATE_DVC_ONFOCUSOUT_SUCCESS = 'API_UPDATE_DVC_ONFOCUSOUT_SUCCESS'
export const API_UPDATE_DVC_ONFOCUSOUT_FAILURE = 'API_UPDATE_DVC_ONFOCUSOUT_FAILURE'

export const API_DELETE_SKU = 'API_DELETE_SKU'
export const API_DELETE_SKU_REQUEST = 'API_DELETE_SKU_REQUEST'
export const API_DELETE_SKU_SUCCESS = 'API_DELETE_SKU_SUCCESS'
export const API_DELETE_SKU_FAILURE = 'API_DELETE_SKU_FAILURE'

export const API_EDIT_SKU = 'API_EDIT_SKU'
export const API_EDIT_SKU_REQUEST = 'API_EDIT_SKU_REQUEST'
export const API_EDIT_SKU_SUCCESS = 'API_EDIT_SKU_SUCCESS'
export const API_EDIT_SKU_FAILURE = 'API_EDIT_SKU_FAILURE'

export const API_ADD_SKU = 'API_ADD_SKU'
export const API_ADD_SKU_REQUEST = 'API_ADD_SKU_REQUEST'
export const API_ADD_SKU_SUCCESS = 'API_ADD_SKU_SUCCESS'
export const API_ADD_SKU_FAILURE = 'API_ADD_SKU_FAILURE'

export const API_QUALIFY_ALL_REQUEST = 'API_QUALIFY_ALL_REQUEST'
export const API_QUALIFY_ALL_SUCCESS = 'API_QUALIFY_ALL_SUCCESS'
export const API_QUALIFY_ALL_FAILURE = 'API_QUALIFY_ALL_FAILURE'

export const API_CONFIRMBAT_REQUEST = 'API_CONFIRMBAT_REQUEST'
export const API_CONFIRMBAT_SUCCESS = 'API_CONFIRMBAT_SUCCESS'
export const API_CONFIRMBAT_FAILURE = 'API_CONFIRMBAT_FAILURE'

export const API_ORDER_ARTICLE_DVCS_REQUEST = 'API_ORDER_ARTICLE_DVCS_REQUEST'
export const API_ORDER_ARTICLE_DVCS_SUCCESS = 'API_ORDER_ARTICLE_DVCS_SUCCESS'
export const API_ORDER_ARTICLE_DVCS_FAILURE = 'API_ORDER_ARTICLE_DVCS_FAILURE'

export const API_ORDER_ARTICLE_COMPO_REQUEST = 'API_ORDER_ARTICLE_COMPO_REQUEST'
export const API_ORDER_ARTICLE_COMPO_SUCCESS = 'API_ORDER_ARTICLE_COMPO_SUCCESS'
export const API_ORDER_ARTICLE_COMPO_FAILURE = 'API_ORDER_ARTICLE_COMPO_FAILURE'

export const API_UPDATE_ORDER_ARTICLE_COMPOSITION_REQUEST = 'API_UPDATE_ORDER_ARTICLE_COMPOSITION_REQUEST'
export const API_UPDATE_ORDER_ARTICLE_COMPOSITION_SUCCESS = 'API_UPDATE_ORDER_ARTICLE_COMPOSITION_SUCCESS'
export const API_UPDATE_ORDER_ARTICLE_COMPOSITION_FAILURE = 'API_UPDATE_ORDER_ARTICLE_COMPOSITION_FAILURE'

export const API_UPDATE_ORDER_ARTICLE_SKU_DVSS_REQUEST = 'API_UPDATE_ORDER_ARTICLE_SKU_DVSS_REQUEST'
export const API_UPDATE_ORDER_ARTICLE_SKU_DVSS_SUCCESS = 'API_UPDATE_ORDER_ARTICLE_SKU_DVSS_SUCCESS'
export const API_UPDATE_ORDER_ARTICLE_SKU_DVSS_FAILURE = 'API_UPDATE_ORDER_ARTICLE_SKU_DVSS_FAILURE'

export const API_UPDATE_ORDER_DELIVERY_DETAILS_REQUEST = 'API_UPDATE_ORDER_DELIVERY_DETAILS_REQUEST'
export const API_UPDATE_ORDER_DELIVERY_DETAILS_SUCCESS = 'API_UPDATE_ORDER_DELIVERY_DETAILS_SUCCESS'
export const API_UPDATE_ORDER_DELIVERY_DETAILS_FAILURE = 'API_UPDATE_ORDER_DELIVERY_DETAILS_FAILURE'

export const API_EXPORT_XL_OFU_REQUEST = 'API_EXPORT_XL_OFU_REQUEST'
export const API_EXPORT_XL_OFU_SUCCESS = 'API_EXPORT_XL_OFU_SUCCESS'
export const API_EXPORT_XL_OFU_FAILURE = 'API_EXPORT_XL_OFU_FAILURE'

export const API_EXPORT_XL_PRODUCTCATALOG_REQUEST = 'API_EXPORT_XL_PRODUCTCATALOG_REQUEST'
export const API_EXPORT_XL_PRODUCTCATALOG_SUCCESS = 'API_EXPORT_XL_PRODUCTCATALOG_SUCCESS'
export const API_EXPORT_XL_PRODUCTCATALOG_FAILURE = 'API_EXPORT_XL_PRODUCTCATALOG_FAILURE'

//OWNER SHIP
export const API_TAKE_OWNER_SHIP_REQUEST = 'API_TAKE_OWNER_SHIP_REQUEST'
export const API_TAKE_OWNER_SHIP_SUCCESS = 'API_TAKE_OWNER_SHIP_SUCCESS'
export const API_TAKE_OWNER_SHIP_FAILURE = 'API_TAKE_OWNER_SHIP_FAILURE'

//addressSimulateRequest
export const API_ORDER_SAP_SIMULATE_GET = 'API_ORDER_SAP_SIMULATE_GET'
export const API_ORDER_SAP_SIMULATE_REQUEST = 'API_ORDER_SAP_SIMULATE_REQUEST'
export const API_ORDER_SAP_SIMULATE_SUCCESS = 'API_ORDER_SAP_SIMULATE_SUCCESS'
export const API_ORDER_SAP_SIMULATE_FAILURE = 'API_ORDER_SAP_SIMULATE_FAILURE'

//orderSapCreateRequest
export const API_ORDER_SAP_CREATE_GET = 'API_ORDER_SAP_CREATE_GET'
export const API_ORDER_SAP_CREATE_REQUEST = 'API_ORDER_SAP_CREATE_REQUEST'
export const API_ORDER_SAP_CREATE_SUCCESS = 'API_ORDER_SAP_CREATE_SUCCESS'
export const API_ORDER_SAP_CREATE_FAILURE = 'API_ORDER_SAP_CREATE_FAILURE'

export const API_UPDATE_GEOGRAPHICAL_AREA_REQUEST = 'API_UPDATE_GEOGRAPHICAL_AREA_REQUEST'
export const API_UPDATE_GEOGRAPHICAL_AREA_SUCCESS = 'API_UPDATE_GEOGRAPHICAL_AREA_SUCCESS'
export const API_UPDATE_GEOGRAPHICAL_AREA_FAILURE = 'API_UPDATE_GEOGRAPHICAL_AREA_FAILURE'

export const API_REPEAT_ORDER_REQUEST = 'API_REPEAT_ORDER_REQUEST'
export const API_REPEAT_ORDER_SUCCESS = 'API_REPEAT_ORDER_SUCCESS'
export const API_REPEAT_ORDER_FAILURE = 'API_REPEAT_ORDER_FAILURE'

export const API_UPDTATE_WISHED_EXPEDITION_DATE_REQUEST = 'API_UPDTATE_WISHED_EXPEDITION_DATE_REQUEST'
export const API_UPDTATE_WISHED_EXPEDITION_DATE_SUCCESS = 'API_UPDTATE_WISHED_EXPEDITION_DATE_SUCCESS'
export const API_UPDTATE_WISHED_EXPEDITION_DATE_FAILURE = 'API_UPDTATE_WISHED_EXPEDITION_DATE_FAILURE'

export const API_ADD_ITEM_TO_ORDER_REQUEST = 'API_ADD_ITEM_TO_ORDER_REQUEST'
export const API_ADD_ITEM_TO_ORDER_SUCCESS = 'API_ADD_ITEM_TO_ORDER_SUCCESS'
export const API_ADD_ITEM_TO_ORDER_FAILURE = 'API_ADD_ITEM_TO_ORDER_FAILURE'

export const API_CREATE_CARRIER_REQUEST = 'API_CREATE_CARRIER_REQUEST'
export const API_CREATE_CARRIER_SUCCESS = 'API_CREATE_CARRIER_SUCCESS'
export const API_CREATE_CARRIER_FAILURE = 'API_CREATE_CARRIER_FAILURE'
export const UPDATE_CARRIER = 'UPDATE_CARRIER'

export const UPDATE_VISIBLE_SKU = 'UPDATE_VISIBLE_SKU'

export const API_MODIFY_ALL_SKU = 'API_MODIFY_ALL_SKU'
export const API_MODIFY_ALL_SKU_REQUEST = 'API_MODIFY_ALL_SKU_REQUEST'
export const API_MODIFY_ALL_SKU_SUCCESS = 'API_MODIFY_ALL_SKU_SUCCESS'
export const API_MODIFY_ALL_SKU_FAILURE = 'API_MODIFY_ALL_SKU_FAILURE'

//BAT PENDING
export const API_GET_PATS_PENDING_REQUEST = 'API_GET_PATS_PENDING_REQUEST'
export const API_GET_PATS_PENDING_SUCCESS = 'API_GET_PATS_PENDING_SUCCESS'
export const API_GET_PATS_PENDING_FAILURE = 'API_GET_PATS_PENDING_FAILURE'
// NEW FILTER
export const API_GET_BATS_PENDING = 'API_GET_BATS_PENDING'
export const API_GET_BATS_PENDING_REQUEST = 'API_GET_BATS_PENDING_REQUEST'
export const API_GET_BATS_PENDING_SUCCESS = 'API_GET_BATS_PENDING_SUCCESS'
export const API_GET_BATS_PENDING_FAILURE = 'API_GET_BATS_PENDING_FAILURE'

// END NEW FILTER
export const API_GET_PATS_TO_TREAT_REQUEST = 'API_GET_PATS_TO_TREAT_REQUEST'
export const API_GET_PATS_TO_TREAT_SUCCESS = 'API_GET_PATS_TO_TREAT_SUCCESS'
export const API_GET_PATS_TO_TREAT_FAILURE = 'API_GET_PATS_TO_TREAT_FAILURE'

export const API_BAT_APPROUVE_REJECT_REQUEST = 'API_BAT_APPROUVE_REJECT_REQUEST'
export const API_BAT_APPROUVE_REJECT_SUCCESS = 'API_BAT_APPROUVE_REJECT_SUCCESS'
export const API_BAT_APPROUVE_REJECT_FAILURE = 'API_BAT_APPROUVE_REJECT_FAILURE'

export const API_BAT_HISTORY_REQUEST = 'API_BAT_HISTORY_REQUEST'
export const API_BAT_HISTORY_SUCCESS = 'API_BAT_HISTORY_SUCCESS'
export const API_BAT_HISTORY_FAILURE = 'API_BAT_HISTORY_FAILURE'

//DELIVERIES
export const API_GET_DELIVERIES_FOLLOW_UP = 'API_GET_DELIVERIES_FOLLOW_UP'
export const API_GET_DELIVERIES_FOLLOW_UP_REQUEST = 'API_GET_DELIVERIES_FOLLOW_UP_REQUEST'
export const API_GET_DELIVERIES_FOLLOW_UP_SUCCESS = 'API_GET_DELIVERIES_FOLLOW_UP_SUCCESS'
export const API_GET_DELIVERIES_FOLLOW_UP_FAILURE = 'API_GET_DELIVERIES_FOLLOW_UP_FAILURE'

export const API_PUT_DELIVERIES_WAITING_FOLLOW_UP = 'API_PUT_DELIVERIES_WAITING_FOLLOW_UP'
export const API_PUT_DELIVERIES_WAITING_FOLLOW_UP_REQUEST = 'API_PUT_DELIVERIES_WAITING_FOLLOW_UP_REQUEST'
export const API_PUT_DELIVERIES_WAITING_FOLLOW_UP_SUCCESS = 'API_PUT_DELIVERIES_WAITING_FOLLOW_UP_SUCCESS'
export const API_PUT_DELIVERIES_WAITING_FOLLOW_UP_FAILURE = 'API_PUT_DELIVERIES_WAITING_FOLLOW_UP_FAILURE'

export const API_GET_DELIVERY_REQUEST = 'API_GET_DELIVERY_REQUEST'
export const API_GET_DELIVERY_SUCCESS = 'API_GET_DELIVERY_SUCCESS'
export const API_GET_DELIVERY_FAILURE = 'API_GET_DELIVERY_FAILURE'

export const API_CREATE_DELIVERY_REQUEST = 'API_CREATE_DELIVERY_REQUEST'
export const API_CREATE_DELIVERY_SUCCESS = 'API_CREATE_DELIVERY_SUCCESS'
export const API_CREATE_DELIVERY_FAILURE = 'API_CREATE_DELIVERY_FAILURE'

export const API_CANCEL_DELIVERY_REQUEST = 'API_CANCEL_DELIVERY_REQUEST'
export const API_CANCEL_DELIVERY_SUCCESS = 'API_CANCEL_DELIVERY_SUCCESS'
export const API_CANCEL_DELIVERY_FAILURE = 'API_CANCEL_DELIVERY_FAILURE'

export const API_DELIVERY_PRINT_INVOICE_REQUEST = 'API_DELIVERY_PRINT_INVOICE_REQUEST'
export const API_DELIVERY_PRINT_INVOICE_SUCCESS = 'API_DELIVERY_PRINT_INVOICE_SUCCESS'
export const API_DELIVERY_PRINT_INVOICE_FAILURE = 'API_DELIVERY_PRINT_INVOICE_FAILURE'

export const API_DELIVERY_PRINT_PREPARATION_SHEET_REQUEST = 'API_DELIVERY_PRINT_PREPARATION_SHEET_REQUEST'
export const API_DELIVERY_PRINT_PREPARATION_SHEET_SUCCESS = 'API_DELIVERY_PRINT_PREPARATION_SHEET_SUCCESS'
export const API_DELIVERY_PRINT_PREPARATION_SHEET_FAILURE = 'API_DELIVERY_PRINT_PREPARATION_SHEET_FAILURE'

export const API_AWB_DELIVERY_REQUEST = 'API_AWB_DELIVERY_REQUEST'
export const API_AWB_DELIVERY_SUCCESS = 'API_AWB_DELIVERY_SUCCESS'
export const API_AWB_DELIVERY_FAILURE = 'API_AWB_DELIVERY_FAILURE'

export const API_UPDATE_PACKING_LIST_REQUEST = 'API_UPDATE_PACKING_LIST_REQUEST'
export const API_UPDATE_PACKING_LIST_SUCCESS = 'API_UPDATE_PACKING_LIST_SUCCESS'
export const API_UPDATE_PACKING_LIST_FAILURE = 'API_UPDATE_PACKING_LIST_FAILURE'

export const API_SAVE_PRINT_PACKING_LIST_REQUEST = 'API_SAVE_PRINT_PACKING_LIST_REQUEST'
export const API_SAVE_PRINT_PACKING_LIST_SUCCESS = 'API_SAVE_PRINT_PACKING_LIST_SUCCESS'
export const API_SAVE_PRINT_PACKING_LIST_FAILURE = 'API_SAVE_PRINT_PACKING_LIST_FAILURE'

export const API_ADD_PACKING_LIST_BOX_REQUEST = 'API_ADD_PACKING_LIST_BOX_REQUEST'
export const API_ADD_PACKING_LIST_BOX_SUCCESS = 'API_ADD_PACKING_LIST_BOX_SUCCESS'
export const API_ADD_PACKING_LIST_BOX_FAILURE = 'API_ADD_PACKING_LIST_BOX_FAILURE'

export const API_GET_CARRIER_DATA_REQUEST = 'API_GET_CARRIER_DATA_REQUEST'
export const API_GET_CARRIER_DATA_SUCCESS = 'API_GET_CARRIER_DATA_SUCCESS'
export const API_GET_CARRIER_DATA_FAILURE = 'API_GET_CARRIER_DATA_FAILURE'
export const API_SAVE_PACKING_LIST_REQUEST = 'API_SAVE_PACKING_LIST_REQUEST'
export const API_SAVE_PACKING_LIST_SUCCESS = 'API_SAVE_PACKING_LIST_SUCCESS'
export const API_SAVE_PACKING_LIST_FAILURE = 'API_SAVE_PACKING_LIST_FAILURE'

export const API_ADD_ITEM_PACKING_LIST_REQUEST = 'API_ADD_ITEM_PACKING_LIST_REQUEST'
export const API_ADD_ITEM_PACKING_LIST_SUCCESS = 'API_ADD_ITEM_PACKING_LIST_SUCCESS'
export const API_ADD_ITEM_PACKING_LIST_FAILURE = 'API_ADD_ITEM_PACKING_LIST_FAILURE'

export const API_DELETE_PACKING_LIST_BOX_REQUEST = 'API_DELETE_PACKING_LIST_BOX_REQUEST'
export const API_DELETE_PACKING_LIST_BOX_SUCCESS = 'API_DELETE_PACKING_LIST_BOX_SUCCESS'
export const API_DELETE_PACKING_LIST_BOX_FAILURE = 'API_DELETE_PACKING_LIST_BOX_FAILURE'

export const API_DELETE_PACKING_LIST_BOX_ITEM_REQUEST = 'API_DELETE_PACKING_LIST_BOX_ITEM_REQUEST'
export const API_DELETE_PACKING_LIST_BOX_ITEM_SUCCESS = 'API_DELETE_PACKING_LIST_BOX_ITEM_SUCCESS'
export const API_DELETE_PACKING_LIST_BOX_ITEM_FAILURE = 'API_DELETE_PACKING_LIST_BOX_ITEM_FAILURE'

export const API_DELETE_PACKING_LIST_REQUEST = 'API_DELETE_PACKING_LIST_REQUEST'
export const API_DELETE_PACKING_LIST_SUCCESS = 'API_DELETE_PACKING_LIST_SUCCESS'
export const API_DELETE_PACKING_LIST_FAILURE = 'API_DELETE_PACKING_LIST_FAILURE'

//=====================MY ADDRESSES=============================

export const API_DELETE_MY_ADDRESS_REQUEST = 'API_DELETE_MY_ADDRESS_REQUEST'
export const API_DELETE_MY_ADDRESS_SUCCESS = 'API_DELETE_MY_ADDRESS_SUCCESS'
export const API_DELETE_MY_ADDRESS_FAILURE = 'API_DELETE_MY_ADDRESS_FAILURE'

export const API_EDIT_MY_ADDRESS_REQUEST = 'API_EDIT_MY_ADDRESS_REQUEST'
export const API_EDIT_MY_ADDRESS_SUCCESS = 'API_EDIT_MY_ADDRESS_SUCCESS'
export const API_EDIT_MY_ADDRESS_FAILURE = 'API_EDIT_MY_ADDRESS_FAILURE'

export const API_ADD_MY_ADDRESS_REQUEST = 'API_ADD_MY_ADDRESS_REQUEST'
export const API_ADD_MY_ADDRESS_SUCCESS = 'API_ADD_MY_ADDRESS_SUCCESS'
export const API_ADD_MY_ADDRESS_FAILURE = 'API_ADD_MY_ADDRESS_FAILURE'
//=====================MY documentation=============================

export const API_GET_MY_DOCUMENTATION_REQUEST = 'API_GET_MY_DOCUMENTATION_REQUEST'
export const API_GET_MY_DOCUMENTATION_SUCCESS = 'API_GET_MY_DOCUMENTATION_SUCCESS'
export const API_GET_MY_DOCUMENTATION_FAILURE = 'API_GET_MY_DOCUMENTATION_FAILURE'
export const RESET_MY_DOCUMENTATION = 'RESET_MY_DOCUMENTATION'
//=====================GM OPTIONS=============================

export const API_GET_GM_OPTIONS = 'API_GET_GM_OPTIONS'
export const API_GET_GM_OPTIONS_REQUEST = 'API_GET_GM_OPTIONS_REQUEST'
export const API_GET_GM_OPTIONS_SUCCESS = 'API_GET_GM_OPTIONS_SUCCESS'
export const API_GET_GM_OPTIONS_FAILURE = 'API_GET_GM_OPTIONS_FAILURE'

export const API_PUT_GM_OPTION = 'API_PUT_GM_OPTION'
export const API_PUT_GM_OPTION_REQUEST = 'API_PUT_GM_OPTION_REQUEST'
export const API_PUT_GM_OPTION_SUCCESS = 'API_PUT_GM_OPTION_SUCCESS'
export const API_PUT_GM_OPTION_FAILURE = 'API_PUT_GM_OPTION_FAILURE'

export const API_EXPORT_XL_GM_OPTION_REQUEST = 'API_EXPORT_XL_GM_OPTION_REQUEST'
export const API_EXPORT_XL_GM_OPTION_SUCCESS = 'API_EXPORT_XL_GM_OPTION_SUCCESS'
export const API_EXPORT_XL_GM_OPTION_FAILURE = 'API_EXPORT_XL_GM_OPTION_FAILURE'
//=====================MY CARRIERS=============================

export const API_GET_MY_CARRIERS_REQUEST = 'API_GET_MY_CARRIERS_REQUEST'
export const API_GET_MY_CARRIERS_SUCCESS = 'API_GET_MY_CARRIERS_SUCCESS'
export const API_GET_MY_CARRIERS_FAILURE = 'API_GET_MY_CARRIERS_FAILURE'

export const API_DELETE_MY_CARRIER_REQUEST = 'API_DELETE_MY_CARRIER_REQUEST'
export const API_DELETE_MY_CARRIER_SUCCESS = 'API_DELETE_MY_CARRIER_SUCCESS'
export const API_DELETE_MY_CARRIER_FAILURE = 'API_DELETE_MY_CARRIER_FAILURE'

export const API_EDIT_MY_CARRIER_REQUEST = 'API_EDIT_MY_CARRIER_REQUEST'
export const API_EDIT_MY_CARRIER_SUCCESS = 'API_EDIT_MY_CARRIER_SUCCESS'
export const API_EDIT_MY_CARRIER_FAILURE = 'API_EDIT_MY_CARRIER_FAILURE'

export const API_ADD_MY_CARRIER_REQUEST = 'API_ADD_MY_CARRIER_REQUEST'
export const API_ADD_MY_CARRIER_SUCCESS = 'API_ADD_MY_CARRIER_SUCCESS'
export const API_ADD_MY_CARRIER_FAILURE = 'API_ADD_MY_CARRIER_FAILURE'

// shippementHistory
export const API_GET_SHIPPEMENT_HISTORY_REQUEST = 'API_GET_SHIPPEMENT_HISTORY_REQUEST'
export const API_GET_SHIPPEMENT_HISTORY_SUCCESS = 'API_GET_SHIPPEMENT_HISTORY_SUCCESS'
export const API_GET_SHIPPEMENT_HISTORY_FAILURE = 'API_GET_SHIPPEMENT_HISTORY_FAILURE'

// Dashboard
export const API_GET_DASHBOARD_REQUEST = 'API_GET_DASHBOARD_REQUEST'
export const API_GET_DASHBOARD_SUCCESS = 'API_GET_DASHBOARD_SUCCESS'
export const API_GET_DASHBOARD_FAILURE = 'API_GET_DASHBOARD_FAILURE'

// Stats
export const API_GET_STATS_REQUEST = 'API_GET_STATS_REQUEST'
export const API_GET_STATS_SUCCESS = 'API_GET_STATS_SUCCESS'
export const API_GET_STATS_FAILURE = 'API_GET_STATS_FAILURE'

// swithUserRole
export const API_PUT_SWITH_USER_ROLE_REQUEST = 'API_PUT_SWITH_USER_ROLE_REQUEST'
export const API_PUT_SWITH_USER_ROLE_SUCCESS = 'API_PUT_SWITH_USER_ROLE_SUCCESS'
export const API_PUT_SWITH_USER_ROLE_FAILURE = 'API_PUT_SWITH_USER_ROLE_FAILURE'

// File order follow up
export const API_GET_FILE_ORDER_FOLLOW_UP = 'API_GET_FILE_ORDER_FOLLOW_UP'
export const API_GET_FILE_ORDER_FOLLOW_UP_REQUEST = 'API_GET_FILE_ORDER_FOLLOW_UP_REQUEST'
export const API_GET_FILE_ORDER_FOLLOW_UP_SUCCESS = 'API_GET_FILE_ORDER_FOLLOW_UP_SUCCESS'
export const API_GET_FILE_ORDER_FOLLOW_UP_FAILURE = 'API_GET_FILE_ORDER_FOLLOW_UP_FAILURE'

export const API_GET_FILE_ORDER_ERRORS = 'API_GET_FILE_ORDER_ERRORS'
export const API_GET_FILE_ORDER_ERRORS_REQUEST = 'API_GET_FILE_ORDER_ERRORS_REQUEST'
export const API_GET_FILE_ORDER_ERRORS_SUCCESS = 'API_GET_FILE_ORDER_ERRORS_SUCCESS'
export const API_GET_FILE_ORDER_ERRORS_FAILURE = 'API_GET_FILE_ORDER_ERRORS_FAILURE'

export const API_GET_FILE_ORDER_DOWNLOAD_FILE = 'API_GET_FILE_ORDER_DOWNLOAD_FILE'
export const API_GET_FILE_ORDER_DOWNLOAD_FILE_REQUEST = 'API_GET_FILE_ORDER_DOWNLOAD_FILE_REQUEST'
export const API_GET_FILE_ORDER_DOWNLOAD_FILE_SUCCESS = 'API_GET_FILE_ORDER_DOWNLOAD_FILE_SUCCESS'
export const API_GET_FILE_ORDER_DOWNLOAD_FILE_FAILURE = 'API_GET_FILE_ORDER_DOWNLOAD_FILE_FAILURE'

export const API_CANCEL_FILE_ORDER = 'API_CANCEL_FILE_ORDER'
export const API_CANCEL_FILE_ORDER_REQUEST = 'API_CANCEL_FILE_ORDER_REQUEST'
export const API_CANCEL_FILE_ORDER_SUCCESS = 'API_CANCEL_FILE_ORDER_SUCCESS'
export const API_CANCEL_FILE_ORDER_FAILURE = 'API_CANCEL_FILE_ORDER_FAILURE'

export const TEST_REFRESH = 'TEST_REFRESH'
export const SET_TEST_FALSE = 'SET_TEST_FALSE'

export const SET_IS_AWB_TRUE = 'SET_IS_AWB_TRUE'
export const SET_IS_AWB_FALSE = 'SET_IS_AWB_FALSE'

// Partnerships Manager

export const API_GET_PARTNERSHIPS_MANAGEMENT = 'API_GET_PARTNERSHIPS_MANAGEMENT'
export const API_GET_PARTNERSHIPS_MANAGEMENT_REQUEST = 'API_GET_PARTNERSHIPS_MANAGEMENT_REQUEST'
export const API_GET_PARTNERSHIPS_MANAGEMENT_SUCCESS = 'API_GET_PARTNERSHIPS_MANAGEMENT_SUCCESS'
export const API_GET_PARTNERSHIPS_MANAGEMENT_FAILURE = 'API_GET_PARTNERSHIPS_MANAGEMENT_FAILURE'

export const API_PUT_PARTNERSHIPS_MANAGEMENT = 'API_PUT_PARTNERSHIPS_MANAGEMENT'
export const API_PUT_PARTNERSHIPS_MANAGEMENT_REQUEST = 'API_PUT_PARTNERSHIPS_MANAGEMENT_REQUEST'
export const API_PUT_PARTNERSHIPS_MANAGEMENT_SUCCESS = 'API_PUT_PARTNERSHIPS_MANAGEMENT_SUCCESS'
export const API_PUT_PARTNERSHIPS_MANAGEMENT_FAILURE = 'API_PUT_PARTNERSHIPS_MANAGEMENT_FAILURE'

export const API_EXPORT_PARTNERSHIPS_MANAGEMENT = 'API_EXPORT_PARTNERSHIPS_MANAGEMENT'
export const API_EXPORT_PARTNERSHIPS_MANAGEMENT_REQUEST = 'API_EXPORT_PARTNERSHIPS_MANAGEMENT_REQUEST'
export const API_EXPORT_PARTNERSHIPS_MANAGEMENT_SUCCESS = 'API_EXPORT_PARTNERSHIPS_MANAGEMENT_SUCCESS'
export const API_EXPORT_PARTNERSHIPS_MANAGEMENT_FAILURE = 'API_EXPORT_PARTNERSHIPS_MANAGEMENT_FAILURE'

// SOURCE FILE ORDER MODAL

export const API_PUT_SOURCE_FILE_MODAL = 'API_PUT_SOURCE_FILE_MODAL'
export const API_PUT_SOURCE_FILE_MODAL_REQUEST = 'API_PUT_SOURCE_FILE_MODAL_REQUEST'
export const API_PUT_SOURCE_FILE_MODAL_SUCCESS = 'API_PUT_SOURCE_FILE_MODAL_SUCCESS'
export const API_PUT_SOURCE_FILE_MODAL_FAILURE = 'API_PUT_SOURCE_FILE_MODAL_FAILURE'

export const API_GET_MY_CARRIERS_DATA_REQUEST = "API_GET_MY_CARRIERS_DATA_REQUEST"
export const API_GET_MY_CARRIERS_DATA_SUCCESS = "API_GET_MY_CARRIERS_DATA_SUCCESS"
export const API_GET_MY_CARRIERS_DATA_FAILURE = "API_GET_MY_CARRIERS_DATA_FAILURE"

//=====================ROLES=============================
export const GAdmin = 'GAdmin'
export const ADMGlobal = 'ADMGlobal'
export const CSP = 'CSP'
export const CSS = 'CSS'
export const EAC = 'EAC'
export const EAdmin = 'EAdmin'
export const FAC = 'FAC'
export const FACA = 'FACA'
export const LPAdmin = 'LPAdmin'
export const LSAdmin = 'LSAdmin'
export const PAdmin = 'PAdmin'
export const POP = 'POP'

export const allRoles = {
  GAdmin: 'GAdmin',
  ADMGlobal: 'ADMGlobal',
  CSP: 'CSP',
  CSS: 'CSS',
  EAC: 'EAC',
  EAdmin: 'EAdmin',
  FAC: 'FAC',
  FACA: 'FACA',
  LPAdmin: 'LPAdmin',
  LSAdmin: 'LSAdmin',
  PAdmin: 'PAdmin',
  POP: 'POP'
}
//===================SITE ROUTES=============================
// ou bien créer un espace de nom routes.login dans les constantes
export const api = '/api'
export const login = '/login'
export const requestAccount = '/request-account'
export const forgotPassword = '/forgot-password'
export const resetPassword = '/user/reset'
export const userConfirm = '/user/confirm'
export const index = '/'
export const users = '/admin/users'
export const userEdit = '/admin/user/edit/'
export const userCreate = '/admin/user/create'
export const roles = '/admin/roles'
export const logs = '/admin/logs'
export const countriesLabellists = '/admin/countries-labelling-specialist'
export const brands = '/admin/brands'
export const stats = '/stats'
export const managePrintshops = '/admin/manage-printshops'
export const brandEdit = '/admin/brand/edit/'
export const brandEditFileFormats = '/admin/brands/file-order-formats/'
export const ordersList = '/orders/pre'
export const orderDetail = '/order/'
export const apiExportofu = '/api/orders/exportofu'
export const apiExportProductCatalog = '/api/catalog/export/xlsx'
export const orderNew = '/order/new'
export const orderDeliveryDetails = '/delivery-details'
export const orderAddItem = '/add-item'
export const confirmOrder = '/confirm-order'
export const confirmOrderMessage = '/confirm-order-message'
export const confirmOrderSuccess = '/confirm-order-success'
export const confirmOrderFailure = '/confirm-order-failure'
export const article = '/article/'
export const articleFixe = '/article/s/'
export const articleVariable = '/article/v/'
export const shipmentHistory = '/shipment-history/'
export const composition = '/composition'
export const maintenance = '/maintenance'
export const languages = '/admin/languages'
export const languageCreate = '/admin/language/create'
export const languageEdit = '/admin/language/edit/'
export const myAccount = '/my-account'
export const myDocumentation = '/my-documentation'
export const myDocumentationSub = '/my-documentation-sub'
export const company = '/company/'
export const documentationHistory = '/documentationHistory'
export const documentationHistory2 = 'documentationHistory'
export const deliveryAddress = '/delivery-address'
export const followUp = '/follow-up'
export const orderRepeat = '/order-repeat'
export const orderViewShipment = '/shipment'
export const notFound = '*'

export const deliveriesFollowUp = '/deliveries-follow-up'
export const waitingForDelivery = '/api/deliveries/waiting'
export const delivery = '/delivery/'
export const downloadARC = '/pdf_from_sap/arc/'
export const downloadInvoice = '/pdf_from_sap/facture/'
export const packinglist = '/packinglist'
export const packinglistSlash = '/packinglist/'
export const preparationSheet = '/preparation-sheet/'
export const manageGMOptions = '/manageGmOptions/'

export const container = '/container/'
export const packinglistRemove = '/remove'
export const packinglistPackRemove = '/packinglist/remove'
export const packinglistItem = '/item/'
export const packinglistBoxNew = '/packinglist/box/new'
export const packinglistAdditems = '/additems'
export const packinglistBox = '/packinglist/box/'
export const packinglistSavePrint = '/packinglist/saveandprint/'
export const duplicate = '/duplicate'

export const catalogue = '/catalogue'
export const placeOrder = '/place-order'
export const documentation = '/documentation'
export const proformaInvoices = '/proformas'
export const deliveryNote = '/delivery-note'
export const invoices = '/invoices'
export const shippingInfo = '/shipping'
export const cgv = '/cgv'
export const confidentiality = '/confidentiality'
export const contact = '/contact'

export const myCarriers = '/myCarriers'
export const myAddresses = '/my-addresses'

export const batPending = '/bat-pending'
export const batView = '/bat-view/'
export const batHistory = '/bat-history/'
export const commandeArticle = '/commande-article/'

export const productCatalog = '/product-catalog/'

export const productionFollowUp = '/production-follow-up/'

export const stockFollowUp = '/stock-follow-up/'

export const itemFollowUp = '/item-follow-up/'

export const fileOrderFollowUp = '/file-order-follow-up'

export const partnershipsManagement = '/partnerships-management'

export const carriersData = '/carrier/my-carrier'

//===================API ROUTES=========================
//TOKEN
export const apiToken = `/api/oauth/token`
export const apiTokenRenew = `/api/oauth/token/renew`
//USER
export const apiUser = '/api/user/' // POST on c.apiUser - PUT on `${c.apiUser}${user_id}`
export const dashBoard = 'dashBoard' // POST on c.apiUser - PUT on `${c.apiUser}${user_id}`
export const apiUserConfirm = '/confirm' // /api/user/{id}/confirm
export const apiUserResetRequest = '/resetrequest' // /api/user/{email}/resetrequest --> will send an email to the user with a reset link
export const apiUserReset = '/reset' // /api/user/{id}/reset --> will change the password of the user using the token
export const apiUserEnable = '/enable' // /api/user/{id}/enable
export const apiUserDisable = '/disable' // /api/user/{id}/disable
export const apiUserUpdatePassword = '/password' // /api/user/{id}/password
export const apiUserUpdatePreferredLanguage = '/language' // /api/user/{id}/language
export const apiUserLogout = '/logout' // /api/user/{id}/logout
// USERS
export const apiUsers = '/api/users/'
// ME
export const apiRequestAcount = '/api/register'
export const swithUserRole = 'swithUserRole'
//LANGUAGE
export const apiLanguage = '/api/language/' // POST on c.apiLanguage - PUT on `${c.apiLanguage}${language_id}`
export const apiLanguageNoFallback = '/nofallback' // /api/language/{id}/nofallback
export const apiLanguageKey = '/key/' // /api/language/{id}/key/{key}
// LANGUAGES
export const apiLanguages = '/api/languages/'
//UTILS
export const apiCheckMaintenance = '/api/utils/ping'
//LOGS
export const apiLogs = '/api/logs'
//COMPANY
export const apiCompany = '/api/company/' // POST on c.apiCompany - PUT on `${c.apiCompany}${company_id}`
export const apiCarrier = '/api/carrier' // POST company/{societeid}/carrier/create
export const apiCarriernew = '/carrier/new' // POST /api/company/{id_Company}/carrier/new
export const carrier = '/carrier/' // POST /api/company/{id_Company}/carrier/new
export const apiFileOrderFormats = '/fileorderformats' // POST /api/company/{id_Company}/fileorderformats
export const address = '/address/' // POST /api/company/{id_Company}/carrier/new
export const apiAddressnew = '/address/new' // POST /api/company/{id_Company}/carrier/new
export const apiCompanyType = '/api/order/' // api/order/{idOrder}/company/{companyType}(br, ps, lb, gm)
export const apiAddStandardFixed = '/fileorderformats/addstandardfixed'
//COMPANIES
export const apiCompanies = '/api/companies/'
export const apiCompanyPartnerBrands = 'api/company/partnerBrands'
//PRINTSHOPS
export const apiPrintshops = '/api/warehouses/deliveryRules'
//COUNTRIES
export const apiCountries = '/api/countries/'
//COUNTRY
export const apiCountry = '/api/countryy/'
export const apiUpdateLabellistCountry = '/labellist' // PUT on   `${c.apiCountry}${country_alpha_2_code}${c.apiUpdateLabellistCountry}`,
export const apiUpdatePrintshop_PP_Country = '/printshop/pp' // PUT on   `${c.apiCountry}${country_alpha_2_code}${c.apiUpdatePrintshop_PP_Country}`,
export const apiUpdatePrintshop_PE_Country = '/printshop/pe' // PUT on   `${c.apiCountry}${country_alpha_2_code}${c.apiUpdatePrintshop_PE_Country}`,
//LABELLIST
export const apiLabellist = '/api/labellist/'
//ROLES
export const apiRoles = '/api/roles'
//DELIVERIES
export const apiDeliveries = '/api/deliveries'
export const apiDeliveriesWaiting = '/api/deliveries/waiting'
export const apiDelivery = '/api/delivery/'
export const apiCreate = '/new'
export const apiCancel = '/cancel'
export const apiConfirm = '/confirm'
export const apiDeliveryPrintInvoice = '/printInvoice'
export const apiDeliveryPrintPS = '/printpreparationsheet'
//PRODUCT CATALOG
export const apiGetProductCatalog = '/api/catalog'

//PRODUCTION FOLLOW UP CATALOG
export const apiGetProductionFollowUp = '/api/productions/profu'
export const apiGetProductionFollowUpExport = '/api/productions/exportprofu'
export const apiGetProductionFollowUpResetOP = '/api/productions/plannedorder'
export const apiGetProductionFollowUpCloseOP = '/api/productions/plannedorder'

// ITEM FoLLOW UP
export const apiGetItemFollowUp = '/api/ifu' // /api/ifu
export const apiGetItemFollowUpId = '/api/ifu' // /api/ifu/'id'
export const apiGetItemFollowUpExport = '/api/ifu/export' // /api/ifu/export'

//STOCK FOLLOW UP CATALOG
export const apiGetStockFollowUp = '/api/stocks' // "/api/stocks"
export const apiGetStockFollowUpExport = 'api/stocks/exportstofu' // "api/stocks/exportstofu"
export const apiGetStockFollowUpId = '/api/stocks' // "/api/stocks/'id'"

// FILE ORDER FOLLOW UP
export const apiGetFileOrderFollowUp = '/api/fofu'
export const apiGetFileOrderErrors = '/errors'
export const apiCancelFileOrder = '/cancel'
export const apiGetFileOrderFileDownload = '/download'

//ORDERS
export const apiOrders = 'api/orders/ofu'

export const apiOrderDetail = '/api/order/'
export const apiOFU = '/ofu'
export const apiOrderNew = 'new/'
export const apiConfirmOrder = '/confirmOrder'
export const apiOrderAddItem = '/article'
export const apiArticleFixe = '/article/'
export const apiDeliveryDetails = '/deliverydetails'
export const apiOrderConfirm = '/confirm' // GET on ${c.apiOrderDetail}${orderId}${c.apiOrderConfirm}
export const apiCancelOrder = '/cancel' // PUT on   `${c.apiOrderDetail}${orderId}${c.apiCancelOrder}`,
export const apiDvcArticle = '/dvc/aztec'
export const apiItem = '/item/'
export const apiDvc = '/dvc'
export const apiDvOonfocusout = '/dvc/onfocusout'
export const apiSku = '/sku/'
export const apiCompo = '/compo'
export const apiQualifyAll = '/qualifyall'
export const apiConfirmbat = '/confirmbat'
export const apiCompositionArticle = '/dvc/fieldarray'
export const apiSkuArticle = '/dvs/aztec'
export const apiIdExtFaconnier = '/updateidextfac'
export const apiRepeat = '/repeat'
export const apiupdateWishedExpeditionDate = '/updatewishedexpdate'

export const apiSapSimulation = '/sap/simulate'
export const apiSapCreate = '/sap/create'

export const apiModifyAllSkuNumber = '/sku/quantity-number'
export const apiModifyAllSkuPercent = '/sku/quantity-percent'
export const apiTakeOwnerShip = '/takeOwnership'
export const apiReassign = '/reassign'
//BAT PENDING
export const apiBATspending = '/api/batspending'
export const apiBATspendingOrder = '/api/batspending/order/'
export const apiBAT = '/api/bat/'
export const approveReject = '/approvereject'
export const apiBatHistory = '/batHistory'

//shipent history
export const apiShipmentHistory = '/shipmentHistory'

//STATS
export const apiStats = '/api/user/stats'

// GM OPTIONS
export const apiGmOptions = '/api/purchasers'
export const apiGmOption = '/api/purchaser'
export const apiGmOptionsExport = '/api/purchasers/export'

// PARTNERSHIPS MANAGEMENT
export const apiPartnerShipsManagement = '/api/partnerships'
export const apiPartnerShipManagement = '/api/partnership'
export const apiPartnerShipsManagementExport = '/api/partnerships/export'

// SOURCE FILE ORDER MODAL
export const apiSourceFileOrderModal = '/api/sourcefile/'

//===================RESTRICTED FUNCTIONS=========================
export const funcUpdateId = 'funcUpdateId'
export const funcCreateNewUser = 'funcCreateNewUser'
export const visualizeActivateDesactivateUser = 'visualizeActivateDesactivateUser'
export const addLanguage = 'addLanguage'
export const seeOrderDetail = 'seeOrderDetail'
export const seeOrderRepeat = 'seeOrderRepeat'
export const viewShipment = 'viewShipment'
export const actionPrintInvoice = 'actionPrintInvoice'
export const qualifyArticle = 'qualifyArticle' // defines the right to qualify an article
export const cancelArticle = 'cancelArticle' // defines the right to cancel an article
export const updateWishedExpeditionDate = 'updateWishedExpeditionDate' // defines the right to update wished expedition date
export const updateDeliveryZone = 'updateDeliveryZone'
export const isFacOrFaca = 'isFacOrFaca'
export const cancelFileOrder = 'cancelFileOrder'
export const reassignOrder = 'reassignOrder'
export const viewUserEnableDisable = 'viewUserEnableDisable'

//===================DATE FORMATS=============================
export const dateDMY = 'dd-mm-yy'
export const dateISOYMD = 'yyyy-mm-dd'
export const dateHMS = 'HH:MM:ss'
//===================STATIC ENGLISH TEXT=========================
export const stForgotPassword = 'Forgot Password?'
export const stRegister = 'Request Your Just Print It Account'
export const stLogin = 'Login'


export const stCnil =
  '<p> The information collected on this form is recorded in a file computerized by Close To Clothes for the management of our customers. (N ° xxxxxxxxxxxx)) </p><p> They are kept for 1 year and are intended for marketing and sales departments established outside the European Union. </p><p> In accordance with the law "informatique et libertés", you can exercise your right of access to the data concerning you and have them corrected by contacting: Close To Clothes Paris. </p>'

//===================MISCELANEOUS=========================
export const baseLanguageDefaultId = 1
export const siteLanguage = 'siteLanguage'

// authorisation status :
export const displayViewShipment = [18, 19, 20, 22]
export const displayViewShipmentArticle = [37, 38, 39]

//table traslation
const todayDate = new Date()
const yesterdayDate = new Date()
yesterdayDate.setDate(yesterdayDate.getDate() - 1)
todayDate.setHours(0, 0, 0, 0)
yesterdayDate.setHours(0, 0, 0, 0)
const formatted_yesterday = moment(yesterdayDate).format('DD-MM-YYYY')
const formatted_today = moment(todayDate).format('DD-MM-YYYY')

export const dashboardTransTable = {
  pendingBats: {
    title: 'dashboard_BR_BATPending_Title',
    subTitle: 'dashboard_BR_BATPending_SubTitle',
    table: {
      gmName: { text: 'dashboard_BR_BATPending_TableTitle_gmName', format: 'text' },
      jpiPo: { text: 'dashboard_BR_BATPending_TableTitle_JPIPO', format: 'text' },
      brandPo: { text: 'dashboard_BR_BATPending_TableTitle_brandPO', format: 'text' },
      bat_Date: { text: 'dashboard_BR_BATPending_TableTitle_receivedDate', format: 'date' },
      deadline_date: { text: 'dashboard_BR_BATPending_TableTitle_brandDeadline', format: 'date' },
      action: { text: '', format: 'iconBat', icon: 'fa-eye', color: '#5bc0de' }
    },
    footer: {
      title: 'dashboard_BR_BATPending_ViewAllBtn_text',
      format: 'btn',
      style: {
        labelColor: '#fff',
        backgroundColor: '#ef3c39'
      },
      link: {
        to: '/bat-pending',
        params: ''
      }
    }
  },
  rejectedBats: {
    title: 'dashboard_MK_BATRejected_Title',
    subTitle: 'dashboard_MK_BATRejected_SubTitle',
    table: {
      brandName: { text: 'dashboard_MK_BATRejected_TableTitle_brandName', format: 'text' },
      jpiPo: { text: 'dashboard_MK_BATRejected_TableTitle_JPIPO', format: 'text' },
      brandPo: { text: 'dashboard_MK_BATRejected_TableTitle_brandPO', format: 'text' },
      gmPo: { text: 'dashboard_MK_BATRejected_TableTitle_gmPO', format: 'text' },
      bat_Date: { text: 'dashboard_MK_BATRejected_TableTitle_rejectionDate', format: 'date' },
      deadline_date: { text: 'dashboard_MK_BATRejected_TableTitle_brandDeadline', format: 'date' }
    },
    footer: {
      title: 'dashboard_MK_BATRejected_totalordersRejected',
      value: 'totalRejectedBats',
      format: 'textValue',
      color: '#ef3c39'
    }
  },
  pendingOrdersBR: {
    title: 'dashboard_BR_PendingOrders_Title',
    subTitle: 'dashboard_BR_PendingOrders_SubTitle',
    table: {
      gmName: { text: 'dashboard_BR_PendingOrders_TableTitle_gmName', format: 'text' },
      jpiPo: { text: 'dashboard_BR_PendingOrders_TableTitle_JPIPO', format: 'text' },
      brandPo: { text: 'dashboard_BR_PendingOrders_TableTitle_brandPO', format: 'text' },
      salesOrder_Date: { text: 'dashboard_BR_PendingOrders_TableTitle_receivedDate', format: 'date' },
      deadline_date: { text: 'dashboard_BR_PendingOrders_TableTitle_brandDeadline', format: 'date' },
      action: { text: '', format: 'icon', icon: 'fa-eye', color: '#5bc0de' }
    },
    footer: {
      title: 'dashboard_BR_PendingOrders_ViewAllBtn_text',
      format: 'btn',
      style: {
        labelColor: '#fff',
        backgroundColor: '#ef3c39'
      },
      link: {
        to: '/follow-up',
        params: '?filter=pending'
      }
    }
  },
  pendingOrdersGM: {
    title: 'dashboard_MK_PendingOrders_Title',
    subTitle: 'dashboard_MK_PendingOrders_SubTitle',
    table: {
      brandName: { text: 'dashboard_MK_PendingOrders_TableTitle_brandName', format: 'text' },
      jpiPo: { text: 'dashboard_MK_PendingOrders_TableTitle_JPIPO', format: 'text' },
      brandPo: { text: 'dashboard_MK_PendingOrders_TableTitle_brandPO', format: 'text' },
      gmPo: { text: 'dashboard_MK_PendingOrders_TableTitle_gmPO', format: 'text' },
      salesOrder_Date: { text: 'dashboard_MK_PendingOrders_TableTitle_receivedDate', format: 'date' },
      deadline_date: { text: 'dashboard_MK_PendingOrders_TableTitle_brandDeadline', format: 'date' },
      action: { text: '', format: 'icon', icon: 'fa-eye', color: '#5bc0de' }
    },
    footer: {
      title: 'dashboard_MK_PendingOrders_ViewAllBtn_text',
      format: 'btn',
      style: {
        labelColor: '#fff',
        backgroundColor: '#ef3c39'
      },
      link: {
        to: '/follow-up',
        params: '?filter=pending'
      }
    }
  },
  waitingForPaymentOrdersGM: {
    title: 'dashboard_MK_WaitingForPayment_Title',
    subTitle: 'dashboard_MK_WaitingForPayment_SubTitle',
    table: {
      brandName: { text: 'dashboard_MK_WaitingForPayment_TableTitle_brandName', format: 'text' },
      jpiPo: { text: 'dashboard_MK_WaitingForPayment_TableTitle_JPIPO', format: 'text' },
      brandPo: { text: 'dashboard_MK_WaitingForPayment_TableTitle_brandPO', format: 'text' },
      gmPo: { text: 'dashboard_MK_WaitingForPayment_TableTitle_gmPO', format: 'text' },
      dueSince: { text: 'dashboard_MK_WaitingForPayment_TableTitle_dueSinceDate', format: 'date' }
    },
    footer: {
      title: 'dashboard_MK_WaitingForPayment_ViewAllBtn_text',
      format: 'btn',
      style: {
        labelColor: '#fff',
        backgroundColor: '#FEDF41'
      },
      link: {
        to: '/follow-up',
        params: '?filter=waitingForPayment'
      }
    }
  },
  waitingForPaymentOrdersLAB: {
    title: 'dashboard_LAB_WaitingForPayment_Title',
    subTitle: 'dashboard_LAB_WaitingForPayment_SubTitle',
    table: {
      brandName: { text: 'dashboard_LAB_WaitingForPayment_TableTitle_brandName', format: 'text' },
      gmName: { text: 'dashboard_LAB_WaitingForPayment_TableTitle_gmName', format: 'text' },
      jpiPo: { text: 'dashboard_LAB_WaitingForPayment_TableTitle_JPIPO', format: 'text' },
      sapPo: { text: 'dashboard_LAB_WaitingForPayment_TableTitle_SAPPO', format: 'text' },
      dueSince: { text: 'dashboard_LAB_WaitingForPayment_TableTitle_dueSinceDate', format: 'date' },
      total_Amount: { text: 'dashboard_LAB_WaitingForPayment_TableTitle_amount', format: 'text' }
    },
    footer: {
      title: 'dashboard_MK_WaitingForPayment_ViewAllBtn_text',
      format: 'btn',
      style: {
        labelColor: '#fff',
        backgroundColor: '#FEDF41'
      },
      link: {
        to: '/follow-up',
        params: '?filter=waitingForPayment'
      }
    }
  },
  fileOrdersBR: {
    title: 'dashboard_BR_LastFiles_Title',
    subTitle: 'dashboard_BR_LastFiles_SubTitle',
    table: {
      file_Date: { text: 'dashboard_BR_LastFiles_TableTitle_receptionDate', format: 'date' },
      fileName: { text: 'dashboard_BR_LastFiles_TableTitle_fileName', format: 'text' },
      status: { text: 'dashboard_BR_LastFiles_TableTitle_status', format: 'translated', transKey: 'traductionKey' }
    }
  },
  fileOrdersLAB: {
    title: 'dashboard_LAB_LastFiles_Title',
    subTitle: 'dashboard_LAB_LastFiles_SubTitle',
    table: {
      brandName: { text: 'dashboard_LAB_LastFiles_TableTitle_brandName', format: 'text' },
      file_Date: { text: 'dashboard_BR_LastFiles_TableTitle_receptionDate', format: 'date' },
      fileName: { text: 'dashboard_BR_LastFiles_TableTitle_fileName', format: 'text' },
      status: { text: 'dashboard_BR_LastFiles_TableTitle_status', format: 'translated', transKey: 'traductionKey' }
    }
  },
  dailyShipments: {
    title: '',
    subTitle: '',
    table: {},
    footer: {
      title: 'dashboard_LAB_DailyShipment_ViewAllBtn_text',
      format: 'btn',
      style: {
        labelColor: '#fff',
        backgroundColor: '#0FB6FE'
      },
      link: {
        to: '/deliveries-follow-up',
        params: `?fromShipping=${formatted_yesterday}&&toShipping=${formatted_today}`
      }
    }
  }
}
