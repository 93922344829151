////////LIBRARY/////////
import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import { ID_TOKEN } from '../../utils/apiUtils'
import { getItem } from '../../utils/cookiesHelper'
import { reLogin } from '../../actions/auth'
import { checkMaintenanceMode } from '../../actions/maintenance'

class RestrictPage extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    const { user_isAuthenticated, checkMaintenanceMode, reLogin, location } = this.props
    const { router } = this.context
    const path = location.pathname
    // check if in maintenance mode
    checkMaintenanceMode(`?from=${path}`)
    // if user not authenticated with user_isAuthenticated state
    if (!user_isAuthenticated) {
      // getting const from LocalStorage && Cookie
      const accessTokenLst = localStorage.getItem(ID_TOKEN)
      const accessTokenCookie = getItem(ID_TOKEN)
      const accessToken = accessTokenLst || accessTokenCookie
      // if variables in localStorage or Cookie exist, dispatch reLogin action, if not send user to /login and pass on this page's path in order to redirect back upon login
      if (accessToken) reLogin(accessToken)
      else if(path != '/') router.push(`/login?redirect=${path}`)
      else router.push(`/login`)
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { user_isAuthenticated } = this.props
    if (user_isAuthenticated) return this.props.children
    return null
  }
}

RestrictPage.propTypes = {
  user_isAuthenticated: PropTypes.bool,
  children: PropTypes.object,
  location: PropTypes.object
}

RestrictPage.contextTypes = {
  router: PropTypes.object.isRequired
}

function mapStateToProps({ auth }) {
  const { user_isAuthenticated } = auth
  return { user_isAuthenticated }
}

export default connect(mapStateToProps, { reLogin, checkMaintenanceMode })(RestrictPage)
