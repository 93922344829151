////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { orderBy, isNull, isEqual } from 'lodash'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import { theme } from '../../components/styledComponents'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import { apiGetStockFollowUp, apiStockFollowUpExport } from '../../actions/stockFollowUp'
import StockFollowUpTable from '../../components/Sematable/Tables/StockFollowUpTable'

/////////STYLED/////////
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
`
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ? props.margin : '0'};
`
const LegendTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const LegendTableText = styled.span`
  font-weight: bold;
  color: red;
  margin: 0 0 5px 0;
  font-size: 10px !important;
`
/////////STYLED/////////

class StockFollowUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      BrandId: null,
      searchValue: '',
      searchByValue: 'common__all',
    }
    this.defaultState = this.state
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    const { selectedBrandId, xlsx, inputBy } = nextProps
    if (!this.props.xlsx && xlsx) {
      setTimeout(() => {
        this.exportXlsx && this.exportXlsx.click()
      }, 1000)
    }
    if (selectedBrandId !== this.state.BrandId) this.setState({ BrandId: isNull(selectedBrandId) ? 0 : selectedBrandId })
    if (inputBy !== this.state.searchByValue) this.setState({ searchByValue: isNull(inputBy) ? 'common__all' : inputBy })
  }

  componentDidMount() {
    this.queryData()
  }

  queryData = (defaultState) => {
    const { BrandId, searchValue, searchByValue } = this.state
    if (isEqual(defaultState, this.defaultState)) this.props.apiGetStockFollowUp({ BrandId: defaultState.BrandId, InputSearchBy: { key: defaultState.searchValue, value: null }})
    else {
      this.props.apiGetStockFollowUp({
        BrandId: BrandId === 0 ? null : BrandId,
        InputSearchBy: {
          key: searchValue,
          value: searchByValue === 'common__all' ? null : searchByValue
        }
      })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleSelectBrandChange = (event, index, value) => {
    this.setState({ BrandId: value })
  }

  handleSearchChange = e => {
    this.setState({ searchValue: e.target.value })
  }

  handleSearchByChange = (event, index, value) => {
    this.setState({ searchByValue: value })
  }

  handleReset = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

  handleOpenExportExel = () => {
    const { BrandId, searchValue, searchByValue } = this.state
    this.props.apiStockFollowUpExport({
      BrandId: BrandId === 0 ? null : BrandId,
      InputSearchBy: {
        key: searchValue,
        value: searchByValue === 'common__all' ? null : searchByValue
      }
    })
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoadingGlobal, availableByParameters, availableBrands, stocksToReturn, xlsx, lastUpdateTime} = this.props
    if (isLoadingGlobal || !availableByParameters) return null
    let availableByParametersSorted
    if (availableByParameters) {
      availableByParametersSorted = availableByParameters.map(elem => {
        return ({ 'key': elem, 'value': trl[elem] })
      })
    }
    return (
      <div>
        <PageHeader
          title={trl.stofu_title}
          subtitle={trl.stofu_subtitle}
        />
        <SC.PageGutter>
          <FilterAndTableWrapper>
            <TableWrapper text={trl.common__search}>
              <BodyTable>
                <FiltersWrapper>
                  <Label>{trl.common__filter_selectBrand}</Label>
                  <SelectField
                    style={{
                      fontSize: theme.font.fontSize,
                      maxWidth: '195px',
                      height: '35px'
                    }}
                    iconStyle={{
                      margin: '0',
                      padding: '0'
                    }}
                    floatingLabelStyle={{
                      display: 'none'
                    }}
                    labelStyle={{
                      borderRadius: 0,
                      padding: '3px 30px 0 5px',
                      border: '1px solid #ced4da',
                      width: '100%',
                      height: '30px',
                      position: 'relative',
                      top: '4px',
                      lineHeight: 'inherit'
                    }}
                    underlineStyle={{
                      display: 'none'
                    }}
                    value={this.state.BrandId}
                    onChange={this.handleSelectBrandChange}
                    autoWidth={true}
                  >
                    {
                      availableBrands &&
                      availableBrands.map(brand =>
                        <MenuItem
                          key={brand.key}
                          value={brand.key}
                          primaryText={trl[brand.value] || brand.value}
                          style={{ fontSize: theme.font.fontSize }}
                        />
                      )
                    }
                  </SelectField>
                  <InputLabelWrapper>
                    <Label>{trl.common__search} :</Label>
                    <TextField
                      id="product-catalog-search-value"
                      type="text"
                      style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                    />
                    <Label>{trl.common__by} :</Label>
                  </InputLabelWrapper>
                  <InputLabelWrapper>
                    <SelectField
                      style={{
                        fontSize: theme.font.fontSize,
                        maxWidth: '195px',
                        height: '35px'
                      }}
                      iconStyle={{
                        margin: '0',
                        padding: '0'
                      }}
                      floatingLabelStyle={{
                        display: 'none'
                      }}
                      labelStyle={{
                        borderRadius: 0,
                        padding: '3px 30px 0 5px',
                        border: '1px solid #ced4da',
                        width: '100%',
                        height: '30px',
                        position: 'relative',
                        top: '4px',
                        lineHeight: 'inherit'
                      }}
                      underlineStyle={{
                        display: 'none'
                      }}
                      value={this.state.searchByValue}
                      onChange={this.handleSearchByChange}
                    >
                      {
                        availableByParametersSorted &&
                        orderBy(availableByParametersSorted, ['value'], ['asc']).map(param =>
                          <MenuItem
                            key={param.key}
                            value={param.key}
                            primaryText={param.value}
                            style={{ fontSize: theme.font.fontSize }}
                          />
                        )
                      }
                    </SelectField>
                  </InputLabelWrapper>
                  <TableRow margin={'10px 0'}>
                    <ButtonWrapper>
                      <SC.ButtonPA_M
                        onClick={this.queryData}>
                        {trl.common__filter_apply_btn}
                      </SC.ButtonPA_M>
                      <SC.ButtonR_M
                        onClick={this.handleReset}>{trl.common__filter_reset_btn}</SC.ButtonR_M>
                    </ButtonWrapper>
                  </TableRow>
                  <TableRow align={'flex-end'} margin={'10px 0'}>
                    <SC.ButtonS_S
                      onClick={this.handleOpenExportExel}>{trl.common__btn_ExportToExcel}</SC.ButtonS_S>
                    {xlsx && (
                      <a
                        target="_blank"
                        style={{ display: 'none' }}
                        href={`/excel_export/${xlsx}`}
                        ref={exportXlsx => (this.exportXlsx = exportXlsx)}
                      />
                    )}
                  </TableRow>
                </FiltersWrapper>
              </BodyTable>
            </TableWrapper>
          </FilterAndTableWrapper>
          <div>
            <LegendTableWrapper
              style={{
                fontSize: '10px !important'
              }}
            >
              <LegendTableText
                style={{
                  fontSize: '10px !important'
                }}
              >
                {trl.stofu_tableLegend_preparationTime}
              </LegendTableText>
              <LegendTableText
                style={{
                  fontSize: '10px !important'
                }}
              >
                {trl.stofu_tableLegend_qtyConsigned}
              </LegendTableText>
              <LegendTableText
                style={{
                  fontSize: '10px !important'
                }}
              >
                {trl.stofu_tableLegend_qtyAvailable}
              </LegendTableText>
            </LegendTableWrapper>
            {lastUpdateTime && (
              <span>
                {trl.common__date_updated} {dateFormat(new Date(lastUpdateTime), 'dd/mm/yy - HH:MM')}
              </span>
            )}
            <StockFollowUpTable
              data={stocksToReturn}
              isLoading={isLoadingGlobal}
              showPagination
            />
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

StockFollowUp
  .propTypes = {}

StockFollowUp
  .defaultProps = {}

const
  mapStateToProps = state => ({
    isLoadingGlobal: state.appAlerts.loading && state.appAlerts.loading.isLoading,
    ...state.stockFollowUp
  })

export default connect(mapStateToProps, {
  apiGetStockFollowUp,
  apiStockFollowUpExport
})(StockFollowUp)
