////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import { theme, H2 } from '../../styledComponents'
import { apiGetOrderArticleOFU } from '../../../actions/orders'
import OrderDetailFollowUpTable from '../Tables/OrderDetailFollowUpTable'
/////////ASSETS/////////
import ZoomIn from 'material-ui/svg-icons/action/zoom-in'

/////////STYLED/////////
const H2Styled = H2.extend`
  color: ${theme.colors.lightGrey};
  background-color: ${theme.colors.darkGrey};
  padding: 14px;
  font-size: 19px;
`
/////////STYLED/////////

class DisplayActions extends Component {
  state = {
    open: false,
    wishedExpeditionDate: this.props.row.date_Expedition_Souhaitee
  }

 	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = () => {
    if (!this.props.row._isHydrated) this.props.apiGetOrderArticleOFU(this.props.row.iD_Commande)
    window.closeDisplayActionsDialog = this.handleClose;
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    window.closeDisplayActionsDialog = undefined;
  }

 	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl, orders } = this.props
    const updated_row = !isEmpty(orders[row.iD_Commande]) ? orders[row.iD_Commande] : row
    return (
      <div>
        <ZoomIn onClick={this.handleOpen} style={{ cursor: 'pointer' }} />
        <Dialog
          contentStyle={{ maxWidth: '100vw', width: '80vw', marginLeft: '10vw', padding: 0 }}
          style={{ width: '80vw', fontSize: theme.p1.ftz, padding: 0 }}
          bodyStyle={{ overflow: 'scroll', padding: 0 }}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <H2Styled>{`${trl.ofu_lvlItems_Title} ${updated_row.iD_Commande} - ${updated_row.enseigne_Name}`}</H2Styled>
          <OrderDetailFollowUpTable row={updated_row} orderID={updated_row.iD_Commande} style={{ overflow: 'scroll' }} />
        </Dialog>
        
      </div>
    )
  }
}

DisplayActions.propTypes = {
  row: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired
}

DisplayActions.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = ({ languages, auth, orders }) => ({
  trl: languages.siteLanguage.keys,
  myRole: auth.user_role,
  orders: orders
})

export default connect(mapStateToProps, { apiGetOrderArticleOFU })(DisplayActions)
