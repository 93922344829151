////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'

const Round = ({ row }) => {
  let output = ''
  if ( Math.round(row.total_Price * 100) / 100 < 0 || row.total_Price === null || row.currencySign === null ||
       row.status.idStatus === 32 || row.status.idStatus === 22 || row.status.idStatus === 23) output = ''
  else output = Math.round(row.total_Price * 100) / 100 + ' ' + row.currencySign

 	///////////////////////////////// RENDER ///////////////////////////////////////

  return <div>{output}</div>
}

Round.propTypes = {
  row: PropTypes.object.isRequired
}

export default Round
