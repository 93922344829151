////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {isNull} from 'lodash'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'


/////////STYLED/////////
const OverElementWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const RedDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  cursor: pointer;
`
/////////STYLED/////////

class CommentHover extends Component {
  state = {
    isShow: false
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  setIsShow = () => {
    this.setState({ isShow: !this.state.isShow })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props
    return (
      <OverElementWrapper>
        { !isNull(children) && <RedDot onClick={this.setIsShow}/> }
        <Dialog open={this.state.isShow} onRequestClose={this.setIsShow} contentStyle={{ width: '500px' }}>
          { !isNull(children) && children }
        </Dialog>
      </OverElementWrapper>
    )
  }
}

CommentHover.propTypes = {
  row: PropTypes.object,
  children: PropTypes.object
}

CommentHover.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default CommentHover
