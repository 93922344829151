////////LIBRARY/////////
import fetch from 'isomorphic-fetch'
///////COMPONENTS///////
import * as c from '../constants'
import { headerGet } from '../utils/headers'
import { hashHistory } from 'react-router'
import { requestParser } from '../utils/apiUtils'
import { translate } from '../containers/HOC/Translation'

// SET MAINTENANCE
export const serverIsInMaintenance = () => {
  return {
    type: c.MAINTENANCE_CHECK,
    payload: true
  }
}

// UNSET MAINTENANCE
const serverIsNotInMaintenance = () => {
  return {
    type: c.MAINTENANCE_CHECK,
    payload: false
  }
}

// CHECKING SERVER maintenance
const checkingServerMaintenance = () => {
  return {
    type: c.MAINTENANCE_CHECK,
    payload: translate('common__maintenance_pending')
  }
}

// REDIRECT MAINTENANCE
function setMaintenanceMode(dispatch, path) {
  dispatch(serverIsInMaintenance())
  const location = hashHistory.getCurrentLocation()
  if (location.pathname !== c.maintenance) {
    hashHistory.push(path)
  }
}

export function checkMaintenanceMode(path) {
  return (dispatch) => {
    dispatch(checkingServerMaintenance())
    fetch(c.apiCheckMaintenance, headerGet)
      .then((res) => {
        if (res.status === 200 && res.statusText === 'OK') return res
        else setMaintenanceMode(dispatch, `${c.maintenance}${path}`)
      })
      .then(requestParser)
      .then((res) => {
        // if answer is anything other than pong, switch to maintenance
        if (res === 'pong') {
          serverIsNotInMaintenance()
          const location = hashHistory.getCurrentLocation()
          // ONLY if on maintenance page
          if (location.pathname === c.maintenance) {
            //if I have a path stored in my url, navigate user to that path
            if (location.query.from) hashHistory.push(location.query.from)
            else hashHistory.push(c.login)
            //else navigate user to login
          }
        } else setMaintenanceMode(dispatch, `${c.maintenance}${path}`)
      })
      .catch((err) => {
        setMaintenanceMode(dispatch, `${c.maintenance}${path}`)
      })
  }
}
