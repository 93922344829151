export default function makeid() {
  let text = ''
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let random =
    (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295).toString(36).substring(2, 15) +
    (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295).toString(36).substring(2, 15)
  for (let i = 0; i < 12; i++) text += possible.charAt(Math.floor(random * possible.length))

  return text
}
