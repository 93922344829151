////////LIBRARY/////////
import React from 'react'
import styled, { css } from 'styled-components'
///////COMPONENTS///////
import { theme, H3, H4 } from '../styledComponents'

/////////STYLED/////////
const TaxesInside = styled.div`
  margin-left: auto;
  width: 500px;
  border: 1px solid ${theme.colors.black};
  padding: 14px;
`
const TaxRowDefault = css`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`
const TaxRow = H4.extend`
  ${TaxRowDefault};
`
const TaxRowTotal = H3.extend`
  ${TaxRowDefault};
  font-weight: bold;
`
/////////STYLED/////////

const TotalOrder = ({ trl, order }) => {

 	///////////////////////////////// RENDER ///////////////////////////////////////

  const currencySign = order.currencySign;
  return (
    <TaxesInside >
    <TaxRow><span>{trl.common__articles_total_ht} :</span><span> {Math.round(order.items_Total_Price_Tax_Excl * 100) / 100}&nbsp;{currencySign}</span></TaxRow>
    <TaxRow><span>{trl.common__carrier_total_ht} :</span><span> {Math.round(order.totalTransport * 100) / 100}&nbsp;{currencySign}</span></TaxRow>
    <TaxRow><span>{trl.common__order_total_taxes} :</span><span>{Math.round(order.totalTVA * 100) / 100}&nbsp;{currencySign}</span></TaxRow>
    <TaxRow><span>{trl.common__order_total_bankcharges} :</span>{Math.round(order.totalBankCharge * 100) / 100}&nbsp;{currencySign}</TaxRow>
    <br />
    <TaxRowTotal><span>{trl.common__order_total_ht}:</span><span>{Math.round(order.totalTTC * 100) / 100}&nbsp;{currencySign}</span></TaxRowTotal>
  </TaxesInside>
  )
}

export default TotalOrder
