////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/////////STYLED/////////
const ColorItem = styled.span`
  color: ${props => (props.status_Color ? props.status_Color : 'black')};
  padding: 7px;
  font-size: 12px;
  font-weight: bold;
`
/////////STYLED/////////

class DeliveryStatus extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row = {} } = this.props
    const { status = {} } = row
    const trl = this.context.store.getState().languages.siteLanguage.keys
    return (
      <ColorItem status_Color={status.color}>
        {trl[status.traductionKey]}
      </ColorItem>
    )
  }
}

DeliveryStatus.propTypes = {
  row: PropTypes.object.isRequired
}

DeliveryStatus.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default DeliveryStatus
