////////LIBRARY/////////
import React from 'react'

const StaticVariableItemFU = ({ row }) => {

	///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div>
      <span>{`${row.itemType} / ${row.isFix ? 'F' : 'V'}`}</span>
    </div>
  )
}

StaticVariableItemFU.propTypes = {}
export default StaticVariableItemFU
