////////LIBRARY/////////
import React from 'react'
///////COMPONENTS///////
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

const MaterialUiTable = props => {

  const { adjustForCheckbox, displaySelectAll, tableHeaders, bodyData, columnIds } = props

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <Table>
      <TableHeader adjustForCheckbox={adjustForCheckbox} displaySelectAll={displaySelectAll}>
        <TableRow>
          {tableHeaders.map((title, key) => <TableHeaderColumn key={key}>{title}</TableHeaderColumn>)}
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false} showRowHover={true}>
        {bodyData.map((row, index) => (
          <TableRow key={index}>
            {columnIds.map(columnKey => <TableRowColumn key={columnKey}>{row[columnKey]}</TableRowColumn>)}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default MaterialUiTable
