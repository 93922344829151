////////LIBRARY/////////
import React, { Component } from 'react'
import { Link } from 'react-router'
///////COMPONENTS///////
import { List, ListItem } from 'material-ui/List'
import menuParts from './menuParts'
import styles from './styles'

export default class LPAdminMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { value: 1 }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {
      dropdowns,
      manageGMOptions,
      followUp,
      deliveryFollowUp,
      myAccount,
      myDocumentation,
      userList,
      dashboard,
      roles,
      logs,
      languageList,
      brandList,
      printShops,
      batPending,
      statistique,
      productCatalog,
      productionFollowUp,
      stockFollowUp,
      itemFollowUp,
      fileOrderFollowUp,
      partnershipsManagement
    } = menuParts
    const { trl } = this.props

    return (
      <div className="leftMenu">
        <List>
          <ListItem
            primaryText={trl[dashboard.text]}
            style={styles.menuItem}
            containerElement={<Link to={dashboard.link} />}
          />
          <ListItem
            primaryText={trl[productCatalog.text]}
            style={styles.menuItem}
            containerElement={<Link to={productCatalog.link} />}
          />
          <ListItem
            primaryText={trl[stockFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={stockFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[followUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={followUp.link} />}
          />
          <ListItem
            primaryText={trl[itemFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={itemFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[batPending.text]}
            style={styles.menuItem}
            containerElement={<Link to={batPending.link} />}
          />
          <ListItem
            primaryText={trl[productionFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={productionFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[deliveryFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={deliveryFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[fileOrderFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={fileOrderFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[myDocumentation.text]}
            style={styles.menuItem}
            containerElement={<Link to={myDocumentation.link} />}
          />
          <ListItem
            primaryText={trl[statistique.text]}
            style={styles.menuItem}
            containerElement={<Link to={statistique.link} />}
          />

          <ListItem
            primaryText={trl[dropdowns.myAccount.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            nestedItems={[
              <ListItem
                key={1}
                primaryText={trl[myAccount.text]}
                style={styles.menuItem}
                containerElement={<Link to={myAccount.link} />}
              />
            ]}
          />

          <ListItem
            primaryText={trl[dropdowns.administration.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            nestedItems={[
              <ListItem
                key={1}
                primaryText={trl[userList.text]}
                style={styles.menuItem}
                containerElement={<Link to={userList.link} />}
              />,
              <ListItem
                key={3}
                primaryText={trl[roles.text]}
                style={styles.menuItem}
                containerElement={<Link to={roles.link} />}
              />,
              <ListItem
                key={4}
                primaryText={trl[logs.text]}
                style={styles.menuItem}
                containerElement={<Link to={logs.link} />}
              />,
              <ListItem
                key={5}
                primaryText={trl[languageList.text]}
                style={styles.menuItem}
                containerElement={<Link to={languageList.link} />}
              />,
              <ListItem
                key={6}
                primaryText={trl[brandList.text]}
                style={styles.menuItem}
                containerElement={<Link to={brandList.link} />}
              />,
              <ListItem
                key={7}
                primaryText={trl[manageGMOptions.text]}
                style={styles.menuItem}
                containerElement={<Link to={manageGMOptions.link} />}
              />,
              <ListItem
                key={8}
                primaryText={trl[printShops.text]}
                style={styles.menuItem}
                containerElement={<Link to={printShops.link} />}
              />,
              <ListItem
                key={9}
                primaryText={trl[partnershipsManagement.text]}
                style={styles.menuItem}
                containerElement={<Link to={partnershipsManagement.link} />}
              />
            ]}
          />
        </List>
      </div>
    )
  }
}
