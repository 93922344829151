////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hashHistory } from 'react-router'
import { isEmpty, cloneDeep, map, isEqual } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
///////COMPONENTS///////
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import DatePicker from 'material-ui/DatePicker'
import Checkbox from 'material-ui/Checkbox'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import DeliveryFollowUpTable, { columnsIds } from '../../components/Sematable/Tables/DeliveryFollowUpTable'
import * as SC from '../../components/styledComponents'
import {
  DeliveriesFilterContainer,
  AdvancedSearchAndFilterContainer,
  AdvancedSearchCheckbox,
  FilterContainerStyled,
  SearchContainerStyled,
  TextLeftStyledH4,
  ResetCancelOrderContainer
} from '../../components/styledComponents/followUpStyles'
import * as c from '../../constants'
import dateFormatConverter from '../../utils/dateFormatConverter'
import { theme } from '../../components/styledComponents'
import { apiGetDeliveriesFollowUp } from '../../actions/delivery'

/////////STYLED/////////
const FormsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  width: calc(112% - 10px);
  & > * {
    flex: 1 1 25%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1460px) {
    & > * {
      flex: 1 1 100%;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
  }
`
const FormWrapped = styled.form`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  @media (max-width: 1460px) {
    justify-content: space-between;
  }
`
const FooterFilterContainerDefu = styled(ResetCancelOrderContainer)`
  display: grid;
  grid-template-columns: 45% 1fr;
  margin-top: 3%;
`
const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`
const ButtonContainer = styled.div`
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
const Label = styled.span`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  font-size: ${(props) => (props.isTitle ? '14px' : '12px')};
  margin-right: 8px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
`
const Check = styled(Checkbox)`
  width: auto !important;
`
/////////STYLED/////////

class DeliveryFollowUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedStatus: [3, 4, 8, 11],
      SearchBy: {
        key: '',
        value: 'common__all'
      },
      selectedStartCreationDate: moment(this.setSelectedStartCreationDate()).toDate(),
      selectedEndCreationDate: moment(new Date()).toDate(),
      selectedStartShippingDate: null,
      selectedEndShippingDate: null,
      selectedStartCtcConfimDate: null,
      selectedEndCtcConfimDate: null,
      seeReceptions: true,
      seeShipments: true,
      seeArchived: false
    }
    this.defaultState = cloneDeep(this.state)
  }
  // Update state

	///////////////////////////////// HANDLE ///////////////////////////////////////

  updateStateAny = (target, index, value) => {
    this.setState({
      [target]: value
    })
  }

  updateCheckBoxAny = (target, value) => {
    this.setState({
      [target]: !value
    })
  }
  // Création Date

  setSelectedStartCreationDate = () => {
    let startDate = new Date()
    startDate.setDate(startDate.getDate() - 14)
    startDate.setHours(0, 0, 0, 0)
    return new Date(startDate)
  }

  changeDateHandler = (e, date, target) => {
    this.updateStateAny(target, '', moment(date).toDate())
  }

  changeInputSearchByHandler = (event, index, value) => {
    this.setState({
      SearchBy: {
        key: this.state.SearchBy.key,
        value: value
      }
    })
  }
  changeInputSearchHandler = (event, index, value) => {
    this.setState({
      SearchBy: {
        key: index,
        value: this.state.SearchBy.value
      }
    })
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }


  onStatusChangeHandler = (filter) => () => {
    let oldState = cloneDeep(this.state.selectedStatus)

    const elemIdx = !isEmpty(this.state.selectedStatus)
      && this.state['selectedStatus'].includes(filter)
      ? this.state['selectedStatus'].findIndex((e) => e === filter)
      : -1

    elemIdx !== -1 ? oldState.splice(elemIdx, 1) : oldState.push(filter)
    this.setState((state) => ({ selectedStatus: oldState }))
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  queryData = (defaultState) => {
    const inputSearchBy = cloneDeep(this.state.SearchBy)
    inputSearchBy.value = inputSearchBy.value !== 'common__all' ? inputSearchBy.value : ''
    if (isEqual(defaultState, this.defaultState)) {
      this.props.apiGetDeliveriesFollowUp({
        selectedStatus: this.defaultState.selectedStatus,
        SearchBy: this.defaultState.inputSearchBy,
        selectedStartCreationDate: this.defaultState.selectedStartCreationDate,
        selectedEndCreationDate: this.defaultState.selectedEndCreationDate,
        selectedStartShippingDate: this.defaultState.selectedStartShippingDate,
        selectedEndShippingDate: this.defaultState.selectedEndShippingDate,
        selectedStartCtcConfimDate: this.defaultState.selectedStartCtcConfimDate,
        selectedEndCtcConfimDate: this.defaultState.selectedEndCtcConfimDate,
        seeReceptions: this.defaultState.seeReceptions,
        seeShipments: this.defaultState.seeShipments,
        seeArchived: this.defaultState.seeArchived
      })
    } else {
      this.props.apiGetDeliveriesFollowUp({
        selectedStatus: this.state.selectedStatus,
        SearchBy: inputSearchBy,
        selectedStartCreationDate: this.state.selectedStartCreationDate,
        selectedEndCreationDate: this.state.selectedEndCreationDate != this.defaultState.selectedEndCreationDate ?
          moment.parseZone(this.state.selectedEndCreationDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.selectedEndCreationDate,
        selectedStartShippingDate: this.state.selectedStartShippingDate,
        selectedEndShippingDate: this.state.selectedEndShippingDate != this.defaultState.selectedEndCreationDate ?
        moment.parseZone(this.state.selectedEndShippingDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.selectedEndShippingDate,
        selectedStartCtcConfimDate: this.state.selectedStartCtcConfimDate,
        selectedEndCtcConfimDate: this.state.selectedEndCtcConfimDate != this.defaultState.selectedEndCreationDate ?
        moment.parseZone(this.state.selectedEndCtcConfimDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.selectedEndCtcConfimDate,
        seeReceptions: this.state.seeReceptions,
        seeShipments: this.state.seeShipments,
        seeArchived: this.state.seeArchived
      })
    }
  }
  // tester avec dd update voir les datas du reducers suite a awb
  componentDidUpdate(prevProps) {
    // we test if the props has changes, in this case we can make a new call
    if (prevProps.isAWBSuccess !== this.props.isAWBSuccess) {
      const { isAWBSuccess } = this.props
      if (isAWBSuccess) {
        this.queryData()
      }
    }
  }

  componentDidMount() {
    this.queryData(this.defaultState)
  }

  handleReset = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, companyType, deliveriesFilters, deliveries, waitingForDeliveryCount } = this.props
    const isCompanyTypePS = ['PS', 'LS', 'LP'].includes(companyType)
    return (
      <div>
        <PageHeader title={trl.defu_Title} subtitle={trl.orders_pre__subtitle} />
        <SC.Sky grey>
          <SC.PageGutter>
            <DeliveriesFilterContainer>
              <TableWrapper text={trl.common__search}>
                <AdvancedSearchCheckbox>
                  {deliveriesFilters &&
                    deliveriesFilters.availableStatus &&
                    map(deliveriesFilters.availableStatus, (elem, key) => (
                      <Row key={key}>
                        <Check
                          checked={
                            !isEmpty(this.state.selectedStatus)
                              ? !!this.state['selectedStatus'].find((e) => e === elem.idStatus)
                              : false
                          }
                          onCheck={this.onStatusChangeHandler(elem.idStatus)}
                        />
                        <Label isTitle bold color={elem.color}>
                          {trl[elem.traductionKey]}
                        </Label>
                      </Row>
                    ))}
                </AdvancedSearchCheckbox>
                <AdvancedSearchAndFilterContainer>
                  {/* LEFT COLUM */}
                  <div>
                    {/* ADVANCED SEARCH AND FILTER */}
                    {/* 5-1,2 SEARCH & DOMAIN CHOICE */}
                    <SearchContainerStyled>
                      <FormsWrapper>
                        <FormWrapped
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                              e.preventDefault()
                            }
                          }}
                        >
                          <Label>{trl.common__search} :</Label>
                          <TextField
                            type="text"
                            style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                            name="searchTerm"
                            value={this.state.SearchBy.key}
                            onChange={this.changeInputSearchHandler}
                            onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                          />
                        </FormWrapped>
                        <FormWrapped>
                          <Label style={{ marginLeft: 10, width: 25 }}>{trl.common__by} :</Label>
                          <SelectField
                            style={{
                              fontSize: theme.font.fontSize,
                              maxWidth: '195px',
                              height: '35px',
                              marginTop: '-32px'
                            }}
                            iconStyle={{
                              margin: '-6px',
                              padding: '0'
                            }}
                            floatingLabelStyle={{
                              display: 'none'
                            }}
                            labelStyle={{
                              borderRadius: 0,
                              padding: '3px 30px 0 5px',
                              border: '1px solid #ced4da',
                              width: '100%',
                              height: '30px',
                              position: 'relative',
                              top: '4px',
                              lineHeight: 'inherit'
                            }}
                            underlineStyle={{
                              display: 'none'
                            }}
                            floatingLabelText={trl.common__search_by}
                            value={this.state.SearchBy.value}
                            onChange={this.changeInputSearchByHandler}
                          >
                            <MenuItem
                              style={{ fontSize: theme.font.fontSize }}
                              value="common__all"
                              primaryText={trl.common__all}
                            />
                            {columnsIds.map(
                              (c, columIndex) =>
                                c.searchable && (
                                  <MenuItem
                                    style={{ fontSize: theme.font.fontSize }}
                                    value={c.key}
                                    primaryText={c.header}
                                    key={c.key}
                                  />
                                )
                            )}
                          </SelectField>
                        </FormWrapped>
                      </FormsWrapper>
                    </SearchContainerStyled>
                  </div>
                  {/* RIGHT COLUM */}
                  <div>
                    {/* 6.1 RECEPTION */}
                    <FilterContainerStyled flex={'flex-end'} style={{ height: 46 }}>
                      <TextLeftStyledH4 style={{ fontSize: theme.font.fontSize }}>
                        {trl.common__filter_by_reception_date} :
                      </TextLeftStyledH4>
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0',
                          top: '-10px'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '25%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_from}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'selectedStartCreationDate')}
                        value={this.state.selectedStartCreationDate}
                        style={{ marginTop: -46, marginRight: 20 }}
                        formatDate={dateFormatConverter}
                        maxDate={this.state.selectedEndCreationDate}
                      />

                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0',
                          top: '-10px'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '25%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_to}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'selectedEndCreationDate')}
                        value={this.state.selectedEndCreationDate}
                        minDate={this.state.selectedStartCreationDate}
                        formatDate={dateFormatConverter}
                      />
                    </FilterContainerStyled>

                    {/* 6.2 VALIDATION */}
                    <FilterContainerStyled flex={'flex-end'} style={{ height: 42 }}>
                      <TextLeftStyledH4 style={{ fontSize: theme.font.fontSize }}>{trl.defu_filters_ShipmentDate} :</TextLeftStyledH4>
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0',
                          top: '-10px'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '25%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_from}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'selectedStartShippingDate')}
                        value={this.state.selectedStartShippingDate}
                        style={{ marginTop: 40, marginRight: 20 }}
                        formatDate={dateFormatConverter}
                        maxDate={this.state.selectedEndShippingDate}
                      />
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0',
                          top: '-10px'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '25%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_to}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'selectedEndShippingDate')}
                        value={this.state.selectedEndShippingDate}
                        minDate={this.state.selectedStartShippingDate}
                        formatDate={dateFormatConverter}
                      />
                    </FilterContainerStyled>
                    {/* 6.2 VALIDATION */}
                    <FilterContainerStyled flex={'flex-end'} style={{ height: 42 }}>
                      <TextLeftStyledH4 style={{ fontSize: theme.font.fontSize }}>
                        {trl.common__filter_by_CTCConfirmed_date} :
                      </TextLeftStyledH4>
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0',
                          top: '-10px'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '25%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_from}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'selectedStartCtcConfimDate')}
                        value={this.state.selectedStartCtcConfimDate}
                        style={{ marginTop: 40, marginRight: 20 }}
                        formatDate={dateFormatConverter}
                        maxDate={this.state.selectedEndCtcConfimDate}
                      />
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0',
                          top: '-10px'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '25%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_to}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'selectedEndCtcConfimDate')}
                        value={this.state.selectedEndCtcConfimDate}
                        minDate={this.state.selectedStartCtcConfimDate}
                        formatDate={dateFormatConverter}
                      />
                    </FilterContainerStyled>
                  </div>
                </AdvancedSearchAndFilterContainer>
                {/* CANCEL ORDER AND RESET FILTERS */}
                <FooterFilterContainerDefu>
                  {/* 5,5 SHOW CANCELLED ORDERS*/}
                  <CheckBoxContainer>
                    <div style={{ display: 'flex' }}>
                      {/* hide/show orders with idStatus= 2 or 11 */}
                      <Checkbox
                        labelStyle={{ width: 'initial', marginRight: 40 }}
                        label={trl.defu_checkBox__receptions}
                        checked={this.state.seeReceptions}
                        onCheck={() => this.updateCheckBoxAny('seeReceptions', this.state.seeReceptions)}
                      />
                      <Checkbox
                        labelStyle={{ width: 'initial' }}
                        label={trl.defu_checkBox__shipments}
                        checked={this.state.seeShipments}
                        onCheck={() => this.updateCheckBoxAny('seeShipments', this.state.seeShipments)}
                      />
                    </div>
                  </CheckBoxContainer>
                  {/* 7 RESET */}
                  <ButtonContainer>
                    <SC.ButtonPA_M style={{ marginRight: 16 }} onClick={this.queryData}>
                      {trl.common__filter_apply_btn}
                    </SC.ButtonPA_M>
                    <SC.ButtonR_M style={{ marginRight: '10px' }} onClick={this.handleReset}>
                      {trl.common__filter_reset_btn}
                    </SC.ButtonR_M>
                  </ButtonContainer>
                </FooterFilterContainerDefu>
              </TableWrapper>
            </DeliveriesFilterContainer>
          </SC.PageGutter>
        </SC.Sky>
        {/* ORDERS TABLE */}
        <SC.PageGutter>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                {isCompanyTypePS && (
                  <SC.ButtonPA_L onClick={() => hashHistory.push(`${c.waitingForDelivery}`)}>
                    {trl.defu_btn__viewPendingDeliveries}
                  </SC.ButtonPA_L>
                )}
              </div>
              <div style={{ textAlign: 'center', margin: '0 auto', paddingRight: '17%' }}>
                <h2
                  style={{
                    margin: '0',
                    color: '#ee5651',
                    textTransform: 'uppercase',
                    fontSize: 15,
                    textAlign: 'center'
                  }}
                >
                  {trl.defu_topTable_WaitingDelivery} &nbsp;&nbsp; {waitingForDeliveryCount}
                </h2>
              </div>
            </div>
            {deliveries.length > 0 ? (
              <div>
                <div id="deliveryFollowUpTable">
                  <DeliveryFollowUpTable data={deliveries} className="OrdersTable" />
                </div>
                {isCompanyTypePS && (
                  <div style={{}}>
                    <SC.ButtonPA_L onClick={() => hashHistory.push(`${c.waitingForDelivery}`)}>
                      {trl.defu_btn__viewPendingDeliveries}
                    </SC.ButtonPA_L>
                  </div>
                )}
              </div>
            ) : (
              <h3 style={{ textAlign: 'center', marginTop: 80, fontSize: 15 }}>{trl.defu_error__NoElementToDisplay}.</h3>
            )}
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ delivery, auth, apiCallStateManager, me }) {
  return {
    deliveries: delivery.deliveries,
    role: auth.user_role,
    apiCallStateManager,
    isLoading: delivery.isLoading,
    companyType: me.company_type,
    deliveriesFilters: delivery.deliveriesFilters,
    waitingForDeliveryCount: delivery.waitingForDeliveryCount,
    isTest: delivery.isTestOk,
    isAWBSuccess: delivery.isAWBSuccess
  }
}

DeliveryFollowUp.propTypes = {
  deliveries: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiGetDeliveriesFollowUp: PropTypes.func.isRequired,
  apiCallStateManager: PropTypes.object.isRequired
}
DeliveryFollowUp.defaultProps = {
  deliveries: {}
}

export default connect(mapStateToProps, { apiGetDeliveriesFollowUp })(DeliveryFollowUp)
