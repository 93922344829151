////////LIBRARY/////////
import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import TextField from 'material-ui/TextField'
import Yes from 'material-ui/svg-icons/action/done'
import Clear from 'material-ui/svg-icons/content/clear'
import CircularProgress from 'material-ui/CircularProgress'
import { requestParser, checkStatus } from '../../../utils/apiUtils'
import { headerPutWithTokenAndModel } from '../../../utils/headers'
import { styles } from '../../../containers/Forms/misc/formStyles'
import * as c from '../../../constants'

class LanguageTranslationAction extends Component {
  constructor(props) {
    super(props)
    this.state = { apiStatus: null, value: '' }
    this.handleApiCall = this.handleApiCall.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.displayApiStatus = this.displayApiStatus.bind(this)
    this.reinitializeApiState = this.reinitializeApiState.bind(this)
    this.handleApiCall = _.debounce(this.handleApiCall, 800)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    this.setState({ value: this.props.row.targetValue })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleChange(event) {
    event.persist()
    this.setState({ value: event.target.value })
  }

  handleApiCall(e) {
    const rowId = this.props.row.key
    const languageid = this.context.router.params.id
    const url = `${c.apiLanguage}${languageid}${c.apiLanguageKey}${rowId}`
    const model = { key: rowId, value: e.target.value }
    const config = headerPutWithTokenAndModel(model)
    this.setState({ apiStatus: 'pending' })
    fetch(url, config)
      .then(checkStatus)
      .then(requestParser)
      .then(res => {
        this.props.changeTargetLanguageRow(model)
        this.setState({ apiStatus: 'true' })
        this.reinitializeApiState()
      })
      .catch(err => {
        this.setState({ apiStatus: 'false' })
        this.reinitializeApiState()
      })
  }

  reinitializeApiState() {
    setTimeout(() => this.setState({ apiStatus: null }), 3000)
  }

  displayApiStatus() {
    switch (this.state.apiStatus) {
      case 'true':
        return <Yes style={styles.icon.success} />
      case 'false':
        return <Clear style={styles.icon.fail} />
      case 'pending':
        return <CircularProgress size={24} thickness={3} />
      default:
        return null
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <TextField
          id="text-field-controlled"
          value={this.state.value || ''}
          multiLine={true}
          style={{ fontSize: 12, width: 'calc(100% - 50px)' }}
          onChange={event => {
            event.persist()
            this.handleChange(event)
            this.handleApiCall(event)
          }}
        />
        <div style={{ width: 50, alignSelf: 'center', textAlign: 'center' }}>{this.displayApiStatus()}</div>
      </div>
    )
  }
}

LanguageTranslationAction.contextTypes = {
  router: PropTypes.object.isRequired
}

LanguageTranslationAction.propTypes = {
  row: PropTypes.object.isRequired
}

const CustomTable = ({ data, changeTargetLanguageRow, headerTitle }) => {
  const renderTable = rows => {
    return rows.map(row => {
      return (
        <tr key={row.key}>
          <td>{row.baseValue}</td>
          <td style={{ padding: '0 20px', color: 'grey' }}>{row.key}</td>
          <td>
            <LanguageTranslationAction
              row={{ targetValue: row.targetValue, key: row.key }}
              changeTargetLanguageRow={changeTargetLanguageRow}
            />
          </td>
        </tr>
      )
    })
  }

  return (
    <table style={{ width: '100%' }} className="table-sm table table-striped table-hover">
      <thead>
        <tr>
          <th style={{ width: '40%' }}>{headerTitle[0]}</th>
          <th style={{ padding: '0 20px' }}>{headerTitle[1]}</th>
          <th style={{ width: '50%' }}>{headerTitle[2]}</th>
        </tr>
      </thead>
      <tbody>{renderTable(data)}</tbody>
    </table>
  )
}

export default CustomTable
