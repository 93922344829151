////////LIBRARY/////////
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppShowLoading, addAppHideLoading, addAppAlert } from './appAlerts'
import { apiCarrier } from '../constants'

// GET DELIVERIES followUp
function apiGetDeliveriesFollowUpRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_DELIVERIES_FOLLOW_UP_REQUEST, endpointPath })
  }
}
function apiGetDeliveriesFollowUpSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_DELIVERIES_FOLLOW_UP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_DELIVERIES_FOLLOW_UP,
      payload: payload
    })
  }
}
function apiGetDeliveriesFollowUpFailure(endpointPath, err) {
  return { type: c.API_GET_DELIVERIES_FOLLOW_UP_FAILURE, endpointPath }
}
export function apiGetDeliveriesFollowUp(data) {
  return u.getValueFromStore(
    c.apiDeliveries,
    h.headerPutWithToken(data),
    apiGetDeliveriesFollowUpRequest,
    apiGetDeliveriesFollowUpSuccess,
    apiGetDeliveriesFollowUpFailure
  )
}

// PUT DELIVERIES WAITING followUp
function apiPutDeliveriesWaitingFollowUpRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP_REQUEST, endpointPath })
  }
}
function apiPutDeliveriesWaitingFollowUpSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP,
      payload: payload
    })
  }
}
function apiPutDeliveriesWaitingFollowUpFailure(endpointPath, err) {
  return { type: c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP_FAILURE, endpointPath }
}
export function apiPutDeliveriesWaitingFollowUp(data) {
  return u.getValueFromStore(
    c.apiDeliveriesWaiting,
    h.headerPutWithToken(data),
    apiPutDeliveriesWaitingFollowUpRequest,
    apiPutDeliveriesWaitingFollowUpSuccess,
    apiPutDeliveriesWaitingFollowUpFailure
  )
}

// GET DELIVERY
const apiGetDeliveryRequest = (endpointPath) => ({
  type: c.API_GET_DELIVERY_REQUEST,
  endpointPath
})
const apiGetDeliverySuccess = (endpointPath, res) => ({
  type: c.API_GET_DELIVERY_SUCCESS,
  payload: res
})
const apiGetDeliveryFailure = (endpointPath, err) => {
  return {
    type: c.API_GET_DELIVERY_FAILURE,
    endpointPath
  }
}
export const apiGetDelivery = (idDelivery) =>
  u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}`,
    h.headerGetWithToken(),
    apiGetDeliveryRequest,
    apiGetDeliverySuccess,
    apiGetDeliveryFailure
  )

// CREATE DELIVERY
const apiCreateDeliveryRequest = (endpointPath) => {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_CREATE_DELIVERY_REQUEST, endpointPath })
  }
}
const apiCreateDeliverySuccess = (endpointPath, res) => {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_CREATE_DELIVERY_SUCCESS, payload: res })
    hashHistory.push(`${c.delivery}${res.idDeliveryJPI}`)
  }
}
const apiCreateDeliveryFailure = (endpointPath, err) => {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch(addAppAlert(err.message, true))
    dispatch({ type: c.API_CREATE_DELIVERY_FAILURE, endpointPath })
  }
}
export const apiCreateDelivery = (idDelivery) =>
  u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.apiCreate}`,
    h.headerPutWithToken(),
    apiCreateDeliveryRequest,
    apiCreateDeliverySuccess,
    apiCreateDeliveryFailure
  )

// CANCEL DELIVERY
function apiCancelDeliveryRequest(endpointPath) {
  return { type: c.API_CANCEL_DELIVERY_REQUEST, endpointPath }
}
function apiCancelDeliverySuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppAlert('message success', true))
    dispatch({ type: c.API_CANCEL_DELIVERY_SUCCESS, payload: res })
  }
}
function apiCancelDeliveryFailure(endpointPath, err) {
  return { type: c.API_CANCEL_DELIVERY_FAILURE, endpointPath }
}
export function apiCancelDelivery({ idDelivery }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.apiCancel}`,
    h.headerPutWithToken(),
    apiCancelDeliveryRequest,
    apiCancelDeliverySuccess,
    apiCancelDeliveryFailure
  )
}

// DELIVERY PRINT
function apiDeliveryPrintPSRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_DELIVERY_PRINT_PREPARATION_SHEET_REQUEST, endpointPath })
  }
}
function apiDeliveryPrintPSSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELIVERY_PRINT_PREPARATION_SHEET_SUCCESS, payload: res })
  }
}
function apiDeliveryPrintPSFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELIVERY_PRINT_PREPARATION_SHEET_FAILURE, endpointPath })
  }
}
export const apiDeliveryPrintPS = (idDelivery) => {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.apiDeliveryPrintPS}`,
    h.headerPutWithToken(),
    apiDeliveryPrintPSRequest,
    apiDeliveryPrintPSSuccess,
    apiDeliveryPrintPSFailure
  )
}

// PRINT INVOICE
function apiDeliveryPrintInvoiceRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_DELIVERY_PRINT_INVOICE_REQUEST, endpointPath })
  }
}
function apiDeliveryPrintInvoiceSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELIVERY_PRINT_INVOICE_SUCCESS, payload: res })
  }
}
function apiDeliveryPrintInvoiceFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELIVERY_PRINT_INVOICE_FAILURE, endpointPath })
  }
}
export const apiDeliveryPrintInvoice = (idDelivery) => {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.apiDeliveryPrintInvoice}`,
    h.headerPutWithToken(),
    apiDeliveryPrintInvoiceRequest,
    apiDeliveryPrintInvoiceSuccess,
    apiDeliveryPrintInvoiceFailure
  )
}

// AWB DELIVERY followUp
function apiAwpDeliveryRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading(true))
    dispatch({ type: c.API_AWB_DELIVERY_REQUEST, endpointPath })
  }
}
function apiAwpDeliverySuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_AWB_DELIVERY_SUCCESS, payload: res })
  }
}
function apiAwpDeliveryFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_AWB_DELIVERY_FAILURE, endpointPath, payload: err })
  }
}
export function apiAwpDelivery({ idDelivery, inputModel }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.apiConfirm}`,
    h.headerPutWithTokenAndModel(inputModel),
    apiAwpDeliveryRequest,
    apiAwpDeliverySuccess,
    apiAwpDeliveryFailure
  )
}

// PUT DELIVERY PACKINGLIST save&print
function apiSavePrintPackingListRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_SAVE_PRINT_PACKING_LIST_REQUEST, endpointPath })
  }
}
function apiSavePrintPackingListSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_SAVE_PRINT_PACKING_LIST_SUCCESS, payload: res })
  }
}
function apiSavePrintPackingListFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_SAVE_PRINT_PACKING_LIST_FAILURE, endpointPath, payload: err })
  }
}
export function apiSavePrintPackingList({ idDelivery }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglistSavePrint}`,
    h.headerPutWithTokenAndModel(),
    apiSavePrintPackingListRequest,
    apiSavePrintPackingListSuccess,
    apiSavePrintPackingListFailure
  )
}
// PUT DELIVERY PACKINGLIST
function apiUpdatePackingListRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_UPDATE_PACKING_LIST_REQUEST, endpointPath })
  }
}
function apiUpdatePackingListSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_PACKING_LIST_SUCCESS, payload: res })
  }
}
function apiUpdatePackingListFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_PACKING_LIST_FAILURE, endpointPath })
  }
}
export function apiUpdatePackingList({ idDelivery, inputModel, idContainer }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglist}${c.container}${idContainer}`,
    h.headerPutWithTokenAndModel(inputModel),
    apiUpdatePackingListRequest,
    apiUpdatePackingListSuccess,
    apiUpdatePackingListFailure
  )
}

// DELETE PACKINGLIST
function apiDeletePackingListRequest(endpointPath) {
  return { type: c.API_DELETE_PACKING_LIST_REQUEST, endpointPath }
}
function apiDeletePackingListSuccess(endpointPath, res) {
  return { type: c.API_DELETE_PACKING_LIST_SUCCESS, payload: res }
}
function apiDeletePackingListFailure(endpointPath, err) {
  return { type: c.API_DELETE_PACKING_LIST_FAILURE, endpointPath }
}
export function apiDeletePackingList({ idDelivery }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglistPackRemove}`,
    h.headerDeleteWithToken(),
    apiDeletePackingListRequest,
    apiDeletePackingListSuccess,
    apiDeletePackingListFailure
  )
}

// DELETE PACKINGLIST box
function apiDeletePackingListBoxRequest(endpointPath) {
  return { type: c.API_DELETE_PACKING_LIST_BOX_REQUEST, endpointPath }
}
function apiDeletePackingListBoxSuccess(endpointPath, res) {
  return { type: c.API_DELETE_PACKING_LIST_BOX_SUCCESS, payload: res }
}
function apiDeletePackingListBoxFailure(endpointPath, err) {
  return { type: c.API_DELETE_PACKING_LIST_BOX_FAILURE, endpointPath }
}
export function apiDeletePackingListBox({ idDelivery, idContainer }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglistBox}${idContainer}${c.packinglistRemove}`,
    h.headerDeleteWithToken(),
    apiDeletePackingListBoxRequest,
    apiDeletePackingListBoxSuccess,
    apiDeletePackingListBoxFailure
  )
}

// DELETE  PACKINGLIST box lignes articles
function apiDeletePackingListBoxItemRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_DELETE_PACKING_LIST_BOX_ITEM_REQUEST, endpointPath })
  }
}
function apiDeletePackingListBoxItemSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELETE_PACKING_LIST_BOX_ITEM_SUCCESS, payload: res })
  }
}
function apiDeletePackingListBoxItemFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELETE_PACKING_LIST_BOX_ITEM_FAILURE, endpointPath })
  }
}
export function apiDeletePackingListBoxItem({ idDelivery, idContainer, idContainerItem }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglistBox}${idContainer}${c.packinglistItem}${idContainerItem}${c.packinglistRemove}`,
    h.headerDeleteWithToken(),
    apiDeletePackingListBoxItemRequest,
    apiDeletePackingListBoxItemSuccess,
    apiDeletePackingListBoxItemFailure
  )
}

// ADD PACKINGLIST add box
function apiAddPackingListBoxRequest(endpointPath) {
  return { type: c.API_ADD_PACKING_LIST_BOX_REQUEST, endpointPath }
}
function apiAddPackingListBoxSuccess(endpointPath, res) {
  return { type: c.API_ADD_PACKING_LIST_BOX_SUCCESS, payload: res }
}
function apiAddPackingListBoxFailure(endpointPath, err) {
  return { type: c.API_ADD_PACKING_LIST_BOX_FAILURE, endpointPath }
}
export function apiAddPackingListBox({ idDelivery }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglistBoxNew}`,
    h.headerPutWithToken(),
    apiAddPackingListBoxRequest,
    apiAddPackingListBoxSuccess,
    apiAddPackingListBoxFailure
  )
}

// SAVE PACKINGLIST add box
function apiSavePackingListRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_SAVE_PACKING_LIST_REQUEST, endpointPath })
  }
}
function apiSavePackingListSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_SAVE_PACKING_LIST_SUCCESS, payload: res })
  }
}
function apiSavePackingListFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_SAVE_PACKING_LIST_FAILURE, endpointPath })
  }
}
export function apiSavePackingList({ idDelivery }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglist}`,
    h.headerPutWithTokenAndModel(),
    apiSavePackingListRequest,
    apiSavePackingListSuccess,
    apiSavePackingListFailure
  )
}

// ADD ITEM PACKINGLIST
function apiAddItemPackingListRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_ADD_ITEM_PACKING_LIST_REQUEST, endpointPath })
  }
}
function apiAddItemPackingListSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_ADD_ITEM_PACKING_LIST_SUCCESS, payload: res })
  }
}
function apiAddItemPackingListFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_ADD_ITEM_PACKING_LIST_FAILURE, endpointPath })
  }
}
export function apiAddItemPackingList({ idDelivery, inputModel, idBox }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglistBox}${idBox}${c.packinglistAdditems}`,
    h.headerPutWithTokenAndModel(inputModel),
    apiAddItemPackingListRequest,
    apiAddItemPackingListSuccess,
    apiAddItemPackingListFailure
  )
}

// IS AWB
export function setIsAWBTrue() {
  return (dispatch) => {
    dispatch({ type: c.SET_IS_AWB_TRUE })
  }
}
export function setIsAWBFalse() {
  return (dispatch) => {
    dispatch({ type: c.SET_IS_AWB_FALSE })
  }
}

// DUPLICATE PACKINGLIST
function apiDuplicatePackingListBoxRequest(endpointPath) {
  return { type: c.API_ADD_PACKING_LIST_BOX_REQUEST, endpointPath }
}
function apiDuplicatePackingListBoxSuccess(endpointPath, res) {
  return { type: c.API_ADD_PACKING_LIST_BOX_SUCCESS, payload: res }
}
function apiDuplicatePackingListBoxFailure(endpointPath, err) {
  return { type: c.API_ADD_PACKING_LIST_BOX_FAILURE, endpointPath }
}
export function apiDuplicatePackingListBox({ idDelivery, idBox }) {
  return u.getValueFromStore(
    `${c.apiDelivery}${idDelivery}${c.packinglist}${c.container}${idBox}${c.duplicate}`,
    h.headerPutWithToken(),
    apiDuplicatePackingListBoxRequest,
    apiDuplicatePackingListBoxSuccess,
    apiDuplicatePackingListBoxFailure
  )
}

// GET CARRIER DATA
function apiGetCarrierDataRequest(endpointPath) {
  return { type: c.API_GET_CARRIER_DATA_REQUEST, endpointPath }
}
function apiGetCarrierDataSuccess(endpointPath, res) {
  console.log('RES_CARRIER', res)
  return { type: c.API_GET_CARRIER_DATA_SUCCESS, payload: res }
}
function apiGetCarrierDataFailure(endpointPath, err) {
  return { type: c.API_GET_CARRIER_DATA_FAILURE, endpointPath }
}
export function apiGetCarrierData() {
  return u.getValueFromStore(
    `${c.apiCarrier}`,
    h.headerGetWithToken(),
    apiGetCarrierDataRequest,
    apiGetCarrierDataSuccess,
    apiGetCarrierDataFailure
  )
}

