////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
/////////ASSETS/////////
import WarningMatUI from 'material-ui/svg-icons/alert/warning'
import ErrorMatUI from 'material-ui/svg-icons/alert/error'

/////////STYLED/////////
const style = {
  marginRight: '5px',
}
const ErrorWrapper = styled.div`
  display:flex;
  align-items:center;
`
/////////STYLED/////////

///////////////////////////////// RENDER ///////////////////////////////////////

const ErrorInfo = ({ children }) => (
  <ErrorWrapper>
    {children === 'Error' ? <ErrorMatUI style={style} className={'red-error'} /> : <WarningMatUI style={style} className={'yellow-warning'} />}
    {children === 'Error' ? 'Error' : 'Info'}
  </ErrorWrapper>
)

ErrorInfo.propTypes = {
  children: PropTypes.number.isRequired
}
export default ErrorInfo
