import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PageHeader } from '../../components/sharedComponents'
import { ButtonPA_M, PageGutter } from '../../components/styledComponents'
import { apiGetCompanyFileOrderFormats, apiAddStandardFixed } from '../../actions/companies'
import FileFormatsTable from './FileFormatsTable'


class BrandEditFileFormats extends Component {
  constructor(props) {
    super(props)

    this.props.apiGetCompanyFileOrderFormats(props.routeParams.id)
  }

  addStandardFixed = () => {
    this.props.apiAddStandardFixed(this.props.routeParams.id);
  }

  render() {
    const { trl, companies, isLoading } = this.props

    const company = companies && companies[this.props.routeParams.id]

    if (isLoading || !company || !company.fileOrderFormats) {
      return null
    }

    const data = company.fileOrderFormats.map(f => ({
      ...f,
      isEnabledText: f.isEnabled ? trl.common__yes : trl.common__no
    }))

    return (
      <div>
        <PageHeader title={trl.fileOrderFormats_Title + company.companyName} subtitle={trl.fileOrderFormats_SubTitle} />
        <PageGutter>
          <FileFormatsTable
            trl={trl}
            data={data}
            company={company}
          />
          {!company.hasStandardFixed && (
            <ButtonPA_M
              style={{ marginTop: 20 }}
              onClick={this.addStandardFixed}
            >
              {trl.fileOrderFormats_btn_AddStandardFixed}
            </ButtonPA_M>
          )}
        </PageGutter>
      </div>
    )
  }
}

BrandEditFileFormats.propTypes = {
  trl: PropTypes.object.isRequired,
  companies: PropTypes.object,
  isLoading: PropTypes.bool,
  isAddStandardFixedLoading: PropTypes.bool,
}

export default connect(
  state => ({
    companies: state.companies,
    isLoading: state.loading.fileOrderFormats,
    isAddStandardFixedLoading: state.loading.addStandardFixed
  }),
  {
    apiGetCompanyFileOrderFormats,
    apiAddStandardFixed
  }
)(BrandEditFileFormats)