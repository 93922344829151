////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import * as SC from '../../components/styledComponents'
import EditFileFormat from './EditFileFormat'

const config = {
  defaultPageSize: 5000000000,
  showPageSize: false,
  showFilter: false
}

const columns = (trl, company) => ([
  { key: 'code', header: trl.fileOrderFormats_tableTitle_formatCode, primaryKey: true },
  { key: 'description', header: trl.fileOrderFormats_tableTitle_formatDescription },
  { key: 'version', header: trl.fileOrderFormats_tableTitle_formatVersion },
  { key: 'extension', header: trl.fileOrderFormats_tableTitle_formatExtention },
  { key: 'isEnabledText', header: trl.fileOrderFormats_tableTitle_formatIsEnabled },
  { key: 'actions', header: trl.fileOrderFormats_tableTitle_action, Component: EditFileFormat },
])

class FileFormatsTable extends Component {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, company, data } = this.props
    const extendedData = data.map(d => ({ ...d, trl, company }));
    return (
      <SC.TableContainer>
        <Table {...this.props} data={extendedData} columns={columns(trl, company)} />
      </SC.TableContainer>
    )
  }
}

FileFormatsTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  trl: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

export default sematable('fileFormatsTable', FileFormatsTable, columns({}), config)
