////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { isUndefined, isEmpty } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import WiredSelectField from '../../../components/misc/WiredSelectField'
import Paper from 'material-ui/Paper'
import CircularProgress from 'material-ui/CircularProgress'
import FlatButton from 'material-ui/FlatButton'
import Checkbox from 'material-ui/Checkbox'
import PersonAdd from 'material-ui/svg-icons/social/person-add'
import Help from 'material-ui/svg-icons/action/help'
import { login } from '../../../actions/auth'
import { fetchLanguages, setSiteLanguageUsingId, setPreferredLanguage, changeTargetLanguageRow } from '../../../actions/languages'
import { checkMaintenanceMode } from '../../../actions/maintenance'
import { renderInput } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import { selectFieldInitialValue } from './helper'
import * as c from '../../../constants'
import { theme } from '../../../components/styledComponents'
import * as SC from '../../../components/styledComponents'
import { translate } from '../../HOC/Translation'
/////////ASSETS/////////
import CTCLOGO from '../../../assets/ctc-logo-150.png'

/////////STYLED/////////
const Logo = styled.img`
  background-color: black;
  width: 200px;
  display: block;
  margin: 40px auto 30px;
  padding: 10px;
  height: 55px;
  image-rendering: high-quality;
`
/////////STYLED/////////

const validate = (values, props) => {
  const errors = {}
  //if(!isUndefined(props.error)){
  //if(props.error != 'This user account is locked. Please contact the customer service to unlock it.') {
    if (isUndefined(values.email) || isEmpty(values.email)) errors.email = translate('login__email_required_error') || ' '
    else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && !isEmpty(values.email)) errors.email = translate('login__email_invalid_error') || ' '
    else errors.email = null
    if (isUndefined(values.password) || isEmpty(values.password)) errors.password = translate('login__password_required_error') || 'Password is Required To Login'
    else errors.password = null
  //}
  return errors
}

class Login extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const { initialValues, initialize, checkMaintenanceMode, location, fetchLanguages } = this.props
    const path = location.pathname
    //check if server is under maintenance
    checkMaintenanceMode(`?from=${path}`)
    // automatically fill user email if redirected from /user/confirm or /reset
    initialize(initialValues)
    // get languages for selector if not in state
    fetchLanguages()
  }

  componentWillReceiveProps(nextProps) {
    const { router } = this.context
    if (nextProps.user_isAuthenticated) {
      // logged in, let's show redirect if any, or show home
      try {
        const redirect = this.props.location.query.redirect
        router.replace(redirect)
      } catch (err) {
        router.replace('/')
      }
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  displayConfirmMessage() {
    const { query } = this.context.router.location
    // const { trl } = this.props
    // if redirected from /request-account
    if (query.from === `${c.requestAccount}`) return <p style={styles.instruction}>{translate('register__success_text')}</p>
    // if redirected from confirm user
    if (query.from === `${c.userConfirm}/`) {
      if (this.props.me.emailConfirmed) return <p style={styles.instruction}>{translate('login__email_confirm_success')}</p>
      else return <p style={styles.instruction}>{translate('login__email_confirm_error')}</p>
    }
    // if redirected from user/reset
    if (query.from === `${c.resetPassword}/`) {
      if (this.props.me.emailConfirmed) {
        return <p style={styles.instruction}>{translate('login__password_reset_success')}</p>
      } else {
        return <p style={styles.instruction}>{translate('login__password_reset_error')}</p>
      }
    }
    // if redirected from user/my-account in the case of reseting password
    if (query.from === `${c.myAccount}`) return <p style={styles.instruction}>{translate('login__password_reset_from_account_success')}</p>
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(f) {
    if(isUndefined(f.password) || isUndefined(f.email)) return
    return this.props.login(f.email, f.password)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { error, handleSubmit, submitting, trl, languages, setSiteLanguageUsingId, setPreferredLanguage } = this.props
    if(isEmpty(trl)) {
      this.props.fetchLanguages()
    }

    const AlerteMessage = translate('login_infoMessage_message') &&
      <SC.LoginMessageAlerte>
        <SC.TextAlerte>{translate('login_infoMessage_message')}</SC.TextAlerte>
      </SC.LoginMessageAlerte>

    return (
      <div style={styles.loginContainer}>
        {AlerteMessage}
        <h1 style={styles.question}>{translate('login__title')}</h1>
        <h2 style={styles.description}>{translate('login__subtitle') || translate('login_form__provideCredentials')}</h2>
        <Paper style={styles.paper}>
          <div>
            <Logo src={CTCLOGO} alt="CTC logo" />
          </div>
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            {this.displayConfirmMessage()}
            <Field style={{ fontSize: theme.font.fontSize, textTransform: 'capitalize' }} 
                   name="email"
                   type="email" 
                   component={renderInput} 
                   label={translate('login__email')} />
            <Field style={{ fontSize: theme.font.fontSize, textTransform: 'capitalize', paddingTop: '5px' }} 
                   name="password" 
                   type="password" 
                   component={renderInput} 
                   label={translate('login__password') } />
            <div style={{paddingTop: '20px'}}>
              <Checkbox
                label={translate('login__remember')}
                style={styles.checkRemember.style}
                labelStyle={styles.checkRemember.labelStyle}
                iconStyle={styles.checkRemember.iconStyle}
              />
              {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
              <SC.ButtonPA_L
                style={{
                  float: 'right',
                  'margin': '1rem 0'
                }}
                disabled={submitting}
              >
                {translate('login__login_btn')}
              </SC.ButtonPA_L>
            </div>
            <div>{error && <p style={styles.loginError}>{error}</p>}</div>
          </form>
          <WiredSelectField
            fields={languages.length ? languages.filter(l => l.enabled) : languages}
            floatingLabelText={translate('login__language')}
            initialValue={selectFieldInitialValue()}
            functionOnChange={[setSiteLanguageUsingId, setPreferredLanguage]}
            value={'languageid'}
            primaryText={'localName'}
            className="wiredselectfield-login"
          />
        </Paper>
        <div style={styles.buttonsDiv}>
          <FlatButton
            label={translate('common__register') || c.stRegister}
            labelStyle={{fontSize: 12}}
            href={`/#${c.requestAccount}`}
            style={styles.flatButton}
            icon={<PersonAdd />}
          />
          <FlatButton
            label={translate('common__forgot_password') || c.stForgotPassword}
            labelStyle={{fontSize: 12}}
            href={`/#${c.forgotPassword}`}
            style={styles.flatButton}
            icon={<Help />}
          />
        </div>
      </div>
    )
  }
}

Login.contextTypes = {
  router: PropTypes.object.isRequired
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  me: PropTypes.object,
  languages: PropTypes.array.isRequired,
  trl: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired
}

function mapStateToProps({ auth, me, languages }) {
  const { user_isAuthenticated, loginError } = auth
  if (auth) {
    return {
      user_isAuthenticated,
      loginError,
      me,
      languages: languages.languages
    }
  }
  return {
    user_isAuthenticated: null,
    loginError,
    me,
    languages: languages.languages,
    initialValues: me
  }
}

Login = reduxForm({
  form: 'login',
  validate,
  touchOnBlur: false,
  errors: {}
})(Login)

export default connect(mapStateToProps, {
  checkMaintenanceMode,
  fetchLanguages,
  setSiteLanguageUsingId,
  setPreferredLanguage,
  login,
  changeTargetLanguageRow
})(Login)
