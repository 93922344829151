////////LIBRARY/////////
import React, { Component } from 'react'
import { Link } from 'react-router'
///////COMPONENTS///////
import { List, ListItem } from 'material-ui/List'
import menuParts from './menuParts'
import styles from './styles'

export default class FACMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { value: 1 }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {
      dropdowns,
      dashboard,
      followUp,
      myAccount,
      myDocumentation,
      orderNew,
      myCarriers,
      myAddresses,
      batPending,
      productCatalog,
      itemFollowUp,
    } = menuParts
    const { trl } = this.props

    return (
      <div className="leftMenu">
        <List>
          <ListItem
            primaryText={trl[dashboard.text]}
            style={styles.menuItem}
            containerElement={<Link to={dashboard.link} />}
          />
          <ListItem
            primaryText={trl[productCatalog.text]}
            style={styles.menuItem}
            containerElement={<Link to={productCatalog.link} />}
          />
          <ListItem
            primaryText={trl[orderNew.text]}
            // leftIcon={orderNew.icon}
            style={styles.menuItem}
            containerElement={<Link to={orderNew.link} />}
          />

          {/* FOLLOW UP */}
          <ListItem
            primaryText={trl[followUp.text]}
            // leftIcon={followUp.icon}
            style={styles.menuItem}
            containerElement={<Link to={followUp.link} />}
          />
          <ListItem
            primaryText={trl[itemFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={itemFollowUp.link} />}
          />

          <ListItem
            primaryText={trl[batPending.text]}
            style={styles.menuItem}
            containerElement={<Link to={batPending.link} />}
          />
          <ListItem
            primaryText={trl[myDocumentation.text]}
            style={styles.menuItem}
            containerElement={<Link to={myDocumentation.link} />}
          />
          <ListItem
            primaryText={trl[dropdowns.myAccount.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            nestedItems={[
              <ListItem
                key={1}
                primaryText={trl[myAccount.text]}
                style={styles.menuItem}
                containerElement={<Link to={myAccount.link} />}
              />
            ]}
          />

          <ListItem
            primaryText={trl[dropdowns.company.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            innerDivStyle={{ paddingLeft: 0 }}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            nestedItems={[
              <ListItem
                key={4}
                primaryText={trl[myCarriers.text]}
                style={styles.menuItem}
                containerElement={<Link to={myCarriers.link} />}
              />,
              <ListItem
                key={5}
                primaryText={trl[myAddresses.text]}
                style={styles.menuItem}
                containerElement={<Link to={myAddresses.link} />}
              />
            ]}
          />
        </List>
      </div>
    )
  }
}
