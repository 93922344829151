import * as c from '../constants'
import { findIndex, cloneDeep } from 'lodash'

const initialState = {
  filtersSettings: null,
  productionReturn: null,
  xlsx: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API_GET_PRODUCTION_FOLLOW_UP:
      action.payload.filtersSettings.availableByParameters.splice(0, 0, 'common__all')
      action.payload.filtersSettings.availableBrands.splice(0, 0, { key: 0, value: 'common__all' })
      action.payload.filtersSettings.availableLabellists.splice(0, 0, { key: 0, value: 'common__all' })
      action.payload.filtersSettings.availablePrintShops.splice(0, 0, { key: 0, value: 'common__all' })
      return {
        ...state,
        ...action.payload
      }
    case c.API_PRODUCTION_FOLLOW_UP_RESET_OP:
      const oldOP = cloneDeep(state.productionReturn)
      const newOP = action.payload
      const idxOP = findIndex(oldOP, { iD_Commande_Article: newOP.iD_Commande_Article })
      oldOP[idxOP] = newOP
      return {
        ...state,
        productionReturn: oldOP
      }
    case c.API_PRODUCTION_FOLLOW_UP_CLOSE_OP:
      const oldOp = cloneDeep(state.productionReturn)
      const newOp = action.payload
      const idxOp = findIndex(oldOp, { iD_Commande_Article: newOp })
      if (idxOp > -1) {
        oldOp.splice(idxOp, 1)
      }
      return {
        ...state,
        productionReturn: oldOp
      }
    case c.API_EXPORT_XL_PRODUCTION_FOLLOW_UP_REQUEST:
      return {
        ...state,
        xlsx: null
      }
    case c.API_EXPORT_XL_PRODUCTION_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        xlsx: action.payload
      }
    default:
  }
  return state
}
