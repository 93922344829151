////////LIBRARY/////////
import fetch from 'isomorphic-fetch'
import { SubmissionError } from 'redux-form'
///////COMPONENTS///////
import { setItem, removeItem } from '../utils/cookiesHelper'
import {
  ID_TOKEN,
  setIdToken,
  setLocalStorage,
  removeIdToken,
  removeLocalStorage,
  decodeUserProfile,
  getLocalStorage
} from '../utils/apiUtils'
import { user_id, user_name, user_role } from '../reducers/auth'
import * as h from '../utils/headers'
import * as c from '../constants'
import * as u from '../utils/apiUtils'

// RENEWTOKEN
function renewTokenRequest(endpointPath) {
  return { type: c.RENEWTOKEN_REQUEST, endpointPath }
}

function renewTokenSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.RENEWTOKEN_SUCCESS, endpointPath })
    dispatch(loginSuccess(endpointPath, { [ID_TOKEN]: res }))
  }
}

function renewTokenFailure(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.RENEWTOKEN_FAILURE, endpointPath })
    dispatch(logout())
  }
}

// LOGIN
export function loginRequest(endpointPath) {
  return {
    type: c.LOGIN_REQUEST,
    endpointPath
  }
}

export function loginSuccess(endpointPath, payload) {
  return (dispatch) => {
    const idToken = payload[ID_TOKEN]
    const profile = decodeUserProfile(idToken)
    //note: taking role from JWT instead of role because of bug from server framerwork, which doesn't assign user roles correctly
    const { nameid, unique_name, role } = profile
    setIdToken(idToken)
    setLocalStorage(user_id, nameid)
    setLocalStorage(user_name, unique_name)
    setLocalStorage(user_role, role)
    setItem(ID_TOKEN, idToken)
    setItem(user_id, nameid)
    setItem(user_name, unique_name)
    setItem(user_role, role)
    dispatch({ type: c.LOGIN_SUCCESS, endpointPath })
    dispatch({
      type: c.LOGIN,
      [user_id]: nameid,
      [user_name]: unique_name,
      [user_role]: role
    })
  }
}

function loginFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(logout())
    dispatch({ type: c.LOGIN_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}

export function login(e, p) {
  const email = u.encodeUrlBase64(e)
  const config = h.headerAuthLogin(email, p)
  return u.getValueFromStore(c.apiToken, config, loginRequest, loginSuccess, loginFailure, true)
}

// RELOGIN
export function reLogin(token) {
  return u.getValueFromStore(
    c.apiTokenRenew,
    h.headerPutWithTokenAndModel(`${token}`),
    renewTokenRequest,
    renewTokenSuccess,
    renewTokenFailure,
    false
  )
}

// LOGOUT
export function logout() {
  const url = `${c.apiUser}${getLocalStorage(user_id)}${c.apiUserLogout}`
  fetch(url, h.headerPutWithToken())
  removeIdToken()
  removeLocalStorage(user_id)
  removeLocalStorage(user_name)
  removeLocalStorage(user_role)
  removeItem(user_id)
  removeItem(user_name)
  removeItem(user_role)
  removeItem(ID_TOKEN)
  return {
    type: c.LOGOUT
  }
}
