////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import UsersActions from '../Actions/UsersActions'
import YesNo from '../ColumnHelpers/YesNo'
import YesNoOpposite from '../ColumnHelpers/YesNoOpposite'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation';
import theme from '../../styledComponents/theme'
import NoDataComponent from '../NoDataComponent'

const columns = [
  { key: 'id', header: 'ID', hidden: true, primaryKey: true },
  { key: 'fullName', header: translate("admin_users_tableTitle_name"), sortable: true, searchable: true },
  { key: 'email', header: translate("admin_users_tableTitle_email"), sortable: true, searchable: true },
  { key: 'emailConfirmed', header: translate("admin_users_tableTitle_emailConfirmed"), sortable: true, Component: YesNo },
  { key: 'phoneNumbePrefix', header: translate("admin_users_tableTitle_prefix"), searchable: true },
  { key: 'phoneNumber', header: translate("admin_users_tableTitle_phone"), searchable: true },
  { key: 'lockoutEnabled', header: translate("admin_users_tableTitle_active"), sortable: true, Component: YesNoOpposite },
  { key: 'role', header: translate("admin_users_tableTitle_role"), searchable: true, sortable: true },
  { key: 'company_name', header: translate("admin_users_tableTitle_company"), searchable: true, sortable: true },
  { key: 'actions', header: translate("admin_users_tableTitle_actions"), Component: UsersActions }
]

const config = {
  defaultPageSize: 50
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

class UsersTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <TableContainer>
        <Table style={{fontSize: theme.font.fontSize}} {...this.props} columns={columns} NoDataComponent={NoDataComponent}/>
      </TableContainer>
    )
  }
}

UsersTable.propTypes = propTypes
export default sematable('usersTables', UsersTable, columns, config)
