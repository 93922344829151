import { cloneDeep, findIndex, isUndefined, isNull } from 'lodash'
import * as c from '../constants'
import { getLocalStorage } from '../utils/apiUtils'

const INITIAL_STATE = {
  partnerships: [],
  partnershipsFilterInputModel: [],
  xlsx: null,
  isLoading: true,
  error: null
}

export default function (state = INITIAL_STATE, action) {
  // in case of reset filter we need for brand user to set select field with the brand name
  // for that we have to check user role and make a test
  const userRole = getLocalStorage('user_role')
  let isBrand = false
  switch (userRole) {
    case 'EAC':
      isBrand = true
      break
    case 'EAdmin':
      isBrand = true
      break
    default:
      break
  }

  switch (action.type) {
    case c.API_GET_PARTNERSHIPS_MANAGEMENT:
      if (isNull(action.payload.partnershipsFilterInputModel.idBrand) || !isBrand) {
        action.payload.partnershipsFilterInputModel.availableBrands.splice(0, 0, {
          key: 0,
          value: 'common__pleaseSelect'
        })
      } else {
        action.payload.partnershipsFilterInputModel.availableBrands[0].key = 0
      }
      action.payload.partnershipsFilterInputModel.availableByParameters.splice(0, 0, 'common__all')
      return {
        ...state,
        partnerships: action.payload.partnerships,
        partnershipsFilterInputModel: action.payload.partnershipsFilterInputModel,
        isLoading: false
      }
    case c.API_GET_PARTNERSHIPS_MANAGEMENT_REQUEST:
      return { ...state, error: null, isLoading: true }
    case c.API_GET_PARTNERSHIPS_MANAGEMENT_SUCCESS:
      return { ...state, isLoading: false }
    case c.API_GET_PARTNERSHIPS_MANAGEMENT_FAILURE:
      return { ...state, isLoading: false }

    case c.API_EXPORT_PARTNERSHIPS_MANAGEMENT_REQUEST:
      return {
        ...state,
        error: null,
        xlsx: null
      }
    case c.API_EXPORT_PARTNERSHIPS_MANAGEMENT:
      return {
        ...state,
        xlsx: action.payload
      }
    case c.API_PUT_PARTNERSHIPS_MANAGEMENT_REQUEST:
      return { ...state, isLoading: true }
    case c.API_PUT_PARTNERSHIPS_MANAGEMENT:
      const stateCp = cloneDeep(state.partnerships)
      const payloadCp = cloneDeep(action.payload)
      if (!isUndefined(payloadCp)) {
        const idx = findIndex(stateCp, { id: payloadCp.id })
        stateCp[idx] = payloadCp
        return {
          ...state,
          partnerships: stateCp,
          isLoading: false,
          error: null
        }
      }
      return {
        ...state,
        //partnerships: stateCp,
        isLoading: false,
        error: action.error
      }

    case c.API_PUT_PARTNERSHIPS_MANAGEMENT_FAILURE:
      return {
        ...state,

        isLoading: false
      }

    /*  case
         c.API_PUT_GM_OPTION: {
              const stateCp = cloneDeep(state.purchaseDetail)
              const payloadCp = cloneDeep(action.payload)
              const idx = findIndex(stateCp, { idCompany: payloadCp.idCompany })
              stateCp[idx] = payloadCp
              return {
                  ...state,
                  purchaseDetail: stateCp,
                  isisLoading: false
              }
          }
          case c.API_PUT_GM_OPTION_SUCCESS: {
              return { ...state, isLoading: false }
          } */
    default:
      return state
  }
}
