////////LIBRARY/////////
import { SubmissionError } from 'redux-form'
///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'

// FETCHING COUNTRIES
function apiGetCountriesRequest(endpointPath) {
  return { type: c.API_GET_COUNTRIES_REQUEST, endpointPath }
}
function apiGetCountriesSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_COUNTRIES_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_COUNTRIES,
      payload: res
    })
  }
}
function apiGetCountriesFailure(endpointPath, err) {
  return { type: c.API_GET_COUNTRIES_FAILURE, endpointPath }
}
export function apiGetCountries() {
  return u.getValueFromStore(
    c.apiCountries,
    h.headerGetWithToken(),
    apiGetCountriesRequest,
    apiGetCountriesSuccess,
    apiGetCountriesFailure
  )
}

// UPDATE COUNTRY LABELLIST
function apiUpdateCountryLabellistRequest(endpointPath) {
  return { type: c.API_UPDATE_COUNTRY_LABELLIST_REQUEST, endpointPath }
}
function apiUpdateCountryLabellistSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_COUNTRY_LABELLIST_SUCCESS, endpointPath })
    dispatch({ type: c.API_UPDATE_COUNTRY_LABELLIST, payload: res, idCountryKey: res.country_alpha_2_code })
  }
}
function apiUpdateCountryLabellistFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_COUNTRY_LABELLIST_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateCountryLabellist(m) {
  return u.getValueFromStore(
    `${c.apiCountry}${m.country_alpha_2_code}${c.apiUpdateLabellistCountry}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateCountryLabellistRequest,
    apiUpdateCountryLabellistSuccess,
    apiUpdateCountryLabellistFailure,
    false
  )
}

// UPDATE COUNTRY PRINTSHOP PE
function apiUpdateCountryPrintshopPERequest(endpointPath) {
  return { type: c.API_UPDATE_COUNTRY_PRINTSHOP_PE_REQUEST, endpointPath }
}
function apiUpdateCountryPrintshopPESuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_COUNTRY_PRINTSHOP_PE_SUCCESS, endpointPath })
    dispatch({ type: c.API_UPDATE_COUNTRY_PRINTSHOP_PE, payload: res, idCountryKey: res.country_alpha_2_code })
  }
}
function apiUpdateCountryPrintshopPEFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_COUNTRY_PRINTSHOP_PE_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateCountryPrintshopPE(m) {
  return u.getValueFromStore(
    `${c.apiCountry}${m.country_alpha_2_code}${c.apiUpdatePrintshop_PE_Country}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateCountryPrintshopPERequest,
    apiUpdateCountryPrintshopPESuccess,
    apiUpdateCountryPrintshopPEFailure,
    false
  )
}

// UPDATE COUNTRY PRINTSHOP PR
function apiUpdateCountryPrintshopPPRequest(endpointPath) {
  return { type: c.API_UPDATE_COUNTRY_PRINTSHOP_PP_REQUEST, endpointPath }
}
function apiUpdateCountryPrintshopPPSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_COUNTRY_PRINTSHOP_PP_SUCCESS, endpointPath })
    dispatch({ type: c.API_UPDATE_COUNTRY_PRINTSHOP_PP, payload: res, idCountryKey: res.country_alpha_2_code })
  }
}
function apiUpdateCountryPrintshopPPFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_COUNTRY_PRINTSHOP_PP_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateCountryPrintshopPP(m) {
  return u.getValueFromStore(
    `${c.apiCountry}${m.country_alpha_2_code}${c.apiUpdatePrintshop_PP_Country}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateCountryPrintshopPPRequest,
    apiUpdateCountryPrintshopPPSuccess,
    apiUpdateCountryPrintshopPPFailure,
    false
  )
}
