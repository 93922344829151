////////LIBRARY/////////
import React, { Component } from 'react'
///////COMPONENTS///////
import { H1, P1 } from '../../components/styledComponents'
import { translate } from '../HOC/Translation'
export default class NotFound extends Component {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <div style={{ margin: '0 auto', width: '400px', paddingTop: '50px', textAlign: 'center' }}>
        <H1>{`${translate('common__notAllowed')}!`}</H1>
        <P1 marginTop={5}>{`${translate('common__acquirePrivileges')}.`}</P1>
      </div>
    )
  }
}
