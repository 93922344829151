////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
///////COMPONENTS///////
import { apiAddItemToOrder } from '../../../actions/orders'
import TextField from 'material-ui/TextField'
import { H4 } from '../../styledComponents'
import './AddItemActions.css'
import * as SC from '../../styledComponents/'

/////////STYLED/////////
const AddItemActionsWrapper = styled.div`
  width: 160px;
`
/////////STYLED/////////

class AddItemActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      clicked: false,
      errors: {}
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleChange = (event) => {
    this.setState({
      value: event.target.value
    })
  }

  handleChangeQuanity(order) {
    let { value } = this.state
    if (value < 1) value = 1

    const { iD_Article, orderId } = order
    const { trl, apiAddItemToOrder } = this.props
    let errors = null
    if (value && (value / order.article_Conditionnement) % 1 !== 0) {
      if (!errors) errors = {}
      errors.qte = `${trl.order_AddItem_ErrorMsg_Packing} ${order.article_Conditionnement}`
    }
    if (value && value < order.article_Order_Min) {
      if (!errors) errors = {}
      errors.qte = `${trl.order_AddItem_ErrorMsg_MinimumOrder} ${order.article_Order_Min}`
    }
    this.setState({
      errors
    })
    if (!errors) {
      apiAddItemToOrder(order.orderId, {
        iD_Commande: orderId,
        iD_Article: iD_Article,
        qte: value
      }).then(() => {
        this.setState({ clicked: true })
      })
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl } = this.props // row is article
    const { errors = {}, clicked, value } = this.state
    return (
      <div>
        {!row.alreadyInOrder && row.article_Fixe && (
          <AddItemActionsWrapper>
            <TextField
              id="text-field-controlled"
              type="number"
              floatingLabelText={trl.order_AddItem_Quantity}
              style={{ fontWeight: 'bold', width: '100%', fontSize: 16, marginLeft: -40 }}
              value={value}
              onChange={this.handleChange}
              disabled={clicked}
              errorText={errors && errors.qte}
            />
            <br />
          </AddItemActionsWrapper>
        )}
        {!row.alreadyInOrder || (clicked && row.alreadyInOrder) ? (
          <SC.ButtonPA_L
            disabled={clicked}
            onClick={() => this.handleChangeQuanity(row)}
            style={{ marginLeft: '-40px' }}
          >
            {clicked ? trl.order_AddItem_Btn_AddedToOrder : trl.order_AddItem_Btn_AddToOrder}
          </SC.ButtonPA_L>
        ) : (
          <H4>{trl.order_AddItem_AlreadyInOrder}</H4>
        )}
      </div>
    )
  }
}

AddItemActions.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ({ languages }) => ({
  trl: languages.siteLanguage.keys
})

export default connect(mapStateToProps, { apiAddItemToOrder })(AddItemActions)
