const requestState = (state) => {
  return (key) => ({
    ...state,
    [key]: {
      awaitingResponse: true,
      lastUpdated: false,
      didInvalidate: false
    }
  })
}

const successState = (state) => {
  return (key) => ({
    ...state,
    [key]: {
      ...state[key],
      lastUpdated: Date.now(),
      awaitingResponse: false
    }
  })
}

const failureState = (state) => {
  return (key) => ({
    ...state,
    [key]: {
      ...state[key],
      didInvalidate: true,
      awaitingResponse: false
    }
  })
}

const resetState = (state) => {
  return (key) => {
    delete state[key]
    return { ...state }
  }
}

export default function (state = {}, action) {
  // passing the state as first argument of function
  const r = requestState(state)
  const s = successState(state)
  const f = failureState(state)
  const reset = resetState(state)

  switch (true) {
    case action.type.endsWith('_REQUEST'):
      return r(action.endpointPath)
    case action.type.endsWith('_SUCCESS'):
      return s(action.endpointPath)
    case action.type.endsWith('_FAILURE'):
      return f(action.endpointPath)
    case action.type.endsWith('_RESET'): {
      return reset(action.endpointPath)
    }

    default:
      return state
  }
}
