////////LIBRARY/////////
import React from 'react'
////////ASSETS//////////
import Assessment from 'material-ui/svg-icons/action/assessment'
import PersonAdd from 'material-ui/svg-icons/social/person-add'
import People from 'material-ui/svg-icons/social/people'
import Role from 'material-ui/svg-icons/action/card-membership'
import Log from 'material-ui/svg-icons/action/list'
import Language from 'material-ui/svg-icons/action/language'
import ShopingCart from 'material-ui/svg-icons/action/shopping-cart'
import Catalogue from 'material-ui/svg-icons/action/view-list'
import ShippingInformation from 'material-ui/svg-icons/maps/local-shipping'
import AccountDetails from 'material-ui/svg-icons/action/fingerprint'
import Invoice from 'material-ui/svg-icons/action/account-balance'
import Proforma from 'material-ui/svg-icons/action/receipt'
import DeliveryNote from 'material-ui/svg-icons/av/note'
import PlaceOrder from 'material-ui/svg-icons/action/shopping-cart'
import OrdersFollowUp from 'material-ui/svg-icons/action/history'
import CGV from 'material-ui/svg-icons/communication/business'
import Confidentiality from 'material-ui/svg-icons/communication/vpn-key'
import MyAccount from 'material-ui/svg-icons/action/account-box'
import Documentation from 'material-ui/svg-icons/communication/business'
import * as c from '../../../constants'
import ProductionFollowUp from 'material-ui/svg-icons/action/view-list'
import StockFollowUp from 'material-ui/svg-icons/action/view-list'
import ItemFollowUp from '../../../containers/ItemFollowUp/ItemFollowUp'
import FileOrderFollowUp from '../../../containers/FileOrderFollowUp/FileOrderFollowUp'

const menuParts = {
  dropdowns: {
    documentation: { text: 'menu__documentation', icon: <Documentation /> },
    myAccount: { text: 'menu__MyAccount', icon: <MyAccount /> },
    administration: { text: 'menu__admin', icon: <Assessment /> },
    company: { text: 'menu__companySettings', icon: <Assessment /> }
  },
  dashboard: { text: 'menu__dashboard', icon: <Assessment />, link: c.index },
  userList: { text: 'menu__user_list', icon: <People />, link: c.users },
  roles: { text: 'menu__role_list', icon: <Role />, link: c.roles },
  logs: { text: 'menu__log_list', icon: <Log />, link: c.logs },
  languageList: { text: 'menu__language_list', icon: <Language />, link: c.languages },
  countriesLabellistsList: { text: 'menu__country_labellist_list', icon: <Invoice />, link: c.countriesLabellists },
  brandList: { text: 'menu__brands_list', icon: <Invoice />, link: c.brands },
  productCatalog: { text: 'menu__productCatalog', icon: <Invoice />, link: c.productCatalog },
  printShops: { text: 'menu__managePSH', icon: <Invoice />, link: c.managePrintshops },

  orderNew: { text: 'menu__order_new', icon: <ShopingCart />, link: c.orderNew },
  orders: { text: 'menu__orders_pre', icon: <ShopingCart />, link: c.ordersList },
  followUp: { text: 'menu__followup', icon: <OrdersFollowUp />, link: c.followUp },
  deliveryFollowUp: { text: 'menu__DeliveryFollowup', icon: <OrdersFollowUp />, link: c.deliveriesFollowUp },
  batPending: { text: 'menu__BATPending', icon: <OrdersFollowUp />, link: c.batPending },
  catalogue: { text: 'menu__productCatalog', icon: <Catalogue />, link: c.catalogue },
  productionFollowUp: { text: 'menu__productionFollowUp', icon: <ProductionFollowUp />, link: c.productionFollowUp },
  stockFollowUp: { text: 'menu__stofu', icon: <StockFollowUp />, link: c.stockFollowUp },
  itemFollowUp: { text: 'menu__itemFollowUp', icon: <ItemFollowUp />, link: c.itemFollowUp },
  fileOrderFollowUp: { text: 'menu__fileOrderFollowUp', icon: <FileOrderFollowUp />, link: c.fileOrderFollowUp },

  placeOrder: { text: 'menu__newOrder', icon: <PlaceOrder />, link: c.placeOrder },
  deliveryNote: { text: 'menu__deliveryNote', icon: <DeliveryNote />, link: `${c.documentation}${c.deliveryNote}` },
  invoices: { text: 'menu__invoices', icon: <Invoice />, link: `${c.documentation}${c.invoices}` },
  proformaInvoices: { text: 'menu__proformaInvoices', icon: <Proforma />, link: `${c.documentation}${c.proformaInvoices}` },

  myAccount: { text: 'menu__MyAccount_settings', icon: <AccountDetails />, link: c.myAccount },
  myDocumentation: { text: 'menu__Documentation', icon: <AccountDetails />, link: c.myDocumentation },
  myCarriers: { text: 'menu__Carriers', icon: <PersonAdd />, link: c.myCarriers },
  myAddresses: { text: 'menu__myAddresses', icon: <PersonAdd />, link: c.myAddresses },
  manageGMOptions: { text: 'menu__gmOptions', icon: null, link: c.manageGMOptions },
  shippingInfo: {
    text: 'menu__shippingInformation',
    icon: <ShippingInformation />,
    link: `${c.myAccount}${c.shippingInfo}`
  },
  newUser: { text: 'menu__user_new', icon: <PersonAdd />, link: c.userCreate },
  cgv: { text: 'menu__cgv', icon: <CGV />, link: c.cgv },
  confidentiality: { text: 'menu__confidentiality', icon: <Confidentiality />, link: c.confidentiality },
  statistique: { text: 'menu__stats', icon: '', link: c.stats },
  partnershipsManagement: { text: 'menu__Partnerships', icon: '', link: c.partnershipsManagement }
}

export default menuParts
