///////COMPONENTS///////
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import theme from './components/styledComponents/theme'

const muiTheme = getMuiTheme({
  borderRadius: 0,
  checkbox: {
    checkedColor: 'black',
  },
  radioButton: {
    checkedColor: 'black',
  },
  palette: {},
  fontFamily: `${theme.font.fontFamilyName}, sans-serif`,
  appBar: {
    height: 57,
    color: theme.colors.black
  },
  drawer: {
    width: 220,
    paddingLeft:10,
    color: theme.colors.lightGrey,
  },
  root: {
    muiraisedbutton: {
      primaryColor: theme.colors.black,
    }
  },
  raisedButton: {
    primaryColor: theme.colors.black,
  },
  flatButton: {
    primaryTextColor: theme.colors.black
  },
})

export default muiTheme
