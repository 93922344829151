////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import Color from '../ColumnHelpers/Color.js'
import OrdersFollowUpActions from '../Actions/OrdersFollowUpActions'
import OrdersRatioRemaining from '../Actions/OrdersRatioRemaining'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation.js'

const columns = [
  { key: 'iD_Status', header: translate('order_table_tableTitle__status'), Component: Color, sortable: true },
  { key: 'iD_Commande', header: 'ID', primaryKey: true, searchable: true, hidden: true },
  { key: 'iD_Commande_Externe_Enseigne', header: translate('order_table_tableTitle__poNo'), searchable: true, sortable: true },
  { key: 'enseigne_Name', header: translate('order_table_tableTitle__brand'), searchable: true, sortable: true },
  { key: 'iD_Commande_Externe_Faconnier', header: translate('order_table_tableTitle__GarmentMaker'), searchable: true, sortable: true },
  { key: 'date_Created', header: translate('order_table_tableTitle__dateCreated'), sortable: true, Component: DateFormatter },
  { key: 'date_Updated', header: translate('order_table_tableTitle__updated'), sortable: true, Component: DateFormatter },
  { key: 'date_Expedition_Souhaitee', header: translate('order_table_tableTitle__shippingDate'), sortable: true, Component: DateFormatter },
  { key: 'article_status_PRE30_PRE50_count', header: translate('order_table_tableTitle__ItemsInOrder'), Component: OrdersRatioRemaining, sortable: true },
  { key: 'actions', header: translate('order_table_tableTitle__actions'), Component: OrdersFollowUpActions }
]

const config = {
  defaultPageSize: 100
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

class OrdersTable extends Component {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <TableContainer>
        <Table {...this.props} columns={columns} NoDataComponent={NoDataComponent} />
      </TableContainer>
    )
  }
}

OrdersTable.propTypes = propTypes
export default sematable('ordersTable', OrdersTable, columns, config)
