////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
/////////ASSETS/////////
import Yes from 'material-ui/svg-icons/action/done'
import Clear from 'material-ui/svg-icons/content/clear'

///////////////////////////////// RENDER ///////////////////////////////////////

const YesNo = ({ children }) => <span>{children ? <Clear /> : <Yes />}</span>

YesNo.propTypes = {
  children: PropTypes.bool.isRequired
}
export default YesNo
