////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import styled from 'styled-components'
///////COMPONENTS///////
import FlatButton from 'material-ui/FlatButton'
import { renderInput, renderSelectField } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import MenuItem from 'material-ui/MenuItem'
import CircularProgress from 'material-ui/CircularProgress'
import * as SC from '../../../components/styledComponents'
import generateId from '../../../utils/generateId'
import { translate } from '../../HOC/Translation'

/////////STYLED/////////
const FabricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  vertical-align: bottom;
  & > * {
    margin-right: 50px;
  }
  & > *:first-child {
    margin-bottom: 10px;
  }
  & > *:last-child {
    margin-bottom: 10px;
  }
  & > *:nth-child(2) {
    flex: 1 0;
  }
`
const MaterialContainer = FabricContainer.extend`
  align-items: flex-start;
  margin-left: 100px;
  & > *:first-child {
    flex: 0 0 100px;
  }
  & > *:last-child {
    margin-right: 0;
    margin-top: 18px;
  }
`
const FormContainer = styled.div`
  width: 800px;
`
const H2Styled = styled.h2`
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
`
const PercentageContainer = styled.div`
  position: relative;
  &::after {
    content: '%';
    position: absolute;
    top: 40px;
  }
`
/////////STYLED/////////

const validate = (values, props) => {
  const errors = {}
  if (!values.fabrics || !values.fabrics.length) errors.fabrics = { _error: translate('clothesComposition_form__enterMember') }
  else {
    const fabricArrayErrors = []
    const fabricsChosen = []
    values.fabrics.forEach((fabric, fabricIndex) => {
      const fabricErrors = {}
      if (!fabric || !fabric.fabricChoice) {
        // making sure fabric is chosen
        fabricErrors.fabricChoice = translate('clothesComposition_form__fabricChoice')
        fabricArrayErrors[fabricIndex] = fabricErrors
      }
      if (fabric && fabric.fabricChoice) {
        // making sure same fabric is not chosen twice
        if (fabricsChosen.indexOf(fabric.fabricChoice) !== -1) {
          fabricErrors.fabricChoice = translate('clothesComposition_form_error___chosenTwice')
          fabricArrayErrors[fabricIndex] = fabricErrors
        }
        fabricsChosen.push(fabric.fabricChoice)
      }
      if (fabric && fabric.materialCompositions && fabric.materialCompositions.length) {
        const materialsChosen = []
        const materialCompArrayErrors = []
        let percentageCount = 0
        fabric.materialCompositions.forEach((materialComp, materialCompIndex) => {
          const materialCompErrors = {}
          if (!materialComp || !materialComp.material) {
            materialCompErrors.material = translate('clothesComposition_form__requiredMaterial')
            materialCompArrayErrors[materialCompIndex] = materialCompErrors
          }
          if (!materialComp || !materialComp.percentage) {
            materialCompErrors.percentage = translate('clothesComposition_form__percentageRequired')
            materialCompArrayErrors[materialCompIndex] = materialCompErrors
          }
          if (materialComp && parseInt(materialComp.percentage, 10) <= 0) {
            materialCompErrors.percentage = translate('clothesComposition_form__percentageMustBeInteger')
            materialCompArrayErrors[materialCompIndex] = materialCompErrors
          }
          if (materialComp) {
            percentageCount += parseInt(materialComp.percentage, 10)
          } // adding percentages
          if (materialComp && materialComp.material) {
            // making sure same material is not chosen twice
            if (materialsChosen.indexOf(materialComp.material) !== -1) {
              materialCompErrors.material = translate('clothesComposition_form_error___chosenMoreThanOnce')
              materialCompArrayErrors[materialCompIndex] = materialCompErrors
            }
            materialsChosen.push(materialComp.material)
          }
        }) // finishing material composition iteration
        if (materialCompArrayErrors.length) {
          // if there are errors in materialComposition section
          fabricErrors.materialCompositions = materialCompArrayErrors
          fabricArrayErrors[fabricIndex] = fabricErrors
        }
        if (percentageCount !== 100) {
          if (!fabricErrors.materialCompositions) fabricErrors.materialCompositions = []
          fabricErrors.materialCompositions._error = translate('clothesComposition_form_error__sumMustEqual100')
          fabricArrayErrors[fabricIndex] = fabricErrors
        }
      }
    })
    if (fabricArrayErrors.length) errors.fabrics = fabricArrayErrors
  }
  return errors
}

/**
 * Return the valid value that is greater than the specified minimal value.
 *
 * @param {any} minValue
 */
const greaterThan = (minValue) => (value, previousValue) => value > minValue ? value : previousValue

/**
 * Return the valid value that is less than the specified maximal value.
 *
 * @param {any} maxValue
 */
const lessThan = (maxValue) => (value, previousValue) => value < maxValue ? value : previousValue

const renderMaterialCompositions = ({ fields, meta: { error }, ...rest }) => (
  <div>
    {fields.map((materialComp, index) => (
      <div key={index}>
        <MaterialContainer>
          <PercentageContainer>
            <Field
              style={{ width: 'calc(100% - 15px) !important' }}
              name={`${materialComp}.percentage`}
              type="number"
              disabled={rest.isDisabled}
              component={renderInput}
              label={rest.trl.common__fieldarray_fabric_material_percent || ' '}
              normalize={greaterThan(0) && lessThan(101)}
            />
          </PercentageContainer>
          <Field
            name={`${materialComp}.material`}
            component={renderSelectField}
            disabled={rest.isDisabled}
            label={rest.trl.common__fieldarray_fabric_material || ' '}
            style={styles.select}
          >
            {[...rest.materialList].map((m) => (
              <MenuItem style={{ fontSize: '12px' }} key={m.key} value={m.key} primaryText={m.value} />
            ))}
          </Field>
          <FlatButton label="x" disabled={rest.isDisabled} onClick={() => fields.remove(index)} />
        </MaterialContainer>
      </div>
    ))}
    {error && <p style={{ ...styles.loginError, textAlign: 'left', marginLeft: 100 }}>{error}</p>}
    <FlatButton
      style={{ margin: '20px 0 50px 100px' }}
      label="+"
      primary={false}
      disabled={
        rest.isDisabled ||
        (rest.fabricsValue &&
        rest.fabricsValue.length &&
        rest.fabricsValue[rest.index] &&
        rest.fabricsValue[rest.index].materialCompositions &&
        rest.fabricsValue[rest.index].materialCompositions.length >= 3
          ? true
          : false)
      }
      onClick={() => fields.push()}
    />
  </div>
)

const renderFabrics = ({ fields, meta: { error }, ...rest }) => (
  <div style={{ marginBottom: 20 }}>
    {fields.map((fabric, index) => (
      <div key={index}>
        <FabricContainer>
          <H2Styled>
            {rest[index] && rest[index].fabricChoice
              ? `${index + 1} - ${rest[index].fabricChoice}`
              : `${rest.trl.common__fieldarray_fabric_title}${index + 1}`}
          </H2Styled>
          <Field
            name={`${fabric}.fabricChoice`}
            fullWidth={false}
            disabled={rest.isDisabled}
            component={renderSelectField}
            label={rest.trl.common__fieldarray_fabric_label || ' '}
            style={styles.select}
          >
            {rest.fabricList.map((m) => (
              <MenuItem style={{ fontSize: '12px' }} key={m.key} value={m.key} primaryText={m.value} />
            ))}
          </Field>
          <FlatButton label="X" disabled={rest.isDisabled} primary={true} onClick={() => fields.remove(index)} />
        </FabricContainer>
        <FieldArray
          name={`${fabric}.materialCompositions`}
          component={renderMaterialCompositions}
          props={{
            materialList: rest.materialList,
            fabricsValue: rest.fabricsValue,
            index,
            isDisabled: rest.isDisabled
          }}
          trl={rest.trl}
        />
      </div>
    ))}
    {error && <p style={styles.loginError}>{error}</p>}
    <FlatButton
      label={rest.trl.common__fieldarray_fabric_add_btn || ' '}
      primary={false}
      disabled={rest.isDisabled || (rest.fabricsValue && rest.fabricsValue.length >= 4 ? true : false)}
      style={{ marginTop: 20 }}
      onClick={() => fields.push({})}
    />
  </div>
)

const formName = generateId()

class ClothesCompositionForm extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.initialize(this.props.initialValue)
  }

  onSubmit = (formValues) => {
    this.props.submitAction(formValues.fabrics)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { fabricList, materialList, fabricsValue, pristine, handleSubmit, submitting, error, submitSucceeded, trl, isDisabled } = this.props
    return (
      <div>
        <SC.PageGutter className="test">
          <FormContainer>
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <FieldArray
                name="fabrics"
                component={renderFabrics}
                props={{ fabricList, materialList, fabricsValue, isDisabled }}
                trl={trl}
              />
              <div>
                {error && <p style={styles.loginError}>{error}</p>}
                {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
                <SC.ButtonPA_L
                  type="submit"
                  disabled={submitting || pristine || (submitSucceeded && pristine)}
                  style={{ float: 'none' }}
                >
                  {trl.common__fieldarray_submit_btn || ' '}
                </SC.ButtonPA_L>
              </div>
            </form>
          </FormContainer>
        </SC.PageGutter>
      </div>
    )
  }
}

ClothesCompositionForm.propTypes = {
  initialValue: PropTypes.object.isRequired,
  submitAction: PropTypes.func.isRequired,
  fabricList: PropTypes.array.isRequired,
  materialList: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

ClothesCompositionForm = reduxForm({
  form: formName,
  validate,
  touchOnBlur: true
})(ClothesCompositionForm)

const selector = formValueSelector(formName)
ClothesCompositionForm = connect((state) => {
  const fabricsValue = selector(state, 'fabrics')
  return {
    fabricsValue
  }
})(ClothesCompositionForm)

export default ClothesCompositionForm
