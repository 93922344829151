///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppAlert, addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET DASHBOARD
function apiGetDashboardRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_DASHBOARD_REQUEST, endpointPath })
  }
}
function apiGetDashboardSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_DASHBOARD_SUCCESS, payload: res })
  }
}
function apiGetDashboardFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppAlert(err.message, true))
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_DASHBOARD_FAILURE, payload: err })
  }
}
export function apiGetDashboard() {
  return u.getValueFromStore(
    `${c.apiUser}${c.dashBoard}`,
    h.headerGetWithToken(),
    apiGetDashboardRequest,
    apiGetDashboardSuccess,
    apiGetDashboardFailure
  )
}
