///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET GM OPTIONS
function apiGetGmOptionsRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_GM_OPTIONS_REQUEST, endpointPath })
  }
}
function apiGetGmOptionsSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_GM_OPTIONS_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_GM_OPTIONS,
      payload: payload
    })
  }
}
function apiGetGmOptionsFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_GM_OPTIONS_FAILURE, endpointPath })
  }
}
export function apiGetGmOptions(data) {
  return u.getValueFromStore(
    `${c.apiGmOptions}`,
    h.headerPostWithToken(data),
    apiGetGmOptionsRequest,
    apiGetGmOptionsSuccess,
    apiGetGmOptionsFailure
  )
}

// PUT GM OPTIONS
function apiPutGmOptionsRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_PUT_GM_OPTION_REQUEST, endpointPath })
  }
}
function apiPutGmOptionsSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PUT_GM_OPTION_SUCCESS, endpointPath })
    dispatch({
      type: c.API_PUT_GM_OPTION,
      payload: payload
    })
  }
}
function apiPutGmOptionsFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PUT_GM_OPTION_FAILURE, endpointPath })
  }
}
export function apiPutGmOption(row) {
  return u.getValueFromStore(
    `${c.apiGmOption}`,
    h.headerPutWithTokenAndModel(row),
    apiPutGmOptionsRequest,
    apiPutGmOptionsSuccess,
    apiPutGmOptionsFailure
  )
}

// EXPORT GM OPTIONS
function apiGmOptionExportRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_GM_OPTION_REQUEST, endpointPath })
    dispatch(addAppShowLoading(true))
  }
}
function apiGmOptionExportSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_GM_OPTION_SUCCESS, payload: res })
    dispatch(addAppHideLoading(true))
  }
}
function apiGmOptionExportFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_GM_OPTION_FAILURE, endpointPath })
    dispatch(addAppHideLoading(true))
  }
}
export function apiGmOptionExport(params) {
  return u.getValueFromStore(
    `${c.apiGmOptionsExport}`,
    h.headerPostWithToken(params),
    apiGmOptionExportRequest,
    apiGmOptionExportSuccess,
    apiGmOptionExportFailure,
    true
  )
}
