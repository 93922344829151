////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _, { isUndefined } from 'lodash'
///////COMPONENTS///////
import { PageGutter } from '../../components/styledComponents'
import { apiGetOrderArticle } from '../../actions/orders'
import * as c from '../../constants'
import PageLoad from '../../components/PageLoad'
import { PageHeader, HelpComponent, TotalOrder } from '../../components/sharedComponents'
import OrderDetailInformation from '../../containers/OrderDetail/OrderDetailInformation'
import * as SC from '../../components/styledComponents'

class ConfirmOrderSuccess extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showHelpComponent: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.apiGetOrderArticle(this.props.routeParams.orderId)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, orders, routeParams, myRole } = this.props
    const order = orders[routeParams.orderId]
    return (_.isEmpty(orders) || isUndefined(order)) ? (
      <PageLoad text={trl.common__loading} size={80} thickness={9} />
    ) : (
      <PageGutter>
        <PageHeader
          title={`PO ${order.iD_Commande} - ${trl.common__orderConfirmation}`}
          base64Logo={
            order && order.brandLogo
              ? order.brandLogo
              : null
          }
        />
        <OrderDetailInformation
          trl={trl}
          order={order}
          myRole={myRole}
          handleCancelOrder={this.handleCancelOrder}
          garmentMakerStatic
        />
        {order.hasArc ?
          (
            <div style={{
              fontWeight: 'bold'
            }}>
              <span>{trl.order_confirm_success__message}</span>
              <span style={{ 'cursor': 'pointer', 'color': '#1B78B3', 'textDecoration': 'underline' }} onClick={() => window.open(`${c.downloadARC}${order.arcNumber}.pdf`, '_blank')}>
                {trl.order_confirm_success__message_linkARC}
              </span>
              <p style={{
                marginTop: '1em'
              }}>{trl.order_confirm_success__message_signature}</p>
            </div>
          ) : (
            <span>{trl.order_confirm_successNOPDF__message}
            </span>
          )
        }
        <br />
        <br />
        <TotalOrder trl={trl} order={order} />
        <br />
        <div style={{ position: 'relative' }}>
          <img
            src={require('../../assets/centre_appel.png')}
            alt="call center"
            style={{ marginBottom: 30, display: 'block', cursor: 'pointer', width: 50 }}
            onMouseEnter={() => this.setState({ showHelpComponent: true })}
            onMouseLeave={() => this.setState({ showHelpComponent: false })}
          />
          {this.state.showHelpComponent && <HelpComponent />}
        </div>
        <div style={{ textAlign: 'right' }}>

          <SC.ButtonPA_L
            onClick={() => this.context.router.push(c.followUp)}
          >
            {trl.orderConfirmation_btn_followOrders}
          </SC.ButtonPA_L>
        </div>
      </PageGutter>
    )
  }
}

const mapStateToProps = ({ orders, auth }) => ({ orders, myRole: auth.user_role })

ConfirmOrderSuccess.propTypes = {
  trl: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  apiGetOrderArticle: PropTypes.func.isRequired
}

ConfirmOrderSuccess.contextTypes = {
  router: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { apiGetOrderArticle })(ConfirmOrderSuccess)
