////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import { get, isUndefined } from 'lodash'
///////COMPONENTS///////
import DeliveryFllowUpActions from '../Actions/DeliveryFllowUpActions'
import YesNoImg from '../ColumnHelpers/YesNoImg'
import InOutImg from '../ColumnHelpers/InOutImg'
import DeliveryStatus from '../ColumnHelpers/DeliveryStatus'
import YesNoText from '../ColumnHelpers/YesNoText'
import NullIfZero from '../ColumnHelpers/NullIfZero'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

export const columnsIds = [
  { key: 'defu_tableTitle_IDDelivery', header: translate('defu_tableTitle_IDDelivery'), searchable: true },
  { key: 'defu_tableTitle_From', header: translate('defu_tableTitle_From'), searchable: true },
  { key: 'defu_tableTitle_To', header: translate('defu_tableTitle_To'), searchable: true },
  { key: 'defu_tableTitle_PurchaseOrderSAP', header: translate('defu_tableTitle_PurchaseOrderSAP'), searchable: true },
  { key: 'defu_tableTitle_BLSAP', header: translate('defu_tableTitle_BLSAP'), searchable: true },
  { key: 'defu_tableTitle_NumberOfItems', header: translate('defu_tableTitle_NumberOfItems'), searchable: true },
  { key: 'defu_tableTitle_Country', header: translate('defu_tableTitle_Country'), searchable: true },
  { key: 'defu_tableTitle_Carrier', header: translate('defu_tableTitle_Carrier'), searchable: true },
  { key: 'defu_tableTitle_AWB', header: translate('defu_tableTitle_AWB'), searchable: true },
]
const columnsSetup = [
  { key: 'isOut', header: translate('defu_table_tableTitle__inOut'), Component: InOutImg },
  { key: 'idDeliveryJPI', header: translate('defu_table_tableTitle__plNumber'), primaryKey: true },
  { key: 'creationDate', header: translate('defu_table__tableTitle__creationDate'), sortable: true, Component: DateFormatter },
  { key: 'sender.labelCompany', header: translate('defu_table_tableTitle__from'), sortable: true },
  { key: 'recipient.labelCompany', header: translate('defu_table_tableTitle__to'), sortable: true },
  { key: 'status.traductionKey', header: translate('defu_table_tableList__status'), sortable: true, Component: DeliveryStatus },
  { key: 'plReady', header: translate('defu_table_tableTitle__plReady'), sortable: true, Component: YesNoText },
  { key: 'purchaseOrder', header: translate('defu_table_tableTitle__Po'), sortable: true, Component: NullIfZero },
  { key: 'idDeliverySAP', header: translate('defu_table_tableTitle__deliveryNote'), sortable: true },
  { key: 'numberOfItems', header: translate('defu_table_tableTitle__nbrItems'), sortable: true },
  { key: 'recipient.countryAddress', header: translate('defu_table__tableTitle__country'), sortable: true, className: 'uppercase' },
  { key: 'ctcDateConfirmed', header: translate('defu_table_tableTitle__confirmedDate'), sortable: true, Component: DateFormatter },
  { key: 'shippingDate', header: translate('defu_table_tableTitle__shippingDate'), sortable: true, Component: DateFormatter },
  { key: 'carrierName', header: translate('defu_table_tableTitle__carrier'), sortable: true },
  { key: 'awb', header: translate('defu_table_tableTitle__awb'), sortable: true },
  { key: 'shipmentAuth', header: translate('defu_table_tableList__shptAuthor'), sortable: true, Component: YesNoImg },
  { key: 'actions', header: translate('defu_table_tableList_Action'), Component: DeliveryFllowUpActions }
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}

class DeliveryFollowUpTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      columns: columnsSetup
    }
  }

  render() {

    const { data, headers } = this.props

    const { columns } = this.state

    if (!data.length && isUndefined(columns)) return null
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  {
                    columns.map(column => {
                      if (column.hidden) return null
                      // Getting headers object from injected sematable, so we can use sort functions
                      const children = get(headers, column.key)
                      // Patch test : if sortable is not set, setting null function
                      //if(children.sortable !== true) children.handleClick = () => null

                      // Depending on sortable column, we don't use SortableHeader component
                      // => when we are specifying render of our table, we must handle sortable functions manually
                      return (
                        children.sortable ?
                          <SortableHeader
                            key={column.key}
                            {...children}
                          />
                          :
                          <th>
                            {children.name}
                          </th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={item.isOut ? 'tableLigneRed' : 'tableLigneGreen'}>
                      {columns.map(c => {
                        //// console.log('c.Component: ', c.Component)
                        const children = get(item, c.key)
                        return (
                          <td key={'__' + c.key} className={c.className}>
                            {c.Component && c.Component !== null
                              ? React.createElement(c.Component, { row: item, children })
                              : children}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}

DeliveryFollowUpTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
}

export default sematable('DeliveryFollowUpTable', DeliveryFollowUpTable, columnsSetup, config)
