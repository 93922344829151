////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { isEqual } from 'lodash'
///////COMPONENTS///////
import LogsTable from '../../components/Sematable/Tables/LogsTable'
import { fetchLogs } from '../../actions/logs'
import Checkbox from 'material-ui/Checkbox'
import * as SC from '../../components/styledComponents'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import TextField from 'material-ui/TextField'
import DatePicker from 'material-ui/DatePicker'
import { TextLeftStyledH4 } from '../../components/styledComponents/followUpStyles'
import { theme } from '../../components/styledComponents'
import dateFormatConverter from '../../utils/dateFormatConverter'

/////////STYLED/////////
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Label = styled.span`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  font-size: ${(props) => (props.isTitle ? '14px' : '12px')};
  margin-right: 8px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
`
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const WrapperDatePicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  align-items: center;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0')};
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
`
const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`
class LogList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputSearch: null,
      StartLogDate: moment().startOf('day').subtract(7, 'days').toDate(),
      EndLogDate: moment().toDate(),
      SeeArchived: false
    }
    this.defaultState = this.state
  } 
  
  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    this.queryData(this.defaultState)
  }

  queryData = (defaultState) => {
    const { inputSearch, StartLogDate, EndLogDate, SeeArchived } = this.state
    if (isEqual(defaultState, this.defaultState)) 
      this.props.fetchLogs({
        inputSearch: this.defaultState.inputSearch === 0 ? null : this.defaultState.inputSearch,
        StartLogDate: this.defaultState.StartLogDate,
        EndLogDate: this.defaultState.EndLogDate,
        SeeArchived: this.defaultState.SeeArchived
      })
    else {
      this.props.fetchLogs({
        inputSearch: inputSearch === 0 ? null : inputSearch,
        StartLogDate: StartLogDate,
        EndLogDate: EndLogDate != this.defaultState.EndLogDate ? 
          moment.parseZone(EndLogDate).subtract(1, 'seconds').add(1, 'days').toDate() : EndLogDate,
        SeeArchived: SeeArchived
      })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  updateStateAny = (target, index, value) => {
    this.setState({ [target]: value })
  }

  changeDateHandler = (e, date, target) => {
    this.updateStateAny(target, '', moment.parseZone(date).toDate())
  }

  onFilterChange = filter => () => {
    this.setState(state => ({ [filter]: !state[filter] }))
  }

  handleSearchChange = (e) => {
    this.setState({ inputSearch: e.target.value })
  }

  handleReset = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }
 
	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { logsReturn, trl, isLoading } = this.props
    if (isLoading || !Array.isArray(logsReturn)) {
      return null
    }
    return (
      <div>
        <PageHeader title={trl.admin_logs__title} subtitle={trl.admin_logs__subtitle} />
        <SC.PageGutter>
            <div>
              <FilterAndTableWrapper>
                <TableWrapper text={trl.common__search}>
                  <BodyTable>
                    <InputLabelWrapper>
                      <Label>{trl.common__search} :</Label>
                      <TextField
                        id="product-catalog-search-value"
                        type="text"
                        style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                        value={this.state.inputSearch ? this.state.inputSearch : ""}
                        onChange={this.handleSearchChange}
                        onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                      />
                    </InputLabelWrapper>
                    <WrapperDatePicker>
                      <TextLeftStyledH4 style={{ fontSize: theme.font.fontSize }}>
                        {trl.logfu__filter_creationDate} :
                      </TextLeftStyledH4>
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '28%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_from}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'StartLogDate')}
                        value={this.state.StartLogDate}
                        style={{ marginRight: 20 }}
                        formatDate={dateFormatConverter}
                        //maxDate={this.state.StartLogDate}
                        maxDate={this.state.EndLogDate}
                      />
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '28%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_to}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'EndLogDate')}
                        value={this.state.EndLogDate}
                        //minDate={this.state.EndLogDate}
                        minDate={this.state.StartLogDate}
                        formatDate={dateFormatConverter}
                      />
                    </WrapperDatePicker>
                    <TableRow>
                      <div style={{ width: '100%', marginLeft: '62px' }}>
                        <Checkbox
                          labelStyle={{ width: 'initial' }}
                          label={trl.common__filter_displayArchived}
                          //checked={!isEmpty(this.state.status) ? this.state.status.find((e) => e === 13) : false}
                          checked={this.state.SeeArchived}
                          onCheck={this.onFilterChange('SeeArchived')}
                        />
                      </div>
                    </TableRow>
                    <TableRow>
                      <div style={{ width: '100%', marginLeft: '66px', marginTop: '-10px', color: 'red' }}>{trl.logfu_tableLegend_archivingRule}.</div>
                    </TableRow>
                    <TableRow margin={'10px 0'}>
                      <ButtonWrapper>
                        <SC.ButtonPA_M onClick={this.queryData}>{trl.common__filter_apply_btn}</SC.ButtonPA_M>
                        <SC.ButtonR_M onClick={this.handleReset}>{trl.common__filter_reset_btn}</SC.ButtonR_M>
                      </ButtonWrapper>
                    </TableRow>
                  </BodyTable>
                </TableWrapper>
              </FilterAndTableWrapper>
              <div>
                <LogsTable data={logsReturn} />
              </div>
            </div>
        </SC.PageGutter>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.appAlerts.loading && state.appAlerts.loading.isLoading,
  ...state.logs
})

LogList.propTypes = {
  logs: PropTypes.array,
  trl: PropTypes.object.isRequired,
  fetchLogs: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchLogs })(LogList)
