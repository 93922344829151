import { loadIdToken, ID_TOKEN } from '../utils/apiUtils'
import { getItem } from '../utils/cookiesHelper'

///////////////////////////////////////////
/////////////////// GET ///////////////////
///////////////////////////////////////////
export const headerGet = {
  method: 'get',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

export const headerGetWithToken = () => {
  const t = loadIdToken() || getItem(ID_TOKEN)
  return {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${t}`
    }
  }
}

export const headerGetFileWithToken = () => {
  const t = loadIdToken() || getItem(ID_TOKEN)
  return {
    method: 'get',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${t}`
    }
  }
}

///////////////////////////////////////////
/////////////////// POST //////////////////
///////////////////////////////////////////
export const headerPostWithModelUnStringified = (m) => {
  return {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: m
  }
}
export const headerPostWithModel = (m) => {
  return {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(m)
  }
}

export const headerPostWithTokenAndModel = (m) => {
  const t = loadIdToken() || getItem(ID_TOKEN)
  return {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${t}`
    },
    body: JSON.stringify(m)
  }
}

export const headerPostWithToken = (m) => {
  const t = loadIdToken() || getItem(ID_TOKEN)
  return {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${t}`
    },
    body: JSON.stringify(m)
  }
}

export const headerPost = {
  method: 'post',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

///////////////////////////////////////////
/////////////////// DELETE ///////////////////
///////////////////////////////////////////
export const headerDeleteWithToken = () => {
  const t = loadIdToken() || getItem(ID_TOKEN)
  return {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${t}`
    }
  }
}
///////////////////////////////////////////
/////////////////// PUT ///////////////////
///////////////////////////////////////////
export const headerPutWithTokenAndModel = (m) => {
  const t = loadIdToken() || getItem(ID_TOKEN)
  return {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${t}`
    },
    body: JSON.stringify(m)
  }
}
export const headerPutWithToken = (m) => {
  const t = loadIdToken() || getItem(ID_TOKEN)
  return {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${t}`
    },
    body: JSON.stringify(m)
  }
}

/////////////////// AUTH /////////////////
export const headerAuthLogin = (email, password) => {
  return {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    // Encode password because this can lead to escape issues
    body: `username=${email}&password=${encodeURIComponent(password)}&grant_type=password`
  }
}
