////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, map, includes, cloneDeep, without, isNull, isEmpty, isUndefined } from 'lodash'
import { hashHistory, Link } from 'react-router'
import { Checkbox } from 'material-ui'
import styled from 'styled-components'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import TextField from 'material-ui/TextField'
import * as SC from '../../styledComponents'
import theme from '../../styledComponents/theme'
import * as c from '../../../constants'
import { apiCancelDelivery, apiAwpDelivery, setIsAWBFalse, apiGetCarrierData } from '../../../actions/delivery'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem'
import Divider from 'material-ui/Divider'
/////////ASSETS/////////
import Print from 'material-ui/svg-icons/action/print'
import Cancel from 'material-ui/svg-icons/navigation/cancel'
import ViewDetail from 'material-ui/svg-icons/action/pageview'
import Info from 'material-ui/svg-icons/action/info'
import SelectField from 'material-ui/SelectField'
import router from 'react-router/lib/Router'

/////////STYLED/////////
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #575756;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 10px;
`
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns:minmax(max-content, 1fr) min-content;
  grid-column-gap: 10px;
  align-items: center;
  padding-left: 10px;
`
const FormAwbContainer = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-content: center;
  align-content: center;
  grid-row-gap: 10px;
  border: 1px solid black;
  padding-bottom: 10px;
`
const FormAwbWrapper = styled.div`
  display: flex;
  width: 70%;
  justify-content: center;
  align-items: center;
`
const ModalFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 40px 0px;
`
/////////STYLED/////////

class DeliveryFllowUpActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openAwbModal: false,
      openCancelModal: false,
      awbText: '',
      carrier: null,
      awbBoxList: [],
      stateAwbEnter: false,
      awbBoxListAll: false,
      formValidation: {
        touch: false,
        error: '',
        valid: false,
        carrier: false,
      },
      modalTest: false,
      isModalOpened: false
    }
    this.handlePrintInvoice = this.handlePrintInvoice.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    if (this.state.openAwbModal && this.props.additional.awbStart && !nextProps.additional.awbStart) {
      if (!nextProps.errors.awb) {
        this.setState({ openAwbModal: false, stateAwbEnter: false })
      } else {
        this.setState({ stateAwbEnter: false })
      }
    }
    if(this.state.openAwbModal) {
      this.setState({ carrier: null })
      this.setState({ awbText: '' })
    }
    this.setState({isModalOpened:false})
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  validate = () => {
    const { trl } = this.props;
    const { awbText, awbBoxList, formValidation, carrier } = cloneDeep(this.state)
    formValidation.touch = true;
    const regCharExcluded = RegExp(/[&<>'"]/)
    let errors = ''
    if (regCharExcluded.test(awbText)) errors = `${trl.common_error_unauthorizedCars}`
    formValidation.valid = isEmpty(errors)
    formValidation.error = errors
    if (isEmpty(awbText)) formValidation.valid = false
    if (isEmpty(awbBoxList)) formValidation.valid = false
    formValidation.carrier = (isUndefined(carrier) || isNull(carrier))
    if(formValidation.valid) formValidation.valid = !formValidation.carrier
      this.setState((prevProps) => ({ ...prevProps, formValidation: { ...formValidation }}))
  }

  handleAwbBoxList = (elem) => {
    const oldState = cloneDeep(this.state.awbBoxList);
    const inArray = includes(oldState, elem);
    let newValueList = inArray ? without(oldState, elem) : [...oldState, elem]
    this.props.row.packingList.containers.map(e => {
      if(e.idContainer == elem && !isNull(e.awb)) {
        newValueList = without(newValueList, elem)
      }
    })

    this.setState((prevState) => ({
      ...prevState,
      awbBoxList: newValueList
    }), () => (this.validate())
    )
  }

  handleAwbBoxListAll = () => {
    const { row } = this.props
    const allId = map(row.packingList.containers, elem => isNull(elem.awb) && elem.idContainer)
    this.setState((prevState) => ({
      ...prevState,
      awbBoxListAll: !prevState.awbBoxListAll,
      awbBoxList: prevState.awbBoxListAll ? [] : without(allId, false)
    }), () => (this.validate()))

  }

  handleOpenCancelModal = () => {
    this.setState({ openCancelModal: true, isModalOpened: true })
  }

  handelCloseCancelModal = () => {
    this.setState({ openCancelModal: false, isModalOpened: false })
  }

  handleOpenAwb = () => {
    this.setState({ openAwbModal: true, isModalOpened: true })
    this.props.setIsAWBFalse()
    this.props.apiGetCarrierData()
  }

  handleCloseAwb = () => {
    this.setState({ openAwbModal: false, stateAwbEnter: false, awbBoxList: [], awbBoxListAll: false, awbText: '', isModalOpened: false })
  }

  handleConfirmAwb = () => {
    this.props.apiAwpDelivery({
      inputModel: {
        AWB: this.state.awbText,
        IdDeliveryJPI: this.props.row.idDeliveryJPI,
        SelectedBoxes: this.state.awbBoxList,
        IdCarrier: this.state.carrier
      },
      idDelivery: this.props.row.idDeliveryJPI
    })
    this.setState({ stateAwbEnter: true })
    // @TODO to improve : oldState mut be updated after a success call
    location.reload()
  }

  handlePrintInvoice() {
    const {
      row: { delivery = {} }
    } = this.props
    const { idInvoice_externalSAP = this.props.row.idInvoice_externalSAP } = delivery
    window.open(`/pdf_from_sap/facture/${idInvoice_externalSAP}.pdf`)
  }

  onPressCancel = () => {
    const { row } = this.props
    const idDeliveryJPI = get(row, 'idDeliveryJPI', '')
    this.props.apiCancelDelivery({ idDelivery: idDeliveryJPI })
    this.handelCloseCancelModal()
  }

  handleChange = event => {
    this.setState({
      awbText: event.target.value
    }, () => this.validate())
  }

  handleChangeCarrier = newValue => {
    this.setState({
      carrier: newValue
    }, () => this.validate())
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl, myCompanyId, currentUserRole, carrierList, company_type } = this.props
    const { status = {} } = row
    const { formValidation } = this.state
    const actions = [
      <SC.ButtonR_S style={{ margin: '0 20px' }} onClick={this.handleCloseAwb}>
        {trl.defu_modalAWB_btn_Cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S keyboardFocused={true} onClick={this.handleConfirmAwb} disabled={!this.state.formValidation.valid}>
        {trl.defu_modalAWB_btn_OK}
      </SC.ButtonPA_S>
    ]
    const actionsCancel = [
      <SC.ButtonR_S style={{ margin: '0 20px' }} onClick={this.handelCloseCancelModal}>
        {trl.defu_modalCancelDelivery_btn_cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S keyboardFocused={true} onClick={this.onPressCancel}>
        {trl.defu_modalCancelDelivery_btn_confirm}
      </SC.ButtonPA_S>
    ]
    const orderHasInvoice = get(row, 'hasInvoice', false)
    return (
      <div>
        <ActionHelper isModalOpened={this.state.isModalOpened}>
          <MenuItem style={{ fontSize: theme.font.fontSize }}
                    primaryText={trl.defu_tableAction_ViewDetail}
                    leftIcon={<ViewDetail />}
                    onClick={() => hashHistory.push(`${c.delivery}${row.idDeliveryJPI}`)}
          />
          {
            (<Divider />)
            &&
            status.subFlow >= '020' &&
            status.subFlow <= '080' &&
            status.subFlow !== 'ANN' &&
            myCompanyId === row.labellist.idCompany &&
            (currentUserRole === 'LPAdmin' || currentUserRole === 'LSAdmin' || currentUserRole === 'CSP' || currentUserRole === 'CSS') && row.isDeliveryFluxJpi && (
              <MenuItem style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.defu_tableAction_CancelDelivery}
                leftIcon={<Cancel />}
                onClick={() => this.handleOpenCancelModal()}
              />
            )}
          {
            (parseInt(status.subFlow, 10) >= 70 || (!row.isDeliveryFluxJpi && row.packingListFile !== null)) && (
              <Link
                to={
                  (!row.isDeliveryFluxJpi && row.packingListFile !== null)
                    ? ``
                    : (row.status.subFlow <= 60
                      ? `${c.delivery}${row.idDeliveryJPI}${c.packinglist}`
                      : `${c.delivery}${row.idDeliveryJPI}${c.packinglistSavePrint}`)
                }
                target="_blank"
                onClick={(e) => {
                  if (!row.isDeliveryFluxJpi && row.packingListFile !== null) {
                    e.preventDefault();
                    window.open(`/external/packing_list/${row.packingListFile}.pdf`);
                  }
                }}
              >
                <MenuItem style={{ fontSize: theme.font.fontSize }} primaryText={trl.defu_tableAction_PrintPL} leftIcon={<Print />} />
              </Link>
            )
          }

          {parseInt(status.subFlow, 10) >= 70 && orderHasInvoice && (
            <MenuItem style={{ fontSize: theme.font.fontSize }}
              leftIcon={<Print />}
              primaryText={trl.defu_tableAction_PrintInvoice}
              onClick={this.handlePrintInvoice}
            />
          )}
          { company_type === 'PS' && (status.subFlow === '080' || status.subFlow === '085') && row.isDeliveryFluxJpi && (
            <MenuItem
              style={{ fontSize: theme.font.fontSize }}
              primaryText={trl.defu_tableAction_EnterAWB}
              leftIcon={<Info />}
              onClick={() => this.handleOpenAwb() } />
          )}
        </ActionHelper>

        <Dialog
          title={trl.defu_modalAWB_Text}
          actions={actions}
          modal={false}
          open={this.state.openAwbModal}
          onRequestClose={this.handleCloseAwb}
          autoScrollBodyContent={true}
        >
          <ModalFormWrapper>

              <div style={{width: '46%'}}>
                <label className="formElementLabel">{trl.defu_modalAWB_label_DeliveryMode}</label>
                <SelectField
                  fullWidth={true}
                  onChange={(event, index, value) => {
                    this.handleChangeCarrier(value)
                  }}
                  value={this.state.carrier}
                  errorText={formValidation && formValidation.carrier && `${trl.common__field_required}`}
                  style={{
                    border: '1px solid rgb(206, 212, 218)',
                    borderRadius: 4,
                    fontSize: theme.font.fontSize
                  }}
                  inputStyle={{
                    paddingLeft: '3%'
                  }}
                  className="formElementText"
                  name="service"
                  underlineStyle={{ display: 'none' }}
                >
                  {
                    carrierList && carrierList.map(item => <MenuItem style={{ fontSize: theme.font.fontSize }} value={item.id} primaryText={item.label} />)
                  }
              </SelectField>
            </div>
            {formValidation.touch && !formValidation.valid && (
              <div>
                <p style={{
                  color: '#ff0000',
                  textAlign: 'center',
                  fontSize: '12px',
                }}>{formValidation.error}</p>
              </div>
            )}
            <FormAwbWrapper>
              <FormAwbContainer>

                <InputLabelWrapper>
                  <Label> {` ${trl.defu_modalAWB_label_EnterAwb}`}</Label>
                  <TextField
                    id="product-catalog-search-value"
                    type="text"
                    style={{
                      height: '30px',
                      marginLeft: '10px',
                      marginRight: '8px',
                      width: '150px',
                      fontSize: 'theme.font.fontSize'
                    }}
                    underlineStyle={{
                      borderTop: 'none rgb(0, 0, 0)',
                      borderLeft: 'none rgb(0, 0, 0)',
                      borderRight: 'none rgb(0, 0, 0)',
                      borderBottom: '1px solid rgb(0, 0, 0)'
                    }}
                    value={this.state.awbText}
                    onChange={this.handleChange}
                  />
                </InputLabelWrapper>
                <CheckboxContainer>
                  <Label>
                    {trl.defu_modalAWB_checkbox_selectAll}
                  </Label>
                  <Checkbox
                    onCheck={this.handleAwbBoxListAll}
                    checked={this.state.awbBoxListAll}
                  />
                </CheckboxContainer>
                {
                  !isNull(row.packingList) &&
                  map(row.packingList.containers, (elem, id) => (
                    <CheckboxContainer>
                      <Label>
                        {elem.label}
                      </Label>
                      <Checkbox
                        key={id}
                        onCheck={() => this.handleAwbBoxList(elem.idContainer)}
                        checked={
                          includes(this.state.awbBoxList, elem.idContainer) ||
                          !isNull(elem.awb)
                        }
                        disabled={!isNull(elem.awb)}
                      />
                    </CheckboxContainer>

                  ))
                }
              </FormAwbContainer>
            </FormAwbWrapper>
          </ModalFormWrapper>
        </Dialog>
        <Dialog
          title={trl.defu_modalCancelDelivery_title}
          actions={actionsCancel}
          modal={false}
          open={this.state.openCancelModal}
        >
          <p>{trl.defu_modalCancelDelivery_wrnMess}</p>
        </Dialog>
      </div>
    )
  }
}

DeliveryFllowUpActions.contextTypes = {
  store: React.PropTypes.object.isRequired
}

DeliveryFllowUpActions.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ({ languages, me, delivery, auth }) => ({
  trl: languages.siteLanguage.keys,
  company_type: me.company_type,
  myCompanyId: me.iD_Societe,
  additional: delivery.additional,
  errors: delivery.errors,
  carrierList: delivery.carrierList,
  currentUserRole: auth.user_role
})

export default connect(
  mapStateToProps,
  { apiCancelDelivery, apiAwpDelivery, setIsAWBFalse, apiGetCarrierData }
)(DeliveryFllowUpActions)
