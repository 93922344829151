///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppDialogAlert } from './appAlerts'

// GET MY CARRIERs
function apiGetMyCarriersDataRequest(endpointPath) {
  return { type: c.API_GET_MY_CARRIERS_DATA_REQUEST, endpointPath }
}
function apiGetMyCarriersDataSuccess(endpointPath, res) {
  return { type: c.API_GET_MY_CARRIERS_DATA_SUCCESS, payload: res }
}
function apiGetMyCarriersDataFailure(endpointPath, err) {
  return { type: c.API_GET_MY_CARRIERS_DATA_FAILURE, endpointPath }
}
export function apiGetMyCarriersData({ iDSociete }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${iDSociete}${c.carriersData}`,
    h.headerGetWithToken(),
    apiGetMyCarriersDataRequest,
    apiGetMyCarriersDataSuccess,
    apiGetMyCarriersDataFailure
  )
}

// GET MY CARRIERs
function apiGetMyCarriersRequest(endpointPath) {
  return { type: c.API_GET_MY_CARRIERS_REQUEST, endpointPath }
}
function apiGetMyCarriersSuccess(endpointPath, res) {
  return { type: c.API_GET_MY_CARRIERS_SUCCESS, payload: res }
}
function apiGetMyCarriersFailure(endpointPath, err) {
  return { type: c.API_GET_MY_CARRIERS_FAILURE, endpointPath }
}
export function apiGetMyCarriers({ iDSociete }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${iDSociete}`,
    h.headerGetWithToken(),
    apiGetMyCarriersRequest,
    apiGetMyCarriersSuccess,
    apiGetMyCarriersFailure
  )
}

// DELETE CARRIERS
function apiDeleteCarrierRequest(endpointPath) {
  return { type: c.API_DELETE_MY_CARRIER_REQUEST, endpointPath }
}
function apiDeleteCarrierSuccess(endpointPath, res) {
  return { type: c.API_DELETE_MY_CARRIER_SUCCESS, payload: res }
}
function apiDeleteCarrierFailure(endpointPath, err) {
  return { type: c.API_DELETE_MY_CARRIER_FAILURE, endpointPath }
}
export function apiDeleteCarrier({ iDSociete, idCarrier }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${iDSociete}${c.carrier}${idCarrier}`,
    h.headerDeleteWithToken(),
    apiDeleteCarrierRequest,
    apiDeleteCarrierSuccess,
    apiDeleteCarrierFailure
  )
}

// EDIT CARRIERS
function apiEditCarrierRequest(endpointPath) {
  return { type: c.API_EDIT_MY_CARRIER_REQUEST, endpointPath }
}
function apiEditCarrierSuccess(endpointPath, res) {
  return { type: c.API_EDIT_MY_CARRIER_SUCCESS, payload: res }
}
function apiEditCarrierFailure(endpointPath, err) {
  return { type: c.API_EDIT_MY_CARRIER_FAILURE, endpointPath }
}
export function apiEditCarrier({ IDSociete, IdCarrier, Model }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${IDSociete}${c.carrier}${IdCarrier}`,
    h.headerPutWithTokenAndModel(Model),
    apiEditCarrierRequest,
    apiEditCarrierSuccess,
    apiEditCarrierFailure
  )
}

// ADD CARRIERS
function apiAddCarrierRequest(endpointPath) {
  return { type: c.API_ADD_MY_CARRIER_REQUEST, endpointPath }
}
function apiAddCarrierSuccess(endpointPath) {
  return { type: c.API_ADD_MY_CARRIER_SUCCESS, endpointPath }
}
function apiAddCarrierFailure(endpointPath, err) {
  return { type: c.API_ADD_MY_CARRIER_FAILURE, endpointPath }
}
export function
apiAddCarrier({ iDSociete, model }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${iDSociete}${c.apiCarriernew}`,
    h.headerPostWithTokenAndModel(model),
    apiAddCarrierRequest,
    apiAddCarrierSuccess,
    apiAddCarrierFailure
  )
}

// ADD ADDRESS
function apiAddAddressRequest(endpointPath) {
  return { type: c.API_ADD_MY_ADDRESS_REQUEST, endpointPath }
}
function apiAddAddressSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppDialogAlert('myaddresses_modal2_textMessage', true))
    dispatch({ type: c.API_ADD_MY_ADDRESS_SUCCESS, payload: res })
  }
}
function apiAddAddressFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppDialogAlert(err.message, true))
    dispatch({ type: c.API_ADD_MY_ADDRESS_FAILURE, endpointPath, error: err.message })
  }
}
export function apiAddAddress({ iDSociete, model }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${iDSociete}${c.apiAddressnew}`,
    h.headerPostWithTokenAndModel(model),
    apiAddAddressRequest,
    apiAddAddressSuccess,
    apiAddAddressFailure
  )
}

// EDIT ADDRESS
function apiEditAddressRequest(endpointPath) {
  return { type: c.API_EDIT_MY_ADDRESS_REQUEST, endpointPath }
}
function apiEditAddressSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppDialogAlert('myaddresses_modal2_textMessage', true))
    dispatch({ type: c.API_EDIT_MY_ADDRESS_SUCCESS, payload: res })
  }
}
function apiEditAddressFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppDialogAlert(err.message, true))
    dispatch({ type: c.API_EDIT_MY_ADDRESS_FAILURE, endpointPath, error: err.message })
  }
}
export function apiEditAddress({ iDSociete, idAddress, model }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${iDSociete}${c.address}${idAddress}`,
    h.headerPutWithTokenAndModel(model),
    apiEditAddressRequest,
    apiEditAddressSuccess,
    apiEditAddressFailure
  )
}

// DELETE Address
function apiDeleteAddressRequest(endpointPath) {
  return { type: c.API_DELETE_MY_ADDRESS_REQUEST, endpointPath }
}
function apiDeleteAddressSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppDialogAlert('myaddresses_modal2_textMessage', true))
    dispatch({ type: c.API_DELETE_MY_ADDRESS_SUCCESS, payload: res })
  }
}
function apiDeleteAddressFailure(endpointPath, err) {
  return { type: c.API_DELETE_MY_ADDRESS_FAILURE, endpointPath }
}
export function apiDeleteAddress({ iDSociete, idAddress }) {
  return u.getValueFromStore(
    `${c.api}${c.company}${iDSociete}${c.address}${idAddress}`,
    h.headerDeleteWithToken(),
    apiDeleteAddressRequest,
    apiDeleteAddressSuccess,
    apiDeleteAddressFailure
  )
}
