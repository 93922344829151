///////COMPONENTS///////
import * as c from '../constants'
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET FILE ORDER
function apiGetFileOrderFollowUpRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_FILE_ORDER_FOLLOW_UP_REQUEST, endpointPath })
  }
}
function apiGetFileOrderFollowUpSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_FILE_ORDER_FOLLOW_UP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_FILE_ORDER_FOLLOW_UP,
      payload: payload
    })
  }
}
function apiGetFileOrderFollowUpFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_FILE_ORDER_FOLLOW_UP_FAILURE, endpointPath })
  }
}
export function apiGetFileOrderFollowUp(data) {
  return u.getValueFromStore(
    c.apiGetFileOrderFollowUp,
    h.headerPutWithToken(data),
    apiGetFileOrderFollowUpRequest,
    apiGetFileOrderFollowUpSuccess,
    apiGetFileOrderFollowUpFailure
  )
}

// GET FILE ORDER errors
function apiGetFileOrderErrorsRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_FILE_ORDER_ERRORS_REQUEST, endpointPath })
  }
}
function apiGetFileOrderErrorsSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_FILE_ORDER_ERRORS_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_FILE_ORDER_ERRORS,
      payload: payload
    })
  }
}
function apiGetFileOrderErrorsFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_FILE_ORDER_ERRORS_FAILURE, endpointPath })
  }
}
export function apiGetFileOrderErrors(fileID) {
  return u.getValueFromStore(
    `${c.apiGetFileOrderFollowUp}/${fileID}${c.apiGetFileOrderErrors}`,
    h.headerGetWithToken(),
    apiGetFileOrderErrorsRequest,
    apiGetFileOrderErrorsSuccess,
    apiGetFileOrderErrorsFailure,
    false,
    { forceUpdate: true }
  )
}

// GET FILE ORDER download
function apiGetFileOrderDownloadRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_FILE_ORDER_DOWNLOAD_FILE_REQUEST, endpointPath })
  }
}
function apiGetFileOrderDownloadSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_FILE_ORDER_DOWNLOAD_FILE_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_FILE_ORDER_DOWNLOAD_FILE,
      payload: payload
    })
  }
}
function apiGetFileOrderDownloadFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_FILE_ORDER_DOWNLOAD_FILE_FAILURE, endpointPath })
  }
}
export function apiGetFileOrderFileDownload(fileID) {
  return u.getValueFromStore(
    `${c.apiGetFileOrderFollowUp}/${fileID}${c.apiGetFileOrderFileDownload}`,
    h.headerGetFileWithToken(),
    apiGetFileOrderDownloadRequest,
    apiGetFileOrderDownloadSuccess,
    apiGetFileOrderDownloadFailure,
    false,
    { forceUpdate: true }
  )
}

// CANCEL FILE ORDER
function apiCancelFileOrderRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_CANCEL_FILE_ORDER_REQUEST, endpointPath })
  }
}
function apiCancelFileOrderSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_CANCEL_FILE_ORDER_SUCCESS, endpointPath })
    dispatch({
      type: c.API_CANCEL_FILE_ORDER,
      payload: payload
    })
  }
}
function apiCancelFileOrderFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_CANCEL_FILE_ORDER_FAILURE, endpointPath })
  }
}
export function apiCancelFileOrder(fileID) {
  return u.getValueFromStore(
    `${c.apiGetFileOrderFollowUp}/${fileID}${c.apiCancelFileOrder}`,
    h.headerPutWithToken(),
    apiCancelFileOrderRequest,
    apiCancelFileOrderSuccess,
    apiCancelFileOrderFailure
  )
}
