////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SortableHeader } from 'sematable'
import { get } from 'lodash'
///////COMPONENTS///////
import BATsPendingActions from '../Actions/BATsPendingActions'
import Sku from '../ColumnHelpers/Sku'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

class OrderSkuTableBat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRow: null,
      openModalSku: false,
      aztecFormData: [],
      skuLine: {},
      columns: []
    }
    this.toggleActivePosition = this.toggleActivePosition.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const hasBarCode = this.props.articleVariable.hasBarCode
    this.setState({
      columns: [
        { key: 'modele', header: translate('order_EditVariableItem_TableTitle_Model'), primaryKey: true, searchable: true, sortable: true, Component: Sku },
        { key: 'couleur', header: translate('order_EditVariableItem_TableTitle_Color'), searchable: true, sortable: true, Component: Sku },
        { key: 'taille', header: translate('order_EditVariableItem_TableTitle_Size'), searchable: true, sortable: true, Component: Sku },
        { key: 'barcode', header: translate('order_EditVariableItem_TableTitle_Barcode'), searchable: true, sortable: true, Component: Sku, hidden: !hasBarCode },
        { key: 'quantity', header: translate('order_EditVariableItem_TableTitle_Quantity'), sortable: true, Component: Sku },
        { key: 'actions', header: translate('ofu_lvlOrder_tableTitle_Action'), Component: BATsPendingActions }
      ]
    })
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  myColor(type, position) {
    return this.state[type] === position ? 'rgba(0, 0, 0, 0.2)' : ''
  }

  toggleActivePosition(type, position) {
    this.setState({ [type]: position })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, articleVariable = {} } = this.props
    const formData = get(articleVariable, 'article_Champ_VariableData', [])
    const { columns } = this.state
    return (
      <div>
        <TableContainer>
          <div className="tableWrapperBorder">
            <div className="table-responsive order-detail-follow-up-table">
              <table className="table table-sm table-striped table-hover">
                <thead>
                  <tr>
                    {columns.map(c => (
                      c.hidden ?
                        null
                        :
                        <SortableHeader
                          style={{ fontSize: 16 }}
                          {...c}
                          sortKey={c.key}
                          name={c.header}
                          handleClick={() => null}
                        />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((skus, index) => {
                    const listDVSToObj = {}
                    skus.listDVS.map(d => { listDVSToObj[d.type] = Object.assign({}, d) })
                    const objectData = {
                      row: { ...skus, listDVSObj: listDVSToObj },
                      toggleActivePosition: this.toggleActivePosition,
                      activeRow: index
                    }
                    return (
                      <tr key={index} style={{ backgroundColor: this.myColor('activeRow', index) }}>
                        {columns.map((c, key) => {
                          if (c.hidden) return null
                          return (
                            <td key={key}>
                              {c.Component
                                ? React.createElement(c.Component, {
                                  ...objectData,
                                  modelKey: c.key,
                                  formData,
                                  iD_Commande: articleVariable.iD_Commande,
                                  iD_Commande_Article: articleVariable.iD_Commande_Article,
                                  handelOpenModalSku: this.handelOpenModalSku
                                })
                                : skus[c.key]}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </TableContainer>
      </div>
    )
  }
}

OrderSkuTableBat.propTypes = {
  data: PropTypes.array.isRequired,
  articleVariable: PropTypes.object.isRequired,
  handelUpdateOrAddSku: PropTypes.func
}
export default OrderSkuTableBat

