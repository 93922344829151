////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import styled from 'styled-components'
import { get } from 'lodash'
///////COMPONENTS///////
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'
import PageLoadTable from '../../PageLoadTable'

/////////STYLED/////////
const TableStock = styled.table`
  & thead > tr {
    border: none;
  }
  & thead > tr > th {
    border: none;
  }
  & tbody {
    border-top: 2px solid black;
  }
`
const TitleTableColumns = styled.td`
  font-weight: bold;
  position: relative;
`
const LeftSeparator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 112px;
  width: 1px;
  background-color: black;
`
const RightSeparator = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  height: 112px;
  width: 1px;
  background-color: black;
`
/////////STYLED/////////

const columns = [
  { key: 'idItem', primaryKey: true, hidden: true },
  { key: 'iD_Article_Externe_SAP', header: translate('stofu_tableTitle_CTCItem'), sortable: true },
  { key: 'iD_Article_Externe_Enseigne', header: translate('stofu_tableTitle_brandItem'), sortable: true },
  { key: 'article_Name', header: translate('stofu_tableTitle_itemDescription'), sortable: true },
  { key: 'article_Type_Name', header: translate('stofu_tableTitle_itemType'), sortable: true },
  { key: 'qte_Article_WH_Paris', header: translate('stofu_tableTitle_qtyInWarehouse_Paris'), sortable: true },
  { key: 'qte_Article_WH_HK', header: translate('stofu_tableTitle_qtyInWarehouse_HK'), sortable: true },
  { key: 'qte_Article_WH_China', header: translate('stofu_tableTitle_qtyInWarehouse_China'), sortable: true },
  { key: 'qte_Article_WH_Turkey', header: translate('stofu_tableTitle_qtyInWarehouse_turkey'), sortable: true },
  { key: 'qte_Article_Consigned', header: translate('stofu_tableTitle_qtyConsigned'), sortable: true },
  { key: 'qte_Article_Production', header: translate('stofu_tableTitle_qtyInProduction'), sortable: true },
  { key: 'qte_Open_Order_Paris', header: translate('stofu_tableTitle_qtyOpenOrders_Paris'), sortable: true },
  { key: 'qte_Open_Order_HK', header: translate('stofu_tableTitle_qtyOpenOrders_HK'), sortable: true },
  { key: 'qte_Open_Order_China', header: translate('stofu_tableTitle_qtyOpenOrders_China'), sortable: true },
  { key: 'qte_Open_Order_Turkey', header: translate('stofu_tableTitle_qtyOpenOrders_turkey'), sortable: true },
  { key: 'qte_Article_Projection', header: translate('stofu_tableTitle_qtyAvailable'), sortable: true }
]

const config = {
  defaultPageSize: 50,
  showPageSize: false,
  showFilter: false
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  isLoading: PropTypes.boolean,
  trl: PropTypes.object.isRequired,
  showPagination: PropTypes.boolean
}

class StockFollowUpTable extends Component {
  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { headers, data, trl, showPagination } = this.props
    config.showPageSize = showPagination
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <TableStock className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  <th colSpan={4}></th>
                  <TitleTableColumns colSpan={4}>
                    <LeftSeparator />
                    {translate('stofu_tableTitle_qtyInWarehouse')}
                    <RightSeparator />
                  </TitleTableColumns>
                  <th colSpan={2}></th>
                  <TitleTableColumns colSpan={4}>
                    <LeftSeparator />
                    {translate('stofu_tableTitle_qtyOpenOrders')}
                    <RightSeparator />
                  </TitleTableColumns>
                  <th colSpan={1}></th>
                </tr>
                <tr>
                  <SortableHeader {...headers.iD_Article_Externe_SAP} />
                  <SortableHeader {...headers.iD_Article_Externe_Enseigne} />
                  <SortableHeader {...headers.article_Name} />
                  <SortableHeader {...headers.article_Type_Name} />
                  <SortableHeader {...headers.qte_Article_WH_Paris} />
                  <SortableHeader {...headers.qte_Article_WH_HK} />
                  <SortableHeader {...headers.qte_Article_WH_China} />
                  <SortableHeader {...headers.qte_Article_WH_Turkey} />
                  <SortableHeader {...headers.qte_Article_Consigned} />
                  <SortableHeader {...headers.qte_Article_Production} />
                  <SortableHeader {...headers.qte_Open_Order_Paris} />
                  <SortableHeader {...headers.qte_Open_Order_HK} />
                  <SortableHeader {...headers.qte_Open_Order_China} />
                  <SortableHeader {...headers.qte_Open_Order_Turkey} />
                  <SortableHeader {...headers.qte_Article_Projection} />
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((item) => {
                    return (
                      <tr key={item.key}>
                        {columns.map((c) => {
                          if (c.hidden) return null
                          const children = get(item, c.key)
                          return (
                            <td key={'__' + c.key} className={c.className}>
                              {c.Component && c.Component !== null
                                ? React.createElement(c.Component, { row: item })
                                : children}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length + 1}>
                      {this.props.isLoading ? (
                        <PageLoadTable text={trl.common__loading} size={80} thickness={9} />
                      ) : (
                        <NoDataComponent />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </TableStock>
          </div>
        </div>
      </TableContainer>
    )
  }
}

StockFollowUpTable.propTypes = propTypes

export default sematable('StockFollowUpTable', StockFollowUpTable, columns, config)
