import * as c from '../constants'
import _, { cloneDeep, findIndex } from 'lodash'
import { translate } from '../containers/HOC/Translation'

const initialState = {
  filtersSettings: null,
  items: null,
  isLoading: false,
  isLoadingTable: false,
  xlsx: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API_GET_ITEM_FOLLOW_UP_REQUEST:
      return {
        ...state,
        isLoadingTable: true
      }

    case c.API_GET_ITEM_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        isLoadingTable: false
      }
    case c.API_GET_ITEM_FOLLOW_UP_FAILURE:
      return {
        ...state,
        isLoadingTable: false
      }
    case c.API_GET_ITEM_FOLLOW_UP_ID_REQUEST:
      return {
        ...state,
        isLoadingTable: true
      }

    case c.API_GET_ITEM_FOLLOW_UP_ID_SUCCESS:
      return {
        ...state,
        isLoadingTable: false
      }
    case c.API_GET_ITEM_FOLLOW_UP_ID_FAILURE:
      return {
        ...state,
        isLoadingTable: false
      }

    case c.API_GET_ITEM_FOLLOW_UP:
      action.payload.ifU_Filters.availableByParameters = _.chain(action.payload.ifU_Filters.availableByParameters)
        .map((elem) => {
          return { key: elem, value: translate(elem) }
        })
        .sortBy('value')
        .value()
      action.payload.ifU_Filters.availableByParameters.splice(0, 0, { key: 0, value: translate('common__all') })
      action.payload.ifU_Filters.availableBrands.splice(0, 0, { key: 0, value: 'common__all' })
      return {
        ...state,
        filtersSettings: { ...action.payload.ifU_Filters },
        items: [...action.payload.ifU_Details]
      }

    case c.API_GET_ITEM_FOLLOW_UP_ID:
      const oldState = cloneDeep(state.items)
      const idx = findIndex(oldState, { jpiOrderItem: action.payload.jpiOrderItem })
      oldState[idx] = action.payload
      return {
        ...state,
        items: oldState
      }

    case c.API_EXPORT_XL_ITEM_FOLLOW_UP_REQUEST:
      return {
        ...state,
        xlsx: null
      }

    case c.API_EXPORT_XL_ITEM_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        xlsx: action.payload
      }

    default:
  }
  return state
}
