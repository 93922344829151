////////LIBRARY/////////
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import theme from '../styledComponents/theme'
import { PageGutter } from '../styledComponents'
const { colors } = theme

/////////STYLED/////////
const PageHeaderContainer = styled.div`
  background: ${props => (props.bgColor ? props.bgColor : colors.white)};
  width: 100%;
  box-sizing: border-box;
  position: relative;
  top: 1.3rem;
`
const PageTitle = styled.h2`
  line-height: 40px;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: ${props => (props.color ? props.color : `${colors.black}`)};
  margin: 0;
  padding-top: 38px;
  border-bottom: 2px solid #DDD;
  margin-bottom: 22px;
`
const PageSubtitle = styled.h3`
  line-height: 1;
  font-weight: normal;
  font-size: 16px;
  margin-top: 10px;
  color: ${props => (props.color ? props.color : `${colors.black}`)};
`
const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`
const LogoStyled = styled.img`
  max-width: 200px;
`
const BrandNameStyled = styled.h5`
  text-align: center;
`
/////////STYLED/////////

///////////////////////////////// RENDER ///////////////////////////////////////

const PageHeader = ({ title, bgColor, subtitle, brandName, base64Logo }) => (
  <div>
    <PageHeaderContainer bgColor={bgColor}>
      <PageGutter>
        <ContentContainer>
          <div style={{minWidth:'30%'}}>
            <PageTitle>{title}</PageTitle>
            {subtitle && <PageSubtitle>{subtitle}</PageSubtitle>}
          </div>
          <div style={{ marginRight: '4%' }}>
            {brandName && <BrandNameStyled>{brandName}</BrandNameStyled>}
            {base64Logo && <LogoStyled src={base64Logo} />}
          </div>
        </ContentContainer>
      </PageGutter>
    </PageHeaderContainer>
  </div>
)

PageHeader.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  brandName: PropTypes.string,
  base64Logo: PropTypes.string
}

export default PageHeader
