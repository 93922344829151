////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { isEmpty, map, isNull, cloneDeep, isEqual } from 'lodash'
import moment from 'moment'
///////COMPONENTS///////
import { theme } from '../../components/styledComponents'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import dateFormatConverter from '../../utils/dateFormatConverter'
import DatePicker from 'material-ui/DatePicker'
import FileOrderFollowUpTable
  from '../../components/Sematable/Tables/FileOrderFollowUpTable'
import PageLoad from '../../components/PageLoad'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import Checkbox from 'material-ui/Checkbox'
import { apiGetFileOrderFollowUp } from '../../actions/fileOrderFollowUp'

/////////STYLED/////////
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const Check = styled(Checkbox)`
  width: auto !important;
`
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`
const LeftFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
  margin-bottom: 30px;
`
const RightFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  grid-auto-rows: max-content;
  align-items: center;
  margin-bottom: 30px;
`
const CheckboxWrapperRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 16px;
  justify-items: center;
  width: 100%;
  grid-column: 1 / 3;
`
const CheckboxFloatRow = styled.div`
  position: absolute;
  left: 0px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ? props.margin : '0'};
  position: relative;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
/////////STYLED/////////

class FileOrderFollowUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectBrandValue: 0,
      downloadDateStart: null,
      downloadDateEnd: null,
      integrationDateStart: moment(this.setIntegrationStartDate()).startOf('day').toDate(),
      integrationDateEnd: moment(this.setIntegrationEndDate()).toDate(),
      allOrdersValidatedDateStart: null,
      allOrdersValidatedDateEnd: null,
      status: [1, 2, 5],
      openFileErrorsModal: false,
      inputSearchBy: {
        'key': '',
        'value': 0
      },
      viewArchivedFiles: false,
      filtersSettings: {},
      items: [],
    }
    this.defaultState = cloneDeep(this.state)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.queryData(this.defaultState)
  }

  queryData = (defaultState) => {
    const { selectBrandValue, inputSearchBy } = this.state

    if (isEqual(defaultState, this.defaultState)) {
      this.props.apiGetFileOrderFollowUp({
        ID_Brand: this.defaultState.selectBrandValue === 0 ? null : this.defaultState.selectBrandValue,
        StartDownloadDate: this.defaultState.downloadDateStart,
        EndDownloadDate: this.defaultState.downloadDateEnd,
        StartIntegrationDate: this.defaultState.integrationDateStart,
        EndIntegrationDate: this.defaultState.integrationDateEnd,
        StartAllOrdersValidatedDate: this.defaultState.allOrdersValidatedDateStart,
        EndAllOrdersValidatedDate: this.defaultState.allOrdersValidatedDateEnd,
        status: this.defaultState.status,
        InputSearchBy: {
          key: this.defaultState.inputSearchBy.key,
          value: this.defaultState.inputSearchBy.value === 0 ? null : this.defaultState.inputSearchBy.value
        },
        SeeAchivedFile: this.state.viewArchivedFiles
      })
    } else {
      this.props.apiGetFileOrderFollowUp({
        ID_Brand: selectBrandValue === 0 ? null : selectBrandValue,
        StartDownloadDate: this.state.downloadDateStart,
        EndDownloadDate: this.state.downloadDateEnd != this.defaultState.integrationDateEnd ?
          moment.parseZone(this.state.downloadDateEnd).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.downloadDateEnd,
        StartIntegrationDate: this.state.integrationDateStart,
        EndIntegrationDate: this.state.integrationDateEnd != this.defaultState.integrationDateEnd ?
          moment.parseZone(this.state.integrationDateEnd).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.integrationDateEnd,
        StartAllOrdersValidatedDate: this.state.allOrdersValidatedDateStart,
        EndAllOrdersValidatedDate: this.state.allOrdersValidatedDateEnd != this.defaultState.integrationDateEnd ?
          moment.parseZone(this.state.allOrdersValidatedDateEnd).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.allOrdersValidatedDateEnd,
        status: this.state.status,
        InputSearchBy: {
          key: inputSearchBy.key,
          value: inputSearchBy.value === 0 ? null : inputSearchBy.value
        },
        SeeAchivedFile: this.state.viewArchivedFiles
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedBrandId, xlsx } = nextProps
    if (!this.props.xlsx && xlsx) setTimeout(() => { this.exportXlsx && this.exportXlsx.click() }, 1000)
    if (selectedBrandId !== this.state.selectBrandValue) this.setState({ selectBrandValue: isNull(selectedBrandId) ? 0 : selectedBrandId })
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  setIntegrationStartDate = () => {
    let startDate = new Date()
    startDate.setDate(startDate.getDay() - 14)
    return new Date(startDate)
  }

  setIntegrationEndDate = () => {
    let endDate = new Date()
    return new Date(endDate)
  }

  handleSelectBrandChange = (event, index, value) => {
    this.setState({ selectBrandValue: value })
  }

  handleSearchChange = e => {
    let currentSearch = cloneDeep(this.state.inputSearchBy)
    currentSearch.key = e.target.value
    this.setState({ inputSearchBy: currentSearch })
  }

  handleSearchByChange = (event, index, value) => {
    let currentSearch = cloneDeep(this.state.inputSearchBy)
    currentSearch.value = value
    this.setState({ inputSearchBy: currentSearch })
  }

  updateStateAny = (target, index, value) => {
    this.setState({ [target]: value })
  }

  handleReset = () => {
    this.setState(this.defaultState)
    this.queryData(this.defaultState)
  }

  handleArchivedFileFilter = () => {
    this.setState({ viewArchivedFiles: !this.state.viewArchivedFiles })
  }

  onStatusChangeHandler = filter => () => {
    let oldState = cloneDeep(this.state.status)
    const elemIdx = !isEmpty(this.state.status)
      && this.state['status'].includes(filter)
      ? this.state['status'].findIndex(e => e === filter)
      : -1

    elemIdx !== -1
      ? oldState.splice(elemIdx, 1)
      : oldState.push(filter)

    this.setState(state => ({ status: oldState }))
  }

  changeDateHandler = (e, date, target, dateEndOfDay = false) => {
    this.updateStateAny(target, '', moment(date).startOf('days').toDate())
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {
      trl,
      isLoading,
      availableByParameters,
      availableBrands,
      filesReturn,
      filtersSettings
    } = this.props
    if (!availableByParameters) {
      return (
        <PageLoad text={trl.common__loading} size={80} thickness={9} />)
    } else {
      return (
        <div>
          <PageHeader
            title={trl.fofu_title}
            subtitle={trl.fofo_subtitle}
          />
          <SC.PageGutter>
            <FilterAndTableWrapper>
              <TableWrapper text={trl.common__search}>
                <BodyTable>
                  <CheckboxWrapperRow>
                    {
                      map(filtersSettings.availableStatus, (elem, idx) => (
                        <Row key={idx}>
                          <Check
                            checked={
                              !isEmpty(this.state.status)
                                ? !!this.state['status'].find(e => e === elem.idStatus)
                                : false
                            }
                            onCheck={this.onStatusChangeHandler(elem.idStatus)} />
                          <Label
                            isTitle
                            bold
                            color={elem.color}
                          >
                            {trl[elem.traductionKey]}
                          </Label>
                        </Row>
                      ))
                    }
                  </CheckboxWrapperRow>
                  <LeftFiltersWrapper>
                    <Label>{trl.common__filter_selectBrand}</Label>
                    <SelectField
                      style={{
                        fontSize: theme.font.fontSize,
                        maxWidth: '195px',
                        height: '35px'
                      }}
                      iconStyle={{
                        margin: '0',
                        padding: '0'
                      }}
                      floatingLabelStyle={{
                        display: 'none'
                      }}
                      labelStyle={{
                        borderRadius: 0,
                        padding: '3px 30px 0 5px',
                        border: '1px solid #ced4da',
                        width: '100%',
                        height: '30px',
                        position: 'relative',
                        top: '4px',
                        lineHeight: 'inherit'
                      }}
                      underlineStyle={{
                        display: 'none'
                      }}
                      value={this.state.selectBrandValue}
                      onChange={this.handleSelectBrandChange}
                      autoWidth={true}
                    >
                      { availableBrands &&
                        availableBrands.map(param =>
                          <MenuItem
                            key={param.key} 
                            value={param.key}
                            primaryText={param.value}
                            style={{ fontSize: theme.font.fontSize }}
                          />)}{/**/}
                    </SelectField>
                    <InputLabelWrapper>
                      <Label>{trl.common__search} :</Label>
                      <TextField
                        id="product-catalog-search-value"
                        type="text"
                        style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                        value={this.state.inputSearchBy.key}
                        onChange={this.handleSearchChange}
                        onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                      />
                      <Label style={{ marginLeft: 10 }}>{trl.common__by} :</Label>
                    </InputLabelWrapper>
                    <InputLabelWrapper>
                      <SelectField
                        style={{
                          fontSize: theme.font.fontSize,
                          maxWidth: '195px',
                          height: '35px'
                        }}
                        iconStyle={{
                          margin: '0',
                          padding: '0'
                        }}
                        floatingLabelStyle={{
                          display: 'none'
                        }}
                        labelStyle={{
                          borderRadius: 0,
                          padding: '3px 30px 0 5px',
                          border: '1px solid #ced4da',
                          width: '100%',
                          height: '30px',
                          position: 'relative',
                          top: '4px',
                          lineHeight: 'inherit'
                        }}
                        underlineStyle={{
                          display: 'none'
                        }}
                        value={this.state.inputSearchBy.value}
                        onChange={this.handleSearchByChange}
                        autoWidth={true}
                      >
                        {filtersSettings && filtersSettings.availableByParameters &&
                          filtersSettings.availableByParameters.map(param =>
                            <MenuItem
                              key={param.key} value={param.key}
                              primaryText={param.value}
                              style={{ fontSize: theme.font.fontSize }}
                            />)}
                      </SelectField>
                    </InputLabelWrapper>
                  </LeftFiltersWrapper>
                  <RightFiltersWrapper>
                    <Label>{trl.fofu_tableTitle_fileDownloadDate} :</Label>

                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0',
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '25%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_from}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'downloadDateStart')}
                      value={this.state.downloadDateStart}
                      maxDate={this.state.downloadDateEnd}
                      formatDate={dateFormatConverter}
                    />

                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0'
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '25%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        marginBottom: '5px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_to}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'downloadDateEnd', true)}
                      value={this.state.downloadDateEnd}
                      minDate={this.state.downloadDateStart}
                      formatDate={dateFormatConverter}
                    />

                    <Label>{trl.fofu_tableTitle_fileIntegrationDate} :</Label>

                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0'
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '25%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_from}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'integrationDateStart')}
                      value={this.state.integrationDateStart}
                      maxDate={this.state.integrationDateEnd}
                      formatDate={dateFormatConverter}
                    />

                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0'
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '25%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        marginBottom: '5px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_to}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'integrationDateEnd', true)}
                      value={this.state.integrationDateEnd}
                      minDate={this.state.integrationDateStart}
                      formatDate={dateFormatConverter}
                    />

                    <Label>{trl.fofu_tableTitle_fileAllOrdersValidatedDate} :</Label>

                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0'
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '25%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_from}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'allOrdersValidatedDateStart')}
                      value={this.state.allOrdersValidatedDateStart}
                      maxDate={this.state.allOrdersValidatedDateEnd}
                      formatDate={dateFormatConverter}
                    />

                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0'
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '25%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        marginBottom: '5px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_to}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'allOrdersValidatedDateEnd', true)}
                      value={this.state.allOrdersValidatedDateEnd}
                      minDate={this.state.allOrdersValidatedDateStart}
                      formatDate={dateFormatConverter}
                    />
                  </RightFiltersWrapper>
                  <TableRow margin={'10px 0'}>
                    <CheckboxFloatRow style={{ zIndex: '1' }}>
                      <Checkbox
                        labelStyle={{ width: 'initial' }}
                        label={trl.common__filter_displayArchivedFiles}
                        checked={this.state.viewArchivedFiles}
                        onCheck={this.handleArchivedFileFilter}
                      />
                      <div style={{ justifySelf: 'start', width: '45%', color: 'red' }}>{trl.fofu_tableLegend_archivingRule}<span style={{ width: '10px', height: '10px', borderRadius: '66%', backgroundColor: '#2e74b2', marginLeft: '2px', marginBottom: '-1px', display: 'inline-block' }}></span></div>
                    </CheckboxFloatRow>
                    <ButtonWrapper style={{ zIndex: '2' }}>
                      <SC.ButtonPA_M
                        onClick={this.queryData}>
                        {trl.common__filter_apply_btn}
                      </SC.ButtonPA_M>
                      <SC.ButtonR_M
                        onClick={this.handleReset}>{trl.common__filter_reset_btn}</SC.ButtonR_M>
                    </ButtonWrapper>
                  </TableRow>
                </BodyTable>
              </TableWrapper>
            </FilterAndTableWrapper>
            <div>
              <FileOrderFollowUpTable
                data={filesReturn}
                isLoading={isLoading}
                trl={trl}
                showPagination
                handleOpenFileErrors={this.handleOpenFileErrors}
              />
            </div>
          </SC.PageGutter >
        </div >
      )
    }

  }
}

FileOrderFollowUp
  .propTypes = {}

FileOrderFollowUp
  .defaultProps = {}

const
  mapStateToProps = state => ({
    isLoadingGlobal: state.appAlerts.loading && state.appAlerts.loading.isLoading,
    ...state.fileOrderFollowUp
  })

export default connect(mapStateToProps, {
  apiGetFileOrderFollowUp,
})(FileOrderFollowUp)
