////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { DatePicker } from 'redux-form-material-ui'
///////COMPONENTS///////
import FlatButton from 'material-ui/FlatButton'
import CircularProgress from 'material-ui/CircularProgress'
import { styles } from '../misc/formStyles'
import dateFormatConverter from '../../../utils/dateFormatConverter'

class WishedExpeditionDate extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const newInitialValue = new Date(this.props.initialValues.date_Expedition_Souhaitee)
    this.props.initialize({ date_Expedition_Souhaitee: newInitialValue })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  formatDateApi = date => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00.000`

  onSubmit = formValues => {
    const { date_Expedition_Souhaitee } = formValues
    const { orderId, apiUpdateWishedExpeditionDate } = this.props
    const userModel = {
      iD_Commande: orderId,
      date_Expedition_Souhaitee: this.formatDateApi(date_Expedition_Souhaitee)
    }
    return apiUpdateWishedExpeditionDate(orderId, userModel)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, error, handleSubmit, submitting } = this.props
    return (
      <form
        onSubmit={handleSubmit(this.onSubmit.bind(this))}
        style={{ display: 'flex', justfifyContent: 'flex-end', paddingRight: 20 }}
      >
        <p style={{ margin: '11px 10px 0 0' }}>{trl.common__date_exp_wished}: </p>
        <Field
          name="date_Expedition_Souhaitee"
          component={DatePicker}
          format={null}
          formatDate={dateFormatConverter}
          textFieldStyle={{ maxWidth: 100 }}
          fullWidth={false}
        />
        <div style={{ float: 'left', transform: 'translateY(-5px)' }}>
          {!submitting && (
            <FlatButton
              label={trl.admin_user_edit__save_btn || ' '}
              style={{ ...styles.loginBtn, margin: '10px 0 0 0' }}
              disabled={submitting}
              type="submit"
            />
          )}
          {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
        </div>
        <div>{error && <p style={styles.loginError}>{error}</p>}</div>
      </form>
    )
  }
}

WishedExpeditionDate.propTypes = {
  trl: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  apiUpdateWishedExpeditionDate: PropTypes.func.isRequired
}

WishedExpeditionDate = reduxForm({
  form: 'WishedExpeditionDateForm',
  touchOnBlur: false,
  errors: {}
})(WishedExpeditionDate)

export default WishedExpeditionDate
