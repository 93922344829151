////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import { P2 } from '../components/styledComponents'
import { css } from 'styled-components'

/////////STYLED/////////
const P2Default = css`
  font-size: 12px;
`
const Text = P2.extend`
  ${P2Default};
`
/////////STYLED/////////

const DisplayAddresses = ({ path, trl }) => {
  ///////////////////////////////// RENDER ///////////////////////////////////////

  if (!path) return null
  return (
    <div>
      <Text>
        <b>{path.name}</b>
      </Text>
      {path.ligne_1 && <Text>{path.ligne_1}</Text>}
      {path.ligne_2 && <Text>{path.ligne_2}</Text>}
      {path.ligne_3 && <Text>{path.ligne_3}</Text>}
      {path.ligne_4 && <Text>{path.ligne_4}</Text>}
      {path.ligne_5 && <Text>{path.ligne_5}</Text>}
      <Text>
        {path.postalCode && <span style={{ marginRight: 14 }}>{path.postalCode}</span>}
        {path.city && ` ${path.city}`}
      </Text>
      {path.pays && <Text>{path.pays}</Text>}
      <br />
      {path.contact2 && (
        <Text>
          {trl.common__address_contact}: {path.contact2}
        </Text>
      )}
    </div>
  )
}

DisplayAddresses.propTypes = {
  path: PropTypes.object,
  trl: PropTypes.object.isRequired
}

export default DisplayAddresses
