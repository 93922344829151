////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'

///////////////////////////////// RENDER ///////////////////////////////////////

const NullIfZero = ({ children }) => {
  return <span>{children === 0 ? '-' : children}</span>
}

NullIfZero.propTypes = {
  children: PropTypes.number
}
export default NullIfZero
