import * as c from '../constants'

const INITIAL_STATE = { printShops: [], error: false, loading: false }

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.FETCH_PRINTSHOPS:
      return { ...state, printShops: action.payload }

    case c.EDIT_PRINTSHOP:
      return {
        ...state,
        printShops: state.printShops.map((printshop) =>
          printshop.idCompany === action.payload.idCompany ? { ...action.payload } : printshop
        )
      }

    case c.PUT_PRINTSHOPS_REQUEST:
      return { ...state, loading: true }

    case c.PUT_PRINTSHOPS_SUCCESS:
      return { ...state, error: false, loading: false }

    case c.PUT_PRINTSHOPS_FAILURE:
      return { ...state, error: true, loading: false }

    default:
      break
  }
  return state
}
