////////LIBRARY/////////
import React from 'react'
import styled from 'styled-components'
///////COMPONENTS///////
import { Div, H2, P1, P3, H5, theme } from '../styledComponents'
import { translate } from '../../containers/HOC/Translation'
/////////ASSETS/////////
import Phone from 'material-ui/svg-icons/communication/phone'
import Email from 'material-ui/svg-icons/communication/email'
import Place from 'material-ui/svg-icons/maps/place'

/////////STYLED/////////
const HelpComponentContainer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 100px;
  border: 1px solid ${theme.colors.black};
  border-bottom: 2px solid black;
`
const HelpContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background-color: ${theme.colors.white};
  padding: 14px;
  & > div:first-child {
    margin-right: 14px;
  }
  H5 { 
    margin-bottom: 3px;
  }
  span {
    margin-left: 4px;
    transform: translateY(-4px);
    display: inline-block;
    font-size: 12px;
  }
`
const H5Help = H5.extend`
  white-space: nowrap;
`
const PlaceContainer = styled.div`
  display: flex;
`
/////////STYLED/////////

const styles = {
  icon: { height: 40, width: 'auto', paddingRight: 10 },
  content: { backgroundColor: theme.colors.lightGrey },
  row: { marginRight: 20, marginLeft: 20 },
}

///////////////////////////////// RENDER ///////////////////////////////////////

const HelpComponent = () =>
  <HelpComponentContainer>
    <Div style={{ textAlign: 'left' }} bgColor={theme.colors.black} color={theme.colors.white} padding={2}>
      <H2>{translate("common__needHelp_title")}</H2>
    </Div>
    <HelpContent style={styles.content}>
      <div style={styles.row}>
        <P1> CTC Paris </P1>
        <br />
        <H5Help>
          <Phone style={styles.icon} />
          <span>+33 1 42 96 00 92</span>
        </H5Help>
        <P1><Email style={styles.icon} /><span><a href="mailto:customer-service@closetoclothes.com">customer-service@closetoclothes.com</a></span></P1>
        <br />
        <PlaceContainer>
          <Place style={styles.icon} />
          <div>
            <P3>Close to Clothes</P3>
            <H5Help>25 RUE HAUTEVILLE</H5Help>
            <H5Help>fond de cour</H5Help>
            <H5Help>75010 PARIS</H5Help>
          </div>
        </PlaceContainer>
      </div>
      <div style={styles.row}>
        <P1> CTC ASIA PACIFIC</P1>
        <br />
        <H5Help><Phone style={styles.icon} /><span>+852 3621 0388</span></H5Help>
        <P1><Email style={styles.icon} /><span><a href="mailto:customer-service-hk@closetoclothes.com">customer-service-hk@closetoclothes.com</a></span></P1>
        <br />
        <PlaceContainer>
          <Place style={styles.icon} />
          <div>
            <P3>Close to Clothes ASIA PACIFIC</P3>
            <H5Help>Unit 04</H5Help>
            <H5Help>4/F</H5Help>
            <H5Help>Join-In Hang Sing Centre</H5Help>
            <H5Help>2-16 Kwai Fung Crescent</H5Help>
            <H5Help>Kwai Chung </H5Help>
            <H5Help>Kowloon </H5Help>
            <H5Help>Hong Kong</H5Help>
          </div>
        </PlaceContainer>
      </div>
    </HelpContent>
  </HelpComponentContainer>

export default HelpComponent