import * as c from '../constants'

const INITIAL_STATE = {
  deliveries: [],
  waitingForDeliveryCount: 0,
  deliveriesFilters: {},
  isLoading: false,
  errors: {},
  //?????????
  delivery: {},
  additional: {},
  // when awb modal ok btn is clicked we set value at true
  isAWBSuccess: false,
  carrierList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_CARRIER_DATA_REQUEST:
      return { ...state, carrierList: [] }
    case c.API_GET_CARRIER_DATA_SUCCESS:
      return { ...state, carrierList: action.payload }
    case c.API_GET_CARRIER_DATA_FAILURE:
      return { ...state, carrierList: [] }
    case c.API_GET_DELIVERIES_FOLLOW_UP_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_GET_DELIVERIES_FOLLOW_UP_FAILURE:
      return { ...state, isLoading: false }
    case c.API_GET_DELIVERIES_FOLLOW_UP_SUCCESS:
      return { ...state, isLoading: false, errors: {} }
    case c.API_GET_DELIVERY_REQUEST:
      return { ...state, isLoading: true, errors: {}, additional: {} }
    case c.API_GET_DELIVERY_FAILURE:
      return { ...state, isLoading: false, errors: {}, additional: {} }
    case c.API_GET_DELIVERY_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_SAVE_PRINT_PACKING_LIST_REQUEST:
      return { ...state, isLoading: true, errors: {}, additional: {} }
    case c.API_SAVE_PACKING_LIST_REQUEST:
      return { ...state, errors: {}, additional: {} }
    case c.API_SAVE_PACKING_LIST_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: { goToNextPage: true } }
    case c.API_SAVE_PACKING_LIST_FAILURE:
      return { ...state, errors: {}, additional: {} }
    case c.API_SAVE_PRINT_PACKING_LIST_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_SAVE_PRINT_PACKING_LIST_FAILURE:
      return { ...state, errors: { savePrint: action.payload }, isLoading: false }
    case c.API_UPDATE_PACKING_LIST_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_DELETE_PACKING_LIST_BOX_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_DELETE_PACKING_LIST_BOX_ITEM_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_ADD_PACKING_LIST_BOX_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_ADD_ITEM_PACKING_LIST_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_DELETE_PACKING_LIST_SUCCESS:
      return { ...state, delivery: action.payload, isLoading: false, errors: {}, additional: {} }
    case c.API_DELIVERY_PRINT_PREPARATION_SHEET_SUCCESS:
      return {
        ...state,
        delivery: action.payload,
        errors: {},
        additional: {}
      }
    case c.API_DELIVERY_PRINT_INVOICE_SUCCESS:
      return {
        ...state,
        delivery: action.payload,
        errors: {},
        additional: {}
      }
    case c.API_CANCEL_DELIVERY_SUCCESS:
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          [action.payload.idDeliveryJPI]: action.payload
        },
        errors: {},
        additional: {}
      }
    case c.API_AWB_DELIVERY_REQUEST:
      return { ...state, errors: {}, additional: { awbStart: true } }
    case c.API_AWB_DELIVERY_SUCCESS:
      return {
        ...state,
        // we comment this line in order to prevent a unnecessary refresh
        /*     deliveries: {
              ...state.deliveries,
              [action.payload.idDeliveryJPI]: action.payload
            }, */
        errors: {},
        additional: {},
        isAWBSuccess: true
      }

    case c.SET_IS_AWB_TRUE:
      return {
        ...state,
        isAWBSuccess: true
      }
    case c.SET_IS_AWB_FALSE:
      return {
        ...state,
        isAWBSuccess: false
      }

    case c.API_AWB_DELIVERY_FAILURE:
      return { ...state, errors: { awb: action.payload }, isLoading: false, additional: {} }

    case c.API_GET_DELIVERIES_FOLLOW_UP:
      action.payload.deliveriesFilters.availableByParameters.splice(0, 0, 'common__all')
      return {
        ...state,
        deliveriesFilters: action.payload.deliveriesFilters,
        deliveries: action.payload.deliveries,
        waitingForDeliveryCount: action.payload.waitingForDeliveryCount
      }

    default:
      break
  }
  return state
}
