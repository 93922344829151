////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

///////////////////////////////// RENDER ///////////////////////////////////////

const TextOrDash = ({ children }) => (
  <span>
    {isEmpty(children) ? '-' : children}
  </span>
)

TextOrDash.propTypes = {
  children: PropTypes.number
}
export default TextOrDash
