///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppAlert, addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET MY DOCS
function apiGetMyDocumentationRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_MY_DOCUMENTATION_REQUEST, endpointPath })
  }
}
function apiGetMyDocumentationSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_MY_DOCUMENTATION_SUCCESS, payload: res })
  }
}
function apiGetMyDocumentationFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppAlert(err.message, true))
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_MY_DOCUMENTATION_FAILURE, payload: err })
  }
}
export function apiGetMyDocumentation(idOrder, data) {
  return u.getValueFromStore(
    `${idOrder.userId ? c.apiCompanyType : c.apiUser}${idOrder.idOrder || idOrder}${c.documentationHistory}`,
    idOrder.userId ? h.headerGetWithToken(data) : h.headerPutWithToken(data),
    apiGetMyDocumentationRequest,
    apiGetMyDocumentationSuccess,
    apiGetMyDocumentationFailure
  )
}

// RESET MY DOCS
export function resetMyDocumentation(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.RESET_MY_DOCUMENTATION, payload: res })
  }
}
