import styled from 'styled-components'

export const FilterContainer = styled.div`
  max-width: auto%;
  margin: 3px auto;
`
export const FileContainerOfu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;  
`
export const DeliveriesFilterContainer = styled.div`
  max-width: 70%;
  margin: 5px auto 45px auto;
`
export const AdvancedSearchCheckbox = styled.div`
  display: grid;
  grid-template-columns: repeat(5,1fr);
  justify-content: center;
  max-width: 90%;
  margin: 10px auto 0px auto;
  min-width: 300px;
  padding-left: 17%;
`
export const AdvancedSearchAndFilterContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items:flex-start;
  margin-bottom: 10px;
`
export const FilterContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props => (props.flex ? props.flex : 'space-between')};
  align-items: flex-end;
`
export const SearchContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: baseline;
`
export const TextLeftStyled = styled.p`
  padding: 10px 25px 10px 0;
  transform: translateY(6px);
`
export const TextLeftStyledTransition = TextLeftStyled.withComponent('h4')
export const TextLeftStyledH4 = TextLeftStyledTransition.extend`
  transform: translateY(0px);
  text-transform: uppercase;
  font-size: 15px;
`
export const ResetCancelOrderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 15px;
  @media (max-width: 1620px) {
    max-width: initial;
    flex-wrap: wrap;
    & > * {
      flex: 1 1 100%;
      text-align: center;
      & > * {
        display: inline-block;
      }
    }
    & > *:first-child {
      margin-bottom: 30px;
    }
  }
`
export const ResetContainer = styled.div`
  @media (max-width: 1620px) {
    transform: initial;
  }
`
