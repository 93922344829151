////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'
import { renderInput, renderCheckbox } from '../misc/FormHelpers'
import dateFormat from '../misc/dateFormat'
import { styles } from '../misc/formStyles'
import { loadIdToken, ID_TOKEN } from '../../../utils/apiUtils'
import { getItem } from '../../../utils/cookiesHelper'
import { apiGetCountries } from '../../../actions/countries'
import * as h from '../../../utils/headers'
import { fetchUser, removeUserFromState, editUser, editUserLockout } from '../../../actions/users'
import ToggleWithConfirm from '../../../components/ToggleWithConfirm'
import { checkRoleHasRightsToFunction } from '../../misc/allowedRolesPerFunction'
import * as c from '../../../constants'
import { PageHeader } from '../../../components/sharedComponents'
import { convertPhoneNumbePrefixToNumber, phoneNumbePrefixValidationFunction } from '../misc/phoneNumbePrefixValidationFunction'
import normalizePhoneNumberPrefix from '../misc/normalizePhoneNumberPrefix'
import stringformat from '../../../utils/stringformat'
import { theme } from '../../../components/styledComponents'
import * as SC from '../../../components/styledComponents'
import PageLoad from '../../../components/PageLoad'

const validate = (values, props) => {
  let errors = {}
  const { trl } = props
  if (!values.firstName) errors.firstName = trl.common__firstname_required_error || ' '
  if (!values.lastName) errors.lastName = trl.common__lastname_required_error || ' '
  if (!values.phoneNumber) errors.phoneNumber = trl.common__phone_required_error || ' '
  if (!values.email) errors.email = trl.common__email_required_error || ' '
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = trl.common__email_invalid_error || ' '
  if (!values.company_name) errors.company_name = trl.common__company_required_error || ' '
  if (!values.phoneNumbePrefix) errors.phoneNumbePrefix = trl.common__phone_prefix_required_error || ' '
  else if (values.phoneNumbePrefix.toString().length > 5) errors.phoneNumbePrefix = stringformat(trl.common__max_length_error, 5) || ' '
  else if (phoneNumbePrefixValidationFunction(values.phoneNumbePrefix, props.countries)) {
    // this is to check that the phone prefix is valid.
    errors.phoneNumbePrefix = trl.common__phone_prefix_invalid_error || ' '
  }
  return errors
}

class UserEdit extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const userId = this.props.routeParams.id
    this.props.apiGetCountries()
    this.props.fetchUser(userId)
  }

  componentWillUnmount() {
    this.props.removeUserFromState()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues !== this.props.initialValues) this.props.initialize(nextProps.initialValues)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(formValues) {
    const { firstName, lastName, email, phoneNumber, role, company_name, phoneNumbePrefix } = formValues
    const userModel = {
      firstName,
      lastName,
      email,
      phoneNumbePrefix: convertPhoneNumbePrefixToNumber(phoneNumbePrefix),
      phoneNumber,
      iD_Societe: this.props.me['iD_Societe'],
      company_name,
      role: role
    }
    const userId = this.props.routeParams.id
    return this.props.editUser(userModel, userId)
  }

  showUneditableFields() {
    const { trl } = this.props
    return (
      <div>
        <Field
          name="company_name"
          type="text"
          disabled={true}
          style={{ marginTop: 10, fontSize: theme.font.fontSize }}
          component={renderInput}
          label={trl.common__company}
        />
        <Field
          style={{ fontSize: theme.font.fontSize }}
          name="role"
          type="text"
          component={renderInput}
          label={trl.common__role}
          disabled={true}
        />
        <Field
          name="emailConfirmed"
          component={renderCheckbox}
          label={trl.common__email_confirmed}
          disabled={true}
          style={{ marginTop: 30, fontSize: theme.font.fontSize }}
        />
        <Field
          name="date_created"
          type="text"
          component={renderInput}
          label={trl.common__date_created}
          format={dateFormat}
          style={{ fontSize: theme.font.fontSize }}
          disabled={true}
        />
      </div>
    )
  }

  displayToggleWithConfirm() {
    const { email, editUserLockout, initialValues, active, routeParams, me, trl } = this.props
    // do I (me) have the right to activate/desactivate users?

    if (me.role && checkRoleHasRightsToFunction(me.role[0], c.viewUserEnableDisable)) {
      //let disableToggle = true
      // do I have the right to activate/desactivate this user? This will have the effect of disabling the toggle. Yes if:
      // 1) email is confirmed, 2) user isn't me, 3) user is not admin
      // checking condition 1)
      if (initialValues.emailConfirmed) {
        // checking condition 2)
        if (me.id !== initialValues.id) {
          // checking condition 3)
          //const userRole = initialValues.role[0]
          // if (userRole !== c.EAdmin && userRole !== c.LPAdmin && userRole !== c.ADMGlobal) {
          //  disableToggle = false
          //} 
        }
      }
      if (email) {
        const labelExpanded = trl.admin_user_edit__activated
        const labelRetracted = trl.admin_user_edit__not_activated
        const confMsgToExpand = trl.admin_user_edit__confirm_activate
        const confMsgToRetract = trl.admin_user_edit__confirm_deactivate
        const accessToken = loadIdToken() || getItem(ID_TOKEN)
        const config = h.headerPutWithToken(accessToken)
        const apiCallToExpand = { url: `${c.apiUser}${routeParams.id}${c.apiUserDisable}`, config }
        const apiCallToRetract = { url: `${c.apiUser}${routeParams.id}${c.apiUserEnable}`, config }
        return (
          <div className="toggleWithConfirm">
            <h4 style={{ ...styles.title }}>{trl.admin_user_edit__activation_title}</h4>
            <ToggleWithConfirm
              disabled={true} // TODO possibilité de modifier le toggle
              expanded={active}
              labelPosition="right"
              labelExpanded={labelExpanded}
              labelRetracted={labelRetracted}
              confMsgToExpand={confMsgToExpand}
              confMsgToRetract={confMsgToRetract}
              apiCallToExpand={apiCallToExpand}
              apiCallToRetract={apiCallToRetract}
              actionOnExpand={() => editUserLockout(initialValues)}
              actionOnRetract={() => editUserLockout(initialValues)}
            />
          </div>
        )
      }
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { pristine, handleSubmit, submitting, error, submitSucceeded, trl, initialValues, email } = this.props
    if(!initialValues || !trl) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    return (
      <div>
        <PageHeader title={trl.admin_user_edit__title} subtitle={initialValues.email} />
        <div className="user-create-form">
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field
              style={{ fontSize: theme.font.fontSize }}
              name="firstName"
              type="text"
              component={renderInput}
              label={trl.common__firstname}
            />
            <Field
              style={{ fontSize: theme.font.fontSize }}
              name="lastName"
              type="text"
              component={renderInput}
              label={trl.common__lastname}
            />
            <Field
              style={{ fontSize: theme.font.fontSize }}
              name="email"
              type="email"
              component={renderInput}
              label={trl.common__email}
            />
            <div style={{ display: 'flex', alignItems: 'top' }}>
              <Field
                name="phoneNumbePrefix"
                type="number"
                component={renderInput}
                label={trl.common__phone_prefix}
                normalize={normalizePhoneNumberPrefix}
                fullWidth={false}
                style={{ width: 120, marginRight: 20, fontSize: theme.font.fontSize }}
              />
              <Field
                name="phoneNumber"
                component={renderInput}
                label={trl.common__phone}
                type="number"
                fullWidth={false}
                style={{ flexGrow: 2, fontSize: theme.font.fontSize }}
              />
            </div>
            {this.showUneditableFields()}
            {error && <p style={styles.loginError}>{error}</p>}
            <div>
              {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
              {/* <RaisedButton
                label={trl.admin_user_edit__save_btn || ' '}
                primary={true}
                style={{
                  ...styles.loginBtn,
                  'margin': '1rem 0' 
                }}
                labelStyle={{ 
                  fontWeight: 'bold',
                  display: 'flex',
                  'flex-wrap': 'nowrap',
                  'justify-content': 'center'
                }}
                disabled={submitting || pristine || (submitSucceeded && pristine)}
                type="submit"
              /> */}
              <SC.ButtonPA_L
                style={{
                  ...styles.loginBtn
                }}
                disabled={submitting || pristine || (submitSucceeded && pristine)}
              >
                {trl.admin_user_edit__save_btn}
              </SC.ButtonPA_L>
            </div>
          </form>
          {this.displayToggleWithConfirm()}
        </div>
      </div>
    )
  }
}

UserEdit.contextTypes = {
  router: PropTypes.object.isRequired,
  store: React.PropTypes.object.isRequired
}

UserEdit.propTypes = {
  location: PropTypes.object.isRequired,
  roles: PropTypes.array,
  active: PropTypes.bool,
  me: PropTypes.object,
  trl: PropTypes.object.isRequired,
  fetchUser: PropTypes.func.isRequired,
  removeUserFromState: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  editUserLockout: PropTypes.func.isRequired,
  apiGetCountries: PropTypes.func.isRequired
}

function mapStateToProps({ users, roles, me, countries }) {
  return {
    initialValues: users.user,
    roles: roles.roles,
    email: users.user.email,
    active: !users.user.lockoutEnabled,
    countries: countries.countries,
    me
  }
}

UserEdit = reduxForm({
  form: 'UserEdit',
  validate
  // touchOnBlur: false,
})(UserEdit)

export default connect(mapStateToProps, {
  fetchUser,
  removeUserFromState,
  editUser,
  editUserLockout,
  apiGetCountries
})(UserEdit)
