import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as sematable } from 'sematable'
import * as c from '../constants'

import auth from './auth'
import users from './users'
import me from './me'
import appAlerts from './appAlerts'
import roles from './roles.js'
import logs from './logs.js'
import maintenance from './maintenance.js'
import languages from './languages.js'
import companies from './companies'
import printShops from './printShops'
import loading from './loading'
import countries from './countries'
import apiCallStateManager from './apiCallStateManager'
import orders from './orders'
import visibility from './visibility'
import delivery from './delivery'
import deliveriesWaiting from './deliveryWaiting'
import myCarriers from './myCarriers'
import stats from './stats'
import bat from './bat'
import shippementHistory from './shippementHistory'
import myDocumentation from './myDocumentation'
import dashboard from './dashboard'
import options from './options'
import productCatalog from './productCatalog'
import productionFollowUp from './productionFollowUp'
import stockFollowUp from './stockFollowUp'
import itemFollowUp from './itemFollowUp'
import fileOrderFollowUp from './fileOrderFollowUp'
import partnershipsManagement from './partnershipsManagement'
import sourceFileOrderModal from './sourceFileOrderModal'

const appReducer = combineReducers({
  auth,
  apiCallStateManager,
  appAlerts,
  companies,
  printShops,
  stats,
  countries,
  loading,
  form: formReducer,
  languages,
  logs,
  maintenance,
  me,
  orders,
  bat,
  visibility,
  roles,
  sematable,
  users,
  delivery,
  deliveriesWaiting,
  myCarriers,
  shippementHistory,
  myDocumentation,
  dashboard,
  options,
  productCatalog,
  productionFollowUp,
  stockFollowUp,
  itemFollowUp,
  fileOrderFollowUp,
  partnershipsManagement,
  sourceFileOrderModal
})

// clearing app state on log out
export const rootReducer = (state, action) => {
  if (action.type === c.LOGOUT) {
    state = undefined
  }
  return appReducer(state, action)
}
