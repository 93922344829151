import * as c from '../../constants'

export const allowedRolesPerPath = {
  // /
  [c.index]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // ./users
  [c.users]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: false,
    [c.EAdmin]: true,
    [c.FAC]: false,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: false
  },

  // /user-edit
  [c.userEdit]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: false,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: false
  },

  // /user-create
  [c.userCreate]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: false
  },

  // /roles
  [c.roles]: {
    [c.ADMGlobal]: true,
    [c.LPAdmin]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /logs
  [c.logs]: {
    [c.ADMGlobal]: true,
    [c.LPAdmin]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /languages
  [c.languages]: {
    [c.ADMGlobal]: true,
    [c.LPAdmin]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /languages/add
  [c.languageCreate]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /languages/edit
  [c.languageEdit]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /orders
  [c.ordersList]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /order/new
  [c.orderNew]: {
    [c.ADMGlobal]: false,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /order/{id}
  [c.orderDetail]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /order/{orderId}/article/s/{idCommandeArticle}
  // reminder: this is what the pathname will look like once parsed from RestrictPerRole container: /order//article/s/
  [`${c.orderDetail}${c.articleFixe}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  //${c.orderDetail}:orderId${c.articleVariable }:idCommandeArticle${c.composition}
  [`${c.orderDetail}${c.articleVariable}${c.composition}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /order/{orderId}/article/v/{idCommandeArticle}
  [`${c.orderDetail}${c.articleVariable}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /order/{orderId}/delivery-details'
  [`${c.orderDetail}${c.orderDeliveryDetails}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /order/{orderId}/add-item'
  [`${c.orderDetail}${c.orderAddItem}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /order/{orderId}/confirm-order'
  [`${c.orderDetail}${c.confirmOrder}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /order/{orderId}/confirm-order'
  [`${c.orderDetail}${c.confirmOrderSuccess}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },
  [`${c.orderDetail}${c.confirmOrderFailure}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /follow-up
  [`${c.followUp}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // /delivery-follow-up
  [`${c.deliveriesFollowUp}`]: {
    [c.GAdmin]: true,
    [c.LPAdmin]: true,
    [c.CSP]: true,
    [c.LSAdmin]: true,
    [c.CSS]: true,
    [c.PAdmin]: true,
    [c.POP]: true,
    [c.ADMGlobal]: true,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false
  },
  // /admin/countries-labelling-specialist
  [`${c.countriesLabellists}`]: {
    [c.ADMGlobal]: false,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /admin/brands
  [`${c.brands}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /stats
  [c.stats]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.POP]: true
  },

  // admin/manage-printshops
  [`${c.managePrintshops}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /admin/brand/edit
  [`${c.brandEdit}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  // /admin/brand/file-order-formats
  [`${c.brandEditFileFormats}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },
  [`${c.partnershipsManagement}`]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  }
}
