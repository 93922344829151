////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import { theme, H2 } from '../../styledComponents'
import { apiGetOrderArticleOFU } from '../../../actions/orders'
import ProductionFollowUpTableDetail from '../Tables/ProductionFollowUpTableDetail'
/////////ASSETS/////////
import ZoomIn from 'material-ui/svg-icons/action/zoom-in'

/////////STYLED/////////
const H2Styled = H2.extend`
  color: ${theme.colors.lightGrey};
  background-color: ${theme.colors.darkGrey};
  padding: 14px;
  font-size: 19px;
`
/////////STYLED/////////

class ProductionFollowUpDetail extends Component {
  state = {
    open: false,
    wishedExpeditionDate: this.props.row.date_Expedition_Souhaitee,
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = () => {
    window.closeDisplayActionsDialog = this.handleClose;
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    window.closeDisplayActionsDialog = undefined;
  }

 	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl } = this.props
    return (
      <div>
        <ZoomIn onClick={this.handleOpen} style={{ cursor: 'pointer' }} />
        <Dialog
          contentStyle={{ maxWidth: '100vw', width: '80vw', marginLeft: '10vw', padding: 0 }}
          style={{ width: '80vw', fontSize: theme.p1.ftz, padding: 0 }}
          bodyStyle={{ padding: '10px' }}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <H2Styled>{`${trl.profu_tableTitle_plannedOrderID} ${row.iD_PlannedOrder} - ${row.enseigne_Name} - ${row.iD_Article_Externe_SAP}`}</H2Styled>
          <ProductionFollowUpTableDetail data={[row]} style={{ overflow: 'scroll' }} />
        </Dialog>
      </div>
    )
  }
}

ProductionFollowUpDetail.propTypes = {
  row: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired
}

ProductionFollowUpDetail.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = ({ languages, auth }) => ({
  trl: languages.siteLanguage.keys,
  myRole: auth.user_role
})

export default connect(mapStateToProps, { apiGetOrderArticleOFU })(ProductionFollowUpDetail)
