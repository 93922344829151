////////LIBRARY/////////
import { SubmissionError } from 'redux-form'
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { logout } from './auth'
import { apiGetDashboard } from './dashboard'
import { getItem } from '../utils/cookiesHelper'
import { reLogin } from '../actions/auth'
import { ID_TOKEN } from '../utils/apiUtils'

// FETCH USER
function fetchMeRequest(endpointPath) {
  return { type: c.FETCH_ME_REQUEST, endpointPath }
}
function fetchMeSuccess(endpointPath, data) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_ME_SUCCESS, endpointPath })
    dispatch({
      type: c.FETCH_ME,
      payload: data
    })
    dispatch({ type: c.UPDATE_AUTH, payload: { key: 'user_role', value: data.role[0] } })
  }
}
function fetchMeFailure(endpointPath, err) {
  return { type: c.FETCH_ME_FAILURE, endpointPath }
}
export function fetchMe(userId, additionalParams = {}) {
  return u.getValueFromStore(
    `${c.apiUser}${userId}`,
    h.headerGetWithToken(),
    fetchMeRequest,
    fetchMeSuccess,
    fetchMeFailure,
    null,
    additionalParams
  )
}

// UPDATE ME
export function updateMe(me) {
  return {
    type: c.UPDATE_ME,
    payload: me
  }
}

// SET MY PREFERRED LANGUAGE
function setMyPreferredLanguageRequest(endpointPath) {
  return {
    type: c.SET_MY_PREFERRED_LANGUAGE_REQUEST,
    endpointPath
  }
}
function setMyPreferredLanguageSuccess(endpointPath, json) {
  return (dispatch) => {
    dispatch({ type: c.SET_MY_PREFERRED_LANGUAGE_SUCCESS, endpointPath })
    dispatch({
      type: c.SET_MY_PREFERRED_LANGUAGE,
      payload: json
    })
  }
}
function setMyPreferredLanguageFailure(endpointPath, err) {
  return {
    type: c.SET_MY_PREFERRED_LANGUAGE_FAILURE,
    endpointPath
  }
}
export function setMyPreferredLanguage(userId, languageid) {
  return u.getValueFromStore(
    `${c.apiUser}${userId}${c.apiUserUpdatePreferredLanguage}`,
    h.headerPutWithTokenAndModel({ languageid }),
    setMyPreferredLanguageRequest,
    setMyPreferredLanguageSuccess,
    setMyPreferredLanguageFailure
  )
}

// CHANGE MY PASSWORD
function changeMyPasswordRequest(endpointPath) {
  return { type: c.CHANGE_MY_PASSWORD_REQUEST, endpointPath }
}
function changeMyPasswordSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.CHANGE_MY_PASSWORD_SUCCESS, endpointPath })
    dispatch(logout())
    const pathname = hashHistory.getCurrentLocation().pathname
    hashHistory.push(`${c.login}?from=${pathname}`)
  }
}
function changeMyPasswordFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.CHANGE_MY_PASSWORD_FAILURE, endpointPath })
    const _error = err.message
    throw new SubmissionError({ _error })
  }
}
export function changeMyPassword(m, uId) {
  return u.getValueFromStore(
    `${c.apiUser}${uId}${c.apiUserUpdatePassword}`,
    h.headerPutWithTokenAndModel(m),
    changeMyPasswordRequest,
    changeMyPasswordSuccess,
    changeMyPasswordFailure,
    true
  )
}

// CONFIRM EMAIL
function confirmEmailRequest(endpointPath) {
  return { type: c.CONFIRM_EMAIL_REQUEST, endpointPath }
}
function confirmEmailSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.CONFIRM_EMAIL_SUCCESS, endpointPath })
    dispatch(updateMe(res))
    const pathname = hashHistory.getCurrentLocation().pathname
    hashHistory.push(`${c.login}?from=${pathname}`)
  }
}
function confirmEmailFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.CONFIRM_EMAIL_FAILURE, endpointPath })
    const pathname = hashHistory.getCurrentLocation().pathname
    hashHistory.push(`${c.login}?from=${pathname}`)
  }
}
export function confirmEmail(m, url) {
  return u.getValueFromStore(
    url,
    h.headerPostWithModel(m),
    confirmEmailRequest,
    confirmEmailSuccess,
    confirmEmailFailure
  )
}

// REQUEST ACCOUNT
function requestAccountRequest(endpointPath) {
  return { type: c.REQUEST_ACCOUNT_REQUEST, endpointPath }
}
function requestAccountSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.REQUEST_ACCOUNT_SUCCESS, endpointPath })
    const pathname = hashHistory.getCurrentLocation().pathname
    hashHistory.push(`${c.login}?from=${pathname}`)
  }
}
function requestAccountFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.REQUEST_ACCOUNT_FAILURE, endpointPath })
    throw new SubmissionError({ error: err.message })
  }
}
export function requestAccount(m) {
  return u.getValueFromStore(
    c.apiRequestAcount,
    h.headerPostWithModel(m),
    requestAccountRequest,
    requestAccountSuccess,
    requestAccountFailure,
    true
  )
}

// SWITCH USER ROLE
function apiSwitchUserRoleRequest(endpointPath) {
  return { type: c.API_PUT_SWITH_USER_ROLE_REQUEST, endpointPath }
}
function apiSwitchUserRoleSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch(fetchMe(res.userId, { forceUpdate: true }))
    dispatch({ type: c.API_PUT_SWITH_USER_ROLE_SUCCESS, endpointPath })
    const accessTokenLst = localStorage.getItem(ID_TOKEN)
    const accessTokenCookie = getItem(ID_TOKEN)
    const accessToken = accessTokenLst || accessTokenCookie
    dispatch(reLogin(accessToken))
    hashHistory.push(`${c.index}`)
    dispatch(apiGetDashboard())
  }
}
function apiSwitchUserRoleFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_PUT_SWITH_USER_ROLE_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiSwitchUserRole() {
  return u.getValueFromStore(
    `${c.apiUser}${c.swithUserRole}`,
    h.headerPutWithTokenAndModel(),
    apiSwitchUserRoleRequest,
    apiSwitchUserRoleSuccess,
    apiSwitchUserRoleFailure,
    true
  )
}
