////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/////////STYLED/////////
const ColorItem = styled.span`
  color: ${props => (props.status_Color ? props.status_Color : 'black')};
  font-size: 12px;
  font-weight: bold;
`
/////////STYLED/////////

class ColorStatusPROFU extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row } = this.props
    const trl = this.context.store.getState().languages.siteLanguage.keys
    return (
      <ColorItem key={row.iD_Commande_Externe_SAP} status_Color={row.status.internalColor}>
        {trl[row.status.internalTraductionKey]}
      </ColorItem>
    )
  }
}

ColorStatusPROFU.propTypes = {
  row: PropTypes.object,
}

ColorStatusPROFU.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default ColorStatusPROFU
