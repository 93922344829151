export const getItem = function (sKey) {
  if (!sKey) {
    return null
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'
        ),
        '$1'
      )
    ) || null
  )
}

export const setItem = function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
  if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
    return false
  }
  let sExpires = ''
  if (vEnd) {
    switch (vEnd.constructor) {
      case Number:
        sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
        break
      case String:
        sExpires = '; expires=' + vEnd
        break
      case Date:
        sExpires = '; expires=' + vEnd.toUTCString()
        break
      default:
        break
    }
  }
  document.cookie =
    encodeURIComponent(sKey) +
    '=' +
    encodeURIComponent(sValue) +
    sExpires +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '') +
    (bSecure ? '; secure' : '')
  return true
}

export const hasItem = function (sKey) {
  if (!sKey) return false
  return new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(
    document.cookie
  )
}

export const removeItem = function (sKey, sPath, sDomain) {
  if (!hasItem(sKey)) return false
  document.cookie =
    encodeURIComponent(sKey) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '')
  return true
}

// TODO Remake the REGEX rule to enable this function
//export const keys = function() {
//  let aKeys = document.cookie
//  const regexReplace = /((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g
//  const regexSplit = /\s*(?:\=[^;]*)?;\s*/
//  aKeys = aKeys.replace(regexReplace, '').split(regexSplit)
//  for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
//    aKeys[nIdx] = decodeURIComponent(aKeys[nIdx])
//  }
//  console.log('debug', aKeys)
//  return aKeys
//}
