////////LIBRARY/////////
import React from 'react'
///////COMPONENTS///////
import { translate } from '../../containers/HOC/Translation'

///////////////////////////////// RENDER ///////////////////////////////////////

const NoDataComponent = () => (
  <div className="text-center">
    {translate('common__table_no_data')}
  </div>
)

export default NoDataComponent