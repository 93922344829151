////////LIBRARY/////////
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import { theme, H3 } from '../styledComponents'

/////////STYLED/////////
const TableWrapperContainer = styled.div`
  border: 1px solid black;
`
const TableWrapperHeader = styled.div.attrs({
  color: props => props.color || theme.colors.black,
})`
  ${props => `
    color: ${props.color};
    height: ${props.hideHeader ? 0 : '35px'}
  `};
  background-color: ${props => props.bgColor || theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -1px;
  border-bottom: 1px solid black;
`
/////////STYLED/////////

///////////////////////////////// RENDER ///////////////////////////////////////

const TableWrapper = ({ text, color, bgColor, children, hideHeader }) => (
  <TableWrapperContainer>
    <TableWrapperHeader color={color} bgColor={bgColor} hideHeader={hideHeader}>
      <H3 style={{ fontWeight: 'bold', fontSize: 18 }}>{text}</H3>
    </TableWrapperHeader>
    {children}
  </TableWrapperContainer>
)

TableWrapper.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

export default TableWrapper
