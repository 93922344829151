////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
///////COMPONENTS///////
import RaisedButton from 'material-ui/RaisedButton'
import DatePicker from 'material-ui/DatePicker'
import OrdersTable from '../../components/Sematable/Tables/OrdersTable'
import { apiGetOrdersAndOrderArticles } from '../../actions/orders'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem';
import theme from '../../components/styledComponents/theme'
import { PageHeader } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import * as c from '../../constants'

class OrdersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      from: null,
      to: null,
      minDate: null,
      statusValue: null
    }
    this.handleFilterOrders = this.handleFilterOrders.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    // already have orders from api, need to reinitilize state with those orders
    if (!_.isEmpty(this.props.orders) && !this.state.orders.length) this.setState({ orders: _.toArray(this.props.orders) })
  }

  componentDidMount() {
    this.props.apiGetOrdersAndOrderArticles({ forceUpdate: true})
  }

  componentWillReceiveProps(nextProps) {
    // set orders from API result to state
    if (!_.isEmpty(nextProps.orders)) this.setState({ orders: _.toArray(nextProps.orders) })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleChangeFrom = (event, date) => this.setState({ from: date, minDate: date }, this.handleFilterOrders)

  handleChangeTo = (event, date) => this.setState({ to: date }, this.handleFilterOrders)

  handleFilterOrders() {
    let toDate = null
    let fromDate = null
    let filterDate = false
    let filterStatus = false
    //if date exists
    if (this.state.from) {
      fromDate = new Date(this.state.from)
      toDate = new Date()
      // if toDate set then that's the toDate, otherwise it's 300 y from now
      toDate = this.state.to ? new Date(this.state.to) : toDate.setFullYear(toDate.getFullYear() + 300)
      filterDate = true
    }
    // if status exists
    if (this.state.statusValue) filterStatus = true
    // filtering orders
    // eslint-disable-next-line
    const ordersFiltered = _.toArray(this.props.orders).filter(o => {
      if (filterDate && filterStatus) {
        let oDate = new Date(o.date_Expedition_Souhaitee)
        return oDate >= fromDate && oDate <= toDate && o.status.idStatus === this.state.statusValue
      } else if (filterDate) {
        let oDate = new Date(o.date_Expedition_Souhaitee)
        return oDate >= fromDate && oDate <= toDate
      } else if (filterStatus) {
        return o.status.idStatus === this.state.statusValue
      } else {
        return true
      }
    })
    this.setState({ orders: ordersFiltered })
  }

  handleResetDate = () => {
    this.setState({
      orders: _.toArray(this.props.orders),
      from: null,
      to: null,
      minDate: null
    })
  }

  handleChangeStatus = (event, index, statusValue) => this.setState({ statusValue }, this.handleFilterOrders)

  handleResetStatus = () => this.setState({ statusValue: null }, this.handleFilterOrders)

  displayOrderStatusSelector() {
    let orderStatusObj = {}
    return _.toArray(this.props.orders).map(o => {
      // if id not in object hash, set id in hash and return a new selection option
      if (!orderStatusObj[o.status.idStatus]) {
        orderStatusObj[o.status.idStatus] = o.status.idStatus
        return <MenuItem style={{ fontSize: theme.font.fontSize }} value={o.status.idStatus} key={o.status.idStatus} primaryText={this.props.trl[o.status_Traduction_Key]} />
      } else return null
    })
  }

  formatDate(date) {
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, apiCallStateManager } = this.props
    if (!apiCallStateManager[c.apiOrders]) return null
    const { awaitingResponse, lastUpdated, didInvalidate } = apiCallStateManager[c.apiOrders]
    if (!this.state.orders.length && !(!awaitingResponse && lastUpdated && !didInvalidate)) return null
    return (
      <div>
        <PageHeader title={trl.orders_pre__title} subtitle={trl.orders_pre__subtitle} />
        <SC.Sky grey>
          <SC.PageGutter>
            <div style={{ marginBottom: 50, display: 'flex', justifyContent: 'space-around' }}>
              <div>
                <p>{trl.orders_pre__filter_date_exp}</p>
                <DatePicker
                  autoOk
                  hintText={trl.orders_pre__filter_date_exp_from}
                  container="inline"
                  onChange={this.handleChangeFrom.bind(this)}
                  value={this.state.from}
                  style={{ marginTop: '40px', fontSize: theme.font.fontSize }}
                  formatDate={this.formatDate}
                />
                <DatePicker
                  autoOk
                  hintText={trl.orders_pre__filter_date_exp_to}
                  container="inline"
                  style={{ fontSize: theme.font.fontSize }}
                  onChange={this.handleChangeTo.bind(this)}
                  disabled={!this.state.from}
                  value={this.state.to}
                  minDate={this.state.minDate}
                  formatDate={this.formatDate}
                />
                <RaisedButton
                  label={trl.orders_pre__filter_date_exp_btn || ' '}
                  onClick={this.handleResetDate}
                  disabled={!this.state.from}
                  style={{ 'margin': '1rem 0' }}
                  labelStyle={{ 
                    fontWeight: 'bold',
                    display: 'flex',
                    'flex-wrap': 'nowrap',
                    'justify-content': 'center'
                  }}
                />
              </div>
              <div>
                <p>{trl.orders_pre__filter_status}</p>
                <SelectField
                  style={{ fontSize: theme.font.fontSize }}
                  floatingLabelText={trl.orders_pre__filter_status_status}
                  value={this.state.statusValue}
                  onChange={this.handleChangeStatus.bind(this)}
                >
                  {this.displayOrderStatusSelector()}
                </SelectField>
                <RaisedButton
                  label={trl.orders_pre__filter_status_btn || ' '}
                  onClick={this.handleResetStatus.bind(this)}
                  disabled={!this.state.statusValue}
                  style={{ 'margin': '1rem 0' }}
                  labelStyle={{ 
                    fontWeight: 'bold',
                    display: 'flex',
                    'flex-wrap': 'nowrap',
                    'justify-content': 'center'
                  }}
                />
              </div>
            </div>
          </SC.PageGutter>
        </SC.Sky>
        <SC.PageGutter>
          {this.state.orders.length ? <OrdersTable data={this.state.orders} /> : <p>{trl.common__no_order}</p>}
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ orders, auth, apiCallStateManager }) {
  return {
    orders: orders,
    role: auth.user_role,
    apiCallStateManager
  }
}

OrdersList.propTypes = {
  orders: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiGetOrdersAndOrderArticles: PropTypes.func.isRequired,
  apiCallStateManager: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { apiGetOrdersAndOrderArticles })(OrdersList)
