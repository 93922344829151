////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/////////STYLED/////////
const ColorItem = styled.span`
  color: ${props => (props.status_Color ? props.status_Color : 'black')};
  padding: 7px;
  font-size: 12px;
  font-weight: bold;
`
/////////STYLED/////////

class DocColorBatStatus extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props
    const trl = this.context.store.getState().languages.siteLanguage.keys
    return (
      <ColorItem status_Color={children.colorCode}>
        {trl[children.label_TradKey]}
      </ColorItem>
    )
  }
}

DocColorBatStatus.propTypes = {
  row: PropTypes.object,
  children: PropTypes.object
}

DocColorBatStatus.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default DocColorBatStatus
