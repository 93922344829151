////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
///////COMPONENTS///////
import * as c from '../../../constants'
/////////ASSETS/////////
import pdfDown from '../../../assets/pdfDown.png'

const MyDocumentationTableActions = ({ trl, row }) => {

 	///////////////////////////////// RENDER ///////////////////////////////////////

  if (row.pdfName === null) return <div>{trl.mydocum_tableContent_messageNoPdfAvailable}</div>

  const isProforma = get(row, 'docType.iD_DocType') === 1
  const downloadUrl = isProforma ? c.downloadARC : c.downloadInvoice

  return (
    <div>
      <a style={{ cursor: 'pointer' }} target="_blank" href={`${downloadUrl}${row.pdfName}.pdf`}>
        <img src={pdfDown} width="20" alt='img' />
      </a>
    </div>
  )
}

MyDocumentationTableActions.propTypes = {
  row: PropTypes.object.isRequired,
  trl: PropTypes.object
}

export default MyDocumentationTableActions
