////////LIBRARY/////////
import { SubmissionError } from 'redux-form'
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import { setItem } from '../utils/cookiesHelper'
import * as h from '../utils/headers'
import { addAppAlert } from './appAlerts'

//FETCH LANGUAGES
function fetchLanguagesRequest(endpointPath) {
  return {
    type: c.FETCH_LANGUAGES_REQUEST,
    endpointPath
  }
}
function fetchLanguagesSuccess(endpointPath, users) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_LANGUAGES_SUCCESS, endpointPath })
    dispatch({
      type: c.FETCH_LANGUAGES,
      payload: users
    })
  }
}
function fetchLanguagesFailure(endpointPath, err) {
  return {
    type: c.FETCH_LANGUAGES_FAILURE,
    endpointPath
  }
}
export function fetchLanguages(forceCall = false) {
  return u.getValueFromStore(
    c.apiLanguages,
    h.headerGetWithToken(),
    fetchLanguagesRequest,
    fetchLanguagesSuccess,
    fetchLanguagesFailure
  )
}

//CREATE LANGUAGE
function createLanguageRequest(endpointPath) {
  return { type: c.CREATE_LANGUAGE_REQUEST, endpointPath }
}
function createLanguageSuccess(endpointPath, l) {
  return (dispatch, getState) => {
    dispatch({ type: c.CREATE_LANGUAGE_SUCCESS, endpointPath })
    const trl = getState().languages.siteLanguage.keys
    // so that I have the new languages in the mix!
    dispatch(fetchLanguages())
    dispatch(addAppAlert(`${l.name} ${trl.common__notification_created}`))
    hashHistory.push(`${c.languageEdit}${l.languageid}`)
  }
}
function createLanguageFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.CREATE_LANGUAGE_FAILURE, endpointPath })
    const _error = err.message
    throw new SubmissionError({ _error })
  }
}
export function createLanguage(m) {
  return u.getValueFromStore(
    c.apiLanguage,
    h.headerPostWithTokenAndModel(m),
    createLanguageRequest,
    createLanguageSuccess,
    createLanguageFailure,
    true
  )
}

// FETCH BASE LANGUAGE
export function addLanguageToBaseLanguage(language) {
  return {
    type: c.ADD_LANGUAGE_TO_BASE_LANGUAGE,
    payload: language
  }
}
function fetchBaseLanguageRequest(endpointPath) {
  return { type: c.FETCH_BASE_LANGUAGE_REQUEST, endpointPath }
}
function fetchBaseLanguageSuccess(endpointPath, l) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_BASE_LANGUAGE_SUCCESS, endpointPath })
    dispatch(addLanguageToBaseLanguage(l))
  }
}
function fetchBaseLanguageFailure(endpointPath, err) {
  return { type: c.FETCH_BASE_LANGUAGE_FAILURE, endpointPath }
}
export function fetchBaseLanguage(languageId) {
  return u.getValueFromStore(
    `${c.apiLanguage}${languageId}${c.apiLanguageNoFallback}`,
    h.headerGetWithToken(),
    fetchBaseLanguageRequest,
    fetchBaseLanguageSuccess,
    fetchBaseLanguageFailure,
    null,
    { forceUpdate: true }
  )
}

// FETCH TARGET LANGUAGE
export function addLanguageToTargetLanguage(language) {
  return {
    type: c.ADD_LANGUAGE_TO_TARGET_LANGUAGE,
    payload: language
  }
}
function fetchTargetLanguageRequest(endpointPath) {
  return { type: c.FETCH_TARGET_LANGUAGE_REQUEST, endpointPath }
}
function fetchTargetLanguageSuccess(endpointPath, l) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_TARGET_LANGUAGE_SUCCESS, endpointPath })
    dispatch(addLanguageToTargetLanguage(l))
  }
}
function fetchTargetLanguageFailure(endpointPath, err) {
  return { type: c.FETCH_TARGET_LANGUAGE_FAILURE, endpointPath }
}
export function fetchTargetLanguage(languageId) {
  return u.getValueFromStore(
    `${c.apiLanguage}${languageId}${c.apiLanguageNoFallback}`,
    h.headerGetWithToken(),
    fetchTargetLanguageRequest,
    fetchTargetLanguageSuccess,
    fetchTargetLanguageFailure,
    null,
    { forceUpdate: true }
  )
}
// CHANGE TARGET LANGUAGE
export function changeTargetLanguageRow(data) {
  return {
    type: c.CHANGE_TARGET_LANGUAGE_ROW,
    payload: data
  }
}

// TOGGLE LANGUAGE
export function toggleLanguageLockout(languageId) {
  return {
    type: c.TOGGLE_LANGUAGE_LOCKOUT,
    payload: parseInt(languageId, 10)
  }
}

// TARGET AND BASE LANGUAGES
export function deleteBaseAndTargetLanguages() {
  return {
    type: c.DELETE_TARGET_AND_BASE_LANGUAGES
  }
}

// SET SITE LANGUAGE TO STATE
export function addSiteLanguageToStateFromLS(l) {
  return {
    type: c.ADD_SITLE_LANGUAGE_FROM_LS,
    payload: l
  }
}

// SET SITE LANGUAGE FROM LANGUAGE ID
function setSiteLanguageUsingIdRequest(endpointPath) {
  return { type: c.SET_SITE_LANGUAGE_USING_ID_REQUEST, endpointPath }
}
function setSiteLanguageUsingIdSuccess(endpointPath, res) {
  return (dispatch) => {
    // add to local storage
    let newKeys = {}
    res.keys.map((obj) => (newKeys[obj.key] = obj.value))
    const lang = JSON.stringify({ ...res, keys: newKeys })
    u.setLocalStorage(c.siteLanguage, lang)
    // add version to cookie
    setItem(c.siteLanguage, res.languageid)
    // add to state
    dispatch({
      type: c.SET_SITE_LANGUAGE_USING_ID_SUCCESS,
      payload: res
    })
  }
}
function setSiteLanguageUsingIdFailure(endpointPath, err) {
  return { type: c.SET_SITE_LANGUAGE_USING_ID_FAILURE, endpointPath }
}
export function setSiteLanguageUsingId(languageId) {
  return u.getValueFromStore(
    `${c.apiLanguage}${languageId || 1}`, // 1 is default language ID (english)
    h.headerGetWithToken(),
    setSiteLanguageUsingIdRequest,
    setSiteLanguageUsingIdSuccess,
    setSiteLanguageUsingIdFailure,
    null,
    { forceUpdate: true }
  )
}

// SET PREFERRED LANGUAGE
export function setPreferredLanguage(lId) {
  return {
    type: c.SET_PREFERRED_LANGUAGE_ID,
    payload: lId
  }
}

// DELETE PREFERRED LANGUAGE
export function deletePreferredLanguage() {
  return {
    type: c.DELETE_PREFERRED_LANGUAGE_ID
  }
}

// CHECK SITE LANGUAGE VERSION
function versionIsUpToDate() {
  return { type: c.VERSION_IS_UP_TO_DATE }
}
export function checkSiteLanguageVersionHelper(response) {
  return (dispatch) => {
    // if version is up to date, it will return a string, otherwise object
    if (typeof response === 'string') {
      dispatch(versionIsUpToDate())
    } else {
      dispatch(setSiteLanguageUsingIdSuccess('', response))
    }
  }
}

// CHECK SITE LANGUAGE version
function checkSiteLanguageVersionRequest(endpointPath) {
  return { type: c.CHECK_SITE_LANGUAGE_VERSION_REQUEST, endpointPath }
}
function checkSiteLanguageVersionSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.CHECK_SITE_LANGUAGE_VERSION_SUCCESS, endpointPath })
    dispatch(checkSiteLanguageVersionHelper(res))
  }
}
function checkSiteLanguageVersionFailure(endpointPath, err) {
  return { type: c.CHECK_SITE_LANGUAGE_VERSION_FAILURE, endpointPath }
}
export function checkSiteLanguageVersion(languageId, version) {
  return u.getValueFromStore(
    `${c.apiLanguage}${languageId || 1}/v/${version}`,
    h.headerGetWithToken(),
    checkSiteLanguageVersionRequest,
    checkSiteLanguageVersionSuccess,
    checkSiteLanguageVersionFailure,
    null,
    { forceUpdate: true }
  )
}
