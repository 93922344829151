////////LIBRARY/////////
import React from 'react'
import { TextField } from 'redux-form-material-ui'
import styled from 'styled-components'
///////COMPONENTS///////
import Checkbox from 'material-ui/Checkbox'
import SelectField from 'material-ui/SelectField'
import { RadioButtonGroup } from 'material-ui/RadioButton'
import { FormError, theme } from '../../../components/styledComponents'

/////////STYLED/////////
const ErrorTextWrapper = styled.div`
  position: absolute;
  left: 0px;
`
const ErrorText = styled.span`
  color: red;
  font-size: 10px;
`
/////////STYLED/////////

export const renderInput = props => {
  return (
    <TextField
      hintText={props.label}
      floatingLabelText={props.label}
      errorText={props.touched && props.error}
      errorStyle={{
        bottom: '-20px'
      }}
      fullWidth={true}
      floatingLabelFocusStyle={{
        left: 0,
        top: '30px'
      }}
      floatingLabelShrinkStyle={{
        left: 0,
        top: '30px'
      }}
      floatingLabelStyle={{
        left: '10px',
        top: '36px'
      }}
      hintStyle={{
        display: 'none'
      }}
      inputStyle={{
        borderRadius: 0,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: '100%',
        height: '55%',
        position: 'relative',
        top: '10px'
      }}
      underlineShow={false}
      {...props}
    />
  )
}
const inputStyle = {
  borderRadius: 0,
  border: '1px solid #ced4da',
  padding: '10px 12px',
  width: '100%',
  height: '55%',
  position: 'relative',
  top: '10px'
}
export const renderInputWithError = props => {
  return (
    <div>
      <TextField
        hintText={props.label}
        floatingLabelText={props.label}
        errorText={props.touched && props.error}
        errorStyle={{
          bottom: '-20px',
          display: 'none'
        }}
        fullWidth={true}
        floatingLabelFocusStyle={{
          left: 0,
          top: '30px'
        }}
        floatingLabelShrinkStyle={{
          left: 0,
          top: '30px'
        }}
        floatingLabelStyle={{
          left: '10px',
          top: '36px'
        }}
        hintStyle={{
          display: 'none'
        }}
        inputStyle={{ ...inputStyle }}
        underlineShow={false}
        {...props}
      >
      </TextField>
      {
        props.meta.error &&
        <ErrorTextWrapper>
          <ErrorText>
            {props.meta.error}
          </ErrorText>
        </ErrorTextWrapper>
      }
    </div>
  )
}


export const renderCheckbox = ({ label, input, disabled, style }) => (
  <Checkbox
    label={label}
    checked={input.value ? true : false}
    onCheck={(e, checked) => input.onChange(checked)}
    disabled={disabled ? true : false}
    style={style ? style : {}}
  />
)

export const renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => {
  return (
    <SelectField
      floatingLabelText={label}
      errorText={touched && error}
      {...input}
      onChange={(event, index, value) => input.onChange(value)}
      children={children}
      floatingLabelFocusStyle={{
        left: 0,
        top: '30px'
      }}
      floatingLabelShrinkStyle={{
        left: 0,
        top: '30px'
      }}
      floatingLabelStyle={{
        left: '10px',
        top: '36px'
      }}
      style={{ fontSize: theme.font.fontSize, width: '100%' }}
      labelStyle={{
        borderRadius: 0,
        border: '1px solid #ced4da',
        padding: '10px 12px',
        width: '100%',
        height: '55%',
        position: 'relative',
        top: '11px',
        lineHeight: 'inherit'
      }}
      underlineShow={false}
      {...custom}
    />
  )
}

export const renderRadioGroup = ({ input, ...rest }) => (
  <div style={{ position: 'relative' }}>
    <RadioButtonGroup
      {...input}
      {...rest}
      errorText={rest.meta.touched && rest.meta.error}
      valueSelected={input.value}
      onChange={(event, value) => input.onChange(value)}
    />
    <FormError>{rest.meta.touched && rest.meta.error}</FormError>
  </div>
)
