import * as c from '../constants'

const INITIAL_STATE = {
  list: {},
  item: {},
  isLoading: true,
  errors: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_MY_CARRIERS_DATA_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_GET_MY_CARRIERS_DATA_FAILURE:
      return { ...state, isLoading: false }
    case c.API_GET_MY_CARRIERS_DATA_SUCCESS: {
      const list = {
        modes_Livraison: action.payload.modeLivraisons.filter(mode => mode.iD_Mode_Livraison !== -1),
        availableCarriers :action.payload.availableCarriers
      }
      return { ...state, list, isLoading: false, errors: {} }
    }
    case c.API_GET_MY_CARRIERS_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_GET_MY_CARRIERS_FAILURE:
      return { ...state, isLoading: false }
    case c.API_GET_MY_CARRIERS_SUCCESS: {
      const list = {
        ...action.payload,
        modes_Livraison: action.payload.modes_Livraison.filter((mode) => mode.iD_Mode_Livraison !== -1)
      }
      return { ...state, list, isLoading: false, errors: {} }
    }
    case c.API_DELETE_MY_CARRIER_SUCCESS: {
      const list = {
        ...action.payload,
        modes_Livraison: action.payload.modes_Livraison.filter((mode) => mode.iD_Mode_Livraison !== -1)
      }
      return { ...state, list, isLoading: false, errors: {} }
    }
    case c.API_ADD_MY_CARRIER_SUCCESS: {
      const list = {
        ...action.payload,
        modes_Livraison: action.payload.modes_Livraison.filter((mode) => mode.iD_Mode_Livraison !== -1)
      }
      return { ...state, list, isLoading: false, errors: {} }
    }
    case c.API_EDIT_MY_CARRIER_SUCCESS: {
      const list = {
        ...action.payload,
        modes_Livraison: action.payload.modes_Livraison.filter((mode) => mode.iD_Mode_Livraison !== -1)
      }
      return { ...state, list, isLoading: false, errors: {} }
    }
    default:
      break
  }
  return state
}
