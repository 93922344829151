////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty, cloneDeep, orderBy } from 'lodash'
///////COMPONENTS///////
import PrintShopsTable, {columns} from '../../components/Sematable/Tables/PrintShopsTable'
import PageLoad from '../../components/PageLoad'
import * as SC from '../../components/styledComponents'
import { PageHeader } from '../../components/sharedComponents'
import { fetchPrintShops, putPrintShops, editPrintShop } from '../../actions/printshops'
import './ManagePrintshops.css'

class ManagePrintshops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSaveConfiramtion: false,
      printShopsState: []
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.fetchPrintShops()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({printShopsState : nextProps.printShops})
  }
  
  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelSortable = index => {
    if (columns[index])this.setState({ lastSortBy: this.state.sortBy, sortBy: index }, this.handleFilterTableItems )
  }

  handleFilterTableItems() {
    const { printShops } = this.props
    const { sortBy, lastSortBy, sortOrderBy } = this.state
    let ps = cloneDeep(printShops)
    // filter by search term
    let newSortByOrder = ['asc']
    if (sortBy === lastSortBy) newSortByOrder = sortOrderBy[0] === 'asc' ? ['desc'] : ['asc']
    ps = orderBy(ps, columns[sortBy].key, newSortByOrder)
    this.setState({ printShopsState: ps, sortOrderBy: newSortByOrder })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { editPrintShop, loading, error, trl } = this.props
    const {printShopsState} = this.state
    if (error) this.renderErrorPage()
    if (isEmpty(printShopsState) || loading) return <PageLoad text={trl.admin_brands__no_brand} size={80} thickness={9} />
    return (
      <div>
        <PageHeader title={trl.managePSH_Title} subtitle={trl.managePSH_Title} />
        <SC.PageGutter>{!isEmpty(printShopsState) &&
          <PrintShopsTable
            data={printShopsState}
            editPrintShop={editPrintShop}
            handelSortable={this.handelSortable} />}
        </SC.PageGutter>
        <div style={{ position: 'absolute', right: '20px' }}>

          <SC.ButtonPA_L
            onClick={this.savePrintShops}
          >
            {trl.managePSH_btn_Save}
          </SC.ButtonPA_L>
        </div>
      </div>
    )
  }

  renderErrorPage = () => {
    const { trl } = this.props
    return <h2>{trl.printShops_error_chargingData}</h2>
  }

  // Events Methods
  savePrintShops = () => {
    const { putPrintShops, printShops } = this.props
    putPrintShops(printShops).then(this.setState({ showCancelOrderConfirmation: !this.state.showCancelOrderConfirmation }));
  }
}

function mapStateToProps({ printShops }) {
  return {
    printShops: printShops.printShops,
    error: printShops.error,
    loading: printShops.loading
  }
}

ManagePrintshops.propTypes = {
  printShops: PropTypes.array.isRequired,
  trl: PropTypes.object.isRequired,
  fetchPrintShops: PropTypes.func.isRequired,
  putPrintShops: PropTypes.func.isRequired,
  editPrintShop: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchPrintShops, putPrintShops, editPrintShop })(ManagePrintshops)
