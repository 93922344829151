////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import { checkMaintenanceMode } from '../../../actions/maintenance'
import PageLoad from '../../../components/PageLoad'

class MaintenancePage extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    this.props.checkMaintenanceMode()
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl } = this.props
    if (this.props.maintenanceStatus === 'pending') return <PageLoad text={trl.common__loading} />
    return (
      <div style={{ margin: '0 auto', width: '400px' }}>
        <h1 style={{ textAlign: 'center', paddingTop: 100 }}>{trl.maintenance__title}</h1>
        <h5 style={{ padding: '20px 0' }}>{trl.maintenance__subtitle}</h5>
      </div>
    )
  }
}

function mapDispatchToProps({ maintenance }) {
  return { maintenanceStatus: maintenance.maintenance }
}

MaintenancePage.contextTypes = {
  store: PropTypes.object.isRequired
}
MaintenancePage.propTypes = {
  trl: PropTypes.object.isRequired
}

export default connect(mapDispatchToProps, { checkMaintenanceMode })(MaintenancePage)
