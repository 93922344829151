const passwordValidationHelper = (pass, trl) => {
  let message = null
  if (!pass) message = trl.common__password_required_error || ''
  else if (pass.length < 8) message = trl.common__password_length_error || ''
  else if (!/\w/g.test(pass)) message = trl.common__password_letter_error || ''
  else if (!/\d/g.test(pass)) message = trl.common__password_number_error || ''
  else if (!(/[A-Z]/g.test(pass) || /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/g.test(pass))) message = trl.common__password_special_error || ''
  return message
}

const passwordValidationFunction = (values, trl) => {
  const errors = {}
  errors.password = passwordValidationHelper(values.password, trl)
  errors.passwordConfirmation = passwordValidationHelper(values.passwordConfirmation, trl)
  if (values.password !== values.passwordConfirmation) errors.passwordConfirmation = trl.common__password_match_error || ''
  return errors
}

export default passwordValidationFunction
