////////LIBRARY/////////
import _, { findIndex, cloneDeep } from 'lodash'
///////COMPONENTS///////
import { translate } from '../containers/HOC/Translation'
import * as c from '../constants'

const INITIAL_STATE = {
  orders: [],
  filtersSettings: null,
  items: null,
  isLoading: false,
  isLoadingTable: false,
  xlsx: null,
  cancelledOrder: false,
  itemWithListSku: [],
  error: null,
  errorSkuMass: null,
  batLoading: false,
  detailLoading: false,
  isDetailLoading: false,
  // 151 - provisory modal reassign
  openReassign: false,
  openReassignConfirm: false,
  reassignLoaded: false,
  reassignUpdated: false,
  isModalOpened: false,
  cancelLoading: false
}
const formatOrder = (order) => {
  let newOrder = { [order.iD_Commande]: { ...order } }
  let commande_Articles = {}
  // according to back modification it's seems we don't need to loop here
  /*   newOrder[order.iD_Commande].commande_Articles.forEach(ca => {
      commande_Articles[ca.iD_Commande_Article] = ca
    }) */
  newOrder[order.iD_Commande].commande_Articles = commande_Articles
  return newOrder
}

const asyncDormatOrder = async (order) => {
  let newOrder = { [order.iD_Commande]: { ...order } }
  // Seems useless because of back-end refact
  /*let commande_Articles = {}
  await newOrder[order.iD_Commande].commande_Articles.forEach(ca => {
    commande_Articles[ca.iD_Commande_Article] = ca
  })
  newOrder[order.iD_Commande].commande_Articles = commande_Articles*/
  return newOrder
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_ORDERS_AND_ORDER_ARTICLES_REQUEST:
      // If we have cancelled an order, resetting the flag here because we are asking for a refresh
      return { ...state, cancelledOrder: false, items: null }
    case c.GET_ORDERS_AND_ORDER_ARTICLES:
      action.payload.ofuFilters.availableByParameters = _.chain(action.payload.ofuFilters.availableByParameters)
        .map((elem) => {
          return { key: elem, value: translate(elem) }
        })
        .sortBy('value')
        .value()
      action.payload.ofuFilters.availableByParameters.splice(0, 0, { key: 0, value: translate('common__all') })
      action.payload.ofuFilters.availableBrands.splice(0, 0, { key: 0, value: 'common__all' })
      action.payload.ofuFilters.availableGarmentMaker.splice(0, 0, { key: 0, value: 'common__all' })
      return {
        ...state,
        /*orders:Object.values(action.payload),*/ filtersSettings: action.payload.ofuFilters,
        items: Object.values(action.payload.ofuDetail),
        detailLoading: false,
        isDetailLoading: false
      }

    case c.API_GET_ORDER_ARTICLE_OFU_REQUEST:
      return {
        ...state,
        isLoadingTable: true
      }
    case c.API_GET_ORDER_ARTICLE_OFU_SUCCESS:
      return {
        ...state,
        isLoadingTable: false,
        detailLoading: false,
        isDetailLoading: false
      }
    case c.API_GET_ORDER_ARTICLE_OFU_FAILURE:
      return {
        ...state,
        isLoadingTable: false
      }
    case c.API_GET_ORDER_ARTICLE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        detailLoading: false
      }
    case c.API_GET_ORDER_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        detailLoading: true
        //loading:false
      }

    case c.UPDATE_ORDER:
      // Find order to replace also, so list is refreshed
      let orders = cloneDeep(state.items)
      // when we got article detailLoading is true because we have finish to load article to remplace order
      let detailLoading = cloneDeep(state.detailLoading)
      //  we have to test it for prevent render side effect to display btn or warning message
      if (detailLoading) {
        return {
          ...state,
          items: orders,
          orders: [],
          [action.idCommandeKey]: { ...state[action.idCommandeKey], ...action.payload },
          isDetailLoading: true
        }
      }
      const ind = findIndex(state.items, (o) => o.iD_Commande === action.payload.iD_Commande)
      if (ind > -1) {
        orders[ind] = action.payload
      }
      return {
        ...state,
        items: orders,
        orders: [],
        [action.idCommandeKey]: { ...state[action.idCommandeKey], ...action.payload }
      }
    case c.API_ORDER_SAP_SIMULATE_REQUEST:
      return { ...state, loading: true }
    case c.API_ORDER_SAP_SIMULATE_FAILURE:
      return { ...state, loading: false }
    case c.API_ORDER_SAP_SIMULATE_GET:
      const order = cloneDeep(action.payload)
      return { ...state, ...order, loading: false }
    case c.API_ORDER_SAP_CREATE_GET:
      return { ...state, ...action.payload }
    case c.API_GET_ORDERS_AND_ORDER_ARTICLES_RESET:
      return INITIAL_STATE

    case c.API_MODIFY_ALL_SKU:
      return {
        ...state,
        orders: action.payload
      }
    case c.API_MODIFY_ALL_SKU_REQUEST:
      return { ...state }
    case c.API_MODIFY_ALL_SKU_SUCCESS:
      return { ...state }
    case c.API_MODIFY_ALL_SKU_FAILURE:
      return { ...state, errorSkuMass: action.payload }
    case c.RESET_SKU_MASS_EDIT_ERROR:
      return { ...state, errorSkuMass: null }
    case c.API_ADD_SKU:
      return {
        ...state,
        orders: action.payload
      }
    case c.API_ADD_SKU_REQUEST:
      return { ...state, isLoadingSKU: true }
    case c.API_ADD_SKU_SUCCESS:
      return { ...state, isLoadingSKU: false }
    case c.API_ADD_SKU_FAILURE:
      return { ...state, isLoadingSKU: false }
    case c.API_EDIT_SKU:
      const ordersCp = cloneDeep(state)
      return {
        ...state,
        orders: !_.isUndefined(action.payload) ? action.payload : ordersCp.orders,
        error: action.error
      }
    case c.API_EDIT_SKU_REQUEST:
      return { ...state }
    case c.API_EDIT_SKU_SUCCESS:
      return { ...state }
    case c.API_EDIT_SKU_FAILURE:
      return { ...state }
    case c.API_DELETE_SKU:
      return { ...state, orders: action.payload }
    case c.API_DELETE_SKU_REQUEST:
      return { ...state }
    case c.API_DELETE_SKU_SUCCESS:
      return { ...state }

    case c.API_DELETE_SKU_FAILURE:
      return { ...state }

    case c.API_UPDATE_ORDER_ARTICLE_DVCS:
      return { ...state, orders: action.payload, error: null }

    case c.API_UPDATE_ORDER_ARTICLE_DVCS_SUCCESS:
      return { ...state, ...action.payload }

    case c.API_UPDATE_DVC_ONFOCUSOUT_SUCCESS:
      return {
        ...state,
        ...asyncDormatOrder(action.payload),
        onfocusout: { loading: false, sucess: true },
        currentOrder: action.payload
      }
    case c.API_UPDATE_DVC_ONFOCUSOUT_REQUEST:
      return { ...state, onfocusout: { loading: true } }
    case c.API_UPDATE_DVC_ONFOCUSOUT_FAILURE:
      return { ...state, onfocusout: { loading: false } }

    case c.API_ORDER_ARTICLE_COMPO_SUCCESS:
      return { ...state, ...formatOrder(action.payload) }
    case c.API_QUALIFY_ALL_SUCCESS:
      return { ...state, orders: action.payload }
    case c.API_CONFIRMBAT_REQUEST:
      return { ...state, batLoading: true }
    case c.API_CONFIRMBAT_SUCCESS:
      return { ...state, batLoading: false, ...formatOrder(action.payload) }

    case c.API_EXPORT_XL_OFU_REQUEST:
      //return { ...state, xlsx: {} }
      return { ...state, xlsx: null }
    case c.API_EXPORT_XL_OFU_SUCCESS:
      //return { ...state, xlsx: { file: action.payload } }
      return { ...state, xlsx: action.payload }
    case c.API_EXPORT_XL_OFU_FAILURE:
      //return { ...state, xlsx: { error: action.payload } }
      break
    case c.API_CREATE_ORDER_REQUEST:
      return { ...state, loading: true }
    case c.API_CREATE_ORDER_SUCCESS:
      return { ...state, loading: false }
    case c.API_UPDATE_ORDER_STATUS_CANCEL_REQUEST:
      return { ...state, cancelledOrder: false, cancelLoading: true }
    case c.API_UPDATE_ORDER_STATUS_CANCEL_SUCCESS:
      return { ...state, cancelledOrder: true, cancelLoading: false }
    case c.API_GET_SKU_LIST_REQUEST:
      return { ...state }
    case c.API_GET_SKU_LIST_SUCCESS:
      return { ...state }
    case c.API_GET_SKU_LIST_FAILURE:
      return { ...state }
    case c.API_GET_SKU_LIST:
      return {
        ...state,
        itemWithListSku: action.payload
      }
    case c.API_MODIFY_ALL_SKU_SUCCESS:
      let newOrders = cloneDeep(state.orders)
      newOrders.listSKU = action.payload
      return { ...state, orders: newOrders }
    case c.API_SHOW_MODAL_REASSIGN:
      return {
        ...state,
        openReassign: action.payload.openReassign,
        isModalOpened: action.payload.isModalOpened
      }
    case c.API_SHOW_MODAL_REASSIGN_CONFIRM:
      return {
        ...state,
        openReassignConfirm: action.payload.openReassignConfirm,
        openReassign: action.payload.openReassign,
        isModalOpened: action.payload.isModalOpened
      }
    case c.API_GET_REASSIGN_ORDER_FAILURE:
      return { ...state }
    case c.API_SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload.orderId
      }
    case c.API_GET_REASSIGN_ORDER_REQUEST:
      return { ...state, reassignLoaded: false }
    case c.API_GET_REASSIGN_ORDER_SUCCESS:
      return { ...state, reassign: action.payload, reassignLoaded: true }
    case c.API_PUT_REASSIGN_ORDER_REQUEST:
      return { ...state }
    case c.API_PUT_REASSIGN_ORDER_SUCCESS:
      return { ...state, openReassignConfirm: false, reassignUpdated: true }
    case c.API_RESET_REASSIGN_UPDATED:
      return { ...state, reassignUpdated: false }
    case c.RESET_ORDER_ARTICLE:
      return { ...INITIAL_STATE }
    case c.API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_REQUEST:
      return { ...state, loading: true }
    case c.API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_SUCCESS:
      return { ...state, loading: false }
    default:
      break
  }
  return state
}
