////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
///////COMPONENTS///////
import Toggle from 'material-ui/Toggle'

///////////////////////////////// RENDER ///////////////////////////////////////

const ToggleOnOff = props => {
  const { handleOnToggle, row, toogleKey } = props
  const children = get(row, toogleKey, false)
  return (
    <div style={{ width: '51px', margin: '0 auto' }}>
      <Toggle toggled={children} onToggle={() => handleOnToggle(row)} />
    </div>
  )
}

ToggleOnOff.propTypes = {
  handleOnToggle: PropTypes.func,
  row: PropTypes.object,
  toogleKey: PropTypes.string
}

export default ToggleOnOff
