////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _, { cloneDeep, orderBy, isEqual, isEmpty } from 'lodash'
import { TextField } from 'material-ui'
import styled from 'styled-components'
import moment from 'moment'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import Checkbox from 'material-ui/Checkbox'
import DatePicker from 'material-ui/DatePicker'
import dateFormatConverter from '../../utils/dateFormatConverter'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import MyDocumentationTable from '../../components/Sematable/Tables/MyDocumentationTable'
import { apiGetMyDocumentation } from '../../actions/myDocumentation'
import MenuItem from 'material-ui/MenuItem'
import { TextLeftStyledH4 } from '../../components/styledComponents/followUpStyles'
import * as c from '../../constants'

/////////STYLED/////////
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const Check = styled(Checkbox)`
  width: auto !important;
`
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
  margin-top: -10px;
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 100px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
`
const LeftFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  top: 8px;
  position: relative;
`
const RightFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  grid-auto-rows: max-content;
  align-items: center;
  margin-bottom: 30px;
`
/////////STYLED/////////

class MyDocumentation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      StartDocDate: moment(this.setStartDate()).startOf('day').toDate(),
      EndDocDate: moment(this.setEndDate()).endOf('day').toDate(),
      inputSearchBy: {
        'key': '',
        'value': 'common__all'
      },
      seeProformaInvoice: true,
      seeInvoice: true,
      //seeArchived: false
    }
    this.defaultState = this.state
  }

    ///////////////////////////////// CONFIG ///////////////////////////////////////

    queryData = (defaultState) => {
      const inputSearchBy = cloneDeep(this.state.inputSearchBy)
      inputSearchBy.value = inputSearchBy.value !== 'common__all' ? inputSearchBy.value : ''
      if (isEqual(defaultState, this.defaultState)) {
        this.props.apiGetMyDocumentation(this.props.userId, {
          StartDocDate: moment(this.defaultState.StartDocDate).startOf('day').toDate(),
          EndDocDate: moment(this.defaultState.EndDocDate).endOf('day').toDate(),
          inputSearchBy: this.defaultState.inputSearchBy,
          seeProformaInvoice: this.defaultState.seeProformaInvoice,
          seeInvoice: this.defaultState.seeInvoice,
          //seeArchived: this.defaultState.seeArchived,
        })
      } else {
        this.props.apiGetMyDocumentation(this.props.userId, {
          StartDocDate: moment(this.state.StartDocDate).startOf('day').toDate(),
          EndDocDate: moment(this.state.EndDocDate).endOf('day').toDate(),
          inputSearchBy: this.state.inputSearchBy,
          seeProformaInvoice: this.state.seeProformaInvoice,
          seeInvoice: this.state.seeInvoice,
          //seeArchived: this.state.seeArchived,
        })
      }
    }
  
    componentDidMount() {
      if(isEmpty(this.props.documentations) || this.props.location.search != '?isRedirect' ) this.queryData()
      if(this.props.location.search == '?isRedirect') window.history.pushState({},'#/' + c.myDocumentation)
    }
  
    componentWillReceiveProps(nextProps) {
      if (nextProps.documentations.myDocsToReturn !== this.props.documentations.myDocsToReturn) {
        this.setState({ documentations: nextProps.documentations }, this.handleFilterTableItems)
      }   
    }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleReset = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

  handleChangeDate = (e, date, name) => this.setState({ [name]: moment(date).toDate() }, this.handleFilterTableItems)

  handelSortable = (index, value) => {
    this.setState({ lastSortBy: this.state.sortBy, sortBy: index }, this.handleFilterTableItems)
  }

  changeInputSearchHandler = (event, index, value) => {
    this.setState({ inputSearchBy: { 'key': index, 'value': this.state.inputSearchBy.value }})
  }
  changeInputSearchByHandler = (event, index, value) => {
    this.setState({ inputSearchBy: { 'key': this.state.inputSearchBy.key, 'value': value }})
  }

  onFilterChange = filter => () => {
    this.setState(state => ({ [filter]: !state[filter] }))
  }

  setStartDate = () => {
    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)
    return new Date(startDate)
  }
  setEndDate = () => {
    let endDate = new Date()
    endDate.setMonth(endDate.getMonth() + 1)
    return new Date(endDate)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, documentations } = this.props
    const { hasOrderIdFilter } = this.state
    if (isLoading || !documentations.myDocsToReturn) return null
    let availableByParameters = documentations.filtersSettings.availableBy.map(elem => { return ({ 'key': elem, 'value': trl[elem] }) })

    return (
      <div>
        <PageHeader title={trl.mydocum_Title} />
        <SC.PageGutter>
          {/* ADVANCED SEARCH AND FILTER */}
          {/* 5-1,2 SEARCH & DOMAIN CHOICE */}
          {!hasOrderIdFilter && (
            <div>
              <FilterAndTableWrapper>
                <TableWrapper text={trl.common__search}>
                  <BodyTable>
                    <LeftFiltersWrapper>
                      <div>
                        <Label>{trl.common__search} :</Label>
                        <TextField
                          type="text"
                          style={{ width: 280, fontSize: theme.font.fontSize, height: 35 }}
                          name="searchTerm"
                          value={this.state.inputSearchBy.key}
                          onChange={this.changeInputSearchHandler}
                          onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                        />
                        <Label style={{ marginLeft: 10 }}>{trl.common__by} :</Label>
                      </div>
                      <div>
                        <SelectField
                          style={{
                            fontSize: theme.font.fontSize,
                            maxWidth: '195px',
                          }}
                          floatingLabelStyle={{
                            display: 'none'
                          }}
                          labelStyle={{
                            borderRadius: 0,
                            padding: '3px 30px 0 5px',
                            border: '1px solid #ced4da',
                            width: '100%',
                            height: '30px',
                            position: 'relative',
                            top: '9px',
                            lineHeight: 'inherit'
                          }}
                          underlineStyle={{
                            display: 'none'
                          }}
                          value={this.state.inputSearchBy.value}
                          onChange={this.changeInputSearchByHandler}
                        >
                          {
                            availableByParameters &&
                            orderBy(availableByParameters, ['value'], ['asc']).map(param =>
                              <MenuItem
                                key={param.key}
                                value={param.key}
                                primaryText={param.value}
                                style={{ fontSize: theme.font.fontSize }}
                              />
                            )
                          }
                        </SelectField>
                      </div>
                      <div>
                        <TableRow align={'flex-start'} margin={'10px 0 0 0'}>
                          <Check
                            checked={this.state.seeProformaInvoice}
                            onCheck={this.onFilterChange('seeProformaInvoice')}
                          />
                          <Label bold='bold' color='#00F359'>{trl.mydocum_filters_proforma}</Label>
                        </TableRow>
                        <TableRow align={'flex-start'} margin={'10px 0 0 0'}>
                          <Check
                            checked={this.state.seeInvoice}
                            onCheck={this.onFilterChange('seeInvoice')}
                          />
                          <Label bold='bold' color='#0FB6FE'>{trl.mydocum_filters_invoice}</Label>
                        </TableRow>
                        {/* <TableRow align={'flex-start'} margin={'10px 0 0 0'}>
                          <Check
                            checked={this.state.seeArchived}
                            onCheck={this.onFilterChange('seeArchived')}
                          />
                          <Label bold='bold'>{trl.common__filter_displayArchived}</Label>
                        </TableRow> */}
                      </div>
                    </LeftFiltersWrapper>
                    <RightFiltersWrapper>
                      <TextLeftStyledH4><Label>{trl.mydocum_tableTitle_docDate} :</Label></TextLeftStyledH4>
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '28%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          top: 2,
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_from}
                        container="inline"
                        onChange={(e, date) => this.handleChangeDate(e, date, 'StartDocDate')}
                        value={this.state.StartDocDate}
                        style={{ marginRight: 20 }}
                        formatDate={dateFormatConverter}
                        maxDate={this.state.EndDocDate}
                      />
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '28%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          top: 2,
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_to}
                        container="inline"
                        onChange={(e, date) => this.handleChangeDate(e, date, 'EndDocDate')}
                        value={this.state.EndDocDate}
                        style={{ paddingRight: 20 }}
                        minDate={this.state.StartDocDate}
                        formatDate={dateFormatConverter}
                      />
                    </RightFiltersWrapper>
                    <TableRow margin={'10px 0'}>
                      <ButtonWrapper>
                        <SC.ButtonPA_M
                          onClick={this.queryData}>
                          {trl.common__filter_apply_btn}
                        </SC.ButtonPA_M>
                        <SC.ButtonR_M
                          onClick={this.handleReset}>{trl.common__filter_reset_btn}</SC.ButtonR_M>
                      </ButtonWrapper>
                    </TableRow>
                  </BodyTable>
                </TableWrapper>
              </FilterAndTableWrapper>
            </div>
          )}
        </SC.PageGutter>
        <SC.PageGutter>
          {!_.isEqual(documentations.myDocsToReturn, []) ? (
            <MyDocumentationTable
              trl={trl}
              data={documentations.myDocsToReturn}
              className="DeliveryDetailTable"
              handelSortable={this.handelSortable}
            />
          ) : (
            <h3 style={{ textAlign: 'center' }}>{trl.common__noElementToDisplay}.</h3>
          )}
        </SC.PageGutter>
      </div>
    )
  }
}

MyDocumentation.propTypes = {
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  documentations: PropTypes.array,
  apiGetMyDocumentation: PropTypes.func.isRequired
}

MyDocumentation.defaultProps = {
  documentations: [],
  isLoading: true
}

const mapStateToProps = ({ myDocumentation, auth, apiCallStateManager }) => ({
  documentations: myDocumentation.list,
  role: auth.user_role,
  userId: auth.user_id,
  apiCallStateManager,
  isLoading: myDocumentation.isLoading
})

export default connect(
  mapStateToProps,
  {
    apiGetMyDocumentation
  }
)(MyDocumentation)

