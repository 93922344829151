////////LIBRARY/////////
import _, { find, cloneDeep } from 'lodash'
import { hashHistory } from 'react-router'
import { SubmissionError } from 'redux-form'
///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppAlert, addAppShowLoading, addAppHideLoading } from './appAlerts'
import { translate } from '../containers/HOC/Translation'

///////////////////////////////// HANDLE ///////////////////////////////////////

// HASHED SKUs
const hashedSKUs = (ca) => _.mapKeys(ca.skUs, 'iD_SKU')

// HASHED COMMANDE ARTICLES
const hashedCommandeArticles = (ca) => _.mapKeys(ca.commande_Articles, 'iD_Commande_Article')

// ORDERS WITH HASHED COMMANDE ARTICLES
const ordersWithHashedCommandeArticles = (res) =>
  res.map((ca) => ({ ...ca, commande_Articles: hashedCommandeArticles(ca) }))

////////////////////////////////////////////////////////////////////////////////

function updateOrder(payload) {
  // payload is order
  return (dispatch, getState) => {
    let order = find(getState().orders.items, payload.iD_Commande)
    if (order) {
      let sAO = order.commande_Articles || {}
      if (payload.commande_Articles) {
        payload.commande_Articles.map((ca) => {
          const oldObj = sAO[ca.iD_Commande_Article]
          let newSKU
          if (ca.skUs && ca.skUs.length) newSKU = hashedSKUs(ca)
          else if (oldObj && !_.isEmpty(oldObj.skUs)) newSKU = oldObj.skUs
          else newSKU = null
          delete ca.skUs // changing property type is not possible. Must delete property and then recreate it.
          ca.skUs = newSKU
          return {
            ...ca,
            dvCs:
              ca.dvCs && ca.dvCs.length ? ca.dvCs : oldObj && oldObj.dvCs && oldObj.dvCs.length ? oldObj.dvCs : null,
            compsotitions:
              ca.compsotitions && ca.compsotitions.length
                ? ca.compsotitions
                : oldObj && oldObj.compsotitions && oldObj.compsotitions.length
                ? oldObj.compsotitions
                : null,
            fabricList:
              ca.fabricList && ca.fabricList.length
                ? ca.fabricList
                : oldObj && oldObj.fabricList && oldObj.fabricList.length
                ? oldObj.fabricList
                : null,
            materialList:
              ca.materialList && ca.materialList.length
                ? ca.materialList
                : oldObj && oldObj.materialList && oldObj.materialList.length
                ? oldObj.materialList
                : null
          }
        })
      }
    }
    // payload is updated and normalized order
    dispatch({
      type: c.UPDATE_ORDER,
      idCommandeKey: payload.iD_Commande,
      payload: { ...payload, commande_Articles: hashedCommandeArticles(payload) }
    })
  }
}

////////////////////////////////////////////////////////////////////////////////

// GET ORDER AND ORDER ARTICLES - phase 1
function apiGetOrdersAndOrderArticlesRequest(endpointPath, additionalParams = {}) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ORDERS_AND_ORDER_ARTICLES_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
    if (additionalParams.forceUpdate) {
      dispatch({
        type: c.API_GET_ORDERS_AND_ORDER_ARTICLES_RESET,
        endpointPath
      })
    }
  }
}
function apiGetOrdersAndOrderArticlesSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_ORDERS_AND_ORDER_ARTICLES_SUCCESS, endpointPath })
    // Check if response is Array. Can return empty HTTP 200 if the user has no order to see.
    let orders = []
    if (_.isArray(res.ofuDetail)) {
      orders = res.ofuDetail
      const ordersWHCA = ordersWithHashedCommandeArticles(orders)
      var newObj = ordersWHCA.reduceRight((acc, cur, i) => {
        const objId = String(cur.iD_Commande)
        acc[objId] = { ...cur, sortNum: i }
        return acc
      }, {})
      orders.ofuDetail = newObj
      orders.ofuFilters = res.ofuFilters
    }
    dispatch({
      type: c.GET_ORDERS_AND_ORDER_ARTICLES,
      payload: orders,
      endpointPath
    })
  }
}
function apiGetOrdersAndOrderArticlesFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_ORDERS_AND_ORDER_ARTICLES_FAILURE, endpointPath })
  }
}
export function apiGetOrdersAndOrderArticles(additionalParams = {}) {
  return u.getValueFromStore(
    c.apiOrders,
    h.headerPutWithToken(additionalParams),
    apiGetOrdersAndOrderArticlesRequest,
    apiGetOrdersAndOrderArticlesSuccess,
    apiGetOrdersAndOrderArticlesFailure
  )
}

// GET SKU ON MODAL
function apiGetSkuListRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_SKU_LIST_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiGetSkuListSuccess(endpointPath, payload) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_GET_SKU_LIST_SUCCESS, endpointPath })
    //dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
    dispatch({
      type: c.API_GET_SKU_LIST,
      payload: payload
    })
  }
}
function apiGetSkuListFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_SKU_LIST_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
  }
}
export function apiGetSkuList(orderId, orderItemId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}/article/${orderItemId}/ofu`,
    h.headerGetWithToken(),
    apiGetSkuListRequest,
    apiGetSkuListSuccess,
    apiGetSkuListFailure,
    null,
    { forceUpdate: true }
  )
}

// RESET SKU MASS EDIT
export function resetSkuMassEditError() {
  return (dispatch) => {
    dispatch({ type: c.RESET_SKU_MASS_EDIT_ERROR })
  }
}

// GET ORDER ARTICLE
function apiGetOrderArticleRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ORDER_ARTICLE_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiGetOrderArticleSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_GET_ORDER_ARTICLE_SUCCESS, endpointPath })
    dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
  }
}
function apiGetOrderArticleFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ORDER_ARTICLE_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
  }
}
export function apiGetOrderArticle(orderId, orderItemId = null, urlPart = null) {
  return u.getValueFromStore(
    !_.isNull(orderItemId) && !_.isNull(urlPart)
      ? `${c.apiOrderDetail}${orderId}/article/${orderItemId}/${urlPart}`
      : !_.isNull(orderItemId)
      ? `${c.apiOrderDetail}${orderId}/article/${orderItemId}`
      : `${c.apiOrderDetail}${orderId}`,
    h.headerGetWithToken(),
    apiGetOrderArticleRequest,
    apiGetOrderArticleSuccess,
    apiGetOrderArticleFailure,
    null,
    { forceUpdate: true }
  )
}

// GET ORDER ARTICLE OFU
function apiGetOrderArticleOFURequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ORDER_ARTICLE_OFU_REQUEST, endpointPath })
  }
}
function apiGetOrderArticleOFUSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ORDER_ARTICLE_OFU_SUCCESS, endpointPath })
    res._isHydrated = true
    dispatch(updateOrder(res))
  }
}
function apiGetOrderArticleOFUFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ORDER_ARTICLE_OFU_FAILURE, endpointPath })
  }
}
export function apiGetOrderArticleOFU(orderId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiOFU}`,
    h.headerGetWithToken(),
    apiGetOrderArticleOFURequest,
    apiGetOrderArticleOFUSuccess,
    apiGetOrderArticleOFUFailure,
    null,
    { forceUpdate: true }
  )
}

// UPDATE DVC
function apiUpdateDvcsRequest(endpointPath) {
  return { type: c.API_UPDATE_ORDER_ARTICLE_DVCS_REQUEST, endpointPath }
}
function apiUpdateDvcsSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_DVCS_SUCCESS, endpointPath })
    dispatch(updateOrder(res))
  }
}
function apiUpdateDvcsFailure(endpointPath, err) {
  return { type: c.API_UPDATE_ORDER_ARTICLE_DVCS_FAILURE, endpointPath }
}
export function apiUpdateDvcs(orderId, articleId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiArticleFixe}${articleId}${c.apiDvcArticle}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateDvcsRequest,
    apiUpdateDvcsSuccess,
    apiUpdateDvcsFailure,
    true
  )
}

// UPDATE COMPOSITION
function apiUpdateCompositionsRequest(endpointPath) {
  return { type: c.API_UPDATE_ORDER_ARTICLE_COMPOSITION_REQUEST, endpointPath }
}
function apiUpdateCompositionsSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_COMPOSITION_SUCCESS, endpointPath })
    dispatch(updateOrder(res))
  }
}
function apiUpdateCompositionsFailure(endpointPath, err) {
  return { type: c.API_UPDATE_ORDER_ARTICLE_COMPOSITION_FAILURE, endpointPath }
}
export function apiUpdateCompositions(orderId, articleId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiArticleFixe}${articleId}${c.apiQualifyAll}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateCompositionsRequest,
    apiUpdateCompositionsSuccess,
    apiUpdateCompositionsFailure
  )
}

// EDIT SKU
function apiUpdateSkuDvssRequest(endpointPath) {
  return { type: c.API_UPDATE_ORDER_ARTICLE_SKU_DVSS_REQUEST, endpointPath }
}
function apiUpdateSkuDvssSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_SKU_DVSS_SUCCESS, endpointPath })
    dispatch(updateOrder(res))
    const commandArticleId = parseInt(hashHistory.getCurrentLocation().pathname.split(c.articleVariable)[1], 10)
    if (commandArticleId) {
      const skus = res.commande_Articles.find((ca) => ca.iD_Commande_Article === commandArticleId).skUs
      const skusComplete = _.toArray(skus).reduce(
        (tot, currentValue) => (currentValue.status.idStatus !== 29 ? false : tot),
        true
      ) // 29 means that all SKUs are qualified
      if (skusComplete) {
        const trl = getState().languages.siteLanguage.keys
        dispatch(addAppAlert(`${trl.common__notification_article_sku_all_qualified}`, true))
        hashHistory.push(`${c.orderDetail}${res.iD_Commande}`)
      }
    }
  }
}
function apiUpdateSkuDvssFailure(endpointPath, err) {
  return { type: c.API_UPDATE_ORDER_ARTICLE_SKU_DVSS_FAILURE, endpointPath }
}
export function apiUpdateSkuDvss(orderId, commandeArticleId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiArticleFixe}${commandeArticleId}${c.apiSkuArticle}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateSkuDvssRequest,
    apiUpdateSkuDvssSuccess,
    apiUpdateSkuDvssFailure,
    true
  )
}

// UPDATE ORDER STATUS QUALIFIED
function apiUpdateOrderStatusQualifiedRequest(endpointPath) {
  return { type: c.API_UPDATE_ORDER_STATUS_QUALIFIED_REQUEST, endpointPath }
}
function apiUpdateOrderStatusQualifiedSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_STATUS_QUALIFIED_SUCCESS, endpointPath })
    dispatch(updateOrder(res))
    const routeToPush = hashHistory.getCurrentLocation().pathname.replace(c.confirmOrder, c.confirmOrderSuccess)
    hashHistory.push(routeToPush)
  }
}
function apiUpdateOrderStatusQualifiedFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_STATUS_QUALIFIED_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateOrderStatusQualified(orderId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiOrderConfirm}`,
    h.headerPutWithToken(),
    apiUpdateOrderStatusQualifiedRequest,
    apiUpdateOrderStatusQualifiedSuccess,
    apiUpdateOrderStatusQualifiedFailure
  )
}

// UPDATE ORDER DELIVERY DETAILS
function apiUpdateOrderDeliveryDetailsRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiUpdateOrderDeliveryDetailsSuccessValidate(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_SUCCESS, endpointPath })
    res.notUpdateState = false
    dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(`${trl.common__delivery_saved}`))
    const routeToPush = hashHistory.getCurrentLocation().pathname.replace(c.orderDeliveryDetails, c.confirmOrder)
    hashHistory.push(routeToPush)
  }
}
function apiUpdateOrderDeliveryDetailsNoRedirectSuccessValidate(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_SUCCESS, endpointPath })
    res.notUpdateState = false
    dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(`${trl.common__delivery_saved}`))
  }
}
function apiUpdateOrderDeliveryDetailsSuccessDraft(endpointPath, res) {
  return (dispatch, getState) => {
    res.notUpdateState = false
    dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_SUCCESS, endpointPath })
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(`${trl.common__delivery_saved_draft}`))
    hashHistory.push(`${c.followUp}`)
  }
}

function apiUpdateOrderDeliveryDetailsFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateOrderDeliveryDetails(m, orderId, isDraft, redirect = true) {
  // need to choose the success function according to wheather the user cliked on validate or draft
  const auoddsv = redirect
    ? apiUpdateOrderDeliveryDetailsSuccessValidate
    : apiUpdateOrderDeliveryDetailsNoRedirectSuccessValidate
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiDeliveryDetails}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateOrderDeliveryDetailsRequest,
    isDraft ? apiUpdateOrderDeliveryDetailsSuccessDraft : auoddsv,
    apiUpdateOrderDeliveryDetailsFailure
  )
}

// API_CHANGE_GEOGRAPHICAL_AREA
function apiUpdateOrderGeographicalAreaRequest(endpointPath) {
  return { type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_REQUEST, endpointPath }
}
function apiUpdateOrderGeographicalAreaSuccessValidate(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_SUCCESS, endpointPath })
    dispatch(updateOrder(res))
  }
}
function apiUpdateOrderGeographicalAreaFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_DELIVERY_DETAILS_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateOrderGeographicalArea(m, orderId) {
  // need to choose the success function according to wheather the user cliked on validate or draft
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiDeliveryDetails}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateOrderGeographicalAreaRequest,
    apiUpdateOrderGeographicalAreaSuccessValidate,
    apiUpdateOrderGeographicalAreaFailure
  )
}

// UPDATE FIXED ARTICLE QUANTITY
function apiUpdateOrderArticleStaticRequest(endpointPath) {
  return { type: c.API_UPDATE_ORDER_ARTICLE_STATIC_REQUEST, endpointPath }
}
function apiUpdateOrderArticleStaticSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_STATIC_SUCCESS, endpointPath })
    dispatch(updateOrder(res))
    const { iD_Commande, status } = res
    const iD_Status = status.idStatus
    dispatch(apiOrderSapSimulate({ iD_Commande, iD_Status }))
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(`${trl.common__notification_article_fix_qty_edited}`))
    const routeToPush = `${c.orderDetail}${res.iD_Commande}`
    hashHistory.push(routeToPush)
  }
}
function apiUpdateOrderArticleStaticFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_STATIC_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateOrderArticleStatic(orderId, idCommandeArticle, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiArticleFixe}${idCommandeArticle}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateOrderArticleStaticRequest,
    apiUpdateOrderArticleStaticSuccess,
    apiUpdateOrderArticleStaticFailure,
    true
  )
}

// CANCEL ORDER
function apiUpdateOrderStatusCancelRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_STATUS_CANCEL_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiUpdateOrderStatusCancelSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDATE_ORDER_STATUS_CANCEL_SUCCESS, endpointPath })
    dispatch(addAppAlert(translate('order_actions_message__orderCancelled'), true))
    dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
  }
}
function apiUpdateOrderStatusCancelFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_STATUS_CANCEL_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateOrderStatusCancel(orderId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiCancelOrder}`,
    h.headerPutWithToken(),
    apiUpdateOrderStatusCancelRequest,
    apiUpdateOrderStatusCancelSuccess,
    apiUpdateOrderStatusCancelFailure
  )
}

// CANCEL ORDER ARTICLE
function apiUpdateOrderArticleStatusCancelRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_REQUEST, endpointPath })
  }
}
function apiUpdateOrderArticleStatusCancelSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_SUCCESS, endpointPath })
    dispatch(addAppAlert(translate('orders_actions_message__itemCancelled'), true))
    dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
  }
}
function apiUpdateOrderArticleStatusCancelFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_STATUS_CANCEL_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateOrderArticleStatusCancel(orderId, idCommandeArticle) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiArticleFixe}${idCommandeArticle}${c.apiCancelOrder}`,
    h.headerPutWithToken(),
    apiUpdateOrderArticleStatusCancelRequest,
    apiUpdateOrderArticleStatusCancelSuccess,
    apiUpdateOrderArticleStatusCancelFailure
  )
}

// REPEAT ORDER
function apiRepeatOrderRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_REPEAT_ORDER_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiRepeatOrderSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_REPEAT_ORDER_SUCCESS, endpointPath })
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(trl.common__notification_id_ext_fac_edited))
    dispatch(updateOrder(res))
    dispatch(addAppHideLoading())
    const routeToPush = `${c.orderDetail}${res.iD_Commande}`
    hashHistory.push(routeToPush)
  }
}
function apiRepeatOrderFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_REPEAT_ORDER_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
  }
}
export function apiRepeatOrder(orderId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiRepeat}`,
    h.headerPutWithToken(),
    apiRepeatOrderRequest,
    apiRepeatOrderSuccess,
    apiRepeatOrderFailure,
    false
  )
}

// EDIT ID FACONNIER
function apiUpdateOrderPoFacRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_UPDATE_ORDER_PO_FAC_REQUEST, endpointPath })
  }
}
function apiUpdateOrderPoFacSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_ORDER_PO_FAC_SUCCESS, endpointPath })
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(trl.common__notification__orderUpdated, true))
    res.notUpdateState = false
    dispatch(updateOrder(res))
  }
}
function apiUpdateOrderPoFacFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_ORDER_PO_FAC_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateOrderPoFac(orderId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiIdExtFaconnier}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateOrderPoFacRequest,
    apiUpdateOrderPoFacSuccess,
    apiUpdateOrderPoFacFailure,
    true
  )
}

// EDIT WISHED EXPEDITION DATE
function apiUpdateWishedExpeditionDateRequest(endpointPath) {
  return { type: c.API_UPDTATE_WISHED_EXPEDITION_DATE_REQUEST, endpointPath }
}
function apiUpdateWishedExpeditionDateSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_UPDTATE_WISHED_EXPEDITION_DATE_SUCCESS, endpointPath })
    dispatch(addAppAlert(`${translate('order_actions_message__orderUpdated')}.`, true))
    res.notUpdateState = false
    dispatch(updateOrder(res))
  }
}
function apiUpdateWishedExpeditionDateFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDTATE_WISHED_EXPEDITION_DATE_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateWishedExpeditionDate(orderId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiupdateWishedExpeditionDate}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateWishedExpeditionDateRequest,
    apiUpdateWishedExpeditionDateSuccess,
    apiUpdateWishedExpeditionDateFailure,
    true
  )
}

// ADD ITEM TO ORDER
function apiAddItemToOrderRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_ADD_ITEM_TO_ORDER_REQUEST, endpointPath })
  }
}
function apiAddItemToOrderSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_ADD_ITEM_TO_ORDER_SUCCESS, payload: res, endpointPath })
    dispatch(
      addAppAlert(
        translate('order_actions_message__itemAddedOrder') || 'Your article has been added to your order',
        false
      )
    ) // todo : trl
    dispatch(addAppHideLoading())
  }
}
function apiAddItemToOrderFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_ADD_ITEM_TO_ORDER_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiAddItemToOrder(orderId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiOrderAddItem}`,
    h.headerPutWithTokenAndModel(m),
    apiAddItemToOrderRequest,
    apiAddItemToOrderSuccess,
    apiAddItemToOrderFailure,
    true
  )
}

// NEW ORDER
function apiCreateOrderRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_CREATE_ORDER_REQUEST, endpointPath })
  }
}
function apiCreateOrderSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch(addAppHideLoading())
    dispatch(addAppAlert(translate('order_actions_message__orderCreated'), true))
    dispatch({ type: c.API_CREATE_ORDER_SUCCESS, endpointPath })
    hashHistory.push(`${c.orderDetail}${res.iD_Commande}`)
  }
}
function apiCreateOrderFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_CREATE_ORDER_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiCreateOrder(brandId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${c.apiOrderNew}${brandId}`,
    h.headerPutWithToken(),
    apiCreateOrderRequest,
    apiCreateOrderSuccess,
    apiCreateOrderFailure
  )
}

// CONFIRM ORDER
function apiConfirmOrderRequest(endpointPath) {
  return { type: c.API_CREATE_ORDER_REQUEST, endpointPath }
}
function apiConfirmOrderSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_CREATE_ORDER_SUCCESS, endpointPath })
    dispatch(addAppAlert(translate('order_actions_message__orderCreated'), true))
    dispatch({ type: c.API_GET_ORDERS_AND_ORDER_ARTICLES_RESET, endpointPath: c.apiOrders })
    dispatch(updateOrder(res))
    if (res.status.idStatus === 25) {
      const routeToPush = hashHistory.getCurrentLocation().pathname.replace(c.confirmOrder, c.confirmOrderMessage)
      hashHistory.push(routeToPush)
      return
    }
    const routeToPush = hashHistory.getCurrentLocation().pathname.replace(c.confirmOrder, c.confirmOrderSuccess)
    hashHistory.push(routeToPush)
  }
}
function apiConfirmOrderFailure(endpointPath, err) {
  return (dispatch) => {
    const routeToPush = hashHistory.getCurrentLocation().pathname.replace(c.confirmOrder, c.confirmOrderFailure)
    hashHistory.push(routeToPush)
    dispatch({ type: c.API_CREATE_ORDER_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiConfirmOrder({ iD_Commande, iD_Status }) {
    return u.getValueFromStore(
      `${c.apiOrderDetail}${iD_Commande}${c.apiConfirmOrder}`,
      h.headerPutWithToken(),
      apiConfirmOrderRequest,
      apiConfirmOrderSuccess,
      apiConfirmOrderFailure
    )
    /*return (dispatch) => {
      dispatch({ type: 'API_ORDER_SAP_SIMULATE_NAN' })
      const routeToPush = hashHistory.getCurrentLocation().pathname.replace(c.confirmOrder, c.confirmOrderFailure)
      hashHistory.push(routeToPush)
    }*/
}

// SAP SIMULATE
function orderSapSimulateRequest(endpointPath) {
  return (dispatch) => {
    //dispatch(addAppShowLoading())
    dispatch({ type: c.API_ORDER_SAP_SIMULATE_REQUEST, endpointPath })
  }
}
function orderSapSimulateSuccess(endpointPath, res) {
  const order = _.mapKeys(ordersWithHashedCommandeArticles([{ ...res, notUpdateState: true }]), 'iD_Commande') || {}
  return (dispatch, getState) => {
    //dispatch(addAppHideLoading())
    dispatch({ type: c.API_ORDER_SAP_SIMULATE_GET, payload: order })
  }
}
function orderSapSimulateFailure(endpointPath, err) {
  return (dispatch) => {
    //dispatch(addAppHideLoading())
    dispatch({ type: c.API_ORDER_SAP_SIMULATE_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiOrderSapSimulate({ iD_Commande, iD_Status, articleAdded }) {
  if (iD_Status >= 3 && iD_Status <= 9 || articleAdded) {
    return u.getValueFromStore(
      `${c.apiOrderDetail}${iD_Commande}${c.apiSapSimulation}`,
      h.headerGetWithToken(),
      orderSapSimulateRequest,
      orderSapSimulateSuccess,
      orderSapSimulateFailure
    )
  } else {
    return (dispatch) => {
      dispatch({ type: 'API_ORDER_SAP_SIMULATE_NAN' })
    }
  }
}

// SAP CREATE
function orderSapCreateRequest(endpointPath) {
  return { type: c.API_ORDER_SAP_CREATE_REQUEST, endpointPath }
}
function orderSapCreateSuccess(endpointPath, res) {
  const order = _.mapKeys(ordersWithHashedCommandeArticles([res]), 'iD_Commande') || {}
  return (dispatch, getState) => {
    dispatch({ type: c.API_ORDER_SAP_CREATE_GET, payload: order })
  }
}
function orderSapCreateSuccessS2(endpointPath, res) {
  const order = _.mapKeys(ordersWithHashedCommandeArticles([res]), 'iD_Commande') || {}
  return (dispatch, getState) => {
    dispatch({ type: c.API_ORDER_SAP_CREATE_GET, payload: order })
    dispatch(apiUpdateOrderStatusQualified(res.iD_Commande))
  }
}
function orderSapCreateFailure(endpointPath, err) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_ORDER_SAP_CREATE_FAILURE, endpointPath })
    const routeToPush = hashHistory.getCurrentLocation().pathname.replace(c.confirmOrder, c.confirmOrderFailure)
    hashHistory.push(routeToPush)
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiOrderSapCreate({ iD_Commande, iD_Status }, scucess2 = false) {
  const oscs = scucess2 ? orderSapCreateSuccessS2 : orderSapCreateSuccess
  if (iD_Status >= 2 && iD_Status <= 9) {
    return u.getValueFromStore(
      `${c.apiOrderDetail}${iD_Commande}${c.apiSapCreate}`,
      h.headerPostWithTokenAndModel(),
      orderSapCreateRequest,
      oscs,
      orderSapCreateFailure
    )
  }
  return (dispatch) => {
    dispatch({ type: 'API_ORDER_SAP_CREATE_NAN' })
  }
}

// OFU EXPORT
function apiOFUExportXlRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_OFU_REQUEST, endpointPath })
    dispatch(addAppShowLoading(true))
  }
}
function apiOFUExportXlSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_OFU_SUCCESS, payload: res })
    dispatch(addAppHideLoading(true))
  }
}
function apiOFUExportXlFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_OFU_FAILURE, endpointPath })
    dispatch(addAppHideLoading(true))
  }
}
export function apiOFUExportXl(params) {
  return u.getValueFromStore(
    `${c.apiExportofu}`,
    h.headerPutWithTokenAndModel(params),
    apiOFUExportXlRequest,
    apiOFUExportXlSuccess,
    apiOFUExportXlFailure,
    true
  )
}

// TAKE OWNERSHIP
function apiTakeOwnershipRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_TAKE_OWNER_SHIP_REQUEST, endpointPath })
    dispatch(addAppShowLoading(true))
  }
}
function apiTakeOwnershipSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_TAKE_OWNER_SHIP_SUCCESS, payload: res })
    dispatch(addAppHideLoading())
    dispatch(updateOrder(res))
  }
}
function apiTakeOwnershipFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppAlert('Action Failed', true))
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_TAKE_OWNER_SHIP_FAILURE, endpointPath })
  }
}
export function apiTakeOwnership(orderId) {
  return u.getValueFromStore(
    `${c.apiCompanyType}${orderId}${c.apiTakeOwnerShip}`,
    h.headerPutWithToken(),
    apiTakeOwnershipRequest,
    apiTakeOwnershipSuccess,
    apiTakeOwnershipFailure,
    true
  )
}

// SHOW MODAL REASSIGN
export function apiShowModalReassign(payload) {
  return (dispatch) => {
    dispatch({ type: c.API_SHOW_MODAL_REASSIGN, payload })
  }
}

// API SET ORDER ID
export function apiSetOrderID(payload) {
  return (dispatch) => {
    dispatch({ type: c.API_SET_ORDER_ID, payload })
  }
}

// SHOW MODAL REASSIGN CONFIRM
export function apiShowModalReassignConfirm(payload) {
  return (dispatch) => {
    dispatch({ type: c.API_SHOW_MODAL_REASSIGN_CONFIRM, payload })
  }
}

// // GET REASSIGN ORDER
function apiGetReassignOrderRequest(orderId) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_REASSIGN_ORDER_REQUEST, orderId })
  }
}
function apiGetReassignOrderSuccess(orderId, res) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_REASSIGN_ORDER_SUCCESS, payload: res })
  }
}
function apiGetReassignOrderFailure(orderId, err) {
  return (dispatch) => {
    dispatch(addAppAlert('Action Failed', true))
    dispatch({ type: c.API_GET_REASSIGN_ORDER_FAILURE, orderId })
  }
}
export function apiGetReassignOrder(orderId) {
  return u.getValueFromStore(
    `${c.apiCompanyType}${orderId}${c.apiReassign}`,
    h.headerGetWithToken(),
    apiGetReassignOrderRequest,
    apiGetReassignOrderSuccess,
    apiGetReassignOrderFailure
  )
}

// PUT REASSIGN ORDER
function apiPutReassignOrderRequest(orderId, gmId) {
  return (dispatch) => {
    dispatch({ type: c.API_PUT_REASSIGN_ORDER_REQUEST, orderId, gmId })
  }
}
function apiPutReassignOrderSuccess(orderId, gmId, res) {
  return (dispatch) => {
    dispatch({ type: c.API_PUT_REASSIGN_ORDER_SUCCESS, payload: res })
  }
}
function apiPutReassignOrderFailure(orderId, gmId, err) {
  return (dispatch) => {
    dispatch(addAppAlert(translate('order__action__reassignOrderFailed'), true))
    dispatch({ type: c.API_PUT_REASSIGN_ORDER_FAILURE, orderId })
  }
}
export function apiPutReassignOrder(orderId, gmId) {
  return u.getValueFromStore(
    `${c.apiCompanyType}${orderId}${c.apiReassign}/${gmId}`,
    h.headerPutWithToken(),
    apiPutReassignOrderRequest,
    apiPutReassignOrderSuccess,
    apiPutReassignOrderFailure
  )
}
// RESET REASSIGN UPDATED
export function apiResetReassignUpdated(payload) {
  return (dispatch) => {
    dispatch({ type: c.API_RESET_REASSIGN_UPDATED, payload })
  }
}

// RESET ORDER ARTICLE
export function resetOrderArticle(payload) {
  return (dispatch) => {
    dispatch({ type: c.RESET_ORDER_ARTICLE, payload })
  }
}
