////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import { get } from 'lodash'
///////COMPONENTS///////
import MyCarriersActions from '../Actions/MyCarriersActions'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import SortableItem from '../ColumnHelpers/SortableItem'

export const columns = [
  { key: 'transporteur_Name', sortable: true, header: translate('mycarriers_tableTitle_Label'), primaryKey: true, Component: SortableItem },
  { key: 'service', sortable: true, header: translate('mycarriers_tableTitle_Service'), Component: SortableItem },
  { key: 'carrier.label', sortable: true, header: translate('mycarriers_tableTitle_CarrierName'), Component: SortableItem},
  { key: 'iD_Client_External', sortable: true, header: translate('mycarriers_tableTitle_Account'), Component: SortableItem },
  { key: 'actions', header: translate('mycarriers_tableTitle_Actions'), Component: MyCarriersActions }
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}

class DeliveryFollowUpTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, handelSortable, handleOpenEdit } = this.props
    if (!data.length) return null
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  {columns.map((c, index) => (
                    <SortableHeader
                      {...c}
                      sortKey={index.toString()}
                      name={c.header}
                      handleClick={index => handelSortable(index)}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={'tableLigneGrey'}>
                      {columns.map(c => {
                        const children = get(item, c.key)
                        return (
                          <td key={'__' + c.key} className={c.className}>
                            {c.Component && c.Component !== null
                              ? React.createElement(c.Component, { row: item, children, handleOpenEdit })
                              : children}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}

DeliveryFollowUpTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  handelSortable: PropTypes.func
}

export default sematable('OrdersFollowUp', DeliveryFollowUpTable, columns, config)