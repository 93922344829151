////////LIBRARY/////////
import React from 'react'

const StaticVariable = ({ children, row }) => {

	///////////////////////////////// RENDER ///////////////////////////////////////

  return (
      <span>{`${row.article_Type_Name} / ${row.article_Fixe ? 'F' : 'V'}`}</span>
  )
}

StaticVariable.propTypes = {}
export default StaticVariable
