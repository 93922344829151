import * as c from '../constants'

const INITIAL_STATE = {
  item: {},
  isLoading: true,
  errors: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_SHIPPEMENT_HISTORY_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_GET_SHIPPEMENT_HISTORY_FAILURE:
      return { ...state, isLoading: false }
    case c.API_GET_SHIPPEMENT_HISTORY_SUCCESS: {
      return { ...state, item: action.payload, isLoading: false, errors: {} }
    }
    default:
      break
  }
  return state
}
