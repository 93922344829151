const theme = {
  colors: {
    darkGrey: '#575756',
    lightGrey: '#dddddd',
    red: '#ff0000',
    green: '#33cc2b',
    lightGreen: '#c6f9c3',
    blue: '#009fe3',
    skyBlue: '#d4edfc',
    orange: '#f39200',
    black: 'black',
    white: 'white',
    yellow: '#ffff00'
  },
  font: {
    fontSize: '12px',
    fontFamilyName: 'Gotham',
    normal: 'Gotham',
    italic: 'Gotham-Italic',
    bold: 'Gotham-Bold',
    boldItalic: 'Gotham-BoldItalic',
  },
  fontSize: {
    fontSize: '12px',
  },
  sizes: {
    // lg: 992, // desktop
    md: 768, // max tablet
    sm: 450, // max Mobile
    max: '1200px'
  },
  transitionTime: '300ms',
  goldenRatio: 7,
  h1: {
    ftz: '28px',
    ftzTablet: '25px',
    ftzMobile: '22px',
    letterSpacing: '0.03em'
  },
  h2: {
    ftz: '22px', // 15.59pt
    ftzTablet: '20px',
    ftzMobile: '18px',
    letterSpacing: '0.03em'
  },
  h3: {
    ftz: '19px', //14pt
    ftzTablet: '17px',
    ftzMobile: '15px',
    letterSpacing: '0.03em'
  },
  h4: {
    ftz: '17px', //13pt
    ftzTablet: '15px',
    ftzMobile: '13px',
    letterSpacing: '0.03em'
  },
  h5: {
    ftz: '16px', //12pt
    ftzTablet: '14px',
    ftzMobile: '12px',
    letterSpacing: '0.03em'
  },
  h6: {
    ftz: '12px', //8.5pt
    ftzTablet: '11px',
    ftzMobile: '9px',
    letterSpacing: '0.03em'
  },
  p1: {
    ftz: '12px', //14.17pt
    ftzTablet: '17px',
    ftzMobile: '15px',
    letterSpacing: '0'
  },
  p2: {
    ftz: '16px', //12.76pt
    ftzTablet: '15px',
    ftzMobile: '13px',
    letterSpacing: '0'
  },
  p3: {
    ftz: '16px', //12pt
    ftzTablet: '14px',
    ftzMobile: '12px',
    letterSpacing: '0'
  },
  p4: {
    ftz: '16px', //11.34pt
    ftzTablet: '13px',
    ftzMobile: '11px',
    letterSpacing: '0'
  },
  p5: {
    ftz: '16px', //9.65pt
    ftzTablet: '11px',
    ftzMobile: '10px',
    letterSpacing: '0'
  },
}

export default theme
