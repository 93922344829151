////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _, { isEmpty ,map ,find ,isNull ,flattenDeep ,isUndefined, cloneDeep, remove, isEqual } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import DatePicker from 'material-ui/DatePicker'
import Checkbox from 'material-ui/Checkbox'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { apiGetOrdersAndOrderArticles, apiOFUExportXl, apiShowModalReassign, apiShowModalReassignConfirm, apiPutReassignOrder, apiResetReassignUpdated } from '../../actions/orders'
import OrdersFollowUpTable, { searchOption } from '../../components/Sematable/Tables/OrdersFolowUpTable'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import dateFormatConverter from '../../utils/dateFormatConverter'
import { theme } from '../../components/styledComponents'
import { FilterContainer } from '../../components/styledComponents/followUpStyles'
import ReassignModal from '../../components/Modal/ReassignModal'
import './followUp.css'
import TextField from 'material-ui/TextField'
import PageLoad from '../../components/PageLoad'

/////////STYLED/////////
const FormWrapped = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: content-box;
`
const CheckboxWrapperRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 16px;
  justify-items: center;
  width: 100%;
  grid-column: 1 / 3;
`
const Label = styled.span`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  font-size: ${(props) => (props.isTitle ? '14px' : '12px')};
  margin-right: 8px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
 text-transform:${props => props.upper ? 'uppercase' : 'none'}
`
const Check = styled(Checkbox)`
  width: auto !important;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  margin-bottom: 16px;
`
const FooterContainer = styled.div`
  width:100%;
  max-height:80px;
  display:grid;
  grid-template-columns:repeat(3,1fr);
  margin:5px 0;
`
const FooterLeft = styled.div`
  height:100%;
`
const FooterMiddle = styled.div`
   height:100%;
`
const FooterRight = styled.div`
   height:100%;
`
const ButtonWrapper = styled.div`
  display:flex;
  justify-content:space-evenly;
`
const ExportButtonWrapper = styled.div`
  display:flex;
  justify-content:flex-end;
`
const FilterAndTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 2px 20px 40px;
`
const LeftFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns:repeat(1,1fr);
  padding-top: 3px;
`
const RightFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns:repeat(1,1fr);
  height: 180px;
`
const FilterWrapperByThree = styled.div`
  display : grid;
  grid-template-columns:repeat(3,1fr);
  grid-template-rows:0px;
`
const FilterWrapperByTwo = styled.div`
  display : grid;
  grid-template-columns:repeat(2,230px);
  grid-template-rows:35px;
  margin-bottom: 1px;
`
const WrapperByBrand = styled.div`
display:flex;
justify-content:flex-end;
  & > div > div{
    margin-top:0px !important;
  }
`
/////////STYLED/////////

const orderStatusFliter = {
  cancelled: {
    checked: false,
    value: [13, 11, 2],
    name: 'cancelled',
    label: 'ofu_filter_statuts_Cancelled',
    color: '#951B81',
    hidden: true
  },
  pending: {
    checked: true,
    value: [4, 5, 7, 8],
    name: 'pending',
    label: 'ofu_filter_statuts_Pending',
    color: '#FF2A2A'
  },
  batPending: {
    checked: true,
    value: [25],
    name: 'batPending',
    label: 'ofu_filter_statuts_BATPending',
    color: '#FF2A2A'
  },
  waitingForPayment: {
    checked: true,
    value: [10, 16],
    name: 'waitingForPayment',
    label: 'ofu_filter_statuts_WaitingForPayment',
    color: '#FFC94E'
  },
  inProduction: {
    checked: true,
    value: [9, 12, 14, 15, 17],
    name: 'inProduction',
    label: 'ofu_filter_statuts_InProd',
    color: '#00F359'
  },
  shipped: {
    checked: true,
    value: [18, 19, 22, 20],
    name: 'shipped',
    label: 'ofu_filter_statuts_Shipped',
    color: '#0FB6FE'
  }
}

class FollowUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      InputSearchBy: {
        key: '',
        value: ''
      },
      IdBrand: 0,
      StartCreationDate: moment().subtract(6, 'week').startOf('day').toDate(),
      EndCreationDate: moment().toDate(),
      StartValidationDate: null,
      EndValidationDate: null,
      StartBrandDeadlineDate: null,
      EndBrandDeadlineDate: null,
      SeeCanceledOrders: false,
      status: [9, 12, 14, 15, 17, 4, 5, 6, 7, 8, 25, 10, 16, 18, 19, 22, 20],
      exportDateError: false,
      filtersSettings: {},
      items: [],
      orders: [],
      forceRefresh: true,
      GarmentMakerId: 0,
      BrandSeason: '',
      SeeArchived: false,
      BrandDrop: '',
      FileOrderId: null,
      openModalReassign: false,
      openReassignConfirm: false,
      reassign: {},
      reassignLoaded: false,
    }

    this.defaultState = this.state
    this.handleChange = this.handleChange.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.filterByStatus = this.filterByStatus.bind(this)
    this.difference = this.difference.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    // already have orders from api, need to reinitilize state with those orders
    if (!isEmpty(this.props.orders) && !this.state.orders.length) this.handleFilter()
  }

  componentDidMount() {
    this.queryData()
  }

  difference = (object, base) => {
    function changes(object, base) {
      return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value
        }
      })
    }
    return changes(object, base)
  }

  queryData = (defaultState) => {
    const { dashboard, router } = this.props
    try {
      if (!_.isUndefined(router.location.query.filter) && !_.isEmpty(dashboard)) {
        if (!_.isNull(dashboard.pendingOrdersBrRedirectData) && router.location.query.filter === 'pending') {
          this.props.apiGetOrdersAndOrderArticles({
            Status: this.state.status,
            IdBrand: null,
            InputSearchBy: { key: '', value: '' },
            StartCreationDate: null,
            EndCreationDate: null,
            StartValidationDate: null,
            EndValidationDate: null,
            StartBrandDeadlineDate: null,
            EndBrandDeadlineDate: null,
            SeeCanceledOrders: false,
            GarmentMakerId: null,
            BrandSeason: '',
            BrandDrop: '',
            SeeArchived: false,
            FileOrderId: null
          })
        } else if (!_.isNull(dashboard.pendingOrdersGmRedirectData) && router.location.query.filter === 'pending') {
          this.props.apiGetOrdersAndOrderArticles({
            Status: dashboard.pendingOrdersGmRedirectData.selectedStatusesId,
            IdBrand: null,
            InputSearchBy: { key: '', value: '' },

            StartCreationDate: null,
            EndCreationDate: null,
            StartValidationDate: null,
            EndValidationDate: null,
            StartBrandDeadlineDate: null,
            EndBrandDeadlineDate: null,
            SeeCanceledOrders: false,
            GarmentMakerId: null,
            BrandSeason: '',
            BrandDrop: '',
            SeeArchived: false,
            FileOrderId: null
          })
        } else if (
          !_.isNull(dashboard.waitingForPaymentOrdersGmRedirectData) &&
          router.location.query.filter === 'waitingForPayment'
        ) {
          this.props.apiGetOrdersAndOrderArticles({
            Status: dashboard.waitingForPaymentOrdersGmRedirectData.selectedStatusesId,
            IdBrand: null,
            InputSearchBy: { key: '', value: '' },

            StartCreationDate: null,
            EndCreationDate: null,
            StartValidationDate: null,
            EndValidationDate: null,
            StartBrandDeadlineDate: null,
            EndBrandDeadlineDate: null,
            SeeCanceledOrders: false,
            GarmentMakerId: null,
            BrandSeason: '',
            BrandDrop: '',
            SeeArchived: false,
            FileOrderId: null
          })
        } else if (
          !_.isNull(dashboard.waitingForPaymentOrdersLabRedirectData) &&
          router.location.query.filter === 'waitingForPayment'
        ) {
          this.props.apiGetOrdersAndOrderArticles({
            Status: dashboard.waitingForPaymentOrdersLabRedirectData.selectedStatusesId,
            IdBrand: null,
            InputSearchBy: { key: '', value: '' },

            StartCreationDate: null,
            EndCreationDate: null,
            StartValidationDate: null,
            EndValidationDate: null,
            StartBrandDeadlineDate: null,
            EndBrandDeadlineDate: null,
            SeeCanceledOrders: false,
            GarmentMakerId: null,
            BrandSeason: '',
            BrandDrop: '',
            SeeArchived: false,
            FileOrderId: null
          })
        } else {
          this.props.apiGetOrdersAndOrderArticles({
            Status: this.state.status,
            IdBrand: this.state.IdBrand <= 0 ? null : this.state.IdBrand,
            InputSearchBy: { key : this.state.InputSearchBy.key == 0 ? '' : this.state.InputSearchBy.key, value : this.state.InputSearchBy.value },
            StartCreationDate: this.state.StartCreationDate,
            EndCreationDate: this.state.EndCreationDate != this.defaultState.EndCreationDate ? 
            moment.parseZone(this.state.EndCreationDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.EndCreationDate,
            StartValidationDate: this.state.StartValidationDate,
            EndValidationDate: this.state.EndValidationDate != this.defaultState.EndCreationDate ? 
            moment.parseZone(this.state.EndValidationDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.EndValidationDate,
            StartBrandDeadlineDate: this.state.StartBrandDeadlineDate,
            EndBrandDeadlineDate: this.state.EndBrandDeadlineDate != this.defaultState.EndCreationDate ? 
            moment.parseZone(this.state.EndBrandDeadlineDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.EndBrandDeadlineDate,
            SeeCanceledOrders: this.state.status.find((e) => e === 13) ? true : false,
            GarmentMakerId: this.state.GarmentMakerId <= 0 ? null : this.state.GarmentMakerId,
            BrandSeason: this.state.BrandSeason,
            BrandDrop: this.state.BrandDrop,
            SeeArchived: this.state.SeeArchived,
            FileOrderId: this.state.FileOrderId
          })
        }
      } else {
        if (isEqual(defaultState, this.defaultState)) {
          this.props.apiGetOrdersAndOrderArticles({
            Status: this.defaultState.status,
            IdBrand: this.defaultState.IdBrand <= 0 ? null : this.defaultState.IdBrand,
            InputSearchBy: { key : this.defaultState.InputSearchBy.key == 0 ? '' : this.defaultState.InputSearchBy.key, value : this.defaultState.InputSearchBy.value },
            StartCreationDate: this.defaultState.StartCreationDate,
            EndCreationDate: this.defaultState.EndCreationDate,
            StartValidationDate: this.defaultState.StartValidationDate,
            EndValidationDate: this.defaultState.EndValidationDate,
            StartBrandDeadlineDate: this.defaultState.StartBrandDeadlineDate,
            EndBrandDeadlineDate: this.defaultState.EndBrandDeadlineDate,
            SeeCanceledOrders: this.defaultState.status.find((e) => e === 13) ? true : false,
            GarmentMakerId: this.defaultState.GarmentMakerId <= 0 ? null : this.defaultState.GarmentMakerId,
            BrandSeason: this.defaultState.BrandSeason,
            BrandDrop: this.defaultState.BrandDrop,
            SeeArchived: this.defaultState.SeeArchived,
            FileOrderId: this.defaultState.FileOrderId
          })
        } else {
          this.props.apiGetOrdersAndOrderArticles({
            Status: this.state.status,
            IdBrand: this.state.IdBrand <= 0 ? null : this.state.IdBrand,
            InputSearchBy: { key : this.state.InputSearchBy.key == 0 ? '' : this.state.InputSearchBy.key, value : this.state.InputSearchBy.value },
            StartCreationDate: this.state.StartCreationDate,
            EndCreationDate: this.state.EndCreationDate != this.defaultState.EndCreationDate ? 
            moment.parseZone(this.state.EndCreationDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.EndCreationDate,
            StartValidationDate: this.state.StartValidationDate,
            EndValidationDate: this.state.EndValidationDate != this.defaultState.EndCreationDate ? 
            moment.parseZone(this.state.EndValidationDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.EndValidationDate,
            StartBrandDeadlineDate: this.state.StartBrandDeadlineDate,
            EndBrandDeadlineDate: this.state.EndBrandDeadlineDate != this.defaultState.EndCreationDate ? 
            moment.parseZone(this.state.EndBrandDeadlineDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.EndBrandDeadlineDate,
            SeeCanceledOrders: this.state.status.find((e) => e === 13) ? true : false,
            GarmentMakerId: this.state.GarmentMakerId <= 0 ? null : this.state.GarmentMakerId,
            BrandSeason: this.state.BrandSeason,
            BrandDrop: this.state.BrandDrop,
            SeeArchived: this.state.SeeArchived,
            FileOrderId: this.state.FileOrderId
          })
        }
      }
    } catch (e) {
      console.log('debug', e)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { xlsx } = nextProps
    const diff = this.difference(nextProps, this.props)
    if (!_.isNull(nextProps.filtersSettings)) this.setState({ status: nextProps.filtersSettings.selectedStatus })
    if (!this.props.xlsx && xlsx) {
      setTimeout(() => {
        this.exportXlsx && this.exportXlsx.click()
      }, 1000)
    }
    // Remove this lock when we load for first time
    if (isNull(this.props.items) && !isEmpty(nextProps.items) > 0) {
      this.setState({ forceRefresh: false })
    }
    if (!isEmpty(diff)) {
      const o = diff[Object.keys(diff)[0]]
      if (!isUndefined(o) && !isUndefined(o.status)) {
        // When item is cancelled and status has changed to cancelled, back call
        if (o.status.idStatus === 13) this.queryData()
      }
    }
    // Update state, who are connected with reassignModal
    // We pass also action in props for this compoenent
    // Open / close the modal, when we Apply, the first modal is close and the confirmationModal is open
    if (this.props.openReassign != nextProps.openReassign) {
      this.setState({ openModalReassign: nextProps.openModalReassign })
    }
    if (this.props.openReassignConfirm != nextProps.openReassignConfirm) {
      if (!nextProps.openModalReassign) {
        this.props.apiShowModalReassignConfirm({ openReassignConfirm: false })
      }
      this.setState({ openReassignConfirm: nextProps.openReassignConfirm })
    }
    // reassign get all gm information and a complet list of Gm 
    if (!_.isNull(nextProps.reassign)) {
      this.setState({ reassign: nextProps.reassign })
    }
    // Like async comportement
    if (this.props.reassignLoaded != nextProps.reassignLoaded) {
      this.setState({ reassignLoaded: nextProps.reassignLoaded })
    }
    // Reset when we switch between Row, and when the orderGm is updated
    if (this.props.orderId != nextProps.orderId || this.props.reassignUpdated != nextProps.reassignUpdated) {
      this.setState({ orderId: nextProps.orderId })
      this.setState({ newDeliveryZone: undefined })
      this.setState({ newGmAlias: undefined })
      this.setState({ newGmName: undefined })
      this.setState({ gmSelected: '' })
      if (this.props.reassignUpdated != nextProps.reassignUpdated) {
        this.queryData()
        this.props.apiResetReassignUpdated()
      }
    }
  }

 	///////////////////////////////// HANDLE ///////////////////////////////////////

  filterByStatus = (itemStatus) => {
    let newStatus = {...itemStatus, checked: !itemStatus.checked}
    const status = {...this.state.status, [newStatus.name]: newStatus}
    this.setState({status} /*,this.handleFilterOrders*/)
  }

  onFilterChange = filter => () => { this.setState(state => ({ [filter]: !state[filter] }))}

  onStatusChangeHandler = (filter) => () => {
    let oldState = cloneDeep(this.state.status)
    const elemIdx = !isEmpty(this.state.status) && this.state['status'].includes(filter)
        ? this.state['status'].findIndex((e) => e === filter)
        : -1
    let arr = []
    map(orderStatusFliter, (elem) => {
      const f = find(elem.value, (o) => o === filter) ? true : false
      if (f) arr.push(elem.value)
    })
    arr = flattenDeep(arr)
    // Here we doesn't push/splice just the id but the entire list corresponding to status
    //oldState.splice( elemIdx, 1 )
    elemIdx !== -1 ? map(arr, (o) => { remove(oldState, (e) => e === o) }) : oldState.push(arr)
    oldState = flattenDeep(oldState)
    this.setState({ status: oldState } /*,this.handleFilterOrders*/)
  }

  handleChange(e, field) {
    if (field === 'BrandSeason') {
      let currentBrandSeason = e.target.value
      this.setState({ BrandSeason: currentBrandSeason }, () => {
        if (this.debounceFn) {
          this.debounceFn.cancel()
        }
        //this.debounceFn = debounce(this.handleFilterOrders, 500)
        this.debounceFn()
      })
    } else if (field === 'BrandDrop') {
      let currentBrandDrop = e.target.value
      this.setState({ BrandDrop: currentBrandDrop }, () => {
        if (this.debounceFn) {
          this.debounceFn.cancel()
        }
        //this.debounceFn = debounce(this.handleFilterOrders, 500)
        this.debounceFn()
      })
    } else {
      let currentSearch = cloneDeep(this.state.InputSearchBy)
      currentSearch.value = e.target.value
      this.setState({ InputSearchBy: currentSearch }, () => {
        if (this.debounceFn) {
          this.debounceFn.cancel()
        }
        //this.debounceFn = debounce(this.handleFilterOrders, 500)
        this.debounceFn()
      })
    }
  }

  handleFilter(e, index, value, name) {
    if (name === 'searchTermDomain') {
      let currentSearch = cloneDeep(this.state.InputSearchBy)
      currentSearch.key = value.length > 0 ? value : 0
      this.setState({ InputSearchBy: currentSearch } /*, this.handleFilterOrders*/)
    } else {
      this.setState({ [name]: value } /*, this.handleFilterOrders*/)
    }
  }

  handleChangeDate = (e, date, name) => {
    this.setState({ [name]: moment(date).toDate() })
  }

  handleReset = () => {
    let defaultState = {...this.defaultState}
    defaultState.forceRefresh = false
    this.setState(defaultState /*, this.handleFilterOrders*/)
    // Also adding back call
    this.queryData({...this.defaultState})
  }

  handleExportExcel = () => {
    // Send filters instead
    this.props.apiOFUExportXl({
      Status: this.state.status,
      IdBrand: this.state.IdBrand <= 0 ? null : this.state.IdBrand,
      InputSearchBy: {
        key: this.state.InputSearchBy.key == 0 ? '' : this.state.InputSearchBy.key, //searchTermDomain,
        value: this.state.InputSearchBy.value //searchTerm
      },
      StartCreationDate: this.state.StartCreationDate,
      EndCreationDate: this.state.EndCreationDate,
      StartValidationDate: this.state.StartValidationDate,
      EndValidationDate: this.state.EndValidationDate,
      StartBrandDeadlineDate: this.state.StartBrandDeadlineDate,
      EndBrandDeadlineDate: this.state.EndBrandDeadlineDate,
      SeeCanceledOrders: this.state.status.find((e) => e === 13) ? true : false,
      GarmentMakerId: this.state.GarmentMakerId <= 0 ? null : this.state.GarmentMakerId,
      BrandSeason: this.state.BrandSeason,
      BrandDrop: this.state.BrandDrop,
      SeeArchived: this.state.SeeArchived,
      FileOrderId: this.state.FileOrderId
    })
  }

  handleCheckStatus = (arrayStatus, status) => {
    return !!(arrayStatus.find((e) => e === status));
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, role, items, filtersSettings, xlsx, isLoadingGlobal, isLoadingTable } = this.props
    const { forceRefresh } = this.state
    if(!items || !trl || !filtersSettings) return <PageLoad text={ trl.common__loading } size={ 80 } thickness={ 9 }/>
    return ( items && 
      <div>
        <PageHeader title={trl.orders_pre__title} subtitle={trl.orders_pre__subtitle} />
        <SC.Sky grey>
          <SC.PageGutter>
              <div>
                <FilterAndTableWrapper>
                  <FilterContainer>
                    <TableWrapper text={trl.common__search}>
                      <BodyTable>
                        <CheckboxWrapperRow>
                          {filtersSettings &&
                            map(filtersSettings.availableStatus, (elem, idx) => (
                              <Row key={idx}>
                                <Check
                                  checked={ !isEmpty(this.state.status) && this.handleCheckStatus(this.state.status, elem.idStatus) }
                                  onCheck={this.onStatusChangeHandler(elem.idStatus)}
                                />
                                <Label isTitle bold color={elem.color}>
                                  {trl[elem.traductionKey]}
                                </Label>
                              </Row>
                            ))}
                        </CheckboxWrapperRow>
                        <LeftFiltersWrapper>
                          {/* SEARCH */}
                          <FilterWrapperByTwo>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Label>{trl.common__search} :</Label>
                              <TextField
                                type="text"
                                style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                                name="searchTerm"
                                value={this.state.InputSearchBy.value}
                                onChange={this.handleChange}
                                onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                              />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <Label style={{ paddingRight: 5 }}>{trl.common__by} : </Label>
                              <SelectField
                                style={{
                                  fontSize: theme.font.fontSize,
                                  maxWidth: '195px',
                                  height: '35px'
                                }}
                                iconStyle={{
                                  margin: '0',
                                  padding: '0'
                                }}
                                floatingLabelStyle={{
                                  display: 'none'
                                }}
                                labelStyle={{
                                  borderRadius: 0,
                                  padding: '3px 30px 0 5px',
                                  border: '1px solid #ced4da',
                                  width: '100%',
                                  height: '30px',
                                  position: 'relative',
                                  top: '4px',
                                  lineHeight: 'inherit'
                                }}
                                underlineStyle={{
                                  display: 'none'
                                }}
                                autoWidth={true}
                                value={this.state.InputSearchBy.key == '' ? 0 : this.state.InputSearchBy.key /*searchTermDomain*/}
                                onChange={(e, index, value) => this.handleFilter(e, index, value, 'searchTermDomain')}
                              >
                                {filtersSettings &&
                                  filtersSettings.availableByParameters.map((item) => {
                                    const e = find(searchOption, { key: item.key })
                                    if (!isUndefined(e) && e.notShowforRoles.includes(role)) return null
                                    return (
                                      <MenuItem
                                        key={item.key}
                                        style={{ fontSize: theme.font.fontSize }}
                                        value={item.key}
                                        primaryText={item.value}
                                      />
                                    )
                                  })}
                              </SelectField>
                            </div>
                          </FilterWrapperByTwo>
                          {/* SELECT BY BRAND */}
                          <FilterWrapperByTwo>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <Label >{trl.orders_pre__filter_brand} :</Label>
                            </div>
                            <WrapperByBrand  >
                              <SelectField
                                style={{
                                  fontSize: theme.font.fontSize,
                                  maxWidth: '195px',
                                  height: '35px'
                                }}
                                iconStyle={{
                                  margin: '0',
                                  padding: '10px',
                                }}
                                floatingLabelStyle={{
                                  display: 'none'
                                }}
                                labelStyle={{
                                  borderRadius: 0,
                                  padding: '3px 30px 0 5px',
                                  border: '1px solid #ced4da',
                                  width: '100%',
                                  height: '30px',
                                  position: 'relative',
                                  top: '4px',
                                  lineHeight: 'inherit'
                                }}
                                underlineStyle={{
                                  display: 'none'
                                }}
                                autoWidth={true}
                                className='buttonBrand'
                                floatingLabelText={trl.orders_pre__filter_brand}
                                value={this.state.IdBrand}
                                onChange={(e, index, value) => this.handleFilter(e, index, value, 'IdBrand')}
                              >
                                {filtersSettings &&
                                  filtersSettings.availableBrands &&
                                  filtersSettings.availableBrands.map((brand) => (
                                    <MenuItem
                                      key={brand.key}
                                      value={brand.key}
                                      primaryText={trl[brand.value] || brand.value}
                                      style={{ fontSize: theme.font.fontSize }}
                                    />
                                  ))}
                              </SelectField>
                            </WrapperByBrand>
                          </FilterWrapperByTwo>
                          {/* SELECT GM */}
                          <FilterWrapperByTwo>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <Label >{trl.orders_pre__filter_garment_maker} :</Label>
                            </div>
                            <WrapperByBrand>
                              <SelectField
                                style={{
                                  fontSize: theme.font.fontSize,
                                  maxWidth: '195px',
                                  height: '35px'
                                }}
                                iconStyle={{
                                  margin: '0',
                                  padding: '0',
                                  paddingBottom: '11px'
                                }}
                                floatingLabelStyle={{
                                  display: 'none'
                                }}
                                labelStyle={{
                                  borderRadius: 0,
                                  padding: '3px 30px 0 5px',
                                  border: '1px solid #ced4da',
                                  width: '100%',
                                  height: '30px',
                                  position: 'relative',
                                  top: '4px',
                                  lineHeight: 'inherit'
                                }}
                                underlineStyle={{
                                  display: 'none'
                                }}
                                autoWidth={true}
                                floatingLabelText={trl.orders_pre__filter_garment_maker}
                                value={this.state.GarmentMakerId}
                                onChange={(e, index, value) => this.handleFilter(e, index, value, 'GarmentMakerId')}
                              >
                                {filtersSettings &&
                                  filtersSettings.availableGarmentMaker &&
                                  filtersSettings.availableGarmentMaker.map((brand) => (
                                    <MenuItem
                                      key={brand.key}
                                      value={brand.key}
                                      primaryText={trl[brand.value] || brand.value}
                                      style={{ fontSize: theme.font.fontSize }}
                                    />
                                  ))}
                              </SelectField>
                            </WrapperByBrand>
                          </FilterWrapperByTwo>
                          {/* SOURCE FILE */}
                          <FilterWrapperByTwo>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <Label>{trl.common_sourceFile_filter__file} :</Label>
                            </div>
                            <WrapperByBrand>
                              <SelectField
                                style={{
                                  fontSize: theme.font.fontSize,
                                  maxWidth: '195px',
                                  height: '35px'
                                }}
                                iconStyle={{
                                  margin: '0',
                                  padding: '0',
                                  paddingBottom: '11px'
                                }}
                                floatingLabelStyle={{
                                  display: 'none'
                                }}
                                labelStyle={{
                                  borderRadius: 0,
                                  padding: '3px 30px 0 5px',
                                  border: '1px solid #ced4da',
                                  width: '100%',
                                  height: '30px',
                                  position: 'relative',
                                  top: '4px',
                                  lineHeight: 'inherit'
                                }}
                                underlineStyle={{
                                  display: 'none'
                                }}
                                autoWidth={true}
                                value={this.state.FileOrderId}
                                floatingLabelText={trl.common__all}
                                onChange={(e, index, value) => this.handleFilter(e, index, value, 'FileOrderId')}
                              >
                                <MenuItem
                                  style={{ fontSize: theme.font.fontSize }}
                                  value={null}
                                  primaryText={trl.common__all}
                                />
                                {
                                  filtersSettings &&
                                  filtersSettings.availableFileOrder.map(fileOrder =>
                                    <MenuItem
                                      key={fileOrder.key}
                                      value={fileOrder.key}
                                      primaryText={trl[fileOrder.value] || fileOrder.value}
                                      style={{ fontSize: theme.font.fontSize }}
                                    />
                                  )
                                }
                              </SelectField>
                            </WrapperByBrand>
                          </FilterWrapperByTwo>
                          {/* BRAND SEASON */}
                          <FilterWrapperByTwo>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <Label>{trl.orders_pre__filter_brand_season} :</Label>
                            </div>
                            <div>
                              <FormWrapped
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.keyCode === 13) {
                                    e.preventDefault()
                                    this.queryData()
                                  }
                                }}
                                style={{ fontSize: theme.font.fontSize, height: 'auto', marginLeft: '30px' }}
                                onKeyPress={(evt) => this.onKeyPressHandler(evt)}

                              >
                                <TextField
                                  type="text"
                                  style={{ paddingLeft: 5, width: 193, fontSize: theme.font.fontSize, height: 35 }}
                                  name="BrandSeason"
                                  value={this.state.BrandSeason}
                                  onChange={(e) => this.handleChange(e, 'BrandSeason')}
                                  onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                                />
                              </FormWrapped>
                            </div>
                          </FilterWrapperByTwo>
                          {/* DROP SEASON */}
                          <FilterWrapperByTwo>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <Label>{trl.orders_pre__filter_brand_drop} :</Label>
                            </div>
                            <div>
                              <FormWrapped
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter' || e.keyCode === 13) {
                                    e.preventDefault()
                                    this.queryData()
                                  }
                                }}
                                style={{ fontSize: theme.font.fontSize, height: 'auto', marginLeft: '30px' }}
                              >
                                <TextField
                                  type="text"
                                  style={{ paddingLeft: 5, width: 193, fontSize: theme.font.fontSize, height: 35 }}
                                  name="BrandDrop"
                                  value={this.state.BrandDrop}
                                  onChange={(e) => this.handleChange(e, 'BrandDrop')}
                                />
                              </FormWrapped>

                            </div>
                          </FilterWrapperByTwo>
                        </LeftFiltersWrapper>
                        <RightFiltersWrapper>
                          {/* CREATION DATE */}
                          <FilterWrapperByThree>
                            <div style={{ display: 'flex', alignItems: 'center', height: 'inherit' }}>

                              <Label upper={true} style={{ marginTop: '45px' }} >
                                {trl.common__filter_by_reception_date} :
                              </Label>
                            </div>

                            <DatePicker
                              autoOk
                              textFieldStyle={{
                                maxWidth: 100,
                                fontSize: theme.font.fontSize,
                                height: '30px',
                                margin: '5px 0'
                              }}
                              underlineStyle={{ display: 'none' }}
                              hintStyle={{
                                position: 'absolute',
                                left: '50%',
                                top: '28%',
                                transform: 'translate(-50%, -50%)'
                              }}
                              inputStyle={{
                                top: 2,
                                height: '30px',
                                border: '1px solid #ced4da',
                                borderRadius: 0,
                                fontSize: theme.font.fontSize,
                                padding: '0 5px',
                                textAlign: 'center'
                              }}
                              hintText={trl.orders_pre__filter_date_exp_from}
                              container="inline"
                              onChange={(e, date) => this.handleChangeDate(e, date, 'StartCreationDate')}
                              value={this.state.StartCreationDate}
                              style={{ marginRight: 20 }}
                              formatDate={dateFormatConverter}
                              maxDate={this.state.EndCreationDate}
                            />
                            <DatePicker
                              autoOk
                              textFieldStyle={{
                                maxWidth: 100,
                                fontSize: theme.font.fontSize,
                                height: '30px',
                                margin: '5px 0'
                              }}
                              underlineStyle={{ display: 'none' }}
                              hintStyle={{
                                position: 'absolute',
                                left: '50%',
                                top: '28%',
                                transform: 'translate(-50%, -50%)'
                              }}
                              inputStyle={{
                                top: 2,
                                height: '30px',
                                border: '1px solid #ced4da',
                                borderRadius: 0,
                                fontSize: theme.font.fontSize,
                                padding: '0 5px',
                                textAlign: 'center'
                              }}
                              hintText={trl.orders_pre__filter_date_exp_to}
                              container="inline"
                              onChange={(e, date) => this.handleChangeDate(e, date, 'EndCreationDate')}
                              style={{}}
                              value={this.state.EndCreationDate}
                              minDate={this.state.StartCreationDate}
                              formatDate={dateFormatConverter}
                            />
                          </FilterWrapperByThree>
                          {/* VALIDATION DATE */}
                          <FilterWrapperByThree>
                            <div style={{ display: 'flex', alignItems: 'center', height: 'inherit' }}>
                              <Label upper={true} style={{ marginTop: '45px' }} >
                                {trl.common__filter_by_validation_date} :
                              </Label>
                            </div>
                            <div>
                              <DatePicker
                                autoOk
                                textFieldStyle={{
                                  maxWidth: 100,
                                  fontSize: theme.font.fontSize,
                                  height: '30px',
                                  margin: '5px 0'
                                }}
                                underlineStyle={{ display: 'none' }}
                                hintStyle={{
                                  position: 'absolute',
                                  left: '50%',
                                  top: '28%',
                                  transform: 'translate(-50%, -50%)'
                                }}
                                inputStyle={{
                                  top: 2,
                                  height: '30px',
                                  border: '1px solid #ced4da',
                                  borderRadius: 0,
                                  fontSize: theme.font.fontSize,
                                  padding: '0 5px',
                                  textAlign: 'center'
                                }}
                                hintText={trl.orders_pre__filter_date_exp_from}
                                container="inline"
                                onChange={(e, date) => this.handleChangeDate(e, date, 'StartValidationDate')}
                                value={this.state.StartValidationDate}
                                formatDate={dateFormatConverter}
                                maxDate={this.state.EndValidationDate}
                              />
                            </div>
                            <div>
                              <DatePicker
                                autoOk
                                textFieldStyle={{
                                  maxWidth: 100,
                                  fontSize: theme.font.fontSize,
                                  height: '30px',
                                  margin: '5px 0'
                                }}
                                underlineStyle={{ display: 'none' }}
                                hintStyle={{
                                  position: 'absolute',
                                  left: '50%',
                                  top: '28%',
                                  transform: 'translate(-50%, -50%)'
                                }}
                                inputStyle={{
                                  top: 2,
                                  height: '30px',
                                  border: '1px solid #ced4da',
                                  borderRadius: 0,
                                  fontSize: theme.font.fontSize,
                                  padding: '0 5px',
                                  textAlign: 'center'
                                }}
                                hintText={trl.orders_pre__filter_date_exp_to}
                                container="inline"
                                onChange={(e, date) => this.handleChangeDate(e, date, 'EndValidationDate')}
                                value={this.state.EndValidationDate}
                                minDate={this.state.StartValidationDate}
                                style={{}}
                                formatDate={dateFormatConverter}
                              />
                            </div>
                          </FilterWrapperByThree>
                          {/* BRAND DEADLINE DATE */}
                          <FilterWrapperByThree>
                            <div style={{ display: 'flex', alignItems: 'center', height: 'inherit' }}>
                              <Label upper={true} style={{ marginTop: '45px' }}>
                                {trl.common__filter_by_warehouse_date} :
                              </Label>
                            </div>
                            <div>
                              <DatePicker
                                autoOk
                                textFieldStyle={{
                                  maxWidth: 100,
                                  fontSize: theme.font.fontSize,
                                  height: '30px',
                                  margin: '5px 0'
                                }}
                                underlineStyle={{ display: 'none' }}
                                hintStyle={{
                                  position: 'absolute',
                                  left: '50%',
                                  top: '28%',
                                  transform: 'translate(-50%, -50%)'
                                }}
                                inputStyle={{
                                  top: 2,
                                  height: '30px',
                                  border: '1px solid #ced4da',
                                  borderRadius: 0,
                                  fontSize: theme.font.fontSize,
                                  padding: '0 5px',
                                  textAlign: 'center'
                                }}
                                hintText={trl.orders_pre__filter_date_exp_from}
                                container="inline"
                                onChange={(e, date) => this.handleChangeDate(e, date, 'StartBrandDeadlineDate')}
                                value={this.state.StartBrandDeadlineDate}
                                style={{}}
                                formatDate={dateFormatConverter}
                                maxDate={this.state.EndBrandDeadlineDate}
                              />
                            </div>
                            <div>
                              <DatePicker
                                autoOk
                                textFieldStyle={{
                                  maxWidth: 100,
                                  fontSize: theme.font.fontSize,
                                  height: '30px',
                                  margin: '5px 0'
                                }}
                                underlineStyle={{ display: 'none' }}
                                hintStyle={{
                                  position: 'absolute',
                                  left: '50%',
                                  top: '28%',
                                  transform: 'translate(-50%, -50%)'
                                }}
                                inputStyle={{
                                  top: 2,
                                  height: '30px',
                                  border: '1px solid #ced4da',
                                  borderRadius: 0,
                                  fontSize: theme.font.fontSize,
                                  padding: '0 5px',
                                  textAlign: 'center'
                                }}
                                hintText={trl.orders_pre__filter_date_exp_to}
                                container="inline"
                                onChange={(e, date) => this.handleChangeDate(e, date, 'EndBrandDeadlineDate')}
                                value={this.state.EndBrandDeadlineDate}
                                minDate={this.state.StartBrandDeadlineDate}
                                style={{}}
                                formatDate={dateFormatConverter}
                              />
                            </div>
                          </FilterWrapperByThree>
                          {/* CHECKBOX */}
                          <FilterWrapperByTwo>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
                              <div style={{ justifySelf: 'start' }}>
                                {/* hide/show orders with idStatus= 2 or 11 */}
                                <Checkbox
                                  labelStyle={{ width: 'initial' }}
                                  label={trl.common__show_cancelled_orders || ' '}
                                  checked={!isEmpty(this.state.status) ? this.state.status.find((e) => e === 13) : false}
                                  onCheck={this.onStatusChangeHandler(13)}
                                />
                              </div>
                              <div style={{ justifySelf: 'start' }}>
                                <Checkbox
                                  labelStyle={{ width: 'initial' }}
                                  label={trl.common__filter_displayArchivedOrders}
                                  checked={this.state.SeeArchived}
                                  onCheck={this.onFilterChange('SeeArchived')}
                                />
                              </div>
                              <div style={{ justifySelf: 'start', marginLeft: '5px', width: '500px', color: 'red', marginTop: 5 }}>
                                {trl.stofu_tableLegend_archivingRule}
                                <span style={{ width: '10px', height: '10px', borderRadius: '66%', backgroundColor: '#2e74b2', marginLeft: '2px', marginBottom: '-1px', display: 'inline-block' }}></span>
                              </div>
                            </div>
                          </FilterWrapperByTwo>
                        </RightFiltersWrapper>
                      </BodyTable>
                      <FooterContainer>
                        <FooterLeft></FooterLeft>
                        <FooterMiddle>
                          <ButtonWrapper>
                            <div>
                              <SC.ButtonPA_M onClick={this.queryData}>{trl.common__filter_apply_btn}</SC.ButtonPA_M>
                            </div>
                            <div>
                              <SC.ButtonR_M onClick={this.handleReset}>{trl.common__filter_reset_btn || ' '}</SC.ButtonR_M>
                            </div>
                          </ButtonWrapper>
                        </FooterMiddle>
                        <FooterRight>
                          <ExportButtonWrapper>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
                              <SC.ButtonS onClick={this.handleExportExcel}>{trl.ofu_btn_ExportToExcel}</SC.ButtonS>
                              {xlsx && (
                                <a
                                  target="_blank"
                                  style={{ display: 'none' }}
                                  href={`/excel_export/${xlsx}`}
                                  ref={(exportXlsx) => (this.exportXlsx = exportXlsx)}
                                />
                              )}
                            </div>
                          </ExportButtonWrapper>
                        </FooterRight>
                      </FooterContainer>
                    </TableWrapper>
                  </FilterContainer>
                </FilterAndTableWrapper>
                <div>
                  {!isNull(items) && items.length ? (
                    <OrdersFollowUpTable data={items} className="OrdersTable font12" isLoadingTable={isLoadingGlobal}/>
                  ) : (
                    <p>{trl.common__no_order}</p>
                  )}
                </div>
              </div>
          </SC.PageGutter>
        </SC.Sky>

        {this.props.reassignLoaded && <ReassignModal data={{
          openReassign: this.props.openReassign,
          openReassignConfirm: this.props.openReassignConfirm,
          reassignLoaded: this.props.reassignLoaded,
          orderId: this.state.orderId,
          currentGmName: this.props.reassign.currentGmName,
          currentOwnerEmail: this.props.reassign.currentOwnerEmail,
          currentDeliveryZone: this.props.reassign.currentDeliveryZone,
          currentGmAlias: this.props.reassign.currentGmAlias,
          availableGmPartners: this.props.reassign.availableGmPartners,
        }}>
        </ReassignModal>}
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard.tableList,
  isLoadingGlobal: state.appAlerts.loading && state.appAlerts.loading.isLoading,
  role: state.auth.user_role,
  ...state.orders,
})

FollowUp.propTypes = {}

export default connect(mapStateToProps, { apiGetOrdersAndOrderArticles, apiOFUExportXl, apiShowModalReassign, apiShowModalReassignConfirm, apiPutReassignOrder, apiResetReassignUpdated })(FollowUp)
