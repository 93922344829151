////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import MenuItem from 'material-ui/MenuItem'
import theme from '../../styledComponents/theme'
import * as SC from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import { apiDeleteSku } from '../../../actions/visibility'
import ActionHelper from '../ColumnHelpers/ActionHelper'
/////////ASSETS/////////
import Edit from 'material-ui/svg-icons/image/edit'
import Trash from 'material-ui/svg-icons/action/delete'

class OrderSkuActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openEdit: false,
      openConfirmDeleteSku: false,
      isModalOpened: false
    }
    this.handelEdit = this.handelEdit.bind(this)
    this.handelDelete = this.handelDelete.bind(this)
    this.handelCloseDeleteModal = this.handelCloseDeleteModal.bind(this)
    this.handelDeleteSku = this.handelDeleteSku.bind(this)
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handelCloseDeleteModal() {
    this.setState({ openConfirmDeleteSku: false, isModalOpened: false })
  }

  handelDelete() {
    this.setState({ openConfirmDeleteSku: true, isModalOpened: true })
  }

  handelDeleteSku() {
    this.handelCloseDeleteModal()
    const { iD_Commande, iD_Commande_Article } = this.props
    this.props.apiDeleteSku(iD_Commande, iD_Commande_Article, this.props.row.iD_SKU)
  }

  handelEdit() {
    this.props.handelOpenModalSku(this.props.row)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { isDisabled, trl } = this.props
    const actionsDelete = [

      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={this.handelCloseDeleteModal}>
        {translate("myaddresses_modal_btn_Cancel")}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handelDeleteSku}>
        {trl.common__delete}
      </SC.ButtonPA_S>
    ]
    if (isDisabled) return <ActionHelper isPropsArray={true} />
    return (
      <div style={{ cursor: 'pointer' }}>
        <ActionHelper isModalOpened={this.state.isModalOpened} >
          <MenuItem
            style={{ fontSize: theme.font.fontSize }}
            primaryText={trl.orderSku_table_actions__edit}
            leftIcon={<Edit />}
            onClick={this.handelEdit}
          />
          <MenuItem
            style={{ fontSize: theme.font.fontSize }}
            primaryText={trl.common__delete}
            leftIcon={<Trash />}
            onClick={this.handelDelete}
          />
        </ActionHelper>
        <Dialog
          title={trl.orderSku_table_actions__confirmDeleteSku}
          actions={actionsDelete}
          modal={false}
          open={this.state.openConfirmDeleteSku}
        >
          <p>{trl.orderSku_action__goingToDelete}</p>
        </Dialog>
      </div>
    )
  }
}

OrderSkuActions.propTypes = {
  row: PropTypes.object.isRequired,
  toggleActivePosition: PropTypes.func.isRequired,
  activeRow: PropTypes.number.isRequired
}

function mapStateToProps({ languages }) {
  return {
    trl: languages.siteLanguage.keys,
  }
}
export default connect(
  mapStateToProps,
  { apiDeleteSku }
)(OrderSkuActions)
