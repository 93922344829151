////////LIBRARY/////////
import React from 'react'
///////COMPONENTS///////
import * as SC from '../../components/styledComponents'
import { translate } from '../../containers/HOC/Translation'

const NotFound = () => (
  <SC.PageGutter>
    <div style={{ margin: '0 auto', width: '400px' }}>
      <h1>{translate('common__404ErrorCode')}!</h1>
      <p>{`${translate('common__noExistingPage')}.`}</p>
    </div>
  </SC.PageGutter>
)

export default NotFound
