////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import * as c from '../../../constants'

class DateFormatterInline extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props;
    const date2000 = '2000-01-01T00:00:01.087';
    return (
      children && new Date(children) > new Date(date2000) ? 
      ( <span style={{ minWidth: 63, display: 'inline-block', whiteSpace:'nowrap' }}>
          {dateFormat(new Date(children), c.dateDMY)}
        </span>
      ) :  null
    )
  }
}

DateFormatterInline.propTypes = {
  children: PropTypes.string
}

DateFormatterInline.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default DateFormatterInline
