////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import Divider from 'material-ui/Divider'
import Dialog from 'material-ui/Dialog'
import MenuItem from 'material-ui/MenuItem'
import { apiUpdateOrderArticleStatusCancel, apiGetOrderArticle, apiShowModalReassign, apiGetReassignOrder, apiOrderSapSimulate } from '../../../actions/orders'
import { checkRoleHasRightsToFunction } from '../../../containers/misc/allowedRolesPerFunction'
import * as SC from '../../styledComponents'
import theme from '../../styledComponents/theme'
import * as c from '../../../constants'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import { hasOwnerShipIfFac } from '../../../utils/apiUtils'
/////////ASSETS/////////
import LocalShipping from 'material-ui/svg-icons/device/airplanemode-active'
import Edit from 'material-ui/svg-icons/image/edit'
import ZoomIn from 'material-ui/svg-icons/action/zoom-in'
import ViewDetail from 'material-ui/svg-icons/action/pageview'
import Trash from 'material-ui/svg-icons/action/delete'

class OrderDetailFollowUpActions extends Component {
  state = {
    open: false,
    hasRequestedItemDetails: false,
    isDisabledButton: false,
    isModalOpened: false
  }

  requestItemDetails = () => {
    const { row } = this.props // row is commande article
    const orderId = row.iD_Commande
    const artId = row.iD_Commande_Article
    const artFixe = row.article_Fixe ? 's/' : 'v/'
    this.setState({ hasRequestedItemDetails: true });
    // we comment this line in order to prevent double call already set in ArticleVariable.js 
    hashHistory.push(`${c.orderDetail}${orderId}${c.article}${artFixe}${artId}`)
  }

  handleOpen = () => {
    this.setState({ open: true, isModalOpened: true })
  }

  handleClose = () => {
    this.setState({ open: false, isModalOpened: false })
  }

  componentWillReceiveProps(nextProps) {
    // Only closing modal if status is changing (aka cancelled)
    if (nextProps.iD_Status_Commande !== this.props.iD_Status_Commande) window.closeDisplayActionsDialog && window.closeDisplayActionsDialog()
  }

  render() {
    const { row, myRole, apiUpdateOrderArticleStatusCancel, trl, orders } = this.props // row is commande article
    const orderId = row.iD_Commande
    const artId = row.iD_Commande_Article
    const artFixe = row.article_Fixe ? 's/' : 'v/'
    const commandeArticleStatus = row.status.idStatus
    const orderStatus = row.iD_Status_Commande
    if (this.state.hasRequestedItemDetails) {
      if (Array.isArray(row._isHydratedWith) && row._isHydratedWith.includes(c.API_GET_ORDER_ARTICLE_SUCCESS)) {
        hashHistory.push(`${c.orderDetail}${orderId}${c.article}${artFixe}${artId}`)
      }
    }
    const ownerShip = hasOwnerShipIfFac(orders[orderId])
    const actions = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={this.handleClose}>
        {trl.myaddresses_modal_btn_Cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S keyboardFocused={true} onClick={async () => {
      try {
        await apiUpdateOrderArticleStatusCancel(orderId, artId); // Attendre que l'appel soit terminé
        await this.props.apiOrderSapSimulate({ iD_Commande: orderId, iD_Status: commandeArticleStatus, articleAdded: true });
        this.handleClose();
      } catch (error) {
        // Gérer les erreurs ici, si nécessaire
        console.error("Erreur lors de l'appel des API:", error);
      }
    }}>
    {trl.ofu_table_actions__confirmCancellation}
  </SC.ButtonPA_S>


  ]
    return (
      <div>          
        <ActionHelper
          isPropsArray={true}
          isModalOpened={this.state.isModalOpened}>
          {// button Qualify
            checkRoleHasRightsToFunction(myRole, c.qualifyArticle) &&
            [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39].includes(commandeArticleStatus) && (
              <div>
                {/* Si Article Fixe */}
                {row.article_Fixe ? (
                  <MenuItem
                    style={{ fontSize: theme.font.fontSize }}
                    primaryText={trl.ofu_lvlItems_Action_ViewItemDetails}
                    leftIcon={orderStatus >= 14 || !ownerShip ? <ZoomIn /> : <Edit />}
                    onClick={() => hashHistory.push(`${c.orderDetail}${orderId}${c.articleFixe}${artId}`)}
                  />
                ) : (
                  <MenuItem
                    style={{ fontSize: theme.font.fontSize }}
                    primaryText={trl.orderDetail_Action_ItemDetails}
                    leftIcon={orderStatus >= 14 || !ownerShip ? <ZoomIn /> : <Edit />}
                    onClick={() => this.requestItemDetails()}
                  />
                )}
              </div>
            )}
          {commandeArticleStatus === 30 && ownerShip && (
            <div>
              <Divider style={{ height: '1.5px' }} />
              <MenuItem
                style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.ofu_table_actions__viewBat}
                leftIcon={<ViewDetail />}
                onClick={() =>
                  hashHistory.push(`${c.batView}${row.iD_Commande}${c.commandeArticle}${row.iD_Commande_Article}`)
                }
              />
            </div>
          )}
          {// button Cancel
            ((myRole == 'FAC' || myRole == 'FACA') && ownerShip || checkRoleHasRightsToFunction(myRole, c.cancelArticle))  &&
            (commandeArticleStatus !== 23 &&
              commandeArticleStatus !== 32 &&
              commandeArticleStatus !== 34 &&
              commandeArticleStatus !== 37 &&
              commandeArticleStatus !== 38) &&
            (orderStatus !== 2 &&
              orderStatus !== 11 &&
              orderStatus !== 13 &&
              orderStatus !== 19 &&
              orderStatus !== 20) &&
              this.props.row.canCancelAfterProduction && (
              <div>
                <Divider style={{ height: '1.5px' }} />
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  primaryText={trl.orderDetail_Action_CancelItem}
                  leftIcon={<Trash />}
                  onClick={this.handleOpen}
                />
              </div>
            )}
          {c.displayViewShipmentArticle.includes(commandeArticleStatus) &&
            checkRoleHasRightsToFunction(myRole, c.viewShipment) && (
              <div>
                <Divider style={{ height: '1.5px' }} />
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  primaryText={trl.ofu_lvlOrder_Action_ViewShipment}
                  leftIcon={<LocalShipping />}
                  onClick={() => hashHistory.push(`${c.orderDetail}${row.iD_Commande}${c.shipmentHistory}`)}
                />
              </div>
            )}

          {row.haveBatHistory && (
            <div>
              <Divider style={{ height: '1.5px' }} />
              <MenuItem
                style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.ofu_lvlItems_Action_BATHistory}
                leftIcon={<ViewDetail />}
                onClick={() => hashHistory.push(`${c.batHistory}${orderId}/${artId}`)}
              />
            </div>
          )}
        </ActionHelper>



        <Dialog
          title={trl.ofu_table_actions__confirmItemCancellation}
          actions={actions}
          modal={false}
          open={this.state.open}
        //onRequestClose={/*this.handleClose*/}
        >
          <p>
            {commandeArticleStatus === 33 || commandeArticleStatus === 35 || commandeArticleStatus === 36
              ? trl.ofu_action__confirmCancellationInProduction
              : commandeArticleStatus === 25 ||
                commandeArticleStatus === 28 ||
                commandeArticleStatus === 29 ||
                commandeArticleStatus === 31
                && trl.ofu_action__confirmCancellation
            }
          </p>
        </Dialog>
      </div>
    )
  }
}

OrderDetailFollowUpActions.propTypes = {
  row: PropTypes.object.isRequired,
  orders: PropTypes.object,
  myRole: PropTypes.string.isRequired
}

const mapStateToProps = ({ auth, languages, orders }) => ({
  myRole: auth.user_role,
  trl: languages.siteLanguage.keys,
  orders
})

export default connect(
  mapStateToProps,
  { apiUpdateOrderArticleStatusCancel, apiGetOrderArticle, apiShowModalReassign, apiGetReassignOrder, apiOrderSapSimulate }
)(OrderDetailFollowUpActions)
