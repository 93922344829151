////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/////////STYLED/////////
const ColorItem = styled.span`
  color: ${props => (props.status_Color ? props.status_Color : 'black')};
  padding: 7px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`
/////////STYLED/////////

class Color extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row } = this.props
    const trl = this.context.store.getState().languages.siteLanguage.keys
    return (
      <ColorItem status_Color={row.status ? row.status.color : row.status_Color}>
        {row.status ? trl[row.status.traductionKey] : trl[row.status_Traduction_Key]}
      </ColorItem>
    )
  }
}

Color.propTypes = {
  row: PropTypes.object.isRequired
}

Color.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default Color
