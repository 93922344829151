////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
import styled from 'styled-components'
///////COMPONENTS///////
import { TableContainer } from '../../styledComponents'
import Color from '../ColumnHelpers/Color'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'

/////////STYLED/////////
const TableWrapper = styled.div`
  margin-left: 10%;
`
/////////STYLED/////////

const columnsNestedTable = [
  { key: 'iD_SKU', header: 'Item ref', primaryKey: true, hidden: true },
  { key: 'status_Color', header: translate('ofu_table_nested_tableTitle__status'), Component: Color },
  { key: 'modele', modelKey: translate('ofu_table_nested_tableTitle__model'), header: 'MODEL_GB', sortable: true },
  { key: 'couleur', header: translate('ofu_table_nested_tableTitle__colorGb'), sortable: true },
  { key: 'taille', header: translate('ofu_table_nested_tableTitle__sizeGb'), sortable: true },
  { key: 'barcode', header: translate('ofu_table_nested_tableTitle__barCode'), sortable: true },
  { key: 'quantity', header: translate('ofu_table_nested_tableTitle__quantity'), sortable: true }
]

const configNestedTable = {
  showPageSize: false,
  showFilter: false,
  defaultPageSize: 100000
}

class OrderArticleNestedFollowUpTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <TableWrapper>
        <TableContainer>
          <Table {...this.props} columns={columnsNestedTable} className="orderArticleNestedFollowUpTable" NoDataComponent={NoDataComponent} />
        </TableContainer>
      </TableWrapper>
    )
  }
}

OrderArticleNestedFollowUpTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

export default sematable('nestedTable', OrderArticleNestedFollowUpTable, columnsNestedTable, configNestedTable)
