import theme from '../../styledComponents/theme'

const styles = {
  customWidth: {
    width: 200
  },
  menuItem: {
    color: theme.colors.black,
    fontSize: theme.p2,
    fill: theme.colors.white,
    backgroundColor: theme.colors.lightGrey,
    paddingRight: 10,
    paddingLeft: 0
  }
}

export default styles
