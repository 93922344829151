////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import * as c from '../../../constants'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem';
import theme from '../../styledComponents/theme'
import { translate } from '../../../containers/HOC/Translation'
/////////ASSETS/////////
import Edit from 'material-ui/svg-icons/image/edit'

const propTypes = { row: PropTypes.object.isRequired }

class UsersActions extends Component {

 	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const row = this.props.row
    return (
      <ActionHelper>
        <MenuItem
          style={{ fontSize: theme.font.fontSize }}
          primaryText={translate('admin_users_tableAction_editUser')}
          leftIcon={<Edit />}
          onClick={() => hashHistory.push(`${c.userEdit}${row.id}`)}
        />
      </ActionHelper>
    )
  }
}

UsersActions.propTypes = propTypes
export default UsersActions

