////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
/////////ASSETS/////////
import check from '../../../assets/check.png'
import cross from '../../../assets/cross.png'

class YesNoImg extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props
    return (
      <img src={children ? check : cross} alt='img' />
    )
  }
}

YesNoImg.propTypes = {
  children: PropTypes.bool
}

export default YesNoImg
