////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hashHistory } from 'react-router'
import _, { isEmpty, find, isUndefined } from 'lodash'
///////COMPONENTS///////
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'
import Dialog from 'material-ui/Dialog'
import { PageHeader } from '../../components/sharedComponents'
import Bat from '../../components/Bat/Bat'
import PageLoad from '../../components/PageLoad'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import * as c from '../../constants'
import { apiBATsToreat, apiBATApprouveReject } from '../../actions/bat'
import { translate } from '../HOC/Translation';

const styles = {
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

class BATView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTermDomain: 'All',
      searchTerm: '',
      openModalRefusal: false,
      openModalError: false,
      openModalBrandFileError: false,
      refusalReason: {
        refusalComment: null,
        idRefusalReason: null
      },
      hasCommon: false,
      hasCompo: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const { params: { idOrder } } = this.props
    this.props.apiBATsToreat(idOrder)
  }

  componentWillReceiveProps(nextProps) {
    const { params: { idOrder, idBat }, errors } = this.props
    const { batsList } = nextProps
    let commandeArticle = ""
    if (!_.isEmpty(batsList)) {
      const bat = _.isEmpty(batsList) && find(batsList, bat => parseInt(bat.idBat, 10) === parseInt(idBat, 10)) || batsList[0]
      commandeArticle = bat.orderItem
    }
    if (!_.isEmpty(commandeArticle.article_Champ_Compo)) this.setState({ hasCompo: true })
    if (!_.isEmpty(commandeArticle.article_Champ_CommonData)) this.setState({ hasCommon: true })
    if (isEmpty(errors) && !isEmpty(nextProps.errors)) this.setState({ openModalError: true })
    if (nextProps.tempRefrech) this.props.apiBATsToreat(idOrder)
    if ((nextProps.approuveRejectAction) && nextProps.batsList.length < 1) hashHistory.push(`${c.batPending}`)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  async handelApprouveReject(approved) {
    const { refusalReason } = this.state
    const bat = this.getBat()
    const inputData = {
      idBat: bat.idBat,
      idSalesOrderItem: bat.orderItem.iD_Commande_Article,
      approved,
      idRefusalReason: refusalReason.idRefusalReason,
      refusalComment: refusalReason.refusalComment
    }
    await this.props.apiBATApprouveReject(inputData)
    this.handelCloseOpenModal(false)
  }

  getBat() {
    const { params: { idBat, iD_Commande_Article }, batsList } = this.props
    if (iD_Commande_Article) {
      return (
        find(batsList, bat => parseInt(bat.orderItem.iD_Commande_Article, 10) === parseInt(iD_Commande_Article, 10)) ||
        batsList[0]
      )
    }
    return find(batsList, bat => parseInt(bat.idBat, 10) === parseInt(idBat, 10)) || batsList[0]
  }

  handelCloseOpenModal(openModalRefusal) {
    this.setState({
      openModalRefusal,
      refusalReason: {
        refusalComment: null,
        idRefusalReason: null
      }
    })
  }

  handelCancel() {
    hashHistory.push(`${c.batPending}`)
  }

  handleChange(name, value) {
    const { refusalReason } = this.state
    this.setState({ refusalReason: { ...refusalReason, [name]: value }})
  }

  brandFileErrorModalHandler = (reason) => {
    if (reason === 2) this.setState({ openModalBrandFileError: true });
  }

  modalBrandFileErrorCloseHandler = () => {
    this.setState({ openModalBrandFileError: false });
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, batsList, role, params: { idBat } } = this.props
    const { refusalReason: { refusalComment, idRefusalReason }, hasCommon, hasCompo } = this.state
    const isButtonsEnabel = ['EAdmin', 'EAC'].includes(role)
    if (isLoading || !batsList) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    const bat = find(batsList, bat => parseInt(bat.idBat, 10) === parseInt(idBat, 10)) || batsList[0]
    if (!bat) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    const commandeArticle = bat.orderItem
    const availableRefusalReasons = !isUndefined(bat) ? bat.availableRefusalReasons : []
    const actionsError = [
      <SC.ButtonPA_S onClick={() => this.handelCancel()}>
        {trl.common__ok}
      </SC.ButtonPA_S>]
    
    const actionsEdit = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={() => this.handelCloseOpenModal(false)}>
        {trl.common__cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={() => this.handelApprouveReject(false)}>
        {trl.common__ok}
      </SC.ButtonPA_S>]
    
    const confirmBrandFileError = [
      <SC.ButtonPA_S onClick={this.modalBrandFileErrorCloseHandler}>
        {trl.common__ok}
      </SC.ButtonPA_S>
    ]
 
    return (
      <div>
        <PageHeader
          title={`BAT - ITEM  ${bat.ctcItemRef} (${bat.brandItemRef})${!_.isNull(bat.brandPo) ? ' - ' + bat.brandPo : ''}`}
          subtitle={`${bat.orderItem.article_Name}`}
        ></PageHeader>
        <SC.PageGutter>
          <Bat data={commandeArticle} trl={trl} type="viewBat" dontShowCompo={!hasCompo} dontShowCommon={!hasCommon} />
          <div style={styles.buttonsWrapper}>
            <SC.ButtonS
              disabled={!isButtonsEnabel}
              onClick={() => this.handelCloseOpenModal(true)}
            >
              {trl.batView_btn__reject}
            </SC.ButtonS>
            <SC.ButtonR_L
              onClick={() => this.handelCancel()}
            >
              {trl.common__cancel}
            </SC.ButtonR_L>
            <SC.ButtonPA_L
              disabled={!isButtonsEnabel}
              onClick={() => this.handelApprouveReject(true)}
            >
              {trl.batView_btn__approve}
            </SC.ButtonPA_L>
          </div>
        </SC.PageGutter>
        <Dialog
          title={trl.batViewAndDecide_errorMessage_ApproveRejectFailed}
          actions={actionsError}
          modal={true}
          open={this.state.openModalError}
        />
        <Dialog
          title={`${trl.batView_message__ThxConfirming}!`}
          actions={actionsEdit}
          modal={true}
          open={this.state.openModalRefusal}
        >
          <div className="formElementGrid">
            <div className="formElementLabel" style={{ maxWidth: 210 }}>
              {trl.batView_label__refusalReason}
            </div>
            <SelectField
              style={{
                fontSize: theme.font.fontSize,
                lineHeight: 24,
                width: 256,
                height: 42,
                display: 'inline-block',
                position: 'relative',
                backgroundColor: 'transparent',
                fontFamily: 'Gotham, sans-serif',
                border: ' 1px solid rgb(206, 212, 218)',
                borderRadius: 0
              }}
              underlineStyle={{
                display: 'none'
              }}
              name="idRefusalReason"
              onChange={(e, index, value) => this.handleChange('idRefusalReason', value)}
              value={idRefusalReason}
            >
              {availableRefusalReasons.map(reason => (
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  key={reason.iD_BatRefusalReason}
                  value={reason.iD_BatRefusalReason}
                  primaryText={reason.label_BatRefusalReason}
                  onClick={() => this.brandFileErrorModalHandler(reason.iD_BatRefusalReason)}
                />
              ))}
            </SelectField>
          </div>
          <div style={{ padding: 10 }}> </div>
          <div className="formElementGrid">
            <div className="formElementLabel" style={{ maxWidth: 210 }}>
              {trl.batView_label__refusalComment}
            </div>
            <TextField
              multiLine={true}
              rows={2}
              rowsMax={4}
              onChange={(e, value) => this.handleChange('refusalComment', value)}
              inputStyle={{
                fontSize: 16,
                fontFamily: 'Gotham, sans-serif',
                border: ' 1px solid rgb(206, 212, 218)',
                borderRadius: 0
              }}
              underlineStyle={{
                display: 'none'
              }}
              name="refusalComment"
              value={refusalComment || ''}
            />
          </div>
        </Dialog>
        <Dialog
          contentStyle={{ width: "300px" }}
          actions={confirmBrandFileError}
          modal={true}
          open={this.state.openModalBrandFileError}
        >
          <p>{translate("batViewAndDecide_confirmMessage_brandFileError")}</p>
        </Dialog>
      </div>
    )
  }
}

BATView.contextTypes = {
  router: PropTypes.object.isRequired
}

BATView.propTypes = {
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiBATsToreat: PropTypes.func.isRequired
}

BATView.defaultProps = {
  batsList: [],
  isLoading: true
}

const mapStateToProps = ({ bat, auth, apiCallStateManager, languages }) => ({
  batsList: bat.batToTreat.list,
  approuveRejectAction: bat.batToTreat.approuveRejectAction,
  tempRefrech: bat.batToTreat.tempRefrech,
  isLoading: bat.batToTreat.isLoading,
  errors: bat.batToTreat.errors,
  role: auth.user_role,
  apiCallStateManager,
  trl: languages.siteLanguage.keys,
})

export default connect(
  mapStateToProps,
  {
    apiBATsToreat,
    apiBATApprouveReject
  }
)(BATView)


