////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import OrderDetailFollowUpActions from '../Actions/OrderDetailFollowUpActions'
import StaticVariable from '../ColumnHelpers/StaticVariable'
import Color from '../ColumnHelpers/Color.js'
import Round from '../ColumnHelpers/Round.js'
import ShowImageAction from '../ColumnHelpers/ShowImage'
import PriceWithCurrency from '../ColumnHelpers/PriceWithCurrency'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import NoDataComponent from '../NoDataComponent'

const columns = [
  { key: 'iD_Commande', header: translate('orderDetail_TableTitle_Image'), Component: ShowImageAction },
  { key: 'status_Traduction_Key', header: translate('orderDetail_TableTitle_Status'), Component: Color },
  { key: 'iD_Article_Externe_SAP', header: translate('orderDetail_TableTitle_CTCItem'), primaryKey: true },
  { key: 'iD_Article_Externe_Enseigne', header: translate('orderDetail_TableTitle_BrandItem') },
  { key: 'article_Name', header: translate('orderDetail_TableTitle_Designation') },
  { key: 'article_Fixe', header: translate('orderDetail_TableTitle_ItemType'), Component: StaticVariable },
  { key: 'qte_Totale', header: translate('orderDetail_TableTitle_Quantity') },
  { key: 'price', header: translate('orderDetail_TableTitle_Price'), Component: PriceWithCurrency },
  { key: 'total_Price', header: translate('orderDetail_TableTitle_TotalPrice'), Component: Round },
  { key: 'lastShipmentDate', header: translate('ofu_lvlOrder_tableTitle_lastShipmentDate'), Component: DateFormatter, sortable: true },
  { key: 'carrier', header: translate('orderDetail_TableTitle_carrier') },
  { key: 'lastAWB', header: translate('ofu_lvlOrder_tableTitle_lastAWB'), sortable: true },
  { key: 'actions', header: translate('orderDetail_TableTitle_Action'), Component: OrderDetailFollowUpActions}
]

const config = {
  showPageSize: false,
  showFilter: false,
  defaultPageSize: 100000
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}
class OrderDetailTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <TableContainer>
        <Table {...this.props} columns={columns} NoDataComponent={NoDataComponent}/>
      </TableContainer>
    )
  }
}

OrderDetailTable.propTypes = propTypes
export default sematable('orderDetailTable', OrderDetailTable, columns, config)
