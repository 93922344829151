////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isNull } from 'lodash'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import { H2, theme } from '../../styledComponents'
import './AddItemActions.css'
import * as SC from '../../styledComponents/'
import Dialog from 'material-ui/Dialog'
import { apiGetStockFollowUpId } from '../../../actions/stockFollowUp'
import StockFollowUpTable from '../Tables/StockFollowUpTable'
import PageLoadTable from '../../PageLoadTable'

/////////STYLED/////////
const TopRightStock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 15px;
`
const H2Styled = H2.extend`
  color: ${ theme.colors.lightGrey };
  background-color: ${ theme.colors.darkGrey };
  padding: 14px;
  font-size: 19px;
`
const LegendTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const LegendTableText = styled.span`
  font-weight: bold;
  color: red;
  margin: 0 0 5px 0;
  font-size: 10px !important;
`
const LastUpdateTime = styled.span`
  color: black;
  font-size: 12px;
`
/////////STYLED/////////

class ViewStockActions extends Component {
  constructor( props ) {
    super( props )
    this.state = {
      open: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  queryData = () => {
    this.props.apiGetStockFollowUpId( this.props.row.iD_Article )
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = () => {
    this.queryData()
    this.setState( { open: true } )
  }

  handleClose = () => {
    this.setState( { open: false } )
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl, stockFollowUp, lastUpdateTime, myRole, myCompanyType, isLoading } = this.props // row is article
    return (
      <div>
        <Dialog
          contentStyle={ {
            maxWidth: '100vw',
            width: '80vw',
            marginLeft: '10vw',
            padding: 0
          } }
          style={ { width: '80vw', fontSize: theme.p1.ftz, padding: 0 } }
          bodyStyle={ { padding: '10px' } }
          modal={ false }
          open={ this.state.open }
          onRequestClose={ this.handleClose }
        >
          { isLoading ?
              <PageLoadTable text={ trl.common__loading } size={ 80 } thickness={ 9 }/>
              :
                <div>
                  <H2Styled>{
                    stockFollowUp && `${ trl.stofu_title } - ${ row.iD_Article_Externe_SAP }`
                  }</H2Styled>
                  { lastUpdateTime && (
                    <LastUpdateTime>
                      { trl.common__date_updated } { dateFormat( new Date( lastUpdateTime ), 'dd/mm/yy - h:MM tt' ) }
                    </LastUpdateTime>
                  ) }
                  <StockFollowUpTable
                    data={ isNull( stockFollowUp ) || isLoading ? [] : stockFollowUp }
                    style={ { overflow: 'scroll' } }
                    isLoading={ isLoading }
                    trl={ trl }
                  />
                  <LegendTableWrapper style={{ fontSize: '10px !important' }}>
                    <LegendTableText style={{ fontSize: '10px !important' }}>
                      { trl.stofu_tableLegend_preparationTime }
                    </LegendTableText>
                    <LegendTableText style={{ fontSize: '10px !important' }}>
                      { trl.stofu_tableLegend_qtyConsigned }
                    </LegendTableText>
                    <LegendTableText style={{ fontSize: '10px !important' }}>
                      { trl.stofu_tableLegend_qtyAvailable }
                    </LegendTableText>
                  </LegendTableWrapper>
                </div>
          }
        </Dialog>
        {( myRole === 'FAC' || myRole === 'FACA' ) && myCompanyType === 'BR' && row.article_Fixe &&
          <TopRightStock>
            <SC.ButtonPA_S color={ 'white' } onClick={ this.handleOpen }>
              { trl.order_AddItem_Btn_viewStocks }
            </SC.ButtonPA_S>
          </TopRightStock>
        }
      </div>
    )
  }
}

ViewStockActions.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ( {
                            appAlerts,
                            me,
                            auth,
                            languages,
                            stockFollowUp
                          } ) => ( {
  myRole: auth.user_role,
  myCompanyType: me.company_type,
  trl: languages.siteLanguage.keys,
  stockFollowUp: stockFollowUp.stocksToReturn,
  lastUpdateTime: stockFollowUp.lastUpdateTime,
  isLoading: stockFollowUp.isLoading,
} )

export default connect(
  mapStateToProps,
  { apiGetStockFollowUpId }
)( ViewStockActions )
