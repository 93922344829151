///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET BATs
function apiGetBATsPendingRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_BATS_PENDING_REQUEST, endpointPath })
  }
}
function apiGetBATsPendingSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_BATS_PENDING_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_BATS_PENDING,
      payload: payload
    })
  }
}
function apiGetBATsPendingFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_BATS_PENDING_FAILURE, endpointPath, err })
  }
}
export function apiGetBATsPending(data) {
  return u.getValueFromStore(
    c.apiBATspending,
    h.headerPutWithToken(data),
    apiGetBATsPendingRequest,
    apiGetBATsPendingSuccess,
    apiGetBATsPendingFailure
  )
}

// GET BATs to treat
const apiBATsToTreatRequest = (endpointPath) => {
  return (dispatch) => {
    dispatch({ type: c.API_GET_PATS_TO_TREAT_REQUEST, endpointPath })
  }
}
const apiBATsToTreatSuccess = (_endpointPath, res) => {
  return (dispatch) => {
    dispatch({ type: c.API_GET_PATS_TO_TREAT_SUCCESS, payload: res })
  }
}
const apiBATsToTreatFailure = (endpointPath, err) => {
  return (dispatch) => {
    dispatch({ type: c.API_GET_PATS_TO_TREAT_FAILURE, endpointPath, err })
  }
}
export const apiBATsToreat = (idOrder) =>
  u.getValueFromStore(
    `${c.apiBATspendingOrder}${idOrder}`,
    h.headerGetWithToken(),
    apiBATsToTreatRequest,
    apiBATsToTreatSuccess,
    apiBATsToTreatFailure
  )

// BATs approve OR reject
const apiBATApprouveRejectRequest = (endpointPath) => ({
  type: c.API_BAT_APPROUVE_REJECT_REQUEST,
  endpointPath
})
const apiBATApprouveRejectSuccess = (endpointPath, res) => {
  return {
    type: c.API_BAT_APPROUVE_REJECT_SUCCESS,
    payload: res
  }
}
const apiBATApprouveRejectFailure = (endpointPath, err) => {
  return {
    type: c.API_BAT_APPROUVE_REJECT_FAILURE,
    payload: err,
    endpointPath
  }
}
export const apiBATApprouveReject = (batInputModel) => {
  return u.getValueFromStore(
    `${c.apiBAT}${batInputModel.idBat}${c.approveReject}`,
    h.headerPutWithTokenAndModel(batInputModel),
    apiBATApprouveRejectRequest,
    apiBATApprouveRejectSuccess,
    apiBATApprouveRejectFailure
  )
}

// BATs History
const apiBATHistoryRequest = (endpointPath) => {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_BAT_HISTORY_REQUEST, endpointPath })
  }
}
const apiBATHistorySuccess = (endpointPath, res) => {
  return (dispatch) => {
    dispatch(addAppHideLoading())

    dispatch({ type: c.API_BAT_HISTORY_SUCCESS, payload: Array.isArray(res) ? res : [res] })
  }
}
const apiBATHistoryFailure = (endpointPath, err) => {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_BAT_HISTORY_FAILURE, payload: err, endpointPath })
  }
}
export const apiBATHistory = ({ idOrder, idOrderItem }) => {
  const url = idOrderItem
    ? `${c.apiOrderDetail}${idOrder}${c.apiItem}${idOrderItem}${c.apiBatHistory}`
    : `${c.apiOrderDetail}${idOrder}${c.apiBatHistory}`
  return u.getValueFromStore(
    url,
    h.headerGetWithToken(),
    apiBATHistoryRequest,
    apiBATHistorySuccess,
    apiBATHistoryFailure
  )
}
