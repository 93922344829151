////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/////////STYLED/////////
const ColorItem = styled.span`
  color: ${props => (props.status_Color ? props.status_Color : 'black')};
  font-size: 12px;
  font-weight: bold;
`
/////////STYLED/////////

class ColorStatusFOFU extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row } = this.props
    const trl = this.context.store.getState().languages.siteLanguage.keys
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ColorItem key={row.file_ID} status_Color={row.status.color}>
            {trl[row.status.traductionKey]}
          </ColorItem>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          { this.props.row.archived ?
            <span style={{ width: '10px', 
                           height: '10px', 
                           borderRadius: '66%', 
                           backgroundColor: '#2e74b2', 
                           marginLeft: '2px', 
                           marginBottom: '-1px', 
                           display: 'inline-block' }}>
            </span>
            : null
          }
        </div>
      </div>
    )
  }
}

ColorStatusFOFU.propTypes = {
  row: PropTypes.object,
}

ColorStatusFOFU.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default ColorStatusFOFU
