////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _, { cloneDeep, isUndefined, findIndex, isEqual, orderBy } from 'lodash'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import PartnershipsManagementTable, { columns } from '../../components/Sematable/Tables/PartnershipsManagementTable'
import * as SC from '../../components/styledComponents'
import { apiPutPartnerShipsManagement, apiGetPartnerShipsManagement, apiExportPartnerShipsManagement } from '../../actions/partnershipsManagement'
import { theme } from '../../components/styledComponents'
import { getLocalStorage } from '../../utils/apiUtils'
import { TextField } from 'material-ui'

/////////STYLED/////////
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
`
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ? props.margin : '0'};
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
`
const ErrorWrapper = styled.h4`
  color: ${theme.colors.red};
  font-size: 15px;
`
/////////STYLED/////////

class PartnershipsManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRow: {},
      IdBrand: 0,
      psDetails: [],
      alias: [],
      searchValue: '',
      searchByValue: 'common__all',
      InputSearchBy: {
        key: '',
        value: ''
      }
    }
    this.defaultState = this.state
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.queryData()
  }

  componentWillReceiveProps(nextProps) {
    const { xlsx } = nextProps
    if (!this.props.xlsx && xlsx) {
      setTimeout(() => {
        this.exportXlsx && this.exportXlsx.click()
      }, 1000)
    }
    if (nextProps.partnershipsManagementDetail !== this.props.partnershipsManagementDetail) {
      this.setState({ psDetails: nextProps.partnershipsManagementDetail })
      // If props are changing, so do our input values list
      let alias = []
      nextProps.partnershipsManagementDetail.forEach(elem => { alias.push({ key: `Alias_${elem.id}`, value: elem.alias })})
      this.setState({ alias: alias })
    }
  }

  queryData = (defaultState) => {
    const { IdBrand, searchValue, searchByValue } = this.state
    if (isEqual(defaultState, this.defaultState)) 
      this.props.apiGetPartnerShipsManagement({
        IdBrand: this.defaultState.IdBrand === 0 ? null : this.defaultState.IdBrand, 
        InputSearchBy: { key: this.defaultState.searchByValue, value: this.defaultState.searchValue }
      })
    else this.props.apiGetPartnerShipsManagement({ IdBrand: IdBrand === 0 ? null : IdBrand, InputSearchBy: { key: searchByValue, value: searchValue}})
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleSelectBrandChange = (event, index, value) => {
    this.setState({ IdBrand: value })
  }

  handleSearchChange = e => {
    this.setState({ searchValue: e.target.value })
  }

  handleSearchByChange = (event, index, value) => {
    this.setState({ searchByValue: value })
  }

  handleReset = () => {
    const userRole = getLocalStorage('user_role')
    // in case of reset with a brand user we need to call with the goog id brand
    if (userRole === 'EAC' || userRole === 'EAdmin') {
      //searchValue: null,
      //searchByValue: 'common__all',
      this.setState({
        ...this.defaultState,
        IdBrand: 0,
      })
      // Also adding back call
      this.props.apiGetPartnerShipsManagement({ InputSearchBy: { searchValue: '', searchByValue: 0 }, IdBrand: null })
    } else {
      this.setState(this.defaultState)
      // Also adding back call
      this.queryData(this.defaultState)
    }
  }

  handleOpenExportExcel = () => {
    const { IdBrand, searchByValue, searchValue } = this.state
    this.props.apiExportPartnerShipsManagement({ IdBrand: IdBrand === 0 ? null : IdBrand, InputSearchBy: { key: searchByValue, value: searchValue }})
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  handelSortable = index => {
    if (columns[index]) this.setState({ lastSortBy: this.state.sortBy, sortBy: index }, this.handleFilterTableItems)
  }

  handleAlias = (row, value) => {
    if (row.fileIntegrationContact !== value) {
      const updatedRow = { id: row.id, alias: value === "" ? null : value }
      this.setState({ currentRow: row })
      this.props.apiPutPartnerShipsManagement(updatedRow)
    }
  }

  // Function handling the change of an input when user is modyfing it
  handleChangeAliasProps = (idCompany, value) => {
    let aliasCp = cloneDeep(this.state.alias)
    const f = findIndex(aliasCp, { key: `Alias_${idCompany}` })
    if (!isUndefined(f)) aliasCp[f].value = value
    this.setState({ alias: aliasCp })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, xlsx, isLoadingGlobal, partnershipsManagementFilter, partnershipsManagementDetail, partnershipsManagementAvailableParameters, partnershipsManagement } = this.props
    const { alias } = this.state
    let availableByParametersSorted
    if (partnershipsManagementAvailableParameters) availableByParametersSorted = partnershipsManagementAvailableParameters.map(elem => { return ({ 'key': elem, 'value': trl[elem] }) })
    if (isLoadingGlobal || !partnershipsManagementDetail) return null

    return (
      <div>
        <PageHeader title={trl.partnerships_Title} /* subtitle={trl.gmOptions_SubTitle} */ />
        <SC.PageGutter>
          <FilterAndTableWrapper>
            <TableWrapper text={trl.common__search}>
              <BodyTable>
                <FiltersWrapper >
                  <Label>{trl.common__filter_selectBrand}</Label>
                  <SelectField
                    style={{
                      fontSize: theme.font.fontSize,
                      maxWidth: '195px',
                      height: '30px'
                    }}
                    floatingLabelStyle={{
                      display: 'none'
                    }}
                    labelStyle={{
                      borderRadius: 0,
                      padding: '3px 30px 0 5px',
                      border: '1px solid #ced4da',
                      width: '100%',
                      height: '30px',
                      position: 'relative',
                      top: '4px',
                      lineHeight: 'inherit'
                    }}
                    underlineStyle={{
                      display: 'none'
                    }}
                    value={this.state.IdBrand}
                    onChange={this.handleSelectBrandChange}
                    autoWidth={true}
                  >
                    {
                      partnershipsManagementFilter.availableBrands &&
                      partnershipsManagementFilter.availableBrands.map(brand =>
                        <MenuItem
                          key={brand.key}
                          value={brand.key}
                          primaryText={trl[brand.value] || brand.value}
                          style={{ fontSize: theme.font.fontSize }}
                        />
                      )
                    }
                  </SelectField>
                  <InputLabelWrapper>
                    <Label style={{ marginTop: '11px' }}>{trl.common__search} :</Label>
                    <TextField
                      id="product-catalog-search-value"
                      type="text"
                      style={{ width: 150, fontSize: theme.font.fontSize, height: 35, top: '7px' }}
                      value={this.state.searchValue}
                      onChange={this.handleSearchChange}
                      onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                    />
                    <Label style={{ marginTop: '11px' }}>{trl.common__by} :</Label>
                  </InputLabelWrapper>
                  <InputLabelWrapper>
                    <SelectField
                      style={{
                        fontSize: theme.font.fontSize,
                        maxWidth: '195px',
                        height: '30px'
                      }}
                      floatingLabelStyle={{
                        display: 'none'
                      }}
                      labelStyle={{
                        borderRadius: 0,
                        padding: '3px 30px 0 5px',
                        border: '1px solid #ced4da',
                        width: '100%',
                        height: '30px',
                        position: 'relative',
                        top: '4px',
                        lineHeight: 'inherit'
                      }}
                      underlineStyle={{
                        display: 'none'
                      }}
                      value={this.state.searchByValue}
                      onChange={this.handleSearchByChange}
                    >
                      {
                        availableByParametersSorted &&
                        orderBy(availableByParametersSorted, ['key'], ['asc']).map(param =>
                          <MenuItem
                            key={param.key}
                            value={param.key}
                            primaryText={param.value}
                            style={{ fontSize: theme.font.fontSize }}
                          />
                        )
                      }
                    </SelectField>
                  </InputLabelWrapper>
                  <TableRow margin={'10px 0'}>
                    <ButtonWrapper>
                      <SC.ButtonPA_M
                        onClick={this.queryData} >
                        {trl.common__filter_apply_btn}
                      </SC.ButtonPA_M>
                      <SC.ButtonR_M
                        onClick={this.handleReset} >{trl.common__filter_reset_btn}</SC.ButtonR_M>
                    </ButtonWrapper>
                  </TableRow>
                  <TableRow align={'flex-end'} margin={'10px 0'}>
                    <SC.ButtonS_S
                      onClick={this.handleOpenExportExcel}>{trl.common__btn_ExportToExcel}</SC.ButtonS_S>
                    {xlsx && (
                      <a
                        target="_blank"
                        style={{ display: 'none' }}
                        href={`/excel_export/${xlsx}`}
                        ref={exportXlsx => (this.exportXlsx = exportXlsx)}
                      />
                    )}
                  </TableRow>
                </FiltersWrapper>

              </BodyTable>
            </TableWrapper>
          </FilterAndTableWrapper>
        </SC.PageGutter>
        <SC.PageGutter>
          <SC.Sky grey>
          </SC.Sky>
        </SC.PageGutter>
        <SC.PageGutter>
          <div>
            {!isEqual(partnershipsManagementDetail, []) ? (
              <div>
                {!_.isUndefined(partnershipsManagement.error) ? (
                  <ErrorWrapper>{!_.isNull(partnershipsManagement.error) && partnershipsManagement.error.message}</ErrorWrapper>
                ) : null}
                <div >
                  <PartnershipsManagementTable
                    data={partnershipsManagementDetail}
                    //handleOnToggle={row => this.handleOnToggle(row)}
                    className="OrdersTable"
                    handelSortable={this.handelSortable}
                    handleAlias={this.handleAlias}
                    alias={alias}
                    handleChangeFileIntegrationContactProps={this.handleChangeAliasProps}
                    error={(!_.isUndefined(partnershipsManagement.error) && !_.isNull(partnershipsManagement.error)) && partnershipsManagement.error.message}
                    currentRow={this.state.currentRow}
                  />
                </div>
              </div>
            ) : (
              <h2 style={{ textAlign: 'center', marginTop: 80, fontSize: '15px' }}>{'There is no Element to display.'}</h2> // TODO: traduction
            )}
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ auth, me, partnershipsManagement, appAlerts }) {
  return {
    role: auth.user_role,
    iDSociete: me.iD_Societe,
    isLoadingGlobal: appAlerts.loading && appAlerts.loading.isLoading,
    isLoading: partnershipsManagement.isLoading,
    partnershipsManagementFilter: partnershipsManagement.partnershipsFilterInputModel,
    partnershipsManagementDetail: partnershipsManagement.partnerships,
    partnershipsManagementAvailableParameters: partnershipsManagement.partnershipsFilterInputModel.availableByParameters,
    partnershipsManagement: partnershipsManagement,
    xlsx: partnershipsManagement.xlsx
  }
}

PartnershipsManagement.propTypes = {
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired
}
PartnershipsManagement.defaultProps = {
  gmOptions: {}
}
export default connect(
  mapStateToProps,
  { apiPutPartnerShipsManagement, apiGetPartnerShipsManagement, apiExportPartnerShipsManagement }
)(PartnershipsManagement)

