////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
///////COMPONENTS///////
import BrandsAction from '../Actions/BrandsAction'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

export const columns = [
  { key: 'iD_Societe', header: '', hidden: true, sortable: false, primaryKey: true },
  { key: 'name', header: translate('brand_table_tableTitle__name'), sortable: true },
  { key: 'name_2', header: translate('brand_table_tableTitle__&Name'), sortable: true },
  { key: 'ligne_1_Facturation', header: translate('brand_table__address'), sortable: true },
  { key: 'ville_Facturation', header: translate('brand_table_tableTitle__cityConfirmed'), sortable: true },
  { key: 'pays_Facturation', header: translate('brand_table_tableTitle__country'), sortable: true },
  { key: 'actions', header: translate('brand_table_tableTitle__actions'), sortable: true, Component: props => (<BrandsAction {...props} />) }
]

export const searchDomaine = [
  { key: 'name', header: 'Name' },
  { key: 'name_2', header: "& Name" },
  { key: 'ligne_1_Facturation', header: "Adress" },
  { key: 'ville_Facturation', header: "City confirmed" },
  { key: 'pays_Facturation', header: "Country" },
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}
// set show page size to false if we want data on single page, in this case we encounter a little piece of lag during the data sort 
class BrandsTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, trl, handelSortable } = this.props
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  {columns.map((c, index) => {
                    if (c.hidden) return null
                    return (
                      <SortableHeader
                        {...c}
                        sortKey={index.toString()}
                        name={c.header}
                        handleClick={index => handelSortable(index)}
                      />
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  const objectData = {
                    row: {
                      ...item
                    },
                  }
                  return (
                    <tr key={index}>
                      {columns.map((c, key) => {
                        if (c.hidden) return null
                        return (
                          <td key={key}>
                            {c.Component
                              ? React.createElement(c.Component, {
                                ...objectData,
                                children: item[c.key],
                                trl
                              })
                              : item[c.key]}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}



BrandsTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
}



export default sematable('brandsTable', BrandsTable, columns, config)
