import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import { get, find, isUndefined, isNull } from 'lodash'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

export const columns = [
  { key: 'idAddress', sortable: false, header: '', primaryKey: true, hidden: true },
  { key: 'name', sortable: true, header: translate('partnerships_management_tableTitle_garmentMaker') },
  { key: 'idExterne', sortable: true, header: translate('partnerships_management_tableTitle_sapCode'), },
  { key: 'alias', sortable: true, header: translate('partnerships_management_tableTitle_alias'), Component: (props) => {
      // We need to handle input values with value attr. instead of defaultValue (which doesn't change on render)
      // To store values of each inputs, we need to "state" them.
      // We are retrieving those values by finding them in props.
      const val = find(props.alias, { key: `Alias_${props.row.id}` })
      return <input
        name="alias"
        id={`Alias_${props.row.id}`}
        type="text"
        // if we enter 2 same alias we display red border to user
        style={{ maxWidth: 150, border: (props.error.length > 0 && props.row.id === props.currentRow.id) ? '1px solid red' : '1px solid black' }}
        //fullWidth={false}
        //pattern='[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z.]{2,30}'
        // If value is not defined (by page loading for example), we are loading the initial props instead
        value={!isUndefined(val) ? isNull(val.value) ? "" : val.value : props.row.alias}
        // we disabled all input while user don't fix identical value
        disabled={props.error.length > 0 && props.row.id !== props.currentRow.id}
        onBlur={(e) => {
          e.target.title = ""
          e.target.style = "max-width: 150px;"
          props.handleAlias(props.row, e.target.value)
        }}
        // Calling function to change value of input, now that we are not using defaultValue
        onChange={(e) => props.handleChangeFileIntegrationContactProps(props.row.id, e.target.value)}
      />
    }
  },
  //{ key: 'actions', header: translate('myaddresses_tableTitle_action'), Component: ToggleOnOff }
]

export const searchDomaine = [
  { key: 'name', header: translate('partnerships_management_tableTitle_garmentMaker') },
  { key: 'idExterne', header: translate('partnerships_management_tableTitle_sapCode') },
  { key: 'alias', header: translate('partnerships_management_tableTitle_alias') },
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false,
}

class PartnershipsManagementTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, handelSortable, handleChangeFileIntegrationContactProps, alias, handleAlias, error, currentRow } = this.props
    if (!data.length) return null
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  {columns.map((c, index) => {
                    if (c.hidden) return null
                    return (
                      <SortableHeader
                        {...c}
                        sortKey={index.toString()}
                        name={c.header}
                        handleClick={index => handelSortable(index)}
                      />
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.key + '_' + index} className={'tableLigneGrey'}>
                      {columns.map(c => {
                        if (c.hidden) return null
                        const children = get(item, c.key)
                        return (
                          <td key={'__' + c.key} className={c.className}>
                            {c.Component && c.Component !== null
                              ? React.createElement(c.Component, {
                                row: item,
                                children,
                                alias: alias,
                                handleAlias: c.key === 'alias' ? handleAlias : null,
                                handleChangeFileIntegrationContactProps: handleChangeFileIntegrationContactProps,
                                error,
                                currentRow: currentRow,
                              })
                              : children}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}

PartnershipsManagementTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  handelSortable: PropTypes.func,
}

export default sematable('PartnershipsManagementTable', PartnershipsManagementTable, columns, config)
