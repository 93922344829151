////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual, isNull, orderBy } from 'lodash'
import dateFormat from 'dateformat'
import styled from 'styled-components'
///////COMPONENTS///////
import { theme } from '../../components/styledComponents'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import ProductCatalogTable from '../../components/Sematable/Tables/ProductCatalogTable'
import Checkbox from 'material-ui/Checkbox'
import MenuItem from 'material-ui/MenuItem'
import SelectField from 'material-ui/SelectField'
import { apiGetProductCatalog, apiProductCatalogExport } from '../../actions/productCatalog'
import TextField from 'material-ui/TextField'

/////////STYLED/////////
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const Check = styled(Checkbox)`
  width: auto !important;
`
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
`
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ? props.margin : '0'};
`
/////////STYLED/////////

class ProductCatalog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      brandId: null,
      inputSearch: '',
      inputBy: null,
      seeFixedItem: true,
      seeVariableItem: true
    }
    this.defaultState = this.state
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    const { selectedBrandId, selectedSeeFixedItem, seeVariableItem, xlsx, selectedBy } = nextProps
    if (!this.props.xlsx && xlsx) {
      setTimeout(() => {
        this.exportXlsx && this.exportXlsx.click()
      }, 1000)
    }
    if ( selectedBrandId !== this.state.brandId ) this.setState({ brandId: isNull(selectedBrandId) ? 0 : selectedBrandId })
    if ( selectedBy !== this.state.inputBy ) this.setState({ inputBy: isNull(selectedBy) ? 'common__all' : selectedBy })
    if ( selectedSeeFixedItem !== this.state.seeFixedItem && selectedSeeFixedItem !== this.props.selectedSeeFixedItem) {
      this.setState({ seeFixedItem: selectedSeeFixedItem })
    }
    if (seeVariableItem !== this.state.seeVariableItem && seeVariableItem !== this.props.seeVariableItem) {
      this.setState({ seeVariableItem: seeVariableItem })
    }
  }

  componentDidMount() {
    this.queryData()
  }

  queryData = (defaultState) => {
    const { brandId, inputSearch, inputBy } = this.state
    if (isEqual(defaultState, this.defaultState)) {
      this.props.apiGetProductCatalog(defaultState)
    } else {
      this.props.apiGetProductCatalog({
        brandId: brandId === 0 ? null : brandId,
        inputSearch: inputSearch,
        inputBy: inputBy === 'common__all' ? null : inputBy,
        seeFixedItem: this.state.seeFixedItem,
        seeVariableItem: this.state.seeVariableItem
      })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleSelectBrandChange = (event, index, value) => { this.setState({ brandId: value })
  }

  handleSearchChange = e => { this.setState({ inputSearch: e.target.value })
  }

  handleSearchByChange = (event, index, value) => { this.setState({ inputBy: value })
  }

  onFilterChange = filter => () => { this.setState(state => ({ [filter]: !state[filter] }))
  }

  handleReset = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

  handleOpenExportExel = () => {
    const { brandId, inputSearch, inputBy } = this.state
    this.props.apiProductCatalogExport({
      brandId: brandId === 0 ? null : brandId,
      inputSearch: inputSearch,
      inputBy: inputBy === 'common__all' ? null : inputBy,
      seeFixedItem: this.state.seeFixedItem,
      seeVariableItem: this.state.seeVariableItem
    })
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, availableBrands, availableParameters, itemPrices, xlsx, lastUpdateTime, isLoadingGlobal } = this.props
    if (isNull(availableBrands) || isLoadingGlobal) return null
    let availableByParametersSorted
    if (availableParameters) {
      availableByParametersSorted = availableParameters.map(elem => { return ({ 'key': elem, 'value': trl[elem] }) })
    }
    if(isNull(this.state.brandId)) this.handleSelectBrandChange('','',0)
    if(isNull(this.state.brandId)) this.handleSearchByChange('','',0)

    return (
      <div>
        <PageHeader
          title={trl.productCatalogue_title}
          subtitle={trl.productCatalogue_subTitle}
        />
        <SC.PageGutter>
          <FilterAndTableWrapper>
            <TableWrapper text={trl.common__search}>
              <BodyTable>
                <FiltersWrapper>
                  <Label>{trl.common__filter_selectBrand}</Label>
                  <SelectField
                    style={{
                      fontSize: theme.font.fontSize,
                      maxWidth: '195px',
                      height: '35px'
                    }}
                    iconStyle={{
                      margin: '0',
                      padding: '0'
                    }}
                    floatingLabelStyle={{
                      display: 'none'
                    }}
                    labelStyle={{
                      borderRadius: 0,
                      padding: '3px 30px 0 5px',
                      border: '1px solid #ced4da',
                      width: '100%',
                      height: '30px',
                      position: 'relative',
                      top: '4px',
                      lineHeight: 'inherit'
                    }}
                    underlineStyle={{
                      display: 'none'
                    }}
                    value={this.state.brandId}
                    onChange={this.handleSelectBrandChange}
                    autoWidth={true}
                  >
                    {
                      availableBrands &&
                      availableBrands.map(brand =>
                        <MenuItem
                          key={brand.key}
                          value={brand.key}
                          primaryText={trl[brand.value] || brand.value}
                          style={{ fontSize: theme.font.fontSize }}
                        />
                      )
                    }
                  </SelectField>
                  <InputLabelWrapper>
                    <Label>{trl.common__search} :</Label>
                    <TextField
                      id="product-catalog-search-value"
                      type="text"
                      style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                      value={this.state.inputSearch}
                      onChange={this.handleSearchChange}
                      onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                    />
                    <Label style={{marginLeft: 10}}>{trl.common__by} :</Label>
                  </InputLabelWrapper>
                  <InputLabelWrapper>
                    <SelectField
                      style={{
                        fontSize: theme.font.fontSize,
                        maxWidth: '195px',
                        height: '35px'
                      }}
                      floatingLabelStyle={{
                        display: 'none'
                      }}
                      labelStyle={{
                        borderRadius: 0,
                        padding: '3px 30px 0 5px',
                        border: '1px solid #ced4da',
                        width: '100%',
                        height: '30px',
                        position: 'relative',
                        top: '4px',
                        lineHeight: 'inherit'
                      }}
                      underlineStyle={{
                        display: 'none'
                      }}
                      iconStyle={{
                        margin: '0',
                        padding: '0'
                      }}
                      value={this.state.inputBy}
                      onChange={this.handleSearchByChange}
                    >
                      {
                        availableByParametersSorted &&
                        orderBy(availableByParametersSorted, ['value'], ['asc']).map(param =>
                          <MenuItem
                            key={param.key}
                            value={param.key}
                            primaryText={param.value}
                            style={{ fontSize: theme.font.fontSize }}
                          />
                        )
                      }
                    </SelectField>
                  </InputLabelWrapper>
                  <TableRow align={'flex-start'} margin={'10px 0 0 0'}>
                    <Check checked={this.state.seeFixedItem}
                      onCheck={this.onFilterChange('seeFixedItem')} />
                    <Label>{trl.common__show_static_items}</Label>
                  </TableRow>
                  <TableRow align={'flex-start'} margin={'0 0 10px 0'}>
                    <Check checked={this.state.seeVariableItem}
                      onCheck={this.onFilterChange('seeVariableItem')} />
                    <Label>{trl.common__show_variable_items}</Label>
                  </TableRow>
                  <TableRow margin={'10px 0'}>
                    <ButtonWrapper>
                      <SC.ButtonPA_M
                        onClick={this.queryData}>
                        {trl.common__filter_apply_btn}
                      </SC.ButtonPA_M>
                      <SC.ButtonR_M
                        onClick={this.handleReset}>{trl.common__filter_reset_btn}</SC.ButtonR_M>
                    </ButtonWrapper>
                  </TableRow>
                  <TableRow align={'flex-end'} margin={'10px 0'}>
                    <SC.ButtonS_S
                      onClick={this.handleOpenExportExel}>{trl.common__btn_ExportToExcel}</SC.ButtonS_S>
                    {xlsx && (
                      <a
                        target="_blank"
                        style={{ display: 'none' }}
                        href={`/excel_export/${xlsx}`}
                        ref={exportXlsx => (this.exportXlsx = exportXlsx)}
                      />
                    )}
                  </TableRow>
                </FiltersWrapper>

              </BodyTable>
            </TableWrapper>
          </FilterAndTableWrapper>
          <div>
            {lastUpdateTime && (
              <span>
                {trl.common__date_updated} {dateFormat(new Date(lastUpdateTime), 'dd/mm/yy - h:MM tt')}
              </span>
            )}
            <ProductCatalogTable data={itemPrices} isLoading={isLoading} trl={trl} />
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

ProductCatalog.propTypes = {}

ProductCatalog.defaultProps = {}

const mapStateToProps = state => ({
  isLoadingGlobal: state.appAlerts.loading && state.appAlerts.loading.isLoading,
  ...state.productCatalog
})

export default connect(mapStateToProps, {
  apiGetProductCatalog,
  apiProductCatalogExport
})(ProductCatalog)
