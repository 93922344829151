////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
import styled from 'styled-components'
import { isNull, map } from 'lodash'
///////COMPONENTS///////
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'
import ProductionFollowUpActions from '../Actions/ProductionFollowUpActions'
import ProductionFollowUpDetail from '../Actions/ProductionFollowUpDetail'
import ColorStatusPROFU from '../ColumnHelpers/ColorStatusPROFU'

/////////STYLED/////////
const TableContainerCustom = styled(TableContainer)`
  & table > thead > tr > th[data-key='plannedOrderQty'] {
      border-right: 1px solid black;
  };
  & table > tbody > tr > td:nth-child(9) {
      border-right: 1px solid black;
  }
  & table {
    margin-bottom: 0px !important;
  }
`
/////////STYLED/////////

class PlannedOrderQtyPROFU extends Component {
  render() {
    const { row } = this.props
    return <div>{!isNull(row.plannedOrderQty) ? row.plannedOrderQty : "-"}</div>
  }
}
class QtePROFU extends Component {
  render() {
    const { row } = this.props
    return <div>{!isNull(row.qte) ? row.qte : "-"}</div>
  }
}

class AvailableQtyPROFU extends Component {
  render() {
    const { row } = this.props
    return <div>{!isNull(row.availableQty) ? row.availableQty : "-"}</div>
  }
}

class TotalLostQtyPROFU extends Component {
  render() {
    const { row } = this.props
    return <div>{!isNull(row.totalLostQty) ? row.totalLostQty : "-"}</div>
  }
}

class TotalTestedQtyPROFU extends Component {
  render() {
    const { row } = this.props
    return <div>{!isNull(row.totalTestedQty) ? row.totalTestedQty : "-"}</div>
  }
}

const columns = [
  { key: 'idItem', primaryKey: true, hidden: true },
  { key: 'detail', header: translate('profu_table_tableTitle__detail'), Component: ProductionFollowUpDetail, sortable: true },
  { key: 'enseigne_Name', header: translate('profu_tableTitle_brand'), sortable: true },
  { key: 'faconnier_Name', header: translate('profu_tableTitle_GMName'), sortable: true },
  { key: 'iD_Article_Externe_SAP', header: translate('profu_tableTitle_CTCItem'), sortable: true },
  { key: 'designation', header: translate('profu_tableTitle_itemDesignation'), sortable: true },
  { key: 'ctC_Date_Confirmed', header: translate('profu_tableTitle_CTCDateConfirmed'), Component: DateFormatter, sortable: true },
  { key: 'iD_PlannedOrder', header: translate('profu_tableTitle_plannedOrderID'), sortable: true },
  { key: 'status.idStatus', header: translate('profu_tableTitle_status'), Component: ColorStatusPROFU, sortable: true, },
  { key: 'plannedOrderQty', header: translate('profu_tableTitle_plannedOrder_currentQty'), sortable: true, Component: PlannedOrderQtyPROFU },
  { key: 'qte', header: translate('profu_tableTitle_qty'), sortable: true, Component: QtePROFU },
  { key: 'availableQty', header: translate('profu_tableTitle_plannedOrder_totalProduceQty'), sortable: true, Component: AvailableQtyPROFU },
  { key: 'totalLostQty', header: translate('profu_tableTitle_plannedOrder_totalWastedQty'), sortable: true, Component: TotalLostQtyPROFU },
  { key: 'totalTestedQty', header: translate('profu_tableTitle_plannedOrder_totalTestedQty'), sortable: true, Component: TotalTestedQtyPROFU },
  { key: 'iD_Commande_Externe_SAP', header: translate('profu_tableTitle_SAPPO'), sortable: true },
  { key: 'iD_Commande', header: translate('profu_tableTitle_JPIPO'), sortable: true },
  { key: 'printshop_Name', header: translate('profu_tableTitle_printshop'), sortable: true },
  { key: 'labellist_Name', header: translate('profu_tableTitle_labellist'), sortable: true },
  { key: 'action', header: translate('common__table_header_actions'), Component: ProductionFollowUpActions, sortable: true }
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

class ProductionFollowUpTable extends Component {

 	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data } = this.props
    const computedData = map(data, (item, key) => {
      item.status.tradValue = translate(item.status.traductionKey)
      return item
    })
    return (
      <TableContainerCustom style={{ border: '1px solid black' }}>
        <Table {...this.props} data={computedData} columns={columns} NoDataComponent={NoDataComponent} />
      </TableContainerCustom>
    )
  }
}

ProductionFollowUpTable.propTypes = propTypes

export default sematable('ProductionFollowUpTable', ProductionFollowUpTable, columns, config)
