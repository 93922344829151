// PROPERTIES NEEDED FOR COMPONENT
// expanded={active}  -> lightswitch on or off : boolean
// labelPosition='right'  -> if label is on the right or left : 'rights || 'left'
// labelExpanded={labelExpanded}  -> label text if lightswitch on : string
// labelRetracted={labelRetracted}  -> label text if lightswitch off : string
// confMsgToExpand={confMsgToExpand}  -> confirmation message when looking to turn lightswitch on : string
// confMsgToRetract={confMsgToRetract}  -> confirmation message when looking to turn lightswitch off : string
// apiCallToExpand={apiCallToExpand}  -> api call when looking to turn lightswitch on : string
// apiCallToRetract={apiCallToRetract} -> api call when looking to turn lightswitch off : string
// actionOnExpand={() => editUserLockout} -> action to update Application state when lightswitch turned on successfully: function | optional
// actionOnRetract={() => editUserLockout} /> -> action to update Application state when lightswitch turned off successfully: function | optional
// disabled  -> disable the toggle : boolean | optional
// flatButtonTrue -> text for the flatButton that is true: string | optional
// flatButtonFalse -> text for the flatButton that is false: string | optional

////////LIBRARY/////////
import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
///////COMPONENTS///////
import Toggle from 'material-ui/Toggle'
import CircularProgress from 'material-ui/CircularProgress'
import { headerGet } from '../../utils/headers'
import { checkStatus } from '../../utils/apiUtils'
import * as SC from '../styledComponents'
import { translate } from '../../containers/HOC/Translation'

class ToggleWithConfirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      toggleActive: true,
      label: null,
      confirmMsg: null
    }
    this.setCircularProgress = this.setCircularProgress.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const { expanded, labelExpanded, labelRetracted } = this.props
    if (expanded) this.setState({ expanded: true, label: labelExpanded })
    else this.setState({ expanded: false, label: labelRetracted })
  }

  setCircularProgress() {
    this.setState({ confirmMsg: <CircularProgress size={40} thickness={5} /> })
  }

  makeApiCall(action) {
    const { apiCallToExpand, apiCallToRetract, labelExpanded, labelRetracted, actionOnExpand, actionOnRetract } = this.props
    this.setCircularProgress()
    let url = null
    let config = null
    let message = null
    let appStateAction = null
    // setting appropriate url and config for API call
    if (!this.state.expanded) {
      url = apiCallToRetract.url
      config = apiCallToRetract.config || headerGet
      message = labelExpanded
      appStateAction = actionOnRetract
    } else {
      url = apiCallToExpand.url
      config = apiCallToExpand.config || headerGet
      message = labelRetracted
      appStateAction = actionOnExpand
    }
    // making API CALL
    fetch(url, config)
      .then(checkStatus)
      //.then(parseJson)
      .then((data) => {
        // toggle lightswitch and display appropriate message
        this.setState({
          expanded: !this.state.expanded,
          label: message,
          confirmMsg: null,
          toggleActive: true
        })
        if (appStateAction) appStateAction()
      })
      .catch((err) => {
        const error = err.message || err
        this.setState({ confirmMsg: error, toggleActive: true })
      })
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  setConfirmMessageToExpand() {
    this.setState({
      confirmMsg: (
        <div>
          {this.props.confMsgToExpand}
          <div>
            <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={() => this.handleCancelDemand()}>
              {this.props.flatButtonFalse || translate('common__no')}
            </SC.ButtonR_S>,
            <SC.ButtonPA_S onClick={() => this.makeApiCall()}>
              {this.props.flatButtonTrue || translate('common__yes')}
            </SC.ButtonPA_S>
          </div>
        </div>
      )
    })
  }

  setConfirmMessageToRetract() {
    this.setState({
      confirmMsg: (
        <div>
          {this.props.confMsgToRetract}
          <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={() => this.handleCancelDemand()}>
            {this.props.flatButtonFalse || translate('common__no')}
          </SC.ButtonR_S>,
          <SC.ButtonPA_S onClick={() => this.makeApiCall()}>
            {this.props.flatButtonTrue || translate('common__yes')}
          </SC.ButtonPA_S>
        </div>
      )
    })
  }

  handleCancelDemand() {
    this.setState({ confirmMsg: null, toggleActive: true })
  }

  handleConfirmMessages() {
    // if lightswitch not expanded, display confirm to expand
    if (!this.state.expanded) {
      return this.setConfirmMessageToExpand()
    } else {
      // if lightswitch expanded, display confirm message to retract
      return this.setConfirmMessageToRetract()
    }
  }

  handleToggle = (event, toggle) => {
    //if toggle is active
    if (this.state.toggleActive) {
      // make toggle inactive
      this.setState({ toggleActive: false })
      //toggle confirmation message
      this.handleConfirmMessages()
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { labelPosition, disabled = false } = this.props
    return (
      <div>
        <Toggle
          toggled={this.state.expanded}
          onToggle={this.handleToggle}
          labelPosition={labelPosition}
          disabled={disabled}
          label={this.state.label}
        />
        {this.state.confirmMsg}
      </div>
    )
  }
}

export default ToggleWithConfirm
