///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// FETCHING LOGS
function fetchLogsRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.FETCH_LOGS_REQUEST, endpointPath })
  }
}
function fetchLogsSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.FETCH_LOGS_SUCCESS, endpointPath })
    dispatch({
      type: c.FETCH_LOGS,
      payload: payload
    })
  }
}
function fetchLogsFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.FETCH_LOGS_FAILURE, endpointPath })
  }
}
export function fetchLogs(data) {
  return u.getValueFromStore(
    c.apiLogs,
    h.headerPostWithToken(data),
    fetchLogsRequest,
    fetchLogsSuccess,
    fetchLogsFailure
  )
}
