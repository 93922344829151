////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import Divider from 'material-ui/Divider'
import * as c from '../../../constants'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem'
import theme from '../../styledComponents/theme'
import ViewFileErrors from './ViewFileErrors'
import * as SC from '../../styledComponents'
import Dialog from 'material-ui/Dialog'
import { checkRoleHasRightsToFunction } from '../../../containers/misc/allowedRolesPerFunction'
import { apiGetFileOrderFileDownload, apiCancelFileOrder } from '../../../actions/fileOrderFollowUp'
/////////ASSETS/////////
import FileDownload from 'material-ui/svg-icons/file/file-download'
import Edit from 'material-ui/svg-icons/image/edit'
import Cancel from 'material-ui/svg-icons/navigation/cancel'

class FileOrderFollowUpActions extends Component {
  state = {
    openFileErrorsModal: false,
    open: false,
    isModalOpened: false
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = name => {
    this.setState({ [name]: true, isModalOpened: true })
  }

  handleClose = name => {
    this.setState({ [name]: false, isModalOpened: false })
  }

  handleCancelSubmit = () => {
    const { apiCancelFileOrder, row } = this.props
    apiCancelFileOrder(row.file_ID)
    this.handleClose('open')
  }

  seeFileErrorsOpen = () => {
    this.setState({ openFileErrorsModal: !this.state.openFileErrorsModal, isModalOpened: !this.state.isModalOpened })
  }

  getFile = (fileID) => {
    const { apiGetFileOrderFileDownload } = this.props
    apiGetFileOrderFileDownload(fileID)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, myRole, row } = this.props

    const actions = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={() => this.handleClose('open')}>
        {trl.common__no}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleCancelSubmit.bind(this)}>
        {trl.common__yes}
      </SC.ButtonPA_S>
    ]
    return (
      <div>
        <ActionHelper onClick={this.handleActionClick} isModalOpened={this.state.isModalOpened}>
          {/* Download File */}
          { row.file_ID > 0 &&
            <div>
              <MenuItem
                style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.fofu_tableAction_downloadFile}
                leftIcon={<FileDownload />}
                onClick={() => this.getFile(row.file_ID)}
              />
            </div>
          }
          {/* Cancel File Order */}
          {row.can_Cancel_File && checkRoleHasRightsToFunction(myRole, c.cancelFileOrder) && (
            <div>
              <Divider style={{ height: '1.5px' }} />
              <MenuItem
                style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.fofu_tableAction_cancelFile}
                leftIcon={<Cancel />}
                onClick={() => this.handleOpen('open')}
              />
            </div>
          )
          }
          { row.file_Have_Errors && row.file_ID !== null &&
            <div>
              <Divider style={{ height: '1.5px' }} />
              <MenuItem
                style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.fofu_tableAction_viewError}
                leftIcon={<Edit />}
                onClick={this.seeFileErrorsOpen}
              />
            </div>
          }
        </ActionHelper>
        <ViewFileErrors
          row={row}
          open={this.state.openFileErrorsModal}
          openHandler={this.seeFileErrorsOpen}
          trl={trl}
        />
        <Dialog
          title={trl.common__order_cancel_confirm || ' '}
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={() => this.handleClose('open')}
        />
      </div>
    )
  }
}

FileOrderFollowUpActions.propTypes = {
  row: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired
}

const mapStateToProps = ({ languages, auth, me, fileOrderFollowUp }) => ({
  trl: languages.siteLanguage.keys,
  myRole: auth.user_role,
  myCompanyType: me.company_type,
  fileOrderFollowUp: fileOrderFollowUp
})

export default connect(
  mapStateToProps, {
  apiGetFileOrderFileDownload, apiCancelFileOrder
}
)(FileOrderFollowUpActions)
