////////LIBRARY/////////
import React from 'react'

const PriceWithCurrendy = ({ row }) => {
    let output = ''
    if ( row.price < 0 || row.price === null || row.currencySign === null || row.status.idStatus === 32 ||
         row.status.idStatus === 22 || row.status.idStatus === 23 ) output = ''
    else output = Math.round(row.price * 100) / 100 + ' ' + row.currencySign

	///////////////////////////////// RENDER ///////////////////////////////////////

    return (
        <div>
          {output}
        </div>
    )
}

PriceWithCurrendy.propTypes = {
}

export default PriceWithCurrendy
