export function fontFaceHelper(name, src, fontWeight = 'normal', fontStyle = 'normal', isOtf = false) {
  return `
    @font-face{
      font-family: "${name}";
      src: url(${require('../assets/fonts/' + src + '.eot')});
      src: url(${require('../assets/fonts/' + src + '.eot')}?#iefix) format("embedded-opentype"),
           url(${require('../assets/fonts/' + src + '.woff')}) format("woff"),
           url(${require('../assets/fonts/' + src + '.ttf')}) format("truetype"),
           url(${require('../assets/fonts/' + src + '.svg')}#${name}) format("svg")
           ${isOtf ? `, url(${require('../assets/fonts/' + src + '.otf')}) format("opentype")` : ``};

      font-style: ${fontStyle};
      font-weight: ${fontWeight};
    }
  `
}
