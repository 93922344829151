////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
///////COMPONENTS///////
import WiredSelectField from '../../../components/misc/WiredSelectField'
import CircularProgress from 'material-ui/CircularProgress'
import { styles } from '../misc/formStyles'
import { renderInput } from '../misc/FormHelpers'
import passwordValidationFunction from '../misc/passwordValidation'
import { fetchLanguages, setSiteLanguageUsingId, setPreferredLanguage } from '../../../actions/languages'
import { changeMyPassword, setMyPreferredLanguage } from '../../../actions/me'
import { selectFieldInitialValue } from '../Login/helper'
import { PageHeader } from '../../../components/sharedComponents'
import { PageGutter, theme } from '../../../components/styledComponents'
import * as SC from '../../../components/styledComponents'

const validate = (values, props) => {
  let errors = {}
  const { trl } = props
  if (!values.currentPassword) errors.currentPassword = trl.common__password_required_error || ''
  errors = { ...errors, ...passwordValidationFunction(values, trl) }
  return errors
}

class MyAccount extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.fetchLanguages()
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(f) {
    const userModel = {
      OldPassword: f.currentPassword,
      NewPassword: f.password
    }
    return this.props.changeMyPassword(userModel, this.props.user_id)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {error, handleSubmit ,submitting ,languages ,setSiteLanguageUsingId ,setPreferredLanguage ,setMyPreferredLanguage ,user_id ,trl } = this.props
    return (
      <div>
        <PageHeader title={trl.my_account__title} />
        <PageGutter style={{ marginLeft: 30 }}>
          <h4 style={{ ...styles.title, textAlign: 'left' }}>{trl.my_account__password_title}</h4>
          <p style={{ ...styles.instruction, margin: 0 }}>{trl.my_account__password_subtitle}</p>
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field
              style={{ fonstSize: theme.font.fonstSize }}
              name="currentPassword"
              fullWidth={false}
              type="password"
              component={renderInput}
              label={trl.common__password_current}
            />
            <br />
            <Field
              name="password"
              fullWidth={false}
              type="password"
              component={renderInput}
              label={trl.common__password_new}
            />
            <br />
            <Field
              name="passwordConfirmation"
              fullWidth={false}
              type="password"
              component={renderInput}
              label={trl.common__password_confirm_new}
            />
            <br />
            {error && <p style={styles.loginError}>{error}</p>}
            <div>
              {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
              <SC.ButtonPA_M
                style={{ marginTop: 20 }}
                type="submit"
                disabled={submitting}
              >
                {trl.my_account__password_btn || ' '}
              </SC.ButtonPA_M>
            </div>
          </form>
          <h4 style={{ ...styles.title, marginTop: 80, textAlign: 'left' }}>{trl.my_account__language_title}</h4>
          <WiredSelectField
            fields={languages.length ? languages.filter(l => l.enabled) : languages}
            floatingLabelText={trl.my_account__language}
            initialValue={selectFieldInitialValue()}
            functionOnChange={[
              setSiteLanguageUsingId,
              setPreferredLanguage,
              setMyPreferredLanguage.bind(this, user_id)
            ]}
            value={'languageid'}
            primaryText={'localName'}
          />
        </PageGutter>
      </div>
    )
  }
}

MyAccount.propTypes = {
  fetchLanguages: PropTypes.func.isRequired,
  setSiteLanguageUsingId: PropTypes.func.isRequired,
  setPreferredLanguage: PropTypes.func.isRequired,
  setMyPreferredLanguage: PropTypes.func.isRequired,
  changeMyPassword: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  trl: PropTypes.object.isRequired
}

MyAccount = reduxForm({
  form: 'myAccount',
  validate,
  touchOnBlur: false,
  errors: {}
})(MyAccount)

function mapStateToProps({ auth, languages }) {
  return {
    user_id: auth.user_id,
    languages: languages.languages
  }
}

export default connect(mapStateToProps, {
  changeMyPassword,
  fetchLanguages,
  setSiteLanguageUsingId,
  setPreferredLanguage,
  setMyPreferredLanguage
})(MyAccount)
