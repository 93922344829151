////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/////////STYLED/////////
const ColorItem = styled.span`
  color: ${props => (props.status_Color ? props.status_Color : 'black')};
  font-size: 12px;
  font-weight: bold;
`
/////////STYLED/////////

class ColorStatusIFU extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row } = this.props
    const trl = this.context.store.getState().languages.siteLanguage.keys
    return (
      <ColorItem key={row.jpiOrder} status_Color={row.itemStatus.color}>
        {trl[row.itemStatus.traductionKey]}
      </ColorItem>
    )
  }
}

ColorStatusIFU.propTypes = {
  row: PropTypes.object,
}

ColorStatusIFU.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default ColorStatusIFU
