const stringformat = (...all) => {
  // The string containing the format items (e.g. "{0}")
  // will and always has to be the first argument.
  let theString = all[0]

  // start with the second argument (i = 1)
  for (let i = 1; i < all.length; i++) {
    // "gm" = RegEx options for Global search (more than one instance)
    // and for Multiline search
    let regEx = new RegExp('\\{' + (i - 1) + '\\}', 'gm')
    theString = theString.replace(regEx, all[i])
  }

  return theString
}

export default stringformat
