// takes the baseLanguage and targetLanguage Objects to merge them in a common object to pass into a table
import { createSelector } from 'reselect'

//pick off pieces of state I care about for this calculation
const baseLanguage = (state) => state.languages.baseLanguage
const targetLanguage = (state) => state.languages.targetLanguage

// creating select functions with the merging logic
const mergeBaseAndTargetTranslations = (base, target) => {
  try {
    // creating an object using base
    let mergedBaseTargetState = {}
    base.keys.map((item) => {
      return (mergedBaseTargetState[item.key] = { key: item.key, baseValue: item.value, targetValue: null })
    })
    // adding target value to mergedBaseTargetState
    target.keys.map((item) => {
      return (mergedBaseTargetState[item.key].targetValue = item.value || '')
    })
    // reformating object so that it fits the format for sematable [{...}, {...}]
    let mergedBasedTargetStateForSematable = []
    for (let key in mergedBaseTargetState) {
      if (mergedBaseTargetState.hasOwnProperty(key)) {
        mergedBasedTargetStateForSematable.push(mergedBaseTargetState[key])
      }
    }
    return mergedBasedTargetStateForSematable
  } catch (err) {
    return []
  }
}

export default createSelector(
  baseLanguage,
  targetLanguage,
  mergeBaseAndTargetTranslations //last argument always the function that has our select logic
)
