////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SortableHeader } from 'sematable'
import styled from 'styled-components'
import _, { isEmpty } from 'lodash'
///////COMPONENTS///////
import OrderDetailFollowUpActions from '../Actions/OrderDetailFollowUpActions'
import Color from '../ColumnHelpers/Color.js'
import { apiGetOrderArticle, apiGetSkuList } from '../../../actions/orders'
import OrderArticleNestedFollowUpTable from './OrderArticleNestedFollowUpTable'
import PageLoad from '../../PageLoad'
import { TableContainer } from '../../styledComponents'
import DateFormatterLastShipmentDate from '../ColumnHelpers/DateFormatterLastShipmentDate'
import DateFormatterCtc from '../ColumnHelpers/DateFormatterCtc'
import ArticleType from '../ColumnHelpers/ArticleType'
import { translate } from '../../../containers/HOC/Translation'
import PageLoadTable from '../../PageLoadTable'
/////////ASSETS/////////
import ZoomIn from 'material-ui/svg-icons/action/zoom-in'

/////////STYLED/////////
const OrderDetailFollowUpTableLoader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255,255,255, 0.8);
  z-index: 1499;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`
/////////STYLED/////////

const ViewArticleDetailAction = ({ orderID, idCommandeArticle, ToggleNestedTable, row }) => {

	///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div>
      {row.skuCount > 0 && !row.article_Fixe && (
        <div onClick={() => ToggleNestedTable(orderID, idCommandeArticle)} style={{ cursor: 'pointer' }}>
          <ZoomIn />
        </div>
      )}
    </div>
  )
}

ViewArticleDetailAction.propTypes = {
  ToggleNestedTable: PropTypes.func,
  orderID: PropTypes.number,
  idCommandeArticle: PropTypes.number,
  row: PropTypes.object.isRequired
}

// ORDER DETAIL FOLLOWUPTABLE
const columns = [
  { key: 'iD_Article', header: 'CTC item ref', primaryKey: true, hidden: true },
  { key: 'iD_Commande', header: '', Component: ViewArticleDetailAction },
  { key: 'status_Traduction_Key', header: translate('ofu_lvlItems_tableTitle_Status'), Component: Color },
  { key: 'iD_Article_Externe_Enseigne', header: translate('ofu_lvlItems_tableTitle_BrandItem') },
  { key: 'iD_Article_Externe_SAP', header: translate('ofu_lvlItems_tableTitle_CTCItem') },
  { key: 'article_Name', header: translate('ofu_lvlItems_tableTitle_Designation') },
  { key: 'article_Type_Name', header: translate('ofu_lvlItems_tableTitle_ItemType'), Component: ArticleType },
  { key: 'ctC_Date_Confirmed', header: translate('ofu_lvlItems_tableTitle_ConfirmedDate'), Component: DateFormatterCtc },
  { key: 'qte_Totale', header: translate('ofu_lvlItems_tableTitle_Quantity') },
  { key: 'qte_Shipped_Totale', header: translate('ofu_lvlItems_tableTitle_ShippedQuantity') },
  { key: 'lastShipmentDate', header: translate('ofu_lvlOrder_tableTitle_lastShipmentDate'), Component: DateFormatterLastShipmentDate },
  { key: 'carrier', header: translate('ofu_table_nested_tableTitle__carrier') },
  { key: 'lastAWB', header: translate('ofu_lvlOrder_tableTitle_lastAWB') },
  { key: 'actions', header: translate('ofu_lvlItems_tableTitle_Action'), Component: OrderDetailFollowUpActions }
]

class OrderDetailFollowUpTable extends Component {
  constructor(props) {
    super(props)
    this.state = { displayNestedTable: null }
    this.ToggleNestedTable = this.ToggleNestedTable.bind(this)
  }

 	///////////////////////////////// HANDLE ///////////////////////////////////////

  ToggleNestedTable = (iD_Commande, idCommandeArticle) => {
    if (iD_Commande && idCommandeArticle) {
      this.props.apiGetSkuList(iD_Commande, idCommandeArticle)
      this.setState({
        displayNestedTable: this.state.displayNestedTable === idCommandeArticle ? false : idCommandeArticle
      })
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { orderID, orders, trl, row, itemWithListSku } = this.props
    const updated_row = !isEmpty(orders[row.iD_Commande]) ? orders[row.iD_Commande] : row
    let tableRows = []
    _.toArray(updated_row.commande_Articles).forEach(ca => {
      tableRows.push(ca)
      tableRows.push(ca)
    })
    let listSkUs = []
    if (!_.isUndefined(itemWithListSku.listSKU)) listSkUs = itemWithListSku.listSKU

    return !orderID || !updated_row ? 
    ( <div>{trl.ofu__no_order}</div> ) 
    : ( <TableContainer>
        {orders.isLoadingTable &&
          <OrderDetailFollowUpTableLoader>
            <PageLoadTable text={trl.common__loading} size={80} thickness={9} />
          </OrderDetailFollowUpTableLoader>
        }
        <div className="table-responsive order-detail-follow-up-table">
          <table className="table table-sm table-striped table-hover">
            <thead>
              <tr>
                { columns.map(c => !c.hidden ? <SortableHeader {...c} sortKey={c.key} name={c.header} handleClick={() => null} /> : null) }
              </tr>
            </thead>
            <tbody>
              {tableRows.map((ca, index) => {
                const objectData = {
                  row: ca,
                  children: ca.article_Fixe,
                  orderID,
                  idCommandeArticle: ca.iD_Commande_Article,
                  ToggleNestedTable: this.ToggleNestedTable,
                  listSkUs
                }
                let skUs = []
                if (!ca.article_Fixe) {
                  skUs = listSkUs.map(sku => {
                    const listDvs = {}
                    sku.listDVS.map(dvs => {
                      return ( listDvs[dvs.type] = dvs.value )
                    })
                    return {
                      key: sku.iD_SKU,
                      status_Color: sku.status_Color,
                      status_Traduction_Key: sku.status_Traduction_Key,
                      ...listDvs
                    }
                  })
                }
                return index % 2 === 0 ? (
                  <tr key={ca.iD_Commande_Article}>
                    {columns.map(c =>
                      !c.hidden ? (
                        <td key={c.key}>{c.Component ? React.createElement(c.Component, objectData) : ca[c.key]}</td>
                      ) : null
                    )}
                  </tr>
                ) : (
                  <tr key={`nested${ca.iD_Commande_Article}`}>
                    {this.state.displayNestedTable === ca.iD_Commande_Article && (
                      <td colSpan={columns.length - 1}>
                        {_.isEmpty(listSkUs) ? (
                          <PageLoad text={trl.common__loading} />
                        ) : (
                          <OrderArticleNestedFollowUpTable data={skUs} />
                        )}
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </TableContainer>
    )
  }
}

OrderDetailFollowUpTable.propTypes = {
  orderID: PropTypes.number
}

OrderDetailFollowUpTable.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = ({ orders, languages }) => ({ orders, trl: languages.siteLanguage.keys, itemWithListSku: orders.itemWithListSku })

export default connect(
  mapStateToProps,
  { apiGetOrderArticle, apiGetSkuList }
)(OrderDetailFollowUpTable)
