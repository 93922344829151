///////COMPONENTS///////
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppHideLoading } from './appAlerts'
import * as c from '../constants'

// PUT SOURCE FILE MODAL
function apiPutSourceFileModalRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_PUT_SOURCE_FILE_MODAL_REQUEST, endpointPath })
  }
}
function apiPutSourceFileModalSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch({ type: c.API_PUT_SOURCE_FILE_MODAL_SUCCESS, endpointPath })
    dispatch({
      type: c.API_PUT_SOURCE_FILE_MODAL,
      payload: payload
    })
  }
}
function apiPutSourceFileModalFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PUT_SOURCE_FILE_MODAL_FAILURE, endpointPath })
  }
}
export function apiPutSourceFileModal(orderId) {
  return u.getValueFromStore(
    `${c.apiSourceFileOrderModal}${orderId}`,
    h.headerGetWithToken(orderId),
    apiPutSourceFileModalRequest,
    apiPutSourceFileModalSuccess,
    apiPutSourceFileModalFailure
  )
}
