////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { isEmpty, isUndefined, isNull } from 'lodash'
import FileBase64 from 'react-file-base64'
///////COMPONENTS///////
import Toggle from 'material-ui/Toggle'
import { styles } from '../misc/formStyles'
import stringformat from '../../../utils/stringformat'
import { apiGetCompanies, apiUpdateCompany, apiGetCompaniesId } from '../../../actions/companies'
import { PageHeader } from '../../../components/sharedComponents'
import * as SC from '../../../components/styledComponents'
import WiredSelectField from '../../../components/misc/WiredSelectField'
import { theme } from '../../../components/styledComponents'
/////////ASSETS/////////
import cross from '../../../assets/cross.png'

const validate = (values, props) => {
  let errors = {}
  if (values.name_2 && values.name_2.length > 255) {
    errors.name_2 = stringformat(props.trl.common__max_length_error, 255) || ' '
  }
  return errors
}

class BrandEdit extends Component {
  constructor() {
    super()
    this.state = {
      base64Logo: '',
      name_2: '',
      batContact: '',
      fileIntegrationContact: null,
      files: {},
      iD_Labelliste: 0,
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    // Is usefull and called when we are DIRECTLY loading brand edit page (by browser refresh for example)
    //this.props.apiGetCompanies()
    const idCompany = this.props.params.id
    this.props.apiGetCompaniesId(idCompany)
  }

  componentWillReceiveProps(nextProps) {
    // Act on receiving companies api callback
    // But it will only be usefull when we are DIRECTLY loading brand edit page (by browser refresh for example)
    if (isEmpty(this.props.company) && !isEmpty(nextProps.company)) {
      if (nextProps.company.labellists && nextProps.company.labellists.length > 0) this.fieldsInitializer(nextProps.company.labellists)
      this.setState({
        name_2: nextProps.company.name_2,
        batContact: nextProps.company.batContact,
        fileIntegrationContact: nextProps.company.fileIntegrationContact,
        base64Logo: nextProps.company.base64Logo,

      })
    }
    if (this.props.company !== nextProps.company) {
      if (nextProps.company.labellists && nextProps.company.labellists.length > 0) this.fieldsInitializer(nextProps.company.labellists)
      this.setState({
        name_2: nextProps.company.name_2,
        batContact: nextProps.company.batContact,
        fileIntegrationContact: nextProps.company.fileIntegrationContact,
        base64Logo: nextProps.company.base64Logo,
        iD_Labelliste: nextProps.company.iD_Labelliste
      })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  fieldsInitializer(companies, routeParams = null) {
    // Add null company with ID -1
    if (companies.filter(c => c.iD_Societe === -1).length === 0) {
      companies.push({
        iD_Societe: -1,
        name: 'No labellist',
        type: 'LS'
      })
    }
  }

  // File upload Callback
  getFiles(files) {
    this.setState({ files: files })
  }

  handleChangeSelect = value => {
    const model = {
      iD_Societe: this.props.params.id,
      iD_Labelliste: value
    }
    return this.props.apiUpdateCompany(model)
  }

  handleChangeToggle = (event, isInputChecked) => {
    const model = {
      iD_Societe: this.props.params.id,
      Permission_Enum_Name: event.target.name,
      Permission_Value: isInputChecked
    }
    return this.props.apiUpdateCompany(model)
  }

  handleChange = (type, target, e) => {
    this.setState({ [target]: e.target.value })
  }

  handleDeleteFile = (target) => {
    this.setState({ [target]: {} })
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) {
        // enter key pressed
        event.preventDefault()
        this.handleSubmit()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.handleSubmit()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.handleSubmit()
      }
    }
  }

  handleSubmit = (target = null) => {
    const { trl } = this.props
    let company = {}
    let errors = 'errors'
    let canSubmitName = true
    let canSubmitBatContact = true
    let canSubmitFileIntegration = true
    const reg = RegExp(
      /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    if (target === 'imageUpdate') {
      company = {
        iD_Societe: this.props.company.iD_Societe,
        name_2: this.state.name_2,
        base64Logo: !isUndefined(this.state.files.base64) ? this.state.files.base64 : "",
        batContact: !isEmpty(this.state.batContact) ? this.state.batContact : null,
        fileIntegrationContact: !isEmpty(this.state.fileIntegrationContact) ? this.state.fileIntegrationContact : null,
      }
    } else {
      company = {
        iD_Societe: this.props.company.iD_Societe,
        name_2: this.state.name_2,
        base64Logo: null,
        batContact: !isEmpty(this.state.batContact) ? this.state.batContact : null,
        fileIntegrationContact: !isEmpty(this.state.fileIntegrationContact) ? this.state.fileIntegrationContact : null,
      }
    }
    // we test what field is edit, and if we detect an error we set a new error objet in local state
    // to know if we can do the api call we set a local  boolean according to the field  
    if (!isUndefined(this.state.name_2)) {
      if (this.state.name_2.length <= 0) {
        canSubmitName = false
        this.setState({
          [`${errors}_name_2`]: {
            message: trl.common__field_required,
            name: 'name_2',
            type: 'minLength'
          }
        })
      } else if (this.state.name_2.length > 255) {
        canSubmitName = false
        this.setState({
          [`${errors}_name_2`]: {
            message: stringformat(trl.common__max_length_error, 255),
            name: 'name_2',
            type: 'maxLength'

          }
        })
      } else {
        canSubmitName = true
        this.setState({
          [`${errors}_name_2`]: {
            message: null,
            name: 'name_2',
            type: null
          }
        })
      }
    }

    if (!isNull(this.state.batContact)) {
      if (this.state.batContact.length > 255) {
        canSubmitBatContact = false
        this.setState({
          [`${errors}_batContact`]: {
            message: stringformat(trl.common__max_length_error, 255),
            name: 'batContact',
            type: 'maxLength'
          }
        })
      } else if (this.state.batContact.length > 0 && !this.state.batContact.match(reg)) {
        canSubmitBatContact = false
        this.setState({
          [`${errors}_batContact`]: {
            message: trl.login__email_invalid_error,
            name: 'batContact',
            type: 'mail'
          }
        })
      }
      else {
        canSubmitBatContact = true
        this.setState({
          [`${errors}_batContact`]: {
            message: null,
            name: '_batContact',
            type: null
          }
        })
      }
    }

    if (!isNull(this.state.fileIntegrationContact)) {
      if (this.state.fileIntegrationContact.length > 255) {
        canSubmitFileIntegration = false
        this.setState({
          [`${errors}_fileIntegrationContact`]: {
            message: stringformat(trl.common__max_length_error, 255),
            name: 'fileIntegrationContact',
            type: 'maxLength'
          }
        })
      } else if (this.state.fileIntegrationContact.length > 0 && !this.state.fileIntegrationContact.match(reg)) {
        canSubmitFileIntegration = false
        this.setState({
          [`${errors}_fileIntegrationContact`]: {
            message: trl.login__email_invalid_error,
            name: 'fileIntegrationContact',
            type: 'mail'
          }
        })
      }
      else {
        canSubmitFileIntegration = true
        this.setState({
          [`${errors}fileIntegrationContact`]: {
            message: null,
            name: 'fileIntegrationContact',
            type: null
          }
        })
      }
    }

    // if we dont have errors we can submit our datas
    if (canSubmitName && canSubmitBatContact && canSubmitFileIntegration) {
      this.props.apiUpdateCompany(company)
      this.setState({
        errors_batContact: { message: null, name: 'batContact', type: null },
        errors_name_2: { message: null, name: 'name_2', type: null },
        errors_fileIntegrationContact: { message: null, name: 'fileIntegrationContact', type: null }
      })
    }
  }

  render() {
    const { trl, submitting, isLoadingGlobal, company } = this.props
    if (isLoadingGlobal || isEmpty(company)) {
      return null
    }
    return (
      <div>
        <PageHeader
          title={trl.admin_brand_edit__title}
          subtitle={trl.admin_brand_edit__subtitle}
          brandName={company.name_2}
          base64Logo={company.base64Logo}
        />
        <SC.PageGutter>
          <h2 className={'h2--centered'}>{ }</h2>
          <p>{trl.brand_form__brandLogo}:</p>
          {
            !isEmpty(this.state.files) ?
              <div>
                <img src={this.state.files.base64} style={{ margin: '15px' }} alt="brand logo" />
                <img src={cross} alt='delete' onClick={() => this.handleDeleteFile('files')} style={{ cursor: 'pointer' }} />
              </div>
              : null
          }
          <div style={{ border: '1px solid black', padding: '14px', maxWidth: '500px' }}>
            <p>
              {trl.brand_form_choseFile} : <br />
              <i>{trl.brand_form__recommendedDimensions} :</i> <br />
              <i>{`${trl.brand_form_imageInformationsWidth}. `}</i><br />
              <i>{`${trl.brand_form_imageInformationsHeight}. `}</i> <br />
            </p>
            <FileBase64 multiple={false} onDone={this.getFiles.bind(this)} />
            <div>
              <SC.ButtonPA_S
                style={{ ...styles.loginBtn, margin: '10px 0 0 0', float: 'initial' }}
                onClick={() => this.handleSubmit('imageUpdate')}
              >
                {trl.common__save_id_ext_fac_btn || ' '}
              </SC.ButtonPA_S>

            </div>
          </div>
          <p style={{ clear: 'both' }}>
            {trl.common__company_code}: {company.iD_Externe_SAP}
          </p>
          <p>
            {trl.common__company_name}: {company.name_2}
          </p>

          <div>
            <p style={{ margin: '0px 10px 8px 0' }}>{trl.common__company_name_2}* : </p>
            <div style={{ display: 'flex', alignItems: 'start', columnGap: '10px' }}>
              <input
                id='name_2'
                type="text"
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: 0,
                  height: '30px',
                  paddingLeft: '10px',
                  width: 500,
                }}
                name="name_2"
                value={this.state.name_2}
                onChange={e => this.handleChange('text', 'name_2', e)}
                onKeyPress={(evt) => this.onKeyPressHandler(evt)}
              />
            </div>
            {
              !isUndefined(this.state.errors_name_2) && this.state.errors_name_2.name === 'name_2' &&
              <p style={{ color: theme.colors.red }}>{this.state.errors_name_2.message}</p >

            }
          </div>
          <div>
            <p style={{ margin: '8px 10px 8px 0' }}>{trl.companySettings__configuration_batContact} : </p>
            <div style={{ display: 'flex', alignItems: 'start', columnGap: '10px' }}>
              <input
                id='batContact'
                type="email"
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: 0,
                  height: '30px',
                  paddingLeft: '10px',
                  width: 500,
                }}
                name="batContact"
                value={this.state.batContact}
                onChange={e => this.handleChange('email', 'batContact', e)}
                onKeyPress={(evt) => this.onKeyPressHandler(evt)}

              />
            </div>
            {
              !isUndefined(this.state.errors_batContact) && this.state.errors_batContact.name === 'batContact' &&
              <p style={{ color: theme.colors.red }}>{this.state.errors_batContact.message}</p >
            }
          </div>
          <div>
            <p style={{ margin: '8px 10px 8px 0' }}>{trl.companySettings__configuration_fileOrderContact} : </p>
            <div style={{ display: 'flex', alignItems: 'start', columnGap: '10px' }}>
              <input
                id='fileIntegrationContact'
                type="email"
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: 0,
                  height: '30px',
                  paddingLeft: '10px',
                  width: 500,
                }}
                name="fileIntegrationContact"
                value={this.state.fileIntegrationContact}
                onChange={e => this.handleChange('email', 'fileIntegrationContact', e)}
                onKeyPress={(evt) => this.onKeyPressHandler(evt)}
              />
            </div>
            {
              !isUndefined(this.state.errors_fileIntegrationContact) && this.state.errors_fileIntegrationContact.name === 'fileIntegrationContact' &&
              <p style={{ color: theme.colors.red }}>{this.state.errors_fileIntegrationContact.message}</p >
            }
          </div>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <SC.ButtonPA_S
                style={{ ...styles.loginBtn, float: 'none', margin: '8px 0 0 0' }}
                disabled={submitting}
                //type="submit"
                onClick={() => this.handleSubmit()}
              >
                {trl.common__save_id_ext_fac_btn || ' '}
              </SC.ButtonPA_S>

            </div>

          </div>

          <p>
            {trl.brand_form__address} : {company.ligne_1_Facturation}
          </p>
          <p>
            {trl.brand_form__city} : {company.ville_Facturation}
          </p>
          <p>
            {trl.brand_from__country} : {company.pays_Facturation}
          </p>

          <hr />
          <h2 className={'h2'}>{trl.brand_form__companySet}</h2>

          <WiredSelectField
            fields={company.labellists}
            floatingLabelText={trl.brand_form__handlerLabellist}
            initialValue={this.state.iD_Labelliste ? this.state.iD_Labelliste : -1}
            initVal={this.state.iD_Labelliste ? this.state.iD_Labelliste : -1}
            functionOnChange={[this.handleChangeSelect]}
            value={'iD_Societe'}
            primaryText={'name'}
          />


          {company &&
            company.permissions &&
            company.permissions.map(perm => (
              <Toggle
                key={perm.enum_name}
                name={perm.enum_name}
                label={trl[perm.traduction_Key]}
                defaultToggled={perm.value}
                labelPosition="right"
                onToggle={this.handleChangeToggle.bind(this)}
              />
            ))}
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ companies, appAlerts }) {

  return {
    company: companies.company,
    isLoadingGlobal: appAlerts.loading && appAlerts.loading.isLoading,


  }
}

BrandEdit.propTypes = {
  trl: PropTypes.object.isRequired,
  apiGetCompanies: PropTypes.func.isRequired,
  companies: PropTypes.object.isRequired
}

BrandEdit = reduxForm({
  form: 'brandEditForm',
  validate,
  touchOnBlur: false,
  errors: {}
})(BrandEdit)

export default connect(
  mapStateToProps,
  { apiGetCompanies, apiUpdateCompany, apiGetCompaniesId }
)(BrandEdit)
