import * as c from '../constants'

const INITIAL_STATE = {
  list: [],
  isLoading: false,
  errors: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_MY_DOCUMENTATION_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_GET_MY_DOCUMENTATION_FAILURE:
      return { ...state, isLoading: false }
    case c.API_GET_MY_DOCUMENTATION_SUCCESS: {
      action.payload.filtersSettings && action.payload.filtersSettings.availableBy.splice(0, 0, 'common__all')
      return { ...state, list: action.payload.filtersSettings && action.payload, isLoading: false, errors: {} }
    }
    case c.RESET_MY_DOCUMENTATION: {
      return { ...INITIAL_STATE }
    }
    default:
      break
  }
  return state
}
