////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import * as SC from '../../styledComponents'
import { apiDeleteAddress } from '../../../actions/myCarriers'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem';
import theme from '../../styledComponents/theme'
/////////ASSETS/////////
import Edit from 'material-ui/svg-icons/image/edit'
import Cancel from 'material-ui/svg-icons/navigation/cancel'

class MyAddressesAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openAwbModal: false,
      openDeleteModal: false,
      isModalOpened: false
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpenDeleteModal = () => {
    this.setState({ openDeleteModal: true, isModalOpened: true })
  }

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false, isModalOpened: false })
  }

  handleConfirmDelete = () => {
    const { row: { iD_Adresse }, iDSociete } = this.props
    this.props.apiDeleteAddress({ iDSociete, idAddress: iD_Adresse })
    this.setState({ openDeleteModal: false })
  }

 	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl = {}, handleOpenEdit } = this.props

    const actionsCancel = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={this.handleCloseDeleteModal}>
        {trl.myaddresses_modal_btn_Cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleConfirmDelete}>
        {trl.common__ok}
      </SC.ButtonPA_S>
    ]

    return (
      <div>
        <ActionHelper isModalOpened={this.state.isModalOpened}>
          <MenuItem
            style={{ fontSize: theme.font.fontSize }}
            primaryText={trl.mycarriers_tableAction_Edit}
            leftIcon={<Edit />}
            onClick={() => handleOpenEdit(row)}
          />
          <MenuItem
            style={{ fontSize: theme.font.fontSize }}
            primaryText={trl.mycarriers_tableAction_Delete}
            leftIcon={<Cancel />}
            onClick={() => this.handleOpenDeleteModal()}
          />
        </ActionHelper>
        <Dialog
          title={trl.mycarriers_tableAction_Delete}
          actions={actionsCancel}
          modal={false}
          open={this.state.openDeleteModal}
        >
          <p>{trl.common__actionsConfirm}</p>
        </Dialog>
      </div>
    )
  }
}

MyAddressesAction.contextTypes = {
  store: React.PropTypes.object.isRequired
}

MyAddressesAction.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ({ languages, me, delivery }) => ({
  trl: languages.siteLanguage.keys,
  company_type: me.company_type,
  additional: delivery.additional,
  errors: delivery.errors
})
export default connect(
  mapStateToProps,
  { apiDeleteAddress }
)(MyAddressesAction)
