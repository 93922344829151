////////LIBRARY/////////
import React from 'react'
import styled, { css } from 'styled-components'
///////COMPONENTS///////
import theme from './theme'
import { Link } from 'react-router'
import * as PR from './propReceivers'
import './injectGlobal'
export { default as theme } from './theme'

const { h1, h2, h3, h4, h5, h6, p1, p2, p3, p4, p5, colors, sizes } = theme

/////////STYLED/////////
export const TableContainer = styled.div`
  overflow: auto;
  position: relative;
  min-height: 160px;
`
export const PageGutter = styled.div`
  padding: 10px 20px 10px 5px;
  background: ${(props) => (props.bgColor ? props.bgColor : 'transparent')};
`
export const Sky = styled.div`
  background-color: ${colors.white};
`
export const TH = styled.th`
  font-size: 1em !important;
  font-weight: bold !important;
`
export const P = styled.p`
  ${(props) => props.centered && `text-align: center`};
  ${PR.marginProps};
`
export const FormError = styled.div`
  position: absolute;
  bottom: -10px;
  font-size: 12px;
  line-height: 12px;
  color: rgb(244, 67, 54);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`
//////////////////////////////////////////
//    START Styled Button of application
//////////////////////////////////////////

//////////////////////////////////////////////////////
// Main action button list
export const ButtonPA_S = styled.button`
  background-color: ${(props) => (props.color ? props.color : 'black')};
  padding: 3px 10px;
  color: ${(props) => (props.color ? 'black' : 'white')};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`
export const ButtonPA_M = styled.button`
  background-color: black;
  padding: 5px 15px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`
export const ButtonPA_L = styled.button`
  background-color: black;
  padding: 7px 20px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`
//////////////////////////////////////////////////////

//////////////////////////////////////////////////////
// Return action button list
export const ButtonR_S = styled.button`
  padding: 1px 10px;
  font-size: 12px;
  background-color: white;
  border: 2px solid black;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
`
export const ButtonR_M = styled.button`
  padding: 5px 15px;
  font-size: 14px;
  background-color: white;
  border: 2px solid black;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
`
export const ButtonR_L = styled.button`
  background-color: white;
  border: 2px solid black;
  padding: 5px 20px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: ${props => props.paddingBottom ? props.paddingBottom : '0'}px;
`
//////////////////////////////////////////////////////

//////////////////////////////////////////////////////
// Secondary action button list

// middle size button
export const ButtonS = styled.button`
  padding: 5px 15px;
  font-size: 14px;
  background-color: lightgrey;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.5s;
  :hover {
    background-color: grey;
  }
`
export const ButtonS_S = styled.button`
  padding: 3px 10px;
  font-size: 12px;
  background-color: lightgrey;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.5s;
  :hover {
    background-color: grey;
  }
`
export const ButtonS_L = styled.button`
  font-size: 16px;
  padding: 7px 20px;
  background-color: lightgrey;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.5s;
  :hover {
    background-color: grey;
  }
`
//////////////////////////////////////////
//    END Styled Button of application
//////////////////////////////////////////

//////////////////////////////////////////
//    START Styled message on login
//////////////////////////////////////////

export const LoginMessageAlerte = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid red;
  padding: 10px;
  margin-bottom: 30px;
`
export const TextAlerte = styled.div`
  text-align: center;
  margin: 0;
  padding: 0;
`
export const Column = styled.div`
  width: 100%;
  padding: 2em;
  display: flex;
  font-size: 13px !important;
  line-height: 20px !important;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    text-transform: uppercase;
  }
  & > *:last-child {
    padding-bottom: 0;
  }
  @media (max-width: 1200px) {
    flex: 0 1 100%;
    width: 100%;
    border: none;
  }
`
const ColumnLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  width: 100%;
  @media (max-width: 1200px) {
    justify-content: center;
    text-align: center;
  }
`
export const ColumnLineLarge = ColumnLine.extend`
  max-width: 400px;
`
export const ColorItem = styled.span`
  color: ${(props) => (props.color ? props.color : 'black')};
  font-size: 12px;
  padding: 7px;
  font-weight: bold;
`
export const ColumnLineMiddle = ColumnLine.extend`
  margin-left: auto;
  margin-right: auto;
`
//////////////////////////////////////////
//    END Styled message on login
//////////////////////////////////////////

export const StyledLink = styled(
  ({
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p1,
    p2,
    p3,
    p4,
    p5,
    ftz,
    marginBottom,
    marginTop,
    marginLeft,
    marginRight,
    margin,
    marginVertical,
    marginHorizontal,
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    padding,
    paddingVertical,
    paddingHorizontal,
    color,
    colorHover,
    bgColor,
    bgColorHover,
    underline,
    block,
    uppercase,
    href,
    target,
    onClick,
    ...rest
  }) => <Link {...rest} href={href} target={target} onClick={onClick} />
)`
  ${(props) => PR.ftzProps(props)};
  ${PR.letterSpacingProps};
  ${PR.marginProps};
  ${PR.paddingProps};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  ${PR.backgroundProps};
  ${PR.bgColorHoverProps};
  ${PR.uppercaseProps};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  cursor: pointer;
  text-align: center;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    top: 4px;
    height: calc(100% - 8px);
    width: 1px;
    border-right: 1px solid #fff;
    padding-right: 15px;
    @media (max-width: 768px) {
      top: 3px;
      height: calc(100% - 6px);
    }
    @media (max-width: 450px) {
      top: 2px;
      height: calc(100% - 4px);
    }
  }
`
/* Global Tags */
export const H1 = styled.h1`
  ${(props) => PR.ftzProps(props, h1.ftz, h1.ftzTablet, h1.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, h1.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${(props) => PR.uppercaseProps(props, 'uppercase')};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const H2 = styled.h2`
  ${(props) => PR.ftzProps(props, h2.ftz, h2.ftzTablet, h2.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, h2.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${(props) => PR.uppercaseProps(props, 'uppercase')};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const H3 = styled.h3`
  ${(props) => PR.ftzProps(props, h3.ftz, h3.ftzTablet, h3.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, h3.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${(props) => PR.uppercaseProps(props, 'uppercase')};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const H4 = styled.h4`
  ${(props) => PR.ftzProps(props, h4.ftz, h4.ftzTablet, h4.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, h4.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${(props) => PR.uppercaseProps(props, 'uppercase')};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const H5 = styled.h5`
  ${(props) => PR.ftzProps(props, h5.ftz, h5.ftzTablet, h5.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, h5.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${(props) => PR.uppercaseProps(props, 'uppercase')};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const H6 = styled.h6`
  ${(props) => PR.ftzProps(props, h6.ftz, h6.ftzTablet, h6.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, h6.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${(props) => PR.uppercaseProps(props, 'uppercase')};
  line-height: 1.4em;
  font-weight: normal;
  ${PR.ftzWeightProps};
`
export const P1 = styled.p`
  ${(props) => PR.ftzProps(props, p1.ftz, p1.ftzTablet, p1.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, p1.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${PR.uppercaseProps};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const P2 = styled.p`
  ${(props) => PR.ftzProps(props, p2.ftz, p2.ftzTablet, p2.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, p2.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${PR.uppercaseProps};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const P3 = styled.p`
  ${(props) => PR.ftzProps(props, p3.ftz, p3.ftzTablet, p3.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, p3.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${PR.uppercaseProps};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const P4 = styled.p`
  ${(props) => PR.ftzProps(props, p4.ftz, p4.ftzTablet, p4.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, p4.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${PR.uppercaseProps};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const P5 = styled.p`
  ${(props) => PR.ftzProps(props, p5.ftz, p5.ftzTablet, p5.ftzMobile)};
  ${(props) => PR.letterSpacingProps(props, p5.letterSpacing)};
  ${(props) => PR.colorProps(props)};
  ${PR.colorHoverProps};
  margin-top: 0;
  margin-bottom: 0;
  ${PR.marginProps};
  ${PR.uppercaseProps};
  line-height: 1.4em;
  ${PR.ftzWeightProps};
`
export const Div = styled.div`
  ${PR.backgroundProps};

  ${PR.marginProps};
  ${PR.paddingProps};

  ${PR.colorProps};
`
// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `
  return acc
}, {})


