////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { apiCancelDelivery } from '../../actions/delivery'
import styled from 'styled-components'
///////COMPONENTS///////
import DateFormatter from '../../components/Sematable/ColumnHelpers/DateFormatter'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/svg-icons/navigation/menu'
import { H4 } from '../../components/styledComponents'
import { translate } from '../HOC/Translation'
import * as SC from '../../components/styledComponents'
import YesNoImg from '../../components/Sematable/ColumnHelpers/YesNoImg'

/////////STYLED/////////
const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 40px;
  & > * {
    background-color: ${SC.theme.colors.lightGrey};
  }
  @media (min-width: 1501px) {
    & > * {
      flex: 0 1 25%;
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 1200px) {
    & > * {
      flex: 0 1 100 %;
      width: 100%;
      border: none;
    }
  }
`
const Column = styled.div`
  width: 100%;
  padding: 2em;
  display: flex;
  font-size: 13px !important;
  line-height: 20px !important;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    text-transform: uppercase;
  }
  & > *:last-child {
    padding-bottom: 0;
  }
  @media (max-width: 1200px) {
    flex: 0 1 100%;
    width: 100%;
    border: none;
  }
`
const ColorItem = styled.span`
  color: ${props => (props.color ? props.color : 'black')};
  font-size: 12px;
  padding: 7px;
  font-weight: bold;
`
const ColumnLine = styled.div`
  display: flex;
  align-items: center;
  max-width: 280px;
  justify-content: flex-start;
  padding-bottom: 10px;
  width: 100%;
  @media (max-width: 1200px) {
    justify-content: center;
    text-align: center;
  }
`
const ColumnLineLast = ColumnLine.extend`
`
const ColumnLineMiddle = ColumnLine.extend`
  margin-left: auto;
  max-width: 300px;
  margin-right: auto;
  @media (max-width: 1500px) {
    margin-left: 0;
    margin-right: 0;
  }
`
const ColumnLineLarge = ColumnLine.extend`
  max-width: 400px;
`
/////////STYLED/////////

const styles = {
  maxWidth: {
    maxWidth: 10000
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  selectWrapper: { marginTop: '-10px' }
}

class DeliveryDetailInformation extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, companyType, delivery } = this.props
    return (
      <ColumnsWrapper>
        {/* Left Column */}
        <div>
          <Column>
            <ColumnLineLarge>
              <span>
                {trl.delivery_header_From}&nbsp;{delivery.sender.labelCompany}
              </span>
            </ColumnLineLarge>
            <ColumnLineLarge>
              <span>
                {trl.delivery_header_To}&nbsp;{delivery.recipient.labelCompany}
              </span>
            </ColumnLineLarge>
            <ColumnLineLarge>
              <span>
                {trl.delivery_header_Country}&nbsp;{delivery.recipient.countryAddress}
              </span>
            </ColumnLineLarge>
          </Column>
        </div>
        {/* Middle Column */}
        <div>
          <Column>
            <ColumnLineMiddle style={styles.maxWidth}>
              <span style={styles.noWrap}>{trl.delivery_header_status}:&nbsp;</span>
              <span>
                <ColorItem color={delivery.status.color}>{translate(delivery.status.traductionKey)}</ColorItem>
              </span>
            </ColumnLineMiddle>
            <ColumnLineMiddle style={styles.maxWidth}>
              <span style={styles.noWrap}>{trl.delivery_header_shipmentAuthoriz}:&nbsp;</span>
              <span>
                <YesNoImg children={delivery.shipmentAuth} />
              </span>
            </ColumnLineMiddle>
            {delivery.status.subFlow >= '020' &&
              delivery.status.subFlow <= '050' &&
              delivery.status.subFlow !== 'ANN' &&
              delivery.clickOnCreate_Date &&
              (companyType === 'LP' || companyType === 'LS') && (
                <ColumnLineMiddle style={styles.maxWidth}>
                  <H4 style={SC.theme.fontSize}>{trl.orderDetail_OrderHeaders_Action}:</H4>
                  <IconMenu iconButtonElement={<Menu />} style={{ cursor: 'pointer' }}>
                    <MenuItem
                      style={{ fontSize: SC.theme.font.fontSize }}
                      primaryText="Cancel delivery"// TODO traduction
                      onClick={() => this.props.apiCancelDelivery({ idDelivery: delivery.idDeliveryJPI })}
                    />
                  </IconMenu>
                </ColumnLineMiddle>
              )}
          </Column>
        </div>
        {/* Right Column */}
        <div>
          <Column>
            <ColumnLineLast>
              <span>{trl.delivery_header_CreationDate}:&nbsp;</span>
              {delivery.creationDate && <DateFormatter>{delivery.creationDate}</DateFormatter>}
            </ColumnLineLast>
            <ColumnLineLast>
              <span>{trl.delivery_header_CTCDateConfirmed}:&nbsp;</span>
              {delivery.ctcDateConfirmed && <DateFormatter>{delivery.ctcDateConfirmed}</DateFormatter>}
            </ColumnLineLast>
            <ColumnLineLast>
              <span>{trl.delivery_header_ShipDate}:&nbsp;</span>
              {delivery.shippingDate && <DateFormatter>{delivery.shippingDate}</DateFormatter>}
            </ColumnLineLast>
          </Column>
        </div>
      </ColumnsWrapper>
    )
  }
}

DeliveryDetailInformation.propTypes = {
  trl: PropTypes.object.isRequired,
  delivery: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
  apiCancelDelivery: PropTypes.func.isRequired
}

DeliveryDetailInformation.contextTypes = {
  router: PropTypes.object.isRequired
}

export default connect(
  null,
  { apiCancelDelivery }
)(DeliveryDetailInformation)
