////////LIBRARY/////////
import React, { Component } from 'react'
///////COMPONENTS///////
import { PageHeader } from '../sharedComponents'

class Contact extends Component {

   	///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
        const { trl } = this.props
        return (
            <div>
                <PageHeader title={trl.contactus_Title} />
                <div style={{ display: 'flex', marginTop: 20 }}>
                    <div style={{ float: 'left', width: '60%', display: 'flex' }}>
                        <div style={{ flex: '50%' }}>
                            <div style={{ marginBottom: 70, textAlign: 'center' }}>
                                <img
                                    src={`${require('../../assets/location.svg')}`}
                                    title="Close To Clothes"
                                    alt="Close To Clothes"
                                    style={{ width: 50, marginBottom: 15 }}
                                /><br />
                                {trl.contactus_CTCParis_line1}<br />
                                {trl.contactus_CTCParis_line2}<br />
                                {trl.contactus_CTCParis_line3}<br />
                                {trl.contactus_CTCParis_line4}<br />
                                {trl.contactus_CTCParis_line5}<br />
                                {trl.contactus_CTCParis_line6}<br />
                                {trl.contactus_CTCParis_line7}<br />
                            </div>
                            <div style={{ marginBottom: 100, textAlign: 'center' }}>
                                <img
                                    src={`${require('../../assets/location.svg')}`}
                                    title="Close To Clothes"
                                    alt="Close To Clothes"
                                    style={{ width: 50, marginBottom: 15 }}
                                /><br />
                                {trl.contactus_CTCHK_line1}<br />
                                {trl.contactus_CTCHK_line2}<br />
                                {trl.contactus_CTCHK_line3}<br />
                                {trl.contactus_CTCHK_line4}<br />
                                {trl.contactus_CTCHK_line5}<br />
                                {trl.contactus_CTCHK_line6}<br />
                                {trl.contactus_CTCHK_line7}<br />
                            </div>
                            <div style={{ marginBottom: 100, textAlign: 'center' }}>
                                <img
                                    src={`${require('../../assets/location.svg')}`}
                                    title="Close To Clothes"
                                    alt="Close To Clothes"
                                    style={{ width: 50, marginBottom: 15 }}
                                /><br />
                                {trl.contactus_CTCChina_line1}<br />
                                {trl.contactus_CTCChina_line2}<br />
                                {trl.contactus_CTCChina_line3}<br />
                                {trl.contactus_CTCChina_line4}<br />
                                {trl.contactus_CTCChina_line5}<br />
                                {trl.contactus_CTCChina_line6}<br />
                                {trl.contactus_CTCChina_line7}<br />
                            </div>
                        </div>
                        <div style={{ flex: '50%' }}>
                            <div style={{ marginBottom: 130, textAlign: 'center' }}>
                                <img
                                    src={`${require('../../assets/phone.png')}`}
                                    title="Close To Clothes"
                                    alt="Close To Clothes"
                                    style={{ width: 40, marginBottom: 15 }}
                                /><br />
                                {trl.contactus_CTCParis_tel}<br />
                                {trl.contactus_CTCParis_fax}<br /><br />
                                {trl.contactus_CTCParis_email}<br />
                            </div>
                            <div style={{ marginBottom: 160, textAlign: 'center' }}>
                                <img
                                    src={`${require('../../assets/phone.png')}`}
                                    title="Close To Clothes"
                                    alt="Close To Clothes"
                                    style={{ width: 40, marginBottom: 15 }}
                                /><br />
                                {trl.contactus_CTCHK_tel}<br />
                                {trl.contactus_CTCHK_fax}<br /><br />
                                {trl.contactus_CTCHK_email}<br />
                            </div>
                            <div style={{ marginBottom: 100, textAlign: 'center' }}>
                                <img
                                    src={`${require('../../assets/phone.png')}`}
                                    title="Close To Clothes"
                                    alt="Close To Clothes"
                                    style={{ width: 40, marginBottom: 15 }}
                                /><br />
                                {trl.contactus_CTCChina_tel}<br />
                                {trl.contactus_CTCChina_fax}<br /><br />
                                {trl.contactus_CTCChina_email}<br />
                            </div>
                        </div>
                    </div>
                    <div style={{ float: 'left', width: '40%', marginRight: 50 }}>
                        <img
                            src={`${require('../../assets/JPI_Contact-us_background.png')}`}
                            title="Close To Clothes"
                            alt="Close To Clothes"
                            style={{ maxWidth: '100%', height: '52em' }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact