////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _, { cloneDeep } from 'lodash'
///////COMPONENTS///////
import { PageHeader } from '../../components/sharedComponents'
import PageLoad from '../../components/PageLoad'
import { apiGetStats } from '../../actions/stats'
import StatsTable from './StatsTable'
import StatsTableAnalysisSales from './StatsTableAnalysisSales'
import StatsTableAnalysisBasket from './StatsTableAnalysisBasket'
import './stats.css'

// objet de configuration du css des tableaux
const miseEnForm = {
  topFiveProductAnalysis: {
    currentSeasonItemRefs : {
      title: {
        itemType : 'AnalysisTitleColored',
        volume: 'AnalysisTitleColored',
      },
      tableHeader: {
        itemType : '',
        volume: 'TablesBorder-Left',
      },
      tableBody: {
        itemType : '',
        volume : 'TablesBorder-Left'
      },
    },
    lastSeasonItemRefs: {
      title: {
        itemType : 'AnalysisTitleColored',
        volume: 'AnalysisTitleColored',
      },
      tableHeader: {
        itemType : 'TablesBorder-Left',
        volume: 'TablesBorder-Left',
      },
      tableBody: {
        itemType : 'TablesBorder-Left',
        volume : 'TablesBorder-Left'
      },
    },
    secondToLastSeasonItemRefs: {
      title: {
        itemType : 'AnalysisTitleColored',
        volume: 'AnalysisTitleColored',
      },
      tableHeader: {
        itemType : 'TablesBorder-Left',
        volume: 'TablesBorder-Left',
      },
      tableBody: {
        itemType : 'TablesBorder-Left',
        volume : 'TablesBorder-Left'
      },
    },
  },
  salesAnalysis: {
    averageShoppingBasket: {
      table: "TablesBorder-Bottom TablesBorder-Left TablesBorder-Right",
      title: {
        devise                  : 'AnalysisTitleColored',
        totalCurrentSeason      : '',
        totalLastSeason         : '',
        totalSecondToLastSeason : '',
      },
      tableHeader: {
        devise                  : 'DeviseTable ColumnBold',
        totalCurrentSeason      : 'TablesBorder-Left',
        totalLastSeason         : 'TablesBorder-Left',
        totalSecondToLastSeason : 'TablesBorder-Left',
      },
      tableBody: {
        devise                  : 'DeviseTable ColumnBold',
        totalCurrentSeason      : 'TablesBorder-Left',
        totalLastSeason         : 'TablesBorder-Left',
        totalSecondToLastSeason : 'TablesBorder-Left',
      },
    },
    totalSalesVolume : {
      table: "TablesBorder-Bottom TablesBorder-Left TablesBorder-Right",
      title: {
        salesVolume             : "AnalysisTitleColored",
        totalCurrentSeason      : '',
        totalLastSeason         : '',
        totalSecondToLastSeason : '',
      },
      tableHeader: {
        salesVolume             : "DeviseTable ColumnBold",
        totalCurrentSeason      : 'TablesBorder-Left',
        totalLastSeason         : 'TablesBorder-Left',
        totalSecondToLastSeason : 'TablesBorder-Left',
      },
      tableBody: {
          totalCurrentSeason      : 'TablesBorder-Left',
          totalLastSeason         : 'TablesBorder-Left',
          totalSecondToLastSeason : 'TablesBorder-Left',
      },
    },
  },
  serviceAnalysis: {
    deliveryModeUsage:{
      table: "TablesBorder-Bottom TablesBorder-Left TablesBorder-Right",
      title: {
        deliveryMode              : "AnalysisTitleColored",
        currentSeasonPercent      : '',
        lastSeasonPercent         : '',
        secondToLastSeasonPercent : '',
      },
      tableHeader: {
        deliveryMode              : "DeviseTable ColumnBold TablesBorder-Bottom TablesBorder-Top",
        currentSeasonPercent      : 'TablesBorder-Left TablesBorder-Bottom TablesBorder-Top',
        lastSeasonPercent         : 'TablesBorder-Left TablesBorder-Bottom TablesBorder-Top',
        secondToLastSeasonPercent : 'TablesBorder-Left TablesBorder-Bottom TablesBorder-Top',
      },
      tableBody: {
        deliveryMode              : 'DeviseTable ColumnBold',
        currentSeasonPercent      : 'TablesBorder-Left',
        lastSeasonPercent         : 'TablesBorder-Left',
        secondToLastSeasonPercent : 'TablesBorder-Left',
      },
    },
    averageServiceRate:{
      table: "",
      title: {
        average                           : "AnalysisTitleColored",
        currentSeasonLeadtimePercent      : '',
        lastSeasonLeadtimePercent         : '',
        secondToLastSeasonLeadtimePercent : '',
      },
      tableHeader: {
        average                           : "DeviseTable",
        currentSeasonLeadtimePercent      : 'TablesBorder-Left',
        lastSeasonLeadtimePercent         : 'TablesBorder-Left',
        secondToLastSeasonLeadtimePercent : 'TablesBorder-Left',
      },
      tableBody: {
          currentSeasonLeadtimePercent      : 'TablesBorder-Left',
          lastSeasonLeadtimePercent         : 'TablesBorder-Left',
          secondToLastSeasonLeadtimePercent : 'TablesBorder-Left',
      },
    }
  }
}

class Stats extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
     this.props.apiGetStats()
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  /**
   * *********************************************************************************
   * check if table are not empty
   *
   * @return  {array}  return not empty table liste
   */
  tableFilter = () => {
    const tables  = {...this.props.stats.tableList} // array de tableau de stat une partie est null
    let tableArray = []
    _.forIn(tables, ( value, index ) => {
      if(value) tableArray.push({[index] : value})
    })  
    return tableArray
  }

  /**
   * *********************************************************************************
   * map tables end return element to layout
   *
   * @param tables    array of tables to display
   * @param trl       array of traduction
   * @return  {array}  return array of jsx element to display
   */
  tableToLayout = (tables, trl) => {
    let layout = tables.map( (elem, index) => {
      switch(Object.getOwnPropertyNames(elem)[0]) {
        case 'topFiveProductAnalysis' :
          return (
            <div className="TableFomat TablesBorder-All">
              <div className="TablesBorder-Bottom TablesTitle">
                <p>{trl['statistics_topTableTitle_topProductsAnalysis']}</p>
              </div>
              {_.map(elem, (element, idx) => {                
                return (
                    <StatsTable 
                      key={idx}
                      data={element}
                      format={miseEnForm.topFiveProductAnalysis}
                      trl={trl}
                    />
                )
              })}
            </div>
          )
        case 'salesAnalysis' :
          const sales = {'totalSalesVolume' : elem['salesAnalysis']['totalSalesVolume']}
          return (
            <div className="TableFomat TablesBorder-Top">
              <div className="TablesBorder-Bottom TablesBorder-Left TablesBorder-Right TablesTitle">
                <p>{trl['statistics_topTableTitle_salesAnalysis']}</p>
              </div>
              <div>
              <StatsTableAnalysisSales
                data={sales}
                format={miseEnForm.salesAnalysis.totalSalesVolume}
                formatTable={miseEnForm.salesAnalysis.totalSalesVolume.table}
                trl={trl}
              />
              <StatsTableAnalysisBasket
                data={elem['salesAnalysis']['averageShoppingBasket']['devises']}
                format={miseEnForm.salesAnalysis.averageShoppingBasket}
                formatTable={miseEnForm.salesAnalysis.averageShoppingBasket.table}
                trl={trl}
              />
              </div>
            </div>
          )
        case 'serviceAnalysis' :
          const delivery = {'deliveryModeUsage' : elem['serviceAnalysis']['deliveryModeUsage']}
          const averageServiceRate = {"averageServiceRate" : elem['serviceAnalysis']['averageServiceRate']}          
          return (
            <div className="TableFomat TablesBorder-All">
              <div className="TablesBorder-Bottom TablesTitle">
                <p>{trl['statistics_topTableTitle_serviceAnalysis']}</p>
              </div>
              <div>
              <StatsTableAnalysisSales
                data={averageServiceRate}
                format={miseEnForm.serviceAnalysis.averageServiceRate}
                formatTable={miseEnForm.serviceAnalysis.averageServiceRate.table}
                trl={trl}
                />
              <StatsTable
                data={delivery}
                format={miseEnForm.serviceAnalysis}
                trl={trl}
                />
              </div>
            </div>
          )
        default:        
          return (
              <StatsTable
                key={index}
                data={elem}
                css={true}
                trl={trl}
              />
          )
      }
    })
    // applie CSS style for LP_ADMIN, FACA and EADMIN pages
    let gridSize;
    tables.length === 2 ? gridSize = "tablesFaca" : gridSize = "tablesEnseigne"
    layout = (layout.length === 3) ? (
        <div className="StatsTableLab">
          {layout[0]}
          <div className="StatsTable">
            {layout[1]}
            <div style={{margin:" 0 2%"}}></div>
            {layout[2]}
          </div>
        </div>
    ) : (
        <div className={gridSize}>        
          {layout}
        </div> )
    return layout
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, stats } = this.props
    const tables = this.tableFilter();
    const layout = this.tableToLayout(cloneDeep(tables), trl)

    if( stats.isLoading ) {
      return null
    }
    return (
      <div className="wrapper_stats">
        <PageHeader title={trl.statistics_pageTitle} subtitle={trl.statistics_subTitle} />
          <div className="StatsTableWrapper">
            {layout}
          </div>
      </div>
    )
  }
}


Stats.propTypes = {
  trl: PropTypes.object.isRequired,
  apiGetStats: PropTypes.func,
  stats: PropTypes.object,
  isLoading: PropTypes.bool
}
const mapStateToProps = ({ stats }) => ({
  stats: stats,
  isLoading: stats.isLoading
})

export default connect(
  mapStateToProps,
  { apiGetStats }
)(Stats)
