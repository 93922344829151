////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import styled from 'styled-components'
///////COMPONENTS///////
import ColorBatStatus from '../ColumnHelpers/ColorBatStatus'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import BATsPendingActions from '../Actions/BATsPendingActions'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import PageLoadTable from '../../PageLoadTable'

/////////STYLED/////////
const BATPendingTableLoader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255,255,255, 0.8);
  z-index: 1499;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
`
/////////STYLED/////////

const columns = [
  { key: 'idBat', header: 'BAT id', hidden: true, primaryKey: true },
  { key: 'batDate', header: translate('batPending_tableTitle_Date'), searchable: true, sortable: true, Component: DateFormatter},
  { key: 'gM_Name', header: translate('batPending_tableTitle_GMName'), sortable: true, searchable: true },
  { key: 'batStatus', header: translate('batPending_tableTitle_Status'), Component: ColorBatStatus },
  { key: 'brandPo', header: translate('batPending_tableTitle_BrandPO'), searchable: true, sortable: true },
  { key: 'jpiPo', header: translate('batPending_tableTitle_JPIPO'), searchable: true, sortable: true },
  { key: 'brandItemRef', header: translate('batPending_tableTitle_BrandItemref'), searchable: true, sortable: true },
  { key: 'ctcItemRef', header: translate('batPending_tableTitle_CTCItemRef'), sortable: true },
  { key: 'brandSeason', header: translate('batPending_tableTitle_BrandSeason'), sortable: true },
  { key: 'brandDrop', header: translate('batPending_tableTitle_BrandDrop'), sortable: true },
  { key: 'date_LastRefusal', header: translate('batPending_tableTitle_LastRejectionDate'), searchable: true, sortable: true, Component: DateFormatter },
  { key: 'actions',  header: translate('batPending_tableTitle_Actions'), Component: BATsPendingActions }
]

export const searchDomaine = [
  { key: 'batDate', header: translate('batPending_tableTitle_Date'), type: 'date' },
  { key: 'gM_Name', header: translate('batPending_tableTitle_GMName') },
  { key: 'brandPo', header: translate('batPending_tableTitle_BrandPO') },
  { key: 'jpiPo', header: translate('batPending_tableTitle_JPIPO') },
  { key: 'brandItemRef', header: translate('batPending_tableTitle_BrandItemref') },
  { key: 'ctcItemRef', header: translate('batPending_tableTitle_CTCItemRef') },
  { key: 'brandSeason', header: translate('batPending_tableTitle_BrandSeason') },
  { key: 'brandDrop', header: translate('batPending_tableTitle_BrandDrop')},
  { key: 'date_LastRefusal', header: translate('batPending_tableTitle_LastRejectionDate'), type: 'date' }
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}

class BATsPendingTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRow: null,
      openModalSku: false,
      aztecFormData: [],
      skuLine: {}
    }
  }

 	///////////////////////////////// HANDLE ///////////////////////////////////////

  myColor(type, position) {
    return this.state[type] === position ? 'rgba(0, 0, 0, 0.2)' : ''
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, trl, isLoadingTable } = this.props
    if (data.length < 1) return null
    return (
      <div>
        <TableContainer>
          { isLoadingTable &&
            <BATPendingTableLoader>
              <PageLoadTable text={trl.common__loading} size={80} thickness={9} />
            </BATPendingTableLoader>
          }
          <div className="tableWrapperBorder">
            <div className="table-responsive order-detail-follow-up-table">
              <table className="table table-sm table-striped table-hover">
                <thead>
                  <tr>
                    {columns.map(c => {
                      if (c.hidden) return null
                      return (
                        <SortableHeader
                          style={{ fontSize: 16 }}
                          {...c}
                          sortKey={c.key}
                          name={c.header}
                          handleClick={() => null}
                        />
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((itemRow, index) => {
                    const objectData = {
                      row: {
                        ...itemRow,
                      },
                      activeRow: index
                    }
                    return (
                      <tr key={index} style={{ backgroundColor: this.myColor('activeRow', index) }}>
                        {columns.map((c, key) => {
                          if (c.hidden) return null
                          return (
                            <td key={key}>
                              {c.Component
                                ? React.createElement(c.Component, {
                                  ...objectData,
                                  children: itemRow[c.key],
                                  trl
                                })
                                : itemRow[c.key]
                                  ? itemRow[c.key]
                                  : '-'}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </TableContainer>
      </div>
    )
  }
}

BATsPendingTable.propTypes = {
  data: PropTypes.array.isRequired,
  handelUpdateOrAddSku: PropTypes.func
}
export default sematable('BATsPendingTable', BATsPendingTable, columns, config)
