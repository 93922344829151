////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
/////////ASSETS/////////
import ZoomIn from 'material-ui/svg-icons/action/zoom-in'
import Flag from '../../../assets/red_flag.png'

/////////STYLED/////////
const FlagImg = styled.img`
  max-width: 15px;
  max-height: 15px;
  margin-right: 5px;
`
const ImgAndZoomWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
const ZoomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
/////////STYLED/////////

class ItemFollowUpSeeNestedActions extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, ToggleNestedTable } = this.props
    return (
      <ImgAndZoomWrapper>
        { row.isOrderWFP && <FlagImg src={Flag} />}
        { row.multipleBreakdown && <ZoomWrapper onClick={() => ToggleNestedTable(row.jpiOrderItem)} style={{ cursor: 'pointer' }}>
                                    <ZoomIn />
                                   </ZoomWrapper>
        }
      </ImgAndZoomWrapper>
    )
  }
}

ItemFollowUpSeeNestedActions.propTypes = {
  row: PropTypes.object.isRequired,
  ToggleNestedTable: PropTypes.func.isRequired
}

export default ItemFollowUpSeeNestedActions
