////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import { setSiteLanguageUsingId, addSiteLanguageToStateFromLS, checkSiteLanguageVersion } from '../../actions/languages'
import { getItem } from '../../utils/cookiesHelper'
import * as c from '../../constants'

export const translate = key => {
  const siteLangLocalStorage = JSON.parse(localStorage.getItem(c.siteLanguage))
  return siteLangLocalStorage && siteLangLocalStorage.keys ? siteLangLocalStorage.keys[key] : null
}

export default function (ComposedComponent) {
  class Translation extends Component {

    ///////////////////////////////// CONFIG ///////////////////////////////////////

    componentDidMount() {
      const { siteLanguage, setSiteLanguageUsingId, checkSiteLanguageVersion, addSiteLanguageToStateFromLS } = this.props
      const siteLangLocalStorage = JSON.parse(localStorage.getItem(c.siteLanguage))
      const siteLangCookie = getItem(c.siteLanguage)
      // is siteLanguage keys empty? checking for the language id property
      if (!siteLanguage.languageid) {
        // is siteLanguage in LocalStorage empty?
        if (!siteLangLocalStorage) {
          // is siteLanguage Id in cookie?
          if (!siteLangCookie) {
            // get default language (english) and set in cookie, localStorage, and state
            setSiteLanguageUsingId()
          } else {
            // if site language exist in cookie, put in state and localStorage
            setSiteLanguageUsingId(siteLangCookie)
          }
        } else {
          // if exist in localStorage, put in state and check version to make sure language version is up to date
          addSiteLanguageToStateFromLS(siteLangLocalStorage)
          checkSiteLanguageVersion(siteLangLocalStorage.languageid, siteLangLocalStorage.version)
        }
      } else {
        //if exist in state, check version to make sure language version is up to date
        checkSiteLanguageVersion(siteLangLocalStorage.languageid, siteLangLocalStorage.version)
      }
    }
    // implement should component update to only change the site language if new version or new language

    ///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
      return <ComposedComponent {...this.props} trl={this.props.siteLanguage.keys} />
    }
  }

  Translation.propTypes = {
    setSiteLanguageUsingId: PropTypes.func.isRequired,
    checkSiteLanguageVersion: PropTypes.func.isRequired,
    addSiteLanguageToStateFromLS: PropTypes.func.isRequired,
    siteLanguage: PropTypes.object.isRequired
  }

  function mapDispatchToProps({ languages }) {
    return { siteLanguage: languages.siteLanguage }
  }

  return connect(mapDispatchToProps, {
    setSiteLanguageUsingId,
    checkSiteLanguageVersion,
    addSiteLanguageToStateFromLS
  })(Translation)
}
