////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FOFUErrorsTableRow extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props
    const trl = this.context.store.getState().languages.siteLanguage.keys
    return (
      <span>
        {trl[children]}
      </span>
    )
  }
}

FOFUErrorsTableRow.propTypes = {
  row: PropTypes.object,
}

FOFUErrorsTableRow.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default FOFUErrorsTableRow