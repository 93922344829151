////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, isNull, isUndefined, map } from 'lodash'
import { Link } from 'react-router'
///////COMPONENTS///////
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem'
import theme from '../../styledComponents/theme'
import * as c from '../../../constants'
import { TableContainer } from '../../styledComponents'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import TextOrDash from '../ColumnHelpers/TextOrDash'
/////////ASSETS/////////
import Print from 'material-ui/svg-icons/action/print'
import Info from 'material-ui/svg-icons/action/info'

const showPrintInvoice = item => {
  const invoiceNumber = get(item, 'invoiceNumber', null)
  return !(!invoiceNumber || invoiceNumber === null)
}

const MyActions = ({ trl = {}, row = {} }) => {
  const showPI = showPrintInvoice(row)

	///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <ActionHelper>
      <Link
        onClick={()=> (!row.isDeliveryFluxJpi && !isUndefined(row.packingListFile) && !isNull(row.packingListFile)) && window.open(`/external/packing_list/${row.packingListFile}.pdf`)}
        to={ (!row.isDeliveryFluxJpi && row.packingListFile != null) ? '' : `${c.delivery}${row.iD_PackingList}${c.packinglistSavePrint}`}
        target="_blank">
        {((row.isDeliveryFluxJpi) || (!row.isDeliveryFluxJpi && row.packingListFile != null)) && <MenuItem
          style={{ fontSize: theme.font.fontSize }}
          primaryText={trl.shipHistory_tableAction_printPL}
          leftIcon={<Print />} />}
      </Link>
      {showPI && (
        <Link href={`/pdf_from_sap/facture/${row.invoiceNumber}.pdf`}
          target="_blank">
          <MenuItem
            style={{ fontSize: theme.font.fontSize }}
            primaryText={trl.shipHistory_tableAction_printInvoice}
            leftIcon={<Info />} onClick={() => null} />
        </Link>
      )}
    </ActionHelper>
  )
}

export const columnsDeliveries = [
  { key: 'iD_PackingList', header: 'shipHistory_tableTitle_deliveryID', primaryKey: true },
  { key: 'actions', header: 'myaddresses_tableTitle_action', Component: MyActions }
]

export const columnsAwbs = [
  { key: 'shippmentDate', header: 'shipHistory_tableTitle_shipmentDate', Component: DateFormatter },
  { key: 'carrierName', header: 'shipHistory_tableTitle_carrier', Component: TextOrDash },
  { key: 'awb', header: 'shipHistory_tableTitle_trackingID' }
]

export const columnsDeliveriesItems = [
  { key: 'ctcItemRef', header: 'shipHistory_subTableTitle_CTCItemRef', primaryKey: true },
  { key: 'quantity', header: 'shipHistory_subTableTitle_quantity' }
]

class ShipmentHistoryTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, trl } = this.props
    return (
      <TableContainer>
        <div className="tableWrapperBorder" style={{ marginBottom: 0, minWidth: 640 }}>
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm" style={{ marginBottom: 0 }}>
              <thead>
                <tr>
                  {columnsDeliveries.map((c) => {
                    return (
                      <th key={c.key} className="tableLigneGrey__"
                        style={{ backgroundColor: '#666666' }}>
                        {trl[c.header]}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  { columnsDeliveries.map(c => {
                    const children = get(data, c.key)
                    return (
                      <td key={'__' + c.key} className={c.className}>
                        <div>
                          {c.Component && c.Component !== null
                            ? React.createElement(c.Component, {
                              row: data,
                              trl,
                              children
                            })
                            : children}
                        </div>

                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        { map(data.awbs, (awb) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '100%',
                minWidth: 540,
                marginTop: '10px',
              }}
            >
              <div
                className="tableWrapperBorder"
                style={{
                  width: '50%',
                  minWidth: 540,
                  marginBottom: 0
                }}
              >
                <table className="table table-sm" style={{ marginBottom: 0 }}>
                  <thead>
                    <tr>
                      { columnsAwbs.map((c) => {
                        return (
                          <th key={c.key} className="tableLigneGreyDarker">
                            {trl[c.header]}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      { columnsAwbs.map(c => {
                        const children = get(awb, c.key)
                        return (
                          <td key={'__' + c.key} className={c.className}>
                            <div>
                              {c.Component && c.Component !== null
                                ? React.createElement(c.Component, {
                                  row: data,
                                  trl,
                                  children
                                })
                                : children}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="tableWrapperBorder"
                style={{
                  width: '35%',
                  minWidth: 300,
                  borderTop: 'none',
                }}
              >
                <table className="table table-sm" style={{ marginBottom: 0 }}>
                  <thead>
                    <tr>
                      { columnsDeliveriesItems.map((c, index) => {
                        return (
                          <th key={c.key} className="tableLigneGrey">
                            {trl[c.header]}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    { awb.deliveryItems.map(item => (
                      <tr key={item.ctcItemRef} className="tableLigneBlue" >
                        { columnsDeliveriesItems.map(c => {
                          const children = get(item, c.key)
                          return (
                            <td key={'__' + c.key} className={c.className}>
                              {children}
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))
        }
      </TableContainer>
    )
  }
}

ShipmentHistoryTable.propTypes = {
  trl: PropTypes.object,
  data: PropTypes.object.isRequired
}

export default ShipmentHistoryTable
