///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET PARTNERSHIPS
function apiGetPartnershipsManagementRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_PARTNERSHIPS_MANAGEMENT_REQUEST, endpointPath })
  }
}
function apiGetPartnershipsManagementSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_PARTNERSHIPS_MANAGEMENT_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_PARTNERSHIPS_MANAGEMENT,
      payload: payload
    })
  }
}
function apiGetPartnershipsManagementFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_PARTNERSHIPS_MANAGEMENT_FAILURE, endpointPath })
  }
}
export function apiGetPartnerShipsManagement(data) {
  return u.getValueFromStore(
    `${c.apiPartnerShipsManagement}`,
    h.headerPutWithToken(data),
    apiGetPartnershipsManagementRequest,
    apiGetPartnershipsManagementSuccess,
    apiGetPartnershipsManagementFailure
  )
}

// PUT PARTNERSHIPS
function apiPutPartnershipsManagementRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_PUT_PARTNERSHIPS_MANAGEMENT_REQUEST, endpointPath })
  }
}
function apiPutPartnershipsManagementSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PUT_PARTNERSHIPS_MANAGEMENT_SUCCESS, endpointPath })
    dispatch({
      type: c.API_PUT_PARTNERSHIPS_MANAGEMENT,
      payload: payload
    })
  }
}
function apiPutPartnershipsManagementFailure(endpointPath, error) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PUT_PARTNERSHIPS_MANAGEMENT_FAILURE, endpointPath })
    dispatch({
      type: c.API_PUT_PARTNERSHIPS_MANAGEMENT,
      error
    })
  }
}
export function apiPutPartnerShipsManagement(data) {
  return u.getValueFromStore(
    `${c.apiPartnerShipManagement}`,
    h.headerPutWithToken(data),
    apiPutPartnershipsManagementRequest,
    apiPutPartnershipsManagementSuccess,
    apiPutPartnershipsManagementFailure
  )
}

// EXPORT PARTNERSHIPS
function apiExportPartnershipsManagementRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_EXPORT_PARTNERSHIPS_MANAGEMENT_REQUEST, endpointPath })
  }
}
function apiExportPartnershipsManagementSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_EXPORT_PARTNERSHIPS_MANAGEMENT_SUCCESS, endpointPath })
    dispatch({
      type: c.API_EXPORT_PARTNERSHIPS_MANAGEMENT,
      payload: payload
    })
  }
}
function apiExportPartnershipsManagementFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_EXPORT_PARTNERSHIPS_MANAGEMENT_FAILURE, endpointPath })
  }
}
export function apiExportPartnerShipsManagement(data) {
  return u.getValueFromStore(
    `${c.apiPartnerShipsManagementExport}`,
    h.headerPutWithToken(data),
    apiExportPartnershipsManagementRequest,
    apiExportPartnershipsManagementSuccess,
    apiExportPartnershipsManagementFailure
  )
}
