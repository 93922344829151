////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import * as c from '../../../constants'

class DateFormatterLogs extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props
    const date2000 = '2000-01-01T00:00:01.087'
    const date9999 = '2100-12-12T00:00:01.087'
    return (
      children && (new Date(children) > new Date(date2000) && new Date(children) < new Date(date9999)) ? 
      ( <span style={{ minWidth: 126, display: 'block', whiteSpace: 'nowrap' }}>
          {dateFormat(new Date(children), c.dateDMY)} {dateFormat(new Date(children), c.dateHMS)}
        </span> ) 
      : ( <span>-</span> )
    )
  }
}

DateFormatterLogs.propTypes = {
  children: PropTypes.string
}

DateFormatterLogs.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default DateFormatterLogs