////////LIBRARY/////////
import { cloneDeep, findIndex } from 'lodash'
///////COMPONENTS///////
import * as c from '../constants'

const INITIAL_STATE = {
  purchaseDetail: [],
  purchaseFilter: [],
  xlsx: null,
  isLoading: true
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_GM_OPTIONS:
      action.payload.purchaseFilter.availableBrands.splice(0, 0, { key: 0, value: 'common__all' })
      return {
        ...state,
        purchaseDetail: action.payload.purchaseDetail,
        purchaseFilter: action.payload.purchaseFilter,
        isLoading: false
      }
    case c.API_GET_GM_OPTIONS_REQUEST:
      return { ...state, isLoading: true }
    case c.API_GET_GM_OPTIONS_FAILURE:
      return { ...state, isLoading: false }
    case c.API_GET_GM_OPTIONS_SUCCESS:
      return { ...state, isLoading: false }
    case c.API_EXPORT_XL_GM_OPTION_REQUEST:
      return {
        ...state,
        xlsx: null
      }
    case c.API_EXPORT_XL_GM_OPTION_SUCCESS:
      return {
        ...state,
        xlsx: action.payload
      }
    case c.API_PUT_GM_OPTION: {
      const stateCp = cloneDeep(state.purchaseDetail)
      const payloadCp = cloneDeep(action.payload)
      const idx = findIndex(stateCp, { idCompany: payloadCp.idCompany })
      stateCp[idx] = payloadCp
      return {
        ...state,
        purchaseDetail: stateCp,
        isisLoading: false
      }
    }
    case c.API_PUT_GM_OPTION_SUCCESS: {
      return { ...state, isLoading: false }
    }
    default:
      return state
  }
}
