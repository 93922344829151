////////LIBRARY/////////
import React, { PropTypes } from 'react'

class Checkbox extends React.Component {
  constructor(props) {
    super(props)
    this.onCheck = this.onCheck.bind(this)
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  onCheck(...args) {
    if (typeof this.props.onCheck === 'function') this.props.onCheck(this.props.control, ...args)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const props = this.props
    const CHECKBOX = props.library[props.component]
    let errorText = ''
    let checkBoxStyle = {}
    if (props.control.customRules) {
      errorText = props.control.customRules.message
      checkBoxStyle = { marginBottom: 30 }
    }
    return (
      <div className="formElementGrid" style={checkBoxStyle}>
        <div className="formElementLabel">{props.control.props.title}</div>
        <CHECKBOX {...props.attributes} onCheck={this.onCheck} />
        {props.control.props.displayFormat === 'type_7' && <img src={props.control.props.label} alt='Label' />}
        {errorText !== '' ? (
          <div
            style={{
              position: 'absolute',
              bottom: -36,
              left: 283,
              fontSize: 12,
              lineHeight: 4,
              color: 'rgb(244, 67, 54)'
            }}
          >
            {errorText}
          </div>
        ) : null}
      </div>
    )
  }
}

Checkbox.propTypes = {
  library: PropTypes.object,
  component: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  control: PropTypes.object,
  rules: PropTypes.object,
  onCheck: PropTypes.func
}
export default Checkbox