////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isNull } from 'lodash'

class SortableItem extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props
    return (
      children && !isNull(children) && typeof children == "string" ?
      ( <span>
          {children.trim()}
        </span> ) 
      : ( <span>-</span> )
    )
  }
}

SortableItem.propTypes = {
  row: PropTypes.object
}

export default SortableItem
