////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { cloneDeep, isUndefined, findIndex, isEqual } from 'lodash'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import MnageGmOptionsTable, { columns } from '../../components/Sematable/Tables/MnageGmOptionsTable'
import * as SC from '../../components/styledComponents'
import { apiGetGmOptions, apiPutGmOption, apiGmOptionExport } from '../../actions/options'
import { theme } from '../../components/styledComponents'

/////////STYLED/////////
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
`
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ? props.margin : '0'};
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
`
/////////STYLED/////////

class ManageGMOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      IdBrand: null,
      SearchBy: null
    }
    this.defaultState = this.state
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.queryData()
  }

  queryData = (defaultState) => {
    const { IdBrand, SearchBy } = this.state
    if (isEqual(defaultState, this.defaultState))
      this.props.apiGetGmOptions({
        IdBrand: this.defaultState.IdBrand === 0 ? null : this.defaultState.IdBrand,
        SearchBy: this.defaultState.SearchBy
      })
    else {
      this.props.apiGetGmOptions({
        IdBrand: IdBrand === 0 ? null : IdBrand,
        SearchBy: SearchBy
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { xlsx } = nextProps
    if (!this.props.xlsx && xlsx) {
      setTimeout(() => {
        this.exportXlsx && this.exportXlsx.click()
      }, 1000)
    }
    if (nextProps.gmOptions !== this.props.gmOptions) {
      this.setState({ gmOptions: nextProps.gmOptions })
      // If props are changing, so do our input values list
      let fileContacts = []
      nextProps.gmOptions.forEach(elem => {
        fileContacts.push({ key: `FileIntegrationContact_${elem.idCompany}`, value: elem.fileIntegrationContact })
      })
      this.setState({ fileIntegrationContacts: fileContacts })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleSelectBrandChange = (event, index, value) => {
    this.setState({ IdBrand: value })
  }

  handleSearchChange = e => {
    this.setState({ SearchBy: e.target.value })
  }

  handleReset = () => {
    this.setState({
      IdBrand: null,
      SearchBy: ''
    })
    // Also adding back call
    this.queryData(this.defaultState)
  }

  handleOpenExportExcel = () => {
    const { IdBrand, SearchBy } = this.state
    this.props.apiGmOptionExport({
      IdBrand: IdBrand === 0 ? null : IdBrand,
      SearchBy: SearchBy
    })
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  handelSortable = index => {
    if (columns[index]) this.setState({ lastSortBy: this.state.sortBy, sortBy: index}, this.handleFilterTableItems)
  }

  handleFileIntegrationContact = (row, value) => {
    if (row.fileIntegrationContact !== value) {
      const updatedRow = { ...row, fileIntegrationContact: value}
      this.props.apiPutGmOption(updatedRow)
    }
  }

  // Function handling the change of an input when user is modyfing it
  handleChangeFileIntegrationContactProps = (idCompany, value) => {
    let fileContacts = cloneDeep(this.state.fileIntegrationContacts)
    const f = findIndex(fileContacts, { key: `FileIntegrationContact_${idCompany}` })
    if (!isUndefined(f)) fileContacts[f].value = value
    this.setState({ fileIntegrationContacts: fileContacts })
  }

  handleOnToggle(row) {
    const updatedRow = { ...row, fiveKilosException: !row.fiveKilosException }
    this.props.apiPutGmOption(updatedRow)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, purchaseDetail, purchaseFilter, xlsx, isLoadingGlobal } = this.props
    const { fileIntegrationContacts } = this.state
    if (isLoadingGlobal || !purchaseDetail) return null
    return (
      <div>
        <PageHeader title={trl.gmOptions_Title} subtitle={trl.gmOptions_SubTitle} />
        <SC.PageGutter>
          <FilterAndTableWrapper>
            <TableWrapper text={trl.common__search}>
              <BodyTable>
                <FiltersWrapper>
                  <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr', rowGap: '20px' }}>
                    <InputLabelWrapper>
                      <Label>{trl.GM_option__filter_btn_search} :</Label>
                      <TextField
                        id="product-catalog-search-value"
                        type="text"
                        style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                        value={this.state.SearchBy}
                        onChange={this.handleSearchChange}
                        onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                      />
                    </InputLabelWrapper>
                    <InputLabelWrapper>
                      <Label>{trl.GM_option__filter_btn_brand} :</Label>
                      <SelectField
                        style={{
                          fontSize: theme.font.fontSize,
                          maxWidth: '195px',
                        }}
                        floatingLabelStyle={{
                          display: 'none'
                        }}
                        labelStyle={{
                          borderRadius: 0,
                          padding: '3px 30px 0 5px',
                          border: '1px solid #ced4da',
                          width: '100%',
                          height: '30px',
                          position: 'relative',
                          top: '4px',
                          lineHeight: 'inherit'
                        }}
                        underlineStyle={{
                          display: 'none'
                        }}
                        value={this.state.IdBrand === null ? 0 : this.state.IdBrand}
                        onChange={this.handleSelectBrandChange}
                        autoWidth={true}
                      >
                        {
                          !isUndefined(purchaseFilter.availableBrands) &&
                          purchaseFilter.availableBrands.map(brand =>
                            <MenuItem
                              key={brand.key}
                              value={brand.key}
                              primaryText={trl[brand.value] || brand.value}
                              style={{ fontSize: theme.font.fontSize }}
                            />
                          )}
                      </SelectField>
                    </InputLabelWrapper>
                  </div>
                  <TableRow margin={'10px 0'}>
                    <ButtonWrapper>
                      <SC.ButtonPA_M
                        onClick={this.queryData}>
                        {trl.GM_option__filter_btn_Apply_filters}
                      </SC.ButtonPA_M>
                      <SC.ButtonR_M
                        onClick={this.handleReset}>{trl.GM_option__filter_btn_Reset_filters}</SC.ButtonR_M>
                    </ButtonWrapper>
                  </TableRow>
                  <TableRow align={'flex-end'} margin={'10px 0'}>
                    <SC.ButtonS_S
                      onClick={this.handleOpenExportExcel}>{trl.GM_option_ExportToExcel}</SC.ButtonS_S>
                    {xlsx !== null && (
                      <a
                        target="_blank"
                        style={{ display: 'none' }}
                        href={`/excel_export/${xlsx}`}
                        ref={exportXlsx => (this.exportXlsx = exportXlsx)}
                      />
                    )}
                  </TableRow>
                </FiltersWrapper>
              </BodyTable>
            </TableWrapper>
          </FilterAndTableWrapper>
        </SC.PageGutter>
        <SC.PageGutter>
          <SC.Sky grey>
          </SC.Sky>
        </SC.PageGutter>
        <SC.PageGutter>
          <div>
            {!isEqual(purchaseDetail, []) ? (
              <div>
                <div id="deliveryFollowUpTable">
                  <MnageGmOptionsTable
                    data={purchaseDetail}
                    handleOnToggle={row => this.handleOnToggle(row)}
                    className="OrdersTable"
                    handelSortable={this.handelSortable}
                    handleFileIntegrationContact={this.handleFileIntegrationContact}
                    fileIntegrationContacts={fileIntegrationContacts}
                    handleChangeFileIntegrationContactProps={this.handleChangeFileIntegrationContactProps}
                  />
                </div>
              </div>
            ) : (
              <h2 style={{ textAlign: 'center', marginTop: 80 }}>{trl.manageGmOption_error__noElementToDisplay}.</h2>
            )}
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ auth, apiCallStateManager, me, options, appAlerts }) {
  return {
    role: auth.user_role,
    apiCallStateManager,
    isLoading: options.isLoading,
    iDSociete: me.iD_Societe,
    purchaseDetail: options.purchaseDetail,
    purchaseFilter: options.purchaseFilter,
    xlsx: options.xlsx,
    isLoadingGlobal: appAlerts.loading && appAlerts.loading.isLoading
  }
}

ManageGMOptions.propTypes = {
  gmOptions: PropTypes.array,
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired
}
ManageGMOptions.defaultProps = {
  gmOptions: {}
}
export default connect(
  mapStateToProps,
  { apiPutGmOption, apiGetGmOptions, apiGmOptionExport }
)(ManageGMOptions)