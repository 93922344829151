////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import { apiPutProductionFollowUpResetOP, apiPutProductionFollowUpCloseOP } from '../../../actions/productionFollowUp'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem'
import theme from '../../styledComponents/theme'
import * as SC from '../../styledComponents'
import { hashHistory } from 'react-router'
import Divider from 'material-ui/Divider'
import * as c from '../../../constants'
/////////ASSETS/////////
import Cancel from 'material-ui/svg-icons/navigation/cancel'
import ViewDetail from 'material-ui/svg-icons/action/pageview'

class ProductionFollowUpActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openReinitOpModal: false,
      openShutOpModal: false,
      isModalOpened: false
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpenReinitOpModal = () => {
    this.setState({ openReinitOpModal: true, isModalOpened: true })
  }

  handleCloseReinitOpModal = () => {
    this.setState({ openReinitOpModal: false, isModalOpened: false })
  }

  handleReinitOp = () => {
    const { row, apiPutProductionFollowUpResetOP } = this.props
    apiPutProductionFollowUpResetOP({ idOp: row.iD_Commande_Article })
    this.setState({ openReinitOpModal: false })
  }

  handleOpenShutOpModal = () => {
    this.setState({ openShutOpModal: true, isModalOpened: true })
  }
  handleCloseShutOpModal = () => {
    this.setState({ openShutOpModal: false, isModalOpened: false })
  }

  handleCloseOp = () => {
    const { row, apiPutProductionFollowUpCloseOP } = this.props
    apiPutProductionFollowUpCloseOP({ idOp: row.iD_Commande_Article })
    this.setState({ openShutOpModal: false })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl, user_role } = this.props
    let reinitAuthorized = false
    if (
      user_role[0] === 'LPAdmin' ||
      user_role[0] === 'LSAdmin' ||
      user_role[0] === 'CSS' ||
      user_role[0] === 'CSP' ||
      user_role[0] === 'ADMGlobal'
    ) {
      reinitAuthorized = true
    }
    const actionsCancel = [
      <SC.ButtonR_S style={{ margin: '0 20px' }} onClick={this.handleCloseReinitOpModal}>
        {trl.common__cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleReinitOp}>
        {trl.profu_action_reinitOP_modal_btn_OK}
      </SC.ButtonPA_S>
    ]

    const actionsCancelClose = [
      <SC.ButtonR_S style={{ margin: '0 20px' }} onClick={this.handleCloseShutOpModal}>
        {trl.common__cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleCloseOp}>
        {trl.profu_table_actions__closeOp || trl.common__ok}
      </SC.ButtonPA_S>
    ]

    return (
      <div>
        <ActionHelper isModalOpened={this.state.isModalOpened}>
          <div>
            <MenuItem
              style={{ fontSize: theme.font.fontSize }}
              primaryText={trl.profu_tableAction_viewOrderDetail}
              leftIcon={<ViewDetail />}
              onClick={() => hashHistory.push(`${c.orderDetail}${row.iD_Commande}`)}
            />
            { row.canBeReinitOP && reinitAuthorized &&
              <MenuItem
                style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.profu_tableAction_reinitOP}
                leftIcon={<Cancel />}
                onClick={() => this.handleOpenReinitOpModal()}
              />
            }
            { row.canClosePlannedOrder &&
              <MenuItem
                style={{ fontSize: theme.font.fontSize }}
                primaryText={trl.profu_tableAction_closePlannedOrder}
                leftIcon={<Cancel />}
                onClick={() => this.handleOpenShutOpModal()}
              />
            }
            { row.canClosePlannedOrder &&
              <div>
                <Divider style={{ height: '1.5px' }} />
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  primaryText={trl.profu_tableAction_closePlannedOrder}
                  leftIcon={<Cancel />}
                  onClick={() => this.handleOpenShutOpModal()}
                />
              </div>
            }
          </div>
        </ActionHelper>
        <Dialog
          title={trl.profu_action_reinitOP_modal_title}
          actions={actionsCancel}
          modal={false}
          open={this.state.openReinitOpModal}
        >
          <p>{trl.profu_action_reinitOP_modal_body}</p>
        </Dialog>
        <Dialog
          title={trl.profu_action_closeOP_modal_title}
          actions={actionsCancelClose}
          modal={false}
          open={this.state.openShutOpModal}
        >

          <p>{trl.profu_table_actions__confirmCloseOp}</p>
        </Dialog>
      </div>
    )
  }
}

ProductionFollowUpActions.contextTypes = {
  store: React.PropTypes.object.isRequired
}

ProductionFollowUpActions.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ({ languages, me, delivery }) => ({
  trl: languages.siteLanguage.keys,
  user_role: me.role,
  additional: delivery.additional,
  errors: delivery.errors
})
export default connect(
  mapStateToProps,
  { apiPutProductionFollowUpResetOP, apiPutProductionFollowUpCloseOP }
)(ProductionFollowUpActions)
