import * as c from '../constants'

const INITIAL_STATE = {
  tableList: {},
  isLoading: true,
  errors: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_DASHBOARD_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_GET_DASHBOARD_FAILURE:
      return { ...state, isLoading: false }
    case c.API_GET_DASHBOARD_SUCCESS: {
      return { ...state, tableList: action.payload, isLoading: false, errors: {} }
    }
    default:
      break
  }
  return state
}
