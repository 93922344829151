////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem';
import { apiUpdateCountryLabellist } from '../../../actions/countries'
import theme from '../../styledComponents/theme';

class CountryLabellistActions extends Component {

 	///////////////////////////////// HANDLE ///////////////////////////////////////

  SetLabellistForCountry(country_alpha_2_code, iD_Labelliste) {
    const model = {
      country_alpha_2_code: country_alpha_2_code,
      iD_Labelliste: iD_Labelliste
    }
    return this.props.apiUpdateCountryLabellist(model)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const row = this.props.row
    return (
      <ActionHelper>
        {row.companies &&
          row.companies.map(c => (
            <MenuItem
              style={{ fontSize: theme.font.fontSize }}
              key={c.iD_Societe}
              primaryText={c.name}
              onClick={() => this.SetLabellistForCountry(row.country_alpha_2_code, c.iD_Societe)}
            />
          ))}
      </ActionHelper>
    )
  }
}

CountryLabellistActions.propTypes = {
  row: PropTypes.object.isRequired
}

export default connect(null, { apiUpdateCountryLabellist })(CountryLabellistActions)
