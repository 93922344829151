////////LIBRARY/////////
import { injectGlobal } from 'styled-components'
///////COMPONENTS///////
import theme from './theme'
import { fontFaceHelper } from '../../utils/fontFaceHelper'
const { font, colors } = theme

/* Global Styles */
// eslint-disable-next-line
injectGlobal`
  ${fontFaceHelper(font.fontFamilyName, font.normal)}
  ${fontFaceHelper(font.fontFamilyName, font.italic, 'normal', 'italic')}
  ${fontFaceHelper(font.fontFamilyName, font.bold, 'bold')}
  ${fontFaceHelper(font.fontFamilyName, font.boldItalic, 'bold', 'italic')}
  /*${fontFaceHelper('wash_care_symbols', 'wash_care_symbols')}*/
  ${fontFaceHelper('Ginetex-Symbols', 'Ginetex-Symbols', 'normal', 'normal', true)}

  html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
  }
  body {
    font-family: ${font.fontFamilyName},sans-serif !important;
    margin: 0;
    color: ${colors.black};
    word-wrap: break-word;
    text-overflow: ellipsis;
    font-smoothing: auto;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    font-size: 12px;
  }
  a {
    color: ${colors.black};
    text-decoration: none;
  }
  .orders - follow - up - table th: nth - child(2),
  .order - detail - follow - up - table th: nth - child(2) {
    min - width: 170px;
  }
  /* Fix for sematable - search results number selector cross-browser problem */
  div select.form - control.sema - field{
    padding: 0;
  }
  // delete border on top th sematable
  .table th {
    background: ${colors.black};
    color: ${colors.white};
  }
  .table - sm tr {
    border - left: 1px solid ${colors.black};
  }
  .orderArticleNestedFollowUpTable.table th {
    background: ${colors.darkGrey};
    color: ${colors.white};
  }
  .app {
    margin: 57px 0px 15px 0px;
  }
  .app--overlay{
    position: fixed;
    background-color: ${theme.colors.black};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    transition: opacity cubic-bezier(0.23,1,0.32,1) 400ms;
    z-index: 1200;
  }
  .app--overlay-open{
    z-index: 1200;
    opacity: 0.54;
  }
  .app--overlay-closed {
    z-index: -10000;
    opacity: 0;
  }
  .h2--centered{
    text-align: center;
    line-height: 1.7em;
  }
  table {
    overflow: auto !important;
    th {
      font-size: 12px;
    }
    td {
      font-size: 12px;
    }
  }

  {/* //////// SEMATABLE /////////*/}

  .table-sm.table th {
    white-space: nowrap;
    color: rgb(158, 158, 158);
    height: 56px;
    font-size: 12px;
    vertical-align: middle;
    text-transform: uppercase;
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
  }
  .table-sm td {
    height: 48px;
    font-size: 12px;
    vertical-align: middle !important;
    color: rgba(0, 0, 0, 0.87);
    & > * {
      vertical-align : middle;
    }
  }
  .table-sm tr {
    /*border-bottom: 1px solid rgb(224, 224, 224);*/
  }
  .table-sm.table-striped tbody tr:nth-of-type(odd) {
      background-color: white;
  }
  .table-sm.table-hover tbody tr:hover {
      background-color: rgba(0, 0, 0, 0.075);
  }
  .table-sm [data-key="phoneNumber"] {
    min-width: 111px;
  }
  .table-sm svg path{
    fill: rgba(0, 0, 0, 0.87);
  }
  .table-sm.table th[data-key="date_Created"],
  .table-sm.table th[data-key="date_Updated"],
  .table-sm.table th[data-key="date_Expedition_Souhaitee"]{
    min-width: 65px;
  }
  {/* ////////END  SEMATABLE /////////*/}

  {/* ////////TABLES ////// */}
  .OrdersTable {
    margin-top: 35px;
    &:before {
      content: '';
      height: 35px;
      position: absolute;
      top: 0px;
      left: 10px;
      right: 10px;
      background-color: ${theme.colors.darkGrey};
    }
  }
  .orderArticleNestedFollowUpTable {
    th {
      background-color: ${theme.colors.skyBlue} !important;
      border-bottom: 1px solid ${theme.colors.black} !important;
      color: ${theme.colors.black} !important;
      text-align: center;
    }
    tr{
      background-color: ${theme.colors.skyBlue} !important;
      border: 0 !important;
    }
    td {
      border: 0 !important;
      text-align: center;
    }
  }
  .order-detail-follow-up-table {
    th, td {
      text-align: center;
      font-size: 12px;
    }
  }
  .OrdersTable, .order-confirm-table {
    th, td {
      text-align: center;
      vertical-align: middle !important;
    }
    th {
      background-color: ${theme.colors.white} !important;
      color: ${theme.colors.black} !important;
    }
  }
  svg.red-error path{
    fill: ${theme.colors.red};
  }
  svg.yellow-warning path{
    fill:#FFC107;
  }
  .errorGifs {
    max-width: 400px;
  }
  .dual-info-display {
    margin: 40px 0;
    text-align: center;
  }
  .article-image-col {
    max-width:380px !important;
  }
  .user-create-form{
    padding:30px;
    max-width: 550px;
    margin:0 auto;
  }
  .edit-language{
      padding:30px;
  }
  /* Override Material UI */
  .step-button svg {
    fill: ${theme.colors.white} !important;
  }
  .step-button svg text {
    fill: ${theme.colors.black} !important;
  }

  {/*//////////////////// LEFT DRAWER ///////////////////*/}
  .left-drawer svg path{
    fill:rgba(0, 0, 0, 0.65) !important;
  }
  .left-drawer-items,
  .left-drawer-items a,
  .left-drawer-items a:hover,
  .left-drawer-items a:active,
  .left-drawer-items a:visited,
  .left-drawer-items .nestedMenu{
    font-weight: 300;
    word-wrap: break-word;
    font-family: ${font.fontFamilyName}, "Helvetica Neue", sans-serif  !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)  !important;
    font-size: 16px  !important;
    font-weight: inherit  !important;
    color: rgba(0, 0, 0, 0.87)  !important;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms  !important;
    min-height: 48px  !important;
    white-space: nowrap  !important;
  }
  .left-drawer-items .leftMenu > div,
  .left-drawer-items .nestedMenu + div,
  .left-drawer-items .nestedMenu{
    padding:0 !important;
  }
  .leftMenu {
    text-align: right;
    font-size: 16px !important;
  }

  {/*//////////////////// global style fix ///////////////////*/}

  .table .fa-arrows-v{
    display: none !important;
  }
  .table>thead>tr>td, .table>thead>tr>th{
    text-align: center;
  }
  .table>tbody>tr>td{
    border: none;
    border-top: none  !important;
    text-align: center;
  }
  .normalLabel label {
    font-weight: normal;
  }
  .normalLabelValue {
    position:absolute;
    right: -40px;
    top: 2px;
    font-weight: bold;
  }
  .table>thead>tr>th {
    border-bottom-color: #000 !important;
  }
  .SelectFieldTextRight>div>div {
    width: 100%;
    text-align: right;
  }
  .SelectFieldTextRight  label {
    width: 100%;
    text-align: right;
  }
  button:disabled {opacity:.3}
  .deliveryRadioButton {
  }
  .deliveryRadioButton .deliveryRadio {
    min-width: 340px;
  }
  .deliveryRadioButton .deliveryRadio > div {
    margin-top: 12px;
    font-size: 12px
  }
  .table.table-borderBottom {
    border-bottom: 2px Solid black;
  }
  .deliveryRadioButton{
    overflow:hidden;
    clear:both;
    width:100%
  }
  .col1Delivery {
    float : left;
    min-width:550px;
  }
  .col2Delivery {
    width: 40%;
    float : left;
  }
  select.form-control.sema-field {
    padding:0 0 0 4px;
  }
  .formElementLabel {float:left; min-width:280px;     font-weight: bold;
    font-size: 12px;}
  .tissu .formElementLabel {min-width:160px}
  .formElementGrid {    
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
  }
  .mb12 { margin-bottom: 12px; }
  .mt12 { margin-top: 12px; }
  .caresymbol, .price { float:left;clear: none !important; }
  .rootAzetecform .show { clear: both;     margin-top 40px;}
  .editSk .rootAzetecform .show { clear: both;     margin-top: 20px;}
  .clear { clear: both;  width: 100% }
  .matiere {float:left ;    clear: right !important;}
  .tissu {float:none;clear: both; width: 100%; }
  .tissu:not(:first-child) { padding-top: 40px; }
  .matiere_percent { float:left;clear: left !important;      margin:0px 20px 0px 161px; }
  .matiere_percent .formElementText {
    width:80px !important;
    margin: 0px;
    margin-right: 10px;
  }
  .formElementText{font-size: 12px;}
  .caresymbol .formElementSelect { max-width: 100px;margin-right: 14px;}
  .caresymbol .formElementSelect > div+div { background-color: rgba(0, 0, 0, 0.5) !important; text-indent: 10px; }
  .caresymbol_1 .formElementSelect > div+div{ background-color: #50aa36 !important;}
  .caresymbol_2 .formElementSelect > div+div{ background-color: #f7b133 !important;}
  .caresymbol_3 .formElementSelect > div+div{ background-color: #e84e2e !important;}
  .caresymbol_4 .formElementSelect > div+div{ background-color: #a3335b !important;}
  .caresymbol_5 .formElementSelect > div+div{ background-color: #1e70b7 !important;}
  .price .formElementText, .price .formElementSelect{ max-width: 100px;margin-right: 14px;}
  .price + .price .formElementLabel{ display:none}
  .caresymbol .formElementLabel{ display:none}
  .rootAzetecform .caresymbol_1 .formElementLabel{ display:block}
  .rootAzetecform .radio .formElementLabel+div, .rootAzetecform .checkbox .formElementLabel+div{ width: 100%}
  .careinstruction+.careinstruction .formElementLabel, .rootAzetecform .checkbox.hiddenLabel .formElementLabel{visibility: hidden;}
  .careinstruction+.careinstruction {    margin-top: 5px;}
  .caresymbol_5+div.show {    clear:left !important}
  .rootAzetecform .price+div.show:not(.price) {    margin-top: 120px !important;}
  .rootAzetecform .careinstruction:last-child {    margin-top: 20px;}
  .rootAzetecform .matiere,.rootAzetecform  .matiere_percent { margin-top: 20px; }
  .rootAzetecform .matiere:last-child,.rootAzetecform  .matiere_percent:last-child { margin-bottom: 20px; }
  .type_7 label, .isType9 label {display:none !important;}
  .type_7 .formElementLabel+div , .type_9 .formElementLabel+div {width:40px !important;}
  .isType9 {
    background-repeat: no-repeat;
    background-position: right center;
    padding: 32px 0;
    width: 118px !important;
    float: left;
    margin-right: 28px;
  }
  .isCertifLogo{
    background-repeat: no-repeat;
    background-position: 100px;
    padding: 24px 558px;
    margin-right: 7px;
    margin-bottom:5%;
    & > div > label {
      display:none !important;
    }
  }
  .certification_message, .certification_label{
    margin-top: 15px !important;
  }
  }
  .matiere_percent .formElementLabel, .matiere .formElementLabel{ display:none}
  .step-button{ background-color: #dddddd!important; }
  img.careSymboleImg {
    position: absolute;
    top: -36px;
    right: 50px;
    width: 30px;
  }
  .tableWrapperBorder {
    border: 1px solid black;
    margin-bottom: 20px;
  }
  .caresymbol {
    margin-top: 60px !important;
  }
  .tableLigneGreen {background-color: #e5f1d4 !important;}
  .tableLigneGrey {background-color: #dddddd !important;}
  .tableLigneGreyDarker {background-color: #9e9e9e !important;}
  .tableLigneRed {background-color: #faddce !important;}
  .tableLigneBlue {background-color: ${theme.colors.skyBlue} !important;}
  #deliveryFollowUpTable .row .col-md-6:first-child {
    float: right;
    margin-left: 50%;
    text-align: right;
  }
  .uppercase {    text-transform: uppercase;  }
  .rootAzetecform .price.show {
    margin-bottom: 30px;
  }
  #articleVar .step-button {cursor: auto !important}
  .noPointer th { cursor: initial !important; }
  .centerAllItem,.centerAllItem th, .centerAllItem th  { text-align: center; }
  .centerAllItem td > div  {     display: inline-block !important; }
  .saveandprint .table-bordered>tbody>tr>td,.saveandprint .table-bordered {
    border: 1px solid #000;
  }
  .tableBox1, .tableBox2, .tableBox3, .tableBox4, .tableBox5 {
    border: none !important;
  }
  .tableBox1 tr td:first-child {
    border-left: none !important;
  }
  .tableBox1 tr td:last-child {
    border-right: none !important;
  }
  .tableBox2 tr td:first-child {
    border-left: 2px solid #000 !important;
  }
  .batRAboxWrapper {
    display: flex;
    justify-content: space-between;
    max-width: 94%;
    align-items: center;
  }
  .batRAbox {
    min-width: 300px;
    border: 2px solid red;
  }
  .batRAboxNavigation {
    width: 150px;
    display: flex;
    height: 40px;
    justify-content: space-between;
  }
  .dashTable {
    flex-basis: 48%;
    overflow: auto;
    margin-bottom: 40px;
  }
  .dashBoardTablesWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .dashTableTitle {
    line-height: 0.5;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    height 30px;
    padding-top: 5px;
  }
  .dashTableTitle span {
    display: inline-block;
    position: relative;
    height: 40px;
  }
  .dashTableTitle span:before,
  .dashTableTitle span:after {
    content: "";
    position: absolute;
    height: 5px;
    border-top: 2px solid #000;
    top: 4px;
    width: 1100px;
  }
  .dashTableTitle span:before {
    right: 100%;
    margin-right: 15px;
  }
  .dashTableTitle span:after {
    left: 100%;
    margin-left: 15px;
  }
  .hideThisst+span {display:none !important; }

  table.font12 td { font-size: 12px; }

  button{border: 0;}
  table.table-striped.table-hover .lbSgfr{font-size:12px;}
  form.sc-cJSrbW.lhUFdx div:first-child{margin-left: 150px;}
  `
