////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import { translate } from '../../../containers/HOC/Translation'

const YesNoText = ({ children }) => {

	///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <span>
      {children ? translate('common__yes') : translate('common__no')}
    </span>
  )
}

YesNoText.propTypes = {
  children: PropTypes.bool
}

export default YesNoText
