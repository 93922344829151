////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'
import { renderInput } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import { fetchRoles } from '../../../actions/roles'
import { removeUserFromState, createUser } from '../../../actions/users'
import { apiGetCompanies } from '../../../actions/companies'
import { apiGetCountries } from '../../../actions/countries'
import * as c from '../../../constants'
import { PageHeader } from '../../../components/sharedComponents'
import { convertPhoneNumbePrefixToNumber, phoneNumbePrefixValidationFunction } from '../misc/phoneNumbePrefixValidationFunction'
import normalizePhoneNumberPrefix from '../misc/normalizePhoneNumberPrefix'
import stringformat from '../../../utils/stringformat'
import * as SC from '../../../components/styledComponents'

const validate = (values, props) => {
  let errors = {}
  const { trl, role } = props
  if (!values.firstName) errors.firstName = trl.common__firstname_required_error || ' '
  if (!values.lastName) errors.lastName = trl.common__lastname_required_error || ' '
  if (!values.email) errors.email = trl.common__email_required_error || ' '
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = trl.common__email_invalid_error || ' '
  if (!values.iD_Societe && (role === c.ADMGlobal || role === c.LPAdmin || role === c.LSAdmin)) errors.iD_Societe = trl.common__company_required_error || ' '
  if (values.phoneNumbePrefix) {
    errors.phoneNumbePrefix = trl.common__phone_prefix_required_error || ' '
    if (values.phoneNumbePrefix.toString().length > 5) errors.phoneNumbePrefix = stringformat(trl.common__max_length_error, 5) || ' '
    // this is to check that the phone prefix is valid.
    else if (phoneNumbePrefixValidationFunction(values.phoneNumbePrefix, props.countries)) errors.phoneNumbePrefix = trl.common__phone_prefix_invalid_error || ' '
  }
  return errors
}

class UserCreate extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.removeUserFromState()
    this.props.apiGetCountries()
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(formValues) {
    let { firstName, lastName, email, phoneNumber, phoneNumbePrefix, /* iD_Societe */ } = formValues    
    const { createUser } = this.props
    // me, role, roles, companies,
    // packaging it all up in a model for the api...
    const userModel = {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneNumbePrefix: phoneNumbePrefix && convertPhoneNumbePrefixToNumber(phoneNumbePrefix),
      // iD_Societe: myCompanyId,
      // role: [roleSelected]
    }
    return createUser(userModel)
  }

 	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { pristine, handleSubmit, submitting, error, submitSucceeded, trl } = this.props

    return (
      <div>
        <PageHeader title={trl.admin_user_create__title} subtitle={trl.admin_user_create__subtitle} />
        <div className="user-create-form">
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field name="firstName" type="text" component={renderInput} label={trl.common__firstname} />
            <Field name="lastName" type="text" component={renderInput} label={trl.common__lastname} />
            <Field name="email" type="email" component={renderInput} label={trl.common__email} />
            <div style={{ display: 'flex', alignItems: 'top' }}>
              <Field
                name="phoneNumbePrefix"
                type="number"
                component={renderInput}
                label={trl.common__phone_prefix}
                normalize={normalizePhoneNumberPrefix}
                fullWidth={false}
                style={{ width: 120, marginRight: 20 }}
              />
              <Field
                name="phoneNumber"
                component={renderInput}
                label={trl.common__phone}
                type="number"
                fullWidth={false}
                style={{ flexGrow: 2 }}
              />
            </div>
            {error && <p style={styles.loginError}>{error}</p>}
            <div>
              {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
              <SC.ButtonPA_L
                style={{
                  ...styles.loginBtn,
                }}
                disabled={submitting || pristine || (submitSucceeded && pristine)}
              >
                {trl.admin_user_create__create_btn}
              </SC.ButtonPA_L>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

UserCreate.propTypes = {
  trl: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  companies: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  removeUserFromState: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  apiGetCompanies: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired
}

UserCreate.contextTypes = {
  store: React.PropTypes.object.isRequired
}

function mapStateToProps({ users, roles, companies, auth, me, countries }) {
  return {
    roles: roles.roles,
    users: users.users,
    companies,
    countries: countries.countries,
    role: auth.user_role,
    me
  }
}

UserCreate = reduxForm({
  form: 'UserCreate',
  validate
})(UserCreate)

export default connect(mapStateToProps, {
  createUser,
  removeUserFromState,
  fetchRoles,
  apiGetCompanies,
  apiGetCountries
})(UserCreate)
