import React from 'react'
import { Route, IndexRoute } from 'react-router'
import App from '../containers/App'
import AppNotLoggedIn from '../components/AppNotLoggedIn'
import ArticleFixe from '../containers/ArticleFixe'
import ArticleVariable from '../containers/ArticleVariable'
import Catalogue from '../components/Catalogue'
import CGV from '../components/CGV'
import ClothesComposition from '../containers/Forms/ClothesComposition'
import ConfirmEmail from '../containers/Forms/ConfirmEmail'
import Confidentiality from '../components/Confidentiality'
import ConfirmOrder from '../containers/Forms/ConfirmOrder'
import ConfirmOrderSuccess from '../containers/ConfirmOrderSuccess'
import ConfirmOrderMessage from '../containers/ConfirmOrderSuccess/ConfirmOrderMessage'
import ConfirmOrderFailure from '../containers/ConfirmOrderSuccess/ConfirmOrderFailure'
import Contact from '../components/Contact'
import DeliveryNote from '../components/DeliveryNote'
import deliveryAddress from '../components/DeliveryAddress'
import ForgotPassword from '../containers/Forms/ForgotPassword'
import FollowUp from '../containers/FollowUp'
import BATPending from '../containers/BATPending'
import BATView from '../containers/BATPending/BATView'
import BatHistory from '../containers/BatHistory'
import Invoices from '../components/Invoices'
import Home from '../components/Home'
import LanguageList from '../containers/LanguageList'
import LanguageEdit from '../containers/LanguageEdit'
import LanguageCreate from '../containers/Forms/LanguageCreate'
import Login from '../containers/Forms/Login'
import LogList from '../containers/LogList'
import MaintenancePage from '../containers/misc/MaintenancePage'
import NotFound from '../components/misc/NotFound'
import MyAccount from '../containers/Forms/MyAccount'
import MyDocumentation from '../containers/MyDocumentation'
import MyDocumentationSub from '../containers/MyDocumentation/MyDocumentationSub'
import OrderDeliveryDetails from '../containers/OrderDeliveryDetails'
import OrderDetail from '../containers/OrderDetail'
import OrdersList from '../containers/OrdersList'
import PlaceOrder from '../components/PlaceOrder'
import ProformaInvoices from '../components/ProformaInvoices'
import RestrictPage from '../containers/misc/RestrictPage'
import RequestAccount from '../containers/Forms/RequestAccount'
import ShippingInfo from '../components/ShippingInfo'
import UserEdit from '../containers/Forms/UserEdit'
import UserCreate from '../containers/Forms/UserCreate'
import UserList from '../containers/UserList'
import RoleList from '../containers/RoleList'
import alertMessages from '../containers/HOC/RenderMessages'
import translation from '../containers/HOC/Translation'
import restrictPerRole from '../containers/HOC/RestrictPerRole'
import CountryLabellistsList from '../containers/CountryLabellistsList'
import BrandList from '../containers/BrandList'
import Stats from '../containers/Stats'
import ManagePrintshops from '../containers/ManagePrintshops'
import BrandEdit from '../containers/Forms/BrandEdit'
import BrandEditFileFormats from '../containers/BrandEditFileFormats'
import AddItem from '../containers/AddItem'
import SelectBrand from '../containers/SelectBrand'
import DeliveryFollowUp from '../containers/DeliveryFollowUp'
import WaitingForDelivery from '../containers/WaitingForDelivery'
import DeliveryDetail from '../containers/DeliveryDetail'
import PackingList from '../containers/PackingList'
import PreparationSheet from '../containers/PreparationSheet'
import PrintPackingList from '../containers/PrintPackingList'
import MyCarriers from '../containers/MyCarriers'
import myAddresses from '../containers/MyAddresses'
import ManageGMOptions from '../containers/ManageGMOptions'
import ShipmentHistory from '../containers/ShipmentHistory'
import ProductCatalog from '../containers/ProductCatalog'
import ProductionFollowUp from '../containers/ProductFollowUp'
import StockFollowUp from '../containers/StockFollowUp'
import ItemFollowUp from '../containers/ItemFollowUp/ItemFollowUp'
import FileOrderFollowUp from '../containers/FileOrderFollowUp'
import PartnershipsManagement from '../containers/PartnershipsManagement'
import * as c from '../constants'

export default (
  <Route>
    <Route component={translation(AppNotLoggedIn)}>
      <Route path={c.login} component={alertMessages(Login)} />
      <Route path={c.requestAccount} component={RequestAccount} />
      <Route path={c.forgotPassword} component={ForgotPassword} />
      <Route path={c.userConfirm} component={ConfirmEmail} />
      <Route path={c.resetPassword} component={ConfirmEmail} />
      <Route path={c.maintenance} component={MaintenancePage} />
    </Route>
    <Route component={RestrictPage}>
      <Route path={c.index} component={translation(App)}>
        <IndexRoute component={alertMessages(Home)} />
        <Route path={c.users} component={restrictPerRole(alertMessages(UserList))} />
        <Route
          path={c.partnershipsManagement}
          component={restrictPerRole(alertMessages(PartnershipsManagement))}
          exact={true}
        />
        <Route path={c.userEdit + ':id'} component={restrictPerRole(alertMessages(UserEdit))} />
        <Route path={c.userCreate} component={restrictPerRole(UserCreate)} />
        <Route path={c.roles} component={restrictPerRole(alertMessages(RoleList))} />
        <Route path={c.logs} component={restrictPerRole(alertMessages(LogList))} />
        <Route path={c.languages} component={restrictPerRole(alertMessages(LanguageList))} exact={true} />
        <Route path={c.languageCreate} component={restrictPerRole(alertMessages(LanguageCreate))} />
        <Route path={`${c.languageEdit}:id`} component={restrictPerRole(alertMessages(LanguageEdit))} />
        <Route path={c.countriesLabellists} component={restrictPerRole(CountryLabellistsList)} />
        <Route path={c.brands} component={restrictPerRole(alertMessages(BrandList))} />
        <Route path={c.stats} component={restrictPerRole(alertMessages(Stats))} />
        <Route path={c.managePrintshops} component={restrictPerRole(alertMessages(ManagePrintshops))} />
        <Route path={`${c.brandEdit}:id`} component={restrictPerRole(alertMessages(BrandEdit))} />
        <Route path={`${c.brandEditFileFormats}:id`} component={restrictPerRole(alertMessages(BrandEditFileFormats))} />
        <Route path={c.orderNew} component={restrictPerRole(alertMessages(SelectBrand))} />
        <Route path={c.ordersList} component={restrictPerRole(alertMessages(OrdersList))} />
        <Route path={`${c.orderDetail}:id`} component={restrictPerRole(alertMessages(OrderDetail))} />
        <Route
          path={`${c.orderDetail}:orderId${c.orderDeliveryDetails}`}
          component={restrictPerRole(alertMessages(OrderDeliveryDetails))}
          exact={true}
        />
        <Route
          path={`${c.orderDetail}:orderId${c.orderAddItem}`}
          component={restrictPerRole(alertMessages(AddItem))}
          exact={true}
        />
        <Route
          path={`${c.orderDetail}:orderId${c.confirmOrder}`}
          component={restrictPerRole(alertMessages(ConfirmOrder))}
          exact={true}
        />
        <Route
          path={`${c.orderDetail}:orderId${c.confirmOrderSuccess}`}
          component={restrictPerRole(ConfirmOrderSuccess)}
          exact={true}
        />
        <Route path={`${c.orderDetail}:orderId${c.confirmOrderMessage}`} component={ConfirmOrderMessage} exact={true} />
        <Route
          path={`${c.orderDetail}:orderId${c.confirmOrderFailure}`}
          component={restrictPerRole(ConfirmOrderFailure)}
          exact={true}
        />
        <Route
          path={`${c.orderDetail}:orderId${c.articleFixe}:idCommandeArticle`}
          component={restrictPerRole(alertMessages(ArticleFixe))}
          exact={true}
        />
        <Route
          path={`${c.orderDetail}:orderId${c.articleVariable}:idCommandeArticle`}
          component={restrictPerRole(alertMessages(ArticleVariable))}
          exact={true}
        />
        <Route
          path={`${c.orderDetail}:orderId${c.articleVariable}:idCommandeArticle${c.composition}`}
          component={restrictPerRole(ClothesComposition)}
          exact={true}
        />
        /
        <Route
          path={`${c.orderDetail}:orderId${c.shipmentHistory}`}
          component={alertMessages(ShipmentHistory)}
          exact={true}
        />
        <Route path={`${c.company}:companyId${c.deliveryAddress}`} component={deliveryAddress} exact={true} />
        <Route path={`${c.followUp}`} component={FollowUp} exact={true} />
        {/* Dont add alertMessages for this line  */}
        <Route path={`${c.batPending}`} component={alertMessages(BATPending)} exact={true} />
        <Route path={`${c.batView}:idOrder/:idBat`} component={alertMessages(BATView)} exact={true} />
        <Route
          path={`${c.batView}:idOrder${c.commandeArticle}:iD_Commande_Article`}
          component={alertMessages(BATView)}
          exact={true}
        />
        <Route path={`${c.batHistory}:idOrder/:idOrderItem`} component={alertMessages(BatHistory)} exact={true} />
        <Route path={`${c.batHistory}:idOrder`} component={alertMessages(BatHistory)} exact={true} />
        <Route path={`${c.deliveriesFollowUp}`} component={alertMessages(DeliveryFollowUp)} exact={true} />
        <Route path={`${c.waitingForDelivery}`} component={alertMessages(WaitingForDelivery)} exact={true} />
        <Route path={`${c.delivery}:deliveryId`} component={alertMessages(DeliveryDetail)} exact={true} />
        <Route
          path={`${c.delivery}:deliveryId${c.packinglist}`}
          component={alertMessages(PackingList)}
          exact={true}
        />{' '}
        {/* Dont add to dont load all page when we updated box info */}
        <Route
          path={`${c.delivery}:deliveryId${c.packinglistSavePrint}`}
          component={alertMessages(PrintPackingList)}
          exact={true}
        />
        <Route path={`${c.delivery}:deliveryId${c.preparationSheet}`} component={PreparationSheet} exact={true} />
        <Route path={c.myCarriers} component={alertMessages(MyCarriers)} />
        <Route path={c.myAddresses} component={alertMessages(myAddresses)} />
        <Route path={c.manageGMOptions} component={alertMessages(ManageGMOptions)} exact={true} />
        <Route path={`${c.catalogue}`} component={Catalogue} exact={true} />
        <Route path={`${c.placeOrder}`} component={PlaceOrder} exact={true} />
        <Route path={`${c.documentation}${c.proformaInvoices}`} component={ProformaInvoices} exact={true} />
        <Route path={`${c.documentation}${c.deliveryNote}`} component={DeliveryNote} exact={true} />
        <Route path={`${c.documentation}${c.invoices}`} component={Invoices} exact={true} />
        <Route path={`${c.myAccount}${c.shippingInfo}`} component={ShippingInfo} exact={true} />
        <Route path={c.invoices} component={Invoices} exact={true} />
        <Route path={c.cgv} component={CGV} exact={true} />
        <Route path={c.confidentiality} component={Confidentiality} />
        <Route path={c.contact} component={Contact} />
        <Route path={c.myAccount} component={MyAccount} />
        <Route path={`${c.myDocumentation}/:idOrder`} component={alertMessages(MyDocumentationSub)} />
        <Route path={c.myDocumentation} component={alertMessages(MyDocumentation)} />
        <Route path={c.productCatalog} component={alertMessages(ProductCatalog)} />
        <Route path={c.productionFollowUp} component={alertMessages(ProductionFollowUp)} />
        <Route path={c.stockFollowUp} component={alertMessages(StockFollowUp)} />
        <Route path={c.itemFollowUp} component={alertMessages(ItemFollowUp)} />
        <Route path={c.fileOrderFollowUp} component={alertMessages(FileOrderFollowUp)} />
        <Route path={c.notFound} component={NotFound} />
      </Route>
    </Route>
    <Route path={c.notFound} component={translation(NotFound)} />
  </Route>
)
