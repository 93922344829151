////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import LanguagesActions from '../Actions/LanguagesActions'
import YesNo from '../ColumnHelpers/YesNo'
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'

const columns = [
  { key: 'languageid', header: 'ID', hidden: true, primaryKey: true },
  { key: 'name', header: translate('language_table_tableTitle__name') },
  { key: 'localName', header: translate('languages_table_tableTitle__localName') },
  { key: 'enabled', header: translate('languages_table_tableTitle__enabled'), Component: YesNo },
  { key: 'actions', header: translate('language_table_tableTitle__actions'), Component: LanguagesActions }
]

const config = {
  defaultPageSize: 5000000000,
  showPageSize: false,
  showFilter: false
}

class LanguagesTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <TableContainer>
        <Table {...this.props} columns={columns} NoDataComponent={NoDataComponent} />
      </TableContainer>
    )
  }
}

LanguagesTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

export default sematable('languagesTable', LanguagesTable, columns, config)
