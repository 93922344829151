import * as c from '../constants'

const INITIAL_STATE = {
  deliveriesWaiting: [],
  deliveriesWaitingFilters: {},
  isLoading: false,
  errors: {}
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP_FAILURE:
      return { ...state, isLoading: false }
    case c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP_SUCCESS:
      return { ...state, isLoading: false, errors: {} }
    case c.API_PUT_DELIVERIES_WAITING_FOLLOW_UP:
      return {
        ...state,
        deliveriesWaiting: action.payload.wfdDetails,
        deliveriesWaitingFilters: action.payload.wfdFilters
      }

    default:
      break
  }
  return state
}
