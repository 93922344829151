////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'
import CommentHover from '../ColumnHelpers/CommentHover'

const columns = [
  { key: 'idItem', primaryKey: true, hidden: true },
  { key: 'validateDate', header: translate( 'profu_modal_tableTitle_validationDate' ), Component: DateFormatter },
  { key: 'scheduledStart', header: translate( 'profu_modal_tableTitle_scheduledStartProd' ), Component: DateFormatter },
  { key: 'scheduledEnd', header: translate( 'profu_modal_tableTitle_scheduledStopProd' ), Component: DateFormatter },
  { key: 'ctC_Date_Confirmed', header: translate( 'profu_tableTitle_CTCDateConfirmed' ), Component: DateFormatter, sortable: true },
  { key: 'wastedQty', header: translate( 'profu_modal_tableTitle_wastedQty' ) },
  { key: 'quantityTest', header: translate( 'profu_modal_tableTitle_testedQty' ) },
  { key: 'operatorComment', header: translate( 'profu_modal_tableTitle_comment' ), Component: CommentHover }
]

const config = {
  defaultPageSize: false,
  showPageSize: false,
  showFilter: false
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

class ProductionFollowUpTableDetail extends Component {
  
	///////////////////////////////// RENDER ///////////////////////////////////////
  
  render() {
    const { data } = this.props
    return (
      <TableContainer
        style={ { border: '1px solid black', padding: 0, margin: '0' } }>
        <Table { ...this.props } data={ data } columns={ columns } NoDataComponent={ NoDataComponent } />
      </TableContainer>
    )
  }
}

ProductionFollowUpTableDetail.propTypes = propTypes

export default sematable( 'ProductionFollowUpTableDetail', ProductionFollowUpTableDetail, columns, config )
