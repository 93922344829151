///////COMPONENTS///////
import * as c from '../constants'
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET ITEM followUp
function apiGetItemFollowUpRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_ITEM_FOLLOW_UP_REQUEST, endpointPath })
  }
}
function apiGetItemFollowUpSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_ITEM_FOLLOW_UP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_ITEM_FOLLOW_UP,
      payload: payload
    })
  }
}
function apiGetItemFollowUpFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_ITEM_FOLLOW_UP_FAILURE, endpointPath })
  }
}
export function apiGetItemFollowUp(data) {
  return u.getValueFromStore(
    c.apiGetItemFollowUp,
    h.headerPutWithToken(data),
    apiGetItemFollowUpRequest,
    apiGetItemFollowUpSuccess,
    apiGetItemFollowUpFailure
  )
}

// GET ITEM followUp id
function apiGetItemFollowUpIdRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ITEM_FOLLOW_UP_ID_REQUEST, endpointPath })
  }
}
function apiGetItemFollowUpIdSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ITEM_FOLLOW_UP_ID_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_ITEM_FOLLOW_UP_ID,
      payload: payload
    })
  }
}
function apiGetItemFollowUpIdFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_ITEM_FOLLOW_UP_ID_FAILURE, endpointPath })
  }
}
export function apiGetItemFollowUpId(jpiOrderItem) {
  return u.getValueFromStore(
    `${c.apiGetItemFollowUpId}/${jpiOrderItem}`,
    h.headerGetWithToken(),
    apiGetItemFollowUpIdRequest,
    apiGetItemFollowUpIdSuccess,
    apiGetItemFollowUpIdFailure,
    null,
    { forceUpdate: true }
  )
}

// GET ITEM followUp export
function apiItemFollowUpExportRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_ITEM_FOLLOW_UP_REQUEST, endpointPath })
    dispatch(addAppShowLoading(true))
  }
}
function apiItemFollowUpExportSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_ITEM_FOLLOW_UP_SUCCESS, payload: res })
    dispatch(addAppHideLoading(true))
  }
}
function apiItemFollowUpExportFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_ITEM_FOLLOW_UP_FAILURE, endpointPath })
    dispatch(addAppHideLoading(true))
  }
}
export function apiItemFollowUpExport(params) {
  return u.getValueFromStore(
    `${c.apiGetItemFollowUpExport}`,
    h.headerPutWithToken(params),
    apiItemFollowUpExportRequest,
    apiItemFollowUpExportSuccess,
    apiItemFollowUpExportFailure,
    true
  )
}
