////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import CustomTable from '../../components/misc/CustomTable'
import WiredSelectField from '../../components/misc/WiredSelectField'
import {
  fetchTargetLanguage,
  fetchBaseLanguage,
  fetchLanguages,
  changeTargetLanguageRow,
  toggleLanguageLockout,
  deleteBaseAndTargetLanguages
} from '../../actions/languages'
import PageLoad from '../../components/PageLoad'
import SelectorLanguageMerge from '../../selectors/language_translation_merge'
import ToggleWithConfirm from '../../components/ToggleWithConfirm'
import { headerPutWithTokenAndModel } from '../../utils/headers'
import * as c from '../../constants'
import { checkRoleHasRightsToFunction } from '../misc/allowedRolesPerFunction'
import { PageHeader } from '../../components/sharedComponents'

class LanguageEdit extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const {
      routeParams,
      baseLanguage,
      targetLanguage,
      fetchTargetLanguage,
      fetchBaseLanguage,
      fetchLanguages
    } = this.props
    const targetLanguageId = routeParams.id
    // delete existant base and target languages if different than those requested
    // make api call to base and target languages if don't exist
    // default base language is english with id 1
    if (baseLanguage.id !== c.baseLanguageDefaultId) fetchBaseLanguage(c.baseLanguageDefaultId)
    if (targetLanguage.id !== targetLanguageId) fetchTargetLanguage(targetLanguageId)
    fetchLanguages()
  }

  componentWillUnmount() {
    this.props.deleteBaseAndTargetLanguages()
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  displayToggleWithConfirm() {
    const { targetLanguage, routeParams, toggleLanguageLockout, me, trl } = this.props
    // if LPAdmin, LSAdmin, or ADMGlobal
    if (me.role && checkRoleHasRightsToFunction(me.role[0], c.addLanguage)) {
      let disableToggle = true
      let labelExpanded = trl.admin_language_edit__activated
      // Default language cannot be disabled
      if (targetLanguage.languageid !== 1) disableToggle = false
      else labelExpanded = trl.admin_language_edit__activated_default

      const labelRetracted = trl.admin_language_edit__deactivated
      const confMsgToExpand = trl.admin_language_edit__confirm_activate
      const confMsgToRetract = trl.admin_language_edit__confirm_deactivate
      const userModel = { ...targetLanguage, enabled: !targetLanguage.enabled, keys: [] }
      const config = headerPutWithTokenAndModel(userModel)
      const apiCallToExpand = { url: `${c.apiLanguage}${routeParams.id}`, config }
      const apiCallToRetract = { url: `${c.apiLanguage}${routeParams.id}`, config }
      return (
        <div className="toggleWithConfirm">
          <ToggleWithConfirm
            expanded={targetLanguage.enabled}
            disabled={disableToggle}
            labelPosition="right"
            labelExpanded={labelExpanded}
            labelRetracted={labelRetracted}
            confMsgToExpand={confMsgToExpand}
            confMsgToRetract={confMsgToRetract}
            apiCallToExpand={apiCallToExpand}
            apiCallToRetract={apiCallToRetract}
            actionOnExpand={() => toggleLanguageLockout(routeParams.id)}
            actionOnRetract={() => toggleLanguageLockout(routeParams.id)}
          />
        </div>
      )
    }
    return null
  }

  fetchBaseLanguage = (id) => {
    this.props.fetchBaseLanguage(id);
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {
      targetLanguage,
      tableData,
      changeTargetLanguageRow,
      languages,
      baseLanguage,
      trl
    } = this.props

    if (!languages || !targetLanguage || !tableData || !tableData.length) {
      // TODO : better handling of no Table Data case
      return <PageLoad text={trl.common__loading} size={80} thickness={9}/>
    }
    
    return (
      <div>
        <PageHeader
          title={trl.admin_language_edit__title}
          subtitle={`${targetLanguage.name} (${targetLanguage.localName})`}
        />
        <div className="edit-language">
          {this.displayToggleWithConfirm()}
          <WiredSelectField
            fields={languages}
            floatingLabelText={trl.admin_language_edit__base_language}
            initialValue={baseLanguage ? baseLanguage.languageid : c.baseLanguageDefaultId}
            functionOnChange={[this.fetchBaseLanguage]}
            value={'languageid'}
            primaryText={'name'}
          />
          <CustomTable
            data={tableData}
            headerTitle={[
              trl.common__table_header_base_language,
              trl.common__table_header_key,
              trl.common__table_header_target_language
            ]}
            changeTargetLanguageRow={data => changeTargetLanguageRow(data)}
          />
        </div>
      </div>
    )
  }
}

LanguageEdit.propTypes = {
  baseLanguage: PropTypes.object.isRequired,
  targetLanguage: PropTypes.object.isRequired,
  tableData: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  trl: PropTypes.object.isRequired,
  fetchTargetLanguage: PropTypes.func.isRequired,
  fetchBaseLanguage: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  changeTargetLanguageRow: PropTypes.func.isRequired,
  toggleLanguageLockout: PropTypes.func.isRequired,
  deleteBaseAndTargetLanguages: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    me: state.me,
    languages: state.languages.languages,
    baseLanguage: state.languages.baseLanguage,
    targetLanguage: state.languages.targetLanguage,
    tableData: SelectorLanguageMerge(state)
  }
}

export default connect(mapStateToProps, {
  fetchTargetLanguage,
  fetchBaseLanguage,
  fetchLanguages,
  changeTargetLanguageRow,
  toggleLanguageLockout,
  deleteBaseAndTargetLanguages
})(LanguageEdit)
