////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cloneDeep, orderBy, isEmpty, isNull, isUndefined } from 'lodash'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem';
import PageLoad from '../../components/PageLoad'
import { PageHeader } from '../../components/sharedComponents'
import MyCarriersTable, { columns } from '../../components/Sematable/Tables/MyCarriersTable'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import { apiAddCarrier, apiEditCarrier,apiGetMyCarriersData } from '../../actions/myCarriers'

class MyCarriers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      myCarriers: { modes_Livraison:[], availableCarriers:[] },
      sortBy: null,
      lastSortBy: null,
      sortOrderBy: ['asc'],
      openAwbModal: false,
      formErrors: {
        transporteur_Name: false,
        service: false,
        iD_Client_External: false,
        carrier: false
      },
      carrier: {
        carrier: null,
        transporteur_Name: '',
        service: '',
        iD_Client_External: '',
        addMode: true
      }
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    if (this.props.iDSociete) {
      this.props.apiGetMyCarriersData({ iDSociete: this.props.iDSociete })    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.iDSociete !== this.props.iDSociete && nextProps.iDSociete) this.props.apiGetMyCarriersData({ iDSociete: nextProps.iDSociete })
    if (nextProps.myCarriers.modes_Livraison !== this.props.myCarriers.modes_Livraison) {
      const newData = nextProps.myCarriers.modes_Livraison
      const newDataList = nextProps.myCarriers.availableCarriers
      this.setState({ myCarriers: { modes_Livraison: newData, availableCarriers: newDataList } })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelSortable = index => {
    if (columns[index]) this.setState({ lastSortBy: this.state.sortBy, sortBy: index }, this.handleFilterTableItems)
  }

  handleFilterTableItems() {
    const { myCarriers } = this.state
    const { sortBy, lastSortBy, sortOrderBy } = this.state
    let articles = cloneDeep(myCarriers.modes_Livraison)
    let newSortByOrder = ['asc']
    if (sortBy < 3) {
      if (sortBy === lastSortBy) newSortByOrder = sortOrderBy[0] === 'asc' ? ['desc'] : ['asc']
      articles = orderBy(articles, columns[sortBy].key, newSortByOrder)
    }
    this.setState({ myCarriers: { modes_Livraison: articles }, sortOrderBy: newSortByOrder })
  }

  handleOpenAwb = (editRow = null) => {
    if (editRow) this.setState({ carrier: editRow, openAwbModal: true })
    else {
      this.setState({
        openAwbModal: true,
        carrier: {
          transporteur_Name: '',
          service: '',
          iD_Client_External: '',
          addMode: true
        }
      })
    }
  }

  handleCloseAwb = () => {
    this.setState({ openAwbModal: false, stateAwbEnter: false })
  }

  validateForm = (form) => {
    const { trl } = this.props
    const formToValidate = form
    const regCharExcluded = RegExp(/[&<>'"]/);
    let validate = true
    let formErrors = {
      transporteur_Name: false,
      service: false,
      iD_Client_External: false,
      carrier: false
    }
    if (regCharExcluded.test(formToValidate.transporteur_Name)) {
      validate = false
      formErrors.transporteur_Name = `${trl.common_error_unauthorizedCars}`
    }
    if (regCharExcluded.test(formToValidate.iD_Client_External)) {
      validate = false
      formErrors.iD_Client_External = `${trl.common_error_unauthorizedCars}`
    }
    if (isUndefined(formToValidate.carrier) || isNull(formToValidate.carrier)) {
      validate = false
      formErrors.carrier = `${trl.common__field_required}`
    }
    if (isEmpty(formToValidate.transporteur_Name)) {
      validate = false
      formErrors.transporteur_Name = `${trl.common__field_required}`
    }
    if (isEmpty(formToValidate.iD_Client_External)) {
      validate = false
      formErrors.iD_Client_External = `${trl.common__field_required}`
    }
    if (isEmpty(formToValidate.service)) {
      validate = false
      formErrors.service = `${trl.common__field_required}`
    }
    this.setState({
      formErrors: formErrors
    })
    return validate
  }

  handleConfirmAwb = () => {
    const { carrier } = this.state
    if (this.validateForm(carrier)) {
      this.setState({ openAwbModal: false })
      const model = {
        Transporteur_Name: carrier.transporteur_Name,
        Service: carrier.service,
        ID_Client_External: carrier.iD_Client_External,
        ID_Mode_Livraison: 0,
        ID_Societe: this.props.iDSociete,
        ID_Carrier:carrier.carrier
      }
      if (carrier.addMode) this.props.apiAddCarrier({ iDSociete: this.props.iDSociete, model }).then(()=>this.props.apiGetMyCarriersData({ iDSociete: this.props.iDSociete }))
      else {
        this.props.apiEditCarrier({
          IDSociete: this.props.iDSociete,
          Model: {
            ...model,
            ID_Mode_Livraison: carrier.iD_Mode_Livraison
          },
          IdCarrier: carrier.iD_Mode_Livraison
        }).then(()=>this.props.apiGetMyCarriersData({ iDSociete: this.props.iDSociete }))
      }
    }
    //setTimeout(() => {this.props.apiGetMyCarriersData({ iDSociete: this.props.iDSociete })}, 50)
  }

  handleChange(field, newValue) {
    this.setState({
      carrier: {
        ...this.state.carrier,
        [field]: newValue
      }
    }, () => {
      this.validateForm(this.state.carrier)
    })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading } = this.props
    const { carrier, myCarriers, formErrors } = this.state
    if(!carrier.addMode && carrier.carrier && carrier.carrier.id) {
      this.handleChange('carrier', carrier.carrier.id)
    }
    //cosnt columns
    if (isLoading) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    const actions = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={this.handleCloseAwb}>
        {trl.common__cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handleConfirmAwb}>
        {trl.common__ok}
      </SC.ButtonPA_S>
    ]
    return (
      <div>
        <PageHeader title={trl.mycarriers_Title} />
        {/* ORDERS TABLE */}
        <SC.PageGutter>
          <div>
            {myCarriers.modes_Livraison.length ? (
              <div>
                <div id="deliveryFollowUpTable">
                  <MyCarriersTable
                    data={myCarriers.modes_Livraison}
                    handleOpenEdit={this.handleOpenAwb}
                    className="OrdersTable"
                    handelSortable={this.handelSortable}
                  />
                </div>
              </div>
            ) : (
              <h2 style={{ textAlign: 'center', marginTop: 80, fontSize: '15px' }}>{'There is no Element to display.'}</h2>
            )}
            <div style={{ textAlign: 'center' }}>

              <SC.ButtonPA_M onClick={() => this.handleOpenAwb()}>
                {trl.mycarriers_btn_AddCarrier}
              </SC.ButtonPA_M>
            </div>
          </div>
        </SC.PageGutter>
        <Dialog
          title={carrier.addMode ? trl.mycarriers_modal_Title : trl.mycarriers_modal_EditTitle}
          actions={actions}
          modal={false}
          open={this.state.openAwbModal}
          onRequestClose={this.handleCloseAwb}
        >
          <div className="formElementGrid">
            <label className="formElementLabel">{trl.mycarriers_tableTitle_Label}</label>
            <TextField
              fullWidth={false}
              onChange={(event, newValue) => this.handleChange('transporteur_Name', newValue)}
              value={carrier.transporteur_Name}
              errorText={formErrors && formErrors.transporteur_Name}
              className="formElementText"
              inputStyle={{
                paddingLeft: '3%'
              }}
              style={{
                border: '1px solid rgb(206, 212, 218)',
                borderRadius: 4,
                fontSize: theme.font.fontSize,
                paddingRight: '3%'
              }}
              name="transporteur_Name"
              underlineStyle={{ display: 'none' }}
            />
          </div>
          <div>&nbsp;</div>
          <div className="formElementGrid">
            <label className="formElementLabel">{trl.mycarriers_modal_Name}</label>
            <SelectField
              fullWidth={false}
              onChange={(event, index, value) => {
                this.handleChange('carrier', value)
              }}
              value={carrier.carrier}
              errorText={formErrors && formErrors.carrier}
              style={{
                border: '1px solid rgb(206, 212, 218)',
                borderRadius: 4,
                fontSize: theme.font.fontSize
              }}
              inputStyle={{
                paddingLeft: '3%'
              }}
              className="formElementText"
              name="service"
              underlineStyle={{ display: 'none' }}
            >
              {
                myCarriers.availableCarriers && myCarriers.availableCarriers.map((item, key) => <MenuItem key={"MenuItemAvailableCarriers"+key.toString()} style={{ fontSize: theme.font.fontSize }} value={item.id} primaryText={item.label} />)
              }
            </SelectField>
          </div>
          <div>&nbsp;</div>
          <div className="formElementGrid">
            <label className="formElementLabel">{trl.mycarriers_modal_Service}</label>
            <SelectField
              fullWidth={false}
              onChange={(event, newValue) => this.handleChange('service', newValue ? 'Express' : 'Economic')}
              value={carrier.service}
              errorText={formErrors && formErrors.service}
              style={{
                border: '1px solid rgb(206, 212, 218)',
                borderRadius: 4,
                fontSize: theme.font.fontSize
              }}
              inputStyle={{
                paddingLeft: '3%'
              }}
              className="formElementText"
              name="service"
              underlineStyle={{ display: 'none' }}
            >
              <MenuItem style={{ fontSize: theme.font.fontSize }} value={'Economic'} primaryText={'Economic'} />
              <MenuItem style={{ fontSize: theme.font.fontSize }} value={'Express'} primaryText={'Express'} />
            </SelectField>
          </div>
          <div>&nbsp;</div>
          <div className="formElementGrid">
            <label className="formElementLabel">{trl.mycarriers_modal_Account}</label>
            <TextField
              fullWidth={false}
              onChange={(event, newValue) => this.handleChange('iD_Client_External', newValue)}
              value={carrier.iD_Client_External}
              className="formElementText"
              errorText={formErrors && formErrors.iD_Client_External}
              inputStyle={{
                paddingLeft: '3%'
              }}
              style={{
                border: '1px solid rgb(206, 212, 218)',
                borderRadius: 4,
                fontSize: theme.font.fontSize
              }}
              underlineStyle={{ display: 'none' }}
              name="iD_Client_External"
            />
          </div>
        </Dialog>
      </div>
    )
  }
}

function mapStateToProps({ auth, apiCallStateManager, me, myCarriers }) {
  return {
    role: auth.user_role,
    apiCallStateManager,
    isLoading: myCarriers.isLoading,
    iDSociete: me.iD_Societe,
    myCarriers: myCarriers.list,
  }
}

MyCarriers.propTypes = {
  deliveries: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired
}
MyCarriers.defaultProps = {
  deliveries: {}
}

export default connect(
  mapStateToProps,
  { apiAddCarrier, apiEditCarrier,apiGetMyCarriersData }
)(MyCarriers)
