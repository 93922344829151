////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
/////////ASSETS/////////
import inImg from '../../../assets/in.png'
import outImg from '../../../assets/out.png'

class InOut extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { children } = this.props
    return (
      <img src={children ? outImg : inImg} width="20" alt='img' />
    )
  }
}

InOut.propTypes = {
  children: PropTypes.bool
}

export default InOut
