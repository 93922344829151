////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import { get } from 'lodash'
///////COMPONENTS///////
import MyAddressesActions from '../Actions/MyAddressesActions'
import MyAddressesDisplayActions from '../Actions/MyAddressesDisplayActions'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

export const columns = [
  { key: 'iD_Adresse', sortable: false, header: '', primaryKey: true, hidden: true },
  { key: 'zoom', sortable: false, header: '', Component: MyAddressesDisplayActions },
  { key: 'type', sortable: true, header: translate('myaddresses_tableTitle_addressType') },
  { key: 'name', sortable: true, header: translate('myaddresses_tableTitle_addressName') },
  { key: 'contact2', sortable: true, header: translate('myaddresses_tableTitle_contact') },
  { key: 'city', sortable: true, header: translate('myaddresses_tableTitle_city') },
  { key: 'country', sortable: true, header: translate('myaddresses_tableTitle_country') },
  { key: 'actions', header: translate('myaddresses_tableTitle_action'), Component: MyAddressesActions }
]

export const searchDomaine = [
  { key: 'type', sortable: true, header: translate('myaddresses_tableTitle_addressType') },
  { key: 'name', sortable: true, header: translate('myaddresses_tableTitle_addressName') },
  { key: 'contact2', sortable: true, header: translate('myaddresses_tableTitle_contact') },
  { key: 'city', sortable: true, header: translate('myaddresses_tableTitle_city') },
  { key: 'country', sortable: true, header: translate('myaddresses_tableTitle_country') }
]

const config = {
  defaultPageSize: 1000,
  showPageSize: false,
  showFilter: false
}

class MyAddressesTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, handelSortable, handleOpenEdit, iDSociete } = this.props
    if (!data.length) return null
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  {columns.map((c, index) => {
                    if (c.hidden) return null
                    
                    return ( <SortableHeader
                                {...c}
                                sortKey={index.toString()}
                                name={c.header}
                                handleClick={index => handelSortable(index)}
                              /> )
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.key + '_' + index} className={'tableLigneGrey'}>
                      {columns.map(c => {
                        if (c.hidden) return null
                        const children = get(item, c.key)
                        
                        return ( <td key={'__' + c.key} className={c.className}>
                                    {c.Component && c.Component !== null
                                      ? React.createElement(c.Component, { row: item, children, handleOpenEdit, iDSociete })
                                      : children}
                                  </td> )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}

MyAddressesTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  handelSortable: PropTypes.func
}

export default sematable('MyAddressesTable', MyAddressesTable, columns, config)
