////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import theme from '../../styledComponents/theme'

const OrdersRatioRemaining = ({ row }) => {
  const { colors } = theme
  const bgColor = row.article_status_PRE30_PRE50_count > 0 ? colors.red : colors.green

	///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div style={{ backgroundColor: bgColor, textAlign: 'center', padding: 2, borderRadius: 4 }}>
      {`${row.commande_Articles_Count} / ${row.article_status_PRE30_PRE50_count}`}
    </div>
  )
}

OrdersRatioRemaining.propTypes = {
  row: PropTypes.object.isRequired
}

export default OrdersRatioRemaining
