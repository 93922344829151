////////LIBRARY/////////
import 'isomorphic-fetch'
import jwt_decode from 'jwt-decode'
import { get, isUndefined } from 'lodash'
///////COMPONENTS///////
import { getItem } from './cookiesHelper'
import Base64 from './base64'
import { translate } from '../containers/HOC/Translation'

export function checkStatus(response) {
  // (response.status < 200 || response.status > 300)
  if (!response.ok) {
    console.warn('response: ', response)
    const errorMessage = `${translate('error_message_global__criticalProblem')}.`
    switch (response.status) {
      case 400:
        const urlSplit = response.url.split('/')
        // TODO : Clearly need to remove those tests because it's not maintainable !
        const targetSku = urlSplit.find((e) => e === 'sku')
        const targetQuantityNumber = urlSplit.find((e) => e === 'quantity-number')
        const targetQuantityPercent = urlSplit.find((e) => e === 'quantity-percent')
        const targetPartnerShipsManagement = urlSplit.find((e) => e === 'partnership')
        if (
          targetSku === 'sku' &&
          targetPartnerShipsManagement !== 'partnership' &&
          targetQuantityNumber !== 'quantity-number' &&
          targetQuantityPercent !== 'quantity-percent'
        ) {
          throw new Error(translate('common__error_article_sku_unsaved'))
        } else if (
          targetPartnerShipsManagement === 'partnership' &&
          targetSku !== 'sku' &&
          targetQuantityNumber !== 'quantity-number' &&
          targetQuantityPercent !== 'quantity-percent'
        ) {
          throw new Error(translate('common__error__duplicateAliasPartnerShip'))
        } else {
          return response.json().then((body) => {
            const error400Message = body.error_description || get(body, 'modelState.error_description[0]', body.message)
            const error400 = new Error(error400Message)
            throw error400
          })
        }
      // break;
      case 404:
        throw new Error(errorMessage)
      // break;
      case 500:
        throw new Error(errorMessage)
      //break;
      default:
        const error = new Error(response.statusText)
        error.response = response
        throw error
    }
  }
  return response
}

export function requestParser(response) {
  let contentType = response.headers.get('content-type')
  {
    if (contentType.indexOf('application/json') !== -1) contentType = 'application/json'
  }
  switch (contentType) {
    case 'application/json':
      return response.json()
    // For cases below, return complete response instead of text, so we can use headers properties (content-type, filename, etc...)
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return response
    case 'text/plain':
      return response
    default:
      return {}
  }
}

export function isValueInLocalStore(state, endpointPath) {
  if (state.apiCallStateManager && state.apiCallStateManager[endpointPath]) {
    const s = state.apiCallStateManager[endpointPath]
    // return true if isAwaitingResponse is false or lastUpdated has been more than 300s
    return (s.lastUpdated && (Date.now() - s.lastUpdated) / 1000 >= 300) || !s.awaitingResponse
  }
  return false
}

/**
 * A utility to get value from LocalStore or from remote API store
 *
 * @param endpointPath The restful service end point.
 * @param config The config object of the call. Can be null.
 * @param request The request action.
 * @param onRequestSuccess The callback function to create request success action.
 *                 The function expects response json payload as its argument.
 * @param onRequestFailure The callback function to create request failure action.
 *                 The function expects error as its argument.
 * @param isForm: boolean indicating whether the call is from a form or not
 */
export function getValueFromStore(
  endpointPath,
  config,
  onRequest,
  onRequest_Success,
  onRequest_Failure,
  isForm,
  additionalParams = {}
) {
  return (dispatch, getState) => {
    // Will run request to API if value IS PUT OR POST
    if (
      config.method === 'put' ||
      config.method === 'post' ||
      additionalParams.forceUpdate ||
      !isValueInLocalStore(getState(), endpointPath)
    ) {
      dispatch(onRequest(endpointPath, additionalParams))
      return fetch(endpointPath, config)
        .then(checkStatus)
        .then(requestParser)
        .then((responseRequest) => {
          dispatch(onRequest_Success(endpointPath, responseRequest, additionalParams))
        })
        .catch((error) => {
          if (isForm) {
            dispatch(onRequest_Failure(endpointPath, error, additionalParams))
          } else {
            const response = error.response
            if (response === undefined) {
              dispatch(onRequest_Failure(endpointPath, error, additionalParams))
            } else {
              error.status = response.status
              error.statusText = response.statusText
              response.text().then((text) => {
                try {
                  const json = JSON.parse(text)
                  error.message = json.message
                } catch (ex) {
                  error.message = text
                }
                dispatch(onRequest_Failure(endpointPath, error, additionalParams))
              })
            }
          }
        })
    } else return Promise.resolve()
  }
}

export const ID_TOKEN = 'access_token'

export function setIdToken(idToken) {
  localStorage.setItem(ID_TOKEN, idToken)
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value)
}

export function removeIdToken() {
  localStorage.removeItem(ID_TOKEN)
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key)
}

export function loadIdToken() {
  return localStorage.getItem(ID_TOKEN)
}

export function getLocalStorage(key) {
  return localStorage.getItem(key)
}

export function getToken() {
  const accessTokenLst = localStorage.getItem(ID_TOKEN)
  const accessTokenCookie = getItem(ID_TOKEN)
  return accessTokenLst || accessTokenCookie
}

export function decodeUserProfile(idToken) {
  try {
    return jwt_decode(idToken)
  } catch (err) {
    return null
  }
}

export const encodeUrlBase64 = (string) => Base64.encode(string)

export const filterDateHelper = (fromDateState, toDateState, key, items) => {
  // if fromDate not set, then we start from today - 100 years
  const fromDate = fromDateState ? new Date(fromDateState) : new Date().setFullYear(1900)
  let toDate = new Date()
  // if toDate set then that's the toDate, otherwise it's 300 y from now
  toDate = toDateState ? new Date(toDateState) : toDate.setFullYear(toDate.getFullYear() + 100)
  items = items.filter((o) => {
    const oDate = new Date(o[key]).setHours(0, 0, 0, 0)
    return oDate >= fromDate && oDate <= toDate
  })
  return items
}

export const hasOwnerShipIfFac = (order) => {
  const userRole = getLocalStorage('user_role')
  const userId = getLocalStorage('user_id')
  let ownership = false
  // Check of order actually have an ownership
  if (!isUndefined(order) && order.hasOwnerShip) {
    // If FAC, must be the Owner to update it
    if (userRole === 'FAC') ownership = order.iD_User_Created === userId
    // If FACA, can do what he want
    else if (userRole === 'FACA') ownership = true
  }

  return ownership
}
