///////COMPONENTS///////
import * as c from '../constants'
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppAlert } from './appAlerts'
import { translate } from '../containers/HOC/Translation'

//FETCH PRINTSHOPS
function fetchPrintShopsRequest(endpointPath) {
  return { type: c.FETCH_PRINTSHOPS_REQUEST, endpointPath }
}
function fetchPrintShopsSuccess(endpointPath, printshops) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_PRINTSHOPS_SUCCESS, endpointPath })
    dispatch({ type: c.FETCH_PRINTSHOPS, payload: printshops })
  }
}
function fetchPrintShopsFailure(endpointPath, err) {
  return { type: c.FETCH_PRINTSHOPS_FAILURE, endpointPath }
}
export function fetchPrintShops() {
  return u.getValueFromStore(
    c.apiPrintshops,
    h.headerGetWithToken(),
    fetchPrintShopsRequest,
    fetchPrintShopsSuccess,
    fetchPrintShopsFailure
  )
}

//PUT PRINTSHOPS
function putPrintShopsRequest(endpointPath) {
  return { type: c.PUT_PRINTSHOPS_REQUEST, endpointPath }
}
function putPrintShopsSuccess(endpointPath, printshops) {
  return (dispatch) => {
    dispatch(addAppAlert(translate('printShop_actions_message__configSaved'), true))
    dispatch({ type: c.PUT_PRINTSHOPS, payload: printshops })
    dispatch({ type: c.PUT_PRINTSHOPS_SUCCESS, endpointPath })
  }
}
function putPrintShopsFailure(endpointPath, err) {
  return { type: c.PUT_PRINTSHOPS_FAILURE, endpointPath }
}
export function putPrintShops(printShops) {
  return u.getValueFromStore(
    c.apiPrintshops,
    h.headerPutWithTokenAndModel(printShops),
    putPrintShopsRequest,
    putPrintShopsSuccess,
    putPrintShopsFailure
  )
}

// EDIT PRINTSHOPS
export function editPrintShop(printShop) {
  return {
    type: c.EDIT_PRINTSHOP,
    payload: printShop
  }
}
