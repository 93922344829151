////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
///////COMPONENTS///////
import FlatButton from 'material-ui/FlatButton'
import UsersTable from '../../components/Sematable/Tables/UsersTable'
import { fetchUsers, removeUserFromState } from '../../actions/users'
import { apiGetCountries } from '../../actions/countries'
import PageLoad from '../../components/PageLoad'
import * as c from '../../constants'
import { checkRoleHasRightsToFunction } from '../misc/allowedRolesPerFunction'
import * as SC from '../../components/styledComponents'
import { PageHeader } from '../../components/sharedComponents'
import { isEmpty } from 'lodash'
/////////ASSETS/////////
import PersonAdd from 'material-ui/svg-icons/social/person-add'

class UserList extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.fetchUsers()
    this.props.removeUserFromState()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleDisplayAddNewUser() {
    if (checkRoleHasRightsToFunction(this.props.role, c.funcCreateNewUser)) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', textDecoration: 'none' }}>
          <Link to={c.userCreate}>
            <FlatButton
              label={this.props.trl.admin_users__add_new_user_btn}
              labelPosition="before"
              primary={true}
              icon={<PersonAdd />}
            />
          </Link>
        </div>
      )
    }
    return null
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { users, trl } = this.props
    if (isEmpty(users)) return <PageLoad text={trl.common__loading/* /admin_users__no_user */} size={80} thickness={9} />
    return (
      <div>
        <PageHeader title={trl.admin_users__title} subtitle={trl.admin_users__subtitle} />
        <SC.PageGutter>
          {this.handleDisplayAddNewUser()}
          {!users.length && <div style={{ fontSize: '15px', width: '100%', textAlign: 'center', paddingBottom: '10px'}}>{trl.admin_users__no_user}</div>}
          <UsersTable data={users} style={{ fontSize: SC.theme.font.fontSize}} />
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ users, auth }) {
  return {
    users: users.users,
    role: auth.user_role
  }
}

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  auth: PropTypes.object,
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  removeUserFromState: PropTypes.func.isRequired,
  apiGetCountries: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchUsers, removeUserFromState, apiGetCountries })(UserList)
