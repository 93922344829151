////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import ErrorInfo from '../ColumnHelpers/ErrorInfo'
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import DateFormatterLogs from '../ColumnHelpers/DateFormatterLogs'
import { translate } from '../../../containers/HOC/Translation'

const columns = [
  { key: 'iD_Event', header: 'ID', hidden: true, primaryKey: true },
  { key: 'event_Date', header: translate('logs_table_tableTitle__date'), sortable: true, searchable: true, Component: DateFormatterLogs },
  { key: 'name_Event_Type', header: translate('log_table_tableList__type'), sortable: true, searchable: true, Component: ErrorInfo },
  { key: 'detail', header: translate('logs_table_tableTitle__details'), sortable: true, searchable: true }
]

const config = {
  defaultPageSize: 50,
  showFilter: false,
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

class LogsTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <TableContainer>
        <Table {...this.props} columns={columns} NoDataComponent={NoDataComponent} />
      </TableContainer>
    )
  }
}

LogsTable.propTypes = propTypes
export default sematable('logsTables', LogsTable, columns, config)
