////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty, map, cloneDeep, isEqual, isNull } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import Checkbox from 'material-ui/Checkbox'
import { theme } from '../../components/styledComponents'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import dateFormatConverter from '../../utils/dateFormatConverter'
import DatePicker from 'material-ui/DatePicker'
import ItemFollowUpTable from '../../components/Sematable/Tables/ItemFollowUpTable'
import { FilterContainer } from '../../components/styledComponents/followUpStyles'
import { apiGetItemFollowUp, apiGetItemFollowUpId, apiItemFollowUpExport } from '../../actions/itemFollowUp'
/////////ASSETS/////////
import Flag from '../../assets/red_flag.png'

/////////STYLED/////////
const Label = styled.span`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  font-size: ${(props) => (props.isTitle ? '14px' : '12px')};
  margin-right: 8px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
`
const FilterAndTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`
const Check = styled(Checkbox)`
  width: auto !important;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const LeftFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
  margin-bottom: 30px;
`
const RightFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  grid-auto-rows: max-content;
  align-items: center;
  margin-bottom: 30px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  align-items: center;
  width: 100%;
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 100px;
`
const CheckboxWrapperRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 16px;
  justify-items: center;
  width: 100%;
  grid-column: 1 / 3;
`
const LegendTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`
const LegendTableText = styled.span`
  font-weight: bold;
  color: red;
  margin: 0 0 5px 0;
  font-size: 10px !important;
`
const FlagImg = styled.img`
  max-width: 15px;
  max-height: 15px;
  margin-right: 10px;
`
/////////STYLED/////////

class ItemFollowUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      BrandID: null,
      OrderDateStart: moment(new Date()).subtract(7, 'days').startOf('days').toDate(),
      OrderDateEnd: moment(new Date()).toDate(),
      LastShipDateStart: null,
      LastShipDateEnd: null,
      SelectedStatus: [33, 37, 39],
      InputSearchBy: {
        key: '',
        value: ''
      },
      SeeFixed: true,
      SeeVariable: true,
      filtersSettings: {},
      items: [],
      forceRefresh: true,
      GarmentMakerId: null,
      BrandSeason: '',
      BrandDrop: '',
      FileOrderId: null
    }
    this.defaultState = cloneDeep(this.state)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.queryData(this.defaultState)
  }

  queryData = (defaultState) => {
    const inputSearchBy = cloneDeep(this.state.InputSearchBy)
    inputSearchBy.value = inputSearchBy.value !== 0 ? inputSearchBy.value : ''
    if (isEqual(defaultState, this.defaultState)) 
      this.props.apiGetItemFollowUp({
        BrandID: this.defaultState.BrandID === 0 ? null : this.defaultState.BrandID,
        OrderDateStart: this.defaultState.OrderDateStart,
        OrderDateEnd: this.defaultState.OrderDateEnd,
        LastShipDateStart: this.defaultState.LastShipDateStart,
        LastShipDateEnd: this.defaultState.LastShipDateEnd,
        SelectedStatus: this.defaultState.SelectedStatus,
        InputSearchBy: {
          value: this.defaultState.InputSearchBy.value !== 0 ? this.defaultState.InputSearchBy.value : '',
          key: this.defaultState.InputSearchBy.key,
        },
        SeeFixed: this.defaultState.SeeFixed,
        SeeVariable: this.defaultState.SeeVariable,
        GarmentMakerId: this.defaultState.GarmentMakerId === 0 ? null : this.defaultState.GarmentMakerId,
        BrandSeason: this.defaultState.BrandSeason,
        BrandDrop: this.defaultState.BrandDrop,
        FileOrderId: this.defaultState.FileOrderId
      })
    else {
      this.props.apiGetItemFollowUp({
        BrandID: this.state.BrandID === 0 ? null : this.state.BrandID,
        OrderDateStart: this.state.OrderDateStart,
        OrderDateEnd: this.state.OrderDateEnd != this.defaultState.OrderDateEnd ?
          moment.parseZone(this.state.OrderDateEnd).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.OrderDateEnd,
        LastShipDateStart: this.state.LastShipDateStart,
        LastShipDateEnd: this.state.LastShipDateEnd != this.defaultState.OrderDateEnd ?
          moment.parseZone(this.state.LastShipDateEnd).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.LastShipDateEnd,
        SelectedStatus: this.state.SelectedStatus,
        InputSearchBy: inputSearchBy,
        SeeFixed: this.state.SeeFixed,
        SeeVariable: this.state.SeeVariable,
        GarmentMakerId: this.state.GarmentMakerId === 0 ? null : this.state.GarmentMakerId,
        BrandSeason: this.state.BrandSeason,
        BrandDrop: this.state.BrandDrop,
        FileOrderId: this.state.FileOrderId
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { xlsx } = nextProps
    if (!this.props.xlsx && xlsx) {
      setTimeout(() => {
        this.exportXlsx && this.exportXlsx.click()
      }, 1000)
    }
    // Remove this lock when we load for first time
    if (isNull(this.props.items) && !isEmpty(nextProps.items) > 0) this.setState({ forceRefresh: false })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  setStartDate = () => {
    let orderDateStart = new Date()
    orderDateStart.setDate(orderDateStart.getDate() - 7)
    orderDateStart.setHours(0, 0, 0, 0)
    return new Date(orderDateStart)
  }

  queryDataById = (id) => {
    this.props.apiGetItemFollowUpId(id)
  }

  updateStateAny = (target, value) => {
    this.setState({ [target]: value })
  }

  /**
   * update boolean state
   * @param filter    state to target
   * @returns {void}
   */
  onFilterChange = (filter) => () => {
    this.setState((state) => ({ [filter]: !state[filter] }))
  }

  selectedBrandHandler = (event, index, value) => {
    this.updateStateAny('BrandID', value)
  }

  selectedGarmentMakerHandler = (event, index, value) => {
    this.updateStateAny('GarmentMakerId', value)
  }

  selectedFileOrderHandler = (event, index, value) => {
    this.updateStateAny('FileOrderId', value)
  }

  changeDateHandler = (e, date, target, dateEndOfDay = false) => {
    this.updateStateAny(target, moment(date).toDate())
  }

  changeInputSearchByHandler = (event, index, value) => {
    this.setState({ InputSearchBy: { key: this.state.InputSearchBy.key, value: value }})
  }

  changeInputSearchHandler = (event, index) => {
    this.setState({ InputSearchBy: { key: index, value: this.state.InputSearchBy.value }})
  }

  onStatusChangeHandler = (filter) => () => {
    let oldState = cloneDeep(this.state.SelectedStatus)
    const elemIdx = !isEmpty(this.state.SelectedStatus) && this.state['SelectedStatus'].includes(filter) ? 
                    this.state['SelectedStatus'].findIndex((e) => e === filter) : -1
    elemIdx !== -1 ? oldState.splice(elemIdx, 1) : oldState.push(filter)
    this.setState({ SelectedStatus: oldState })
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') {
        // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  handleReset = () => {
    let defaultState = this.defaultState
    defaultState.forceRefresh = false
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

  handleOpenExportExel = () => {
    const inputSearchBy = cloneDeep(this.state.InputSearchBy)
    inputSearchBy.value = inputSearchBy.value !== 0 ? inputSearchBy.value : ''
    this.props.apiItemFollowUpExport({
      BrandID: this.state.BrandID === 0 ? null : this.state.BrandID,
      OrderDateStart: this.state.OrderDateStart,
      OrderDateEnd: this.state.OrderDateEnd,
      LastShipDateStart: this.state.LastShipDateStart,
      LastShipDateEnd: this.state.LastShipDateEnd,
      SelectedStatus: this.state.SelectedStatus,
      InputSearchBy: inputSearchBy,
      SeeFixed: this.state.SeeFixed,
      SeeVariable: this.state.SeeVariable,
      GarmentMakerId: this.state.GarmentMakerId === 0 ? null : this.state.GarmentMakerId,
      BrandSeason: this.state.BrandSeason,
      BrandDrop: this.state.BrandDrop,
      FileOrderId: this.state.FileOrderId
    })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoadingTable, filtersSettings, items, xlsx, isLoadingGlobal } = this.props
    const { forceRefresh } = this.state
    if (!filtersSettings || isLoadingGlobal || forceRefresh && isLoadingGlobal || !items || isLoadingTable && isLoadingGlobal) return null
    return (
      <div>
        <PageHeader title={trl.ifu_title} subtitle={trl.ifu_subTitle} />
        <SC.PageGutter>
            <FilterAndTableWrapper>
              <FilterContainer>
                <TableWrapper text={trl.common__search}>
                  <BodyTable>
                    <CheckboxWrapperRow>
                      {map(filtersSettings.availableStatus, (elem, idx) => (
                        <Row key={idx}>
                          <Check
                            checked={
                              !isEmpty(this.state.SelectedStatus)
                                ? !!this.state['SelectedStatus'].find((e) => e === elem.idStatus)
                                : false
                            }
                            onCheck={this.onStatusChangeHandler(elem.idStatus)}
                          />
                          <Label isTitle bold color={elem.color}>
                            {trl[elem.traductionKey]}
                          </Label>
                        </Row>
                      ))}
                    </CheckboxWrapperRow>
                    <LeftFiltersWrapper>
                      <div>
                        <Label>{trl.common__search} :</Label>
                        <TextField
                          id="product-catalog-search-value"
                          type="text"
                          style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                          value={this.state.InputSearchBy.key}
                          onChange={this.changeInputSearchHandler}
                          onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                        />
                        <Label style={{marginLeft: 10}}>{trl.common__by} :</Label>
                      </div>
                      <div>
                        <SelectField
                          style={{
                            fontSize: theme.font.fontSize,
                            width: '80%',
                            height: '30px'
                          }}
                          floatingLabelStyle={{
                            display: 'none'
                          }}
                          iconStyle={{
                            margin: '0',
                            padding: '0'
                          }}
                          labelStyle={{
                            borderRadius: 0,
                            padding: '3px 30px 0 5px',
                            border: '1px solid #ced4da',
                            width: '100%',
                            height: '30px',
                            position: 'relative',
                            top: '4px',
                            lineHeight: 'inherit'
                          }}
                          underlineStyle={{
                            display: 'none'
                          }}
                          value={this.state.InputSearchBy.value == '' ? 0 : this.state.InputSearchBy.value}
                          onChange={this.changeInputSearchByHandler}
                        >
                          {filtersSettings &&
                            filtersSettings.availableByParameters &&
                            filtersSettings.availableByParameters.map((param) => (
                              <MenuItem
                                key={param.key}
                                value={param.key}
                                primaryText={param.value}
                                style={{ fontSize: theme.font.fontSize }}
                              />
                            ))}
                        </SelectField>
                      </div>
                      <Label>{trl.common__filter_by_brand}</Label>
                      <SelectField
                        style={{
                          fontSize: theme.font.fontSize,
                          width: '80%',
                          height: '30px',
                          top: '-15px'
                        }}
                        floatingLabelStyle={{
                          display: 'none'
                        }}
                        iconStyle={{
                          margin: '0',
                          padding: '0',
                          paddingBottom: '11px'
                        }}
                        labelStyle={{
                          borderRadius: 0,
                          padding: '3px 30px 0 5px',
                          border: '1px solid #ced4da',
                          width: '100%',
                          height: '30px',
                          position: 'relative',
                          top: '4px',
                          lineHeight: 'inherit'
                        }}
                        underlineStyle={{
                          display: 'none'
                        }}
                        value={this.state.BrandID}
                        floatingLabelText={'hehe'}
                        onChange={this.selectedBrandHandler}
                      >
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize }}
                          value={null}
                          primaryText={trl.common__all}
                        />
                        {filtersSettings &&
                          filtersSettings.availableBrands &&
                          filtersSettings.availableBrands.map((brand) => {
                            return (brand.key != 0 && <MenuItem
                              key={brand.key}
                              value={brand.key}
                              primaryText={trl[brand.value] || brand.value}
                              style={{ fontSize: theme.font.fontSize }}
                            />)
                          })}
                      </SelectField>
                      {/* Filter by garment maker */}
                      <Label>{trl.orders_pre__filter_garment_maker} :</Label>
                      <div style={{ marginTop: '5px' }}>
                        <SelectField
                          style={{
                            fontSize: theme.font.fontSize,
                            width: '80%',
                            height: '30px',
                            top: '-15px'
                          }}
                          floatingLabelStyle={{
                            display: 'none'
                          }}
                          iconStyle={{
                            margin: '0',
                            padding: '0',
                            paddingBottom: '11px'
                          }}
                          labelStyle={{
                            borderRadius: 0,
                            padding: '3px 30px 0 5px',
                            border: '1px solid #ced4da',
                            width: '100%',
                            height: '30px',
                            position: 'relative',
                            top: '4px',
                            lineHeight: 'inherit'
                          }}
                          underlineStyle={{
                            display: 'none'
                          }}
                          value={this.state.GarmentMakerId}
                          floatingLabelText={trl.common__all}
                          onChange={this.selectedGarmentMakerHandler}
                        >
                          <MenuItem
                            style={{ fontSize: theme.font.fontSize }}
                            value={null}
                            primaryText={trl.common__all}
                          />
                          {filtersSettings &&
                            filtersSettings.availableGarmentMaker &&
                            filtersSettings.availableGarmentMaker.map((brand) => (
                              <MenuItem
                                key={brand.key}
                                value={brand.key}
                                primaryText={trl[brand.value] || brand.value}
                                style={{ fontSize: theme.font.fontSize }}
                              />
                            ))}
                        </SelectField>
                      </div>
                      <Label>{trl.common_sourceFile_filter__file} :</Label>
                      <SelectField
                        style={{
                          fontSize: theme.font.fontSize,
                          width: '80%',
                          height: '30px',
                          top: '-20px'
                        }}
                        floatingLabelStyle={{
                          display: 'none'
                        }}
                        iconStyle={{
                          margin: '0',
                          padding: '0',
                          paddingBottom: '11px'
                        }}
                        labelStyle={{
                          borderRadius: 0,
                          padding: '3px 30px 0 5px',
                          border: '1px solid #ced4da',
                          width: '100%',
                          height: '30px',
                          position: 'relative',
                          top: '4px',
                          lineHeight: 'inherit'
                        }}
                        underlineStyle={{
                          display: 'none'
                        }}
                        autoWidth={true}
                        value={this.state.FileOrderId}
                        floatingLabelText={trl.common__all}
                        onChange={this.selectedFileOrderHandler}
                      >
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize}}
                          value={null}
                          primaryText={trl.common__all}
                        />
                        {
                          filtersSettings &&
                          filtersSettings.availableFileOrder.map(fileOrder =>
                            <MenuItem
                              key={fileOrder.key}
                              value={fileOrder.key}
                              primaryText={trl[fileOrder.value] || fileOrder.value}
                              style={{ fontSize: theme.font.fontSize }}
                            />
                          )
                        }
                      </SelectField>
                      {/* Filter by Season Brand */}
                      <div>
                        <Label>{trl.orders_pre__filter_brand_season} :</Label>
                      </div>
                      <div>
                        <TextField
                          id="BrandSeason"
                          type="text"
                          style={{ width: 195, fontSize: theme.font.fontSize, height: 35 }}
                          value={this.state.BrandSeason}
                          onChange={(e) => this.updateStateAny('BrandSeason', e.target.value)}
                          onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                        />
                      </div>
                      {/* Filter by Drop */}
                      <div>
                        <Label>{trl.orders_pre__filter_brand_drop} :</Label>
                      </div>
                      <div>
                        <TextField
                          id="BrandDrop"
                          type="text"
                          style={{ width: 195, fontSize: theme.font.fontSize, height: 35 }}
                          value={this.state.BrandDrop}
                          onChange={(e) => this.updateStateAny('BrandDrop', e.target.value)}
                          onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                        />
                      </div>
                    </LeftFiltersWrapper>
                    <RightFiltersWrapper>
                      <Label>{trl.common__filter_by_validation_date} :</Label>
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '22%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          bottom: 0,
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_from}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'OrderDateStart')}
                        value={this.state.OrderDateStart}
                        maxDate={this.state.OrderDateEnd}
                        formatDate={dateFormatConverter}
                      />
                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '22%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          bottom: 0,
                          height: '30px',
                          marginBottom: '5px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_to}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'OrderDateEnd', true)}
                        value={this.state.OrderDateEnd}
                        minDate={this.state.OrderDateStart}
                        formatDate={dateFormatConverter}
                      />

                      <Label>{trl.common__filter_by_lastShipment_date} :</Label>

                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '22%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          bottom: 0,
                          height: '30px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_from}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'LastShipDateStart')}
                        value={this.state.LastShipDateStart}
                        maxDate={this.state.LastShipDateEnd}
                        formatDate={dateFormatConverter}
                      />

                      <DatePicker
                        autoOk
                        textFieldStyle={{
                          maxWidth: 100,
                          fontSize: theme.font.fontSize,
                          height: '30px',
                          margin: '5px 0'
                        }}
                        underlineStyle={{ display: 'none' }}
                        hintStyle={{
                          position: 'absolute',
                          left: '50%',
                          top: '22%',
                          transform: 'translate(-50%, -50%)'
                        }}
                        inputStyle={{
                          bottom: 0,
                          height: '30px',
                          marginBottom: '5px',
                          border: '1px solid #ced4da',
                          borderRadius: 0,
                          fontSize: theme.font.fontSize,
                          padding: '0 5px',
                          textAlign: 'center'
                        }}
                        hintText={trl.orders_pre__filter_date_exp_to}
                        container="inline"
                        onChange={(e, date) => this.changeDateHandler(e, date, 'LastShipDateEnd', true)}
                        value={this.state.LastShipDateEnd}
                        minDate={this.state.LastShipDateStart}
                        formatDate={dateFormatConverter}
                      />
                    </RightFiltersWrapper>

                    <TableRow align={'flex-start'} margin={'10px 0 0 0'}>
                      <Check checked={this.state.SeeFixed} onCheck={this.onFilterChange('SeeFixed')} />
                      <Label>{trl.common__show_static_items}</Label>
                    </TableRow>
                    <TableRow align={'flex-start'} margin={'0 0 10px 0'}>
                      <Check checked={this.state.SeeVariable} onCheck={this.onFilterChange('SeeVariable')} />
                      <Label>{trl.common__show_variable_items}</Label>
                    </TableRow>

                    <TableRow>
                      <ButtonWrapper>
                        <SC.ButtonPA_M style={{ marginRight: 16 }} onClick={this.queryData}>
                          {trl.common__filter_apply_btn}
                        </SC.ButtonPA_M>
                        <SC.ButtonR_M onClick={this.handleReset}>{trl.common__filter_reset_btn}</SC.ButtonR_M>
                      </ButtonWrapper>
                    </TableRow>
                    <TableRow align={'flex-end'}>
                      <SC.ButtonS_S onClick={this.handleOpenExportExel}>{trl.common__btn_ExportToExcel}</SC.ButtonS_S>
                      {xlsx && (
                        <a
                          target="_blank"
                          style={{ display: 'none' }}
                          href={`/excel_export/${xlsx}`}
                          ref={(exportXlsx) => (this.exportXlsx = exportXlsx)}
                        />
                      )}
                    </TableRow>
                  </BodyTable>
                </TableWrapper>
              </FilterContainer>

              <div>
                <LegendTableWrapper>
                  <FlagImg src={Flag} />
                  <LegendTableText>{trl.ifu_tableLegend_orderWaitingForPayment}</LegendTableText>
                </LegendTableWrapper>
                <ItemFollowUpTable
                  data={items}
                  getItemsById={this.queryDataById}
                  isLoadingTable={isLoadingTable}
                  trl={trl}
                />
              </div>
            </FilterAndTableWrapper>
        </SC.PageGutter>
      </div>
    )
  }
}

ItemFollowUp.propTypes = {}

ItemFollowUp.defaultProps = {}

const mapStateToProps = (state) => ({
  isLoadingGlobal: state.appAlerts.loading && state.appAlerts.loading.isLoading,
  ...state.itemFollowUp
})

export default connect(mapStateToProps, {
  apiGetItemFollowUp,
  apiItemFollowUpExport,
  apiGetItemFollowUpId
})(ItemFollowUp)
