////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
///////COMPONENTS///////
import { List, ListItem } from 'material-ui/List'
import menuParts from './menuParts'
import styles from './styles'

class PAdminMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { value: 1 }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { dropdowns, dashboard, userList, followUp, deliveryFollowUp, myAccount, productionFollowUp } = menuParts
    const { trl, companyIsPrintshopProd } = this.props

    return (
      <div className="leftMenu">
        <List>
          <ListItem
            primaryText={trl[dashboard.text]}
            style={styles.menuItem}
            containerElement={<Link to={dashboard.link} />}
          />
          {/* FOLLOW UP */}
          <ListItem
            primaryText={trl[followUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={followUp.link} />}
          />
          {
            companyIsPrintshopProd &&
            <ListItem
              primaryText={trl[productionFollowUp.text]}
              style={styles.menuItem}
              containerElement={<Link to={productionFollowUp.link} />}
            />
          }
          <ListItem
            primaryText={trl[deliveryFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={deliveryFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[dropdowns.myAccount.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            nestedItems={[
              <ListItem
                key={1}
                primaryText={trl[myAccount.text]}
                style={styles.menuItem}
                containerElement={<Link to={myAccount.link} />}
              />
            ]}
          />
          <ListItem
            primaryText={trl[dropdowns.company.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            innerDivStyle={{ paddingLeft: 0 }}
            nestedItems={[
              <ListItem
                key={1}
                primaryText={trl[userList.text]}
                style={styles.menuItem}
                containerElement={<Link to={userList.link} />}
              />
            ]}
          />
        </List>
      </div>
    )
  }
}
const mapStateToProps = ({ me }) => ({
  companyIsPrintshopProd: me.is_PrintShop_Prod,
})
export default connect(
  mapStateToProps,
  null
)(PAdminMenu)

