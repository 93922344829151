import * as c from '../constants'

export const user_lang = 'user_lang'

const INITIAL_STATE = {
  languages: [],
  baseLanguage: {},
  targetLanguage: {},
  siteLanguage: { keys: {} },
  preferredLanguage: null,
  [user_lang]: null
}

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case c.FETCH_LANGUAGES:
      return { ...state, languages: action.payload }

    case c.ADD_LANGUAGE_TO_BASE_LANGUAGE:
      return { ...state, baseLanguage: { ...action.payload } }

    case c.ADD_LANGUAGE_TO_TARGET_LANGUAGE:
      return { ...state, targetLanguage: { ...action.payload } }

    case c.DELETE_TARGET_AND_BASE_LANGUAGES:
      return { ...state, baseLanguage: {}, targetLanguage: {} }

    case c.CHANGE_TARGET_LANGUAGE_ROW:
      // we never know if the key exists already or not. To solve this, do either update or create
      // this is to update
      let updated = false
      let keys = []
      state.targetLanguage.keys.map((row) => {
        if (row.key === action.payload.key) {
          updated = true
          return keys.push({ ...row, value: action.payload.value })
        } else {
          return keys.push(row)
        }
      })
      // this is to create
      if (!updated) {
        keys.push({ key: action.payload.key, value: action.payload.value })
      }
      return { ...state, targetLanguage: { ...state.targetLanguage, keys } }

    case c.TOGGLE_LANGUAGE_LOCKOUT:
      // toggling for the languages list
      return {
        ...state,
        languages: state.languages.map((language) =>
          language.languageid === action.payload ? { ...language, enabled: !language.enabled } : language
        )
      }

    case c.ADD_SITLE_LANGUAGE_FROM_LS:
      return { ...state, siteLanguage: action.payload }

    // SET SITE LANGUAGE USING LANGUAGE ID
    case c.SET_SITE_LANGUAGE_USING_ID_SUCCESS:
      let newKeys = {}
      action.payload.keys.map((obj) => (newKeys[obj.key] = obj.value))
      setTimeout(() => {
        window.location.reload() // reload root site to update table Translation title
      }, 10)
      return {
        ...state,
        siteLanguage: {
          ...action.payload,
          keys: newKeys
        }
      }

    case c.VERSION_IS_UP_TO_DATE:
      return state

    case c.SET_PREFERRED_LANGUAGE_ID:
      return { ...state, preferredLanguage: action.payload }

    case c.DELETE_PREFERRED_LANGUAGE_ID:
      return { ...state, preferredLanguage: null }

    default:
  }

  return state
}
