import * as c from '../constants'

import _, { cloneDeep, findIndex } from 'lodash'
import { translate } from '../containers/HOC/Translation'

const initialState = {
  selectedBrandId: null,
  availableByParameters: null,
  inputBy: null,
  inputSearch: null,
  filesReturn: null,
  availableBrands: null,
  filesErrors: null,
  isLoading: false,
  dataDL: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API_GET_FILE_ORDER_FOLLOW_UP_REQUEST:
      return {
        ...state,
        isLoading: true
      }

    case c.API_GET_FILE_ORDER_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case c.API_GET_FILE_ORDER_FOLLOW_UP_FAILURE:
      return {
        ...state,
        isLoading: false
      }

    case c.API_GET_FILE_ORDER_FOLLOW_UP:
      action.payload.filtersSettings.availableByParameters = _.chain(
        action.payload.filtersSettings.availableByParameters
      )
        .map((elem) => {
          return { key: elem, value: translate(elem) }
        })
        .sortBy('value')
        .value()
      action.payload.filtersSettings.availableByParameters.splice(0, 0, { key: 0, value: translate('common__all') })
      action.payload.filtersSettings.availableBrands.splice(0, 0, { key: 0, value: translate('common__all') })
      return {
        ...state,
        availableBrands: action.payload.filtersSettings.availableBrands,
        selectedBrandId: action.payload.filtersSettings.selectedBrandId,
        availableByParameters: action.payload.filtersSettings.availableByParameters,
        inputBy: action.payload.filtersSettings.inputBy,
        inputSearch: action.payload.filtersSettings.inputSearch,
        filesReturn: action.payload.filesReturn,
        filtersSettings: action.payload.filtersSettings
      }

    case c.API_GET_FILE_ORDER_ERRORS_REQUEST:
      return {
        ...state,
        isLoading: true
      }

    case c.API_GET_FILE_ORDER_ERRORS_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case c.API_GET_FILE_ORDER_ERRORS_FAILURE:
      return {
        ...state,
        isLoading: false
      }

    case c.API_GET_FILE_ORDER_ERRORS:
      return {
        ...state,
        filesErrors: action.payload.files_Errors
      }

    case c.API_GET_FILE_ORDER_DOWNLOAD_FILE_REQUEST:
      return {
        ...state,
        isLoading: true
      }

    case c.API_GET_FILE_ORDER_DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case c.API_GET_FILE_ORDER_DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        isLoading: false
      }

    case c.API_GET_FILE_ORDER_DOWNLOAD_FILE:
      let fileName = action.payload.headers.get('content-disposition').split('"')
      const type = action.payload.headers.get('content-type')

      let data

      action.payload.blob().then((rst) => {
        data = rst

        const url = window.URL.createObjectURL(new Blob([data], { type: type }))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', fileName[1])
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

      return {
        ...state
      }

    case c.API_CANCEL_FILE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true
      }

    case c.API_CANCEL_FILE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case c.API_CANCEL_FILE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case c.API_CANCEL_FILE_ORDER:
      const stateCp = cloneDeep(state.filesReturn)
      const payloadCp = action.payload
      const idx = findIndex(stateCp, { file_ID: payloadCp.file_ID })
      stateCp[idx] = payloadCp
      return {
        ...state,
        filesReturn: stateCp
      }

    default:
  }
  return state
}
