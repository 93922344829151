////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable from 'sematable'
///////COMPONENTS///////
import { TableContainer } from '../../styledComponents'
import Toggle from 'material-ui/Toggle'
import { translate } from '../../../containers/HOC/Translation'

export const columns = [
  { key: 'addressName', sortable: true, header: translate('managePSH_tableTitle_printshop'), primaryKey: true },
  { key: 'allowCtcEconomic', sortable: true, header: translate('managePSH_tableTitle_allowCtcEconomic') },
  { key: 'allowCtcExpress', sortable: true, header: translate('managePSH_tableTitle_allowCtcExpress') },
  { key: 'allowPickUpAtPrintshop', sortable: true, header: translate('managePSH_tableTitle_allowPickUpAtPrintshop') },
  { key: 'fiveKilosRule', sortable: true, header: translate('managePSH_tableTitle_fiveKilosRule') }
]

const config = {
  defaultPageSize: 1000,
  showPageSize: false,
  showFilter: false
}

class PrintShopsTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data } = this.props
    if (!data.length) return null
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  {columns.map((c, index) => { return ( <th>{c.header}</th> ) })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.idCompany} className={'tableLigneGrey'}>
                      <td>{item.nameCompany}</td>
                      <td>
                        <Toggle
                          style={{ width: '51px', margin: '0px auto' }}
                          onToggle={(e, value) => this.handleChange(value, index, `allowCtcEconomic`)}
                          defaultToggled={item.allowCtcEconomic}
                        />
                      </td>
                      <td>
                        <Toggle
                          style={{ width: '51px', margin: '0px auto' }}
                          onToggle={(e, value) => this.handleChange(value, index, `allowCtcExpress`)}
                          defaultToggled={item.allowCtcExpress}
                        />
                      </td>
                      <td>
                        <Toggle
                          style={{ width: '51px', margin: '0px auto' }}
                          onToggle={(e, value) => this.handleChange(value, index, `allowPickUpAtPrintshop`)}
                          defaultToggled={item.allowPickUpAtPrintshop}
                        />
                      </td>
                      <td>
                        <Toggle
                          style={{ width: '51px', margin: '0px auto' }}
                          onToggle={(e, value) => this.handleChange(value, index, `fiveKilosRule`)}
                          defaultToggled={item.fiveKilosRule}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }

  // Methodes for events
  handleChange = (value, index, attribute) => {
    const { editPrintShop, data } = this.props
    const item = data[index]
    item[attribute] = value
    editPrintShop(item)
  }
}

PrintShopsTable.propTypes = {
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  editPrintShop: PropTypes.func.isRequired
}

export default sematable('PrintShopsTable', PrintShopsTable, columns, config)
