////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import { get, find, isUndefined, isNull } from 'lodash'
///////COMPONENTS///////
import ToggleOnOff from '../ColumnHelpers/ToggleOnOff'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

export const columns = [
  { key: 'idAddress', sortable: false, header: '', primaryKey: true, hidden: true },
  { key: 'addressName', sortable: true, header: translate('gmOptions_tableTitle_gmName') },
  { key: 'fiveKilosException', sortable: true, header: translate('gmOptions_tableTitle_5KgException'), Component: ToggleOnOff },
  { key: 'fileIntegrationContact', sortable: true, header: translate('gmOptions__configuration_fileOrderContact'), Component: (props) => {
      // We need to handle input values with value attr. instead of defaultValue (which doesn't change on render)
      // To store values of each inputs, we need to "state" them.
      // We are retrieving those values by finding them in props.
      const val = find(props.fileIntegrationContacts, { key: `FileIntegrationContact_${props.row.idCompany}` })
      return <input
        name="fileIntegrationContact"
        id={`FileIntegrationContact_${props.row.idCompany}`}
        type="email"
        style={{ maxWidth: 150 }}
        //fullWidth={false}
        pattern='[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z.]{2,30}'
        // If value is not defined (by page loading for example), we are loading the initial props instead
        value={!isUndefined(val) ? isNull(val.value) ? "" : val.value : props.row.fileIntegrationContact}
        onBlur={(e) => {
          if (e.target.validationMessage.length > 0) {
            e.target.title = e.target.validationMessage
            e.target.style = "border-color: red; max-width: 150px;"
          } else {
            e.target.title = ""
            e.target.style = "max-width: 150px;"
            props.handleFileIntegrationContact(props.row, e.target.value)
          }
        }}
        // Calling function to change value of input, now that we are not using defaultValue
        onChange={(e) => props.handleChangeFileIntegrationContactProps(props.row.idCompany, e.target.value)}
      />
    }
  },
]

export const searchDomaine = [
  { key: 'addressName', header: translate('gmOptions_tableTitle_gmName') },
  { key: 'fiveKilosException', header: translate('gmOptions_tableTitle_5KgException') },
  { key: 'fileIntegrationContact', header: translate('gmOptions__configuration_fileOrderContact') },
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false,
}

class MnageGmOptionsTable extends Component {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, handelSortable, handleOnToggle, handleFileIntegrationContact, fileIntegrationContacts, handleChangeFileIntegrationContactProps } = this.props
    if (!data.length) return null
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  {columns.map((c, index) => {
                    if (c.hidden) return null
                    return ( <SortableHeader {...c} sortKey={index.toString()} name={c.header} handleClick={index => handelSortable(index)}/> )
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={item.key + '_' + index} className={'tableLigneGrey'}>
                      {columns.map(c => {
                        if (c.hidden) return null
                        const children = get(item, c.key)

                        return (
                          <td key={'__' + c.key} className={c.className}>
                            {c.Component && c.Component !== null
                              ? React.createElement(c.Component, {
                                row: item,
                                children,
                                handleOnToggle: handleOnToggle,
                                toogleKey: c.key,
                                fileIntegrationContacts: fileIntegrationContacts,
                                handleFileIntegrationContact: c.key === 'fileIntegrationContact' ? handleFileIntegrationContact : null,
                                handleChangeFileIntegrationContactProps: handleChangeFileIntegrationContactProps
                              })
                              : children}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}

MnageGmOptionsTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  handelSortable: PropTypes.func,
}

export default sematable('MnageGmOptionsTable', MnageGmOptionsTable, columns, config)
