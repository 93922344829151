////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import MaterialUiTable from '../../components/MaterialUiTable'
import { fetchRoles } from '../../actions/roles'
import * as SC from '../../components/styledComponents'
import { PageHeader } from '../../components/sharedComponents'

class RoleList extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    this.props.fetchRoles()
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { roles, trl } = this.props
    const data = roles.roles
    if (roles.didInvalidate) {
      return <h2>...{trl.roleList_error_loadingData}</h2>
    }
    if (!data.length || !data) {
      return null
    }
    return (
      <div>
        <PageHeader title={trl.admin_roles__title} subtitle={trl.admin_roles__subtitle} />
        <SC.PageGutter>
          <MaterialUiTable
            adjustForCheckbox={false}
            displaySelectAll={false}
            tableHeaders={[trl.common__table_header_role, trl.common__table_header_description]}
            bodyData={data}
            columnIds={['id', 'name']}
          />
        </SC.PageGutter>
      </div>
    )
  }
}

const mapStateToProps = ({ roles }) => ({ roles })

RoleList.propTypes = {
  roles: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  fetchRoles: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchRoles })(RoleList)
