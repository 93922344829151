////////LIBRARY/////////
import React from 'react'
///////COMPONENTS///////
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import muiTheme from '../../theme-default'
import { styles } from '../../containers/Forms/misc/formStyles'

const AppNotLoggedIn = props => {
  
  const childrenWithProps = React.Children.map(props.children, child => React.cloneElement(child, { trl: props.trl }))

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <div style={styles.pageBackground}>{childrenWithProps}</div>
    </MuiThemeProvider>
  )
}

export default AppNotLoggedIn
