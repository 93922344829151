////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import * as c from '../../../constants'

class DateFormatterOrb extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row } = this.props
    const date2000 = '2000-01-01T00:00:01.087'
    return (
      row && new Date(row.ctC_Date_Confirmed) > new Date(date2000) && row.status.idStatus ? 
      ( <span style={{ minWidth: 63, display: 'block', whiteSpace: 'nowrap' }}>
          {dateFormat(new Date(row.ctC_Date_Confirmed), c.dateDMY)}
        </span> ) 
      : ( <span>-</span> )
    )
  }
}

DateFormatterOrb.propTypes = {
  row: PropTypes.object
}

DateFormatterOrb.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default DateFormatterOrb
