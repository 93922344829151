////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import * as c from '../../../constants'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import theme from '../../styledComponents/theme'
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
/////////ASSETS/////////
import Edit from 'material-ui/svg-icons/image/edit'

const propTypes = {
  row: PropTypes.object.isRequired
}

class BrandsAction extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, myRole, trl } = this.props;
    return (
      <ActionHelper>
        <MenuItem
          style={{ fontSize: theme.font.fontSize }}
          primaryText={trl.brand_table_actions__editBrand}
          leftIcon={<Edit />}
          onClick={() => hashHistory.push(`${c.brandEdit}${row.iD_Societe}`)}
        />
        <Divider />
        {['ADMGlobal', 'LPAdmin'].includes(myRole) && (
          <MenuItem
            style={{ fontSize: theme.font.fontSize }}
            primaryText={trl.admin_brand_tableAction_viewBrandFormats}
            leftIcon={<Edit />}
            onClick={() => hashHistory.push(`${c.brandEditFileFormats}${row.iD_Societe}`)}
          />
        )}
      </ActionHelper>
    )
  }
}

BrandsAction.propTypes = propTypes

export default connect(
  ({ auth }) => ({ myRole: auth.user_role }),
  {}
)(BrandsAction)
