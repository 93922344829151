import * as c from '../constants'

export default function (state = { maintenance: false }, action) {
  switch (action.type) {
    case c.MAINTENANCE_CHECK:
      return { ...state, maintenance: action.payload }

    default:
  }
  return state
}
