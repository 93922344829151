////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
import styled from 'styled-components'
///////COMPONENTS///////
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'
import FOFUErrorsTableRow from '../ColumnHelpers/FOFUErrorsTableRow'
import FOFUErrorsTableinputValueRow from '../ColumnHelpers/FOFUErrorsTableinputValueRow'
import { allRoles as r } from '../../../constants'

const columns = [
  { key: 'idItem', primaryKey: true, hidden: true },
  { key: 'error_Number', header: translate('fofu_nested_tableTitle_errorId'), sortable: false },
  { key: 'error_Code', header: translate('fofu_nested_tableTitle_errorCode'), sortable: false },
  { key: 'subject', header: translate('fofu_nested_tableTitle_errorTitle'), sortable: false, Component: props => <FOFUErrorsTableRow {...props} /> },
  { key: 'input_Value', header: translate('fofu_nested_tableTitle_errorIncorrectValue'), sortable: false, Component: props => <FOFUErrorsTableinputValueRow {...props} /> },
  { key: 'issue_Detail', header: translate('fofu_nested_tableTitle_errorMessage'), sortable: false, Component: props => <FOFUErrorsTableRow {...props} /> }
]

const config = {
  defaultPageSize: 5,
  showFilter: false,
  showPageSize: true,
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

const columnsFilteredList = [
  [
    'error_Number',
    'subject',
    'input_Value',
    'issue_Detail'
  ],
  [
    'error_Number',
    'error_Code',
    'subject',
    'input_Value',
    'issue_Detail'
  ]
]

export const allRoles = [
  'ADMGlobal',
  'CSP',
  'CSS',
  'EAC',
  'EAdmin',
  'FAC',
  'FACA',
  'LPAdmin',
  'LSAdmin',
  'PAdmin',
  'POP'
]

const displayMappings = {
  [r.ADMGlobal]: columnsFilteredList[1],
  [r.LPAdmin]: columnsFilteredList[1],
  [r.LSAdmin]: columnsFilteredList[1],
  [r.CSP]: columnsFilteredList[1],
  [r.CSS]: columnsFilteredList[1],

  default: columnsFilteredList[0]
}

/////////STYLED/////////
const TableContainerCustom = styled(TableContainer)`
  overflow: auto;
  position: relative;
  min-height: 0px;
  max-height:400px;
`
/////////STYLED/////////

class FileOrderErrorsTable extends Component {
  constructor(props) {
    super(props)
    const displayMapping = displayMappings[props.myRole] || displayMappings.default
    this.state = {
      columns: columns.filter(c => displayMapping.includes(c.key))
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data } = this.props
    return (
      <TableContainerCustom style={{ border: '1px solid black' }}>
        <Table
          {...this.props}
          data={data}
          columns={this.state.columns}
          NoDataComponent={NoDataComponent}
        />
      </TableContainerCustom>
    )
  }
}

FileOrderErrorsTable.propTypes = propTypes

export default sematable('FileOrderErrorsTable', FileOrderErrorsTable, columns, config)
