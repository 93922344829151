////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
///////COMPONENTS///////
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

const tables = {
  title: '',
  subTitle: '',
  table: {
    devise: { text: 'statistics_tableTitle_averageShoppingBasket', format: 'text' },
    totalCurrentSeason: { text: 'statistics_tableTitle_currentSeason', format: 'text' },
    totalLastSeason: { text: 'statistics_tableTitle_n1', format: 'text' },
    totalSecondToLastSeason: { text: 'statistics_tableTitle_n2', format: 'text' }
  }
}

const StatsTableAnalysisBasket = (props) => {
  const { data, trl, format, formatTable } = props
  let dataFilter = { ...data }
  map(dataFilter, (elem, index) => {
    let sign = Math.sign(elem['percentCurrentSeason']) === 1 ? '+' : ''
    let format = []
    if (elem['percentCurrentSeason'] > 0) {
      format.push(
        <span key={index}>
          {elem['totalCurrentSeason']}
          <span className="plus">&nbsp;({sign + elem['percentCurrentSeason']}%)</span>
        </span>
      )
    }
    if (elem['percentCurrentSeason'] < 0) {
      format.push(
        <span key={index}>
          {elem['totalCurrentSeason']}
          <span className="minus">&nbsp;({sign + elem['percentCurrentSeason']}%)</span>
        </span>
      )
    }
    if (elem['percentCurrentSeason'] === 0) {
      format.push(
        <span key={index}>
          {elem['totalCurrentSeason']}
          <span className="plus">&nbsp;({sign + elem['percentCurrentSeason']}%)</span>
        </span>
      )
    }
    elem['totalCurrentSeason'] = format
    delete elem['percentCurrentSeason']
    sign = Math.sign(elem['percentLastSeason']) === 1 ? '+' : ''
    format = []
    if (elem['percentLastSeason'] > 0) {
      format.push(
        <span key={index}>
          {elem['totalLastSeason']}
          <span className="plus">&nbsp;({sign + elem['percentLastSeason']}%)</span>
        </span>
      )
    }
    if (elem['percentLastSeason'] < 0) {
      format.push(
        <span key={index}>
          {elem['totalLastSeason']}
          <span className="minus">&nbsp;({sign + elem['percentLastSeason']}%)</span>
        </span>
      )
    }
    if (elem['percentLastSeason'] === 0) {
      format.push(
        <span key={index}>
          {elem['totalLastSeason']}
          <span className="plus">&nbsp;({sign + elem['percentLastSeason']}%)</span>
        </span>
      )
    }
    elem['totalLastSeason'] = format
    delete elem['percentLastSeason']
  })
  if (!Array.isArray(data) && typeof data !== 'object') return null
  const tableProps = tables || {}

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div>
      <div>
        <Table className={formatTable} fixedHeader={false}>
          <TableHeader
            style={{ borderBottom: '0px' }}
            displaySelectAll={false}
            enableSelectAll={false}
            adjustForCheckbox={false}
          >
            <TableRow style={{ borderBottom: '0px' }}>
              {map(tableProps.table, (header, key) => {
                let titleFormat = 'AnalysisTitle '
                titleFormat += format && format.title[key]
                return (
                  <TableHeaderColumn
                    className={format.tableHeader[key]}
                    style={{ textAlign: 'center', padding: '24px 1%', borderBottom: '1px solid black' }}
                    key={key}
                  >
                    <p className={titleFormat}>{trl[header.text]}</p>
                  </TableHeaderColumn>
                )
              })}
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {map(dataFilter, (row, idx) => (
              <TableRow key={idx} style={{ height: 30 }}>
                {map(row, (elem, index) => {
                  return (
                    <TableRowColumn
                      className={format.tableBody[index]}
                      key={index}
                      style={{ textAlign: 'center', height: 'auto !important' }}
                    >
                      <span>{elem}</span>
                    </TableRowColumn>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  ) // end return
} // end function

StatsTableAnalysisBasket.propTypes = {
  data: PropTypes.array.isRequired
}

export default StatsTableAnalysisBasket
