import * as c from '../constants'

export const user_id = 'user_id'
export const user_name = 'user_name'
export const user_role = 'user_role'
export const loginError = 'loginError'

const INITIAL_STATE = {
  [user_id]: null,
  [user_name]: null,
  [user_role]: null,
  [loginError]: null,
  user_isAuthenticated: false
}

export default function auth(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case c.LOGIN:
      return {
        ...state,
        user_isAuthenticated: true,
        [loginError]: false,
        [user_name]: action[user_name],
        [user_id]: action[user_id],
        [user_role]: action[user_role]
      }
    case c.UPDATE_AUTH:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      }

    default:
      return state
  }
}
