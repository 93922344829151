////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, filter, cloneDeep, some, find, orderBy } from 'lodash'
import MenuItem from 'material-ui/MenuItem'
import styled from 'styled-components'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import Checkbox from 'material-ui/Checkbox'
import DatePicker from 'material-ui/DatePicker'
import dateFormatConverter from '../../utils/dateFormatConverter'
import { filterDateHelper } from '../../utils/apiUtils'
import { PageHeader } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import * as c from '../../constants'
import MyDocumentationTable, { searchDomaine } from '../../components/Sematable/Tables/MyDocumentationTable'
import { apiGetMyDocumentation } from '../../actions/myDocumentation'

class MyDocumentation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentations: [],
      searchTermDomain: 'All',
      searchTerm: '',
      showPrisma: true,
      showInvoice: true,
      fromReception: null,
      toReception: null,
      sortBy: null,
      lastSortBy: null,
      sortOrderBy: ['asc'],
      hasOrderIdFilter: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////
  
  componentWillMount() {
    const { userId, routeParams = {} } = this.props
    const { idOrder = null } = routeParams
    this.props.apiGetMyDocumentation({ userId, idOrder })
    if (idOrder) this.setState({ showPrisma: false, hasOrderIdFilter: true })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.documentations !== this.props.documentations) 
      this.setState({ documentations: nextProps.documentations }, this.handleFilterTableItems)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleResetFilter() {
    this.setState(
      {
        searchTermDomain: 'All',
        searchTerm: '',
        showPrisma: true,
        showInvoice: true,
        fromReception: null,
        toReception: null,
        sortBy: null,
        lastSortBy: null,
        sortOrderBy: ['asc']
      },
      this.handleFilterTableItems
    )
  }

  handleFilter(value, name) {
    this.setState({ [name]: value }, this.handleFilterTableItems)
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, this.handleFilterTableItems)
  }
  handleChangeDate = (e, date, name) => this.setState({ [name]: date }, this.handleFilterTableItems)

  toggleShowItems(fieldName) {
    this.setState({ [fieldName]: !this.state[fieldName] }, this.handleFilterTableItems)
  }

  handelSortable = (index) => {
    this.setState({ lastSortBy: this.state.sortBy, sortBy: index }, this.handleFilterTableItems)
  }

  handleFilterTableItems() {
    const { documentations } = this.props
    const {
      searchTermDomain,
      searchTerm,
      showPrisma,
      showInvoice,
      fromReception,
      toReception,
      sortBy,
      lastSortBy,
      sortOrderBy
    } = this.state
    const term = searchTerm.toString().toLowerCase()
    let articles = cloneDeep(documentations)

    if (!showPrisma) articles = articles.filter(a => a.docType.iD_DocType !== 1)
    if (!showInvoice) articles = articles.filter(a => a.docType.iD_DocType !== 2)
    //filter by Reception Date
    if (fromReception || toReception) articles = filterDateHelper(fromReception, toReception, 'docDate', articles)
    // filter by search term
    if (term) {
      //filter by searchTermDomain
      if (searchTermDomain !== 'All') {
        const item = find(searchDomaine, item => item.key && item.key.includes(searchTermDomain))
        articles = filter(articles, o => {
          const getSearchTermDomain = get(o, searchTermDomain, false)
          const searchableItem =
            item.type === 'date' && getSearchTermDomain
              ? dateFormat(new Date(getSearchTermDomain), c.dateDMY)
              : getSearchTermDomain
          return (
            searchableItem &&
            searchableItem
              .toString()
              .toLowerCase()
              .includes(term)
          )
        })
      } else {
        articles = filter(articles, o => {
          return some(searchDomaine, sc => {
            const getSearchTermDomain = get(o, sc.key, false)
            const searchableItem =
              sc.type === 'date' && getSearchTermDomain
                ? dateFormat(new Date(getSearchTermDomain), c.dateDMY)
                : getSearchTermDomain
            return searchableItem
              ? searchableItem
                .toString()
                .toLowerCase()
                .includes(term)
              : false
          })
        })
      }
    }
    let newSortByOrder = ['asc']
    if (sortBy) {
      if (sortBy === lastSortBy) newSortByOrder = sortOrderBy[0] === 'asc' ? ['desc'] : ['asc']
      if (['docDate'].includes(sortBy)) {
        articles = articles.map((order, index) => {
          return { ...order, docDate: order.docDate === null ? '2000-01-01T00:00:01.087' : order.docDate}
        })
        articles.sort((a, b) => {
          let dateA = new Date(a[sortBy])
          let dateB = new Date(b[sortBy])
          return sortOrderBy[0] === 'desc' ? dateA - dateB : dateB - dateA
        })
      } else if (sortBy === 'docType') articles = orderBy(articles, `${sortBy}.iD_DocType`, newSortByOrder)
      else articles = orderBy(articles, sortBy, newSortByOrder)
    }
    this.setState({ documentations: articles, sortOrderBy: newSortByOrder })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading } = this.props
    const { documentations, showPrisma, showInvoice, fromReception, toReception, hasOrderIdFilter } = this.state
    if (isLoading) return null
    return (
      <div>
        <PageHeader title={trl.mydocum_Title} />
        <SC.PageGutter>
          <SC.Sky grey>
            <SC.PageGutter>
              {/* ADVANCED SEARCH AND FILTER */}
              {/* 5-1,2 SEARCH & DOMAIN CHOICE */}
              {!hasOrderIdFilter && (
                <SearchContainerStyled>
                  <TextLeftStyled>{trl.common__search}</TextLeftStyled>
                  <div>
                    <input
                      type="text"
                      style={{ width: 300 }}
                      name="searchTerm"
                      value={this.state.searchTerm}
                      onChange={e => this.handleChange(e)}
                    />
                  </div>
                  <p style={{ padding: '0px 30px 10px 10px', transform: 'translateY(6px)' }}>{trl.common__by}</p>
                  <SelectField
                    style={{ fontSize: theme.font.fontSize }}
                    hintText={trl.common__search_by || ' '}
                    value={this.state.searchTermDomain}
                    onChange={(e, index, value) => this.handleFilter(e, index, value, 'searchTermDomain')}
                  >
                    <MenuItem style={{ fontSize: theme.font.fontSize }} value="All" primaryText={trl.common__all} />
                    {searchDomaine.map(field => (
                      <MenuItem style={{ fontSize: theme.font.fontSize }} value={field.key} key={field.key} primaryText={field.header} />
                    ))}
                  </SelectField>
                  <FilterContainerStyled flex={'flex-end'}>
                    <TextLeftStyledH4 style={{ fontSize: theme.font.fontSize }}>{trl.mydocum_filters_docDate}</TextLeftStyledH4>
                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 200
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        padding: '5px 10px',
                        top: '6px'
                      }}
                      inputStyle={{
                        padding: '5px 10px',
                        bottom: 0,
                        height: '85%',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: '.9em'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_from}
                      container="inline"
                      onChange={(e, date) => this.handleChangeDate(e, date, 'fromReception')}
                      value={fromReception}
                      style={{ marginTop: 40, marginRight: 20 }}
                      formatDate={dateFormatConverter}
                      maxDate={toReception}
                    />
                    <DatePicker
                      autoOk
                      textFieldStyle={{ maxWidth: 200 }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        padding: '5px 10px',
                        top: '6px'
                      }}
                      inputStyle={{
                        padding: '5px 10px',
                        bottom: 0,
                        height: '85%',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: '.9em'
                      }}
                      hintText={trl.orders_pre__filter_date_exp_to}
                      container="inline"
                      onChange={(e, date) => this.handleChangeDate(e, date, 'toReception')}
                      value={toReception}
                      minDate={fromReception}
                      formatDate={dateFormatConverter}
                    />
                  </FilterContainerStyled>
                </SearchContainerStyled>
              )}
              <br />
              <CheckboxContainer>
                {/* 7 RESET */}
                <div>
                  <Checkbox
                    style={{}}
                    labelStyle={{ width: 'initial', color: '#00F359' }}
                    label={trl.mydocum_filters_proforma || ' '}
                    checked={showPrisma}
                    onCheck={() => this.toggleShowItems('showPrisma')}
                  />
                  <Checkbox
                    style={{}}
                    labelStyle={{ width: 'initial', color: '#0FB6FE' }}
                    label={trl.mydocum_filters_invoice || ' '}
                    checked={showInvoice}
                    onCheck={() => this.toggleShowItems('showInvoice')}
                  />
                </div>
              </CheckboxContainer>
              <br />
              <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>

                <SC.ButtonS
                  onClick={() => this.handleResetFilter()}
                  style={{ marginLeft: '0', marginBottom: 20 }}
                >
                  {trl.common__filter_reset_btn}
                </SC.ButtonS>
              </div>
            </SC.PageGutter>
          </SC.Sky>
        </SC.PageGutter>
        <SC.PageGutter>
          {documentations.length > 0 ? (
            <MyDocumentationTable
              trl={trl}
              data={documentations}
              className="DeliveryDetailTable"
              handelSortable={this.handelSortable}
            />
          ) : (
            <h3 style={{ textAlign: 'center' }}>{trl.common__noElementToDisplay}.</h3>
          )}
        </SC.PageGutter>
      </div>
    )
  }
}

MyDocumentation.propTypes = {
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  documentations: PropTypes.array,
  apiGetMyDocumentation: PropTypes.func.isRequired
}

MyDocumentation.defaultProps = {
  documentations: [],
  isLoading: true
}

const mapStateToProps = ({ myDocumentation, auth, apiCallStateManager }) => ({
  documentations: myDocumentation.list,
  role: auth.user_role,
  userId: auth.user_id,
  apiCallStateManager,
  isLoading: myDocumentation.isLoading
})

export default connect(
  mapStateToProps,
  {
    apiGetMyDocumentation
  }
)(MyDocumentation)

const SearchContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
`

const TextLeftStyled = styled.p`
  padding-right: 15px;
  transform: translateY(6px);
`
const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`
const FilterContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props => (props.flex ? props.flex : 'space-between')};
  align-items: flex-end;
  padding-left: 90px;
  @media (max-width: 1620px) {
    justify-content: center;
  }
`
const TextLeftStyledTransition = TextLeftStyled.withComponent('h4')
const TextLeftStyledH4 = TextLeftStyledTransition.extend`
  transform: translateY(0px);
  text-transform: uppercase;
  font-size: 15px;
`
