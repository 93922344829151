////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
///////COMPONENTS///////
import CompaniesTable from '../../components/Sematable/Tables/CompaniesTable'
import { apiGetCompanies } from '../../actions/companies'
import { apiGetCountries } from '../../actions/countries'
import PageLoad from '../../components/PageLoad'
import * as SC from '../../components/styledComponents'
import { PageHeader } from '../../components/sharedComponents'

class CountryLabellistsList extends Component {
  
  ///////////////////////////////// CONFIG ///////////////////////////////////////
  
  componentDidMount() {
    this.props.apiGetCountries()
    this.props.apiGetCompanies()
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { companies, countries, trl } = this.props
    if (!companies || !countries) return <PageLoad text={trl.common__no_data_available} size="80" thickness="9" />
    // Insert the companies field into the countries to build the selector
    countries.map(d => (d.companies = companies))
    return (
      <div>
        <PageHeader title={trl.admin_country_labellist__title} subtitle={trl.admin_country_labellist__subtitle} />
        <SC.PageGutter>
          <h2 className={'h2--centered'}>{}</h2>
          <CompaniesTable data={countries} trl={trl} companies={companies} />
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ companies, countries, auth, me }) {
  return {
    companies: _.toArray(companies).filter(c => c.type.toUpperCase() === 'LP' || c.type.toUpperCase() === 'LS'),
    countries: countries.countries,
    role: auth.user_role,
    me
  }
}

CountryLabellistsList.propTypes = {
  companies: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  apiGetCompanies: PropTypes.func.isRequired,
  apiGetCountries: PropTypes.func.isRequired,
  auth: PropTypes.object,
  trl: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { apiGetCountries, apiGetCompanies })(CountryLabellistsList)
