import * as c from '../constants'

const INITIAL_STATE = {
  countries: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_COUNTRIES:
      return { ...state, countries: [...action.payload] }

    case c.API_UPDATE_COUNTRY_LABELLIST:
      return {
        ...state,
        countries: state.countries.map((country) =>
          country.country_alpha_2_code === action.idCountryKey
            ? {
                ...country,
                iD_Labelliste: action.payload.iD_Labelliste,
                labelliste_name: action.payload.labelliste_name
              }
            : country
        )
      }

    case c.API_UPDATE_COUNTRY_PRINTSHOP_PE:
      return {
        ...state,
        countries: state.countries.map((country) =>
          country.country_alpha_2_code === action.idCountryKey
            ? {
                ...country,
                iD_Printshop_PE: action.payload.iD_Printshop_PE,
                printshop_PE_Name: action.payload.printshop_PE_Name
              }
            : country
        )
      }

    case c.API_UPDATE_COUNTRY_PRINTSHOP_PP:
      return {
        ...state,
        countries: state.countries.map((country) =>
          country.country_alpha_2_code === action.idCountryKey
            ? {
                ...country,
                iD_Printshop_PP: action.payload.iD_Printshop_PP,
                printshop_PP_Name: action.payload.printshop_PP_Name
              }
            : country
        )
      }

    default:
      break
  }
  return state
}
