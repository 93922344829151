////////LIBRARY/////////
import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import { PageHeader } from '../../components/sharedComponents'
import { apiGetOrderArticle, apiUpdateOrderArticleStatic, resetOrderArticle } from '../../actions/orders'
import * as c from '../../constants'
import * as SC from '../../components/styledComponents'
import ArticleFixeForm from '../Forms/ArticleFixeForm'
import ShowImageAction from '../../components/Sematable/ColumnHelpers/ShowImage'
import StaticVariable from '../../components/Sematable/ColumnHelpers/StaticVariable'
import { theme } from '../../components/styledComponents'
import { hasOwnerShipIfFac } from '../../utils/apiUtils'

/////////STYLED/////////
const ShowImageActionWrapper = styled.div`
  width: 300px;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`
const TableWrapper = styled.div`
  margin: 0 auto;
  background-color: ${theme.colors.lightGrey};
  margin-bottom: 70px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  & > *:last-child {
    align-self: flex-end;
  }
  & > *:nth-child(2) {
    align-self: center;
  }
`
const ItemType = styled.p`
  & > div {
    display: inline;
  }
`
/////////STYLED/////////

class ArticleFixe extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const { routeParams } = this.props
    const { orderId } = routeParams
    this.props.apiGetOrderArticle(orderId)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////
  renderYesNo = (v) => <span>{v ? this.props.trl.common__yes : this.props.trl.common__no}</span>

  render() {
    const { orders, trl, routeParams, apiUpdateOrderArticleStatic } = this.props
    const { orderId, idCommandeArticle } = routeParams
    if (_.isEmpty(orders) || !orders[routeParams.orderId] || !orders[orderId].commande_Articles[idCommandeArticle]) {
      return null
    } else {
      const commandeArticle = orders[orderId].commande_Articles[idCommandeArticle] || {}
      const order = orders[routeParams.orderId]
      const hasOwner = hasOwnerShipIfFac(order)
      return (
        <div>
          <PageHeader
            title={`${trl.order_EditFixItem_TitleStart} ${commandeArticle.iD_Article_Externe_SAP}`}
            subtitle={`${trl.order_EditFixItem_Subtitle.replace('{0}', orders[orderId].iD_Commande)}`}
            base64Logo={order ? order.brandLogo : null}
          />
          <SC.PageGutter className="test">
            <TableWrapper style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* LEFT COLUMN */}
              <ShowImageActionWrapper>
                <ShowImageAction row={commandeArticle} big={true} />
              </ShowImageActionWrapper>
              {/* MIDDLE COLUMN */}
              <div
                style={{
                  width: '30%',
                  maxWidth: '100%',
                  marginLeft: '3em',
                  fontSize: theme.font.fontSize
                }}
                className="AddedItemDetail"
              >
                <p style={{ fontWeight: 'bold', fontSize: theme.font.fontSize, marginBottom: '4px' }}>
                  {trl.order_EditFixItem_Text_ItemInfo}
                </p>
                <br />
                <p style={{ marginBottom: '4px' }}>
                  {trl.order_EditFixItem_Text_BrandItem}: {commandeArticle.iD_Article_Externe_Enseigne}
                </p>
                <p style={{ marginBottom: '4px' }}>
                  {trl.order_EditFixItem_Text_Design}: {commandeArticle.article_Name}
                </p>
                <ItemType>
                  {trl.order_EditFixItem_Text_ItemType}: <StaticVariable row={commandeArticle} />
                </ItemType>
                <br />
                <p style={{ marginBottom: '4px' }}>
                  {trl.order_EditFixItem_Text_Minimum}: <strong>{commandeArticle.article_Order_Min}</strong>
                </p>
                <p style={{ marginBottom: '4px' }}>
                  {trl.order_EditFixItem_Text_Packing}: <strong>{commandeArticle.article_Conditionnement}</strong>
                </p>
              </div>

              {/* RIGHT COLUMN */}
              <ArticleFixeForm
                apiUpdateOrderArticleStatic={apiUpdateOrderArticleStatic}
                trl={trl}
                labelStyle={{ fontSize: theme.font.fontSize }}
                isDisabled={commandeArticle.iD_Status_Commande > 8 || !hasOwner}
                orderId={orderId}
                idCommandeArticle={idCommandeArticle}
              />
            </TableWrapper>

            <SC.ButtonR_L onClick={() => hashHistory.push(`${c.orderDetail}${orderId}`)}>
              {trl.order_EditFixItem_Btn_Back}
            </SC.ButtonR_L>
          </SC.PageGutter>
        </div>
      )
    }
  }
}

ArticleFixe.propTypes = {
  trl: PropTypes.object.isRequired,
  apiGetOrderArticle: PropTypes.func.isRequired,
  apiUpdateOrderArticleStatic: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired
}

ArticleFixe.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = ({ orders }) => ({ orders })

export default connect(mapStateToProps, {
  apiGetOrderArticle,
  apiUpdateOrderArticleStatic,
  resetOrderArticle
})(ArticleFixe)
