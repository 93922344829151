////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
import { connect } from 'react-redux'
import styled from 'styled-components'
///////COMPONENTS///////
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import NoDataComponent from '../NoDataComponent'
import ColorStatusFOFU from '../ColumnHelpers/ColorStatusFOFU'
import PageLoadTable from '../../PageLoadTable'
import FileOrderFollowUpActions from '../Actions/FileOrderFollowUpActions'

const columns = [
  { key: 'file_ID', primaryKey: true, hidden: true },
  { key: 'status.description.traductionKey', header: translate('fofu_tableTitle_status'), sortable: true, Component: ColorStatusFOFU },
  { key: 'brand_Name', header: translate('fofu_tableTitle_brandName'), sortable: true },
  { key: 'file_Name', header: translate('fofu_tableTitle_fileName'), sortable: true },
  { key: 'file_Format', header: translate('fofu_tableTitle_fileFormat'), sortable: true, },
  { key: 'download_Date', header: translate('fofu_tableTitle_fileDownloadDate'), Component: DateFormatter, sortable: true },
  { key: 'integration_Date', header: translate('fofu_tableTitle_fileIntegrationDate'), Component: DateFormatter, sortable: true },
  { key: 'all_Orders_Validation_Date', header: translate('fofu_tableTitle_fileAllOrdersValidatedDate'), Component: DateFormatter, sortable: true },
  { key: 'orders_Count', header: translate('fofu_tableTitle_ordersCount'), sortable: true },
  { key: 'actions', header: translate('common__table_header_actions'), Component: FileOrderFollowUpActions, sortable: false }
]

/////////STYLED/////////
const FileOrdersFollowUpTableLoader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255,255,255, 0.8);
  z-index: 1499;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
`
/////////STYLED/////////

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}
class FileOrderFollowUpTable extends Component {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, showPagination, isLoading, trl } = this.props
    config.showPageSize = showPagination ? true : false
    return (
      <TableContainer style={{ border: '1px solid black' }}>
        {isLoading &&
          <FileOrdersFollowUpTableLoader>
            <PageLoadTable text={trl.common__loading} size={80} thickness={9} />
          </FileOrdersFollowUpTableLoader>
        }
        <Table
          {...this.props}
          data={data}
          columns={columns}
          NoDataComponent={NoDataComponent}
        />

      </TableContainer>
    )
  }
}


FileOrderFollowUpTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

function mapStateToProps({ auth, languages }) {
  return {
    role: auth.user_role,
    trl: languages.siteLanguage.keys
  }
}


export default connect(mapStateToProps)(sematable('FileOrderFollowUpTable', FileOrderFollowUpTable, columns, config))
