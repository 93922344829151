////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
///////COMPONENTS///////
import { allowedRolesPerPath as roleRights } from '../misc/allowedRolesPerPath'
import NotAllowed from '../misc/NotAllowed'

export default function (ComposedComponent) {
  class RestrictPerRole extends Component {

    ///////////////////////////////// HANDLE ///////////////////////////////////////

    handleParseParam(pathname, params) {
      for (let param in params) {
        if (params.hasOwnProperty(param)) pathname = pathname.replace(`${params[param]}`, '')
      }
      return pathname
    }

    handleRestrictPerRole() {
      const { params, role } = this.props
      let { pathname } = this.props.location
      if (!_.isEmpty(params)) pathname = this.handleParseParam(pathname, params)
      if (roleRights[pathname][role]) return <ComposedComponent {...this.props} />
      else return <NotAllowed />
    }

    ///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
      return <div>{this.handleRestrictPerRole()}</div>
    }
  }

  function mapStateToProps({ auth }) {
    return { role: auth.user_role }
  }

  RestrictPerRole.propTypes = {
    location: PropTypes.object.isRequired,
    params: PropTypes.object,
    role: PropTypes.string.isRequired
  }

  RestrictPerRole.contextTypes = {
    router: PropTypes.object.isRequired
  }

  return connect(mapStateToProps)(RestrictPerRole)
}
