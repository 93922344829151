////////LIBRARY/////////
import React, { PropTypes } from 'react'
import _ from 'lodash'
import styled  from 'styled-components'

/////////STYLED/////////
const RadioWrapper = styled.div`
  & : nth-child(1n+0){
    display:${props => (props.isCertifLogo && props.isType9) && 'flex'};
    flex-direction: ${props => (props.isCertifLogo && props.isType9) && 'column'};
    padding-left:${props =>  (props.isCertifLogo && props.isType9) && '0%' }
  }
`
/////////STYLED/////////

class Radio extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = {
      value: this.props.control.props.value
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  onChange(...args) {
    this.setState({ value: args[1] })
    if (typeof this.props.onChange === 'function') this.props.onChange(this.props.control, ...args)
  }
  // create wrapper with styled component
  // target 1 child of the xxx wrapper
  // add a test to force display (flex / column ) if we have certification logo to display 
  // test with isType9 && referenceCode: "certifLogo"

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const props = this.props
    const RADIO = this.props.library[props.component]
    const OPTION = this.props.library[props.option]
    const isType9 = props.control.props.displayFormat === 'type_9'
    const requiredRule = _.find(props.control.rules.validation, vaidation => vaidation.rule === 'mandatory')
    const isCertifLogo = this.props.control.referenceCode === 'certifLogo'
    return (
      <div className="formElementGrid">
        <div className="formElementLabel" style={props.attributes.titleStyle}>
          {props.attributes.label}
        </div>
        <RadioWrapper isType9={isType9} isCertifLogo={isCertifLogo} >
          <RADIO {...props.attributes} onChange={this.onChange} valueSelected={this.state.value} >
            {this.props.control.option.map((option, index) => {
            
              const styles = {
                backgroundImage: `url(${option.label})`,
                backgroundSize: isCertifLogo && 'contain',
                width: isCertifLogo && `300px` 
              }
              return (
                <OPTION
                  {...option}
                  key={index}
                  className={`${(isType9 && !isCertifLogo) ? 'isType9' : (isType9 && isCertifLogo) && 'isCertifLogo'}`}
                  style={styles }
                  //style={{ backgroundImage: `url(${test})` }}    /VariableData_images/FIBRES_GOTS_TOTAL.png
                  disabled={props.attributes.disabled}
                >
                { }
                </OPTION>
              )
            })}
          </RADIO>
          {requiredRule && !this.state.value && props.control.isSubmited ? (
            <div style={{ position: 'relative', bottom: 2, fontSize: 12, lineHeight: 4, color: 'rgb(244, 67, 54)' }}>
              {requiredRule.message}
            </div>
          ) : null}
        </RadioWrapper>
      </div>
    )
  }
}

Radio.propTypes = {
  library: PropTypes.object,
  component: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  control: PropTypes.object,
  option: PropTypes.string.isRequired,
  onChange: PropTypes.func
}
export default Radio
