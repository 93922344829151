////////LIBRARY/////////
import React from 'react'
///////COMPONENTS///////
import FlatButton from 'material-ui/FlatButton'
import { fullWhite } from 'material-ui/styles/colors'
/////////ASSETS/////////
import Clear from 'material-ui/svg-icons/content/clear'

const DeleteButton = ({ onClick, bgColor }) => {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <FlatButton
      backgroundColor={bgColor || '#b3b3b3'}
      hoverColor="#b9b9b9"
      icon={<Clear color={fullWhite} />}
      style={{ margin: 12, width: 40, minWidth: 40, zoom: 0.8, border: '1px solid #020202' }}
      fullWidth={false}
      onClick={onClick}
    />
  )
}
export default DeleteButton
