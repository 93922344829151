////////LIBRARY/////////
import _ from 'lodash'
import React, { PropTypes } from 'react'
///////COMPONENTS///////
import validation from './../../helpers/validation'
import theme from '../../../../styledComponents/theme'

class SelectField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.attributes.selected,
      errorText: props.attributes.errorText || ''
    }
    this.onChange = this.onChange.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(props) {
    this.state = {
      value: props.attributes.selected,
      errorText: props.attributes.errorText || ''
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  validate(value) {
    let isValid = true
    if (this.props.rules && this.props.rules.validation) {
      for (const data of this.props.rules.validation) {
        isValid = validation[data.rule](value ? value.toString() : '', data.value)
        if (!isValid) return { isValid: false, message: data.message }
      }
    }
    return { isValid: true, message: '' }
  }

  onChange(...args) {
    const validator = this.validate(args[2])
    if (!validator.isValid) this.setState({ errorText: validator.message, value: args[2] })
    else this.setState({ errorText: '', value: args[2] })
    if (typeof this.props.onChange === 'function') this.props.onChange(this.props.control, ...args)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const props = this.props
    let errorText = this.state.errorText
    if (!errorText && props.control.customRules) errorText = props.control.customRules.message
    let type12OutputText = null
    if (props.control.props.displayFormat === 'type_12') {
      const outputObj = _.find(props.control.option, option => option.value === this.state.value)
      if (outputObj) {
        type12OutputText = ' '
        outputObj.translations.map(t => {
          return(
            type12OutputText += t.transaltedValue + ' / '
          )
        })
        type12OutputText = type12OutputText.substr(0, type12OutputText.length - 2)
      }
    }
    const SELECTFIELD = this.props.library[props.component]
    const OPTION = this.props.library[props.option]
    const isCareSymbole = _.includes(props.attributes.typeValue.name, 'caresymbol')
    this.props.control.option = _.uniqBy(this.props.control.option, op => op.value)
    return (
      <div className="formElementGrid">
        {props.control.props.label && <div className="formElementLabel">{props.control.props.label}</div>}
        <SELECTFIELD
          {...props.attributes}
          value={this.state.value}
          errorText={errorText}
          onChange={this.onChange}
          className='formElementSelect'
          labelStyle={isCareSymbole && { fontFamily: 'Ginetex-Symbols', fontSize: 30}}
          style={
            !isCareSymbole ? {
              border: '1px solid #ced4da',
              borderRadius: 0,
              height: '42px',
              fontSize: theme.font.fontSize
            } :
            {
              marginBottom:'35px'
            }
          }
          inputStyle={
            !isCareSymbole && {
              paddingLeft: '10px',
              fontSize: theme.font.fontSize
            }
          }
          underlineStyle={{
            display: 'none'
          }}
          errorStyle={
            isCareSymbole && {
              fontSize: 12,
              position: 'absolute',
              top: 59,
              fontFamily: 'Gotham, sans-serif',
              textIndent: 0
            }
          }
          menuItemStyle={
            isCareSymbole && {
              fontFamily: 'Ginetex-Symbols',
              fontSize: 27,
            }
          }
        >
          <OPTION value={props.control.formType === 'commonData' && null } primaryText="" key={'index'}>
            {}
          </OPTION>
          {this.props.control.option.map((option, index) => {
            return (
              <OPTION {...option} key={index} style={{fontSize: theme.font.fontSize}}>
                {}
              </OPTION>
            )
          })}
        </SELECTFIELD>
        {props.control.careSymboleImg && <img src={props.control.careSymboleImg} className="careSymboleImg" alt='img' />}
        {type12OutputText && <div className="formElementExtendedText" style={{marginLeft:"5px"}}>{type12OutputText}</div>}
      </div>
    )
  }
}

SelectField.propTypes = {
  library: PropTypes.object,
  component: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  control: PropTypes.object,
  option: PropTypes.string.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func
}
export default SelectField
