////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import IconButton from 'material-ui/IconButton'
/////////ASSETS/////////
import ActionHome from 'material-ui/svg-icons/navigation/close'

class ShowImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { big, row, size } = this.props
    const Image = styled.img`
      ${props => `
      width: ${size ? size : '300px'};
      height: ${size ? size : '300px'};
      cursor:pointer;
      max-width: ${big ? 'initial' : '64px'};
      max-height: ${big ? 'initial' : '64px'};
    `};
    `
  
    const actions = [
      <IconButton onClick={this.handleClose} primary={true}>
        <ActionHome />
      </IconButton>
    ]

    return (
      <div>
        <Image
          onClick={this.handleOpen}
          big={big}
          src={`/product_images/${row[this.props.imageKey || 'itemPictureName']}_300x300.png`}
          alt={row.iD_Article_Externe_Enseigne}
        />
        <Dialog
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoDetectWindowHeight={true}
          repositionOnUpdate={true}
          ref={dialogRef => (this.dialogRef = dialogRef)}
          bodyStyle={{ height: "100%" }}
        >
          <div style={{ height: '50vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={`/product_images/${row[this.props.imageKey || 'itemPictureName']}_1000x1000.png`}
              alt={row.iD_Article_Externe_Enseigne}
              style={{ maxWidth: '100%', maxHeight: '100%', marginLeft: 'auto', marginRight: 'auto' }}
              onLoad={() => this.dialogRef.forceUpdate()}
            />
          </div>

        </Dialog>
      </div>
    )
  }
}

ShowImage.propTypes = {
  row: PropTypes.object.isRequired,
  imageKey: PropTypes.string
}

export default ShowImage
