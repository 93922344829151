////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _, { isEmpty } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import { hasOwnerShipIfFac } from '../../utils/apiUtils'
import OrderDetailTable from '../../components/Sematable/Tables/OrderDetailTable'
import { apiGetOrderArticle, apiUpdateOrderStatusCancel, apiOrderSapSimulate,
  apiUpdateOrderGeographicalArea, apiShowModalReassign, apiShowModalReassignConfirm,
  apiPutReassignOrder, apiResetReassignUpdated, resetOrderArticle } from '../../actions/orders'
import { GetOrderCompanyByType } from '../../actions/companies'
import * as SC from '../../components/styledComponents'
import stringformat from '../../utils/stringformat'
import DisplayActionsInformation from '../DisplayActionsInformation'
import OrderDetailInformation from './OrderDetailInformation'
import { PageHeader, TableWrapper, HelpComponent } from '../../components/sharedComponents'
import * as c from '../../constants'
import { theme } from '../../components/styledComponents'
import ReassignModal from '../../components/Modal/ReassignModal'
import PageLoad from '../../components/PageLoad/PageLoad'
/////////ASSETS/////////
import Alert from 'material-ui/svg-icons/alert/warning'

/////////STYLED/////////
const FullowUpAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: flex-start;
  & > *:last-child div {
    max-width: initial !important;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin: 20px;
`
const WarningText = styled.p`
  color: ${theme.colors.red};
  text-align: center;
  margin-top: 5px;
`
/////////STYLED/////////

class OrderDetail extends Component {
  constructor(props) {
    super(props)
    this.doSimulation = true
    this.state = {
      showCancelOrderConfirmation: false,
      showHelpComponent: false,
      warningMessage: '',
      openModalReassign: false,
      openReassignConfirm: false,
      reassign: {},
      reassignLoaded: false,
      gmSelected: 'test'
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    const { orders, routeParams } = this.props
    const order = orders ? orders[routeParams.id] : null
    const cdeId = !_.isEmpty(orders.orders) ? orders.orders.iD_Commande : this.props.params.id
    new Promise((resolve, reject) => { resolve(this.props.apiGetOrderArticle(cdeId)) }).then((res)=>{
      this.doSimulation = false
      let hasArticlesAdded = false
      if(this.props.orders[routeParams.id]) {
        const { commande_Articles, iD_Commande } = this.props.orders[routeParams.id]
        _.toArray(commande_Articles).map(commande_Article => {
          if(commande_Article.status.idStatus == 29) hasArticlesAdded = true
        })
        this.props.apiOrderSapSimulate({ iD_Commande, iD_Status: 1, articleAdded:hasArticlesAdded })
      }
      else if (order) {
        if (!_.isEmpty(orders.orders)) {
          const { iD_Status_Commande, iD_Commande } = orders.orders
          _.toArray(orders.orders.commande_Articles).map(commande_Article => {
            if(commande_Article.status.idStatus == 29) hasArticlesAdded = true
          })
          this.props.apiOrderSapSimulate({ iD_Commande, iD_Status: iD_Status_Commande, articleAdded:hasArticlesAdded })
        } else {
          const { iD_Commande, status: { idStatus } } = order
          _.toArray(order.commande_Articles).map(commande_Article => {
            if(commande_Article.status.idStatus == 29) hasArticlesAdded = true
          })
          const iD_Status_Commande = !_.isUndefined(order.iD_Status_Commande) ? order.iD_Status_Commande : idStatus
          this.props.apiOrderSapSimulate({ iD_Commande, iD_Status: iD_Status_Commande, articleAdded:hasArticlesAdded })

        }
      }})
  }

  componentWillReceiveProps(nextProps) {
    const { routeParams, trl } = this.props
    const { orders } = nextProps
    const order = orders[routeParams.id]
    this.setState({orderId: this.props.params.id})
    /*if(orders[routeParams.id] && orders[routeParams.id].commande_Articles, this.props.orders && this.props.orders[routeParams.id] && this.props.orders[routeParams.id].commande_Articles){
      if(_.toArray(this.props.orders[routeParams.id].commande_Articles).length != _.toArray(orders[routeParams.id].commande_Articles).length){
        let hasArticlesAdded = false
        _.toArray(orders[routeParams.id].commande_Articles).map(commande_Article => {
          if(commande_Article.status.idStatus == 29) hasArticlesAdded = true
        })
        if(hasArticlesAdded) this.props.apiOrderSapSimulate({ iD_Commande: routeParams.id, articleAdded: true })
      }
    }*/
    if (order && !order.notUpdateState && order.iD_Adresse_Livraison !== null && !this.doSimulation) this.doSimulation = true
    if (orders.isDetailLoading) {
      if (order && !order.isAddItemButtonShown ) {
        const tradKey = trl.order__duplicated_warning
        const reg = new RegExp('\\\\n', 'g')
        const modifyText = tradKey.length > 0 ? tradKey.replace(reg, '<br>') : ''
        this.setState({ warningMessage: modifyText })
      }
    }
    if(this.props.orders.openReassign != nextProps.orders.openReassign){
      this.setState({ openModalReassign: nextProps.orders.openModalReassign })
    }
    if(this.props.orders.openReassignConfirm != nextProps.orders.openReassignConfirm){
      if(!nextProps.orders.openModalReassign) {
        this.props.apiShowModalReassignConfirm({openReassignConfirm : false})
      }
      this.setState({ openReassignConfirm: nextProps.orders.openReassignConfirm })
    }
    if(!_.isNull(nextProps.orders.reassign)){
      this.setState({ reassign: nextProps.orders.reassign })
    }
    if(this.props.orders.reassignLoaded != nextProps.orders.reassignLoaded){
      this.setState({ reassignLoaded: nextProps.orders.reassignLoaded })
    }
    if(this.props.orders.orderId != nextProps.orders.orderId){
      this.setState({ orderId: nextProps.orders.orderId })
      this.setState({newDeliveryZone: undefined})
      this.setState({newGmAlias: undefined})
      this.setState({newGmName: undefined})
      this.setState({gmSelected: ''})
    }
    if(this.props.orders.reassignUpdated != nextProps.orders.reassignUpdated) {
      this.componentDidMount()
      this.setState({newDeliveryZone: undefined})
      this.setState({newGmAlias: undefined})
      this.setState({newGmName: undefined})
      this.setState({gmSelected: ''})
      this.props.apiResetReassignUpdated()
    }
  }

  componentWillUnmount() {
    this.props.resetOrderArticle(this.props.params.id)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  toggleCancelOrder = () => this.setState({ showCancelOrderConfirmation: !this.state.showCancelOrderConfirmation })

  handleCancelOrder = () => {
    this.props.apiUpdateOrderStatusCancel(this.props.routeParams.id)
    this.toggleCancelOrder()
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { orders, trl, routeParams, myRole, companies } = this.props
    const order = orders ? orders[routeParams.id] : null
    const company = companies.companyType
    if (!order || !order.possible_Delivery_Addresses || !this.props.orders[this.props.params.id] || this.props.orders.loading) return (<PageLoad text={ trl.common__loading } size={ 80 } thickness={ 9 }/>)
    const ownerShip = hasOwnerShipIfFac(order)
    return (
      <div>
        <div>
          <PageHeader
            title={stringformat(trl.order__title, order.iD_Commande || '')}
            subtitle={order.status.idStatus !== 2 ? trl.order__subtitle : trl.order__subtitle_canceled}
            base64Logo={company ? company.base64Logo : null}
          />
          <SC.PageGutter>
            <OrderDetailInformation
              trl={trl}
              order={order}
              myRole={myRole}
              handleCancelOrder={this.handleCancelOrder}
              deliveryZoneDisabled={order.status.idStatus > 8}
            />
            {!_.isNull(order.commande_Articles) && order.commande_Articles &&
              <TableWrapper hideHeader={true}>
                <OrderDetailTable data={_.toArray(order.commande_Articles)}/>
              </TableWrapper>}
            {orders.detailLoading && orders.isDetailLoading && (
              !order.isAddItemButtonShown  ? (
                <WarningText dangerouslySetInnerHTML={{ __html: this.state.warningMessage }}></WarningText>
              ) : (
                <ButtonWrapper>
                  <SC.ButtonPA_M
                    disabled={!order.isAddItemButtonEnabled}
                    onClick={() => this.context.router.push(`${c.orderDetail}${order.iD_Commande}${c.orderAddItem}`)}
                  >
                    {trl.order__add_item_btn}
                  </SC.ButtonPA_M>
                </ButtonWrapper>
              )
            )}
            {order.isAddItemButtonShown  && <div></div>}
            <br />
            <p style={{ marginBottom: 0 }}>{trl.orderDetail_Text_FixVariable}</p>

            <p>
            <span style={{ transform: 'translateY(5px)', marginRight: 5, display: 'inline-block' }}>
              <Alert style={{ color: theme.colors.red }} />
            </span>
              {trl.orderDetail_Text_SelectDeliveryZone}
            </p>
            <br />
            <FullowUpAndButtonContainer>
              <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ position: 'relative', width: '100%' }}>
                  <img
                    src={require('../../assets/centre_appel.png')}
                    alt="call center"
                    style={{ marginBottom: 30, display: 'block', cursor: 'pointer', width: 50 }}
                    onMouseEnter={() => this.setState({ showHelpComponent: true })}
                    onMouseLeave={() => this.setState({ showHelpComponent: false })}
                  />
                  {this.state.showHelpComponent && <HelpComponent />}
                </div>
                <div style={{ position: 'relative', width: '100%' }}>
                  <div style={{ position: 'relative', float: 'left', width: '30%' }}>
                    <SC.ButtonR_L onClick={() => this.context.router.push(c.followUp)}>
                      {trl.orderDetail_Btn_Back}
                    </SC.ButtonR_L>
                  </div>
                  <div style={{ position: 'relative', float: 'right', textAlign: 'right' }}>
                    <DisplayActionsInformation
                      trl={trl}
                      order={order}
                      myRole={myRole}
                      showOnlyProcess={true}
                      ownerShip={ownerShip}
                    />
                  </div>
                </div>
              </div>
            </FullowUpAndButtonContainer>
          </SC.PageGutter>
        </div>
        {this.state.reassignLoaded &&
          <ReassignModal data={{
            openReassign: this.props.orders.openReassign,
            openReassignConfirm: this.props.orders.openReassignConfirm,
            reassignLoaded: this.state.reassignLoaded,
            orderId: this.state.orderId,
            currentGmName: this.state.reassign.currentGmName,
            currentOwnerEmail: this.state.reassign.currentOwnerEmail,
            currentDeliveryZone: this.state.reassign.currentDeliveryZone,
            currentGmAlias: this.state.reassign.currentGmAlias,
            availableGmPartners: this.state.reassign.availableGmPartners,
          }}>
          </ReassignModal>}
      </div>
    )
  }
}

OrderDetail.contextTypes = {
  router: PropTypes.object.isRequired
}

OrderDetail.propTypes = {
  apiGetOrderArticle: PropTypes.func.isRequired,
  apiUpdateOrderStatusCancel: PropTypes.func.isRequired,
  //apiGetCompanies: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  languageName: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired
}

const mapStateToProps = ({ orders, languages, auth, companies, companyType }) => ({
  orders,
  companies,
  companyType,
  languageName: languages.siteLanguage.name,
  myRole: auth.user_role
})

export default connect(mapStateToProps, {
  apiGetOrderArticle,
  apiUpdateOrderStatusCancel,
  apiOrderSapSimulate,
  apiUpdateOrderGeographicalArea,
  GetOrderCompanyByType,
  apiShowModalReassign,
  apiShowModalReassignConfirm,
  apiPutReassignOrder,
  apiResetReassignUpdated,
  resetOrderArticle
})(OrderDetail)
