////////LIBRARY/////////
import React, { Component } from 'react'
import _, { isEqual, isNull, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
///////COMPONENTS///////
import { PageHeader } from '../../components/sharedComponents'
import OrderSkuTable from '../../components/Sematable/Tables/OrderSkuTable'
import AztecComponent from '../../components/Aztec'
import Bat from '../../components/Bat/Bat'
import { checkRoleHasRightsToFunction } from '../../containers/misc/allowedRolesPerFunction'
import { Step, Stepper, StepButton } from 'material-ui/Stepper'
import {
  apiGetOrdersAndOrderArticles,
  apiGetOrderArticle,
  apiUpdateDvcs,
  apiUpdateSkuDvss,
  apiUpdateCompositions
} from '../../actions/orders'
import {
  updateVisibleSku,
  apiEditSku,
  apiAddSku,
  apiDvcs,
  apiCompo,
  apiQualifyAll,
  apiConfirmbat,
  apiDvOnfocusout
} from '../../actions/visibility.js'
import { theme } from '../../components/styledComponents'
import ShowImageAction from '../../components/Sematable/ColumnHelpers/ShowImage'
import StaticVariable from '../../components/Sematable/ColumnHelpers/StaticVariable'
import * as SC from '../../components/styledComponents'
import * as c from '../../constants'
import { hasOwnerShipIfFac } from '../../utils/apiUtils'
import CircularProgress from 'material-ui/CircularProgress'

/////////STYLED/////////
const ShowImageActionWrapper = styled.div`
  width: 25%;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`
const TableWrapper = styled.div`
  margin: 0 auto;
  background-color: ${theme.colors.lightGrey};
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  flex-flow: row nowrap;
  & > *:last-child {
    align-self: flex-end;
  }
  & > *:nth-child(2) {
    margin: 0 auto;
  }
`
const ItemType = styled.p`
  & > div {
    display: inline;
  }
`
const ConnectorStyled = styled.span`
  background: #bebdbd;
  width: 2px;
  height: 31px;
  margin: 0 20px;
`
/////////STYLED/////////

const styles = {
  root: {
    width: '100%',
    backgroundColor: '#dddddd'
  },
  content: {
    margin: '30px 16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actions: {
    marginTop: '2em',
    display: 'flex',
    flexDirection: 'row'
  },
  backButton: {
    marginRight: 12,
    boxShadow: 'none'
  },
  stepper: {
    flexWrap: 'wrap',
    maxWidth: '1000px',
    justifyContent: 'flex-start'
  },
  step: {},
  stepButton: { color: 'red', fontSize: '12px' },
  iconContainer: { display: 'none' },
  label: { fontSize: 12, color: '#bebdbd' },
  labelActive: { fontSize: 12, color: 'black' },
  circular: {
    display: 'flex',
    alignItems: 'center',
    margin: '2px 15px 2px 0px'
  }
}

const restrictedCompoArticles = [
  /* 9015 */ // ZVCARELAB01-OPT1 - Care label of Zadig & Voltaire
]

class ArticleVariable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stepIndex: 0,
      hasCompo: false,
      hasCommon: false,
      hasVariable: false,
      completed: {},
      articleChampCommonData: [],
      articleChampCompo: [],
      articleChampListSKU: [],
      loading: false,
      stepperItems: [],
      showBAT: false,
      hasRestrictedCompos: false,
      pictureLogo: null
    }
    this.handelUpdateOrAddSku = this.handelUpdateOrAddSku.bind(this)
    this.handelApiQualifyAll = this.handelApiQualifyAll.bind(this)
    this.onFocusOutCommonData = this.onFocusOutCommonData.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    const { orders, routeParams, ordersList } = nextProps

    const { orderId } = routeParams
    const { trl } = this.props
    if (!_.isEqual(orders, this.props.orders)) {
      const articleChampCommonData = _.get(orders, `[${orderId}].article_Champ_CommonData`, [])
      const order = _.get(orders, `[${orderId}]`, {})
      const articleChampCompo = _.get(orders, `[${orderId}].article_Champ_Compo`, [])
      //const articleChampListSKU = _.get(orders, `[${orderId}].listSKU`, [])
      if (!_.isEmpty(ordersList)) {
        this.setState({ articleChampListSKU: ordersList.listSKU })
      } else if (!_.isEmpty(order) && _.isNull(nextProps.orders.error)) {
        this.setState({ articleChampListSKU: order.listSKU })
      } else if (!_.isEmpty(order) && !_.isUndefined(nextProps.orders.error)) {
        if (!_.isUndefined(this.props.ordersList.listSKU)) {
          this.setState({ articleChampListSKU: this.props.ordersList.listSKU })
        } else {
          this.setState({ articleChampListSKU: order.listSKU })
        }
      }
      const articleChampVariableData = _.get(orders, `[${orderId}].article_Champ_VariableData`, [])
      const commandeArticle = _.get(orders, `[${orderId}]`, {})
      let hasCompo = commandeArticle.hasCompo
      const hasVariable = !_.isEmpty(commandeArticle.article_Champ_VariableData)
      const hasCommon = !_.isEmpty(commandeArticle.article_Champ_CommonData)
      if (isNull(commandeArticle.hasCompo) || isUndefined(commandeArticle.hasCompo)) hasCompo = false
      // If commandeArticle is not set, we cannot determine which step we are ...
      // commandeArticle can be set, but not the "article" fields requested to check the step...
      if (!isEqual(commandeArticle, {}) && (hasCompo || hasVariable || hasCommon)) {
        if (!hasCompo && this.state.stepIndex === 0) {
          this.setState({ stepIndex: 1, hasCompo: false })
        }
        if (hasCommon && !hasCompo) {
          this.setState({ stepIndex: 1, hasCompo: false, hasCommonData: true })
        }
        if (hasVariable && !hasCompo && !hasCommon) {
          this.setState({ stepIndex: 2, hasCompo: false, hasCommonData: false })
        }
        if (hasVariable && this.state.stepIndex === 2 && !hasCompo) {
          this.setState({ stepIndex: 2, hasCompo: false, hasCommonData: false })
        }
        if (hasCommon && !hasCompo && hasVariable && this.state.stepIndex === 3) {
          this.setState({ stepIndex: 3, hasCompo: false, hasCommonData: true })
        }
      }
      if (!_.isEmpty(commandeArticle.article_Champ_VariableData)) {
        this.setState({
          hasVariable: true
        })
      }
      if (!_.isEmpty(commandeArticle.article_Champ_Compo)) {
        this.setState({
          hasCompo: true
        })
      }
      if (!_.isEmpty(commandeArticle.article_Champ_CommonData)) {
        this.setState({
          hasCommon: true
        })
      }
      this.setState({ hasRestrictedCompos: /* !restrictedCompoArticles.includes(commandeArticle.iD_Article) */true})
      /*   const showBAT = _.get(commandeArticle, 'viewBatAllowed', true)
        this.setState({ showBAT: showBAT }) 
   */
      const showBAT = !_.isEmpty(ordersList) ? ordersList.viewBatAllowed : order.viewBatAllowed
      this.setState({ showBAT: showBAT })
      this.setState({ articleChampCommonData, articleChampCompo, articleChampVariableData })
      this.setState({
        stepperItems: [
          { label: trl.order_article_var__step_compo_title.toUpperCase() || '', disabled: false, hide: !hasCompo },
          { label: trl.order_article_var__step_dvc_title.toUpperCase() || '', disabled: false, hide: !hasCommon },
          { label: trl.order_article_var__step_dvs_title.toUpperCase() || '', disabled: false, hide: false },
          { label: trl.order_article_var__step_bat_title.toUpperCase() || '', disabled: !showBAT, hide: false }
        ]
      })
    }
  }

  componentDidMount() {
    const { routeParams } = this.props
    const { orderId, idCommandeArticle } = routeParams
    this.props.apiGetOrderArticle(orderId, idCommandeArticle)
    this.setState({ loading: true })
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleNext = () => {
    const { orderId, idCommandeArticle } = this.props.routeParams
    const { stepIndex } = this.state
    switch (stepIndex) {
      case 0:
        this.aztecForm.triggerSubmit()
        break
      case 1:
        this.aztecForm2.triggerSubmit()
        break
      case 3:
        this.props.apiConfirmbat(orderId, idCommandeArticle)
        break
      default:
        this.setState({
          stepIndex: stepIndex + 1,
          finished: stepIndex >= 3
        })
    }
  }

  handlePrev = () => {
    const { stepIndex, hasCompo } = this.state
    const { orderId } = this.props.routeParams
    const { orders } = this.props
    const commandeArticle = _.get(orders, `[${orderId}]`, {})
    const hasCommonData = !_.isEmpty(commandeArticle.article_Champ_CommonData) ? true : false
    if ((!hasCompo && stepIndex > 1) || (hasCompo && stepIndex) > 0) {
      this.setState({ stepIndex: stepIndex - 1 })
    }
    if (!hasCommonData && stepIndex > 1) {
      this.setState({ stepIndex: 0 })
    }
    if (stepIndex === 3 && !hasCommonData && !hasCompo) {
      this.setState({ stepIndex: 2 })
    }
    if (stepIndex === 3 && !hasCommonData) {
      this.setState({ stepIndex: 2 })
    }
  }

  handelApiQualifyAll(listSku) {
    const { orderId, idCommandeArticle } = this.props.routeParams
    const QualiFySkuInputModel = {
      ID_Order: orderId,
      ID_SaleOrderItem: idCommandeArticle,
      ListDvs: listSku
    }
    this.props.apiQualifyAll(orderId, idCommandeArticle, QualiFySkuInputModel)
  }

  handelUpdateOrAddSku(skuLine) {
    const idSku = _.get(skuLine, 'aditionnelData.iD_SKU', null)
    const { orderId, idCommandeArticle } = this.props.routeParams
    const skuInputModel = {
      ID_Order: orderId,
      ID_SaleOrderItem: idCommandeArticle,
      ListDvs: skuLine.Aztec_List
    }
    if (idSku) {
      skuInputModel.iD_SKU = idSku
      this.props.apiEditSku(orderId, idCommandeArticle, idSku, skuInputModel)
    } else {
      this.props.apiAddSku(orderId, idCommandeArticle, skuInputModel)
    }
  }

  handleSubmit(formData, index) {
    const { orderId, idCommandeArticle } = this.props.routeParams
    const { hasCommon, hasCompo, hasVariable } = this.state
    let nextStep = 0
    const dataFormOutput = {
      ID_Order: parseInt(orderId, 10),
      ID_SaleOrderItem: parseInt(idCommandeArticle, 10),
      ListDvc: formData.Aztec_List
    }
    // Extra data if item has restricted compos
    if (!_.isUndefined(formData.hasRestrictedCompos)) {
      dataFormOutput.MatCertif1Id =
        formData.MatCertif1Id !== false && !_.isUndefined(formData.MatCertif1Id) ? formData.MatCertif1Id : null
      dataFormOutput.MatCertif1Percent =
        formData.MatCertif1Percent !== false && !_.isUndefined(formData.MatCertif1Percent)
          ? formData.MatCertif1Percent
          : null
      dataFormOutput.MatCertif2Id =
        formData.MatCertif2Id !== false && !_.isUndefined(formData.MatCertif2Id) ? formData.MatCertif2Id : null
    }
    if (index === 0) {
      // Proper modification ?
      this.props.apiCompo(orderId, idCommandeArticle, dataFormOutput)
    }
    if (index === 1 || (index === 0 && hasCommon && !hasCompo)) {
      this.props.apiDvcs(orderId, idCommandeArticle, dataFormOutput)
    }
    if (hasVariable && index === 0 && !hasCommon && hasCompo) {
      nextStep = 2
    } else {
      nextStep = index + 1
    }
    this.setState({
      stepIndex: nextStep,
      finished: index + 2 >= 2
    })
  }
  
  getLabelStyle(index) {
    return this.state.stepIndex === index ? styles.labelActive : styles.label
  }

  isBatActivate() {
    if (this.state.stepIndex !== 3) {
      return true
    }
    const { orders, myRole, routeParams } = this.props
    const { orderId } = routeParams
    const commandeArticle = _.get(orders, `[${orderId}]`, {})
    if (!checkRoleHasRightsToFunction(myRole, c.isFacOrFaca)) {
      return false
    }
    if (commandeArticle.iD_Status_Commande > 8) {
      return false
    }
    return true
  }

  articleVariableChangeStep(stepIndex) {
    this.setState({ stepIndex })
  }

  onFocusOutCommonData(itemModel) {
    const { routeParams } = this.props
    const { orderId, idCommandeArticle } = routeParams
    const m = {
      ...itemModel
    }
    this.props.apiDvOnfocusout(orderId, idCommandeArticle, m)
  }

  handleTestClick() {
    const { routeParams } = this.props
    this.context.router.push(`${c.orderDetail}${routeParams.orderId}`)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  renderYesNo = (v) => <span>{v ? this.props.trl.common__yes : this.props.trl.common__no}</span>

  render() {
    const { trl, routeParams, orders, ordersList, batLoading, isLoading } = this.props
    const { orderId, idCommandeArticle } = routeParams
    const currentOrder = _.get(orders, `currentOrder.commande_Articles`, [])
    const cca = currentOrder.find((ca) => parseInt(ca.iD_Commande_Article, 10) === parseInt(idCommandeArticle, 10))
    const { stepIndex, articleChampCompo, articleChampListSKU, hasCompo, hasCommon, hasRestrictedCompos } = this.state
    const commandeArticle = _.get(orders, `[${orderId}]`, {})
    //  we remove loading that we init in didMount by a test if we have loaded commandeArticle to prevent undefined data on page loading
    if (_.isUndefined(commandeArticle.article_Name) || this.props.orders.isLoadingSKU || batLoading || isLoading) {
      // Not adding additional loader, returning null instead
      return null
    } else {
      const order = _.get(orders, `[${orderId}]`, {})
      const commandeArticle = _.get(orders, `[${orderId}]`, {})
      const articleChampCommonData = _.get(commandeArticle, `article_Champ_CommonData`, {})
      const orderCanalIsFile = _.get(order, `order_Canal_isFile`, false)
      const hasOwner = hasOwnerShipIfFac(orders[orderId])
      if(!isUndefined(order.brandLogo) && !isNull(order.brandLogo) && isNull(this.state.pictureLogo)) this.setState({pictureLogo: order.brandLogo})
      return (
        <div>
          <PageHeader
            title={
              !_.isUndefined(commandeArticle.article_Name)
                ? `${trl.order_article_var__title.replace('{0}', commandeArticle.iD_Article_Externe_Enseigne)} ${commandeArticle.article_Name
                }`
                : null
            }
            brandName={commandeArticle.enseigne_Name}
            subtitle={`${trl.order_EditVariableItem_Subtitle.replace('{0}', commandeArticle.iD_Commande)}`}
            base64Logo={this.state.pictureLogo}
          />
          <SC.PageGutter>
            <TableWrapper style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 60 }}>
              {/* LEFT COLUMN */}
              <ShowImageActionWrapper>
                <ShowImageAction row={commandeArticle} big={true} size="200px" />
              </ShowImageActionWrapper>
              {/* MIDDLE COLUMN */}
              <div style={{ width: '30%', fontSize: 13 }}>
                <p style={{ fontWeight: 'bold', fontSize: 16 }}>{trl.order_EditFixItem_Text_ItemInfo}</p>
                <br />
                <p>
                  {trl.order_EditFixItem_Text_BrandItem}: {commandeArticle.iD_Article_Externe_Enseigne}
                </p>
                <p>
                  {trl.order_EditFixItem_Text_Design}: {commandeArticle.article_Name}
                </p>
                <ItemType>
                  {trl.order_EditFixItem_Text_ItemType}: <StaticVariable row={commandeArticle} />
                </ItemType>
                <br />
                <p>
                  {trl.order_EditFixItem_Text_Minimum}: {commandeArticle.article_Order_Min}
                </p>
                <p>
                  {trl.order_EditFixItem_Text_Packing}: {commandeArticle.article_Conditionnement}
                </p>
              </div>
            </TableWrapper>
            <div style={styles.root} id="articleVar">
              <Stepper linear={false} style={styles.stepper} connector={<ConnectorStyled />}>
                {!_.isEmpty(this.state.stepperItems) &&
                  this.state.stepperItems.map((step, index) => (
                    <Step
                      completed={false}
                      active={stepIndex === index}
                      style={step.hide && { display: 'none', visibility: 'hidden' }}
                      className={step.hide ? 'hideThisst' : ''}
                    >
                      <StepButton
                        className="step-button"
                        style={{ ...styles.stepButton, borderColor: `${stepIndex === index ? 'grey' : 'transparent'}` }}
                        iconContainerStyle={styles.iconContainer}
                        disabled={step.disabled}
                      >
                        <span style={this.getLabelStyle(index)}>{step.label}</span>
                      </StepButton>
                    </Step>
                  ))}
              </Stepper>
            </div>
            <div style={{ marginTop: 60 }} />
            {/* ORDER SKU TABLE */}
            {stepIndex === 2 && (
              <div>
                <p>{trl.order_EditVariableItem_Text_ClicEdit}</p>
                <p> </p>
                <OrderSkuTable
                  data={articleChampListSKU}
                  //articleVariable={commandeArticle}
                  // if ordersList is empty, is means that dvcs call was not called
                  articleVariable={_.isEmpty(ordersList) ? order : ordersList}
                  orderCanalIsFile={orderCanalIsFile}
                  trl={trl}
                  handelUpdateOrAddSku={this.handelUpdateOrAddSku}
                  handelApiQualifyAll={this.handelApiQualifyAll}
                  isDisabled={commandeArticle.iD_Status_Commande >= 8 || !hasOwner}
                  ref={(aztecFormSku) => (this.aztecFormSku = aztecFormSku)}
                  routeParams={routeParams}
                />
              </div>
            )}
            <div style={{ marginTop: 50 }} />
            {/* for first step index, aztec with dvCs */}
            {stepIndex === 0 && (
              <div>
                <AztecComponent
                  guid={`ArticleVariableForm0_${idCommandeArticle}`}
                  data={articleChampCompo}
                  trl={trl}
                  isDisabled={commandeArticle.iD_Status_Commande >= 8 || !hasOwner}
                  submitAction={(m) => this.handleSubmit(m, 0)}
                  ref={(aztecForm) => (this.aztecForm = aztecForm)}
                  hasRestrictedCompos={hasRestrictedCompos}
                />
              </div>
            )}
            {/* for second step, fieldArray with compositions */}
            {stepIndex === 1 && (
              <AztecComponent
                guid={`commonData_${idCommandeArticle}`}
                data={articleChampCommonData}
                trl={trl}
                isDisabled={commandeArticle.iD_Status_Commande >= 8 || !hasOwner}
                submitAction={(m) => this.handleSubmit(m, 1)}
                ref={(aztecForm2) => (this.aztecForm2 = aztecForm2)}
                handelFocusOut={this.onFocusOutCommonData}
                backupData={cca}
                hasRestrictedCompos={hasRestrictedCompos}
                hasCertifLogo={_.isEmpty(ordersList) ? order.hasCertifLogo : ordersList.hasCertifLogo}
                hasCertifMessage={_.isEmpty(ordersList) ? order.hasCertifMessage : ordersList.hasCertifMessage}
              />
            )}
            {stepIndex === 3 && (
              <Bat
                articleVariableChangeStep={(stepIndex) => this.articleVariableChangeStep(stepIndex)}
                data={_.isEmpty(ordersList) ? order : ordersList}
                trl={trl}
                type="articleVariable"
                isDisabled={!hasOwner}
                dontShowCompo={!hasCompo}
                dontShowCommon={!hasCommon}
              />
            )}
            {/* STEPPER NEXT BACK */}
            <div style={styles.content}>
              <SC.ButtonR_L onClick={() => this.context.router.push(`${c.orderDetail}${routeParams.orderId}`)}>
                {trl.order_article__back_order_detail_btn}
              </SC.ButtonR_L>
              {stepIndex !== null && (
                <div style={styles.actions}>
                  <SC.ButtonR_L
                    style={{ marginRight: '20px' }}
                    onClick={this.handlePrev}
                    disabled={
                      stepIndex === 0 ||
                      (!this.state.hasCompo && stepIndex === 1) ||
                      (!this.state.hasCompo && !this.state.hasCommon && stepIndex === 2)
                    }
                  >
                    {trl.common__step_back}
                  </SC.ButtonR_L>
                  {batLoading && (
                    <div style={styles.circular}>
                      <CircularProgress style={styles.circularProgress} size={36} thickness={4} />
                    </div>
                  )}
                  <SC.ButtonPA_L
                    disabled={
                      (stepIndex !== 2 && !this.isBatActivate()) ||
                      (stepIndex === 2 && !this.state.showBAT) ||
                      (stepIndex === 3 && !hasOwner)
                    }
                    onClick={this.handleNext}
                  >
                    { stepIndex !== 2 ? trl.common__step_next : trl.articleVariable_btn_viewBat }
                  </SC.ButtonPA_L>
                </div>
              )}
            </div>
          </SC.PageGutter>
        </div>
      )
    }
  }
}

ArticleVariable.propTypes = {
  trl: PropTypes.object.isRequired,
  apiGetOrdersAndOrderArticles: PropTypes.func.isRequired,
  apiGetOrderArticle: PropTypes.func.isRequired,
  apiUpdateDvcs: PropTypes.func.isRequired,
  apiUpdateSkuDvss: PropTypes.func.isRequired,
  apiUpdateCompositions: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
  visibility: PropTypes.object.isRequired
}

ArticleVariable.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = ({ orders, visibility, auth }) => ({
  orders,
  visibility,
  myRole: auth.user_role,
  ordersList: orders.orders,
  batLoading: orders.batLoading
})

export default connect(mapStateToProps, {
  apiGetOrdersAndOrderArticles,
  apiGetOrderArticle,
  apiUpdateDvcs,
  apiUpdateSkuDvss,
  apiUpdateCompositions,
  updateVisibleSku,
  apiEditSku,
  apiAddSku,
  apiQualifyAll,
  apiConfirmbat,
  apiDvcs,
  apiCompo,
  apiDvOnfocusout
})(ArticleVariable)
