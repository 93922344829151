////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
///////COMPONENTS///////
import Paper from 'material-ui/Paper'
import CircularProgress from 'material-ui/CircularProgress'
import Key from 'material-ui/svg-icons/communication/vpn-key'
import passwordValidationFunction from '../misc/passwordValidation'
import { renderInput } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import * as c from '../../../constants'
import { confirmEmail } from '../../../actions/me'
import * as SC from '../../../components/styledComponents'
import { isEmpty, replace } from 'lodash'

const validate = (values, props) => {
  let errors = {}
  errors = { ...errors, ...passwordValidationFunction(values, props.trl) }
  return errors
}

class ConfirmEmail extends Component {

 	///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(formValues) {
    const { router } = this.context
    const { pathname, query } = router.location
    if(isEmpty(formValues) || formValues.passwordConfirmation != formValues.password) return
    const { password } = formValues
    let urlParams = JSON.parse(decodeURI(JSON.stringify(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"').replace('\\','').replace('null', '').replace('\\":}', '') + '"}'))
    const userModel = {
      UserId: urlParams.userid,
      Token: urlParams.token,
      NewPassword: password
    }
    // choose api endpoint according to if it is a confirm password or a reset password
    let url =
      pathname === `${c.userConfirm}/`
        ? `${c.apiUser}${urlParams.userid}${c.apiUserConfirm}`
        : `${c.apiUser}${urlParams.userid}${c.apiUserReset}` // Set first password and confirm account OR change password
    this.props.confirmEmail(userModel, url)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { error, handleSubmit, submitting, trl } = this.props
    return (
      <div style={styles.loginContainer}>
        <h1 style={styles.question}>{trl.user_confirm__title}</h1>
        <h2 style={styles.description}>{trl.user_confirm__subtitle}</h2>
        <Paper style={styles.paper}>
          <Key style={styles.topIcon} />
          <p style={styles.instruction}>{trl.user_confirm__help}</p>
          <ul style={styles.instruction}>
            <li>{trl.user_confirm__help_length}</li>
            <li>{trl.user_confirm__help_number}</li>
            <li>{trl.user_confirm__help_special}</li>
            <li>{trl.user_confirm__help_username}</li>
          </ul>
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field name="password" type="password" component={renderInput} label={trl.common__password} />
            <Field
              name="passwordConfirmation"
              type="password"
              component={renderInput}
              label={trl.common__password_confirm}
            />
            <div>
              {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
              <SC.ButtonPA_S
                style={{
                  ...styles.loginBtn,
                  'margin': '1rem 0' 
                }}
                disabled={submitting}
                type="submit"
              >
                {trl.user_confirm__save_btn || ' '}
              </SC.ButtonPA_S>
            </div>
            <div>{error && <p style={styles.loginError}>{error}</p>}</div>
          </form>
        </Paper>
      </div>
    )
  }
}

ConfirmEmail.contextTypes = {
  router: PropTypes.object.isRequired
}

ConfirmEmail.propTypes = {
  location: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired
}

ConfirmEmail = reduxForm({
  form: 'confirmUser',
  validate,
  touchOnBlur: false,
  errors: {}
})(ConfirmEmail)

export default connect(null, { confirmEmail })(ConfirmEmail)
