////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
/////////ASSETS/////////
import ZoomIn from 'material-ui/svg-icons/action/zoom-in'

const MyAddressesDisplayActions = ({ row, trl, handleOpenEdit }) => {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return <ZoomIn onClick={() => handleOpenEdit(row, true)} style={{ cursor: 'pointer' }} />
}

MyAddressesDisplayActions.propTypes = {
  row: PropTypes.object.isRequired,
  handleOpenEdit: PropTypes.func
}

export default MyAddressesDisplayActions
