////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import Toggle from 'material-ui/Toggle'
import { apiSwitchUserRole } from '../../actions/me'

class SwitchUserRole extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toogled: false
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelToggle(value) {
    this.props.apiSwitchUserRole()
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { companyType, trl, userRole } = this.props
    if (companyType !== 'BR') return null
    return (
      <div
        style={{
          width: '100%',
          bottom: '1px',
          display: 'flex',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          marginTop: '10px'
        }}
      >
        <Toggle
          label={trl.menu__switchBrandToGM}
          labelPosition="right"
          style={{ width: '80%', fontSize: '16px !important' }}
          onToggle={(e, value) => this.handelToggle(value)}
          defaultToggled={['FAC', 'FACA'].includes(userRole)}
        />
      </div>
    )
  }
}
SwitchUserRole.propTypes = {
  companyType: PropTypes.string,
  userRole: PropTypes.string,
  trl: PropTypes.object
}

const mapStateToProps = ({ auth, me }) => ({
  userId: auth.user_id,
  userRole: auth.user_role,
  companyType: me.company_type
})

export default connect(
  mapStateToProps,
  { apiSwitchUserRole,
   }
)(SwitchUserRole)
