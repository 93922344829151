///////COMPONENTS///////
import * as c from '../constants'
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET STOCK FOLLOWUP
function apiGetStockFollowUpRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_STOCK_FOLLOW_UP_REQUEST, endpointPath })
  }
}
function apiGetStockFollowUpSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_STOCK_FOLLOW_UP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_STOCK_FOLLOW_UP,
      payload: payload
    })
  }
}
function apiGetStockFollowUpFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_STOCK_FOLLOW_UP_FAILURE, endpointPath })
  }
}
export function apiGetStockFollowUp(data) {
  return u.getValueFromStore(
    c.apiGetStockFollowUp,
    h.headerPutWithToken(data),
    apiGetStockFollowUpRequest,
    apiGetStockFollowUpSuccess,
    apiGetStockFollowUpFailure
  )
}

// GET STOCK FOLLOWUP ID
function apiGetStockFollowUpIdRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_STOCK_FOLLOW_UP_ID_REQUEST, endpointPath })
  }
}
function apiGetStockFollowUpIdSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_STOCK_FOLLOW_UP_ID_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_STOCK_FOLLOW_UP_ID,
      payload: payload
    })
  }
}
function apiGetStockFollowUpIdFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_STOCK_FOLLOW_UP_ID_FAILURE, endpointPath })
  }
}
export function apiGetStockFollowUpId(id) {
  return u.getValueFromStore(
    `${c.apiGetStockFollowUpId}/${id}`,
    h.headerGetWithToken(),
    apiGetStockFollowUpIdRequest,
    apiGetStockFollowUpIdSuccess,
    apiGetStockFollowUpIdFailure,
    null,
    { forceUpdate: true }
  )
}

// GET STOCK FOLLOWUP EXPORT
function apiStockFollowUpExportRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_STOCK_FOLLOW_UP_REQUEST, endpointPath })
    dispatch(addAppShowLoading(true))
  }
}
function apiStockFollowUpExportSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_STOCK_FOLLOW_UP_SUCCESS, payload: res })
    dispatch(addAppHideLoading(true))
  }
}
function apiStockFollowUpExportFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_STOCK_FOLLOW_UP_FAILURE, endpointPath })
    dispatch(addAppHideLoading(true))
  }
}
export function apiStockFollowUpExport(params) {
  return u.getValueFromStore(
    `${c.apiGetStockFollowUpExport}`,
    h.headerPutWithToken(params),
    apiStockFollowUpExportRequest,
    apiStockFollowUpExportSuccess,
    apiStockFollowUpExportFailure,
    true
  )
}
