import * as c from '../constants'
const INITIAL_STATE = {
  batsPending: [],
  batToTreat: {
    isLoading: false,
    list: [],
    approuveRejectAction: false
  },
  isLoading: true,
  errors: {},
  additional: {},
  batHistory: {
    list: [],
    isLoading: true,
    errors: {}
  },
  // NEW FILTER
  filters: {},
  detail: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    //NEW FILTER
    case c.API_GET_BATS_PENDING:
      action.payload.filters.availableByParameters.splice(0, 0, 'common__all')
      return {
        ...state,
        filters: action.payload.filters,
        detail: action.payload.detail,
        isLoading: false
      }
    case c.API_GET_BATS_PENDING_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case c.API_GET_BATS_PENDING_FAILURE:
      return {
        ...state,
        isLoading: false
      }

    //END NEW FILTER
    case c.API_GET_PATS_PENDING_REQUEST:
      return { ...state, isLoading: true, errors: {} }
    case c.API_GET_PATS_PENDING_FAILURE:
      return { ...state, isLoading: false, errors: action.err }
    case c.API_GET_PATS_PENDING_SUCCESS:
      return { ...state, batsPending: action.payload, isLoading: false, errors: {}, additional: {} }

    case c.API_GET_PATS_TO_TREAT_REQUEST:
      return {
        ...state,
        batToTreat: {
          isLoading: true,
          list: [],
          errors: {},
          approuveRejectAction: false,
          tempRefrech: false
        },
        errors: {}
      }
    case c.API_GET_PATS_TO_TREAT_FAILURE:
      return {
        ...state,
        batToTreat: {
          isLoading: false,
          list: [],
          errors: action.err,
          approuveRejectAction: false,
          tempRefrech: false
        },
        errors: {}
      }
    case c.API_GET_PATS_TO_TREAT_SUCCESS:
      return {
        ...state,
        batToTreat: {
          isLoading: false,
          list: action.payload,
          errors: {},
          approuveRejectAction: true,
          tempRefrech: false // temps var pour refrech la page
        },
        errors: {},
        additional: {}
      }
    case c.API_BAT_APPROUVE_REJECT_REQUEST:
      return {
        ...state,
        batToTreat: {
          ...state.batToTreat,
          isLoading: true,
          approuveRejectAction: false,
          tempRefrech: false, // temps var pour refrech la page,
          errors: {}
        },
        errors: {}
      }
    case c.API_BAT_APPROUVE_REJECT_FAILURE:
      return {
        ...state,
        batToTreat: {
          ...state.batToTreat,
          isLoading: false,
          errors: { message: action.payload },
          approuveRejectAction: false,
          tempRefrech: false // temps var pour refrech la page
        },
        errors: {}
      }
    case c.API_BAT_APPROUVE_REJECT_SUCCESS:
      return {
        ...state,
        batToTreat: {
          isLoading: false,
          list: action.payload,
          errors: {},
          approuveRejectAction: true,
          tempRefrech: true // temps var pour refrech la page
        },
        errors: {},
        additional: {}
      }
    case c.API_BAT_HISTORY_REQUEST:
      return {
        ...state,
        batHistory: {
          ...state.batHistory,
          isLoading: true,
          list: []
        }
      }
    case c.API_BAT_HISTORY_SUCCESS:
      return {
        ...state,
        batHistory: {
          ...state.batHistory,
          isLoading: false,
          list: action.payload
        }
      }

    default:
      break
  }
  return state
}
