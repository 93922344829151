////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import Drawer from 'material-ui/Drawer'
import { spacing, typography } from 'material-ui/styles'
import * as c from '../../constants'
import ADMGlobalMenu from './Menus/ADMGlobalMenu'
import CSPMenu from './Menus/CSPMenu'
import CSSMenu from './Menus/CSSMenu'
import EACMenu from './Menus/EACMenu'
import EAdminMenu from './Menus/EAdminMenu'
import FACMenu from './Menus/FACMenu'
import FACAMenu from './Menus/FACAMenu'
import LPAdminMenu from './Menus/LPAdminMenu'
import LSAdminMenu from './Menus/LSAdminMenu'
import PAdminMenu from './Menus/PAdminMenu'
import POPMenu from './Menus/POPMenu'
import { theme, H2 } from '../styledComponents'
import SwitchUserRole from './SwitchUserRole'

const LeftDrawer = props => {
  let { navDrawerOpen, menu, trl } = props
  const styles = {
    logo: {
      cursor: 'pointer',
      fontSize: 22,
      color: theme.colors.white,
      lineHeight: `${spacing.desktopKeylineIncrement}px`,
      fontWeight: typography.fontWeightLight,
      backgroundColor: theme.colors.black,
      padding: '0 25px',
      textAlign: `center`,
      height: 77,
      borderBottom: '1px solid black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    logoImg: {
      height: '28px',
      width: 'auto'
    },
    avatar: {
      div: {
        padding: '20px 25px 15px',
        backgroundColor: theme.colors.lightGrey,
        textAlign: `right`,
        color: typography.textFullBlack
      },
      span: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingTop: 30,
        display: 'block',
        color: typography.textFullBlack,
        wordWrap: 'break-word'
      }
    },
    leftDrawer: {
      zIndex: 1500,
      position: 'relative'
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  const handleMenu = menu => {
    switch (menu) {
      case c.ADMGlobal:
        return <ADMGlobalMenu trl={trl} />
      case c.CSP:
        return <CSPMenu trl={trl} />
      case c.CSS:
        return <CSSMenu trl={trl} />
      case c.EAC:
        return <EACMenu trl={trl} />
      case c.EAdmin:
        return <EAdminMenu trl={trl} />
      case c.FAC:
        return <FACMenu trl={trl} />
      case c.FACA:
        return <FACAMenu trl={trl} />
      case c.LPAdmin:
        return <LPAdminMenu trl={trl} />
      case c.LSAdmin:
        return <LSAdminMenu trl={trl} />
      case c.PAdmin:
        return <PAdminMenu trl={trl} />
      case c.POP:
        return <POPMenu trl={trl} />
      default:
        return null
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <Drawer style={styles.leftDrawer} docked={true} open={navDrawerOpen}>
      <div style={styles.logo}>
        <a href="#/">
          <img
            src={`${require('../../assets/ctc-logo-150.png')}`}
            title="Close To Clothes"
            alt="Close To Clothes"
            style={styles.logoImg}
          />
        </a>
      </div>
      <div style={styles.avatar.div}>
        <H2 style={styles.avatar.span}>{trl.menu__menu}</H2>
      </div>
      <div>{handleMenu(menu)}</div>
      <SwitchUserRole trl={trl} />
    </Drawer>
  )
}

LeftDrawer.propTypes = {
  navDrawerOpen: PropTypes.bool,
  menus: PropTypes.array,
  username: PropTypes.string
}

export default LeftDrawer
