////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'

class FOFUErrorsTableinputValueRow extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
        const string = this.props.row.input_Value
        const regex = new RegExp('\\\\n', 'g')
        const messageToSplit = string.split(regex)
        return (
            <div>
                { map(messageToSplit, e => ( <span>{e}<br></br></span> )) }
            </div>
        )
    }
}

FOFUErrorsTableinputValueRow.propTypes = {
    row: PropTypes.object,
}

FOFUErrorsTableinputValueRow.contextTypes = {
    store: React.PropTypes.object.isRequired
}

export default FOFUErrorsTableinputValueRow