////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
///////COMPONENTS///////
import AppBar from 'material-ui/AppBar'
import IconButton from 'material-ui/IconButton'
import { white } from 'material-ui/styles/colors'
import * as c from '../../constants'
import { theme, StyledLink, H6 } from '../styledComponents'
/////////ASSETS/////////
import JustPrintItImg from '../../assets/jpi-logo-150.png'
import Menu from 'material-ui/svg-icons/navigation/menu'

/////////STYLED/////////
const JustPrintIt = styled.img`
  height: 28px;
  width: auto;
  position: relative;
  left: 20px;
`
const MenuContainer = styled.div`
  display: flex;
  margin-top: 0px;
  margin-right: 40px;
  & > * {
    color: ${theme.colors.white};
    margin: 0 15px;
    cursor: pointer;
  }
  & > *:hover,
  & > *:focus {
    color: ${theme.colors.white};
    text-decoration: underline;
  }
`
const StyledSpan = styled.span`
  color: ${theme.colors.white};
  :hover {
    text-decoration: underline;
  }
`
/////////STYLED/////////

let RolesManager = []
RolesManager["ADMGlobal"] = 'ADMIN'
RolesManager["CSS"] = 'LB'
RolesManager["CSP"] = 'LB'
RolesManager["LSAdmin"] = 'LB'
RolesManager["LPAdmin"] = 'LB'
RolesManager["PAdmin"] = 'PS'
RolesManager["POP"] = 'PS'
RolesManager["EAdmin"] = 'BR'
RolesManager["EAC"] = 'BR'
RolesManager["FACA"] = 'MK'
RolesManager["FAC"] = 'MK'

let LanguagesManager = []
LanguagesManager['English'] = 'en'
LanguagesManager['French'] = 'fr'

class Header extends Component {

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelDownload() {
    // Get all infos for build the url   
    let xhr = new XMLHttpRequest()
    const role = localStorage.getItem('user_role')
    let lang = JSON.parse(localStorage.getItem('siteLanguage')).name
    // Build the first URL with the right lang and role
    let URL = '/user_guides/JPIGuide_' + RolesManager[role] + '_' + LanguagesManager[lang] + '.pdf'
    // Exception for ADMIN global because he's not define 
    // Actually we test if the fil exist with a simple request, and we wait the status
    xhr.open('GET', URL, false) 
    xhr.send()
    if(xhr.status !== 200) {
      // if the language is not find, we switch in En
      if(lang !== 'English'){
        URL = '/user_guides/JPIGuide_' + RolesManager[role] + '_en.pdf'
        xhr.open('GET', URL, false)
        xhr.send()
      }
      if(xhr.status !== 200){
        // default case
        URL = '/user_guides/JPIGuide_MK' + '_' + LanguagesManager[lang] + '.pdf'
        xhr.open('GET', URL, false) 
        xhr.send()
        if(xhr.status !== 200){
          URL = '/user_guides/JPIGuide_MK' + '_en.pdf'
          xhr.open('GET', URL, false) 
          xhr.send()
          if(xhr.status !== 200) return
        }
      }
    }
    return window.open(URL)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { styles, handleChangeRequestNavDrawer, showMenu, user, trl } = this.props
    const style = {
      appBar: {
        position: 'fixed',
        top: 0,
        overflow: 'hidden',
        maxHeight: 77,
        paddingTop: 10,
        paddingBottom: 10,
        left: 0,
        backgroundColor: theme.colors.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2102
      },
      menuButton: {
        marginLeft: 10
      },
      iconsRightContainer: {
        marginLeft: 20
      }
    }
    return (
      <div>
        <AppBar
          style={{ ...styles, ...style.appBar }}
          iconElementLeft={
            showMenu < 3 ? (
              <IconButton style={style.menuButton} onClick={handleChangeRequestNavDrawer}>
                <Menu color={white} />
              </IconButton>
            ) : (
              <JustPrintIt src={JustPrintItImg} title="Just Print It" alt="Just Print It" />
            )
          }
          iconElementRight={
            <MenuContainer>
              <StyledLink uppercase h6 to={c.myAccount}>
                {`${user.fullName}`}
              </StyledLink>
              <StyledLink uppercase h6 ><StyledSpan onClick={this.handelDownload}>
                {trl.common__navBar_PDFUserGuide}
              </StyledSpan></StyledLink>
              <StyledLink uppercase h6 to={c.contact}>
                {trl.common__navBar_contact}
              </StyledLink>
              <H6 onClick={this.props.handleLogout}>
                {trl.common__navBar_logOut}
              </H6>
            </MenuContainer>
          }
        />
      </div>
    )
  }
}

Header.propTypes = {
  styles: PropTypes.object,
  handleChangeRequestNavDrawer: PropTypes.func,
  handleLogout: PropTypes.func.isRequired,
  trl: PropTypes.object.isRequired
}

Header.contextTypes = {
  router: PropTypes.object.isRequired
}

export default Header
