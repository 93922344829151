////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable from 'sematable'
///////COMPONENTS///////
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer, TH } from '../../styledComponents'
import ShowImageAction from '../ColumnHelpers/ShowImage'
import { translate } from '../../../containers/HOC/Translation'

let columns = [
  { key: 'idDeliveryItem', header: translate('delivery_tableTitle_Visual'), primaryKey: true },
  { key: 'importationDate', header: translate('delivery_tableTitle_ImportationDate') },
  { key: 'referenceItem.enseigne_Name', header: translate('delivery_tableTitle_Brand') },
  { key: 'referenceItem.iD_Article_Externe_SAP', header: translate('delivery_tableTitle_CTCItem') },
  { key: 'referenceItem.article_Name', header: translate('delivery_tableTitle_Designation') },
  { key: 'referenceItem.article_Type_Name', header: translate('delivery_tableTitle_ItemType') },
  { key: 'referenceItem.article_Conditionnement', header: translate('delivery_tableTitle_Packing') },
  { key: 'quantityInitial', header: translate('delivery_tableTitle_QtyInitial') },
  { key: 'quantityAdded', header: translate('delivery_tableTitle_QtyAdded')},
  { key: 'quantityDeleted', header: translate('delivery_tableTitle_QtyRemoved')},
  { key: 'quantityTotalRequested', header: translate('delivery_tableTitle_QtyTotal') },
  { key: 'quantityMissing', header: translate('delivery_tableTitle_QtyMissing') }
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}

class DeliveryDetailTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, subFlow } = this.props
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm table-striped table-hover" style={{ marginBottom: 0 }}>
              <thead>
                <tr>
                  {columns.map((c, index) => (
                    <TH key={c.key}>{c.header}</TH>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={item.status.subFlow !== 'ANN' ? 'tableLigneRed' : ''}>
                      <td>
                        <ShowImageAction row={item.referenceItem} />
                      </td>
                      <td>
                        <DateFormatter children={item.importationDate} />
                      </td>
                      <td>{item.referenceItem.enseigne_Name}</td>
                      <td>{item.referenceItem.iD_Article_Externe_SAP}</td>
                      <td style={{ whiteSpace: 'nowrap' }}>{item.referenceItem.article_Name}</td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {item.referenceItem.article_Type_Name + ' / '}
                        {item.referenceItem.article_Fixe ? 'F' : 'V'}
                      </td>
                      <td>{item.referenceItem.article_Conditionnement}</td>
                      <td>{item.quantityInitial}</td>
                      <td>{item.quantityAdded}</td>
                      <td>{item.quantityDeleted}</td>
                      <td style={{ color: '#f00' }}>{item.quantityTotalRequested}</td>
                      <td>{subFlow >= '060' && subFlow !== 'ANN' ? item.quantityMissing : '-'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}

DeliveryDetailTable.contextTypes = {
  router: PropTypes.object.isRequired
}

DeliveryDetailTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  subFlow: PropTypes.string.isRequired,
  primaryKey: PropTypes.string.isRequired
}

export default sematable('DeliveryDetail', DeliveryDetailTable, columns, config)
