import * as c from '../constants'

const initialState = {
  items: [],
  isLoading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API_PUT_SOURCE_FILE_MODAL:
      return {
        ...state,
        items: action.payload,
        isLoading: false
      }
    case c.API_PUT_SOURCE_FILE_MODAL_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case c.API_PUT_SOURCE_FILE_MODAL_FAILURE:
      return {
        ...state,
        isLoading: false
      }

    default:
  }
  return state
}
