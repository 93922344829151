////////LIBRARY/////////
import { SubmissionError } from 'redux-form'
///////COMPONENTS///////
import * as c from '../constants'
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppAlert, addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET COMPANIES
function apiGetCompaniesRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_COMPANIES_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiGetCompaniesSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_COMPANIES_SUCCESS, payload: payload })
    dispatch(addAppHideLoading())
  }
}
function apiGetCompaniesFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_GET_COMPANIES_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
  }
}
export function apiGetCompanies() {
  return u.getValueFromStore(
    `${c.apiCompanies}`,
    h.headerGetWithToken(),
    apiGetCompaniesRequest,
    apiGetCompaniesSuccess,
    apiGetCompaniesFailure
  )
}

// GET COMPANIES units
function apiGetCompaniesIdRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_COMPANIES_ID_REQUEST, endpointPath })
  }
}
function apiGetCompaniesIdSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_COMPANIES_ID_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_COMPANIES_ID,
      payload: payload
    })
  }
}
function apiGetCompaniesIdFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_COMPANIES_ID_FAILURE, endpointPath })
  }
}
export function apiGetCompaniesId(idCompany) {
  return u.getValueFromStore(
    `${c.apiCompany}${idCompany}`,
    h.headerGetWithToken(),
    apiGetCompaniesIdRequest,
    apiGetCompaniesIdSuccess,
    apiGetCompaniesIdFailure,
    null,
    { forceUpdate: true }
  )
}

// UPDATE COMPANY
function apiUpdateCompanyRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_COMPANY_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiUpdateCompanySuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_COMPANY_SUCCESS, endpointPath })
    dispatch({ type: c.API_UPDATE_COMPANY, payload: res })
  }
}
function apiUpdateCompanyFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_COMPANY_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiUpdateCompany(m) {
  return u.getValueFromStore(
    `${c.apiCompany}${m.iD_Societe}`,
    h.headerPutWithTokenAndModel(m),
    apiUpdateCompanyRequest,
    apiUpdateCompanySuccess,
    apiUpdateCompanyFailure,
    false
  )
}

// GET COMPANIES file order
function apiGetCompanyFileOrderFormatsRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_COMPANY_FILE_ORDER_FORMATS_REQUEST, endpointPath })
  }
}
function apiGetCompanyFileOrderFormatsSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({
      type: c.API_GET_COMPANY_FILE_ORDER_FORMATS_SUCCESS,
      payload,
      endpointPath
    })
  }
}
function apiGetCompanyFileOrderFormatFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_COMPANY_FILE_ORDER_FORMATS_FAILURE, endpointPath })
  }
}
export function apiGetCompanyFileOrderFormats(id) {
  return u.getValueFromStore(
    `${c.apiCompany}${id}${c.apiFileOrderFormats}`,
    h.headerGetWithToken(),
    apiGetCompanyFileOrderFormatsRequest,
    apiGetCompanyFileOrderFormatsSuccess,
    apiGetCompanyFileOrderFormatFailure
  )
}
// ADD STANDART fixed
function apiAddStandardFixedRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_ADD_STANDARD_FIXED_REQUEST, endpointPath })
  }
}
function apiAddStandardFixedSuccess(endpointPath, payload) {
  return (dispatch, getState) => {
    dispatch(addAppHideLoading())
    dispatch({
      type: c.API_ADD_STANDARD_FIXED_SUCCESS,
      payload,
      endpointPath
    })
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(trl.common__toast_elementAdded, true))
  }
}
function apiAddStandardFixedFailure(endpointPath, err) {
  return (dispatch, getState) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_ADD_STANDARD_FIXED_FAILURE, endpointPath })
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(trl.common__toast_modificationError, true))
  }
}
export function apiAddStandardFixed(id) {
  return u.getValueFromStore(
    `${c.apiCompany}${id}${c.apiAddStandardFixed}`,
    h.headerPutWithToken(),
    apiAddStandardFixedRequest,
    apiAddStandardFixedSuccess,
    apiAddStandardFixedFailure
  )
}

// SAVE file order
function apiSaveFileOrderFormatRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_SAVE_FILE_ORDER_FORMAT_REQUEST, endpointPath })
  }
}
function apiSaveFileOrderFormatSuccess(endpointPath, payload, { callback }) {
  return (dispatch, getState) => {
    dispatch({
      type: c.API_SAVE_FILE_ORDER_FORMAT_SUCCESS,
      payload,
      endpointPath
    })
    callback(true)
  }
}
function apiSaveFileOrderFormatFailure(endpointPath, err, { callback }) {
  return (dispatch, getState) => {
    dispatch({ type: c.API_SAVE_FILE_ORDER_FORMAT_FAILURE, endpointPath })
    callback(false)
  }
}
export function apiSaveFileOrderFormat(data, callback) {
  return u.getValueFromStore(
    `${c.apiCompany}${data.companyId}${c.apiFileOrderFormats}/${data.fileOrderFormatId}`,
    h.headerPutWithToken(data),
    apiSaveFileOrderFormatRequest,
    apiSaveFileOrderFormatSuccess,
    apiSaveFileOrderFormatFailure,
    false,
    {
      callback
    }
  )
}

// GET COMPANY brand partner
function GetCompanyBrandPartnerRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_COMPANY_BRAND_PARTNER_REQUEST, endpointPath })
  }
}
function GetCompanyBrandPartnerSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({
      type: c.API_GET_COMPANY_BRAND_PARTNER_SUCCESS,
      endpointPath
    })
    dispatch({
      type: c.API_GET_COMPANY_BRAND_PARTNER,
      payload: payload
    })
  }
}
function GetCompanyBrandPartnerFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_COMPANY_BRAND_PARTNER_FAILURE, endpointPath })
  }
}
export function GetCompanyBrandPartner() {
  return u.getValueFromStore(
    c.apiCompanyPartnerBrands,
    h.headerGetWithToken(),
    GetCompanyBrandPartnerRequest,
    GetCompanyBrandPartnerSuccess,
    GetCompanyBrandPartnerFailure
  )
}

// GET ORDER COMPANY by type
function GetOrderCompanyByTypeRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_COMPANY_BY_TYPE_REQUEST, endpointPath })
  }
}
function GetOrderCompanyByTypeSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({
      type: c.API_GET_COMPANY_BY_TYPE_SUCCESS,
      payload: payload,
      endpointPath
    })
  }
}
function GetOrderCompanyByTypeFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_COMPANY_BY_TYPE_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function GetOrderCompanyByType(id) {
  return u.getValueFromStore(
    `${c.apiCompanyType}${id}/brand/items`,
    h.headerGetWithToken(),
    GetOrderCompanyByTypeRequest,
    GetOrderCompanyByTypeSuccess,
    GetOrderCompanyByTypeFailure,
    null,
    { forceUpdate: true }
  )
}

// RESET ORDER COMPANY BY TYPE
export function resetOrderCompanyByType() {
  return { type: c.RESET_ORDER_COMPANY_BY_TYPE }
}

// ADD NEW CARRIER
function apiCreateCarrierRequest(endpointPath) {
  return { type: c.API_CREATE_CARRIER_REQUEST, endpointPath }
}
function apiCreateCarrierSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_CREATE_CARRIER_SUCCESS, endpointPath })
    dispatch({ type: c.UPDATE_CARRIER, payload: res, idSocieteKey: res.iD_Societe })
  }
}
function apiCreateCarrierFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_CREATE_CARRIER_FAILURE, endpointPath, errors: err.message })
    throw new SubmissionError({ _error: err.message })
  }
}
export function apiCreateCarrier(m, idFaconnier) {
  return u.getValueFromStore(
    `${c.apiCompany}${m.ID_Societe}${c.apiCarriernew}`,
    h.headerPostWithTokenAndModel(m),
    apiCreateCarrierRequest,
    apiCreateCarrierSuccess,
    apiCreateCarrierFailure,
    true
  )
}
