////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEqual, orderBy } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import BATsPendingTable from '../../components/Sematable/Tables/BATsPendingTable'
import { apiGetBATsPending } from '../../actions/bat'

/////////STYLED/////////
const WrappeTable = styled.div`
  & > div > div > div:nth-child(4) {
    display: contents;
    padding-right: 20px;
  }
  & > div > div > div:nth-child(4) > nav {
    right: 0 !important;
    position: absolute;
    padding-right: inherit !important;
  }
`
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
`
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
  margin-top:15px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ? props.margin : '0'};
`
/////////STYLED/////////

class BATPending extends Component {
  constructor(props) {
    super(props)
    this.state = {
      batsPending: {},
      searchTermDomain: 'All',
      searchTerm: '',
      //NEW FILTER
      inputBy: 'common__all',
      inputSearch: '',
      selectedFileOrderId: null
    }
    this.defaultState = this.state
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    //this.props.apiGetBATsPending()
    this.queryData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.batsPending !== this.props.batsPending) this.setState({ batsPending: nextProps.batsPending })
  }

  // NEW FILTER START
  queryData = (defaultState) => {
    const { inputBy, inputSearch, selectedFileOrderId } = this.state
    if (isEqual(defaultState, this.defaultState)) 
      this.props.apiGetBATsPending({
        FileOrderId: this.defaultState.selectedFileOrderId === 0 ? null : this.defaultState.selectedFileOrderId,
        InputSearchBy: {
          Key: this.defaultState.inputBy === 'common__all' ? null : this.defaultState.inputBy,
          Value: this.defaultState.inputSearch
        }
      })
    else {
      this.props.apiGetBATsPending({
        FileOrderId: selectedFileOrderId === 0 ? null : selectedFileOrderId,
        InputSearchBy: {
          Key: inputBy === 'common__all' ? null : inputBy,
          Value: inputSearch
        }
      })
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleSearchChange = e => this.setState({ inputSearch: e.target.value})

  handleSearchByChange = (event, index, value) => this.setState({ inputBy: value })

  handleSearchByFileOrder = (event, index, value) => this.setState({ selectedFileOrderId: value })

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  handleReset = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }
  // NEW FILTER END

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, batToTreat, filters, detail } = this.props
    let availableByParametersSorted
    if (filters.availableByParameters) {
      availableByParametersSorted = filters.availableByParameters.map(elem => { return ({ 'key': elem, 'value': trl[elem] }) })
    }
    if (isLoading) {
      return null
    }
    return (
      <div>
        <PageHeader title={trl.batPending_Title} />
        <SC.PageGutter>
          <SC.Sky grey>
            <SC.PageGutter>
              {/* NEW FILTER START*/}
              <FilterAndTableWrapper>
                <TableWrapper text={trl.common__search}>
                  <BodyTable>
                    <FiltersWrapper>
                      <InputLabelWrapper>
                        <Label>{trl.common__search} :</Label>
                        <TextField
                          id="product-catalog-search-value"
                          type="text"
                          style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                          value={this.state.inputSearch}
                          onChange={this.handleSearchChange}
                          onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                        />
                        <Label style={{marginLeft : 10}}>{trl.common__by} :</Label>
                      </InputLabelWrapper>
                      <InputLabelWrapper>
                        <SelectField
                          style={{
                            fontSize: theme.font.fontSize,
                            maxWidth: '195px',
                          }}
                          floatingLabelStyle={{
                            display: 'none'
                          }}
                          labelStyle={{
                            borderRadius: 0,
                            padding: '3px 30px 0 5px',
                            border: '1px solid #ced4da',
                            width: '100%',
                            height: '30px',
                            position: 'relative',
                            top: '4px',
                            lineHeight: 'inherit'
                          }}
                          iconStyle={{
                            margin: '0',
                            padding: '0'
                          }}
                          underlineStyle={{
                            display: 'none'
                          }}
                          value={this.state.inputBy}
                          onChange={this.handleSearchByChange}
                          onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                        >
                          {
                            availableByParametersSorted &&
                            orderBy(availableByParametersSorted, ['value'], ['asc']).map(param =>
                              <MenuItem
                                key={param.key}
                                value={param.key}
                                primaryText={trl[param.value] || param.value}
                                style={{ fontSize: theme.font.fontSize }}
                              />
                            )
                          }
                        </SelectField>
                      </InputLabelWrapper>
                      <Label>{trl.common_sourceFile_filter__file} :</Label>
                      <SelectField
                        style={{
                          top: '-20px',
                          fontSize: theme.font.fontSize,
                          maxWidth: '195px',
                          height: '35px'
                        }}
                        floatingLabelStyle={{
                          display: 'none'
                        }}
                        labelStyle={{
                          borderRadius: 0,
                          padding: '3px 30px 0 5px',
                          border: '1px solid #ced4da',
                          width: '100%',
                          height: '30px',
                          position: 'relative',
                          top: '4px',
                          lineHeight: 'inherit'
                        }}
                        iconStyle={{
                          top: '1px',
                          margin: '0',
                          padding: '0'
                        }}
                        underlineStyle={{
                          display: 'none'
                        }}
                        value={this.state.selectedFileOrderId}
                        floatingLabelText={trl.common__all}
                        onChange={this.handleSearchByFileOrder}
                        autoWidth={true}
                      >
                        <MenuItem
                          style={{ fontSize: theme.font.fontSize }}
                          value={null}
                          primaryText={trl.common__all}
                        />
                        {
                          filters.availableFileOrder &&
                          filters.availableFileOrder.map(fileOrder =>
                            <MenuItem
                              key={fileOrder.key}
                              value={fileOrder.key}
                              primaryText={trl[fileOrder.value] || fileOrder.value}
                              style={{ fontSize: theme.font.fontSize }}
                            />
                          )
                        }
                      </SelectField>
                      <TableRow margin={'10px 0'}>
                        <ButtonWrapper>
                          <SC.ButtonPA_M
                            onClick={this.queryData} >
                            {trl.common__filter_apply_btn}
                          </SC.ButtonPA_M>
                          <SC.ButtonR_M
                            onClick={this.handleReset} >{trl.common__filter_reset_btn}</SC.ButtonR_M>
                        </ButtonWrapper>
                      </TableRow>
                    </FiltersWrapper>
                  </BodyTable>
                </TableWrapper>
              </FilterAndTableWrapper>
              {/* NEW FILTER END*/}
            </SC.PageGutter>
          </SC.Sky>
        </SC.PageGutter>
        <WrappeTable>
          <SC.PageGutter>
            {detail.length > 0 ? (
              <BATsPendingTable trl={trl} data={detail} isLoadingTable={batToTreat.isLoading} className="DeliveryDetailTable" />
            ) : (
              <h3 style={{ textAlign: 'center' }}>{trl.common__table_no_data}</h3>
            )}
          </SC.PageGutter>
        </WrappeTable>
      </div>
    )
  }
}

BATPending.contextTypes = {
  router: PropTypes.object.isRequired
}

BATPending.propTypes = {
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiGetBATsPending: PropTypes.func.isRequired
}

BATPending.defaultProps = {
  batsPending: [],
  isLoading: true,
  batToTreat: {
    isLoading: false,
    list: [],
    approuveRejectAction: false
  }
}

const mapStateToProps = ({ bat, auth, apiCallStateManager, languages }) => ({
  batsPending: bat.batsPending,
  filters: bat.filters,
  detail: bat.detail,
  role: auth.user_role,
  apiCallStateManager,
  isLoading: bat.isLoading,
  batToTreat: bat.batToTreat,
  trl: languages.siteLanguage.keys,
})

export default connect(
  mapStateToProps,
  {
    apiGetBATsPending
  }
)(BATPending)