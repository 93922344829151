////////LIBRARY/////////
import * as c from '../constants'

// ADD APP alert
export function addAppAlert(message, updateNow) {
  return {
    type: c.ADD_ALERT,
    payload: {
      message,
      updateNow: updateNow || false
    }
  }
}
// ADD ALERT DIALOG
export const addAppDialogAlert = (message, updateNow) => ({
  type: c.ADD_ALERT_DIALOG,
  payload: {
    message,
    updateNow: updateNow || false,
    dialog: true
  }
})
// ADD LOAD
export const addAppShowLoading = (overDialog = false) => ({
  type: c.SHOW_LOADING,
  overDialog
})

// DELETE APP ALERT
export function deleteAlert() {
  return {
    type: c.DELETE_ALERT
  }
}
// DELETE ALERT DIALOG
export const deleteDialogAlert = () => ({
  type: c.DELETE_ALERT_DIALOG
})
// DELETE LOAD
export const addAppHideLoading = () => ({
  type: c.HIDE_LOADING
})
