////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { map, get } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import DateFormatter from '../../components/Sematable/ColumnHelpers/DateFormatter'
import RaisedButton from 'material-ui/RaisedButton'
import * as SC from '../styledComponents'
import { PageHeader } from '../sharedComponents'
import { apiGetDashboard } from '../../actions/dashboard'
import { dashboardTransTable } from '../../constants'
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

const RolesManager = new Array()
RolesManager["ADMGlobal"] = 'ADMIN'
RolesManager["CSS"] = 'LB'
RolesManager["CSP"] = 'LB'
RolesManager["LSAdmin"] = 'LB'
RolesManager["LPAdmin"] = 'LB'
RolesManager["PAdmin"] = 'PS'
RolesManager["POP"] = 'PS'
RolesManager["EAdmin"] = 'BR'
RolesManager["EAC"] = 'BR'
RolesManager["FACA"] = 'MK'
RolesManager["FAC"] = 'MK'

const LanguagesManager = new Array()
LanguagesManager['English'] = 'en'
LanguagesManager['French'] = 'fr'

/////////STYLED/////////
const HomeTableWrapper = styled.div`
  & h5+div > div{
    overflow: auto !important;
  }
`
/////////STYLED/////////

class Home extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    this.props.apiGetDashboard()
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelDownload() {
    // Get all infos for build the url   
    let xhr = new XMLHttpRequest()
    const role = localStorage.getItem('user_role')
    let lang = JSON.parse(localStorage.getItem('siteLanguage')).name
    // Build the first URL with the right lang and role
    let URL = '/user_guides/JPIGuide_' + RolesManager[role] + '_' + LanguagesManager[lang] + '.pdf'
    // Exception for ADMIN global because he's not define 
    // Actually we test if the fil exist with a simple request, and we wait the status
    xhr.open('GET', URL, false) 
    xhr.send()
    if(xhr.status != 200) {
      // if the language is not find, we switch in En
      if(lang != 'English'){
        URL = '/user_guides/JPIGuide_' + RolesManager[role] + '_en.pdf'
        xhr.open('GET', URL, false)
        xhr.send()
      }
      if(xhr.status != 200){
        // default case
        URL = '/user_guides/JPIGuide_MK' + '_' + LanguagesManager[lang] + '.pdf'
        xhr.open('GET', URL, false) 
        xhr.send()
        if(xhr.status != 200){
          URL = '/user_guides/JPIGuide_MK' + '_en.pdf'
          xhr.open('GET', URL, false) 
          xhr.send()
          if(xhr.status != 200) return
        }
      }
    }
    return window.open(URL)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    // trl object of traduction
    const { trl, dashboard, isLoading } = this.props
    if (!dashboard || !trl || isLoading) {
      return null
    }
    return (
      <div>
        <PageHeader title={trl.dashboard__title} />
        <div style={{ textAlign: 'left' }}>
          <h3>{trl.dashboard_downloadGuidePDF_message}</h3>
          <SC.ButtonS
            onClick={this.handelDownload}>
            {trl.dashboard_btn_download}
          </SC.ButtonS>
          <p>&nbsp;</p>
        </div>
        <SC.PageGutter>
          <div className="dashBoardTablesWrapper">
            {map(dashboard, (table, key) => {
              if (!Array.isArray(table)) return null
              const tableProps = dashboardTransTable[key] || {}

              return (
                <HomeTableWrapper key={key} className="dashTable">
                  {tableProps.title && (
                    <h4 className="dashTableTitle">
                      <span>{trl[tableProps.title]}</span>
                    </h4>
                  )}
                  <h5 className='subTitle'>{trl[tableProps.subTitle]}</h5>

                  <Table style={{ tableLayout: 'auto', overflow: 'auto !important' }} fixedHeader={false}>
                    <TableHeader displaySelectAll={false} enableSelectAll={false} adjustForCheckbox={false}>
                      <TableRow>
                        {map(tableProps.table, (header, key) => (
                          <TableHeaderColumn
                            key={key}
                            style={{
                              textOverflow: 'clip',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              color: '#000',
                              fontWeight: 'bold',
                              textAlign: 'center'
                            }}
                          >
                            {trl[header.text]}
                          </TableHeaderColumn>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                      {table.map((row, key) => (
                        <TableRow key={key}>
                          {map(tableProps.table, (header, key) => (
                            <TableRowColumn key={key} style={{ textOverflow: 'clip', paddingLeft: '10px', paddingRight: '10px', textAlign: 'center' }}>
                              {header.format === 'date' && <DateFormatter>{row[key]}</DateFormatter>}
                              {header.format === 'translated' && <span>{trl[row[key][header.transKey]]}</span>}
                              {header.format === 'icon' && (
                                <Link to={`/order/${row.jpiPo}`} style={{ color: header.color }}>
                                  <i className={`fa ${header.icon}`} />
                                </Link>
                              )}
                              {header.format === 'iconBat' && (
                                <Link to={`/bat-view/${row.jpiPo}/0`} style={{ color: header.color }}>
                                  <i className={`fa ${header.icon}`} />
                                </Link>
                              )}
                              {header.format === 'text' && <span>{row[key]}</span>}
                            </TableRowColumn>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {tableProps.footer && tableProps.footer.format === 'btn' && (
                    <div
                      style={{
                        textAlign: 'center',
                        margin: '15px'
                      }}
                    >
                      <Link to={`${tableProps.footer.link.to}${tableProps.footer.link.params}`}>
                        <RaisedButton
                          label={trl[tableProps.footer.title]}
                          {...tableProps.footer.style}
                          labelStyle={{
                            fontWeight: 'bold',
                            display: 'flex',
                            'flexWrap': 'nowrap',
                            'justifyContent': 'center'
                          }}
                        />
                      </Link>
                    </div>
                  )}
                  {tableProps.footer && tableProps.footer.format === 'textValue' && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        style={{
                          border: `3px solid ${tableProps.footer.color}`,
                          padding: '5px 26px',
                          marginTop: '15px',
                          fontWeight: 'bold'
                        }}
                      >
                        {trl[tableProps.footer.title]}
                        <a style={{ color: 'red' }} > {get(dashboard, `${tableProps.footer.value}`)}</a>
                      </div>
                    </div>
                  )}
                </HomeTableWrapper>
              )
            })}
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

Home.propTypes = {
  trl: PropTypes.object.isRequired,
  apiGetDashboard: PropTypes.func,
  dashboard: PropTypes.object,
  isLoading: PropTypes.bool,
}
const mapStateToProps = ({ dashboard, auth }) => ({
  dashboard: dashboard.tableList,
  isLoading: dashboard.isLoading,
})
export default connect(
  mapStateToProps,
  { apiGetDashboard }
)(Home)
