////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { get, forEach, cloneDeep, includes, isNull } from 'lodash'
///////COMPONENTS///////
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import NoDataComponent from '../NoDataComponent'
import ItemFollowUpNestedTable from './ItemFollowUpNestedTable'
import ColorStatusIFU from '../ColumnHelpers/ColorStatusIFU'
import ItemFollowUpActions from '../Actions/ItemFollowUpActions'
import ItemFollowUpSeeNestedActions from '../Actions/ItemFollowUpSeeNestedActions'
import StaticVariableItemFU from '../ColumnHelpers/StaticVariableItemFU'
import SortableItem from '../ColumnHelpers/SortableItem'

const columns = [
  { key: 'primary', hidden: true, primaryKey: true },
  { key: 'nested', Component: ItemFollowUpSeeNestedActions },
  { key: 'status.description.traductionKey', header: translate('ifu_tableTitle_itemStatus'), sortable: true, Component: ColorStatusIFU },
  { key: 'brandName', header: translate('ifu_tableTitle_brand'), sortable: true },
  { key: 'ctcItem', header: translate('ifu_tableTitle_CTCItem'), sortable: true },
  { key: 'brandItem', header: translate('ifu_tableTitle_brandItem'), sortable: true, primaryKey: true },
  { key: 'itemDescription', header: translate('ifu_tableTitle_itemDescription'), sortable: true },
  { key: 'itemType', header: translate('ifu_tableTitle_itemType'), sortable: true, Component: StaticVariableItemFU },
  { key: 'orderingClient', header: translate('ifu_tableTitle_GMName'), sortable: true },
  { key: 'quantity', header: translate('ifu_tableTitle_orderedQty'), sortable: true },
  { key: 'brandSeason', header: translate('ifu_tableTitle_brand_season'), sortable: true },
  { key: 'brandDrop', header: translate('ifu_tableTitle_brand_drop'), sortable: true },
  { key: 'orderDate', header: translate('ifu_tableTitle_OrderValidationDate'), Component: DateFormatter, sortable: true },
  { key: 'allItemsReadyDate', header: translate('ifu_tableTitle_CTCDateConfirmed'), Component: DateFormatter, sortable: true },
  { key: 'ctcOrder', header: translate('ifu_tableTitle_SAPPO'), sortable: true },
  { key: 'jpiOrder', header: translate('ifu_tableTitle_JPIPO'), sortable: true },
  { key: 'clientOrder', header: translate('ifu_tableTitle_GMPO'), sortable: true },
  { key: 'shippedQuantity', header: translate('ifu_tableTitle_shippedQty'), sortable: true },
  { key: 'carrier', header: translate('ifu_tableTitle_carrier'), sortable: true, Component: SortableItem },
  { key: 'lastAWB', header: translate('ifu_tableTitle_lastAWB'), sortable: true },
  { key: 'lastShipmentDate', header: translate('ifu_tableTitle_lastShipmentDate'), Component: DateFormatter, sortable: true },
  { key: 'shipFrom', header: translate('ifu_tableTitle_shipFrom'), sortable: true },
  { key: 'customerService', header: translate('ifu_tableTitle_labellistName'), sortable: true },
  { key: 'actions', header: translate('common__table_header_actions'), Component: ItemFollowUpActions, sortable: false }
]

/////////STYLED/////////
const TableItem = styled.table`
  margin-top: 35px;
  :before {
    content: '';
    height: 35px;
    position: absolute;
    top: 0px;
    left: 10px;
    right: 10px;
    background-color: #575756;
  }
  & thead > tr > th {
    background-color: white;
    color: black;
    vertical-align: inherit !important;
  }
  & tbody > tr > td {
    vertical-align: inherit !important;
  }
`
const ItemFollowUpTableLoader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1499;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
`
/////////STYLED/////////

class ItemFollowUpTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayNestedTable: []
    }
    this.ToggleNestedTable = this.ToggleNestedTable.bind(this)
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  ToggleNestedTable = (jpiOrderItem) => {
    let oldState = cloneDeep(this.state.displayNestedTable)
    if (jpiOrderItem) {
      // displayNestedTable is array contains
      // cTCItem of line which was clicked to show nested table
      // add or delete ctCItem on list to show nested table
      const idx = oldState.findIndex((elem) => elem === jpiOrderItem)
      idx !== -1 ? oldState.splice(idx, 1) : oldState.push(jpiOrderItem)
      this.setState({ displayNestedTable: oldState })
    }
  }

  isFacOrFaca = () => {
    const { role } = this.props
    return role === 'FAC' || role === 'FACA'
  }

  columnsManager = () => {
    const idx = columns.findIndex((elem) => elem.key === 'orderingClient')
    if (this.isFacOrFaca() && idx > -1) columns.splice(idx, 1)
    else if (!this.isFacOrFaca() && idx === -1) columns.splice(8, 0, { key: 'orderingClient', header: translate('ifu_tableTitle_GMName'), sortable: true })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, headers, isLoadingTable, getItemsById, trl } = this.props
    this.columnsManager()
    let tableRow = []
    forEach(data, (elem) => {
      tableRow.push(elem)
      tableRow.push(elem)
    })
    
    return (
      <TableContainer>
        <TableItem className="table orders-follow-up-table font12 table-sm table-hover">
          <thead>
            <tr>
              {columns.map((column) => {
                if (column.hidden) return null
                const children = get(headers, column.key)
                return <SortableHeader key={column.key} {...children} />
              })}
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              tableRow.map((item, index) => {
                // tableRow have all data double time
                // first data is line in table
                // the nested table is the second data
                // so we render only pair number for table row
                // and nested table is odd number
                return index % 2 === 0 ? (
                  <tr key={item.key + '_' + index}>
                    {columns.map((c) => {
                      if (c.hidden) return null
                      const children = get(item, c.key)
                      return (
                        <td key={'__' + c.key} className={c.className}>
                          {c.Component && c.Component !== null ? React.createElement( c.Component, { row: item, ToggleNestedTable: this.ToggleNestedTable }, item[c.key])
                          : !isNull(children) ? children : '-'}
                        </td>
                      )
                    })}
                  </tr>
                ) : (
                  includes(this.state.displayNestedTable, item.jpiOrderItem) && (
                    <tr key={index}>
                      <td></td>
                      <td colSpan={5}>
                        <ItemFollowUpNestedTable getItemsById={getItemsById} data={item} isLoading={isLoadingTable} trl={trl}/>
                      </td>
                    </tr>
                  )
                )
              })
            ) : (
              <tr>
                <td colSpan={columns.length + 1}>{<NoDataComponent />}</td>
              </tr>
            )}
          </tbody>
        </TableItem>
      </TableContainer>
    )
  }
}

ItemFollowUpTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

function mapStateToProps({ auth, languages }) {
  return {
    role: auth.user_role,
    trl: languages.siteLanguage.keys
  }
}

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}

export default connect(mapStateToProps)(sematable('ItemFollowUpTable', ItemFollowUpTable, columns, config))
