////////LIBRARY/////////
import React, { Component } from 'react'
import { map, isEqual } from 'lodash'
import { connect } from 'react-redux'
import styled from 'styled-components'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import * as SC from '../styledComponents'
import { translate } from '../../containers/HOC/Translation'
import { apiPutSourceFileModal } from '../../actions/sourceFileOrderModal'
import { apiGetFileOrderFileDownload } from '../../actions/fileOrderFollowUp'
import DateFormatter from '../Sematable/ColumnHelpers/DateFormatter'

/////////STYLED/////////
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 40px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
  margin: ${props => props.margin ? props.margin : '0'};
`
const WrapperModalCol = styled.div`
  display:grid;
  grid-template-columns :repeat(2, minmax(min-content, max-content));
  grid-template-rows: repeat(1,1fr);
  grid-row-gap:10px;
  grid-column-gap:5px;
`
const WrapperModalRow = styled.div`
  display:grid;
  grid-template-columns : repeat(1, 1fr);
  grid-template-rows: repeat(5,1fr);
  grid-row-gap:10px;
`
/////////STYLED/////////

const labels = [
    { label: `${translate('common_sourceFile_nested_tableTitle__status')} :`},
    { label: `${translate('common_sourceFile_nested_tableTitle__fileName')} :`},
    { label: `${translate('common_sourceFile_nested_tableTitle__integrationDate')} :`},
    { label: `${translate('common_sourceFile_nested_tableTitle__ordersValidationDate')} :`},
    { label: `${translate('common_sourceFile_nested_tableTitle__orderCount')} :`}
]

class SourceFileOrderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    ///////////////////////////////// CONFIG ///////////////////////////////////////

    componentDidMount() {
        const { row } = this.props
        this.props.apiPutSourceFileModal(row.fileOrderId)
    }

    queryData(fileOrderId) {
        this.props.apiGetFileOrderFileDownload(fileOrderId)
    }

	///////////////////////////////// HANDLE ///////////////////////////////////////

    handleRenderLabel = () => {
        return (
            map(labels, l => (
                <p>{l.label}</p>
            ))
        )
    }

    ///////////////////////////////// RENDER ///////////////////////////////////////
    
    render() {
        const { open, handleClose, trl, row, items } = this.props
        return (
            <Dialog
                title={trl.common_sourceFile_nested__title}
                modal={true}
                open={open}
                autoScrollBodyContent={true}
            >
                <WrapperModalCol>
                    <div>
                        <WrapperModalRow>{this.handleRenderLabel()}</WrapperModalRow>
                    </div>
                    {
                        !isEqual(items, []) &&
                        <WrapperModalRow>
                            <div>{trl[items.status.traductionKey]}</div>
                            <div>{items.file_Name}</div>
                            <DateFormatter>{items.integration_Date}</DateFormatter>
                            <DateFormatter>{items.all_Orders_Validation_Date}</DateFormatter>
                            <div>{items.orders_Count}</div>
                        </WrapperModalRow>
                    }
                </WrapperModalCol>
                <TableRow margin={'10px 0'}>
                    <ButtonWrapper>
                        <SC.ButtonS onClick={handleClose}>
                            {trl.common__close}
                        </SC.ButtonS>
                        <SC.ButtonPA_M onClick={() => this.queryData(row.fileOrderId)} >
                            {trl.common_sourceFile_nested_tableAction__download}
                        </SC.ButtonPA_M>
                    </ButtonWrapper>
                </TableRow>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    isLoadingGlobal: state.appAlerts.loading && state.appAlerts.loading.isLoading,
    ...state.sourceFileOrderModal
})

export default connect(mapStateToProps, {
    apiPutSourceFileModal,
    apiGetFileOrderFileDownload
})(SourceFileOrderModal)