////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import PageLoad from '../../components/PageLoad'
import DateFormatter from '../../components/Sematable/ColumnHelpers/DateFormatter'
import { apiGetDelivery } from '../../actions/delivery'
import PreparationSheetTable from '../../components/Sematable/Tables/PreparationSheetTable'
/////////ASSETS/////////
import CTCLogo300 from '../../assets/ctc-logo-300.png'

/////////STYLED/////////
const PSMain = styled.div`
  margin-left: -270px;
  margin-top: -57px;
  padding: 20px;
`
const PSHeader = styled.div`
  width: 100%;
  height: 80px;
  background-color: #aaa;
  border: 2px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const PSHeaderRight = styled.div`
  height: 100%;
  background-color: #fff;
  width: 20%;
  border-left: 2px solid #000;
  text-align: center;
`
const PSTable = styled.div`
  padding-top: 1em;
`
const PSColumns = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
`
const PSColumn = styled.div`
  border: 2px solid #000;
`
const PSTitle = styled.h2`
  border-bottom: 2px solid #000;
  background-color: #ddd;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.3em;
`
const BarCode = styled.p`
  font-family: 'Libre Barcode 39';
  font-size: 13em;
  height: 136px;
  margin: -44px 0 0;
  text-align: center;
  width: 70%;
`
const ColumnDetail = styled.div`
  display: flex;
  justify-content: space-between;
  height: 300px;
`
const ColumnKeys = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  width: 40%;
  text-align: center;
  font-weight: bold;
  border-right: 2px solid #000;
  background-color: #f5f5f5;
  padding-top: 1em;
`
const ColumnVariables = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  width: 60%;
  text-align: center;
  padding-top: 1em;
`
const ColumnLine = styled.p`
  height: 3em;
  font-size: 0.8em;
`
/////////STYLED/////////

class PreparationSheet extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    this.setState({ delivery: nextProps.delivery })
  }

  componentDidMount() {
    this.props.apiGetDelivery(this.props.params.deliveryId)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading } = this.props
    const { delivery } = this.state
    if (isLoading || _.isEmpty(delivery)) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    return (
      <PSMain>
        <PSHeader>
          <img style={{ marginLeft: '1em' }} src={CTCLogo300} alt="" />
          <h1 style={{ color: '#fff', textTransform: 'uppercase', fontWeight: 'bold' }}>
            {trl.prepsheet_DocumentTitle}
          </h1>
          <PSHeaderRight>
            <p style={{ borderBottom: '1px solid #000', fontWeight: 'Bold', padding: '.25em' }}>
              {trl.prepsheet_Title_MaxExpedDate}
            </p>
            <p style={{ fontSize: '2em', marginTop: '-12px' }}>
              <DateFormatter children={delivery.ctcDateConfirmed} />
            </p>
          </PSHeaderRight>
        </PSHeader>
        <PSColumns>
          <PSColumn style={{ width: '25%', height: '342px' }}>
            <PSTitle>{trl.prepsheet_headertitle_Delivery_ID}</PSTitle>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '10em',
                textAlign: 'center',
                borderBottom: '2px solid #000',
                marginTop: '-24px',
                marginBottom: 0,
                height: '190px',
                paddingTop: '18px',
              }}
              ref={el => (el && el.style.setProperty('font-size', '10em', 'important'))}
            >
              {delivery.idDeliveryJPI}
            </p>
            <div style={{ display: 'flex', overflow: 'hidden' }}>
              <BarCode>{delivery.idDeliveryJPI}</BarCode>
              <div style={{ textAlign: 'center', width: '30%', borderLeft: '2px solid #000', height: '144px' }}>
                <p style={{ borderBottom: '2px solid #000' }}>{trl.preparationSheet__authorize}.</p>
                {delivery.shipmentAuth ? (
                  <i style={{ color: 'green', fontSize: '4em', marginTop: '.3em' }} className="fa fa-check-circle" />
                ) : (
                  <i style={{ color: 'red', fontSize: '4em', marginTop: '.3em' }} className="fa fa-times-circle" />
                )}
              </div>
            </div>
          </PSColumn>
          <PSColumn style={{ width: '35%' }}>
            <PSTitle>{trl.prepsheet_headertitle_Information}</PSTitle>
            <ColumnDetail>
              <ColumnKeys style={{}}>
                <ColumnLine>{trl.prepsheet_header_Sender}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_BL}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_ShipMode}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_Weight}</ColumnLine>
              </ColumnKeys>
              <ColumnVariables style={{}}>
                <ColumnLine>{delivery.sender.labelCompany}</ColumnLine>
                <ColumnLine>{delivery.idDeliverySAP}</ColumnLine>
                <ColumnLine style={{ fontWeight: 'bold' }}>{`${delivery.carrierName} / ${delivery.carrierService} / ${delivery.carrierClientNumber !== null ? delivery.carrierClientNumber : '-'
                  }`}</ColumnLine>
                <ColumnLine>{delivery.totalWeight}</ColumnLine>
              </ColumnVariables>
            </ColumnDetail>
          </PSColumn>
          <PSColumn style={{ width: '35%' }}>
            <PSTitle>{trl.prepsheet_headertitle_DeliveryAddress}</PSTitle>
            <ColumnDetail>
              <ColumnKeys>
                {/* <ColumnLine>{trl.prepsheet_header_Recipient}</ColumnLine> */}
                <ColumnLine>{trl.prepsheet_header_rec_Country}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_rec_Address}</ColumnLine>
                <ColumnLine />
                <ColumnLine>{trl.prepsheet_header_rec_City}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_rec_Contact}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_rec_Phone}</ColumnLine>
              </ColumnKeys>
              <ColumnVariables>
                <ColumnLine style={{ fontWeight: 'bold' }}>{delivery.recipient.labelCompany}</ColumnLine>
                <ColumnLine style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                  {delivery.recipient.address.country}
                </ColumnLine>
                <ColumnLine>{`${delivery.recipient.address.ligne_1}\n${delivery.recipient.address.ligne_2
                  }`}</ColumnLine>
                <ColumnLine>{`${delivery.recipient.address.ligne_3}\n${delivery.recipient.address.ligne_4}\n${delivery.recipient.address.ligne_5
                  }`}</ColumnLine>
                <ColumnLine>{`${delivery.recipient.address.postalCode} ${delivery.recipient.address.city}`}</ColumnLine>
                <ColumnLine>{delivery.recipient.address.contact2}</ColumnLine>
                <ColumnLine>{delivery.recipient.address.phoneNumber}</ColumnLine>
              </ColumnVariables>
            </ColumnDetail>
          </PSColumn>
        </PSColumns>
        <PSTable>
          <PreparationSheetTable
            trl={trl}
            data={delivery.deliveryItems}
            subFlow={delivery.status.subFlow}
            className="PreparationSheetTable"
          />
        </PSTable>
      </PSMain>
    )
  }
}

PreparationSheet.propTypes = {
  delivery: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  apiGetDelivery: PropTypes.func.isRequired
}

PreparationSheet.defaultProps = {
  delivery: {}
}

const mapStateToProps = state => ({
  delivery: state.delivery.delivery,
  isLoading: state.delivery.isLoading,
  trl: state.languages.siteLanguage.keys
})

export default connect(
  mapStateToProps,
  { apiGetDelivery }
)(PreparationSheet)
