////////LIBRARY/////////
import React, { Component } from 'react'
import { Link } from 'react-router'
///////COMPONENTS///////
import { List, ListItem } from 'material-ui/List'
import menuParts from './menuParts'
import styles from './styles'

export default class CSSMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { value: 1 }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {
      dropdowns,
      batPending,
      deliveryFollowUp,
      followUp,
      myAccount,
      myDocumentation,
      dashboard,
      productCatalog,
      productionFollowUp,
      stockFollowUp,
      itemFollowUp,
      fileOrderFollowUp,
      partnershipsManagement
    } = menuParts
    const { trl } = this.props

    return (
      <div className="leftMenu">
        <List>
          <ListItem
            primaryText={trl[dashboard.text]}
            style={styles.menuItem}
            containerElement={<Link to={dashboard.link} />}
          />
          <ListItem
            primaryText={trl[productCatalog.text]}
            style={styles.menuItem}
            containerElement={<Link to={productCatalog.link} />}
          />
          <ListItem
            primaryText={trl[stockFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={stockFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[followUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={followUp.link} />}
          />
          <ListItem
            primaryText={trl[itemFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={itemFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[batPending.text]}
            style={styles.menuItem}
            containerElement={<Link to={batPending.link} />}
          />
          <ListItem
            primaryText={trl[productionFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={productionFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[deliveryFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={deliveryFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[fileOrderFollowUp.text]}
            style={styles.menuItem}
            containerElement={<Link to={fileOrderFollowUp.link} />}
          />
          <ListItem
            primaryText={trl[myDocumentation.text]}
            style={styles.menuItem}
            containerElement={<Link to={myDocumentation.link} />}
          />
          <ListItem
            primaryText={trl[dropdowns.myAccount.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            nestedItems={[
              <ListItem
                key={1}
                primaryText={trl[myAccount.text]}
                style={styles.menuItem}
                containerElement={<Link to={myAccount.link} />}
              />
            ]}
          />
          <ListItem
            primaryText={trl[dropdowns.administration.text]}
            initiallyOpen={false}
            style={styles.menuItem}
            primaryTogglesNestedList={true}
            className="nestedMenu"
            nestedItems={[

              <ListItem
                key={1}
                primaryText={trl[partnershipsManagement.text]}
                style={styles.menuItem}
                containerElement={<Link to={partnershipsManagement.link} />}
              />
            ]}
          />
        </List>
      </div>
    )
  }
}
