////////LIBRARY/////////
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, useRouterHistory } from 'react-router'
import { createHashHistory } from 'history'
import injectTapEventPlugin from 'react-tap-event-plugin'
///////COMPONENTS///////
import configureStore from './store/configureStore'
import 'font-awesome/css/font-awesome.css'
import 'flexboxgrid/css/flexboxgrid.css'
import routes from './routes'

/////////////////////////////////////////////////////////////////////////
// browserHistory would be preferred over hashHistory, but browserHistory
// would require configuring the server. So we will use hashHistory here.
// Please change to browserHistory if you have your own backend server.
// import {browserHistory as history} from 'react-router';
//////////////////////////////////////////////////////////////////////////

export const history = useRouterHistory(createHashHistory)()

export const store = configureStore()
injectTapEventPlugin()

ReactDOM.render(
  <Provider store={store}>
    <Router onUpdate={() => window.scrollTo(0, 0)} history={history} routes={routes} />
  </Provider>,
  document.getElementById('root')
)
