////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SortableHeader } from 'sematable'
import _, { get, cloneDeep, find, uniqueId, isInteger, parseInt, forEach } from 'lodash'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import styled from 'styled-components'
///////COMPONENTS///////
import Color from '../ColumnHelpers/Color'
import Dialog from 'material-ui/Dialog'
import Sku from '../ColumnHelpers/Sku'
import { TableContainer, theme } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import AztecComponent from '../../Aztec'
import * as SC from '../../styledComponents/'
import { hasOwnerShipIfFac } from '../../../utils/apiUtils'
import { RadioButton, RadioButtonGroup } from 'material-ui'
import Toggle from 'material-ui/Toggle'
import OrderSkuActions from '../Actions/OrderSkuActions'
import { apiModifyAllSku } from '../../../actions/visibility.js'
import { resetSkuMassEditError } from '../../../actions/orders'
import PageLoad from '../../PageLoad'

/////////STYLED/////////
const ModifyAllSkuWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto min-content;
  grid-column-gap: 5px;
  & > div:last-child > div {
    justify-content: start;
  }
`
const ModifyAllSkuOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & > div > input {
    width: 50px;
  }
  & > div {
    display: flex;
    justify-content: end;
    grid-column-gap: 10px;
    & > div {
      width: auto !important;
    }
  }
`
const ModifyAllSkuInput = styled.input`
  :invalid {
    border-color: red;
  }
`
const ErrorWrapper = styled.h4`
  color: ${theme.colors.red};
`
/////////STYLED/////////

const styles = {
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

class OrderSkuTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRow: null,
      openModalSku: false,
      aztecFormData: [],
      skuLine: {},
      isNotQualified: false,
      openModalSkusQuantities: false,
      modifyAllSkuType: 'number',
      modifyAllSkuOperatorNumber: 'plus',
      modifyAllSkuOperatorPercent: 'plus',
      modifyAllSkuOperatorNumberValue: 0,
      modifyAllSkuOperatorPercentValue: 0,
      isNumberFocused: false,
      isPercentFocused: false,
      columns: [],
      defaultNumberState: 'number',
      isModalOpened: false
    }
    this.toggleActivePosition = this.toggleActivePosition.bind(this)
    this.handelOpenModalSku = this.handelOpenModalSku.bind(this)
    this.handelClosModalSku = this.handelClosModalSku.bind(this)
    this.handelTrigerSubmit = this.handelTrigerSubmit.bind(this)
    this.handleQualifyAll = this.handleQualifyAll.bind(this)
    this.handleModifyAllSkuOperator = this.handleModifyAllSkuOperator.bind(this)
    this.handleModifyAllSkuSubmit = this.handleModifyAllSkuSubmit.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  myColor(type, position) {
    return this.state[type] === position ? 'rgba(0, 0, 0, 0.2)' : ''
  }

  toggleActivePosition(type, position) {
    this.setState({
      [type]: position
    })
  }
  componentDidMount() {
    const { data } = this.props
    let arrayStatus = []
    _.forEach(data, (elem, key) => {
      if (elem.statusSubFlow !== '600') arrayStatus.push(elem.statusSubFlow)
    })
    if (arrayStatus.length > 0) this.setState({ isNotQualified: true })

    const idOrder = parseInt(window.location.href.split('/', 6)[5], 10)
    const hasBarCode = this.props.orders[idOrder].hasBarCode
    this.setState({
      columns:
        [
          { key: 'modele', header: translate('order_EditVariableItem_TableTitle_Model'), primaryKey: true, searchable: true, sortable: true, Component: Sku },
          { key: 'couleur', header: translate('order_EditVariableItem_TableTitle_Color'), searchable: true, sortable: true, Component: Sku },
          { key: 'taille', header: translate('order_EditVariableItem_TableTitle_Size'), searchable: true, sortable: true, Component: Sku },
          { key: 'barcode', header: translate('order_EditVariableItem_TableTitle_Barcode'), searchable: true, sortable: true, Component: Sku, hidden: !hasBarCode },
          { key: 'quantity', header: translate('order_EditVariableItem_TableTitle_Quantity'), sortable: true, Component: Sku },
          { key: 'status', header: translate('order_EditVariableItem_TableTitle_Status'), Component: Color },
          { key: 'actions', header: translate('order_EditVariableItem_TableTitle_Action'), Component: OrderSkuActions }
        ]
    })
  }

  componentWillReceiveProps(nextProps) {
    const { data, visibility } = nextProps
    let arrayStatus = []
    _.forEach(data, (elem, key) => {
      if (elem.statusSubFlow !== '600') arrayStatus.push(elem.statusSubFlow)
    })
    if (arrayStatus.length > 0) this.setState({ isNotQualified: true })
    if (visibility.error === false && visibility.updated) this.handleCloseModalModifySkuSQuantities()
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handelOpenModalSku(sku) {
    const { articleVariable = {} } = this.props
    const formData = cloneDeep(get(articleVariable, 'article_Champ_VariableData', []))
    const editSkuFormData = []
    formData.forEach((field) => {
      if (sku) {
        const dvcItem = find(sku.listDVS, (ld) => ld.iD_Article_Champ === field.id_Article_Champ)
        if (dvcItem) {
          const formSelectedValue = find(
            field.props.selectedValues,
            (selectValue) => selectValue.iD_Commande_Article_Champ === dvcItem.iD_Commande_Article_Champ )
          field.props.selectedValues = formSelectedValue || {}
        }
      } else field.props.selectedValues = { iD_Commande_Article_Champ: 0 }
      editSkuFormData.push(field)
    })
    this.setState({ openModalSku: true, aztecFormData: editSkuFormData, skuLine: sku ? { iD_SKU: sku.iD_SKU } : {} })
  }
  handelClosModalSku() {
    this.setState({ openModalSku: false, aztecFormData: [], skuLine: {} })
  }

  handleQualifyAll() {
    const listSku = this.props.data.map((sku) => sku.iD_SKU)
    this.props.handelApiQualifyAll(listSku)
    this.setState({ isNotQualified: false })
  }

  handleOpenModalModifySkuSQuantities() {
    this.setState({
      openModalSkusQuantities: true, isModalOpened: true, modifyAllSkuType: 'number'
    })
  }

  handleCloseModalModifySkuSQuantities() {
    this.setState({
      openModalSkusQuantities: false,
      //modifyAllSkuType: '', it ' seems we dont need to reset state because this reset change modifyAllSkuType value on handleModifyAllSkuOperator method
      modifyAllSkuOperatorNumber: 'plus',
      modifyAllSkuOperatorPercent: 'plus',
      modifyAllSkuOperatorNumberValue: 0,
      modifyAllSkuOperatorPercentValue: 0,
      isModalOpened: false,
      isNumberFocused: false,
      isPercentFocused: true,
    })
    this.props.resetSkuMassEditError()
  }

  handleModifyAllSkuOperator = (radioFrom) => {

    const { modifyAllSkuType, modifyAllSkuOperatorNumber, modifyAllSkuOperatorPercent } = this.state
    if (radioFrom === modifyAllSkuType && modifyAllSkuType === 'number') {
      this.setState({ modifyAllSkuOperatorNumber: modifyAllSkuOperatorNumber === 'plus' ? 'minus' : 'plus' })
    } else if (radioFrom === modifyAllSkuType && modifyAllSkuType === 'percent') {
      this.setState({ modifyAllSkuOperatorPercent: modifyAllSkuOperatorPercent === 'plus' ? 'minus' : 'plus' })
    }
  }

  handleModifyAllSkuSubmit = () => {
    const {
      modifyAllSkuOperatorNumber,
      modifyAllSkuOperatorPercent,
      modifyAllSkuOperatorNumberValue,
      modifyAllSkuOperatorPercentValue,
      modifyAllSkuType
    } = this.state
    const { routeParams } = this.props
    const { orderId, idCommandeArticle } = routeParams
    if ( (modifyAllSkuOperatorNumberValue.length > 0 && !isInteger(parseInt(modifyAllSkuOperatorNumberValue))) ||
         (modifyAllSkuOperatorPercentValue.length > 0 && !isInteger(parseInt(modifyAllSkuOperatorPercentValue)))) return  
    else {
      const submitObj = {
        Value: modifyAllSkuType === 'number' ? modifyAllSkuOperatorNumberValue : modifyAllSkuOperatorPercentValue,
        IsAdditional: modifyAllSkuType === 'number' ? modifyAllSkuOperatorNumber === 'plus' : modifyAllSkuOperatorPercent === 'plus'
      }
      this.props.apiModifyAllSku(orderId, idCommandeArticle, submitObj, modifyAllSkuType)
    }
    this.setState({
      defaultNumberState: 'number',
      modifyAllSkuOperatorNumberValue: 0,
      modifyAllSkuOperatorPercentValue: 0,
      isModalOpened: false,
    })
  }

  handelTrigerSubmit() {
    this.aztecFormSku.triggerSubmit()
  }
  handleSubmit(data) {
    this.handelClosModalSku()
    this.props.handelUpdateOrAddSku(data)
  }

  isDuplicate = (ledata) => {
    let labelModel = ''
    let labelColor = ''
    let labelSize = ''
    const { trl } = this.props
    for (let i = 0; i < this.props.data.length; i++) {
      // Testing label model, but his index is variable
      const indLabelModel = _.findIndex(
        this.props.articleVariable.article_Champ_VariableData,
        (o) => o.props.typeValue.id === 1
      )
      if (indLabelModel > -1) {
        forEach(this.props.articleVariable.article_Champ_VariableData[indLabelModel].props.selectedValues, (cel, k) => {
          if (
            ['type_1', 'type_2', 'type_10', 'type_3'].includes(
              this.props.articleVariable.article_Champ_VariableData[indLabelModel].props.displayFormat
            )
          ) {
            forEach(ledata, (submitField) => {
              if (
                submitField.id_Article_Champ ===
                this.props.articleVariable.article_Champ_VariableData[indLabelModel].id_Article_Champ &&
                submitField.props.selectedValues.unicValue === cel.unicValue &&
                cel.iD_Commande_Article_Champ !== submitField.props.selectedValues.iD_Commande_Article_Champ
              ) {
                labelModel = cel.unicValue
              }
            })
          } else if (
            ['type_5', 'type_12'].includes(
              this.props.articleVariable.article_Champ_VariableData[indLabelModel].props.displayFormat
            )
          ) {
            forEach(ledata, (submitField) => {
              // iD_Commande_Article_Champ equals zero means a new sku
              if (submitField.props.selectedValues.iD_Commande_Article_Champ === 0) {
                if (
                  submitField.id_Article_Champ ===
                  this.props.articleVariable.article_Champ_VariableData[indLabelModel].id_Article_Champ &&
                  submitField.props.selected === cel.listValue[0]
                ) {
                  labelModel = cel.listValue[0]
                }
              } else {
                if (
                  submitField.id_Article_Champ ===
                  this.props.articleVariable.article_Champ_VariableData[indLabelModel].id_Article_Champ &&
                  submitField.props.selected === cel.listValue[0] &&
                  cel.iD_Commande_Article_Champ !== submitField.props.selectedValues.iD_Commande_Article_Champ
                ) {
                  labelModel = cel.listValue[0]
                }
              }
            })
          }
        })
      }

      // Testing color, but his index is variable
      const indColor = _.findIndex(
        this.props.articleVariable.article_Champ_VariableData,
        (o) => o.props.typeValue.id === 3
      )
      if (indColor > -1) {
        forEach(this.props.articleVariable.article_Champ_VariableData[indColor].props.selectedValues, (cel, k) => {
          if (
            ['type_1', 'type_2', 'type_10', 'type_3'].includes(
              this.props.articleVariable.article_Champ_VariableData[indColor].props.displayFormat
            )
          ) {
            forEach(ledata, (submitField) => {
              if (
                submitField.id_Article_Champ ===
                this.props.articleVariable.article_Champ_VariableData[indColor].id_Article_Champ &&
                submitField.props.selectedValues.unicValue === cel.unicValue &&
                cel.iD_Commande_Article_Champ !== submitField.props.selectedValues.iD_Commande_Article_Champ
              ) {
                labelColor = cel.unicValue
              }
            })
          } else if (
            ['type_5', 'type_12'].includes(
              this.props.articleVariable.article_Champ_VariableData[indColor].props.displayFormat
            )
          ) {
            forEach(ledata, (submitField) => {
              // iD_Commande_Article_Champ equals zero means a new sku
              if (submitField.props.selectedValues.iD_Commande_Article_Champ === 0) {
                if (
                  submitField.id_Article_Champ ===
                  this.props.articleVariable.article_Champ_VariableData[indColor].id_Article_Champ &&
                  submitField.props.selected === cel.listValue[0]
                ) {
                  labelColor = cel.listValue[0]
                }
              } else {
                if (
                  submitField.id_Article_Champ ===
                  this.props.articleVariable.article_Champ_VariableData[indColor].id_Article_Champ &&
                  submitField.props.selected === cel.listValue[0] &&
                  cel.iD_Commande_Article_Champ !== submitField.props.selectedValues.iD_Commande_Article_Champ
                ) {
                  labelColor = cel.listValue[0]
                }
              }
            })
          }
        })
      }

      // Testing size, but his index is variable
      const indSize = _.findIndex(
        this.props.articleVariable.article_Champ_VariableData,
        (o) => o.props.typeValue.id === 2
      )
      if (indSize > -1) {
        forEach(this.props.articleVariable.article_Champ_VariableData[indSize].props.selectedValues, (cel, k) => {
          forEach(ledata, (submitField) => {
            // iD_Commande_Article_Champ equals zero means a new sku
            if (submitField.props.selectedValues.iD_Commande_Article_Champ === 0) {
              if (
                submitField.id_Article_Champ ===
                this.props.articleVariable.article_Champ_VariableData[indSize].id_Article_Champ &&
                submitField.props.selected === cel.listValue[0]
              ) {
                labelSize = cel.listValue[0]
              }
            } else {
              if (
                submitField.id_Article_Champ ===
                this.props.articleVariable.article_Champ_VariableData[indSize].id_Article_Champ &&
                submitField.props.selected === cel.listValue[0] &&
                cel.iD_Commande_Article_Champ !== submitField.props.selectedValues.iD_Commande_Article_Champ
              ) {
                labelSize = cel.listValue[0]
              }
            }
          })
          //}
        })
      }
    }

    if (labelModel !== '' && labelColor !== '' && labelSize !== '') {
      return { msg: trl.common__error_article_sku_duplicated, isDuplicate: true }
    } else {
      return { msg: '', isDuplicate: false }
    }
  }

  handleChecked = (target) => {
    switch (target) {
      case 'number':
        this.setState({ modifyAllSkuType: 'number' })
        this.setState({ isNumberFocused: false })
        this.setState({ isPercentFocused: true })
        this.setState({ modifyAllSkuOperatorPercentValue: 0 })
        break;
      case 'percent':
        this.setState({ modifyAllSkuType: 'percent' })
        this.setState({ isPercentFocused: false })
        this.setState({ isNumberFocused: true })
        this.setState({ modifyAllSkuOperatorNumberValue: 0 })
        this.setState({ isModalOpened: false })
        break;
      default:
        break;
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, articleVariable = {}, isDisabled, orderCanalIsFile, orders, routeParams, trl } = this.props
    const { columns, defaultNumberState, isModalOpened } = this.state
    const formData = get(articleVariable, 'article_Champ_VariableData', [])
    const { orderId } = routeParams
    const hasOwner = hasOwnerShipIfFac(orders[orderId])
    const order = orders.orders

    if(this.props.orders.isLoadingSKU) return <PageLoad text={trl.common__loading} size={80} thickness={9} />

    const actionsEdit = [
      <SC.ButtonR_S style={{ margin: '0 20px' }} onClick={this.handelClosModalSku}>
        {trl.common__cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handelTrigerSubmit}>{trl.common__ok}</SC.ButtonPA_S>
    ]

    const actionsModifySkusQuantities = [
      <SC.ButtonR_S style={{ margin: '0 20px' }} onClick={() => this.handleCloseModalModifySkuSQuantities()}>
        {trl.common__cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S
        disabled={this.state.isNumberFocused && this.state.isPercentFocused}
        type="submit"
        onClick={this.handleModifyAllSkuSubmit}
      >
        {trl.common__apply}
      </SC.ButtonPA_S>
    ]
    return (
      <div>
        {!_.isUndefined(orders.error) ? (
          <ErrorWrapper>{!_.isNull(orders.error) && orders.error.message}</ErrorWrapper>
        ) : null}
        {!this.props.orders.isLoadingSKU && <TableContainer>
          <div className="tableWrapperBorder">
            <div className="table-responsive order-detail-follow-up-table">
              <table className="table table-sm table-striped table-hover">
                <thead>
                  <tr>
                    {columns.map((c) => (
                      c.hidden ?
                        null
                        :
                        <SortableHeader
                          style={{ fontSize: 16 }}
                          {...c}
                          sortKey={c.key}
                          name={c.header}
                          handleClick={() => null}
                        />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((skus, index) => {
                    const listDVSToObj = {}
                    skus.listDVS.map((d) => { listDVSToObj[d.type] = Object.assign({}, d) })
                    const objectData = { row: { ...skus, listDVSObj: listDVSToObj }, toggleActivePosition: this.toggleActivePosition, activeRow: index }
                    return (
                      <tr key={index} style={{ backgroundColor: this.myColor('activeRow', index) }}>
                        {columns.map((c, key) => {
                          if (c.hidden) return null
                          return (
                            <td key={key}>
                              {c.Component
                                ? React.createElement(c.Component, {
                                  ...objectData,
                                  modelKey: c.key,
                                  formData,
                                  isDisabled,
                                  iD_Commande: articleVariable.iD_Commande,
                                  iD_Commande_Article: articleVariable.iD_Commande_Article,
                                  handelOpenModalSku: this.handelOpenModalSku
                                })
                                : skus[c.key]}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </TableContainer>}
        {!this.props.orders.isLoadingSKU && (
          <div style={styles.buttonsWrapper}>
            {!orderCanalIsFile && (
              <SC.ButtonS onClick={() => this.handelOpenModalSku(null)} disabled={isDisabled}>
                {trl.orderSku_table_btn__addLine}
              </SC.ButtonS>
            )}
            {
              // Removing check on this variable
              //orderCanalIsFile && (
              hasOwner && !_.isUndefined(order) && !_.isEmpty(data) && (
                <SC.ButtonPA_M
                  style={{ marginLeft: 'auto', marginRight: '20px' }}
                  disabled={
                    isDisabled || (!_.isUndefined(order) && !_.isUndefined(order.listSKU) && order.listSKU.length === 0)
                  }
                  onClick={() => this.handleOpenModalModifySkuSQuantities()}
                >
                  {trl.order_EditAllVariable_DVS_Quantity__Button}
                </SC.ButtonPA_M>
              )
              //)
            }
            {
              // Removing check on this variable
              //orderCanalIsFile && (
              this.state.isNotQualified && (
                <SC.ButtonPA_M disabled={isDisabled} onClick={this.handleQualifyAll}>
                  {trl.orderSku_table_tableTitle__qualifyAll}
                </SC.ButtonPA_M>
              )
              //)
            }
          </div> )}
        {/* MODALE MODIFY SKUS QUANTITIES*/}
        <Dialog
          //title={translate('order_EditVariableItem_DVS_ModalAddEditSku_Title')}
          title={trl.order_EditAllVariable_DVS_Quantity__Modal_tittle}
          actions={actionsModifySkusQuantities}
          modal={true}
          open={this.state.openModalSkusQuantities}
          autoScrollBodyContent={true}
        >
          <div>
            {!_.isUndefined(orders) && !_.isNull(orders.errorSkuMass) && (
              <span style={{ color: 'red', marginBottom: '10px' }}>{trl[orders.errorSkuMass.message]}</span>
            )}
            <ModifyAllSkuWrapper>

              <RadioButtonGroup name="sku" defaultSelected={defaultNumberState}>
                <RadioButton
                  label={trl.order_EditAllVariable_DVS_Quantity__Increase_Decrease_Percent}
                  value="number"
                  onClick={() => this.handleChecked('number')}
                />
                <RadioButton
                  label={trl.order_EditAllVariable_DVS_Quantity__Increase_Decrease_Percent_pieces}
                  value="percent"
                  onClick={() => this.handleChecked('percent')}
                />
              </RadioButtonGroup>

              <ModifyAllSkuOptionWrapper>
                <div>
                  <span>+</span>
                  <Toggle
                    onToggle={() => this.handleModifyAllSkuOperator('number')}
                    disabled={this.state.isNumberFocused}

                  />
                  <span>-</span>
                </div>
                <div>
                  <span>+</span>
                  <Toggle
                    onToggle={() => this.handleModifyAllSkuOperator('percent')}
                    disabled={this.state.isPercentFocused || isModalOpened}
                  />
                  <span>-</span>
                </div>
              </ModifyAllSkuOptionWrapper>
              <ModifyAllSkuOptionWrapper>
                <div>
                  <ModifyAllSkuInput
                    type="text"
                    pattern="[0-9]{1,5}"
                    onChange={(e) => this.setState({ modifyAllSkuOperatorNumberValue: parseInt(e.target.value) })}
                    disabled={this.state.isNumberFocused}
                    value={isNaN(this.state.modifyAllSkuOperatorNumberValue) ? 0 : this.state.modifyAllSkuOperatorNumberValue}
                  />
                </div>
                <div>
                  <ModifyAllSkuInput
                    type="text"
                    pattern="[0-9]{1,3}"
                    onChange={(e) => this.setState({ modifyAllSkuOperatorPercentValue: parseInt(e.target.value) })}
                    value={isNaN(this.state.modifyAllSkuOperatorPercentValue)
                      ? 0
                      : _.isUndefined(this.state.modifyAllSkuOperatorPercentValue)
                        ? 0
                        : this.state.modifyAllSkuOperatorPercentValue
                    }
                    disabled={this.state.isPercentFocused || isModalOpened}
                  />
                </div>
              </ModifyAllSkuOptionWrapper>
              <ModifyAllSkuOptionWrapper>
                <div>pcs</div>
                <div>%</div>
              </ModifyAllSkuOptionWrapper>
            </ModifyAllSkuWrapper>
          </div>
        </Dialog>
        <Dialog
          title={translate('order_EditVariableItem_DVS_ModalAddEditSku_Title')}
          actions={actionsEdit}
          modal={true}
          open={this.state.openModalSku}
          autoScrollBodyContent={true}
        >
          <div className="editSk">
            <AztecComponent
              guid={uniqueId('skuEditOrAdd_')}
              data={this.state.aztecFormData}
              aditionnelData={this.state.skuLine}
              //isValidQuantity={this.isValidQuantity.bind(this)}
              isDuplicate={this.isDuplicate.bind(this)}
              isDisabled={false}
              submitAction={(m) => this.handleSubmit(m, 0)}
              ref={(aztecFormSku) => (this.aztecFormSku = aztecFormSku)}
            />
          </div>
        </Dialog>
      </div>
    )
  }
}

OrderSkuTable = reduxForm({
  form: 'OrderSkuTable',
  //validate,
  touchOnBlur: false,
  errors: {}
})(OrderSkuTable)

const mapStateToProps = ({ languages, orders, visibility }) => ({
  trl: languages.siteLanguage.keys,
  orders: orders,
  visibility: visibility
})

OrderSkuTable.propTypes = {
  data: PropTypes.array.isRequired,
  articleVariable: PropTypes.object.isRequired,
  handelUpdateOrAddSku: PropTypes.func
}
export default connect(mapStateToProps, { apiModifyAllSku, resetSkuMassEditError })(OrderSkuTable)
