////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
import { isNull } from 'lodash'
import { connect } from 'react-redux'
import styled from 'styled-components'
///////COMPONENTS///////
import DisplayActions from '../Actions/DisplayActions'
import OrdersFollowUpActions from '../Actions/OrdersFollowUpActions'
import { allRoles as r } from '../../../constants'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import DateFormatterOrb from '../ColumnHelpers/DateFormatterOrb'
import SortableItem from '../ColumnHelpers/SortableItem'
import ColorStatusOFU from '../ColumnHelpers/ColorStatusOFU'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import NoDataComponent from '../NoDataComponent'
import PageLoadTable from '../../PageLoadTable'

let columns = [
  { key: 'displayAction', header: '', Component: DisplayActions },
  { key: 'iD_Status', header: translate('ofu_lvlOrder_tableTitle_OrderStatus'), Component: ColorStatusOFU, sortable: true },
  { key: 'enseigne_Name', header: translate('ofu_lvlOrder_tableTitle_Brand'), sortable: true },
  { key: 'iD_Commande_Externe_Enseigne', header: translate('ofu_lvlOrder_tableTitle_BrandPO'), sortable: true },
  { key: 'iD_Commande', header: translate('ofu_lvlOrder_tableTitle_JPIPO'), sortable: true, primaryKey: true },
  { key: 'iD_Commande_Externe_SAP', header: translate('ofu_lvlOrder_tableTitle_SAPPO'), sortable: true },
  { key: 'iD_Commande_Externe_Faconnier', header: translate('ofu_lvlOrder_tableTitle_GMPO'), Component: SortableItem, sortable: true },
  { key: 'faconnier_Name', header: translate('ofu_lvlOrder_tableTitle_GMName'), sortable: true },
  { key: 'iD_Externe_Faconnier_Enseigne', header: translate('ofu_lvlOrder_tableTitle_GMCode'), sortable: true },
  { key: 'brandSeason', header: translate('ofu_tableTitle_brand_season'), sortable: true },
  { key: 'brandDrop', header: translate('ofu_tableTitle_brand_drop'), sortable: true },
  { key: 'date_Created', header: translate('ofu_lvlOrder_tableTitle_CreationDate'), Component: DateFormatter, sortable: true },
  { key: 'date_Validated', header: translate('ofu_lvlOrder_tableTitle_ValidationDate'), Component: DateFormatter, sortable: true },
  { key: 'date_Warehouse_Enseigne', header: translate('ofu_lvlOrder_tableTitle_BrandDeadline'), Component: DateFormatter, sortable: true },
  { key: 'requestedShipping_Date', header: translate('ofu_lvlOrder_tableTitle_RequestedDate'), Component: DateFormatter, sortable: true },
  { key: 'ctC_Date_Confirmed', header: translate('ofu_lvlOrder_tableTitle_OrderReadyBy'), Component: DateFormatterOrb, sortable: true },
  { key: 'lastShipmentDate', header: translate('ofu_lvlOrder_tableTitle_lastShipmentDate'), Component: DateFormatter, sortable: true },
  { key: 'carrier', header: translate('ofu_lvlOrder_tableTitle_carrier'), sortable: true, Component: SortableItem },
  { key: 'lastAWB', header: translate('ofu_lvlOrder_tableTitle_lastAWB'), sortable: true },
  { key: 'actions', header: translate('ofu_lvlOrder_tableTitle_Action'), Component: OrdersFollowUpActions }
]

export const allRoles = [
  'GAdmin',
  'ADMGlobal',
  'CSP',
  'CSS',
  'EAC',
  'EAdmin',
  'FAC',
  'FACA',
  'LPAdmin',
  'LSAdmin',
  'PAdmin',
  'POP'
]

export const searchOption = [
  { key: 'ofu_lvlOrder_tableTitle_Brand', header: translate('ofu_lvlOrder_tableTitle_Brand'), notShowforRoles: [] },
  { key: 'ofu_lvlOrder_tableTitle_BrandPO', header: translate('ofu_lvlOrder_tableTitle_BrandPO'), notShowforRoles: [r.PAdmin, r.POP] },
  { key: 'ofu_lvlOrder_tableTitle_JPIPO', header: translate('ofu_lvlOrder_tableTitle_JPIPO'), notShowforRoles: [] },
  { key: 'ofu_lvlOrder_tableTitle_SAPPO', header: translate('ofu_lvlOrder_tableTitle_SAPPO'), notShowforRoles: [r.EAdmin, r.EAC, r.FAC, r.FACA] },
  { key: 'ofu_lvlOrder_tableTitle_GMPO', header: translate('ofu_lvlOrder_tableTitle_GMPO'), notShowforRoles: [r.EAdmin, r.EAC, r.PAdmin, r.POP] },
  { key: 'ofu_lvlOrder_tableTitle_GMName', header: translate('ofu_lvlOrder_tableTitle_GMName'), notShowforRoles: [r.FAC, r.FACA] },
  { key: 'ofu_lvlOrder_tableTitle_GMCode', header: translate('ofu_lvlOrder_tableTitle_GMCode'), notShowforRoles: [r.FAC, r.FACA, r.PAdmin, r.POP] },
  { key: 'ofu_lvlOrder_tableTitle_lastAWB', header: translate('ofu_lvlOrder_tableTitle_lastAWB'), notShowforRoles: [] },
  { key: 'ofu_lvlOrder_tableTitle_carrier', header: translate('ofu_lvlOrder_tableTitle_carrier'), notShowforRoles: [] }
]

const columnsFilteredList = [
  [
    'displayAction',
    'iD_Status',
    'enseigne_Name',
    'iD_Commande_Externe_Enseigne',
    'iD_Commande',
    'iD_Commande_Externe_SAP',
    'iD_Commande_Externe_Faconnier',
    'faconnier_Name',
    'iD_Externe_Faconnier_Enseigne',
    'brandSeason',
    'brandDrop',
    'date_Created',
    'date_Validated',
    'date_Warehouse_Enseigne',
    'requestedShipping_Date',
    'ctC_Date_Confirmed',
    // 'printshop_Name',
    'carrier',
    'lastShipmentDate',
    'lastAWB',
    'actions'
  ],
  [
    'displayAction',
    'iD_Status',
    'enseigne_Name',
    'iD_Commande_Externe_Enseigne',
    'iD_Commande',
    'faconnier_Name',
    'iD_Externe_Faconnier_Enseigne',
    'brandSeason',
    'brandDrop',
    'date_Created',
    'date_Validated',
    'date_Warehouse_Enseigne',
    'ctC_Date_Confirmed',
    // 'printshop_Name',
    'carrier',
    'lastShipmentDate',
    'lastAWB',
    'actions'
  ],
  [
    'displayAction',
    'iD_Status',
    'enseigne_Name',
    'iD_Commande',
    'iD_Commande_Externe_SAP',
    'faconnier_Name',
    'brandSeason',
    'brandDrop',
    'date_Validated',
    'ctC_Date_Confirmed',
    // 'printshop_Name',
    'carrier',
    'lastShipmentDate',
    'lastAWB',
    'actions'
  ],
  [
    'displayAction',
    'iD_Status',
    'enseigne_Name',
    'iD_Commande_Externe_Enseigne',
    'iD_Commande',
    'iD_Commande_Externe_Faconnier',
    'brandSeason',
    'brandDrop',
    'date_Created',
    'date_Validated',
    'date_Warehouse_Enseigne',
    'requestedShipping_Date',
    'ctC_Date_Confirmed',
    // 'printshop_Name',
    'carrier',
    'lastShipmentDate',
    'lastAWB',
    'actions'
  ]
]

const displayMappings = {
  [r.GAdmin]: columnsFilteredList[0],
  [r.LPAdmin]: columnsFilteredList[0],
  [r.LSAdmin]: columnsFilteredList[0],
  [r.CSP]: columnsFilteredList[0],
  [r.CSS]: columnsFilteredList[0],

  [r.EAdmin]: columnsFilteredList[1],
  [r.EAC]: columnsFilteredList[1],

  [r.PAdmin]: columnsFilteredList[2],
  [r.POP]: columnsFilteredList[2],

  [r.FAC]: columnsFilteredList[3],
  [r.FACA]: columnsFilteredList[3],

  default: columnsFilteredList[0]
}

/////////STYLED/////////
const OrdersFollowUpTableLoader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1499;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
`
/////////STYLED/////////

class OrdersFolowUpTable extends Component {
  constructor(props) {
    super(props)
    const displayMapping = displayMappings[props.role] || displayMappings.default
    this.state = {
      columns: columns.filter((c) => displayMapping.includes(c.key))
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, isLoadingTable, trl } = this.props
    let indice = 0
    while (data[indice] !== undefined) {
      if (data[indice].brandDrop === "" || isNull(data[indice].brandDrop)) data[indice].brandDrop = "-"
      if (data[indice].brandSeason === "" || isNull(data[indice].brandSeason)) data[indice].brandSeason = "-"
      indice++
    }
    return (
      <TableContainer>
        {isLoadingTable && (
          <OrdersFollowUpTableLoader>
            <PageLoadTable text={trl.common__loading} size={80} thickness={9} />
          </OrdersFollowUpTableLoader>
        )}
        <Table
          style={{ overflow: 'auto' }}
          className="table orders-follow-up-table font12"
          {...this.props}
          data={data}
          columns={this.state.columns}
          NoDataComponent={NoDataComponent}
        />
      </TableContainer>
    )
  }
}

OrdersFolowUpTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}
function mapStateToProps({ auth, languages }) {
  return {
    role: auth.user_role,
    trl: languages.siteLanguage.keys
  }
}

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false,
  sortKey: 'date_Created',
  sortDirection: 'desc'
}

export default connect(mapStateToProps)(sematable('OrdersFollowUp', OrdersFolowUpTable, columns, config))
