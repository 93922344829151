////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hashHistory, Link } from 'react-router'
import { get, isUndefined, isNull, isEmpty } from 'lodash'
import { MenuItem, SelectField } from 'material-ui'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import Checkbox from 'material-ui/Checkbox'
import { PageHeader } from '../../components/sharedComponents'
import * as c from '../../constants'
import { apiGetDelivery, apiUpdatePackingList, apiDeletePackingList, apiDeletePackingListBox, apiDeletePackingListBoxItem,
         apiAddPackingListBox, apiSavePrintPackingList, apiAddItemPackingList, apiDuplicatePackingListBox } from '../../actions/delivery'
import ContainerTextField from './ContainerTextField'
import DeleteButton from './DeleteButton'
import styles from './styles'
import * as SC from '../../components/styledComponents'
import stringformat from '../../utils/stringformat'

let isInEdit = false
class PackingList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      containers: [],
      remainingItems: [],
      openSaveModal: false,
      openAddItemModal: false,
      addItemContainer: {},
      savePrintStart: false,
      parcelFormat: 'Select your parcel format',
      errorCheckox: false
    }
    this.onChangePakingBox = this.onChangePakingBox.bind(this)
    this.handleAddBox = this.handleAddBox.bind(this)
    this.handleDeletePackingList = this.handleDeletePackingList.bind(this)
    this.handleBackBtn = this.handleBackBtn.bind(this)
    this.handleSavePrint = this.handleSavePrint.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    this.props.apiGetDelivery(this.props.params.deliveryId)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.delivery !== nextProps.delivery) {
      this.setState({
        containers: nextProps.delivery.packingList.containers,
        remainingItems: nextProps.delivery.packingList.remainingItems
      })
    }
    if (this.props.isLoading && !nextProps.isLoading && this.state.savePrintStart && !nextProps.errors.savePrint) {
      window.open(`/#${c.delivery}${nextProps.delivery.idDeliveryJPI}${c.packinglistSavePrint}`, '_blank')
      this.handleBackBtn()
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onBlurPakingBox(container, delivery, errors) {
    const { idType, idContainer, label, lenght, width, depth, weight } = container
    const inputModel = {
      idContainer,
      label,
      idType,
      lenght,
      width,
      depth,
      weight
    }
    let toVerify = [label, weight, lenght, width, depth]
    let error = false 
    for(let i = 0 ; i<toVerify.length ; i++){
      if(this.verifyValue(toVerify[i])) error = true
    }
    if (delivery !== container && !error) {
      this.props.apiUpdatePackingList({ inputModel, idContainer, idDelivery: this.props.params.deliveryId })
      isInEdit = false
    }
  }

  onChangeRemainingQty(value, Itemindex) {
    const { remainingItems } = this.state
    const updatedState = remainingItems.map((item, index) => {
      const additionanParam = { error: '' }
      if (
        (value % item.article_Conditionnement > 0 && value <= item.remainingQuantity && value > 0) ||
        value === '' ||
        parseInt(value, 10) === 0
      ) {
        additionanParam.error = `Quantity should be a multiple of ${item.article_Conditionnement}` // TODO: traduction
        additionanParam.checked = false
        this.setState({ errorCheckox : true })
      }
      else{
        this.setState({ errorCheckox : false })
      }
      if (index === Itemindex) {
        return {
          ...item,
          newRemainingQuantity: value <= item.remainingQuantity && value >= 0 ? value : item.remainingQuantity,
          ...additionanParam
        }
      }
      return item
    })
    this.setState({ remainingItems: updatedState })
  }

  onCheckRemainingItem(Itemindex) {
    const { remainingItems } = this.state
    const updatedState = remainingItems.map((item, index) => {
      if (index === Itemindex) return { ...item, checked: item.checked !== undefined ? !item.checked : true }
      return item
    })
    this.setState({ remainingItems: updatedState })
  }

  onCheckAllItem() {
    const { remainingItems } = this.state
    const updatedState = remainingItems
    /* An other itherator for..of is deprecated for this methode */
    for (let i = 0; i<updatedState.length; i++) {
      updatedState[i].checked = updatedState[i].checked !== undefined ? !updatedState[i].checked : true
    }
    this.setState({ remainingItems: updatedState })
  }

  verifyValue(value, isInteger = false) {
    /* An other itherator for..of is deprecated for this methode */
    if(isNull(value) || value == null) return false
    if(isInteger){
      if (!/^\d+$/.test(value)) {
        return true;
      }
    }
    if (!/^\d+(\.\d{0,2})?$/.test(value)) {
      return true
    }
    /*for (let i = 0; i<value.length; i++) {
      console.log('debug : ', value[i])
      if(isNaN(value[i]) && !Number.isInteger(parseFloat(value[i])) ) {
        return true
      }
    }*/
    if(value.length === 0) return false
    /*if(notZero) {
      if(!parseInt(value) <= 0) value = parseInt(value)
      return parseInt(value) <= 0
    }
    else {
      if(!parseInt(value) < 0) value = parseInt(value)
      return parseInt(value) < 0
    }*/
  }

  onChangePakingBox(value, index, item, pack) {
    value = value.replace(',', '.')
    isInEdit = true
    // don't do a call if we have a negative number
    const { containers } = this.state
    const valueFormat = value == "" ? null : value
    const updatedState = containers.map((container, containerIndex) => {
      if (containerIndex === index) return { ...container, [item]: valueFormat }
      return container
    })
    this.setState({ containers: updatedState })
    this.render()
  }

  handleDeletePackingList() {
    const idDelivery = this.props.params.deliveryId
    this.props.apiDeletePackingList({ idDelivery })
    hashHistory.push(`${c.delivery}${idDelivery}`)
  }
  handleBackBtn() {
    const idDelivery = this.props.params.deliveryId
    hashHistory.push(`${c.delivery}${idDelivery}`)
  }
  handleDeleteBoxItem(idContainerItem, idContainer) {
    const idDelivery = this.props.params.deliveryId
    this.props.apiDeletePackingListBoxItem({ idDelivery, idContainer, idContainerItem })
  }
  handleDeleteBox(idContainer) {
    const idDelivery = this.props.params.deliveryId
    this.props.apiDeletePackingListBox({ idDelivery, idContainer })
  }
  handleAddBox() {
    const idDelivery = this.props.params.deliveryId
    this.props.apiAddPackingListBox({ idDelivery })
  }

  handleSavePrint() {
    const idDelivery = this.props.params.deliveryId
    this.props.apiSavePrintPackingList({ idDelivery })
    this.setState({ savePrintStart: true, openSaveModal: false })
  }

  handleOpenSaveModal = () => {
    this.setState({ openSaveModal: true })
  }
  handelCloseSaveModal = () => {
    this.setState({ openSaveModal: false })
  }

  handelOpenAddItemModal = pack => {
    this.setState({ openAddItemModal: true, addItemContainer: pack })
  }
  handelCloseAddItemModal = () => {
    this.setState({ openAddItemModal: false })
  }
  handelSaveAddItem = () => {
    this.handelCloseAddItemModal()
    const { addItemContainer, remainingItems } = this.state
    const idDelivery = this.props.params.deliveryId
    const idBox = addItemContainer.idContainer
    const { idContainer, idType, label, lenght, weight, depth, width } = addItemContainer
    const requiredItems = remainingItems.filter(item => item.checked)
    const requiredItemsParams = requiredItems.map(item => {
      return {
        IdDeliveryItem: item.idDeliveryItem,
        IdSku: item.fixedVariable === 'F' ? null : item.idSku,
        Quantity: item.newRemainingQuantity || item.remainingQuantity
      }
    })
    const inputModel = {
      idContainer,
      idType,
      label,
      lenght,
      width,
      depth,
      weight,
      RequiredItems: requiredItemsParams
    }
    this.props.apiAddItemPackingList({ inputModel, idDelivery, idBox })
  }

  handleSelectParcelFormat = (elem, pack) => {

    pack.width = elem.width
    pack.depth = elem.depth
    pack.lenght = elem.length
    pack.parcelLabel = elem.label

    this.setState({selectLabel : pack.parcelLabel = elem.label })

    const { idType, idContainer, label, lenght, width, depth, weight } = pack

    this.setState({[idContainer]: pack.parcelLabel})

    const inputModel = {
      idContainer,
      label,
      idType,
      lenght,
      width,
      depth,
      weight,
    }
    this.props.apiUpdatePackingList({inputModel, idContainer, idDelivery: this.props.params.deliveryId })
  }

  handleDuplicateBox (container) {
    const { idContainer } = container
    this.props.apiDuplicatePackingListBox({ idDelivery: this.props.params.deliveryId, idBox: idContainer })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, delivery, errors } = this.props
    const { containers, addItemContainer, remainingItems } = this.state
    const { idDeliveryJPI } = delivery
    const actionsAddItemBtn = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={this.handelCloseAddItemModal}>
        {trl.packinglist_modal_btn_Cancel}
      </SC.ButtonR_S>,
      <SC.ButtonPA_S onClick={this.handelSaveAddItem}>
        {trl.packinglist_modal_btn_OK}
      </SC.ButtonPA_S>
    ]
    const actionsSaveBtn = [
      <SC.ButtonR_S style={{ margin: "0 20px" }} onClick={this.handelCloseSaveModal}>
        {trl.common__no}
      </SC.ButtonR_S>,
      <Link onClick={this.handleSavePrint}>
        <SC.ButtonPA_S>
          {trl.common__yes}
        </SC.ButtonPA_S>
      </Link>
    ]
    const addItemContainerLabel = get(addItemContainer, 'label', '')
    const isSavePrintDisabled = remainingItems.length > 0
    return (
      <div>
        <PageHeader title={`${trl.defu_tableTitle_IDDelivery} ${idDeliveryJPI}`} />
        {/* ORDERS TABLE */}
        {containers.map((pack, index) => (
        <div key={index}>
          <div style={{ display: 'flex', flex: 1, marginTop: '10px' }} key={pack.idContainer}>
            <div style={styles.container}>
              <ContainerTextField
                label={trl.packinglist_tabletitle_BoxLabel}
                value={!isUndefined(pack.label) && pack.label}
                type="text"
                onChange={value => this.onChangePakingBox(value, index, 'label', delivery.packingList.containers[index])}
                onBlur={() => this.onBlurPakingBox(pack, delivery.packingList.containers[index], [pack.errorLabel, pack.errorWeight, pack.errorLenght, pack.errorWidth, pack.errorDepth])}
                name={'label'}
                errorText={this.verifyValue(pack.label, true) && isInEdit ? 'Should be an integer' : undefined}
              />
              <ContainerTextField
                label={trl.packinglist_tabletitle_Weight}
                value={!isUndefined(pack.weight) && pack.weight}
                onChange={value => this.onChangePakingBox(value, index, 'weight', delivery.packingList.containers[index])}
                onBlur={() => this.onBlurPakingBox(pack, delivery.packingList.containers[index])}
                name={'weight'}
                type="text"
                errorText={this.verifyValue(pack.weight) && isInEdit ? 'Max two decimals' : undefined}
              />
              <ContainerTextField
                label={trl.packinglist_tabletitle_Long}
                value={!isUndefined(pack.lenght) && pack.lenght}
                onChange={value => this.onChangePakingBox(value, index, 'lenght', delivery.packingList.containers[index])}
                onBlur={() => this.onBlurPakingBox(pack, delivery.packingList.containers[index])}
                name={'lenght'}
                type="text"
                errorText={this.verifyValue(pack.lenght) && isInEdit ? 'Max two decimals' : undefined}
              />
              <ContainerTextField
                label={trl.packinglist_tabletitle_Width}
                value={!isUndefined(pack.width) && pack.width}
                onChange={value => this.onChangePakingBox(value, index, 'width', delivery.packingList.containers[index])}
                onBlur={() => this.onBlurPakingBox(pack, delivery.packingList.containers[index])}
                name={'width'}
                type="text"
                errorText={this.verifyValue(pack.width) && isInEdit ? 'Max two decimals' : undefined}
              />
              <ContainerTextField
                label={trl.packinglist_tabletitle_High}
                value={!isUndefined(pack.depth) && pack.depth}
                onChange={value => this.onChangePakingBox(value, index, 'depth', delivery.packingList.containers[index])}
                onBlur={() => this.onBlurPakingBox(pack, delivery.packingList.containers[index])}
                name={'depth'}
                type="text"
                errorText={this.verifyValue(pack.depth) && isInEdit ? 'Max two decimals' : undefined}
              />
              <div style={{maxHeight : 100}}></div>
            </div>
            <div style={{ flex: 1.5, display: 'flex', marginLeft: 50 }}>
              <table style={{ width: '100%' }}>
                <thead style={index ? { visibility: 'hidden' } : {}}>
                  <tr>
                    <th>{trl.packinglist_tabletitle_SalesOrder}</th>
                    <th>{trl.packinglist_tabletitle_ClientPO}</th>
                    <th>{trl.packinglist_tabletitle_Item}</th>
                    <th>{trl.packinglist_tabletitle_BrandItem}</th>
                    <th>{trl.packinglist_tabletitle_Sku}</th>
                    <th>{trl.packinglist_tabletitle_Qty}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={6} />
                    <td>
                      <DeleteButton onClick={() => this.handleDeleteBox(pack.idContainer)} bgColor="#868686" />
                    </td>
                  </tr>
                  {pack.selectedItems.map(item => (
                    <tr key={item.idContainerItem}>
                      <td style={{ padding: '7px 0' }}>{item.idSalesOrder_SAP}</td>
                      <td style={{ padding: '7px 0' }}>{item.clientPo}</td>
                      <td style={{ padding: '7px 0' }}>{item.ctcItem}</td>
                      <td style={{ padding: '7px 0' }}>{item.brandItem}</td>
                      <td style={{ padding: '7px 0' }}>{`${item.model ? item.model + ' / ' : ''}${item.color ? item.color + ' / ' : ''
                        }${item.size ? item.size : ''}`}</td>
                      <td style={{ padding: '7px 0' }}>{item.selectedQuantity}</td>
                      <td style={{ padding: '7px 0' }}>
                        <DeleteButton onClick={() => this.handleDeleteBoxItem(item.idContainerItem, pack.idContainer)}/>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={2} />
                    <td>
                      <SC.ButtonS onClick={() => this.handelOpenAddItemModal(pack)}>
                        {trl.packinglist_tableBtn_AddItem}
                      </SC.ButtonS>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ marginBottom: '40px', maxHeight: '50px' }}>
            <table>
              <tr>
                <td>
                  <SelectField
                    style={{
                      position: 'relative',
                      fontSize: SC.theme.fontSize,
                      width: '262px',
                      paddingLeft: '8px',
                      paddingRight: '8px'
                    }}
                    iconStyle={{
                      margin: '0',
                      padding: '0',
                      paddingBottom: '5px'
                    }}
                    floatingLabelStyle={{
                      top: '34%',
                      left: '20px',
                    }}
                    labelStyle={{
                      borderRadius: 0,
                      padding: '3px 30px 0 5px',
                      border: '1px solid #ced4da',
                      width: '100%',
                      height: '24px',
                      position: 'relative',
                      top: '10px',
                      lineHeight: 'inherit',
                      paddingTop: '8px',
                    }}
                    underlineStyle={{
                      display: 'none'
                    }}
                    floatingLabelText={this.state[pack.idContainer] || this.state.parcelFormat}
                    autoWidth={true}
                  >
                    {this.props.delivery.packingList.containerPresets.map((elem) => {
                      return (
                        <MenuItem
                          key = {elem.idPreset}
                          value = {!isUndefined(elem) &&  elem}
                          primaryText = {elem.label}
                          onClick={() => this.handleSelectParcelFormat(elem, pack)}
                        />
                      )
                    })}
                  </SelectField>
                </td>
                <td><SC.ButtonR_S
                    style={{
                      marginBottom: '0px',
                      position: 'relative',
                    }}
                    onClick={() => this.handleDuplicateBox(pack, delivery.packingList.containers[index])}
                  >DUPLICATE PARCEL</SC.ButtonR_S>
                </td>
              </tr>
            </table>
          </div>
        </div>
        ))}
        <div style={{ marginBottom: '15px', marginTop: '15px' }}>
          <SC.ButtonS
            onClick={this.handleAddBox}
          >
            {trl.packinglist_tableBtn_AddBox}
          </SC.ButtonS>
        </div>
        {errors && errors.savePrint ? (
          <div>
            <h3 style={{ color: '#ff0000', textAlign: 'center' }}>{trl.common__confirmBLSAPerror_message}</h3>
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '98%',
            margin: '50px auto 0 auto'
          }}
        >
          <div style={{ position: 'relative', float: 'left' }}>

            <SC.ButtonR_L
              onClick={this.handleBackBtn}
            >
              {trl.packinglist_btn_Back}
            </SC.ButtonR_L>
          </div>
          <div style={{ position: 'relative', float: 'left' }}>

            <SC.ButtonS
              style={{ marginRight: '20px' }}
              onClick={this.handleDeletePackingList}
            >
              {trl.packinglist_btn_Delete}
            </SC.ButtonS>

            <SC.ButtonPA_L
              onClick={this.handleOpenSaveModal}
              disabled={isSavePrintDisabled}
            >
              {trl.packinglist_btn_Save}
            </SC.ButtonPA_L>
          </div>
        </div>
        <Dialog title={!isUndefined(trl.packingList_modal_title__confirmPrintAndSave) && trl.packingList_modal_title__confirmPrintAndSave} actions={actionsSaveBtn} modal={false} open={this.state.openSaveModal}>
          <p>{!isUndefined(trl.packingList_modal_text__confirmListValidation) && trl.packingList_modal_text__confirmListValidation}</p>
        </Dialog>
        <Dialog
          title={stringformat(trl.packinglist_modal_Title + ' ', addItemContainerLabel)}
          actions={actionsAddItemBtn}
          modal={false}
          open={this.state.openAddItemModal}
          contentStyle={{ width: '80%', maxWidth: 1508 }}
          bodyStyle={{ backgroundColor: 'white' }}
          actionsContainerStyle={{ backgroundColor: 'white' }}
          titleStyle={{ backgroundColor: 'white' }}
          autoScrollBodyContent={true}
        >
          <div style={{ width: '100%' }}>
            <table style={{ width: '100%' }} className="centerAllItem">
              <thead>
                <tr>
                  <th>{trl.packinglist_modal_tabletitle_SalesOrder}</th>
                  <th>{trl.packinglist_modal_tabletitle_Brand}</th>
                  <th>{trl.packinglist_modal_tabletitle_Item}</th>
                  <th>{trl.packinglist_modal_tabletitle_ForV}</th>
                  <th>{trl.packinglist_modal_tabletitle_Model}</th>
                  <th>{trl.packinglist_modal_tabletitle_Color}</th>
                  <th>{trl.packinglist_modal_tabletitle_Size}</th>
                  <th>{trl.packinglist_modal_tabletitle_POqty}</th>
                  <th>{trl.packinglist_modal_tabletitle_RemQty}</th>
                  <th>
                    <table>
                      <tr>
                        <th style={{width: '80%'}}>{trl.packinglist_modal_tabletitle_CheckBox}</th>
                        <th><Checkbox
                              onCheck={e => this.onCheckAllItem()}
                              //checked={item.checked}
                              value={true}
                              defaultChecked={false}
                              disabled={this.state.errorCheckox}
                              style={{ width: '20%'}}
                            />
                        </th>
                      </tr>
                    </table>
                        
                  </th>
                </tr>
              </thead>
              <tbody>
                {remainingItems &&
                  remainingItems.map((item, index) => (
                    <tr key={item.idOrderedItem + item.idSku}>
                      <td>{item.idSalesOrder_SAP}</td>
                      <td>{item.brandItem}</td>
                      <td>{item.ctcItem}</td>
                      <td>{item.fixedVariable}</td>
                      <td>{item.model}</td>
                      <td>{item.color}</td>
                      <td>{item.size}</td>
                      <td>{item.totalQty}</td>
                      <td>
                        <div style={{ width: 80 }}>
                          <ContainerTextField
                            value={ !isUndefined(item.newRemainingQuantity) && item.newRemainingQuantity >= 0  ? item.newRemainingQuantity : item.remainingQuantity}
                            type="text"
                            onChange={value => this.onChangeRemainingQty(value, index)}
                            name={'id_' + item.idSalesOrder}
                            errorText={item.error || undefined}
                            disabled={item.fixedVariable === 'V'}
                          />
                        </div>
                      </td>
                      <td>
                        <Checkbox
                          onCheck={e => this.onCheckRemainingItem(index)}
                          checked={item.checked}
                          value={true}
                          defaultChecked={false}
                          disabled={item.error || undefined}
                          style={{ width: 44 }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Dialog>
      </div>
    )
  }
}

function mapStateToProps({ delivery, auth, me, appAlerts, languages }) {
  return {
    role: auth.user_role,
    isLoading: delivery.isLoading,
    delivery: delivery.delivery,
    errors: delivery.errors,
    companyType: me.company_type,
    inputChange: appAlerts.loading.isLoading,
    trl: languages.siteLanguage.keys,
  }
}

PackingList.propTypes = {
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired
}
PackingList.defaultProps = {
  delivery: {},
  isLoading: true,
  errors: {}
}

export default connect(
  mapStateToProps,
  {
    apiGetDelivery,
    apiUpdatePackingList,
    apiDeletePackingList,
    apiDeletePackingListBox,
    apiDeletePackingListBoxItem,
    apiAddPackingListBox,
    apiSavePrintPackingList,
    apiAddItemPackingList,
    apiDuplicatePackingListBox
  }
)(PackingList)
