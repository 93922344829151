////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import styled from 'styled-components'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'
import { styles } from '../misc/formStyles'
import stringformat from '../../../utils/stringformat'
import { theme } from '../../../components/styledComponents'
import DatePicker from 'material-ui/DatePicker'

let DateTimeFormat = global.Intl.DateTimeFormat

/////////STYLED/////////
const OKbutton = styled.button`
  width: 32px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  font-size: 10px;
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  &:hover {
    transition: 0.2s;
    color: white;
    background-color: ${theme.colors.lightGrey};
    color: ${theme.colors.black};
  }
  margin-left: 7px;
`
const Form = styled.form`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
/////////STYLED/////////

const validate = (values, props) => {
  let errors = {}
  if (values.date_Expedition_Souhaitee && values.date_Expedition_Souhaitee.length > 30) errors.date_Expedition_Souhaitee = stringformat(props.trl.common__max_length_error, 30) || ' '
  return errors
}

class RequestDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date_Expedition_Souhaitee: null
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.initialize(this.props.order)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit = () => {
    const { date_Expedition_Souhaitee } = this.state
    const { apiUpdateOrderPoFac, order } = this.props
    const newOrderId = order.iD_Commande
    const userModel = {
      iD_Commande: parseInt(newOrderId, 10),
      date_Expedition_Souhaitee
    }
    return apiUpdateOrderPoFac(newOrderId, userModel)
  }

  handleChangeDate = (event, date) => {
    let newDate = date
    newDate.setHours(12)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    this.setState({ date_Expedition_Souhaitee: newDate })
    let date_Expedition_Souhaitee = date
    const { apiUpdateOrderPoFac, order } = this.props
    const newOrderId = order.iD_Commande
    const userModel = {
      iD_Commande: parseInt(newOrderId, 10),
      date_Expedition_Souhaitee
    }
    return apiUpdateOrderPoFac(newOrderId, userModel)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, error, submitting, order } = this.props
    let defaultDate = {}
    let DefaultRequestedShipping_Date = '0001-01-01T00:00:00'
    if (order && order.requestedShipping_Date !== DefaultRequestedShipping_Date) {
      defaultDate.defaultDate = new Date(order.requestedShipping_Date)
    }
    const minDate = new Date()
    return (
      <Form>
        <span>{trl.orderDetail_OrderHeaders_RequestedDate}: </span>
        <DatePicker
          name="date_Expedition_Souhaitee"
          type="text"
          underlineStyle={{ borderBottomColor: 'white', bottom: 17 }}
          style={{
            float: 'left',
            paddingLeft: 35,
            paddingRight: 7,
            maxWidth: '146px',
            overflow: 'hidden',
            backgroundColor: '#ffffff',
            height: '28px',
            fontSize: '13px'
          }}
          hintStyle={{
            top: '3px',
            fontSize: '13px'
          }}
          inputStyle={{ textAlign: 'left', paddingBottom: 18, bottom: 0, fontSize: '13px' }}
          hintText="01-01-2018"
          fullWidth={false}
          onChange={this.handleChangeDate}
          {...defaultDate}
          DateTimeFormat={DateTimeFormat}
          locale="fr"
          minDate={minDate}
        />

        <div style={{ fontSize: theme.font.fontSize, float: 'left', marginTop: 4, paddingBottom: 4 }}>
          {!submitting && (
            <OKbutton type="button" disabled={submitting} onClick={this.onSubmit}>
              {trl.common__ok}
            </OKbutton>
          )}
          {/*<FlatButton
            label={'OK' || ' '}
            style={{ ...styles.loginBtn, margin: '10px 0 0 0', borderRadius: '50%', width: 37 }}
            disabled={submitting}
            type="submit"
          />*/}
          {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
        </div>
        {error && <div>{error && <p style={styles.loginError}>{error}</p>}</div>}
      </Form>
    )
  }
}

RequestDate.propTypes = {
  trl: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
  initialValues: PropTypes.object.isRequired,
  apiUpdateOrderPoFac: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
}

RequestDate = reduxForm({
  form: 'RequestDateForm',
  validate,
  touchOnBlur: false,
  errors: {}
})(RequestDate)

export default RequestDate
