////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SortableHeader } from 'sematable'
import { get, isNull } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import PageLoadTable from '../../PageLoadTable'

/////////STYLED/////////
const TableWrapper = styled.div`
`
/////////STYLED/////////

const columnsNestedTable = [
  { key: 'pk_article', primaryKey: true, hidden: true },
  { key: 'qtyBreakdown', header: translate( 'ifu_nested_tableTitle_breakdownQty' ), sortable: true },
  { key: 'ctcDateConfirmBreakdown', header: translate( 'ifu_nested_tableTitle_CTCDateConfirmed' ), sortable: true, Component: DateFormatter },
  { key: 'qtyShippedBreakdown', header: translate( 'ifu_nested_tableTitle_shippedQty' ), sortable: true },
  { key: 'lastAwbBreakdown', header: translate( 'ifu_nested_tableTitle_lastAWB' ), sortable: true, },
  { key: 'lastShipmentDateBreakdown', header: translate( 'ifu_nested_tableTitle_lastShipmentDate' ), sortable: true, Component: DateFormatter }
]

class ItemFollowUpNestedTable extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.getItemsById(this.props.data.jpiOrderItem)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, trl } = this.props
    return (
      // Proper waiting of request before showing anything
      this.props.isLoading ?
      ( <PageLoadTable text={trl.common__loading} size={80} thickness={9} /> )
      : ( <TableWrapper>
            <TableContainer>
              <table className="orderArticleNestedFollowUpTable table table-sm table-striped table-hover">
                <thead>
                  <tr>
                    { columnsNestedTable.map( ( c, index ) => ( !c.hidden &&
                  <SortableHeader
                    { ...c }
                    sortKey={ index.toString() }
                    name={ c.header }
                  />
                    ))}
                  </tr>
                </thead>
                <tbody>
                { !isNull(data.orderItemBreakdown) ?
                  data.orderItemBreakdown.map( ( item, index ) => {
                    return (
                      <tr key={ index } className={ 'tableLigneGrey' }>
                      { columnsNestedTable.map( c => {
                        const children = get( item, c.key )
                          return ( !c.hidden &&
                            <td key={ '__' + c.key } className={ c.className }>
                            { c.Component && c.Component !== null ? 
                              React.createElement( c.Component, { row: item, children }) : !isNull(children) ? children : '-'
                            }
                            </td>
                        )
                      } ) }
                    </tr>
                  )
                } )
                :
                <tr>
                  <td colSpan={ columnsNestedTable.length + 1 }  >
                    {
                      !this.props.isLoading
                        &&
                        <NoDataComponent/>
                    }
                  </td>
                </tr>
            }

            </tbody>
          </table>
        </TableContainer>
      </TableWrapper>
    ))
  }
}

ItemFollowUpNestedTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

export default ItemFollowUpNestedTable

