////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'
import _ from 'lodash'
///////COMPONENTS///////
import YesNoImg from '../ColumnHelpers/YesNoImg'
import DeliveryStatus from '../ColumnHelpers/DeliveryStatus'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { TableContainer } from '../../styledComponents'
import * as SC from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

const columns = [
  { key: 'status.traductionKey', header: translate('newdeliveries_tableTitle_Status'), sortable: true, Component: DeliveryStatus },
  { key: 'idDeliverySAP', header: translate('newdeliveries_tableTitle_IDDelivery'), sortable: true, primaryKey: true },
  { key: 'shipmentAuth', header: translate('newdeliveries_tableTitle_ShipmentAuthoriz'), sortable: true, Component: YesNoImg },
  { key: 'ctcDateConfirmed', header: translate('newdeliveries_tableTitle_CTCConfirmedDate'), sortable: true, Component: DateFormatter },
  { key: 'salesOrder.idSalesOrder_SAP', header: translate('newdeliveries_tableTitle_SalesOrder'), sortable: true },
  { key: 'deliveryItems.referenceItem.article_Fixe', header: translate('newdeliveries_tableTitle_ItemType'), sortable: true },
  { key: 'numberOfItems', header: translate('newdeliveries_tableTitle_NumberOfItem'), sortable: true },
  { key: 'recipient.labelCompany', header: translate('newdeliveries_tableTitle_To'), sortable: true },
  { key: 'recipient.countryAddress', header: translate('newdeliveries_tableTitle_Country'), sortable: true },
  { key: 'action', header: translate('newdeliveries_tableTitle_Actions'), sortable: true }
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false,
  sortKey: 'ctcDateConfirmed',
  sortDirection: 'asc'
}

class WaitingForDeliveryTable extends Component {

	///////////////////////////////// HANDLE ///////////////////////////////////////

  createDelivery = idDelivery => {
    this.props.createDelivery(idDelivery)
  }

  displayActions = (companyType, subFlow, idDelivery) => {
    if (companyType === 'LS' || companyType === 'LP') {
      return (
        <SC.ButtonPA_S onClick={() => { this.createDelivery(idDelivery) }}>
          {translate('newdeliveries_tableAction_Btn_SeeDelivery') || 'See delivery'}
        </SC.ButtonPA_S>
      )
    } else {
      if (subFlow === '010') {
        return (
          <SC.ButtonPA_S onClick={() => { this.createDelivery(idDelivery) }}>
            {translate('newdeliveries_tableAction_Btn_CreateDelivery') || 'Create delivery'}
          </SC.ButtonPA_S>
        )
      }
      if (subFlow === '020') {
        return (
          <SC.ButtonPA_S onClick={() => { this.createDelivery(idDelivery) }}>
            {translate('newdeliveries_tableAction_Btn_UpdateDelivery') || 'Update delivery'}
          </SC.ButtonPA_S>
        )
      }
    }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, companyType } = this.props
    const sortByKey = key => (a, b) => a[key] > b[key]
    const dataSortedByctcDateConfirmed = data.slice().sort(sortByKey('ctcDateConfirmed')).filter(row => !row['clickOnCreate_Date'] && row)
    return (
      <TableContainer>
        <div className="tableWrapperBorder">
          <div className="table-responsive order-detail-follow-up-table">
            <table className="table table-sm table-striped table-hover" style={{ marginBottom: 0 }}>
              <thead>
                <tr className="noPointer">
                  {columns.map((c, index) => (
                    <SortableHeader {...c} sortKey={index.toString()} name={c.header} />
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataSortedByctcDateConfirmed.map((item, index) => {
                  let salesOrders = []
                  item.salesOrders.map(so => {
                    salesOrders.push({
                      id: so.idSalesOrder_SAP,
                      type: so.itemType,
                      count: so.numberOfItems
                    })
                  })
                  salesOrders = _.uniqBy(salesOrders, 'id')
                  return (
                    <tr key={index} className={item.status.subFlow === '020' ? 'tableLigneRed' : ''}>
                      <td>{translate(item.status.traductionKey)}</td>
                      <td>{item.idDeliverySAP}</td>
                      <td>
                        <YesNoImg children={item.shipmentAuth} />
                      </td>
                      <td>
                        <DateFormatter children={item.ctcDateConfirmed} />
                      </td>
                      <td>
                        {salesOrders.map((deli, index) => (
                          <div key={index}>
                            <span>{deli.id}</span>
                            <br />
                          </div>
                        ))}
                      </td>
                      <td>
                        {salesOrders.map((deli, index) => (
                          <div key={index}>
                            <span>{deli.type}</span>
                            <br />
                          </div>
                        ))}
                      </td>
                      <td>
                        {salesOrders.map((deli, index) => (
                          <div key={index}>
                            <span>{deli.count}</span>
                            <br />
                          </div>
                        ))}
                      </td>
                      <td>{item.recipient.labelCompany}</td>
                      <td style={{ textTransform: 'uppercase' }}>{item.recipient.countryAddress}</td>
                      <td style={{ display: 'flex' }}>
                        {this.displayActions(companyType, item.status.subFlow, item.idDeliveryJPI)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </TableContainer>
    )
  }
}

WaitingForDeliveryTable.contextTypes = {
  router: PropTypes.object.isRequired
}

WaitingForDeliveryTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

export default sematable('WaitingForDelivery', WaitingForDeliveryTable, columns, config)
