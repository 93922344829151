////////LIBRARY/////////
import { css } from 'styled-components'
///////COMPONENTS///////
import theme from './theme'
import { media } from './index'

const { h1, h2, h3, h4, h5, h6, p1, p2, p3, p4, p5, goldenRatio } = theme

// FONT SIZE
const ftzMap = p => {
  if (p.h1) {
    return css`
      font-size: ${h1.ftz};
      ${media.md`font-size: ${h1.ftzTablet};`};
      ${media.sm`font-size: ${h1.ftzMobile};`};
    `
  }
  if (p.h2) {
    return css`
      font-size: ${h2.ftz};
      ${media.md`font-size: ${h2.ftzTablet};`};
      ${media.sm`font-size: ${h2.ftzMobile};`};
    `
  }
  if (p.h3) {
    return css`
      font-size: ${h3.ftz};
      ${media.md`font-size: ${h3.ftzTablet};`};
      ${media.sm`font-size: ${h3.ftzMobile};`};
    `
  }
  if (p.h4) {
    return css`
      font-size: ${h4.ftz};
      ${media.md`font-size: ${h4.ftzTablet};`};
      ${media.sm`font-size: ${h4.ftzMobile};`};
    `
  }
  if (p.h5) {
    return css`
      font-size: ${h5.ftz};
      ${media.md`font-size: ${h5.ftzTablet};`};
      ${media.sm`font-size: ${h5.ftzMobile};`};
    `
  }
  if (p.h6) {
    return css`
      font-size: ${h6.ftz};
      ${media.md`font-size: ${h6.ftzTablet};`};
      ${media.sm`font-size: ${h6.ftzMobile};`};
    `
  }
  if (p.p1) {
    return css`
      font-size: ${p1.ftz};
      ${media.md`font-size: ${p1.ftzTablet};`};
      ${media.sm`font-size: ${p1.ftzMobile};`};
    `
  }
  if (p.p2) {
    return css`
      font-size: ${p2.ftz};
      ${media.md`font-size: ${p2.ftzTablet};`};
      ${media.sm`font-size: ${p2.ftzMobile};`};
    `
  }
  if (p.p3) {
    return css`
      font-size: ${p3.ftz};
      ${media.md`font-size: ${p3.ftzTablet};`};
      ${media.sm`font-size: ${p3.ftzMobile};`};
    `
  }
  if (p.p4) {
    return css`
      font-size: ${p4.ftz};
      ${media.md`font-size: ${p4.ftzTablet};`};
      ${media.sm`font-size: ${p4.ftzMobile};`};
    `
  }
  if (p.p5) {
    return css`
      font-size: ${p5.ftz};
      ${media.md`font-size: ${p5.ftzTablet};`};
      ${media.sm`font-size: ${p5.ftzMobile};`};
    `
  }
}
export const ftzProps = (props, ...defaultSize) => {
  if (
    props.h1 ||
    props.h2 ||
    props.h3 ||
    props.h4 ||
    props.h5 ||
    props.h6 ||
    props.p1 ||
    props.p2 ||
    props.p3 ||
    props.p4 ||
    props.p5
  ) {
    return ftzMap(props)
  } else if (props.ftz) {
    return css`
      font-size: ${props.ftz};
    `
  } else {
    return css`
      ${defaultSize[0] && `font-size: ${defaultSize[0]}`};
      ${media.md`
        ${defaultSize[1] && `font-size: ${defaultSize[1]}`};
      `};
      ${media.sm`
        ${defaultSize[2] && `font-size: ${defaultSize[2]}`};
      `};
    `
  }
}
// FONT WEIGHT
export const ftzWeightProps = props => css`
  ${props.ftzWeight && typeof (props.ftzWeight === 'string') ? `font-weight:  ${props.ftzWeight}` : ''};
`
// LETTER SPACING
const letterSpacingMap = p => {
  if (p.h1) {
    return css`
      letter-spacing: ${h1.letterSpacing};
    `
  }
  if (p.h2) {
    return css`
      letter-spacing: ${h2.letterSpacing};
    `
  }
  if (p.h3) {
    return css`
      letter-spacing: ${h3.letterSpacing};
    `
  }
  if (p.h4) {
    return css`
      letter-spacing: ${h4.letterSpacing};
    `
  }
  if (p.h5) {
    return css`
      letter-spacing: ${h5.letterSpacing};
    `
  }
  if (p.h6) {
    return css`
      letter-spacing: ${h6.letterSpacing};
    `
  }
  if (p.p1) {
    return css`
      letter-spacing: ${p1.letterSpacing};
    `
  }
  if (p.p2) {
    return css`
      letter-spacing: ${p2.letterSpacing};
    `
  }
  if (p.p3) {
    return css`
      letter-spacing: ${p3.letterSpacing};
    `
  }
  if (p.p4) {
    return css`
      letter-spacing: ${p4.letterSpacing};
    `
  }
  if (p.p5) {
    return css`
      letter-spacing: ${p5.letterSpacing};
    `
  }
}
export const letterSpacingProps = (props, defaultSize = 'initial') => {
  if (
    props.h1 ||
    props.h2 ||
    props.h3 ||
    props.h4 ||
    props.h5 ||
    props.h6 ||
    props.p1 ||
    props.p2 ||
    props.p3 ||
    props.p4 ||
    props.p5
  ) {
    return letterSpacingMap(props)
  } else if (props.letterSpacing) {
    return css`
      letter-spacing: ${props.letterSpacing};
    `
  } else {
    return css`
      letter-spacing: ${defaultSize};
    `
  }
}

// COLOR
export const backgroundProps = props => css`
  ${props.bgColor && typeof props.bgColor === 'string' && `background-color: ${props.bgColor}`};
`
export const colorPropsVar = { color: 'color' }
export const colorProps = (props, def = 'inherit') => css`
  color: ${props.color && typeof (props.color === 'string') ? props.color : def};
`
export const colorHoverProps = (props, def = 'inherit') => css`
  &:hover {
    color: ${props.colorHover && typeof (props.colorHover === 'string') ? props.colorHover : def};
  }
`
export const bgColorHoverProps = (props, def = 'inherit') => css`
  &:hover {
    background-color: ${props.bgColorHover && typeof (props.bgColorHover === 'string') ? props.bgColorHover : def};
  }
`
export const uppercaseProps = (props, defaultTransform = 'none') => css`
  ${!props.uppercaseFalse &&
  `
    text-transform: ${props.uppercase && typeof (props.uppercase === 'boolean')
    ? props.uppercase ? 'uppercase; letter-spacing: 1px;' : 'none;'
    : defaultTransform};
  `};
`
// MARGIN
export const marginProps = props => css`
  ${props.marginBottom &&
  `margin-bottom: ${typeof props.marginBottom === 'string'
    ? props.marginBottom
    : `${props.marginBottom * goldenRatio}px` || '1em'}`};
  ${props.marginTop &&
  `margin-top: ${typeof props.marginTop === 'string'
    ? props.marginTop
    : `${props.marginTop * goldenRatio}px` || '1em'}`};
  ${props.marginLeft &&
  `margin-left: ${typeof props.marginLeft === 'string'
    ? props.marginLeft
    : `${props.marginLeft * goldenRatio}px` || '1em'}`};
  ${props.marginRight &&
  `margin-right: ${typeof props.marginRight === 'string'
    ? props.marginRight
    : `${props.marginRight * goldenRatio}px` || '1em'}`};
  ${props.margin &&
  `margin: ${typeof props.margin === 'string' ? props.margin : `${props.margin * goldenRatio}px` || '1em'}`};
  ${props.marginVertical &&
  `margin-top: ${typeof props.marginVertical === 'string'
    ? props.marginVertical
    : `${props.marginVertical * goldenRatio}px` || '1em'};
    margin-bottom: ${typeof props.marginVertical === 'string'
    ? props.marginVertical
    : `${props.marginVertical * goldenRatio}px` || '1em'};
  `};
  ${props.marginHorizontal &&
  `margin-left: ${typeof props.marginHorizontal === 'string'
    ? props.marginHorizontal
    : `${props.marginHorizontal * goldenRatio}px` || '1em'};
    margin-right: ${typeof props.marginHorizontal === 'string'
    ? props.marginHorizontal
    : `${props.marginHorizontal * goldenRatio}px` || '1em'};
  `};
  ${media.md`
    ${props.marginBottomT &&
    `margin-bottom: ${typeof props.marginBottomT === 'string'
      ? props.marginBottomT
      : `${props.marginBottomT * goldenRatio}px` || '1em'}`};
    ${props.marginTopT &&
    `margin-top: ${typeof props.marginTopT === 'string'
      ? props.marginTopT
      : `${props.marginTopT * goldenRatio}px` || '1em'}`};
    ${props.marginLeftT &&
    `margin-left: ${typeof props.marginLeftT === 'string'
      ? props.marginLeftT
      : `${props.marginLeftT * goldenRatio}px` || '1em'}`};
    ${props.marginRightT &&
    `margin-right: ${typeof props.marginRightT === 'string'
      ? props.marginRightT
      : `${props.marginRightT * goldenRatio}px` || '1em'}`};
    ${props.marginT &&
    `margin: ${typeof props.marginT === 'string' ? props.marginT : `${props.marginT * goldenRatio}px` || '1em'}`};
    ${props.marginVerticalT &&
    `margin-top: ${typeof props.marginVerticalT === 'string'
      ? props.marginVerticalT
      : `${props.marginVerticalT * goldenRatio}px` || '1em'};
      margin-bottom: ${typeof props.marginVerticalT === 'string'
      ? props.marginVerticalT
      : `${props.marginVerticalT * goldenRatio}px` || '1em'};
    `};
    ${props.marginHorizontalT &&
    `margin-left: ${typeof props.marginHorizontalT === 'string'
      ? props.marginHorizontalT
      : `${props.marginHorizontalT * goldenRatio}px` || '1em'};
      margin-right: ${typeof props.marginHorizontalT === 'string'
      ? props.marginHorizontalT
      : `${props.marginHorizontalT * goldenRatio}px` || '1em'};
    `};
  `};
  ${media.sm`
    ${props.marginBottomM &&
    `margin-bottom: ${typeof props.marginBottomM === 'string'
      ? props.marginBottomM
      : `${props.marginBottomM * goldenRatio}px` || '1em'}`};
    ${props.marginTopM &&
    `margin-top: ${typeof props.marginTopM === 'string'
      ? props.marginTopM
      : `${props.marginTopM * goldenRatio}px` || '1em'}`};
    ${props.marginLeftM &&
    `margin-left: ${typeof props.marginLeftM === 'string'
      ? props.marginLeftM
      : `${props.marginLeftM * goldenRatio}px` || '1em'}`};
    ${props.marginRightM &&
    `margin-right: ${typeof props.marginRightM === 'string'
      ? props.marginRightM
      : `${props.marginRightM * goldenRatio}px` || '1em'}`};
    ${props.marginM &&
    `margin: ${typeof props.marginM === 'string' ? props.marginM : `${props.marginM * goldenRatio}px` || '1em'}`};
    ${props.marginVerticalM &&
    `margin-top: ${typeof props.marginVerticalM === 'string'
      ? props.marginVerticalM
      : `${props.marginVerticalM * goldenRatio}px` || '1em'};
      margin-bottom: ${typeof props.marginVerticalM === 'string'
      ? props.marginVerticalM
      : `${props.marginVerticalM * goldenRatio}px` || '1em'};
    `};
    ${props.marginHorizontalM &&
    `margin-left: ${typeof props.marginHorizontalM === 'string'
      ? props.marginHorizontalM
      : `${props.marginHorizontalM * goldenRatio}px` || '1em'};
      margin-right: ${typeof props.marginHorizontalM === 'string'
      ? props.marginHorizontalM
      : `${props.marginHorizontalM * goldenRatio}px` || '1em'};
    `};
  `};
`

// PADDING
export const paddingProps = props => css`
  ${props.paddingBottom &&
  `padding-bottom: ${typeof props.paddingBottom === 'string'
    ? props.paddingBottom
    : `${props.paddingBottom * goldenRatio}px` || '1em'}`};
  ${props.paddingTop &&
  `padding-top: ${typeof props.paddingTop === 'string'
    ? props.paddingTop
    : `${props.paddingTop * goldenRatio}px` || '1em'}`};
  ${props.paddingLeft &&
  `padding-left: ${typeof props.paddingLeft === 'string'
    ? props.paddingLeft
    : `${props.paddingLeft * goldenRatio}px` || '1em'}`};
  ${props.paddingRight &&
  `padding-right: ${typeof props.paddingRight === 'string'
    ? props.paddingRight
    : `${props.paddingRight * goldenRatio}px` || '1em'}`};
  ${props.padding &&
  `padding: ${typeof props.padding === 'string' ? props.padding : `${props.padding * goldenRatio}px` || '1em'}`};
  ${props.paddingVertical &&
  `padding-top: ${typeof props.paddingVertical === 'string'
    ? props.paddingVertical
    : `${props.paddingVertical * goldenRatio}px` || '1em'};
    padding-bottom: ${typeof props.paddingVertical === 'string'
    ? props.paddingVertical
    : `${props.paddingVertical * goldenRatio}px` || '1em'};
  `};
  ${props.paddingHorizontal &&
  `padding-left: ${typeof props.paddingHorizontal === 'string'
    ? props.paddingHorizontal
    : `${props.paddingHorizontal * goldenRatio}px` || '1em'};
    padding-right: ${typeof props.paddingHorizontal === 'string'
    ? props.paddingHorizontal
    : `${props.paddingHorizontal * goldenRatio}px` || '1em'};
  `};
  ${media.md`
    ${props.paddingBottomT &&
    `padding-bottom: ${typeof props.paddingBottomT === 'string'
      ? props.paddingBottomT
      : `${props.paddingBottomT * goldenRatio}px` || '1em'}`};
    ${props.paddingTopT &&
    `padding-top: ${typeof props.paddingTopT === 'string'
      ? props.paddingTopT
      : `${props.paddingTopT * goldenRatio}px` || '1em'}`};
    ${props.paddingLeftT &&
    `padding-left: ${typeof props.paddingLeftT === 'string'
      ? props.paddingLeftT
      : `${props.paddingLeftT * goldenRatio}px` || '1em'}`};
    ${props.paddingRightT &&
    `padding-right: ${typeof props.paddingRightT === 'string'
      ? props.paddingRightT
      : `${props.paddingRightT * goldenRatio}px` || '1em'}`};
    ${props.paddingT &&
    `padding: ${typeof props.paddingT === 'string' ? props.paddingT : `${props.paddingT * goldenRatio}px` || '1em'}`};
    ${props.paddingVerticalT &&
    `padding-top: ${typeof props.paddingVerticalT === 'string'
      ? props.paddingVerticalT
      : `${props.paddingVerticalT * goldenRatio}px` || '1em'};
      padding-bottom: ${typeof props.paddingVerticalT === 'string'
      ? props.paddingVerticalT
      : `${props.paddingVerticalT * goldenRatio}px` || '1em'};
    `};
    ${props.paddingHorizontalT &&
    `padding-left: ${typeof props.paddingHorizontalT === 'string'
      ? props.paddingHorizontalT
      : `${props.paddingHorizontalT * goldenRatio}px` || '1em'};
      padding-right: ${typeof props.paddingHorizontalT === 'string'
      ? props.paddingHorizontalT
      : `${props.paddingHorizontalT * goldenRatio}px` || '1em'};
    `};
  `};
  ${media.sm`
    ${props.paddingBottomM &&
    `padding-bottom: ${typeof props.paddingBottomM === 'string'
      ? props.paddingBottomM
      : `${props.paddingBottomM * goldenRatio}px` || '1em'}`};
    ${props.paddingTopM &&
    `padding-top: ${typeof props.paddingTopM === 'string'
      ? props.paddingTopM
      : `${props.paddingTopM * goldenRatio}px` || '1em'}`};
    ${props.paddingLeftM &&
    `padding-left: ${typeof props.paddingLeftM === 'string'
      ? props.paddingLeftM
      : `${props.paddingLeftM * goldenRatio}px` || '1em'}`};
    ${props.paddingRightM &&
    `padding-right: ${typeof props.paddingRightM === 'string'
      ? props.paddingRightM
      : `${props.paddingRightM * goldenRatio}px` || '1em'}`};
    ${props.paddingM &&
    `padding: ${typeof props.paddingM === 'string' ? props.paddingM : `${props.paddingM * goldenRatio}px` || '1em'}`};
    ${props.paddingVerticalM &&
    `padding-top: ${typeof props.paddingVerticalM === 'string'
      ? props.paddingVerticalM
      : `${props.paddingVerticalM * goldenRatio}px` || '1em'};
      padding-bottom: ${typeof props.paddingVerticalM === 'string'
      ? props.paddingVerticalM
      : `${props.paddingVerticalM * goldenRatio}px` || '1em'};
    `};
    ${props.paddingHorizontalM &&
    `padding-left: ${typeof props.paddingHorizontalM === 'string'
      ? props.paddingHorizontalM
      : `${props.paddingHorizontalM * goldenRatio}px` || '1em'};
      padding-right: ${typeof props.paddingHorizontalM === 'string'
      ? props.paddingHorizontalM
      : `${props.paddingHorizontalM * goldenRatio}px` || '1em'};
    `};
  `};
`