import * as c from '../constants'

const INITIAL_STATE = {
  companies: { isLoading: false },
  company: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_COMPANIES_REQUEST:
      return { ...state, companies: { isLoading: true } }
    case c.API_GET_COMPANIES:
      return { ...state, companies: { isLoading: false } }
    case c.API_GET_COMPANIES_FAILURE:
      return { ...state, companies: { isLoading: false } }

    case c.API_SAVE_FILE_ORDER_FORMAT_REQUEST:
      return { ...state, saveFileOrderFormat: { isLoading: true } }
    case c.API_SAVE_FILE_ORDER_FORMAT_SUCCESS:
    case c.API_SAVE_FILE_ORDER_FORMAT_FAILURE:
      return state
    // return { ...state, saveFileOrderFormat: { isLoading: false } }

    case c.API_GET_COMPANY_FILE_ORDER_FORMATS_REQUEST:
      return { ...state, fileOrderFormats: true }
    case c.API_GET_COMPANY_FILE_ORDER_FORMATS_SUCCESS:
    case c.API_GET_COMPANY_FILE_ORDER_FORMATS_FAILURE:
      return { ...state, fileOrderFormats: false }

    case c.API_ADD_STANDARD_FIXED_REQUEST:
      return { ...state, addStandardFixed: true }
    case c.API_ADD_STANDARD_FIXED_SUCCESS:
    case c.API_ADD_STANDARD_FIXED_FAILURE:
      return { ...state, addStandardFixed: false }
    /* case c.API_GET_COMPANIES_REQUEST_ID:
      return { ...state, companies: { isLoading: true } }
    case c.API_GET_COMPANIES_ID:
      console.log('pa', action.payload)
      return { ...state, companies: { isLoading: true }, company:action.payload}
    case c.API_GET_COMPANIES_SUCCESS_ID:
      return { ...state, companies: { isLoading: false } }
    case c.API_GET_COMPANIES_FAILURE_ID:
      return { ...state, companies: { isLoading: false } } */
    default:
      return state
  }
}
