///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'

// FETCHING LOGS
function fetchRolesRequest(endpointPath) {
  return { type: c.FETCH_ROLES_REQUEST, endpointPath }
}
function fetchRolesSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_ROLES_SUCCESS, endpointPath })
    dispatch({
      type: c.FETCH_ROLES,
      payload: res
    })
  }
}
function fetchRolesFailure(endpointPath, err) {
  return { type: c.FETCH_ROLES_FAILURE, endpointPath }
}
export function fetchRoles() {
  return u.getValueFromStore(
    c.apiRoles,
    h.headerGetWithToken(),
    fetchRolesRequest,
    fetchRolesSuccess,
    fetchRolesFailure
  )
}
