import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import Header from '../../components/Header'
import LeftDrawer from '../../components/LeftDrawer'
import withWidth, { LARGE, SMALL } from 'material-ui/utils/withWidth'
import muiTheme from '../../theme-default'
import { selectFieldInitialValue } from '../Forms/Login/helper'
import { logout } from '../../actions/auth'
import { fetchMe, setMyPreferredLanguage } from '../../actions/me'
import { setSiteLanguageUsingId } from '../../actions/languages'
import { fetchUser } from '../../actions/users'
import './App.css';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navDrawerOpen: props.width === LARGE
    }
    this.handleLogout = this.handleLogout.bind(this)
  }

  componentDidMount() {
    this.props.fetchMe(this.props.user_id)
    this.props.fetchUser(this.props.user_id)
  }

  componentWillReceiveProps(nextProps) {
    const {
      newPreferredLanguage,
      setSiteLanguageUsingId,
      setMyPreferredLanguage,
      existingPreferredLanguage,
      user_id
    } = this.props

    //when component mounts and first loads me data (reflected in existingPreferredLanguage)
    if (!existingPreferredLanguage && nextProps.existingPreferredLanguage) {
      // if user selected a new language using the language selectField in login and userPreferrenced are different, update user preferences (state, localStorage, and cookie already set when select onChange)
      if (newPreferredLanguage && newPreferredLanguage !== nextProps.existingPreferredLanguage) {
        setMyPreferredLanguage(user_id, newPreferredLanguage)
      }
      // if user did not select a new language but the user preferences (set in selectField in login) is different than the site language value stored in cookie, then set language in state, localStorage, and cookie according to user preferences
      if (!newPreferredLanguage && nextProps.existingPreferredLanguage !== selectFieldInitialValue()) {
        setSiteLanguageUsingId(nextProps.existingPreferredLanguage)
      }
    }

    if (this.props.width !== nextProps.width) {
      this.setState({ navDrawerOpen: nextProps.width === LARGE })
    }
  }

  handleChangeRequestNavDrawer() {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    })
  }

  handleLogout() {
    this.props.logout(this.props.user_id)
    this.context.router.push('/login')
  }

  render() {
    let { navDrawerOpen } = this.state
    const paddingLeftDrawerOpen = 270
    const { role, trl, user } = this.props

    const styles = {
      header: {
        paddingLeft: navDrawerOpen ? paddingLeftDrawerOpen : 0
      },
      container: {
        paddingLeft: navDrawerOpen && this.props.width !== SMALL ? paddingLeftDrawerOpen : 0
      }
    }

    const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, { trl }))
    
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>
          {this.state.navDrawerOpen &&
            this.props.width < 3 && (
              <div
                className={`app--overlay ${this.state.navDrawerOpen ? 'app--overlay-open' : 'app--overlay-closed'}`}
                onClick={() => this.handleChangeRequestNavDrawer()}
              />
            )}
          {(!this.props.location.pathname.includes('preparation-sheet') && !this.props.location.pathname.includes('saveandprint')) && (
            <span>
              <Header
                styles={styles.header}
                handleChangeRequestNavDrawer={this.handleChangeRequestNavDrawer.bind(this)}
                handleLogout={this.handleLogout}
                showMenu={this.props.width}
                trl={trl}
                user={user}
                username={this.props.userName}
                role={role}
              />
              <LeftDrawer navDrawerOpen={navDrawerOpen} menu={role} username={this.props.userName} user={user} role={role} trl={trl} />
            </span>
          )}
          {!this.props.location.pathname.includes('preparation-sheet') &&
            !this.props.location.pathname.includes('saveandprint') && (
              <span>
                <Header
                  styles={styles.header}
                  handleChangeRequestNavDrawer={this.handleChangeRequestNavDrawer.bind(this)}
                  handleLogout={this.handleLogout}
                  showMenu={this.props.width}
                  trl={trl}
                  user={user}
                  username={this.props.userName}
                  role={role}
                />
                <LeftDrawer
                  navDrawerOpen={navDrawerOpen}
                  menu={role}
                  username={this.props.userName}
                  role={this.props.role}
                  companyType={this.props.companyType}
                  trl={trl}
                />
              </span>
            )}
          <div style={styles.container} className="app">
            {childrenWithProps}
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
}

App.contextTypes = {
  router: PropTypes.object.isRequired
}

App.propTypes = {
  children: PropTypes.element,
  width: PropTypes.number,
  userName: PropTypes.string,
  role: PropTypes.string.isRequired,
  existingPreferredLanguage: PropTypes.number,
  newPreferredLanguage: PropTypes.number,
  setSiteLanguageUsingId: PropTypes.func.isRequired,
  fetchMe: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  setMyPreferredLanguage: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user_id: PropTypes.string,
  trl: PropTypes.object.isRequired
}

function mapDispatchToProps({ auth, me, languages, users }) {
  return {
    user_id: auth.user_id,
    role: auth.user_role,
    userName: me.fullName,
    existingPreferredLanguage: me.preferred_language,
    newPreferredLanguage: languages.preferredLanguage,
    user: me
  }
}

export default connect(
  mapDispatchToProps,
  {
    logout,
    fetchMe,
    fetchUser,
    setSiteLanguageUsingId,
    setMyPreferredLanguage
  }
)(withWidth()(App))
