////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isNull } from 'lodash'
///////COMPONENTS///////
import { H2, theme } from '../../styledComponents'
import './AddItemActions.css'
import Dialog from 'material-ui/Dialog'
import { apiGetFileOrderErrors } from '../../../actions/fileOrderFollowUp'
import FileOrderErrorsTable from '../Tables/FileOrderErrorsTable'
import PageLoadTable from '../../PageLoadTable'

/////////STYLED/////////
const H2Styled = H2.extend`
  color: ${ theme.colors.lightGrey };
  background-color: ${ theme.colors.darkGrey };
  padding: 14px;
  font-size: 19px;
`
/////////STYLED/////////

class ViewFileErrors extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidUpdate(prevProps) {
    const { open } = this.props
    if ( prevProps.open !== open && open ) this.queryData()
  }

  queryData = () => {
    this.props.apiGetFileOrderErrors( this.props.row.file_ID )
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, fileOrderFollowUp, isLoading, open, openHandler, myRole } = this.props
    return (
      <div>
        <Dialog
          contentStyle={ {
            maxWidth: '100vw',
            width: '80vw',
            marginLeft: '10vw',
            padding: 0
          } }
          style={ { width: '80vw', fontSize: theme.p1.ftz, padding: 0 } }
          bodyStyle={ { padding: '10px' } }
          modal={ false }
          open={ open }
          onRequestClose={ openHandler }
        >
          {
            isLoading
              ? <PageLoadTable text={ trl.common__loading } size={ 80 }
                               thickness={ 9 }/>
              :
                <div>
                  <H2Styled>
                    { fileOrderFollowUp && `${ trl.fofu_nested_title }` }
                  </H2Styled>
                  { <FileOrderErrorsTable
                      data={ isNull( fileOrderFollowUp ) || isLoading ? [] : fileOrderFollowUp }
                      style={ { overflow: 'scroll' } }
                      isLoading={ isLoading }
                      trl={ trl }
                      myRole={myRole}
                    /> }
                </div>
          }
        </Dialog>
      </div>
    )
  }
}

ViewFileErrors.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ( {
                            me,
                            auth,
                            languages,
                            fileOrderFollowUp
                          } ) => ( {
  myRole: auth.user_role,
  myCompanyType: me.company_type,
  trl: languages.siteLanguage.keys,
  fileOrderFollowUp: fileOrderFollowUp.filesErrors,
  isLoading: fileOrderFollowUp.isLoading
} )

export default connect(
  mapStateToProps,
  { apiGetFileOrderErrors }
)( ViewFileErrors )
