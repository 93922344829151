////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import { connect } from 'react-redux'
///////COMPONENTS///////
import * as c from '../../../constants'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import MenuItem from 'material-ui/MenuItem'
import theme from '../../styledComponents/theme'
/////////ASSETS/////////
import Face from 'material-ui/svg-icons/action/face'

class LanguagesActions extends Component {

 	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const row = this.props.row
    const { trl } = this.props
    return (
      <ActionHelper>
        <MenuItem
          style={{ fontSize: theme.font.fontSize }}
          primaryText={trl.languages__tableAction_edit}
          leftIcon={<Face />}
          onClick={() => hashHistory.push(`${c.languageEdit}${row.languageid}`)}
        />
      </ActionHelper>
    )
  }
}

LanguagesActions.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ({ languages }) => ({
  trl: languages.siteLanguage.keys
})

export default connect(
  mapStateToProps,
)(LanguagesActions)
