////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem/MenuItem';
import { theme } from '../styledComponents';

class WiredSelectField extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.initialValue }
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleSelectChange = (event, index, value) => {
    this.setState({ value })
    this.props.functionOnChange.map(action => action(value))
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { floatingLabelText, fields, value, primaryText, className, initVal } = this.props
    // with brandEdit page stateValue stay similar when update since refact
    // so we send a new props initVal to display correct value
    // to prevent any side effect we test with this new props on value props
    return (
      <SelectField
        style={{ fontSize: theme.font.fontSize }}
        floatingLabelText={floatingLabelText}
        value={initVal ? initVal : this.state.value}
        onChange={this.handleSelectChange}
        className={className ? className : ''}
        floatingLabelStyle={{
          top: '30px'
        }}
        labelStyle={{
          borderRadius: 0,
          border: '1px solid #ced4da',
          padding: '10px 12px',
          width: '100%',
          height: '55%',
          position: 'relative',
          top: '11px',
          lineHeight: 'inherit',
          fontSize: theme.font.fontSize
        }}
        underlineShow={false}
      >
        {fields.map((lang, key) => {
          return <MenuItem style={{ fontSize: theme.font.fontSize }} key={key} value={lang[value]} primaryText={lang[primaryText]} />
        })}
      </SelectField>
    )
  }
}

WiredSelectField.propTypes = {
  fields: PropTypes.array,
  floatingLabelText: PropTypes.string,
  functionOnChange: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default WiredSelectField
