////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
///////COMPONENTS///////
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

const tables = {
  totalSalesVolume: {
    table: {
      salesVolume: { text: 'statistics_tableTitle_totalSales', format: 'text' },
      totalCurrentSeason: { text: 'statistics_tableTitle_currentSeason', format: 'text' },
      totalLastSeason: { text: 'statistics_tableTitle_n1', format: 'text' },
      totalSecondToLastSeason: { text: 'statistics_tableTitle_n2', format: 'text' }
    },
    fixedColumn: {
      fixedItems: 'statistics_tableTitle_fixedItems',
      totalSales: 'statistics_tableTitle_totalSales',
      variables: 'statistics_tableTitle_variableItems'
    }
  },
  averageServiceRate: {
    table: {
      average: { text: 'statistics_tableTitle_averageServiceRate', format: 'text' },
      currentSeasonLeadtimePercent: { text: 'statistics_tableTitle_currentSeason', format: 'text' },
      lastSeasonLeadtimePercent: { text: 'statistics_tableTitle_n1', format: 'text' },
      secondToLastSeasonLeadtimePercent: { text: 'statistics_tableTitle_n2', format: 'text' }
    },
    fixedColumn: {
      leadTime: 'statistics_tableTitle_leadTime'
    }
  }
}

const StatsTableAnalysisSales = (props) => {
  const { data, trl, format, formatTable } = props
  let dataFilter
  let tableProps
  if (typeof data['totalSalesVolume'] !== 'undefined') {
    dataFilter = { ...data['totalSalesVolume'] }
    tableProps = tables['totalSalesVolume']
    map(dataFilter, (elem, index) => {
      let sign = Math.sign(elem['percentCurrentSeason']) === 1 ? '+' : ''
      let format = []
      if (elem['percentCurrentSeason'] > 0) {
        format.push(
          <span key={index}>
            {elem['totalCurrentSeason']}
            <span className="plus">&nbsp;({sign + elem['percentCurrentSeason']}%)</span>
          </span>
        )
      }
      if (elem['percentCurrentSeason'] < 0) {
        format.push(
          <span key={index}>
            {elem['totalCurrentSeason']}
            <span className="minus">&nbsp;({sign + elem['percentCurrentSeason']}%)</span>
          </span>
        )
      }
      if (elem['percentCurrentSeason'] === 0) {
        format.push(
          <span key={index}>
            {elem['totalCurrentSeason']}
            <span className="plus">&nbsp;({sign + elem['percentCurrentSeason']}%)</span>
          </span>
        )
      }
      elem['totalCurrentSeason'] = format
      delete elem['percentCurrentSeason']
      sign = Math.sign(elem['percentLastSeason']) === 1 ? '+' : ''
      format = []
      if (elem['percentLastSeason'] > 0) {
        format.push(
          <span key={index}>
            {elem['totalLastSeason']}
            <span className="plus">&nbsp;({sign + elem['percentLastSeason']}%)</span>
          </span>
        )
      }
      if (elem['percentLastSeason'] < 0) {
        format.push(
          <span key={index}>
            {elem['totalLastSeason']}
            <span className="minus">&nbsp;({sign + elem['percentLastSeason']}%)</span>
          </span>
        )
      }
      if (elem['percentLastSeason'] === 0) {
        format.push(
          <span key={index}>
            {elem['totalLastSeason']}
            <span className="plus">&nbsp;({sign + elem['percentLastSeason']}%)</span>
          </span>
        )
      }
      elem['totalLastSeason'] = format
      delete elem['percentLastSeason']
    })
  } else if (typeof data['averageServiceRate'] !== 'undefined') {
    dataFilter = { ...data['averageServiceRate'] }
    tableProps = tables['averageServiceRate']
  }
  if (!Array.isArray(data) && typeof data !== 'object') return null

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div>
      <div>
        <Table className={formatTable} fixedHeader={false}>
          <TableHeader
            style={{ borderBottom: '0px' }}
            displaySelectAll={false}
            enableSelectAll={false}
            adjustForCheckbox={false}
          >
            <TableRow style={{ borderBottom: '0px' }}>
              {map(tableProps.table, (header, key) => {
                let titleFormat = 'AnalysisTitle '
                titleFormat += format && format.title[key]
                return (
                  <TableHeaderColumn
                    className={format.tableHeader[key]}
                    style={{ textAlign: 'center', padding: '24px 1%', borderBottom: '1px solid black' }}
                    key={key}
                  >
                    <p className={titleFormat}>{trl[header.text]}</p>
                  </TableHeaderColumn>
                )
              })}
            </TableRow>
          </TableHeader>
          <TableBody style={{ borderBottom: '0px' }} displayRowCheckbox={false}>
            {map(dataFilter, (row, idx) => {
              let totalSalesToBold
              idx === 'totalSales' || idx === 'leadTime' ? (totalSalesToBold = 'bold') : (totalSalesToBold = '')
              return (
                <TableRow key={idx} style={{ height: 30, borderBottom: '0px' }}>
                  <TableRowColumn
                    style={{ textAlign: 'center', height: 'auto !important', fontWeight: totalSalesToBold }}
                  >
                    <span>{trl[tableProps['fixedColumn'][idx]]}</span>
                  </TableRowColumn>
                  {map(row, (elem, index) => {
                    return (
                      <TableRowColumn
                        className={format.tableBody[index]}
                        key={index}
                        style={{ textAlign: 'center', height: 'auto !important' }}
                      >
                        <span>{elem} </span>
                      </TableRowColumn>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  ) // end return
} // end function

StatsTableAnalysisSales.propTypes = {
  data: PropTypes.array.isRequired
}

export default StatsTableAnalysisSales
