////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'
import { renderInputWithError } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import stringformat from '../../../utils/stringformat'
import { theme } from '../../../components/styledComponents'

/////////STYLED/////////
const OKbutton = styled.button`
  width: 32px;
  height: 30px;
  font-size: 10px;
  border-radius: 50%;
  line-height: 30px;
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  &:hover {
    transition: 0.2s;
    color: white;
    background-color: ${theme.colors.lightGrey};
    color: ${theme.colors.black};
  }
  margin-left: 7px;
`
const Form = styled.form`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 500;
  & > span {
    width: 80px;
  }
`
/////////STYLED/////////

const validate = (values, props) => {
  const errors = {}
  const { trl } = props
  const regCharExcluded = RegExp(/[&<>'"]/)
  if (values.iD_Commande_Externe_Faconnier && values.iD_Commande_Externe_Faconnier.length > 30) errors.iD_Commande_Externe_Faconnier = stringformat(props.trl.common__max_length_error, 30) || ' '
  if (regCharExcluded.test(values.iD_Commande_Externe_Faconnier)) errors['iD_Commande_Externe_Faconnier'] = `${trl.common_error_unauthorizedCars}`
  return errors
}

class IdExtFaconnier extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.initialize(this.props.order)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { order } = this.props
    if (order !== prevProps.order) this.props.initialize(order)
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit = (formValues) => {
    const { iD_Commande_Externe_Faconnier } = formValues
    const { apiUpdateOrderPoFac, order } = this.props
    const newOrderId = order.iD_Commande
    const userModel = {
      iD_Commande: parseInt(newOrderId, 10),
      iD_Commande_Externe_Faconnier
    }
    return apiUpdateOrderPoFac(newOrderId, userModel)
  }

  handleChangeGMPO(event, index, value) {
    if (this.props.valid) {
      let iD_Commande_Externe_Faconnier = event.target.value
      const { apiUpdateOrderPoFac, order } = this.props
      const newOrderId = order.iD_Commande
      const userModel = {
        iD_Commande: parseInt(newOrderId, 10),
        iD_Commande_Externe_Faconnier
      }
      return apiUpdateOrderPoFac(newOrderId, userModel)
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, error, handleSubmit, submitting } = this.props
    this.handleChangeGMPO = this.handleChangeGMPO.bind(this)
    return (
      <Form onSubmit={handleSubmit(this.onSubmit).bind(this)}>
        <span style={{ whiteSpace: 'nowrap' }}>{trl.common__external_id_fac}: </span>
        <Field
          name="iD_Commande_Externe_Faconnier"
          type="text"
          underlineStyle={{ borderBottomColor: 'white' }}
          style={{
            paddingLeft: 7,
            paddingRight: 7,
            'background-color': '#ffffff',
            'max-height': '2em',
            'margin-bottom': '1px',
            width: 'auto',
            'max-width': '220px'
          }}
          inputStyle={{ textAlign: 'center', fontSize: 12 }}
          component={renderInputWithError}
          fullWidth={false}
          onBlur={this.handleChangeGMPO}
          maxLength={20}
        />
        {error && <span>{error}</span>}
        <div>
          {!submitting && (
            <OKbutton type="submit" disabled={submitting}>
              {trl.common__ok}
            </OKbutton>
          )}
          {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
        </div>
        {error && <div>{error && <p style={styles.loginError}>{error}</p>}</div>}
      </Form>
    )
  }
}

IdExtFaconnier.propTypes = {
  trl: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
  initialValues: PropTypes.object.isRequired,
  apiUpdateOrderPoFac: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
}

IdExtFaconnier = reduxForm({
  form: 'idExtFaconnierForm',
  validate,
  asyncBlurFields: ['iD_Commande_Externe_Faconnier'],
  touchOnBlur: false
})(IdExtFaconnier)

export default IdExtFaconnier
