////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm , reset} from 'redux-form'
import { MenuItem } from 'material-ui'
import SelectField from 'material-ui/SelectField'
import {map  } from "lodash";
///////COMPONENTS///////
import { renderInput, renderSelectField } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import CircularProgress from 'material-ui/CircularProgress'
import { apiCreateCarrier } from '../../../actions/companies'
import theme from '../../../components/styledComponents/theme'
import * as SC from '../../../components/styledComponents'
import { Component } from 'react'

const validate = (values, props) => {
  console.log('VALUES', values)
  console.log('PROPS', props)
  const errors = {}
  const { trl } = props
  const regCharExcluded = RegExp(/[&<>'"]/)

  if (regCharExcluded.test(values.Transporteur_Name)) errors.Transporteur_Name = `${trl.common_error_unauthorizedCars}`
  if (!values.Transporteur_Name) errors.Transporteur_Name = `${trl.common__field_required}`
  if (regCharExcluded.test(values.ID_Client_External)) errors.ID_Client_External = `${trl.common_error_unauthorizedCars}`
  if (!values.ID_Client_External) errors.ID_Client_External = `${trl.common__field_required}`
  if (!values.Service) errors.Service = `${trl.common__field_required}`
  if (!values.Label) errors.Label = `${trl.common__field_required}`
  return errors
}


class DeliveryMethod extends Component {
  constructor(props ) {
    super(props)

  }

  componentWillMount() {
    this.props.dispatch(reset('OrderDeliveryDetail'));
  
  }

 onSubmit = (formValues) => {
    const { ID_Client_External, Service, Label, Transporteur_Name } = formValues
    const userModel = {
      ID_Societe: this.props.idFaconnier,
      Transporteur_Name : Label ,
      ID_Client_External: ID_Client_External ? ID_Client_External : '',
      Service: Service ? Service : '',
      ID_Carrier : Transporteur_Name
    }
    return this.props.dispatch(apiCreateCarrier(userModel, this.props.idFaconnier))
  }
 

  render () {
    const {
       pristine, handleSubmit, submitting, error, submitSucceeded, trl, carrierList } = this.props 
   
       return (
        <form onSubmit={handleSubmit(this.onSubmit)} style={{ position: 'absolute', top: -570, left: 14, width: "500px" }}>
          <Field name="Label" type="text" component={renderInput} label={trl.mycarriers_modal_Label} style={{ fontSize: theme.font.fontSize }}/>
          <Field
            //onChange={this.handleChangeInvoiceAdress}
            name="Transporteur_Name"
            component={renderSelectField}
            label={trl.delivery_mode__carrier_name}
            fullWidth={false}
          >
            {carrierList.map((a, index) => (
                <MenuItem style={{ fontSize: theme.font.fontSize }} key={a.id} value={a.id} primaryText={a.label} />
              ))}
          </Field>
          <Field name="Service" component={renderSelectField} label={trl.delivery_mode__service}>
            <MenuItem style={{ fontSize: theme.font.fontSize }} value={'Economic'} primaryText={'Economic'} />
            <MenuItem style={{ fontSize: theme.font.fontSize }} value={'Express'} primaryText={'Express'} />
          </Field>
          <Field name="ID_Client_External" type="text" component={renderInput} label={trl.delivery_mode__client_id} style={{ fontSize: theme.font.fontSize }}/>
          <div>
            {error && <p style={styles.loginError}>{error}</p>}
            {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
       
            <SC.ButtonPA_S
              style={{
                ...styles.loginBtn,
                margin: '1rem 0'
              }}
              disabled={submitting || pristine || (submitSucceeded && pristine)}
              type="submit"
            >
              {trl.delivery_mode__save_btn || ' '}
            </SC.ButtonPA_S>
          </div>
        </form>
      )
   
    }

  }








/* let DeliveryMethod = (props) => {

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  const onSubmit = (formValues) => {
    const { ID_Client_External, Service, Label, Transporteur_Name } = formValues
    const userModel = {
      ID_Societe: props.idFaconnier,
      Transporteur_Name : Label ,
      ID_Client_External: ID_Client_External ? ID_Client_External : '',
      Service: Service ? Service : '',
      ID_Carrier : Transporteur_Name
    }
   
    return props.dispatch(apiCreateCarrier(userModel, props.idFaconnier))
  }
  const { pristine, handleSubmit, submitting, error, submitSucceeded, trl, carrierList } = props





  ///////////////////////////////// RENDER ///////////////////////////////////////
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'absolute', top: -570, left: 14 }}>
      <Field name="Label" type="text" component={renderInput} label={trl.mycarriers_modal_Label} />
      <Field
        //onChange={this.handleChangeInvoiceAdress}
        name="Transporteur_Name"
        component={renderSelectField}
        label={trl.delivery_mode__carrier_name}
        fullWidth={false}
      >
        {carrierList.map((a, index) => (
            <MenuItem style={{ fontSize: theme.font.fontSize }} key={a.id} value={a.id} primaryText={a.label} />
          ))}
      </Field>
      <Field name="Service" component={renderSelectField} label={trl.delivery_mode__service}>
        <MenuItem style={{ fontSize: theme.font.fontSize }} value={'Economic'} primaryText={'Economic'} />
        <MenuItem style={{ fontSize: theme.font.fontSize }} value={'Express'} primaryText={'Express'} />
      </Field>
      <Field name="ID_Client_External" type="text" component={renderInput} label={trl.delivery_mode__client_id} />
      <div>
        {error && <p style={styles.loginError}>{error}</p>}
        {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
   
        <SC.ButtonPA_S
          style={{
            ...styles.loginBtn,
            margin: '1rem 0'
          }}
          disabled={submitting || pristine || (submitSucceeded && pristine)}
          type="submit"
        >
          {trl.delivery_mode__save_btn || ' '}
        </SC.ButtonPA_S>
      </div>
    </form>
  )
} */

DeliveryMethod.propTypes = {
  trl: PropTypes.object.isRequired,
  apiCreateCarrier: PropTypes.func.isRequired,
  idFaconnier: PropTypes.string.isRequired
}

export default DeliveryMethod = reduxForm({
  form: 'OrderDeliveryDetail',
  validate,
  touchOnBlur: false
})(DeliveryMethod)
