import * as c from '../../constants'

export const allowedRolesPerFunction = {
  [c.cancelArticle]: {
    [c.ADMGlobal]: false,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.qualifyArticle]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false,
  },
  [c.isFacOrFaca]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.funcUpdateId]: {
    [c.ADMGlobal]: false,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.funcCreateNewUser]: {
    [c.ADMGlobal]: true,
    [c.EAdmin]: true,
    [c.FAC]: false,
    [c.FACA]: true,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.PAdmin]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.POP]: false
  },

  [c.viewUserEnableDisable]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: false,
    [c.EAdmin]: true,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.addLanguage]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.seeOrderDetail]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.seeOrderRepeat]: {
    [c.seeOrderRepeat]: false,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.viewShipment]: {
    [c.ADMGlobal]: true,
    [c.LPAdmin]: true,
    [c.CSP]: true,
    [c.LSAdmin]: true,
    [c.CSS]: true,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.actionPrintInvoice]: {
    [c.ADMGlobal]: true,
    [c.LPAdmin]: true,
    [c.CSP]: true,
    [c.LSAdmin]: true,
    [c.CSS]: true,
    [c.EAdmin]: false,
    [c.EAC]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.updateWishedExpeditionDate]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },

  [c.updateDeliveryZone]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: false,
    [c.EAdmin]: false,
    [c.FAC]: true,
    [c.FACA]: true,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },
  [c.cancelFileOrder]: {
    [c.ADMGlobal]: true,
    [c.CSP]: false,
    [c.CSS]: false,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: false,
    [c.LSAdmin]: false,
    [c.PAdmin]: false,
    [c.POP]: false
  },
  [c.reassignOrder]: {
    [c.ADMGlobal]: true,
    [c.CSP]: true,
    [c.CSS]: true,
    [c.EAC]: true,
    [c.EAdmin]: true,
    [c.FAC]: false,
    [c.FACA]: false,
    [c.LPAdmin]: true,
    [c.LSAdmin]: true,
    [c.GAdmin]: true,
    [c.PAdmin]: false,
    [c.POP]: false
  }
}

export function checkRoleHasRightsToFunction(role, funcName) {
  return allowedRolesPerFunction[funcName][role]
}
