import * as c from '../constants'

const INITIAL_STATE = { users: [], user: {} }

export default function usersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.FETCH_USERS:
      return { ...state, users: action.payload }

    case c.FETCH_USER:
      return { ...state, user: { ...action.payload } }

    case c.EDIT_USER:
      return {
        ...state,
        users: state.users.map((user) => (user.id === action.payload.id ? { ...user, ...action.payload } : user))
      }

    case c.ADD_USER_TO_USERS:
      return { ...state, users: [...state.users, action.payload] }

    case c.REMOVE_USER_FROM_STATE:
      return { ...state, user: {} }

    case c.TOGGLE_USER_LOCKOUT:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? { ...user, lockoutEnabled: !user.lockoutEnabled } : user
        )
      }

    default:
  }

  return state
}
