////////LIBRARY/////////
import React, { Component } from 'react'
import _ from 'lodash'
///////COMPONENTS///////
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'
import theme from '../../styledComponents/theme'
/////////ASSETS/////////
import Navigation from 'material-ui/svg-icons/navigation/menu'

const { colors } = theme

class ActionHelper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDisabled: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    // 1- we test if we receive a isPropsArray from action helper component
    // 2- we test if on value inside array are diff than false. If this test are true we can disabled button
    if (this.props.isPropsArray && !_.isUndefined(this.props.children)) {
      const isBelowThreshold = (currentValue) => currentValue === false
      if (this.props.children.every(isBelowThreshold)) this.setState({ isDisabled: true })
    } 
    else if (_.isUndefined(this.props.children)) this.setState({ isDisabled: true })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { isModalOpened } = this.props

    console.log('isModalOpened', isModalOpened)
    return (
      <IconMenu
        color={colors.white}
        touchTapCloseDelay={200}
        open={isModalOpened ? false : null}
        iconButtonElement={
          <IconButton disabled={this.state.isDisabled}>
            <Navigation />
          </IconButton>
        }
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClick={this.props.onClick}
        autoWidth={true}
        menuStyle={this.props.minWidth ? { minWidth: `${this.props.minWidth}px` } : {}}
      >
        {this.props.children}
      </IconMenu>
    )
  }
}

export default ActionHelper
