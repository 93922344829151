////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _, { isUndefined } from 'lodash'
///////COMPONENTS///////
import { PageGutter } from '../../components/styledComponents'
import { apiGetOrderArticle } from '../../actions/orders'
import PageLoad from '../../components/PageLoad'
import { PageHeader, HelpComponent } from '../../components/sharedComponents'
import OrderDetailInformation from '../../containers/OrderDetail/OrderDetailInformation'

class ConfirmOrderMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showHelpComponent: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.apiGetOrderArticle(this.props.routeParams.orderId)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, orders, routeParams, myRole } = this.props
    const order = orders[routeParams.orderId]
    return (_.isEmpty(orders) || isUndefined(order)) ? (
      <PageLoad text={trl.common__loading} size={80} thickness={9} />
    ) : (
      <PageGutter>
        <PageHeader
          title={`PO ${order.iD_Commande} - ${trl.common__orderConfirmation}`}
          base64Logo={
            order && order.brandLogo
              ? order.brandLogo
              : null
          }
        />
        <OrderDetailInformation
          trl={trl}
          order={order}
          myRole={myRole}
          handleCancelOrder={this.handleCancelOrder}
          garmentMakerStatic
        />
        <p>
          <strong>{trl.order_confirm_successBATPending__message}</strong>
        </p>
        <br />
        <br />
        <br />
        <div style={{ position: 'relative' }}>
          <img
            src={require('../../assets/centre_appel.png')}
            alt="call center"
            style={{ marginBottom: 30, display: 'block', cursor: 'pointer', width: 50 }}
            onMouseEnter={() => this.setState({ showHelpComponent: true })}
            onMouseLeave={() => this.setState({ showHelpComponent: false })}
          />
          {this.state.showHelpComponent && <HelpComponent />}
        </div>
      </PageGutter>
    )
  }
}

const mapStateToProps = ({ orders, auth }) => ({ orders, myRole: auth.user_role })

ConfirmOrderMessage.propTypes = {
  trl: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  apiGetOrderArticle: PropTypes.func.isRequired
}

ConfirmOrderMessage.contextTypes = {
  router: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  { apiGetOrderArticle }
)(ConfirmOrderMessage)
