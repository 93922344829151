////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'

const ArticleType = ({ children, row }) => {

///////////////////////////////// RENDER ///////////////////////////////////////

  return ( 
    <div>
      <span>{`${row.article_Type_Name} / ${children ? 'F' : 'V'}`}</span>
    </div>
  )
}

ArticleType.propTypes = {
  children: PropTypes.bool.isRequired
}
export default ArticleType
