////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'
import LinearProgress from 'material-ui/LinearProgress';

const PageLoadTable = props => {
  const { text, size, thickness, style } = props
  const styles = {
    textAlign: 'center',
    h3: {
      marginTop: 20
    },
    display: style === 'linear' ? 'flex' : 'block',
    alignItems: 'flex-end',
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div style={{ ...styles }}>
      {style === 'linear' ?
        <LinearProgress size={size} thickness={thickness} color={'rgb(221, 221, 221)'} />
        :
        <CircularProgress size={size} thickness={thickness} />
      }
      <h3 style={{ ...styles.h3 }}>{text}</h3>
    </div>
  )
}

PageLoadTable.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PageLoadTable
