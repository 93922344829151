////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _, { debounce } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import Checkbox from 'material-ui/Checkbox'
import ArticlesTable from '../../components/Sematable/Tables/ArticlesTable'
import { apiGetOrderArticle } from '../../actions/orders'
import { GetOrderCompanyByType, resetOrderCompanyByType } from '../../actions/companies'
import PageLoad from '../../components/PageLoad'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { PageHeader } from '../../components/sharedComponents'
import stringformat from '../../utils/stringformat'
import * as SC from '../../components/styledComponents'
import * as c from '../../constants'
import { theme } from '../../components/styledComponents'
import { TextField } from 'material-ui'

/////////STYLED/////////
const SearchContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  // width: 100%;
`
const TextLeftStyled = styled.p`
  padding-right: 15px;
  transform: translateY(6px);
`
const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
`
const CustomGutter = styled.div`
  width: 92%;
  margin: 0 auto;
`
const ButtonWrapped = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  width: calc(33% - 10px);
  margin: 2rem 0;
  @media (max-width: 1460px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const FormsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  flex-grow: 1;
  width: calc(66% - 10px);
  & > * {
    flex: 1 1 25%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1460px) {
    width: 50%;
    & > * {
      flex: 1 1 100%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const FormWrapped = styled.form`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  @media (max-width: 1460px) {
    justify-content: space-between;
  }
`

class AddItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      all_articles: [],
      articles: [],
      searchTerm: '',
      searchTermDomain: 'All',
      showStaticItems: true,
      showVariableItems: true
    }
    this.handleFilterArticles = this.handleFilterArticles.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.toggleShowStaticItems = this.toggleShowStaticItems.bind(this)
    this.toggleShowVariableItems = this.toggleShowVariableItems.bind(this)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.GetOrderCompanyByType(this.props.params.orderId)
    if (!this.props.orders[this.props.routeParams.orderId]) this.props.apiGetOrderArticle(this.props.params.orderId)
    // already have articles from api, need to reinitilize state with articles
    if (this.props.orders && this.props.companies && !_.isEmpty(this.props.orders) && !this.state.articles.length) {
      const order = this.props.orders[this.props.routeParams.orderId]
      if (order) {
        const company = this.props.companies.companyType
        if (company) this.setState({ articles: company.articles, all_articles: company.articles })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // set articles from API result to local state
    const order = nextProps.orders[nextProps.routeParams.orderId]
    if (order) {
      if(order.status.idStatus > 8 ) {
        this.setState({ showStaticItems: false })      }
      const company = nextProps.companies.companyType
      if (company) {
        if (!_.isEqual(company.articles, this.state.articles)) this.setState({ articles: company.articles, all_articles: company.articles }, this.handleFilterArticles)
      }
    }
  }

  componentWillUnmount() {
    this.props.resetOrderCompanyByType()
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleFilterArticles() {
    let articles = _.cloneDeep(this.state.all_articles)
    const { searchTermDomain, searchTerm, showStaticItems, showVariableItems } = this.state
    const term = searchTerm.toString().toLowerCase()
    // filter by search term
    if (term) {
      //filter by searchTermDomain
      if (searchTermDomain !== 'All') {
        articles = _.filter(articles, (o) =>
          o[searchTermDomain] ? o[searchTermDomain].toString().toLowerCase().includes(term) : false
        )
      } else {
        articles = _.filter(articles, (o) => {
          const { iD_Article_Externe_SAP, iD_Article_Externe_Enseigne, article_Name, article_Type_Name } = o
          return (
            (iD_Article_Externe_SAP ? iD_Article_Externe_SAP.toString().toLowerCase().includes(term) : false) ||
            (iD_Article_Externe_Enseigne ? iD_Article_Externe_Enseigne.toLowerCase().includes(term) : false) ||
            (article_Name ? article_Name.toLowerCase().includes(term) : false) ||
            (article_Type_Name ? article_Type_Name.toLowerCase().includes(term) : false)
          )
        })
      }
    }
    //hide static articles
    if (!showStaticItems) articles = articles.filter((a) => a.article_Fixe === true)
    //hide static articles
    if (!showVariableItems) articles = articles.filter((a) => a.article_Fixe === false)
    this.setState({ articles })
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.debounceFn) this.debounceFn.cancel()
      this.debounceFn = debounce(this.handleFilterArticles, 500)
      this.debounceFn()
    })
  }

  handleFilter(e, index, value, name) { this.setState({ [name]: value }, this.handleFilterArticles)
  }

  handleReset = () => {
    this.setState({
      articles: this.state.all_articles,
      searchTerm: '',
      searchTermDomain: 'All',
      showStaticItems: true,
      showVariableItems: true
    })
  }

  toggleShowStaticItems = () => this.setState({ showStaticItems: !this.state.showStaticItems }, this.handleFilterArticles)

  toggleShowVariableItems = () => this.setState({ showVariableItems: !this.state.showVariableItems }, this.handleFilterArticles)

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, orders, companies, routeParams, isLoading } = this.props
    const order = orders ? orders[routeParams.orderId] : null
    const company = companies.companyType
    if (!company || !this.state.articles || !order || isLoading || !orders) {
      return <PageLoad text={trl.common__loading} size={80} thickness={9}/>
    }
    return (
      <div>
        <PageHeader
          title={stringformat(trl.order_add_item__title, order.enseigne_Name)}
          base64Logo={order ? order.brandLogo : null}
        />
        <CustomGutter>
          <SC.Sky grey>
            <SC.PageGutter>
              {/* ADVANCED SEARCH AND FILTER */}
              {/* 5-1,2 SEARCH & DOMAIN CHOICE */}
              <SearchContainerStyled>
                <FormsWrapper>
                  <FormWrapped onKeyDown={(e) => { if (e.key === "Enter" || e.keyCode === 13) { e.preventDefault(); } }}>
                    <TextLeftStyled>{trl.common__search} : </TextLeftStyled>
                    <div>
                      <TextField
                        type="text"
                        style={{ width: 300, fontSize: '13'}}
                        name="searchTerm"
                        value={this.state.searchTerm}
                        onChange={this.handleChange}
                      />
                    </div>
                  </FormWrapped>
                  <FormWrapped>
                    <span style={{ padding: '0 10px' }}>{trl.common__by} : </span>
                    <SelectField
                      style={{ fontSize: theme.font.fontSize, top: '-12px' }}
                      floatingLabelText={trl.common__search_by || ' '}
                      value={this.state.searchTermDomain}
                      onChange={(e, index, value) => this.handleFilter(e, index, value, 'searchTermDomain')}
                    >
                      <MenuItem style={{ fontSize: theme.font.fontSize }} value="All" primaryText={trl.common__all} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        value="iD_Article_Externe_SAP"
                        primaryText={trl.common__item_id}
                      />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        value="iD_Article_Externe_Enseigne"
                        primaryText={trl.common__external_item_id_est}
                      />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        value="article_Name"
                        primaryText={trl.common__item_name}
                      />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        value="article_Type_Name"
                        primaryText={trl.common__item_type_name}
                      />
                    </SelectField>
                  </FormWrapped>
                </FormsWrapper>
                <ButtonWrapped>
                  <SC.ButtonPA_L onClick={() => this.context.router.push(`${c.orderDetail}${order.iD_Commande}`)}>
                    {trl.order_add_item__back_to_order_btn}
                  </SC.ButtonPA_L>
                </ButtonWrapped>
              </SearchContainerStyled>
              {/**/}
              <CheckboxContainer>
                {/* 7 RESET */}
                <Form style={{ margin: '2rem 0' }}>
                  <Checkbox
                    style={{}}
                    labelStyle={{ width: 'initial' }}
                    label={trl.common__show_static_items || ' '}
                    checked={this.state.showVariableItems}
                    onCheck={this.toggleShowVariableItems}
                    disabled={order.status.idStatus > 8}
                  />
                  <Checkbox
                    style={{}}
                    labelStyle={{ width: 'initial' }}
                    label={trl.common__show_variable_items || ' '}
                    checked={this.state.showStaticItems}
                    onCheck={this.toggleShowStaticItems}
                    disabled={order.status.idStatus > 8}
                  />
                </Form>
              </CheckboxContainer>
              <br />
              <SC.ButtonS disabled={order.status.idStatus > 8} style={{cursor: order.status.idStatus <= 8 ? "pointer" : "not-allowed"}} onClick={this.handleReset}>{trl.common__filter_reset_btn}</SC.ButtonS>
            </SC.PageGutter>
          </SC.Sky>

          {/* ARTICLES TABLE */}
          <SC.PageGutter>
            {this.state.articles.length ? (
              <ArticlesTable
                trl={trl}
                data={this.state.articles.map((el) => {
                  // Add the orderId from routeParams to each row so action can know about it
                  el.orderId = routeParams.orderId
                  return el
                })}
              />
            ) : (
              <p>{trl.common__no_item_in_brand}</p>
            )}
            <SC.ButtonPA_M onClick={() => this.context.router.push(`${c.orderDetail}${order.iD_Commande}`)}>
              {trl.order_add_item__back_to_order_btn}
            </SC.ButtonPA_M>
          </SC.PageGutter>
        </CustomGutter>
      </div>
    )
  }
}

function mapStateToProps({ orders, companies, auth, apiCallStateManager }) {
  return {
    orders,
    companies,
    role: auth.user_role,
    apiCallStateManager
  }
}

AddItem.contextTypes = {
  router: PropTypes.object.isRequired
}

AddItem.propTypes = {
  GetOrderCompanyByType: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiGetOrderArticle: PropTypes.func.isRequired,
  apiCallStateManager: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { apiGetOrderArticle, GetOrderCompanyByType, resetOrderCompanyByType })(AddItem)
