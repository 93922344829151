////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty, map, orderBy, isNull, cloneDeep, isEqual } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
///////COMPONENTS///////
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import TextField from 'material-ui/TextField'
import Checkbox from 'material-ui/Checkbox'
import { theme } from '../../components/styledComponents'
import { PageHeader, TableWrapper } from '../../components/sharedComponents'
import * as SC from '../../components/styledComponents'
import ProductionFollowUpTable from '../../components/Sematable/Tables/ProductionFollowUpTable'
import { apiGetProductionFollowUp, apiProductionFollowUpExport } from '../../actions/productionFollowUp'
import dateFormatConverter from '../../utils/dateFormatConverter'
import DatePicker from 'material-ui/DatePicker'

/////////STYLED/////////
const Label = styled.span`
  color: ${props => props.color ? props.color : 'inherit'};
  font-size: ${props => props.isTitle ? '14px' : '12px'};
  margin-right: 8px;
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
`
const FilterAndTableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 5px;
`
const Check = styled(Checkbox)`
  width: auto !important;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
const BodyTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
  padding: 20px;
`
const LeftFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
  margin-bottom: 30px;
`
const RightFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  grid-auto-rows: max-content;
  align-items: center;
  margin-bottom: 30px;
`
const TableRow = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  width: 100%;
`
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  align-items: center;
  grid-column-gap: 100px;
`
const CheckboxWrapperRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 16px;
  justify-items: center;
  width: 100%;
  grid-column: 1 / 3;
`
const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`
/////////STYLED/////////

class ProductionFollowUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idBrand: 0,
      idLabelist: 0,
      idPrintShop: 0,
      startDate: moment(this.setStartDate()).startOf('days').toDate(),
      endDate: moment(this.setEndDate()).toDate(),
      status: [33, 35, 36, 40],
      inputSearchBy: {
        key: '',
        value: 'common__all'
      },
      isFirstLoad: true
    }
    this.defaultState = this.state
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  //  TODO Change method : setState is not compatible with this method
  componentWillReceiveProps(nextProps) {
    const { xlsx, filtersSettings } = nextProps
    if (!this.props.xlsx && xlsx) {
      setTimeout(() => {
        this.exportXlsx && this.exportXlsx.click()
      }, 1000)
    }
    if (!isNull(filtersSettings)) {
      if (filtersSettings.selectedBrandId !== this.state.idBrand) this.setState({ idBrand: isNull(filtersSettings.selectedBrandId) ? 0 : filtersSettings.selectedBrandId })
      if (filtersSettings.selectedLabellistId !== this.state.idLabelist) this.setState({ idLabelist: isNull(filtersSettings.selectedLabellistId) ? 0 : filtersSettings.selectedLabellistId })
      if (filtersSettings.selectedPrintShopId !== this.state.idPrintShop) this.setState({ idPrintShop: isNull(filtersSettings.selectedPrintShopId) ? 0 : filtersSettings.selectedPrintShopId })
    }
  }

  componentDidMount() {
    this.queryData(this.defaultState)
  }

  queryData = (defaultState) => {
    const inputSearchBy = cloneDeep(this.state.inputSearchBy)
    if (isEqual(defaultState, this.defaultState)) 
      this.props.apiGetProductionFollowUp({
        ID_Brand: this.defaultState.idBrand === 0 ? null : this.defaultState.idBrand,
        ID_Labellist: this.defaultState.idLabelist === 0 ? null : this.defaultState.idLabelist,
        ID_PrintShop: this.defaultState.idPrintShop === 0 ? null : this.defaultState.idPrintShop,
        startDate: this.defaultState.startDate,
        endDate: this.defaultState.endDate,
        status: this.defaultState.status,
        InputSearchBy: {
          value: this.defaultState.inputSearchBy.value != 'common__all' ? this.defaultState.inputSearchBy.value : '',
          key: this.defaultState.inputSearchBy.key
        }
      })
    else {
      this.props.apiGetProductionFollowUp({
        ID_Brand: this.state.idBrand === 0 ? null : this.state.idBrand,
        ID_Labellist: this.state.idLabelist === 0 ? null : this.state.idLabelist,
        ID_PrintShop: this.state.idPrintShop === 0 ? null : this.state.idPrintShop,
        startDate: this.state.startDate,
        endDate: this.state.endDate != this.defaultState.endDate ? 
          moment.parseZone(this.state.endDate).subtract(1, 'seconds').add(1, 'days').toDate() : this.state.endDate,
        status: this.state.status,
        InputSearchBy: {
          value: inputSearchBy.value != 'common__all' ? this.defaultState.inputSearchBy.value : '',
          key: inputSearchBy.key
        }
      })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  setStartDate = () => {
    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 3)
    return new Date(startDate)
  }
  setEndDate = () => {
    let endDate = new Date()
    endDate.setMonth(endDate.getMonth() + 12)
    return new Date(endDate)
  }

  updateStateAny = (target, index, value) => {
    this.setState({ [target]: value })
  }

  brandSelectedHandler = (event, index, value) => {
    this.updateStateAny('idBrand', index, value)
  }
  labelistSelectedHandler = (event, index, value) => {
    this.updateStateAny('idLabelist', index, value)
  }
  printshopSelectedHandler = (event, index, value) => {
    this.updateStateAny('idPrintShop', index, value)
  }
  changeDateHandler = (e, date, target) => {
    this.updateStateAny(target, '', moment(date).toDate())
  }
  changeInputSearchByHandler = (event, index, value) => {
    this.setState({
      inputSearchBy: {
        'key': this.state.inputSearchBy.key,
        'value': value
      }
    })
  }
  changeInputSearchHandler = (event, index, value) => {
    this.setState({
      inputSearchBy: {
        'key': index,
        'value': this.state.inputSearchBy.value
      }
    })
  }

  onStatusChangeHandler = filter => () => {
    let oldState = cloneDeep(this.state.status)

    const elemIdx = !isEmpty(this.state.status) && this.state['status'].includes(filter) ? 
                    this.state['status'].findIndex(e => e === filter) : -1
    elemIdx !== -1
      ? oldState.splice(elemIdx, 1)
      : oldState.push(filter)

    this.setState({ status: oldState })
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  handleReset = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

  handleOpenExportExel = () => {
    const inputSearchBy = cloneDeep(this.state.inputSearchBy)
    inputSearchBy.value = inputSearchBy.value !== 'common__all' ? inputSearchBy.value : ''

    this.props.apiProductionFollowUpExport({
      ID_Brand: this.state.idBrand === 0 ? null : this.state.idBrand,
      ID_Labellist: this.state.idLabelist === 0 ? null : this.state.idLabelist,
      ID_PrintShop: this.state.idPrintShop === 0 ? null : this.state.idPrintShop,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      status: this.state.status,
      InputSearchBy: inputSearchBy
    })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, productionReturn, filtersSettings, xlsx } = this.props
    // sortable asc array of trad key
    let availableByParameters
    if (filtersSettings && filtersSettings.availableByParameters) {
      availableByParameters = filtersSettings.availableByParameters.map(elem => {
        return ({ 'key': elem, 'value': trl[elem] })
      })
    }
    if (isLoading || !productionReturn) return null
    this.setStartDate()
    return (
      <div>
        <PageHeader
          title={trl.profu_title}
          subtitle={trl.profu_subTitle}
        />
        <SC.PageGutter>
            <FilterAndTableWrapper>
              <TableWrapper text={trl.common__search}>
                <BodyTable>
                  <CheckboxWrapperRow>
                    {
                      filtersSettings && filtersSettings.availableStatus &&
                      map(filtersSettings.availableStatus, (elem, idx) => (
                        <Row key={idx}>
                          <Check
                            checked={
                              !isEmpty(this.state.status)
                                ? !!this.state['status'].find(e => e === elem.idStatus)
                                : false
                            }
                            onCheck={this.onStatusChangeHandler(elem.idStatus)} />
                          <Label
                            isTitle
                            bold
                            color={elem.internalColor}
                          >
                            {trl[elem.internalTraductionKey]}
                          </Label>
                        </Row>
                      ))
                    }
                  </CheckboxWrapperRow>
                  <LeftFiltersWrapper>
                    <div>
                      <Label>{trl.common__search} :</Label>
                      <TextField
                        id="product-catalog-search-value"
                        type="text"
                        style={{ width: 150, fontSize: theme.font.fontSize, height: 35 }}
                        value={this.state.inputSearchBy.key}
                        onChange={this.changeInputSearchHandler}
                        onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                      />
                      <Label style={{marginLeft : 10}}>{trl.common__by} :</Label>
                    </div>
                    <div>
                      <SelectField
                        style={{
                          fontSize: theme.font.fontSize,
                          maxWidth: '195px',
                          height: '35px'
                        }}
                        iconStyle={{
                          margin: '0',
                          padding: '0'
                        }}
                        floatingLabelStyle={{
                          display: 'none'
                        }}
                        labelStyle={{
                          borderRadius: 0,
                          padding: '3px 30px 0 5px',
                          border: '1px solid #ced4da',
                          width: '100%',
                          height: '30px',
                          position: 'relative',
                          top: '4px',
                          lineHeight: 'inherit'
                        }}
                        underlineStyle={{
                          display: 'none'
                        }}
                        value={this.state.inputSearchBy.value}
                        onChange={this.changeInputSearchByHandler}
                      >
                        {filtersSettings && filtersSettings.availableByParameters &&
                          orderBy(availableByParameters, ['value'], ['asc']).map(param =>
                            <MenuItem
                              key={param.key} value={param.key}
                              primaryText={param.value}
                              style={{ fontSize: theme.font.fontSize }}
                            />)}
                      </SelectField>
                    </div>
                    <Label>{trl.common__filter_by_brand}</Label>
                    <SelectField
                      style={{
                        fontSize: theme.font.fontSize,
                        maxWidth: '195px',
                        height: '35px'
                      }}
                      iconStyle={{
                        margin: '0',
                        padding: '0'
                      }}
                      floatingLabelStyle={{
                        display: 'none'
                      }}
                      labelStyle={{
                        borderRadius: 0,
                        padding: '3px 30px 0 5px',
                        border: '1px solid #ced4da',
                        width: '100%',
                        height: '30px',
                        position: 'relative',
                        top: '4px',
                        lineHeight: 'inherit'
                      }}
                      underlineStyle={{
                        display: 'none'
                      }}
                      value={this.state.idBrand}
                      onChange={this.brandSelectedHandler}
                    >
                      {filtersSettings && filtersSettings.availableBrands &&
                        filtersSettings.availableBrands.map(brand =>
                          <MenuItem
                            key={brand.key}
                            value={brand.key}
                            primaryText={trl[brand.value] || brand.value}
                            style={{ fontSize: theme.font.fontSize }}
                          />
                        )
                      }
                    </SelectField>
                    <Label>{trl.common__filter_by_labellist}</Label>
                    <SelectField
                      style={{
                        fontSize: theme.font.fontSize,
                        maxWidth: '195px',
                        height: '35px'
                      }}
                      iconStyle={{
                        margin: '0',
                        padding: '0'
                      }}
                      floatingLabelStyle={{
                        display: 'none'
                      }}
                      labelStyle={{
                        borderRadius: 0,
                        padding: '3px 30px 0 5px',
                        border: '1px solid #ced4da',
                        width: '100%',
                        height: '30px',
                        position: 'relative',
                        top: '4px',
                        lineHeight: 'inherit'
                      }}
                      underlineStyle={{
                        display: 'none'
                      }}
                      value={this.state.idLabelist}
                      onChange={this.labelistSelectedHandler}
                    >
                      {filtersSettings && filtersSettings.availableLabellists &&
                        filtersSettings.availableLabellists.map(labelist =>
                          <MenuItem
                            key={labelist.key} value={labelist.key}
                            primaryText={trl[labelist.value] || labelist.value}
                            style={{ fontSize: theme.font.fontSize }}
                          />)}
                    </SelectField>
                    <Label>{trl.common__filter_by_printshop}</Label>
                    <SelectField
                      style={{
                        fontSize: theme.font.fontSize,
                        maxWidth: '195px',
                      }}
                      iconStyle={{
                        margin: '0',
                        padding: '0'
                      }}
                      floatingLabelStyle={{
                        display: 'none'
                      }}
                      labelStyle={{
                        borderRadius: 0,
                        padding: '3px 30px 0 5px',
                        border: '1px solid #ced4da',
                        width: '100%',
                        height: '30px',
                        position: 'relative',
                        top: '4px',
                        lineHeight: 'inherit'
                      }}
                      underlineStyle={{
                        display: 'none'
                      }}
                      value={this.state.idPrintShop}
                      onChange={this.printshopSelectedHandler}
                    >
                      {filtersSettings && filtersSettings.availablePrintShops &&
                        filtersSettings.availablePrintShops.map(printShop =>
                          <MenuItem
                            key={printShop.key} value={printShop.key}
                            primaryText={trl[printShop.value] || printShop.value}
                            style={{ fontSize: theme.font.fontSize }}
                          />)}
                    </SelectField>
                  </LeftFiltersWrapper>
                  <RightFiltersWrapper>
                    <Label>{trl.common__filter_by_CTCConfirmed_date} :</Label>
                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0'
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '30%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.common__from}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'startDate')}
                      value={this.state.startDate}
                      maxDate={this.state.endDate}
                      formatDate={dateFormatConverter}
                    />
                    <DatePicker
                      autoOk
                      textFieldStyle={{
                        maxWidth: 100,
                        fontSize: theme.font.fontSize,
                        height: '30px',
                        margin: '5px 0'
                      }}
                      underlineStyle={{ display: 'none' }}
                      hintStyle={{
                        position: 'absolute',
                        left: '50%',
                        top: '30%',
                        transform: 'translate(-50%, -50%)'
                      }}
                      inputStyle={{
                        bottom: 0,
                        height: '30px',
                        marginBottom: '5px',
                        border: '1px solid #ced4da',
                        borderRadius: 0,
                        fontSize: theme.font.fontSize,
                        padding: '0 5px',
                        textAlign: 'center'
                      }}
                      hintText={trl.common__to}
                      container="inline"
                      onChange={(e, date) => this.changeDateHandler(e, date, 'endDate')}
                      value={this.state.endDate}
                      minDate={this.state.startDate}
                      formatDate={dateFormatConverter}
                    />
                  </RightFiltersWrapper>
                  <TableRow>
                    <ButtonWrapper>
                      <SC.ButtonPA_M style={{ marginRight: 16 }}
                        onClick={this.queryData}>
                        {trl.common__filter_apply_btn}
                      </SC.ButtonPA_M>
                      <SC.ButtonR_M
                        onClick={this.handleReset}>{trl.common__filter_reset_btn}
                      </SC.ButtonR_M>
                    </ButtonWrapper>
                  </TableRow>
                  <TableRow align={'flex-end'}>
                    <SC.ButtonS_S
                      onClick={this.handleOpenExportExel}>{trl.common__btn_ExportToExcel}
                    </SC.ButtonS_S>
                    {xlsx && (
                      <a
                        target="_blank"
                        style={{ display: 'none' }}
                        href={`/excel_export/${xlsx}`}
                        ref={exportXlsx => (this.exportXlsx = exportXlsx)}
                      />
                    )}
                  </TableRow>
                </BodyTable>
              </TableWrapper>
              <div>
                <ProductionFollowUpTable data={productionReturn} />
              </div>
            </FilterAndTableWrapper>
        </SC.PageGutter>
      </div>
    )
  }
}

ProductionFollowUp
  .propTypes = {}

ProductionFollowUp
  .defaultProps = {}

const
  mapStateToProps = state => ({
    isLoading: state.appAlerts.loading && state.appAlerts.loading.isLoading,
    ...state.productionFollowUp
  })

export default connect(mapStateToProps, {
  apiGetProductionFollowUp,
  apiProductionFollowUpExport
})(ProductionFollowUp)
