////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import sematable from 'sematable'
import styled from 'styled-components'
///////COMPONENTS///////
import AddItemActions from '../Actions/AddItemActions'
import StaticVariable from '../ColumnHelpers/StaticVariable'
import ShowImageAction from '../ColumnHelpers/ShowImage'
import { theme } from '../../styledComponents'
import ViewStockActions from '../Actions/ViewStockActions'
import { translate } from '../../../containers/HOC/Translation'

/////////STYLED/////////
const ShowImageActionWrapper = styled.div`
  width: 25%;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`
const TableWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  background-color: ${theme.colors.lightGrey};
  margin-bottom: 1em;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  & > *:last-child {
    align-self: flex-end;
  }
  & > *:nth-child(2) {
    align-self: center;
  }
`
const ItemType = styled.p`
  & > div {
    display: inline;
  }
`
/////////STYLED/////////

const columns = [
  { key: 'img', header: translate('article_table_tableTitle__details'), Component: ShowImageAction },
  { key: 'orderId', header: 'Order id', hidden: true },
  { key: 'iD_Article', header: translate('article_table_tableTitle__ctcItemRef'), primaryKey: true },
  { key: 'iD_Article_Externe_Enseigne', header: translate('articles_table_tableTitle__itemRef') },
  { key: 'article_Name', header: translate('article_table_tableTitle__designation') },
  { key: 'article_Fixe', header: translate('article_table_tableTitle__itemType'), Component: StaticVariable },
  { key: 'article_Qte_Unite_Gestion', header: translate('article_table_tableTitle__unitMngt') },
  { key: 'article_Order_Min', header: translate('article_table_tableTitle__minOrder') },
  { key: 'article_Conditionnement', header: translate('article_table_tableTitle__packaging') },
  { key: 'price', header: translate('article_table_tableTitle__price') },
  { key: 'price_Currency', header: translate('article_table_tableTitle__currency') },
  { key: 'article_Qte_Unite_Vente', header: translate('article_table_tableTitle__unitSale') },
  { key: 'actions', header: translate('article_table_tableTitle__actions'), Component: AddItemActions },
  { key: 'viewStock', header: 'order_AddItem_Btn_viewStocks', Component: ViewStockActions }
]

const config = {
  showPageSize: false,
  showFilter: false,
  defaultPageSize: 100000
}

const ArticlesTable = ({ data, trl }) => {
  return (
    <div>
      {data.map((row, key) => (
        <TableWrapper style={{ display: 'flex', justifyContent: 'space-between' }} key={row.iD_Article}>
          {/* LEFT COLUMN */}
          <ShowImageActionWrapper>
            <ShowImageAction row={row} big={true} size="200px" />
          </ShowImageActionWrapper>

          {/* MIDDLE COLUMN */}
          <div
            style={{
              width: '30%',
              maxWidth: '100%',
              marginLeft: '3em',
              fontSize: theme.font.fontSize
            }}
            className='AddedItemDetail'
          >
            <p style={{ fontWeight: 'bold', fontSize: theme.font.fontSize, marginBottom: '4px' }}>
              {trl.order_AddItem_CTCItem} {row.iD_Article_Externe_SAP}
            </p>
            <br />
            <p style={{ marginBottom: '4px' }}>
              {trl.order_AddItem_BrandItem} {row.iD_Article_Externe_Enseigne}
            </p>
            <p style={{ marginBottom: '4px' }}>
              {trl.order_AddItem_Designation} {row.article_Name}
            </p>
            <ItemType>
              {trl.order_AddItem_ItemType} <StaticVariable row={row} />
            </ItemType>
            <br />
            <p style={{ marginBottom: '4px' }}>
              {trl.order_AddItem_MinimumOrder} <strong>{row.article_Order_Min}</strong>
            </p>
            <p style={{ marginBottom: '4px' }}>
              {trl.order_AddItem_Packing} <strong>{row.article_Conditionnement}</strong>
            </p>
          </div>

          {/* RIGHT COLUMN */}
          <div style={{ width: '20%' }}>
            <AddItemActions row={row} />
            <ViewStockActions row={row} />
          </div>
        </TableWrapper>
      ))}
    </div>
  )
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  trl: PropTypes.object.isRequired,
  primaryKey: PropTypes.string.isRequired
}

ArticlesTable.propTypes = propTypes
export default sematable('articlesTable', ArticlesTable, columns, config)
