////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import styled from 'styled-components'
import { isEmpty, isUndefined } from "lodash"
///////COMPONENTS///////
import Paper from 'material-ui/Paper'
import CircularProgress from 'material-ui/CircularProgress'
import FlatButton from 'material-ui/FlatButton'
import { renderInput, renderCheckbox } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import * as c from '../../../constants'
import { requestAccount } from '../../../actions/me'
import * as SC from '../../../components/styledComponents'
import { translate } from '../../HOC/Translation'
/////////ASSETS/////////
import Face from 'material-ui/svg-icons/action/face'
import Help from 'material-ui/svg-icons/action/help'

/////////STYLED/////////
const TextInformation = styled.p`
  color: grey;
  font-size: 13px;
`
const ErrorMessage = styled.p`
  color: rgb(244, 67, 54);
  font-size: 12px;
  line-height: 12px;
  margin-top: 7px;
  width: 100%;
`
const ColumnContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  > * {
    flex: 0 1 40%;
  }
`
/////////STYLED/////////

const validate = (values, props) => {
  const errors = {}
  const { trl, deliveryAddressSame } = props
  const requiredFieldText = 'This field is required'

  if (!values.brands) errors.brands = trl.common__field_required || requiredFieldText
  // Invoice Address
  if (!values.ia_c_name) errors.ia_c_name = trl.common__field_required || requiredFieldText
  if (!values.ia_c_a) errors.ia_c_a = trl.common__field_required || requiredFieldText
  if (!values.ia_c_c) errors.ia_c_c = trl.common__field_required || requiredFieldText
  if (!values.ia_c_cp) errors.ia_c_cp = trl.common__field_required || requiredFieldText
  if (!values.ia_c_co) errors.ia_c_co = trl.common__field_required || requiredFieldText
  if (!values.ia_c_pp) errors.ia_c_pp = trl.common__field_required || requiredFieldText
  if (!values.ia_c_p) errors.ia_c_p = trl.common__field_required || requiredFieldText
  // Delivery Address
  if (!isUndefined(deliveryAddressSame) && !deliveryAddressSame) {
    // if chosen a delivery adddress that is different from invoice
    if (!values.da_c_name) errors.da_c_name = trl.common__field_required || requiredFieldText
    if (!values.da_c_a) errors.da_c_a = trl.common__field_required || requiredFieldText
    if (!values.da_c_c) errors.da_c_c = trl.common__field_required || requiredFieldText
    if (!values.da_c_cp) errors.da_c_cp = trl.common__field_required || requiredFieldText
    if (!values.da_c_co) errors.da_c_co = trl.common__field_required || requiredFieldText
    if (!values.da_c_pp) errors.da_c_pp = trl.common__field_required || requiredFieldText
    if (!values.da_c_p) errors.da_c_p = trl.common__field_required || requiredFieldText
    if (!values.da_co_name) errors.da_co_name = trl.common__field_required || requiredFieldText
  }
  // Commercial Contact
  if (!values.cc_name) errors.cc_name = trl.common__field_required || requiredFieldText
  if (!values.cc_pp) errors.cc_pp = trl.common__field_required || requiredFieldText
  if (!values.cc_p) errors.cc_p = trl.common__field_required || requiredFieldText
  if (!values.cc_email) errors.cc_email = trl.common__field_required || requiredFieldText
  if (values.cc_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.cc_email)) errors.cc_email = 'invalid email address'
  // Contact Accounting Department
  if (!values.cad_name) errors.cad_name = trl.common__field_required || requiredFieldText
  if (!values.cad_pp) errors.cad_pp = trl.common__field_required || requiredFieldText
  if (!values.cad_p) errors.cad_p = trl.common__field_required || requiredFieldText
  if (!values.cad_email) errors.cad_email = trl.common__field_required || requiredFieldText
  if (values.cad_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.cad_email)) errors.cad_email = 'invalid email address'
  return errors
}

class RequestAccount extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    if(isEmpty(this.props.trl)) return true
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(formValues) {
    const userModel = {
      brands: formValues.brands,
      //invoice address
      ia_c_name: formValues.ia_c_name,
      ia_c_a: formValues.ia_c_a,
      ia_c_d: formValues.ia_c_d || '',
      ia_c_c: formValues.ia_c_c,
      ia_c_cp: formValues.ia_c_cp,
      ia_c_co: formValues.ia_c_co,
      ia_c_pp: `+${formValues.ia_c_pp || ''}`,
      ia_c_p: formValues.ia_c_p,
      ia_c_vat: formValues.ia_c_vat || '',
      //delivery address
      da_c_name: formValues.deliveryAddressSame ? formValues.ia_c_name : formValues.da_c_name,
      da_c_a: formValues.deliveryAddressSame ? formValues.ia_c_a : formValues.da_c_a,
      da_c_d: formValues.deliveryAddressSame ? formValues.ia_c_d || '' : formValues.da_c_d || '',
      da_c_c: formValues.deliveryAddressSame ? formValues.ia_c_c : formValues.da_c_c,
      da_c_cp: formValues.deliveryAddressSame ? formValues.ia_c_cp : formValues.da_c_cp,
      da_c_co: formValues.deliveryAddressSame ? formValues.ia_c_co : formValues.da_c_co,
      da_c_pp: `+${formValues.deliveryAddressSame ? formValues.ia_c_pp || '' : formValues.da_c_pp || ''}`,
      da_c_p: formValues.deliveryAddressSame ? formValues.ia_c_p : formValues.da_c_p,
      da_co_name: formValues.da_co_name,
      da_c_vat: formValues.deliveryAddressSame ? formValues.ia_c_vat || '' : formValues.da_c_vat || '',
      //commercial contact
      cc_name: formValues.cc_name,
      cc_j: formValues.cc_j || '',
      cc_pp: `+${formValues.cc_pp || ''}`,
      cc_p: formValues.cc_p,
      cc_mp: `+${formValues.cc_mp || ''}`,
      cc_m: formValues.cc_m || '',
      cc_email: formValues.cc_email,
      //other contact
      oc_name: formValues.oc_name || '',
      oc_j: formValues.oc_j || '',
      oc_pp: `+${formValues.oc_pp || ''}`,
      oc_p: formValues.oc_p || '',
      oc_mp: `+${formValues.oc_mp || ''}`,
      oc_m: formValues.oc_m || '',
      oc_email: formValues.oc_email || '',
      //contact accounting department
      cad_name: formValues.cad_name,
      cad_j: formValues.cad_j || '',
      cad_pp: `+${formValues.cad_pp || ''}`,
      cad_p: formValues.cad_p,
      cad_email: formValues.cad_email,
      //shipping
      s_name: formValues.s_name || '',
      s_svc: formValues.s_svc || '',
      s_a: formValues.s_a || '',
      s_c: formValues.s_c || '',
      i_s: formValues.i_s || ''
    }
    return this.props.requestAccount(userModel)
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { handleSubmit, submitting, trl, deliveryAddressSameValue, error, invalid, submitFailed } = this.props
    return (
      <div style={{ ...styles.loginContainer, maxWidth: 1000 }}>
        <h1 style={styles.question}>{!isEmpty(trl) ? trl.register__title : !isUndefined(translate('register__title')) ? translate('register__title') : 'NEW CUSTOMER REGISTRATION FORM'}</h1>
        <h2 style={styles.description}>{!isEmpty(trl) ? trl.register__subtitle : !isUndefined(translate('register__subtitle')) ? translate('register__subtitle') : 'Please fill in the below registration form. All fields beginning by * must be completed. Our Sales team will then contact you'}</h2>
        <Paper style={styles.paper}>
          <p>{!isEmpty(trl) ? trl.register__instructions : !isUndefined(translate('register__instructions')) ? translate('register__instructions') : 'All lines starting with * are required'}</p>
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Field
              name="brands"
              type="text"
              component={renderInput}
              label={!isEmpty(trl) ? '* '+trl.register__post_brands : !isUndefined(translate('register__post_brands')) ? translate('register__post_brands') : '* Enter the brand(s) you want to access'}
              style={{fontSize: 12}}
            />
            {/* ROW 1: Invoicing Address | Delivery Address + Delivery Address Invoicing*/}
            <ColumnContainer>
              <div>
                {/* ROW 1 LEFT COLUMN /*}
                 {/* Invoice Address */}
                <br />
                <br />
                <br />
                <h5>{!isEmpty(trl) ? trl.register__invoice_address_title :!isUndefined(translate('register__invoice_address_title')) ?  translate('register__invoice_address_title') : 'INVOICE ADDRESS'}</h5>
                <Field
                  name="ia_c_name"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_ia_c_name :!isUndefined(translate('register__post_ia_c_name')) ?  '* '+translate('register__post_ia_c_name') : 'Company name'}
                  style={{fontSize: 12}}
                />
                <Field
                  name="ia_c_a"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_ia_c_a :!isUndefined(translate('register__post_ia_c_a')) ?  '* '+translate('register__post_ia_c_a') : 'Company adress'}
                  style={{fontSize: 12}}
                  />
                <Field
                  name="ia_c_d"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_ia_c_d :!isUndefined(translate('register__post_ia_c_d')) ?  translate('register__post_ia_c_d') : 'Other details (door code, stair level...)'}
                  style={{fontSize: 12}}
                  />
                <Field
                  name="ia_c_c"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_ia_c_c :!isUndefined(translate('register__post_ia_c_c')) ?  '* '+translate('register__post_ia_c_c') : '* City'}
                  style={{fontSize: 12}}
                  />
                <Field
                  name="ia_c_cp"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_ia_c_cp :!isUndefined(translate('register__post_ia_c_cp')) ?  '* '+translate('register__post_ia_c_cp') : '* Post code'}
                  style={{fontSize: 12}}
                  />
                <Field
                  name="ia_c_co"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_ia_c_co :!isUndefined(translate('register__post_ia_c_co')) ?  '* '+translate('register__post_ia_c_co') : '* Country'}
                  style={{fontSize: 12}}
                  />
                {/* phone to do */}
                <div style={{ display: 'flex', alignItems: 'top' }}>
                  <Field
                    name="ia_c_pp"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    style={{ width: 120, marginRight: 20, fontSize: 12 }}
                    label={!isEmpty(trl) ? '* '+trl.register__post_ia_c_pp :!isUndefined(translate('register__post_ia_c_pp')) ?  '* '+translate('register__post_ia_c_pp') : '* Prefix'}
                  />
                  <Field
                    name="ia_c_p"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    label={!isEmpty(trl) ? '* '+trl.register__post_ia_c_p :!isUndefined(translate('register__post_ia_c_p')) ?  '* '+translate('register__post_ia_c_p') : '* Phone'}
                    style={{ flexGrow: 2, fontSize: 12 }}
                  />
                </div>
                <Field
                  name="ia_c_vat"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_ia_c_vat :!isUndefined(translate('register__post_ia_c_vat')) ?  translate('register__post_ia_c_vat') : 'VAT number (Required only for EU)'}
                  style={{ fontSize: 12 }}
                />
              </div>
              {/* ROW 1 RIGHT COLUMN */}
              <div>
                {/* Delivery Address */}
                <br />
                <br />
                <br />
                <h5>{!isEmpty(trl) ? trl.register__delivery_address_title :!isUndefined(translate('register__delivery_address_title')) ?  translate('register__delivery_address_title') : 'DELIVERY ADRESS'}</h5>
                <Field
                  name="deliveryAddressSame"
                  component={renderCheckbox}
                  label={!isEmpty(trl) ? trl.register__delivery_address_same_btn :!isUndefined(translate('register__delivery_address_same_btn')) ?  translate('register__delivery_address_same_btn') : 'Deliver to invoice adress'}
                  style={{ fontSize: 12 }}
                />
                {!deliveryAddressSameValue && (
                  <div>
                    <Field
                      name="da_c_name"
                      type="text"
                      component={renderInput}
                      label={!isEmpty(trl) ? '* '+trl.register__post_da_c_name :!isUndefined(translate('register__post_da_c_name')) ?  '* '+translate('register__post_da_c_name') : '* Delivery company name'}
                      style={{ fontSize: 12 }}
                    />
                    <Field
                      name="da_c_a"
                      type="text"
                      component={renderInput}
                      label={!isEmpty(trl) ? '* '+trl.register__post_da_c_a :!isUndefined(translate('register__post_da_c_a')) ?  '* '+translate('register__post_da_c_a') : '* Delivery adress'}
                      style={{ fontSize: 12 }}
                    />
                    <Field
                      name="da_c_d"
                      type="text"
                      component={renderInput}
                      label={!isEmpty(trl) ? trl.register__post_da_c_d :!isUndefined(translate('register__post_da_c_d')) ?  translate('trl.register__post_da_c_d') : 'Other (door code, floor...)'}
                      style={{ fontSize: 12 }}
                    />
                    <Field
                      name="da_c_c"
                      type="text"
                      component={renderInput}
                      label={!isEmpty(trl) ? '* '+trl.register__post_da_c_c :!isUndefined(translate('register__post_da_c_c')) ?  '* '+translate('register__post_da_c_c') : '* City'}
                      style={{ fontSize: 12 }}
                    />
                    <Field
                      name="da_c_cp"
                      type="text"
                      component={renderInput}
                      label={!isEmpty(trl) ? '* '+trl.register__post_da_c_cp :!isUndefined(translate('register__post_da_c_cp')) ?  '* '+translate('register__post_da_c_cp') : '* Zip code'}
                      style={{ fontSize: 12 }}
                    />
                    <Field
                      name="da_c_co"
                      type="text"
                      component={renderInput}
                      label={!isEmpty(trl) ? '* '+trl.register__post_da_c_co :!isUndefined(translate('register__post_da_c_co')) ?  '* '+translate('register__post_da_c_co') : '* Country'}
                      style={{ fontSize: 12 }}
                    />
                    <div style={{ display: 'flex', alignItems: 'top' }}>
                      <Field
                        name="da_c_pp"
                        type="number"
                        component={renderInput}
                        fullWidth={false}
                        style={{ width: 120, marginRight: 20, fontSize: 12 }}
                        label={!isEmpty(trl) ? '* '+trl.register__post_da_c_pp :!isUndefined(translate('register__post_da_c_pp')) ?  '* '+translate('register__post_da_c_pp') : '* Prefix'}
                      />
                      <Field
                        name="da_c_p"
                        type="number"
                        component={renderInput}
                        fullWidth={false}
                        label={!isEmpty(trl) ? '* '+trl.register__post_da_c_p :!isUndefined(translate('register__post_da_c_p')) ?  '* '+translate('register__post_da_c_p') : '* Phone'}
                        style={{ flexGrow: 2, fontSize: 12 }}
                      />
                    </div>
                    <Field
                      name="da_c_vat"
                      type="text"
                      component={renderInput}
                      label={!isEmpty(trl) ? '* '+trl.register__post_da_c_vat :!isUndefined(translate('register__post_da_c_vat')) ?  '* '+translate('register__post_da_c_vat') : '* VAT number (only for EU)'}
                      style={{ fontSize: 12 }}
                    />
                  </div>
                )}
                <Field
                  name="da_co_name"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_da_co_name :!isUndefined(translate('register__post_da_co_name')) ?  '* '+translate('register__post_da_co_name') : '* Delivery adress contact name'}
                  style={{ fontSize: 12 }}
                />
              </div>
            </ColumnContainer>
            {/* ROW 2: Commercial Contact | Other Contact */}
            <ColumnContainer>
              {/* ROW 2 LEFT COLUMN */}
              <div>
                {/* Commercial Contact */}
                <br />
                <br />
                <br />
                <h5>{!isEmpty(trl) ? trl.register__commercial_contact_title :!isUndefined(translate('register__commercial_contact_title')) ?  translate('register__commercial_contact_title') : 'COMMERCIAL CONTACT'}</h5>
                <Field
                  name="cc_name"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_cc_name :!isUndefined(translate('register__post_cc_name')) ?  '* '+translate('register__post_cc_name') :  '* Contact name'}
                  style={{ fontSize: 12 }}
                />
                <Field 
                  name="cc_j" 
                  type="text" 
                  component={renderInput} 
                  style={{ fontSize: 12 }}
                  label={!isEmpty(trl) ? trl.register__post_cc_j :!isUndefined(translate('register__post_cc_j')) ?  translate('register__post_cc_j') :  'Job'} 
                />
                <div style={{ display: 'flex', alignItems: 'top' }}>
                  <Field
                    name="cc_pp"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    style={{ width: 120, marginRight: 20, fontSize: 12 }}
                    label={!isEmpty(trl) ? '* '+trl.register__post_cc_pp :!isUndefined(translate('register__post_cc_pp')) ?  '* '+translate('register__post_cc_pp') :  '* Prefix'}
                  />
                  <Field
                    name="cc_p"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    label={!isEmpty(trl) ? '* '+trl.register__post_cc_p :!isUndefined(translate('register__post_cc_p')) ?  '* '+translate('register__post_cc_p') :  '* Phone'}
                    style={{ flexGrow: 2, fontSize: 12 }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'top' }}>
                  <Field
                    name="cc_mp"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    style={{ width: 120, marginRight: 20, fontSize: 12 }}
                    label={!isEmpty(trl) ? `${trl.register__post_cc_mp}` :!isUndefined(translate('register__post_cc_mp')) ?  `${translate('register__post_cc_mp')}` :  'Prefix'}
                  />
                  <Field
                    name="cc_m"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    label={!isEmpty(trl) ? `${trl.register__post_cc_m}` :!isUndefined(translate('register__post_cc_m')) ?  `${translate('register__post_cc_m')}` :  'Mobile'}
                    style={{ flexGrow: 2, fontSize: 12 }}
                  />
                </div>
                <Field
                  name="cc_email"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_cc_email :!isUndefined(translate('register__post_cc_email')) ?  '* '+translate('register__post_cc_email') :  '* Email'}
                  style={{ fontSize: 12 }}
                />
                {/* Contact Accounting Department*/}
                <br />
                <br />
                <br />
                <h5>{!isEmpty(trl) ? trl.register_accounting_title :!isUndefined(translate('register_accounting_title')) ?  translate('register_accounting_title') :  '* ACCOUNTING CONTACT'}</h5>
                <Field
                  name="cad_name"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_cad_name :!isUndefined(translate('register__post_cad_name')) ?  '* '+translate('register__post_cad_name') :  '* Contact name for invoice'}
                  style={{ fontSize: 12 }}
                />
                <Field
                  name="cad_j" 
                  type="text" 
                  component={renderInput}
                  style={{ fontSize: 12 }} 
                  label={!isEmpty(trl) ? trl.register__post_cad_j :!isUndefined(translate('register__post_cad_j')) ?  `${translate('register__post_cad_j')}` :  'Job'} 
                />
                <div style={{ display: 'flex', alignItems: 'top' }}>
                  <Field
                    name="cad_pp"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    style={{ width: 120, marginRight: 20, fontSize: 12 }}
                    label={!isEmpty(trl) ? '* '+trl.register__post_cad_pp :!isUndefined(translate('register__post_cad_pp')) ?  '* '+translate('register__post_cad_pp') :  '* Prefix'}
                  />
                  <Field
                    name="cad_p"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    label={!isEmpty(trl) ? '* '+trl.register__post_cad_p :!isUndefined(translate('register__post_cad_p')) ?  '* '+translate('register__post_cad_p') :  '* Phone'}
                    style={{ flexGrow: 2, fontSize: 12 }}
                  />
                </div>
                <Field
                  name="cad_email"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? '* '+trl.register__post_cad_email :!isUndefined(translate('register__post_cad_email')) ?  '* '+translate('register__post_cad_email') :  '* Email'}
                  style={{ fontSize: 12 }}
                />
              </div>
              {/* ROW 2 RIGHT COLUMN */}
              <div>
                {/* Other Contact */}
                <br />
                <br />
                <br />
                <h5>{!isEmpty(trl) ? trl.register__other_contact_title :!isUndefined(translate('register__other_contact_title')) ?  translate('register__other_contact_title') :  'OTHER CONTACT'}</h5>
                <Field
                  name="oc_name"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_oc_name :!isUndefined(translate('register__post_oc_name')) ?  translate('register__post_oc_name') :  'Other contact name'}
                  style={{ fontSize: 12 }}
                />
                <Field 
                  name="oc_j" 
                  type="text" 
                  component={renderInput}
                  style={{ fontSize: 12 }}
                  label={!isEmpty(trl) ? trl.register__post_oc_j :!isUndefined(translate('register__post_oc_j')) ?  translate('register__post_oc_j') :  'Job'} 
                />
                <div style={{ display: 'flex', alignItems: 'top' }}>
                  <Field
                    name="oc_pp"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    style={{ width: 120, marginRight: 20, fontSize: 12 }}
                    label={!isEmpty(trl) ? trl.register__post_oc_pp :!isUndefined(translate('register__post_oc_pp')) ?  translate('register__post_oc_pp') :  'Prefix'}
                  />
                  <Field
                    name="oc_p"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    label={!isEmpty(trl) ? trl.register__post_oc_p :!isUndefined(translate('register__post_oc_p')) ?  translate('register__post_oc_p') :  'Phone'}
                    style={{ flexGrow: 2, fontSize: 12 }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'top' }}>
                  <Field
                    name="oc_mp"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    style={{ width: 120, marginRight: 20, fontSize: 12 }}
                    label={!isEmpty(trl) ? trl.register__post_oc_mp :!isUndefined(translate('register__post_oc_mp')) ?  translate('register__post_oc_mp') :  'Prefix'}
                  />
                  <Field
                    name="oc_m"
                    type="number"
                    component={renderInput}
                    fullWidth={false}
                    label={!isEmpty(trl) ? `${trl.register__post_oc_m}` :!isUndefined(translate('register__post_oc_m')) ?  translate('register__post_oc_m') :  'Mobile'}
                    style={{ flexGrow: 2, fontSize: 12 }}
                  />
                </div>
                <Field
                  name="oc_email"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_oc_email :!isUndefined(translate('register__post_oc_email')) ?  translate('register__post_oc_email') :  'Email'}
                  style={{ fontSize: 12 }}
                />
              </div>
            </ColumnContainer>

            {/* ROW 3: Shipping | special needs */}
            <ColumnContainer>
              {/* ROW 3 LEFT COLUMN */}
              <div>
                {/* Shipping */}
                <br />
                <br />
                <br />
                <h5>{!isEmpty(trl) ? trl.register__shipping_title :!isUndefined(translate('register__shipping_title')) ?  translate('register__shipping_title') :  'SHIPPING'}</h5>
                <Field
                  name="s_name"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_s_name :!isUndefined(translate('register__post_s_name')) ?  translate('register__post_s_name') :  'Carrer name'}
                  style={{ fontSize: 12 }}
                />
                <Field 
                  name="s_svc" 
                  type="text" 
                  component={renderInput}
                  style={{ fontSize: 12 }}
                  label={!isEmpty(trl) ? trl.register__post_s_svc :!isUndefined(translate('register__post_s_svc')) ?  translate('register__post_s_svc') :  'Service'} 
                />
                <Field
                  name="s_a"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_s_a :!isUndefined(translate('register__post_s_a')) ?  translate('register__post_s_a') :  'Account number'}
                  style={{ fontSize: 12 }}
                />
                <Field
                  name="s_c"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_s_c :!isUndefined(translate('register__post_s_c')) ?  translate('register__post_s_c') :  'Delivery adress in local language'}
                  style={{ fontSize: 12 }}
                />
                <TextInformation>
                  {!isEmpty(trl) ? trl.register__special_carrier_text :!isUndefined(translate('register__special_carrier_text')) ?  translate('register__special_carrier_text') : 'If you use a local carrier company, please write down below your delivery address in local langage. Thank you'}
                </TextInformation>
              </div>
              {/* ROW 3 LEFT COLUMN */}
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <Field
                  name="i_s"
                  type="text"
                  component={renderInput}
                  label={!isEmpty(trl) ? trl.register__post_i_s :!isUndefined(translate('register__post_i_s')) ?  translate('register__post_i_s') : 'Special requests for invoice'}
                  style={{ fontSize: 12 }}
                />
                <TextInformation>
                  {!isEmpty(trl) ? trl.register__special_request_invoice_text :!isUndefined(translate('register__special_request_invoice_text')) ?  translate('register__special_request_invoice_text') : 'Please advise below if you have any special request on invoice, delivery or shipping. Thank you'}
                </TextInformation>
              </div>
            </ColumnContainer>

            {/* Submit*/}
            <div style={{ textAlign: 'center' }}>{error && <ErrorMessage>{error}</ErrorMessage>}</div>
            {invalid && submitFailed && (
                <p style={{ color: 'red', fontSize: 12, textAlign: 'center', transform: 'translateY(30px)' }}>
                  {!isEmpty(trl) ? trl.requestAccount_form__validFields :!isUndefined(translate('requestAccount_form__validFields')) ?  translate('requestAccount_form__validFields') : 'Please make sure all the fields are valid'}
                </p>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', clear: 'both', position: 'relative' }}>
              {/* <RaisedButton
                label={trl.register__btn : 'Register'}
                labelStyle={{ 
                  fontWeight: 'bold',
                  display: 'flex',
                  'flex-wrap': 'nowrap',
                  'justify-content': 'center'
                }}
                primary={true}
                disabled={submitting}
                style={{ ...styles.loginBtn, float: 'none', marginTop: 40 }}
                type="submit"
              /> */}

              <SC.ButtonPA_L
                disabled={submitting}
                style={{ ...styles.loginBtn, float: 'none', marginTop: 40 }}
                type="submit"
              >
                {!isEmpty(trl) ? trl.register__btn : `${translate('register__btn')}` || 'Register'}
              </SC.ButtonPA_L>

              <div style={{ position: 'absolute', bottom: '10px' }}>
                {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
              </div>
            </div>
          </form>
          <TextInformation dangerouslySetInnerHTML={{ __html: trl.register__cnil || c.stCnil }} />
        </Paper>
        <div style={styles.buttonsDiv}>
          <FlatButton
            label={trl.common__login || c.stLogin}
            href={`/#${c.login}`}
            style={styles.flatButton}
            labelStyle={{fontSize: 12}}
            icon={<Face />}
          />
          <FlatButton
            label={trl.common__forgot_password || c.stForgotPassword}
            href={`/#${c.forgotPassword}`}
            style={styles.flatButton}
            labelStyle={{fontSize: 12}}
            icon={<Help />}
          />
        </div>
      </div>
    )
  }
}

RequestAccount.contextTypes = {
  router: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
}

RequestAccount.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  requestAccount: PropTypes.func.isRequired
}

const mapStateToProps = () => ({ initialValues: { deliveryAddressSame: true } })

RequestAccount = reduxForm({
  form: 'RequestAccount',
  validate
})(RequestAccount)

const selector = formValueSelector('RequestAccount')
RequestAccount = connect(state => {
  const deliveryAddressSameValue = selector(state, 'deliveryAddressSame')
  return {
    deliveryAddressSameValue
  }
})(RequestAccount)

export default connect(mapStateToProps, { requestAccount })(RequestAccount)
