import * as c from '../constants'

const INITIAL_STATE = {
  company: {},
  companiesList: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.API_GET_COMPANIES:
      return { ...state }

    case c.API_GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesList: action.payload
      }

    case c.API_GET_COMPANIES_REQUEST:
      return {
        ...state
      }

    case c.API_GET_COMPANIES_ID:
      return {
        ...state,
        company: action.payload
      }

    case c.API_GET_COMPANIES_ID_REQUEST:
      return {
        ...state,
        company: {}
      }

    case c.API_GET_COMPANIES_ID_SUCCESS:
      return {
        ...state
      }
    //API_GET_COMPANIES_ID

    case c.API_UPDATE_COMPANY:
      return {
        ...state,
        company: action.payload
      }

    case c.API_UPDATE_COMPANY_REQUEST:
      return {
        ...state
      }
    case c.API_UPDATE_COMPANY_SUCCESS:
      return {
        ...state
      }
    case c.API_GET_COMPANY_FILE_ORDER_FORMATS_SUCCESS:
      return {
        ...state,
        [action.payload.companyId]: {
          ...state[action.payload.companyId],
          ...action.payload
        }
      }

    case c.API_ADD_STANDARD_FIXED_SUCCESS:
      return {
        ...state,
        [action.payload.companyId]: {
          ...state[action.payload.companyId],
          ...action.payload
        }
      }

    case c.API_SAVE_FILE_ORDER_FORMAT_SUCCESS:
      const { companyId, fileOrderFormat } = action.payload
      const newState = { ...state }
      const idx = newState[companyId].fileOrderFormats.findIndex(
        (f) => f.fileOrderFormatId === fileOrderFormat.fileOrderFormatId
      )
      newState[companyId].fileOrderFormats[idx] = {
        ...newState[companyId].fileOrderFormats[idx],
        ...fileOrderFormat
      }
      return newState

    case c.API_GET_COMPANY_BRAND_PARTNER:
      return { ...state, brandCompanies: action.payload }

    case c.UPDATE_CARRIER:
      return {
        ...state,
        [action.idSocieteKey]: {
          modes_Livraison: action.payload.modes_Livraison
        }
      }

    case c.API_CREATE_CARRIER_REQUEST:
      return {
        ...state,
        feedBack: {}
      }
    case c.API_CREATE_CARRIER_SUCCESS:
      return {
        ...state,
        feedBack: { success: true }
      }
    case c.API_CREATE_CARRIER_FAILURE:
      return {
        ...state,
        feedBack: { errors: action.errors }
      }

    /***********************************************
     * START GET company from orderID REDUCER
     ************************************************/

    case c.API_GET_COMPANY_BY_TYPE_SUCCESS:
      return {
        ...state,
        companyType: { articles: action.payload }
      }

    case c.RESET_ORDER_COMPANY_BY_TYPE:
      return {
        ...state,
        companyType: { articles: null }
      }

    /***********************************************
     * END GET company from orderID REDUCER
     ************************************************/
    case c.API_ADD_ITEM_TO_ORDER_SUCCESS:
      return {
        ...state,
        companyType: { articles: action.payload }
      }
    default:
      break
  }
  return state
}
