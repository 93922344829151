////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
///////COMPONENTS///////
import Paper from 'material-ui/Paper'
import CircularProgress from 'material-ui/CircularProgress'
import FlatButton from 'material-ui/FlatButton'
import Face from 'material-ui/svg-icons/action/face'
import Person from 'material-ui/svg-icons/social/person'
import PersonAdd from 'material-ui/svg-icons/social/person-add'
import CheckCircle from 'material-ui/svg-icons/action/check-circle'
import { renderInput } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import { headerPost } from '../../../utils/headers'
import * as c from '../../../constants'
import { checkStatus, encodeUrlBase64 } from '../../../utils/apiUtils'
import * as SC from '../../../components/styledComponents'
import { fetchLanguages } from '../../../actions/languages'
import { translate } from '../../HOC/Translation'
import { theme } from '../../../components/styledComponents';

const validate = (values, props) => {
  const { trl } = props
  const errors = {}
  if (!values.email) errors.email = trl.common__email_required_error || ' '
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,13}$/i.test(values.email)) errors.email = trl.common__email_invalid_error || ' '
  return errors
}

class ForgotPassword extends Component {

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(formValues) {
    let { email } = formValues
    email = encodeUrlBase64(email)
    const url = `${c.apiUser}${email}${c.apiUserResetRequest}`
    return fetch(url, headerPost)
      .then(checkStatus)
      .catch(err => {
        throw new SubmissionError({ _error: err.message })
      })
  }
  
  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { error, handleSubmit, submitting, submitSucceeded, trl } = this.props
    const styledsPerson = {...styles.topIcon, margin: '0 auto'}
    return (
      <div style={styles.loginContainer}>
        <h1 style={styles.question}>{trl.forgot_password__title || translate('forgot_password__title')}</h1>
        <h2 style={styles.description}>{trl.forgot_password__subtitle || translate('forgot_password__subtitle')}</h2>
        <Paper style={styles.paper}>
          <Person style={styledsPerson} />
          {!submitSucceeded && (
            <div style={{marginTop: 30}}>
              <p style={styles.instruction}>{trl.forgot_password__help || translate('forgot_password__help')}</p>
              <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <Field name="email" type="email" component={renderInput} label={trl.common__email || translate('common__email')} style={{ fontSize: theme.font.fontSize, textTransform: 'capitalize' }} />
                <div>
                  {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
                  <SC.ButtonPA_L
                    style={{
                      ...styles.loginBtn,
                      'margin': '1rem 0' 
                    }}
                    disabled={submitting}
                    type="submit"
                  >
                    {trl.forgot_password__reset_btn || translate('forgot_password__reset_btn') || ' '}
                  </SC.ButtonPA_L>
                </div>
                {error && <p style={styles.loginError}>{error}</p>}
              </form>
            </div>
          )}
          {submitSucceeded && (
            <div style={styles.successMessage.container}>
              <CheckCircle style={styles.successMessage.icon} />
              <p style={styles.successMessage.message}>{trl.forgot_password__email_success || translate('forgot_password__email_success')}</p>
            </div>
          )}
        </Paper>
        <div style={styles.buttonsDiv}>
          <FlatButton
            label={trl.common__login || translate('common__login') || c.stLogin}
            href={`/#${c.login}`}
            labelStyle={{fontSize: 12}}
            style={styles.flatButton}
            icon={<Face />}
          />
          <FlatButton
            label={trl.common__register || translate('common__register') || c.stRegister}
            href={`/#${c.requestAccount}`}
            labelStyle={{fontSize: 12}}
            style={styles.flatButton}
            icon={<PersonAdd />}
          />
        </div>
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  trl: PropTypes.object.isRequired
}

ForgotPassword = reduxForm({
  form: 'forgotPassword',
  validate
})(ForgotPassword)

export default connect(null, {fetchLanguages})(ForgotPassword)
