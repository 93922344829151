///////COMPONENTS///////
import * as c from '../constants'
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET PRODUCT CATALOG
function apiGetProductCatalogRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_PRODUCT_CATALOG_REQUEST, endpointPath })
  }
}
function apiGetProductCatalogSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_PRODUCT_CATALOG_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_PRODUCT_CATALOG,
      payload: payload
    })
  }
}
function apiGetProductCatalogFailure(endpointPath, _err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_PRODUCT_CATALOG_FAILURE, endpointPath })
  }
}
export function apiGetProductCatalog(data) {
  return u.getValueFromStore(
    c.apiGetProductCatalog,
    h.headerPutWithToken(data),
    apiGetProductCatalogRequest,
    apiGetProductCatalogSuccess,
    apiGetProductCatalogFailure
  )
}

// EXPORT PRODUCT CATALOG
function apiProductCatalogExportRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_PRODUCTCATALOG_REQUEST, endpointPath })
    dispatch(addAppShowLoading(true))
  }
}
function apiProductCatalogExportSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_PRODUCTCATALOG_SUCCESS, payload: res })
    dispatch(addAppHideLoading(true))
  }
}
function apiProductCatalogExportFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_PRODUCTCATALOG_FAILURE, endpointPath })
    dispatch(addAppHideLoading(true))
  }
}
export function apiProductCatalogExport(params) {
  return u.getValueFromStore(
    `${c.apiExportProductCatalog}`,
    h.headerPutWithToken(params),
    apiProductCatalogExportRequest,
    apiProductCatalogExportSuccess,
    apiProductCatalogExportFailure,
    true
  )
}
