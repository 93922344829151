import * as c from '../constants'

const INITIAL_STATE = {
  roles: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.FETCH_ROLES:
      return { ...state, roles: action.payload }

    default:
  }
  return state
}
