import { hashHistory } from 'react-router'
import { SubmissionError } from 'redux-form'
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppAlert } from './appAlerts'

//FETCH USERS
function fetchUsersRequest(endpointPath) {
  return { type: c.FETCH_USERS_REQUEST, endpointPath }
}
function fetchUsersSuccess(endpointPath, users) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_USERS_SUCCESS, endpointPath })
    dispatch({ type: c.FETCH_USERS, payload: users })
  }
}
function fetchUsersFailure(endpointPath, err) {
  return { type: c.FETCH_USERS_FAILURE, endpointPath }
}
export function fetchUsers() {
  return u.getValueFromStore(
    c.apiUsers,
    h.headerGetWithToken(),
    fetchUsersRequest,
    fetchUsersSuccess,
    fetchUsersFailure
  )
}

//FETCH USER
function fetchUserRequest(endpointPath) {
  return { type: c.FETCH_USER_REQUEST, endpointPath }
}
function fetchUserSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.FETCH_USER_SUCCESS, endpointPath })
    dispatch({ type: c.FETCH_USER, payload: res })
  }
}
function fetchUserFailure(endpointPath, err) {
  return { type: c.FETCH_USER_FAILURE, endpointPath }
}
export function fetchUser(userId) {
  return u.getValueFromStore(
    `${c.apiUser}${userId}`,
    h.headerGetWithToken(),
    fetchUserRequest,
    fetchUserSuccess,
    fetchUserFailure,
    null,
    { forceUpdate: true }
  )
}

//CREATE USER
function createUserRequest(endpointPath) {
  return { type: c.CREATE_USER_REQUEST, endpointPath }
}
function createUserSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.CREATE_USER_SUCCESS, endpointPath })
    // fetch users if no users in state.
    dispatch(fetchUsers())
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(`${res.fullName} ${trl.common__notification_created}`))
    dispatch({ type: c.ADD_USER_TO_USERS, payload: res })
    hashHistory.push(c.users)
  }
}
function createUserFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.CREATE_USER_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function createUser(m) {
  return u.getValueFromStore(
    c.apiUser,
    h.headerPostWithTokenAndModel(m),
    createUserRequest,
    createUserSuccess,
    createUserFailure
  )
}

//EDIT USER
function editUserRequest(endpointPath) {
  return { type: c.EDIT_USER_REQUEST, endpointPath }
}
function editUserSuccess(endpointPath, res) {
  return (dispatch, getState) => {
    dispatch({ type: c.EDIT_USER_SUCCESS, endpointPath })
    const trl = getState().languages.siteLanguage.keys
    dispatch(addAppAlert(`${res.fullName} ${trl.common__notification_updated}`))
    dispatch({ type: c.EDIT_USER, payload: res })
    hashHistory.push(c.users)
  }
}
function editUserFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.EDIT_USER_FAILURE, endpointPath })
    throw new SubmissionError({ _error: err.message })
  }
}
export function editUser(m, userId) {
  return u.getValueFromStore(
    `${c.apiUser}${userId}`,
    h.headerPutWithTokenAndModel(m),
    editUserRequest,
    editUserSuccess,
    editUserFailure,
    true
  )
}

//LOCKOUT USER
export function editUserLockout(user) {
  return {
    type: c.TOGGLE_USER_LOCKOUT,
    payload: user
  }
}

//DELETE USER
export function removeUserFromState() {
  return {
    type: c.REMOVE_USER_FROM_STATE
  }
}
