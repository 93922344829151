////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isUndefined, isNull, find, isEmpty, isEqual } from 'lodash'
import { hashHistory } from 'react-router'
import styled from 'styled-components'
///////COMPONENTS///////
import MenuItem from 'material-ui/MenuItem'
import { apiGetItemFollowUpId } from '../../../actions/itemFollowUp'
import { checkRoleHasRightsToFunction } from '../../../containers/misc/allowedRolesPerFunction'
import * as c from '../../../constants'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import theme from '../../styledComponents/theme'
import Divider from 'material-ui/Divider'
import ViewStockActionsIFU from './ViewStockActionsIFU'
import PageLoadTable from '../../PageLoadTable'
import SourceFileOrderModal from '../../../components/SourceFileOrderModal'
import { resetMyDocumentation ,apiGetMyDocumentation } from '../../../actions/myDocumentation'
/////////ASSETS/////////
import FileDownload from 'material-ui/svg-icons/file/file-download'
import ViewDetail from 'material-ui/svg-icons/action/pageview'
import Print from 'material-ui/svg-icons/action/print'
import LocalShipping from 'material-ui/svg-icons/device/airplanemode-active'


/////////STYLED/////////
const Link = styled.div`
  span:nth-child(1) > div > div > div:hover {
    color: ${theme.colors.blue};
    text-decoration: underline;
  }
`
/////////STYLED/////////

class ItemFollowUpActions extends Component {
  state = {
    seeStockModal: false,
    dataLoaded: false,
    openSourceFileInformation: false,
    isModalOpened: false
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    const { itemFollowUp } = nextProps
    if (!itemFollowUp.isLoadingTable) this.setState({ dataLoaded: true })
    if(!isEqual(this.props.documentations.list, nextProps.documentations.list) && !isEmpty(nextProps.documentations.list)) {
      hashHistory.push(`${c.myDocumentation}/?isRedirect`)
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  seeStockHandleOpen = () => {
    this.setState({ seeStockModal: !this.state.seeStockModal, isModalOpened: !this.state.seeStockModal })
  }

  handleActionClick = () => {
    this.props.apiGetItemFollowUpId(this.props.row.jpiOrderItem)
    if (this.state.dataLoaded) this.setState({ dataLoaded: false })
  }

  addViewStockButtonHandler = () => {
    const { myRole, myCompanyType } = this.props
    // for role Eadmin and Eac switched buyer profil
    // exclude FAC and FACA
    return !((myRole === 'FACA' || myRole === 'FAC') &&
      myCompanyType !== 'BR')
  }

  handleOpenSourceInformationModal = () => {
    this.setState({ openSourceFileInformation: true, isModalOpened: true })
  }

  handleCloseSourceInformationModal = () => {
    this.setState({ openSourceFileInformation: false, isModalOpened: false })
  }

  handleRedirectMyDocumentation(updated_row) {
    this.props.resetMyDocumentation()
    this.props.apiGetMyDocumentation(this.props.userId, {
      StartDocDate: updated_row.orderDate,
      EndDocDate: updated_row.lastShipmentDate,
      inputSearchBy: {key: updated_row.jpiOrder.toString(), value: 'mydocum_tableTitle_JPIPO'},
      seeProformaInvoice: false,
      seeInvoice: true,
      //seeArchived: true,
    })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, myRole, row, itemFollowUp } = this.props
    const updated_row = !isUndefined(find(itemFollowUp.items, { jpiOrderItem: row.jpiOrderItem })) ? find(itemFollowUp.items, { jpiOrderItem: row.jpiOrderItem }) : row
    const displayARC = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
    const printInvoice = [18, 19, 20, 22]
    return (
      <div>
        <ActionHelper
          onClick={this.handleActionClick}
          minWidth={200}
          isModalOpened={this.state.isModalOpened}
        >
          <div >
            {
              !this.state.dataLoaded ?
                <PageLoadTable size={10} thickness={9} style={'linear'} color="inherit" />
                :
                <div>
                  {/*See Stock of jpiOrder*/}
                  { this.addViewStockButtonHandler() &&
                    <MenuItem
                      style={{ fontSize: theme.font.fontSize }}
                      primaryText={trl.ifu_tableAction_viewStocks}
                      leftIcon={<ViewDetail />}
                      onClick={this.seeStockHandleOpen}
                    />
                  }
                  {/* View Shipment */}
                  {/* Disabled for now  // do not delete */}
                  { !isNull(updated_row.orderStatus) && // orderStatus cames from unit call, so it will be null at first
                    c.displayViewShipment.includes(updated_row.orderStatus.idStatus) && checkRoleHasRightsToFunction(myRole, c.viewShipment) &&
                    <div>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.ifu_tableAction_viewPL}
                        leftIcon={<LocalShipping />}
                        onClick={() => hashHistory.push(`${c.orderDetail}${updated_row.jpiOrder}${c.shipmentHistory}`)}
                      />
                    </div>
                  }
                  { /* SEE SOURCE FILE */
                    updated_row.canSeeSourceFile &&
                    <div>
                      <Divider style={{ height: '1.5px' }} />
                      < MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.common_sourceFile_tableAction__seeSourceFile} //
                        leftIcon={<ViewDetail />}
                        onClick={() => this.handleOpenSourceInformationModal()}
                      />
                    </div>
                  }
                  {/* Download ARC */}
                  { !isNull(updated_row.orderStatus) && // orderStatus cames from unit call, so it will be null at first
                    displayARC.includes(updated_row.orderStatus.idStatus) &&
                    updated_row.hasArc &&
                    updated_row.arcNumber !== null &&
                    (myRole !== 'PAdmin' && myRole !== 'POP') &&
                    <Link>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.ifu_tableAction_downloadPI}
                        onClick={() => window.open(`${c.downloadARC}${updated_row.arcNumber}.pdf`, '_blank')}
                        leftIcon={<FileDownload />}
                      />
                    </Link>
                  }
                  { !isNull(updated_row.orderStatus) && // orderStatus cames from unit call, so it will be null at first
                    printInvoice.includes(updated_row.orderStatus.idStatus) &&
                    checkRoleHasRightsToFunction(myRole, c.actionPrintInvoice) &&
                    updated_row.hasInvoice &&
                    <div>
                      <Divider style={{ height: '1.5px' }} />
                      <MenuItem
                        style={{ fontSize: theme.font.fontSize }}
                        primaryText={trl.ifu_tableAction_viewInvoice}
                        leftIcon={<Print />}
                        onClick={() => this.handleRedirectMyDocumentation(updated_row)}
                      />
                    </div>
                  }
                </div>
            }
          </div>
        </ActionHelper>
        <ViewStockActionsIFU
          row={updated_row}
          open={this.state.seeStockModal}
          openHandler={this.seeStockHandleOpen}
          trl={trl}
        />
        {/* SOURCE FILE  */}
        { this.state.openSourceFileInformation &&
          <SourceFileOrderModal
            open={this.state.openSourceFileInformation}
            autoScrollBodyContent={true}
            handleClose={this.handleCloseSourceInformationModal}
            row={row}
            trl={trl}
          >
          </SourceFileOrderModal>
        }
      </div>

    )
  }
}

ItemFollowUpActions.propTypes = {
  row: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired
}

const mapStateToProps = ({ languages, auth, me, itemFollowUp, myDocumentation}) => ({
  trl: languages.siteLanguage.keys,
  myRole: auth.user_role,
  myCompanyType: me.company_type,
  itemFollowUp: itemFollowUp,
  documentations: myDocumentation,
  userId: auth.user_id
})

export default connect(
  mapStateToProps,
  { apiGetItemFollowUpId, resetMyDocumentation ,apiGetMyDocumentation }
)(ItemFollowUpActions)
