import * as c from '../constants'

export default function (state = {}, action) {
  switch (action.type) {
    case c.FETCH_ME:
      return { ...state, ...action.payload }

    case c.SET_MY_PREFERRED_LANGUAGE:
      return { ...state, preferred_language: action.payload.preferred_language }

    case c.UPDATE_ME:
      return { ...state, ...action.payload }
    default:
  }

  return state
}
