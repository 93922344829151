import { theme } from '../../components/styledComponents'

export default {
  container: { flex: 1, flexDirection: 'row', display: 'flex' },
  blackButton: {
    color: theme.colors.white,
    fontWeight: 'bold',
    paddingLeft: 66,
    paddingRight: 66
  },
  greyButton: {
    color: theme.colors.black,
    fontWeight: 'bold',
    paddingLeft: 66,
    paddingRight: 66
  },
  hintStyle: {
    padding: '5px 10px',
    top: '6px'
  },
  searchInput: {
    width: 120,
    border: '1px solid #ced4da',
    borderRadius: 0,
    height: '39px',
    paddingLeft: '10px'
  },
  labelSearch: { padding: '0px 30px 10px 10px', transform: 'translateY(6px)' },
  select: {
    borderRadius: 0,
    border: '1px solid #ced4da',
    padding: '10px 12px',
    width: '100%',
    height: '56%',
    position: 'relative',
    top: '4px',
    lineHeight: 'inherit'
  },
  h2: {
    textAlign: 'center',
    marginBottom: -39,
    color: '#ee5651',
    textTransform: 'uppercase',
    fontSize: 19
  }
}
