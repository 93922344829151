////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'

const PageLoad = props => {
  const { text, size, thickness } = props
  const styles = {
    position: 'absolute',
    left: '53%',
    top:'50%',
    display: 'block',
    alignItems: 'flex-end',
    textAlign: 'center',
    h3: {
      marginTop: 20
    },
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div style={{ ...styles }}>
      <CircularProgress size={Number(size) || size} thickness={thickness} />
      <h3 style={{ ...styles.h3 }}>{text}</h3>
    </div>
  )
}

PageLoad.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) ,
  thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) ,
}

export default PageLoad
