////////LIBRARY/////////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppAlert, addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET STATS
function apiGetStatsRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_STATS_REQUEST, endpointPath })
  }
}
function apiGetStatsSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_STATS_SUCCESS, payload: res })
  }
}
function apiGetStatsFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppAlert(err.message, true))
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_STATS_FAILURE, payload: err })
  }
}
export function apiGetStats() {
  return u.getValueFromStore(
    `${c.apiStats}`,
    h.headerGetWithToken(),
    apiGetStatsRequest,
    apiGetStatsSuccess,
    apiGetStatsFailure
  )
}
