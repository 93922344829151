import * as c from '../constants'

const initialState = {
  snackbar: [],
  dialog: {},
  loading: { isLoading: false, count: 0 }
}
export default function (state = initialState, action) {
  switch (action.type) {
    case c.ADD_ALERT:
      return {
        ...state,
        snackbar: [...state.snackbar, action.payload]
      }
    case c.DELETE_ALERT:
      return {
        ...state,
        snackbar: []
      }
    case c.ADD_ALERT_DIALOG:
      return {
        ...state,
        dialog: action.payload
      }
    case c.DELETE_ALERT_DIALOG:
      return {
        ...state,
        dialog: {}
      }
    case c.SHOW_LOADING:
      return {
        ...state,
        loading: { isLoading: true, overDialog: action.overDialog, count: state.loading.count + 1 }
      }
    case c.HIDE_LOADING:
      const oldState = { ...state.loading }
      oldState.count = oldState.count > 0 && oldState.count - 1
      let dataCount

      dataCount = !!oldState.count
      return {
        ...state,
        loading: { isLoading: dataCount, count: oldState.count }
      }
    default:
      return state
  }
}
