import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { SortableHeader } from 'sematable'

import DocColorBatStatus from '../ColumnHelpers/DocColorBatStatus'
import DateFormatter from '../ColumnHelpers/DateFormatter'
import MyDocumentationPdf from '../Actions/MyDocumentationPdf'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'

export const columnsIds = [
  { key: 'docNumber', header: 'mydocum_tableTitle_docID' },
  { key: 'docType', header: 'mydocum_tableTitle_docType' },
  { key: 'labellist', header: 'mydocum_tableTitle_labellist' },
  { key: 'brandPo', header: 'mydocum_tableTitle_brandPO' },
  { key: 'jpipo', header: 'mydocum_tableTitle_JPIPO' },
  { key: 'gmpo', header: 'mydocum_tableTitle_GMPO' },
  { key: 'docDate', header: 'mydocum_tableTitle_docDate' }
]

export const columns = [
  { key: 'docNumber', header: translate('mydocum_tableTitle_docID'), primaryKey: true },
  { key: 'docType', header: translate('mydocum_tableTitle_docType'), sortable: true, Component: DocColorBatStatus },
  { key: 'labellist', header: translate('mydocum_tableTitle_labellist'), sortable: true, searchable: true },
  { key: 'brandPo', header: translate('mydocum_tableTitle_brandPO') },
  { key: 'jpipo', header: translate('mydocum_tableTitle_JPIPO'), searchable: true, sortable: true },
  { key: 'gmpo', header: translate('mydocum_tableTitle_GMPO'), searchable: true, sortable: true },
  { key: 'docDate', header: translate('mydocum_tableTitle_docDate'), searchable: true, sortable: true, Component: DateFormatter },
  { key: 'actions', header: translate('batPending_tableTitle_Actions'), Component: MyDocumentationPdf }
]

export const searchDomaine = [
  { key: 'docNumber', header: translate('mydocum_tableTitle_docID'), primaryKey: true },
  { key: 'labellist', header: translate('mydocum_tableTitle_labellist') },
  { key: 'brandPo', header: translate('mydocum_tableTitle_brandPO') },
  { key: 'jpipo', header: translate('mydocum_tableTitle_JPIPO') },
  { key: 'gmpo', header: translate('mydocum_tableTitle_GMPO') }
]

const config = {
  defaultPageSize: 20,
  showPageSize: true,
  showFilter: false
}

class MyDocumentationTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRow: null,
      openModalSku: false,
      aztecFormData: [],
      skuLine: {}
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  myColor(type, position) {
    return this.state[type] === position ? 'rgba(0, 0, 0, 0.2)' : ''
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, trl, handelSortable } = this.props
    if (data.length < 1) return null
    return (
      <div>
        <TableContainer>
          <div className="tableWrapperBorder">
            <div className="table-responsive order-detail-follow-up-table">
              <table className="table table-sm table-striped table-hover">
                <thead>
                  <tr>
                    {columns.map(c => {
                      if (c.hidden) return null
                      return (
                        <SortableHeader
                          style={{ fontSize: 16 }}
                          {...c}
                          sortKey={c.key}
                          name={c.header}
                          handleClick={index => handelSortable(index)}
                        />
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((itemRow, index) => {
                    const objectData = { row: { ...itemRow }, activeRow: index }
                    return (
                      <tr key={index} style={{ backgroundColor: this.myColor('activeRow', index) }}>
                        {columns.map((c, key) => {
                          if (c.hidden) return null
                          return (
                            <td key={key}>
                              { c.Component ?
                                React.createElement(c.Component, { ...objectData, children: itemRow[c.key], trl }) : itemRow[c.key] ?
                                itemRow[c.key] : '-' }
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </TableContainer>
      </div>
    )
  }
}

MyDocumentationTable.propTypes = {
  data: PropTypes.array.isRequired,
  handelSortable: PropTypes.func
}
export default sematable('MyDocumentationTable', MyDocumentationTable, columns, config)
