////////LIBRARY/////////
import React, { Component } from 'react'
import _ from "lodash"
import PropTypes from 'prop-types'
import styled from "styled-components"
import { connect } from "react-redux"
///////COMPONENTS///////
import Dialog from "material-ui/Dialog"
import { theme } from "../styledComponents"
import SelectField from "material-ui/SelectField"
import stringformat from "../../utils/stringformat"
import MenuItem from "material-ui/MenuItem"
import * as SC from '../../components/styledComponents'
import { apiShowModalReassign, apiShowModalReassignConfirm, apiPutReassignOrder, apiResetReassignUpdated } from "../../actions/orders"

/////////STYLED/////////
const Label = styled.span`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  font-size: ${(props) => (props.isTitle ? '14px' : '12px')};
  margin-right: 8px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
`
const TableModal = styled.table`
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
`
const TD = styled.td`
  padding-bottom: ${props => props.isLast ? '40px' : '20px'};
  padding-top: ${props => props.isFirst && '40px'};
  color: ${theme.colors.black};
`
const ButtonPA_Mstyled = SC.ButtonPA_M.extend`
  border: 2px solid black;
`
/////////STYLED/////////

class ReassignModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.data
        }
    }

    ///////////////////////////////// HANDLE ///////////////////////////////////////

    handleSelectGm = (event, key, value) => {
        if (this.state.reassignLoaded) {
            this.setState({ gmSelected: value })
            this.setState({ gmId: this.state.availableGmPartners[key].gmId })
            this.setState({ newDeliveryZone: this.state.availableGmPartners[key].deliveryZone })
            this.setState({ newGmAlias: this.state.availableGmPartners[key].gmAlias })
            this.setState({ newGmName: this.state.availableGmPartners[key].gmName })
        }
    }

    ///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
        const { data, trl } = this.props
        return (
            <div>
                <Dialog
                    contentStyle={{ maxWidth: '70vw', width: '35vw', marginLeft: '33vw', padding: 0, }}
                    style={{ width: '35vw', fontSize: theme.p1.ftz, padding: 0, zIndex: 2000, overflow: 'visible' }}
                    bodyStyle={{ padding: 0, zIndex: 5, overflow: 'visible' }}
                    modal={false}
                    open={data.openReassign}
                    onRequestClose={this.handleClose}
                    title={stringformat(trl.order_reassignOrder_modal__title, data.reassignLoaded && data.orderId)}
                >
                    <TableModal>
                        <tbody>
                            <tr>
                                <TD isFirst={true} style={{ width: '40%' }}><Label isTitle={true} bold={true} color={theme.colors.black}>{trl.order_reassignOrder_modal_subTitle__currentGm}</Label></TD>
                                <td style={{ width: '60%', paddingLeft: '20px' }}></td>
                            </tr>
                            <tr>
                                <TD><Label isTitle={true} color={theme.colors.black}>{trl.order_reassignOrder_modal__gmName}</Label></TD>
                                {data.reassignLoaded && !_.isNull(data.currentGmName) ?
                                    <TD><Label isTitle={true} color={theme.colors.black}>{data.currentGmName}</Label></TD> :
                                    <TD><Label isTitle={true} color={theme.colors.black}>-</Label></TD>
                                }
                            </tr>
                            <tr>
                                <TD><Label isTitle={true} color={theme.colors.black}>{trl.order_reassignOrder_modal__owner}</Label></TD>
                                {data.reassignLoaded && !_.isNull(data.currentOwnerEmail) ?
                                    <TD><Label isTitle={true} color={theme.colors.black} style={{ textDecoration: 'underline', color: theme.colors.blue }}>{data.currentOwnerEmail}</Label></TD> :
                                    <TD><Label isTitle={true} color={theme.colors.black}>-</Label></TD>
                                }
                            </tr>
                            <tr>
                                <TD><Label isTitle={true} color={theme.colors.black}>{trl.order_reassignOrder_modal__deliveryZone}</Label></TD>
                                {data.reassignLoaded && !_.isNull(data.currentDeliveryZone) ?
                                    <TD><Label isTitle={true} color={theme.colors.black}>{data.currentDeliveryZone}</Label></TD> :
                                    <TD><Label isTitle={true} color={theme.colors.black}>-</Label></TD>
                                }
                            </tr>
                            <tr>
                                <TD><Label isTitle={true} color={theme.colors.black}>{trl.order_reassignOrder_modal__alias}</Label></TD>
                                {data.reassignLoaded && !_.isNull(data.currentGmAlias) ?
                                    <TD><Label isTitle={true} color={theme.colors.black}>{data.currentGmAlias}</Label></TD> :
                                    <TD><Label isTitle={true} color={theme.colors.black}>-</Label></TD>
                                }
                            </tr>
                            <tr>
                                <TD><Label isTitle={true} bold={true} color={theme.colors.black}>{trl.order_reassignOrder_modal_subTitle__newGm}</Label></TD>
                                <TD></TD>
                            </tr>
                            <tr>
                                <TD><Label isTitle={true} color={theme.colors.black}>{trl.order_reassignOrder_modal__newGmName}</Label></TD>
                                <TD>
                                    <SelectField
                                        style={{
                                            fontSize: theme.font.fontSize,
                                            maxWidth: '100%',
                                            maxHeight: '400px',
                                            height: '30px'
                                        }}
                                        className="test"
                                        floatingLabelStyle={{
                                            display: 'none'
                                        }}
                                        iconStyle={{
                                            margin: '0',
                                            padding: '0'
                                        }}
                                        labelStyle={{
                                            borderRadius: 0,
                                            padding: '3px 30px 0 5px',
                                            border: '1px solid #ced4da',
                                            width: '100%',
                                            height: '30px',
                                            position: 'relative',
                                            top: '4px',
                                            lineHeight: 'inherit'
                                        }}
                                        underlineStyle={{
                                            display: 'none'
                                        }}
                                        value={this.state.gmSelected}
                                        onChange={this.handleSelectGm}
                                        autoWidth={true}
                                    >
                                        {data.reassignLoaded && !_.isUndefined(data.availableGmPartners) &&
                                            data.availableGmPartners.map(gm =>
                                                <MenuItem
                                                    key={gm.gmId}
                                                    value={gm.gmName}
                                                    primaryText={gm.gmName}
                                                    style={{ fontSize: theme.font.fontSize }}
                                                />
                                            )
                                        }
                                    </SelectField>
                                </TD>
                            </tr>
                            <tr>
                                <TD><Label isTitle={true} color={theme.colors.black}>{trl.order_reassignOrder_modal__newGmDeliveryZone}</Label></TD>
                                <TD><Label isTitle={true} color={theme.colors.black}>
                                    {data.reassignLoaded && !_.isUndefined(this.state.newDeliveryZone) && !_.isNull(this.state.newDeliveryZone) ?
                                        this.state.newDeliveryZone : '-'}</Label></TD>
                            </tr>
                            <tr>
                                <TD isLast={true}><Label isTitle={true} color={theme.colors.black}>{trl.order_reassignOrder_modal__newGmAlias}</Label></TD>
                                <TD isLast={true}><Label isTitle={true} color={theme.colors.black}>
                                    {data.reassignLoaded && !_.isUndefined(this.state.newGmAlias) && !_.isNull(this.state.newGmAlias) ?
                                        this.state.newGmAlias : '-'}</Label></TD>
                            </tr>
                            <tr>
                                <TD><SC.ButtonR_M onClick={() => this.props.apiShowModalReassign({ openReassign: false, isModalOpened: false })}>{trl.common__cancel}</SC.ButtonR_M></TD>
                                <TD style={{ textAlign: 'end' }}><ButtonPA_Mstyled disabled={!_.isUndefined(this.state.newGmName) ? false : true} onClick={() => this.props.apiShowModalReassignConfirm({ openReassignConfirm: true, openReassign: false, isModalOpened: false })}>{trl.common__ok}</ButtonPA_Mstyled></TD>
                            </tr>
                        </tbody>
                    </TableModal>
                </Dialog>
                <Dialog
                    contentStyle={{ maxWidth: '70vw', width: '40vw', marginLeft: '30vw', padding: 0, }}
                    style={{ width: '40vw', fontSize: theme.p1.ftz, padding: 0, zIndex: 5000 }}
                    bodyStyle={{ padding: 0, zIndex: 5 }}
                    modal={false}
                    open={data.openReassignConfirm}
                    onRequestClose={this.handleClose}
                    title={stringformat(trl.order_reassignOrder_modal__title, data.reassignLoaded && data.orderId)}
                >
                    <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '20px' }}>{stringformat(trl.order_reassignOrder_modal__confirmationMessage, data.reassignLoaded && this.state.currentGmName, data.reassignLoaded && this.state.newGmName)}</div>
                    <TableModal>
                        <tr>
                        </tr>
                        <tr>
                            <TD style={{ width: '40%' }}><SC.ButtonR_M onClick={() => this.props.apiShowModalReassignConfirm({ openReassignConfirm: false, openReassign: false })}>{trl.common__cancel}</SC.ButtonR_M></TD>
                            <TD style={{ textAlign: 'end', width: '60%' }}><ButtonPA_Mstyled onClick={() => this.props.apiPutReassignOrder(this.state.orderId, this.state.gmId)}>{trl.common__confirm}</ButtonPA_Mstyled></TD>
                        </tr>
                    </TableModal>
                </Dialog>
            </div>
        )
    }
}

ReassignModal.propTypes = {
    data: PropTypes.object.isRequired,
}
function mapStateToProps({ auth, languages }) {
    return {
        role: auth.user_role,
        trl: languages.siteLanguage.keys,
    }
}

export default connect(mapStateToProps, { apiShowModalReassign, apiShowModalReassignConfirm, apiPutReassignOrder, apiResetReassignUpdated })(ReassignModal)