///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppAlert, addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET SHIPMENT HISTORY
function apiShippementHistoryRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_SHIPPEMENT_HISTORY_REQUEST, endpointPath })
  }
}
function apiShippementHistorySuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_SHIPPEMENT_HISTORY_SUCCESS, payload: res })
  }
}
function apiShippementHistoryFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch(addAppAlert(err.message, true))
    dispatch({ type: c.API_GET_SHIPPEMENT_HISTORY_FAILURE, endpointPath })
  }
}
export function apiShippementHistory({ orderId }) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiShipmentHistory}`,
    h.headerGetWithToken(),
    apiShippementHistoryRequest,
    apiShippementHistorySuccess,
    apiShippementHistoryFailure
  )
}
