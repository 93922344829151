////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hashHistory } from 'react-router'
import _, { get } from 'lodash'
///////COMPONENTS///////
import FloatingActionButton from 'material-ui/FloatingActionButton'
import Right from 'material-ui/svg-icons/navigation/chevron-right'
import Left from 'material-ui/svg-icons/navigation/chevron-left'
import { PageHeader } from '../../components/sharedComponents'
import Bat from '../../components/Bat/Bat'
import * as SC from '../../components/styledComponents'
import DateFormatter from '../../components/Sematable/ColumnHelpers/DateFormatter'
import OrderDetailInformation from '../../containers/OrderDetail/OrderDetailInformation'
import { apiBATHistory } from '../../actions/bat'
import { apiGetOrderArticle } from '../../actions/orders'

const styles = {
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}
class BATHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      batIndex: 0,
      hasCompo: false,
      hasCommon: false,
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    const {
      params: { idOrder, idOrderItem }
    } = this.props
    this.props.apiBATHistory({ idOrder, idOrderItem })
    this.props.apiGetOrderArticle(idOrder)
  }

  componentWillReceiveProps(nextProps) {
    const { batsList } = nextProps
    if (_.isEmpty(batsList)) return null
    const { batIndex } = this.state
    const bat = batsList[batIndex]
    const commandeArticle = bat.orderItem
    if (!_.isEmpty(commandeArticle.article_Champ_Compo)) this.setState({ hasCompo: true })
    if (!_.isEmpty(commandeArticle.article_Champ_CommonData)) this.setState({ hasCommon: true })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelCancel() {
    hashHistory.push('/follow-up')
  }

  handelNavigation(batIndex) {
    this.setState({ batIndex })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, batsList = [], orders = [], params: { idOrder }, role } = this.props
    const { batIndex, hasCompo, hasCommon } = this.state
    const isButtonsEnabel = false
    const isNavigationNextEnabel = batsList.length - 1 > batIndex
    const isNavigationPrevEnabel = batIndex > 0

    if (isLoading) return null
    const bat = batsList[batIndex]

    if (!bat) return null
    const commandeArticle = bat.orderItem
    const order = get(orders, `${[idOrder]}`, null)

    return (
      <div>
        <PageHeader
          title={`${trl.batHistory_pageTitle__batItem}  ${bat.ctcItemRef} (${bat.brandItemRef})`}
          subtitle={`${bat.orderItem.article_Name}`}
        />
        {order && (
          <OrderDetailInformation
            trl={trl}
            order={order}
            myRole={role}
            deliveryZoneDisabled={true}
            disabledEdit={true}
          />
        )}
        <div className="batRAboxWrapper">
          <div className="batRAbox" style={{ borderColor: '#ff0000' }}>
            <SC.Column>
              <SC.ColumnLineMiddle style={styles.maxWidth}>
                <span style={{ textTransform: 'none' }}>{trl.batHistory_status}&nbsp;</span>
                <span>
                  <SC.ColorItem color={bat.batStatus.color}>{trl[bat.batStatus.traductionKey]}</SC.ColorItem>
                </span>
              </SC.ColumnLineMiddle>
              <SC.ColumnLineMiddle style={styles.maxWidth}>
                <span style={{ textTransform: 'none' }}>{trl.batHistory_date}&nbsp;</span>
                <span style={{ textTransform: 'none' }}>
                  <DateFormatter children={bat.date_Approved || bat.date_LastRefusal} />
                </span>
              </SC.ColumnLineMiddle>

              <SC.ColumnLineMiddle style={styles.maxWidth}>
                <span style={{ textTransform: 'none' }}>{trl.batHistory_brandContact}&nbsp;</span>
                {bat.userApproved && (
                  <span style={{ textTransform: 'none' }}>
                    {bat.userApproved.firstName} {bat.userApproved.lastName} ({bat.userApproved.email})
                  </span>
                )}
                {bat.lastUserRefusal && (
                  <span style={{ textTransform: 'none' }}>
                    {bat.lastUserRefusal.firstName} {bat.lastUserRefusal.lastName} ({bat.lastUserRefusal.email})
                  </span>
                )}
              </SC.ColumnLineMiddle>
              {bat.refusalReason && (
                <SC.ColumnLineMiddle style={styles.maxWidth}>
                  <span style={{ textTransform: 'none' }}>{trl.batHistory_refusalReason}&nbsp;</span>
                  <span style={{ textTransform: 'none' }}>{bat.refusalReason}</span>
                </SC.ColumnLineMiddle>
              )}
              {bat.refusalComment && (
                <SC.ColumnLineMiddle style={styles.maxWidth}>
                  <span style={{ textTransform: 'none' }}>{trl.batHistory_Comment}&nbsp;</span>
                  <span style={{ textTransform: 'none' }}>{bat.refusalComment}</span>
                </SC.ColumnLineMiddle>
              )}
            </SC.Column>
          </div>
          {batsList.length > 1 && (
            <div className="batRAboxNavigation">
              <FloatingActionButton
                mini={true}
                backgroundColor="#ddd"
                onClick={() => this.handelNavigation(batIndex - 1)}
                disabled={!isNavigationPrevEnabel}
              >
                <Left style={{ fill: '#000' }} color={'#000'} />
              </FloatingActionButton>
              <FloatingActionButton
                mini={true}
                backgroundColor="#ddd"
                onClick={() => this.handelNavigation(batIndex + 1)}
                disabled={!isNavigationNextEnabel}
              >
                <Right style={{ fill: '#000' }} color={'#000'} />
              </FloatingActionButton>
            </div>
          )}
        </div>
        <SC.PageGutter>
          <Bat data={commandeArticle} trl={trl} type="viewBat" dontShowCompo={!hasCompo} dontShowCommon={!hasCommon} />
          <div style={styles.buttonsWrapper}>

            <SC.ButtonR_L
              disabled={!isButtonsEnabel}
              onClick={() => null}
            >
              {trl.batHistory_btn__reject}
            </SC.ButtonR_L>

            <SC.ButtonS
              onClick={() => this.handelCancel()}
            >
              {trl.common__cancel}
            </SC.ButtonS>

            <SC.ButtonPA_L
              disabled={!isButtonsEnabel}
              onClick={() => null}
            >
              {trl.batHistory_btn__approve}
            </SC.ButtonPA_L>
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

BATHistory.propTypes = {
  role: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiBATHistory: PropTypes.func.isRequired
}

const mapStateToProps = ({ bat, auth, apiCallStateManager, orders, languages }) => ({
  batsList: bat.batHistory.list,
  isLoading: bat.batHistory.isLoading,
  errors: bat.batHistory.errors,
  role: auth.user_role,
  apiCallStateManager,
  orders,
  trl: languages.siteLanguage.keys,
})

export default connect(
  mapStateToProps,
  {
    apiBATHistory,
    apiGetOrderArticle
  }
)(BATHistory)

