import * as c from '../constants'

const INITIAL_STATE = { error: false, updated: false }

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case c.UPDATE_VISIBLE_SKU:
      return { ...state, shownSku: action.payload }
    case c.API_MODIFY_ALL_SKU_REQUEST:
      return { ...state, error: false, updated: false }
    case c.API_MODIFY_ALL_SKU_SUCCESS:
      return { ...state, updated: true }
    case c.API_MODIFY_ALL_SKU_FAILURE:
      return { ...state, error: action.payload }
    case c.RESET_SKU_MASS_EDIT_ERROR:
      return { ...state, error: false }
    default:
      break
  }
  return state
}
