////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import { cloneDeep, uniqueId, map } from 'lodash'
///////COMPONENTS///////
import MenuItem from 'material-ui/MenuItem'
import Dialog from 'material-ui/Dialog'
import Divider from 'material-ui/Divider'
import theme from '../../styledComponents/theme'
import { apiDeleteSku } from '../../../actions/visibility'
import ActionHelper from '../ColumnHelpers/ActionHelper'
import * as c from '../../../constants'
import AztecComponent from '../../Aztec'
import * as SC from '../../styledComponents/'
import SourceFileOrderModal from '../../../components/SourceFileOrderModal'
/////////ASSETS/////////
import ViewDetail from 'material-ui/svg-icons/action/pageview'

class BATsPendingActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openEdit: false,
      openConfirmDeleteSku: false,
      openModal: false,
      aztecFormData: [],
      skuLine: {},
      openSourceFileInformation: false,
      isModalOpened: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const { formData } = this.props
    this.setState({ aztecFormData: formData })
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = (row) => {
    const aztecFormDataCp = cloneDeep(this.state.aztecFormData)
    const arraySelectedValuesExploded = []
    const output = []
    const arraySelectedValues = map(aztecFormDataCp, elem => {
      return elem.props.selectedValues
    })

    const arrayRowListDvs = map(row.listDVS, r => {
      return r
    })
    for (let i = 0; i < arraySelectedValues.length; i++) {
      for (let y = 0; y < arraySelectedValues[i].length; y++) {
        arraySelectedValuesExploded.push(arraySelectedValues[i][y])
      }
    }
    for (let i = 0; i < arrayRowListDvs.length; i++) {
      aztecFormDataCp[i].props.selectedValues.splice(0, aztecFormDataCp[i].props.selectedValues.length, arraySelectedValuesExploded.filter(f => f.iD_Commande_Article_Champ === arrayRowListDvs[i].iD_Commande_Article_Champ)[0])
      output.push(aztecFormDataCp[i])
      this.setState({ openModal: true, aztecFormData: output, iD_SKU: row.iD_SKU, isModalOpened: true })
    }
  }

  handleClose = () => {
    this.setState({ openModal: false, isModalOpened: false })
  }

  handleOpenSourceInformationModal = () => {
    this.setState({ openSourceFileInformation: true, isModalOpened: true })
  }

  handleCloseSourceInformationModal = () => {
    this.setState({ openSourceFileInformation: false, isModalOpened: false })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, row } = this.props
    const needle = location.hash.split('/')[1]
    const haystack = c.batPending.split('/')[1]
    const actionsEdit = [
      <SC.ButtonPA_S style={{ margin: "0 20px" }} onClick={this.handleClose}>
        {trl.common__ok}
      </SC.ButtonPA_S>
    ]
    return (
      <div style={{ cursor: 'pointer' }}>
        <ActionHelper isModalOpened={this.state.isModalOpened}>
          { needle === haystack ?
              <div>
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  primaryText={trl.batPending_tableAction_ViewBat} //
                  leftIcon={<ViewDetail />}
                  onClick={() => hashHistory.push(`${c.batView}${row.jpiPo}/${row.idBat}`)}
                />
                <Divider style={{ height: '1.5px' }} />
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  primaryText={trl.batPending_tableAction_ViewOrder} //
                  leftIcon={<ViewDetail />}
                  href={`/#/order/${row.jpiPo}`}
                />
                {
                  row.canSeeSourceFile &&
                  <div>
                    <Divider style={{ height: '1.5px' }} />
                    <MenuItem
                      style={{ fontSize: theme.font.fontSize }}
                      primaryText={trl.common_sourceFile_tableAction__seeSourceFile} //
                      leftIcon={<ViewDetail />}
                      onClick={() => this.handleOpenSourceInformationModal()}
                    />
                  </div>
                }
              </div>
              :
              <div>
                <MenuItem
                  style={{ fontSize: theme.font.fontSize }}
                  primaryText={trl.bat_table_actions__viewDetail}
                  leftIcon={<ViewDetail />}
                  onClick={() => this.handleOpen(row)}
                />
                {
                  row.canSeeSourceFile &&
                  <div>
                    <Divider style={{ height: '1.5px' }} />
                    <MenuItem
                      style={{ fontSize: theme.font.fontSize }}
                      primaryText={trl.common_sourceFile_tableAction__seeSourceFile} //
                      leftIcon={<ViewDetail />}
                      onClick={() => this.handleOpenSourceInformationModal()}
                    />
                  </div>
                }
              </div>
          }
        </ActionHelper>
        <Dialog
          actions={actionsEdit}
          modal={true}
          open={this.state.openModal}
          autoScrollBodyContent={true}
        >
          <div className="editSk">
            <AztecComponent
              guid={uniqueId('test')}
              data={this.state.aztecFormData !== undefined ? this.state.aztecFormData : []}
              aditionnelData={this.state.skuLine}
              isDisabled={true}
              submitAction={m => this.handleSubmit(m, 0)}
              ref={aztecFormSku => (this.aztecFormSku = aztecFormSku)}
            />
          </div>
        </Dialog>
        {/* SOURCE FILE  */}
        { this.state.openSourceFileInformation &&
          <SourceFileOrderModal
            open={this.state.openSourceFileInformation}
            autoScrollBodyContent={true}
            handleClose={this.handleCloseSourceInformationModal}
            row={row}
            trl={trl}
          >
          </SourceFileOrderModal>
        }
      </div>
    )
  }
}

BATsPendingActions.propTypes = {
  row: PropTypes.object.isRequired,
  trl: PropTypes.object
}

const mapStateToProps = ({ orders, languages }) => ({
  orders: orders,
  trl: languages.siteLanguage.keys,
})
export default connect(
  mapStateToProps,
  { apiDeleteSku }
)(BATsPendingActions)
