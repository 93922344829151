////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable from 'sematable'
import styled from 'styled-components'
///////COMPONENTS///////
import DateFormatter from '../ColumnHelpers/DateFormatter'
import { translate } from '../../../containers/HOC/Translation'

/////////STYLED/////////
const PSTD = styled.td`
  border: 1px solid #000 !important;
`
const PSTDBorderRight = styled.td`
  border: 1px solid #000 !important;
  border-right: 3px solid #000 !important;
`
/////////STYLED/////////

const columns = [
  { key: 'referenceItem.enseigne_Name', header: translate('delivery_tableTitle_Brand') },
  { key: 'referenceItem.iD_Article_Externe_SAP', header: translate('delivery_tableTitle_CTCItem'), primaryKey: true },
  { key: 'referenceItem.article_Name', header: translate('delivery_tableTitle_Designation') },
  { key: 'referenceItem.article_Type_Name', header: translate('delivery_tableTitle_ItemType') },
  { key: 'referenceItem.article_Conditionnement', header: translate('delivery_tableTitle_Packing') },
  { key: 'importationDate', header: translate('delivery_tableTitle_ImportationDate') },
  { key: 'quantityInitial', header: translate('delivery_tableTitle_QtyInitial') },
  { key: 'quantityAdded', header: translate('delivery_tableTitle_QtyAdded') },
  { key: 'quantityDeleted', header: translate('delivery_tableTitle_QtyRemoved') },
  { key: 'quantityTotalRequested', header: translate('delivery_tableTitle_QtyTotal') },
  { key: 'empty', header: <i className="fa fa-times-circle" /> }
]

const config = {
  defaultPageSize: 50,
  showPageSize: false,
  showFilter: false
}

class PreparationSheetTable extends Component {

	///////////////////////////////// HANDLE ///////////////////////////////////////

  checkItemStatus = (subFlow, index) => {
    if (subFlow !== 'ANN') {
      if (index !== this.props.data.length - 1) return { backgroundColor: '#f5f5f5', borderBottom: '2px solid #000' }
      return { backgroundColor: '#f5f5f5' }
    } 
    else return { backgroundColor: '#fff' }
  }

  THSeparate = index => {
    if (index === 4) return { backgroundColor: '#ddd', borderColor: '#000', borderRightWidth: '3px' }
    else return { backgroundColor: '#ddd', borderColor: '#000' }
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data } = this.props
    return (
      <div className="tableWrapperBorder">
        <table className="table table-sm table-bordered table-striped table-hover" style={{ borderTop: '1px solid #000', marginBottom: 0 }}>
          <thead>
            <tr>
              {columns.map((c, index) => (
                <th style={this.THSeparate(index)} key={c.key}>
                  {c.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index} style={this.checkItemStatus(item.status.subFlow, index)}>
                  <PSTD>{item.referenceItem.enseigne_Name}</PSTD>
                  <PSTD>{item.referenceItem.iD_Article_Externe_SAP}</PSTD>
                  <PSTD>{item.referenceItem.article_Name}</PSTD>
                  <PSTD>{`${item.referenceItem.article_Type_Name} / ${
                    item.referenceItem.article_Fixe ? 'F' : 'V'
                  }`}</PSTD>
                  <PSTDBorderRight>{item.referenceItem.article_Conditionnement}</PSTDBorderRight>
                  <PSTD>
                    <DateFormatter children={item.importationDate} />
                  </PSTD>
                  <PSTD>{item.quantityInitial}</PSTD>
                  <PSTD>{item.quantityAdded}</PSTD>
                  <PSTD>{item.quantityDeleted}</PSTD>
                  <PSTD style={{ color: '#f00' }}>{item.quantityTotalRequested}</PSTD>
                  <PSTD style={{ width: '60px' }} />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

PreparationSheetTable.contextTypes = {
  router: PropTypes.object.isRequired
}

PreparationSheetTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

export default sematable('PreparationSheet', PreparationSheetTable, columns, config)
