////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, hashHistory } from 'react-router'
import { isEmpty, get, isUndefined, isNull } from 'lodash'
///////COMPONENTS///////
import { PageHeader } from '../../components/sharedComponents'
import PageLoad from '../../components/PageLoad'
import * as SC from '../../components/styledComponents'
import * as c from '../../constants'
import stringformat from '../../utils/stringformat'
import DeliveryDetailInformation from './DeliveryDetailInformation'
import DeliveryDetailTable from '../../components/Sematable/Tables/DeliveryDetailTable'
import { apiGetDelivery, apiCancelDelivery, apiDeliveryPrintInvoice, apiDeliveryPrintPS, apiSavePackingList } from '../../actions/delivery'
import { resetOrderArticle } from '../../actions/orders'

class DeliveryDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delivery: {}
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    if (!isEmpty(this.props.delivery) && !this.state.delivery) this.setState({ delivery: this.props.delivery })
  }

  componentDidMount() {
    this.props.apiGetDelivery(this.props.params.deliveryId)
  }

  componentWillReceiveProps(nextProps) {
    const { delivery, additional } = nextProps
    if (this.props.delivery !== delivery) this.setState({ delivery: delivery })
    if (this.props.additional !== additional && additional.goToNextPage) hashHistory.push(`${c.delivery}${delivery.idDeliveryJPI}${c.packinglist}`)
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handlePrintInvoice = (idDelivery, idInvoice) => {
    this.props.apiDeliveryPrintInvoice(idDelivery)
    window.open(`${c.downloadInvoice}${idInvoice}.pdf`, '_blank')
  }

  handlePrintPS = idDelivery => {
    this.props.apiDeliveryPrintPS(idDelivery)
  }

  handleBackBtn = () => {
    this.context.router.push(c.deliveriesFollowUp)
  }

  handleProcessPL = (subFlow, idDelivery) => {
    if (subFlow <= '060') this.props.apiSavePackingList({ idDelivery })
  }

  renderProceedBtn = () => {
    const { trl, companyType, delivery } = this.props
    const subFlow = get(delivery, 'status.subFlow', '')
    if ((companyType !== 'PS') || (!delivery.isDeliveryFluxJpi && delivery.packingListFile != null)) {
      return (
        <Link
          onClick={()=> (!delivery.isDeliveryFluxJpi && !isUndefined(delivery.packingListFile) && !isNull(delivery.packingListFile)) && window.open(`/external/packing_list/${delivery.packingListFile}.pdf`)}
          to={(!delivery.isDeliveryFluxJpi && delivery.packingListFile != null) ? '' : `${c.delivery}${delivery.idDeliveryJPI}${c.packinglistSavePrint}`}
          target="_blank"
        >
          <SC.ButtonPA_L
            disabled={subFlow < '070'}
          >
            {trl.delivery_btn_ViewPL}
          </SC.ButtonPA_L>
        </Link>
      )
    } else if (subFlow <= '060' && delivery.isDeliveryFluxJpi) {
      return (

        <SC.ButtonPA_L
          disabled={subFlow < '040' || subFlow === '050'}
          onClick={() => this.handleProcessPL(subFlow, delivery.idDeliveryJPI)}
        >
          {trl.delivery_btn_ProceedWithPL}
        </SC.ButtonPA_L>
      )
    } else if(delivery.isDeliveryFluxJpi) {
      return (
        <Link
          to={`${c.delivery}${delivery.idDeliveryJPI}${c.packinglistSavePrint}`}
          target="_blank"
        >
          <SC.ButtonPA_L
            disabled={subFlow < '040' || subFlow === '050'}
          >
            {trl.delivery_btn_ViewPL}
          </SC.ButtonPA_L>
        </Link>
      )
    }
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, companyType, delivery } = this.props
    const subFlow = get(delivery, 'status.subFlow', '')
    if (isLoading || isEmpty(delivery)) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    return (
      <div>
        <PageHeader
          title={trl.delivery_Title && stringformat(trl.delivery_Title, delivery.idDeliveryJPI || '')}
          subtitle={trl.delivery_SubTitle ? `${trl.delivery_SubTitle}${delivery.idDeliverySAP}` : 'Delivery Note N°'}
        />
        <SC.PageGutter>
          <DeliveryDetailInformation
            trl={trl}
            delivery={delivery}
            myRole={this.props.role}
            companyType={this.props.companyType}
            apiCancelDelivery={this.props.apiCancelDelivery}
          />
        </SC.PageGutter>
        <SC.PageGutter>
          <DeliveryDetailTable
            trl={trl}
            data={delivery.deliveryItems}
            subFlow={subFlow}
            className="DeliveryDetailTable"
          />
        </SC.PageGutter>
        <SC.PageGutter>
          <div style={{ float: 'right' }}>
            {subFlow < '040' && <p style={{ color: '#f00' }}>{trl.delivery_btn_errorMessage_PrintPSBeforePL}</p>}
            {subFlow === '050' && <p style={{ color: '#f00' }}>{trl.delivery_btn_errorMessage_NotAuthorized}</p>}
          </div>
          <div
            style={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <SC.ButtonR_L
              onClick={() => this.handleBackBtn()}
            >
              {trl.delivery_btn_Back}
            </SC.ButtonR_L>
            {companyType === 'PS' && delivery.isDeliveryFluxJpi && (
              <Link
                to={`${c.delivery}${delivery.idDeliveryJPI}${c.preparationSheet}`}
                target="_blank"
                onClick={() => this.handlePrintPS(delivery.idDeliveryJPI)}
              >

                <SC.ButtonPA_L>
                  {trl.delivery_btn_PrintPreparationSheet}
                </SC.ButtonPA_L>
              </Link>
            )}
            {companyType === 'PS' && parseInt(subFlow, 10) >= 70 && delivery.hasInvoice && (

              <SC.ButtonPA_L
                onClick={() => this.handlePrintInvoice(delivery.idDeliveryJPI, delivery.idInvoice_externalSAP)}
              >
                {trl.delivery_btn_PrintInvoice}
              </SC.ButtonPA_L>
            )}
            {this.renderProceedBtn()}
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

DeliveryDetail.contextTypes = {
  router: PropTypes.object.isRequired
}

DeliveryDetail.propTypes = {
  delivery: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiGetDelivery: PropTypes.func.isRequired,
  apiCancelDelivery: PropTypes.func.isRequired,
  apiDeliveryPrintInvoice: PropTypes.func.isRequired,
  apiDeliveryPrintPS: PropTypes.func.isRequired,
  apiSavePackingList: PropTypes.func.isRequired,
  apiCallStateManager: PropTypes.object.isRequired
}

DeliveryDetail.defaultProps = {
  delivery: {}
}

const mapStateToProps = ({ delivery, me, auth, apiCallStateManager }) => ({
  delivery: delivery.delivery,
  additional: delivery.additional,
  role: auth.user_role,
  companyType: me.company_type,
  apiCallStateManager,
  isLoading: delivery.isLoading
})

export default connect(
  mapStateToProps,
  {
    apiGetDelivery,
    apiCancelDelivery,
    apiDeliveryPrintInvoice,
    apiDeliveryPrintPS,
    apiSavePackingList,
    resetOrderArticle
  }
)(DeliveryDetail)
