////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNull, filter } from 'lodash'
///////COMPONENTS///////
import { GetCompanyBrandPartner } from '../../actions/companies'
import { apiCreateOrder } from '../../actions/orders'
import PageLoad from '../../components/PageLoad'
import * as SC from '../../components/styledComponents'
import { PageHeader } from '../../components/sharedComponents'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem';
import getPermissionValue from '../../utils/permissionHelper'
import CircularProgress from 'material-ui/CircularProgress'

class SelectBrand extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.GetCompanyBrandPartner()
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handleChange(e, value) {
    this.selectedBrand = e.target.innerHTML
    this.props.apiCreateOrder(value)
  }

  selectedBrand = null

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { companies, trl, user, isLoading } = this.props
    if (isLoading || !companies.brandCompanies || !companies.brandCompanies && !isLoading) {
      return <PageLoad text={trl.admin_brands__no_brand} size="80" thickness="9" />
    }
    const brandCompanies = filter(companies.brandCompanies, c => {
      return c.type.toUpperCase() === 'BR' && getPermissionValue(c, 'permission_societe_2') === true
    })
    return (
      <div>
        <PageHeader title={trl.order_new__title} subtitle={trl.order_new__subtitle} />
        <SC.PageGutter>
          {brandCompanies.length > 0 && (
            <SelectField
              style={{ fontSize: SC.theme.fontSize }}
              floatingLabelStyle={{
                top: '35px',
                paddingLeft: '10px'
              }}
              labelStyle={{
                transform: 'translateY(6px)',
                borderRadius: 0,
                border: '1px solid #ced4da',
                height: '56%',
              }}
              underlineStyle={{ display: 'none' }}
              floatingLabelText={isNull(this.selectedBrand) ? trl.order_new__select_brand : this.selectedBrand}
              onChange={(e, index, value) => this.handleChange(e, value)}
            >
              {brandCompanies.map(s => <MenuItem style={{ fontSize: 12 }} key={s.iD_Societe} value={s.iD_Societe} primaryText={s.name_2} />)}
            </SelectField>
          )}
          {brandCompanies &&
            brandCompanies.length === 0 && (
              <p>{`${trl.selectBrands_message__sorryNoContract}.`}</p>
            )}
          {isLoading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={80} thickness={7} />
            </div>
          )}
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ companies, me, loading }) {
  return {
    companies: companies,
    isLoading: loading.companies.isLoading/*  */
  }
}

SelectBrand.propTypes = {
  companies: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  GetCompanyBrandPartner: PropTypes.func.isRequired,
  apiCreateOrder: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { GetCompanyBrandPartner, apiCreateOrder })(SelectBrand)
