////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
///////COMPONENTS///////
import Dialog from 'material-ui/Dialog'
import Toggle from 'material-ui/Toggle'
import * as SC from '../../components/styledComponents'
import { styles } from '../Forms/misc/formStyles'
import { apiSaveFileOrderFormat } from '../../actions/companies'

/////////STYLED/////////
const H2Styled = SC.H2.extend`
  color: ${SC.theme.colors.lightGrey};
  background-color: ${SC.theme.colors.darkGrey};
  padding: 16px;
  font-size: 19px;
`
/////////STYLED/////////

const columns = trl => ([
  { key: 'code', header: trl.fileOrderFormats_modal_formatCode, checkEditable: () => false },
  {
    key: 'description',
    header: trl.fileOrderFormats_modal_formatDescription,
    validate: value => value.length < 101,
    errorMessage: '100 characters max'
  },
  {
    key: 'version',
    header: trl.fileOrderFormats_modal_formatVersion,
    checkEditable: row => row.isStandard,
    validate: value => /^[0-9]+\.[0-9]+$/.test(value),
    errorMessage: 'Should be {NUMBER}.{NUMBER}'
  },
  { key: 'extension', header: trl.fileOrderFormats_modal_formatExtention, checkEditable: () => false },
  { key: 'isEnabled', header: trl.fileOrderFormats_modal_formatIsEnabled, type: 'switch' },
])

class EditFileFormat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openEditModal: false,
      openEditModalError: false,
      code: props.row.code,
      description: props.row.description,
      version: props.row.version,
      extension: props.row.extension,
      isEnabled: props.row.isEnabled,
    }
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  toggleEditModal = openEditModal => () => this.setState({
    openEditModal,
    code: this.props.row.code,
    description: this.props.row.description,
    version: this.props.row.version,
    extension: this.props.row.extension,
    isEnabled: this.props.row.isEnabled,
  })

  toggleEditModalError = openEditModalError => () => this.setState({ openEditModalError })

  handleChange = (e, v) => this.setState({ [e.target.name]: typeof v === 'undefined' ? e.target.value : v })

  validateForm() { return columns({}).every(c => !c.validate || c.validate(this.state[c.key])) }

  save = () => {
    const newFormat = {
      companyId: this.props.row.company.companyId,
      fileOrderFormatId: this.props.row.fileOrderFormatId,
      version: this.state.version,
      description: this.state.description,
      isEnabled: this.state.isEnabled,
    }
    this.props.apiSaveFileOrderFormat(newFormat, success => {
      if (success) return this.toggleEditModal(false)();
      this.toggleEditModalError(true)();
    })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row } = this.props
    const company = row.company
    const trl = row.trl
    return (
      <div>
        <SC.ButtonS
          onClick={this.toggleEditModal(true)}
          style={{ ...styles.loginBtn, margin: '0', float: 'initial', paddingTop: '6px' }}
        >
          {trl.fileOrderFormats_tableAction_edit}
        </SC.ButtonS>
        <Dialog
          actions={[
            <SC.ButtonR_L
              onClick={this.toggleEditModal(false)}
              style={{ marginRight: 20 }}
            >
              {trl.common__cancel}
            </SC.ButtonR_L>,
            <SC.ButtonPA_L
              onClick={this.save}
              disabled={!this.validateForm()}
            >
              {trl.common__save}
            </SC.ButtonPA_L>
          ]}
          contentStyle={{ width: '800px', padding: 0 }}
          style={{ fontSize: SC.theme.p1.ftz, padding: 0 }}
          bodyStyle={{ padding: 0 }}
          modal={false}
          open={this.state.openEditModal}
          autoScrollBodyContent={true}
          onRequestClose={this.toggleEditModal(false)}
        >
          <H2Styled>{`${company.companyName} - ${row.code}`}</H2Styled>
          <table style={{ margin: '12px 24px' }}>
            <tbody>
              {columns(trl).map(({ key, header, type, validate, checkEditable, errorMessage }) => (
                <tr key={key} style={{ height: '50px' }}>
                  <td style={{ paddingRight: '8px' }}>
                    <span>{header}</span>
                  </td>
                  <td>
                    {(() => {
                      if (checkEditable && !checkEditable(row)) {
                        return <span>{this.state[key]}</span>
                      }
                      if(type == 'switch'){
                          return <Toggle
                            name={key}
                            labelPosition="right"
                            style={{ width: '80%', fontSize: '16px !important' }}
                            onToggle={this.handleChange}
                            defaultToggled={row[key]}
                          />
                      } else {
                          return <input
                            type="text"
                            style={{
                              border: '1px solid #ced4da',
                              borderRadius: 0,
                              height: '39px',
                              width: '300px',
                              paddingLeft: '10px'
                            }}
                            name={key}
                            value={this.state[key]}
                            onChange={this.handleChange}
                          />
                      }
                    })}
                    {validate && !validate(this.state[key]) && (
                      <div style={{ color: SC.theme.colors.red, marginBottom: '4px' }}>{errorMessage}</div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Dialog>
        <Dialog
          actions={[
            <SC.ButtonPA_L
              onClick={this.toggleEditModalError(false)}
            >
              {trl.common__ok}
            </SC.ButtonPA_L>
          ]}
          contentStyle={{ width: '600px' }}
          style={{ padding: 0 }}
          bodyStyle={{ padding: '12px' }}
          modal={false}
          open={this.state.openEditModalError}
          autoScrollBodyContent={true}
          onRequestClose={this.toggleEditModalError(false)}
        >
          <span style={{ fontSize: '14px' }}>{trl.fileOrderFormats_modal_errorMessageOnSave}</span>
        </Dialog>
      </div>
    )
  }
}

EditFileFormat.propTypes = {
}

export default connect(state => ({
  isLoading: state.loading.saveFileOrderFormat,
}), {
  apiSaveFileOrderFormat
})(EditFileFormat)