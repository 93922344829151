///////COMPONENTS///////
import TextField from './Textfield'
import Radio from './Radio'
import SelectField from './SelectField'
import Checkbox from './Checkbox'
import Toggle from './Toggle'
import DatePicker from './DatePicker'
import TimePicker from './TimePicker'
import AutoComplete from './AutoComplete'
import Label from './Label'

const Controls = {
  TextField,
  Radio,
  SelectField,
  Checkbox,
  Toggle,
  DatePicker,
  TimePicker,
  AutoComplete,
  Label
}

export default Controls
