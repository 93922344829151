////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toArray, isEmpty, cloneDeep, isEqual } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import { PageHeader } from '../../components/sharedComponents'
import SelectField from 'material-ui/SelectField'
import PageLoad from '../../components/PageLoad'
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import * as c from '../../constants'
import WaitingForDeliveryTable from '../../components/Sematable/Tables/WaitingForDeliveryTable'
import { apiGetDeliveriesFollowUp, apiCreateDelivery, apiPutDeliveriesWaitingFollowUp } from '../../actions/delivery'

/////////STYLED/////////
const SearchContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
`
const TextLeftStyled = styled.p`
  padding-right: 15px;
  transform: translateY(6px);
`
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
const ButtonWrapped = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  width: calc(33% - 10px);
  margin: 2rem 0;
  @media (max-width: 1460px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const FormsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  flex-grow: 1;
  width: calc(66% - 10px);
  & > * {
    flex: 1 1 25%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1460px) {
    width: 50%;
    & > * {
      flex: 1 1 100%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const FormWrapped = styled.form`
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  @media (max-width: 1460px) {
    justify-content: space-between;
  }
`
/////////STYLED/////////

class WaitingForDelivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deliveriesWaiting: {},
      SearchBy: {
        "key": "",
        "value": "",
      },
      filters: []
    }
    this.defaultState = cloneDeep(this.state)
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    if (!isEmpty(this.props.deliveries) && !this.state.deliveries.length) this.setState({ deliveries: toArray(this.props.deliveries) })
  }

  queryData = (defaultState) => {
    const inputSearchBy = cloneDeep(this.state.SearchBy)
    inputSearchBy.value = inputSearchBy.value !== 'common__all' ? inputSearchBy.value : ''
    if (isEqual(defaultState, this.defaultState)) this.props.apiPutDeliveriesWaitingFollowUp(defaultState)
    else {
      this.props.apiPutDeliveriesWaitingFollowUp({
        SearchBy: inputSearchBy,
      })
    }
  }

  componentDidMount() {
    this.queryData()
  }

  componentWillReceiveProps(nextProps) {
    const { deliveries, trl } = nextProps
    this.setState({
      deliveries: deliveries,
      filters: [
        { key: 'newdeliveries_tableTitle_Status', value: 'status', text: trl.newdeliveries_tableTitle_Status },
        { key: 'newdeliveries_tableTitle_IDDelivery', value: 'idDeliverySAP', text: trl.newdeliveries_tableTitle_IDDelivery },
        { key: 'newdeliveries_tableTitle_ShipmentAuthoriz', value: 'shipmentAuth', text: trl.newdeliveries_tableTitle_ShipmentAuthoriz },
        { key: 'newdeliveries_tableTitle_CTCConfirmedDate', value: 'ctcDateConfirmed', text: trl.newdeliveries_tableTitle_CTCConfirmedDate },
        { key: 'newdeliveries_tableTitle_SalesOrder', value: 'idSalesOrder_SAP', text: trl.newdeliveries_tableTitle_SalesOrder },
        { key: 'newdeliveries_tableTitle_ItemType', value: 'itemType', text: trl.newdeliveries_tableTitle_ItemType },
        { key: 'newdeliveries_tableTitle_NumberOfItem', value: 'numberOfItems', text: trl.newdeliveries_tableTitle_NumberOfItem },
        { key: 'newdeliveries_tableTitle_To', value: 'labelCompany', text: trl.newdeliveries_tableTitle_To },
        { key: 'newdeliveries_tableTitle_Country', value: 'countryAddress', text: trl.newdeliveries_tableTitle_Country }
      ]
    })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  changeInputSearchByHandler = (event, index, value) => {
    this.setState({ SearchBy: { 'key': this.state.SearchBy.key, 'value': value }})
  }

  changeInputSearchHandler = (event, index, value) => {
    this.setState({ SearchBy: { 'key': index, 'value': this.state.SearchBy.value }})
  }

  onKeyPressHandler(event) {
    if (event.charCode !== undefined) {
      if (event.charCode === 13) { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.key !== undefined) {
      if (event.key === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    } else if (event.keyIdentifier !== undefined) {
      if (event.keyIdentifier === 'Enter') { // enter key pressed
        event.preventDefault()
        this.queryData()
      }
    }
  }

  resetFilters = () => {
    this.setState(this.defaultState)
    // Also adding back call
    this.queryData(this.defaultState)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, deliveriesWaiting } = this.props
    const { filters } = this.state
    if (isLoading) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    return (
      <div>
        <PageHeader title={trl.order_EditVariableItem_DVS ? trl.order_EditVariableItem_DVS : trl.waitingDelivery_title__waitingDelivery} />
        <SC.Sky grey>
          <SC.PageGutter>
            <SearchContainerStyled>
              <ButtonWrapper>
                <FormsWrapper>
                  <FormWrapped onKeyDown={(e) => { if (e.key === "Enter" || e.keyCode === 13) { e.preventDefault(); } }}>
                    <TextLeftStyled>{trl.common__search} :</TextLeftStyled>
                    <div>
                      <TextField
                        type="text"
                        style={{
                          height: '30px',
                          marginLeft: '10px',
                          marginRight: '8px',
                          width: '150px',
                          fontSize: 'theme.font.fontSize'
                        }}
                        name="searchTerm"
                        value={this.state.SearchBy.key}
                        onChange={this.changeInputSearchHandler}
                        onKeyPress={(evt) => this.onKeyPressHandler(evt)}
                      />
                    </div>
                  </FormWrapped>
                  <FormWrapped>
                    <span style={{ padding: '0 10px' }}>{trl.common__by} :</span>
                    <SelectField
                      style={{ fontSize: theme.font.fontSize }}
                      floatingLabelText={trl.common__search_by || ' '}
                      value={this.state.SearchBy.value}
                      onChange={this.changeInputSearchByHandler}
                    >
                      <MenuItem style={{ fontSize: theme.font.fontSize }} value="All" primaryText={trl.common__all} />
                      {filters.map(f => (
                        <MenuItem style={{ fontSize: theme.font.fontSize }} key={f.value} value={f.key} primaryText={f.text} />
                      ))}
                    </SelectField>
                  </FormWrapped>
                </FormsWrapper>
                <ButtonWrapped>
                  <SC.ButtonPA_M style={{ marginRight: 16 }}
                    onClick={this.queryData}>
                    {trl.common__filter_apply_btn}
                  </SC.ButtonPA_M>
                  <SC.ButtonS
                    onClick={this.resetFilters}
                  >
                    {trl.common__filter_reset_btn || ' '}
                  </SC.ButtonS>
                </ButtonWrapped>
              </ButtonWrapper>
            </SearchContainerStyled>
          </SC.PageGutter>
        </SC.Sky>
        <SC.PageGutter>

          <SC.ButtonR_L
            onClick={() => this.context.router.push(c.deliveriesFollowUp)}
          >
            {trl.newdeliveries_Btn_BackDEFU || ' '}
          </SC.ButtonR_L>
        </SC.PageGutter>
        {/* ORDERS TABLE */}
        <SC.PageGutter>
          {!isEmpty(deliveriesWaiting) ? (
            <WaitingForDeliveryTable
              trl={trl}
              data={deliveriesWaiting}
              companyType={this.props.companyType}
              className="WaitingForDeliveryTable"
              createDelivery={this.props.apiCreateDelivery}
            />
          ) : (
            <h2 style={{ textAlign: 'center', marginTop: 80, fontSize: 15 }}>{trl.defu_error__NoElementToDisplay}.</h2>
          )}
        </SC.PageGutter>
        <SC.PageGutter>

          <SC.ButtonR_L
            onClick={() => this.context.router.push(c.deliveriesFollowUp)}
          >
            {trl.newdeliveries_Btn_BackDEFU || ' '}
          </SC.ButtonR_L>
        </SC.PageGutter>
      </div>
    )
  }
}

WaitingForDelivery.contextTypes = {
  router: PropTypes.object.isRequired
}

WaitingForDelivery.propTypes = {
  deliveries: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  companyType: PropTypes.string.isRequired,
  trl: PropTypes.object.isRequired,
  apiGetDeliveriesFollowUp: PropTypes.func.isRequired,
  apiCallStateManager: PropTypes.object.isRequired
}

WaitingForDelivery.defaultProps = {
  deliveries: {}
}

const mapStateToProps = ({ deliveriesWaiting, auth, apiCallStateManager, me }) => ({
  deliveriesWaiting: deliveriesWaiting.deliveriesWaiting,
  deliveriesWaitingFilters: deliveriesWaiting.deliveriesWaitingFilters,
  role: auth.user_role,
  companyType: me.company_type,
  apiCallStateManager,
  isLoading: deliveriesWaiting.isLoading
})

export default connect(
  mapStateToProps,
  { apiGetDeliveriesFollowUp, apiCreateDelivery, apiPutDeliveriesWaitingFollowUp }
)(WaitingForDelivery)
