////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import { map, isUndefined } from 'lodash'
///////COMPONENTS///////
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

const tables = {
  topFiveItemRef: {
    table: {
      itemRef: { text: 'stats_comon_tableTitle_top5ItemRef', format: 'text' },
      itemType: { text: 'statistics_tableTitle_topItemType', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '25%' }
    }
  },
  topFiveItemType: {
    table: {
      itemType: { text: 'statistics_tableTitle_topItemType', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '35%' }
    }
  },
  topFivePurchasers: {
    table: {
      addressName: { text: 'stats_comon_tableTitle_top5Purchaser', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '35%' }
    }
  },
  topFiveDelivery: {
    table: {
      addressName: { text: 'stats_comon_tableTitle_top5Delivery', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '35%' }
    }
  },
  areas: {
    table: {
      area: { text: 'stats_comon_tableTitle_area', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '35%' }
    }
  },
  currentSeasonItemRefs: {
    title: 'statistics_tableTitle_currentSeason',
    table: {
      itemType: { text: 'statistics_tableTitle_topItemType', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '35%' }
    },
    css: 'TablesBorder-Bottom'
  },
  lastSeasonItemRefs: {
    title: 'statistics_tableTitle_n1',
    table: {
      itemType: { text: 'statistics_tableTitle_topItemType', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '35%' }
    },
    css: 'TablesBorder-Left TablesBorder-Bottom'
  },
  secondToLastSeasonItemRefs: {
    title: 'statistics_tableTitle_n2',
    table: {
      itemType: { text: 'statistics_tableTitle_topItemType', format: 'text' },
      volume: { text: 'statistics_tableTitle_volume', format: 'text', columnSize: '35%' }
    },
    css: 'TablesBorder-Left TablesBorder-Bottom'
  },
  deliveryModeUsage: {
    table: {
      deliveryMode: { text: 'statistics_tableTitle_deliveryModeUsage', format: 'text' },
      currentSeasonPercent: { text: 'statistics_tableTitle_currentSeason', format: 'text' },
      lastSeasonPercent: { text: 'statistics_tableTitle_n1', format: 'text' },
      secondToLastSeasonPercent: { text: 'statistics_tableTitle_n2', format: 'text' }
    }
  }
}

const StatsTable = (props) => {
  const { data, trl, format, formatTable } = props
  let tableColor = props.css && '#a9a9a9'
  let tableBorder = props.css && '1px solid black'
  let tableBorderBottom = props.css && '1px solid black'
  let tableBorderLeft = props.css && '1px solid black'
  let tableBorderRight = props.css && '1px solid black'
  let fontColor = 'black'

  ///////////////////////////////// RENDER ///////////////////////////////////////

  return (
    <div className="StatsTable ">
      {map(data, (table, key) => {
        const tableProps = tables[key] || {}
        if (!Array.isArray(table) && typeof table !== 'object') return null
        let css = 'TablesSubTitle ' + tableProps.css
        return (
          <div className={`TableWrapperSize ${props.css && 'AnalysisTitleColored '}`} key={key}>
            {tableProps.title && (
              <div className={css}>
                <p>{trl[tableProps.title]}</p>
              </div>
            )}
            <Table className={!isUndefined(formatTable) && formatTable}>
              <TableHeader
                style={{ borderBottom: '0px', border: tableBorder, backgroundColor: tableColor }}
                displaySelectAll={false}
                enableSelectAll={false}
                adjustForCheckbox={false}
              >
                <TableRow style={{ borderBottom: '0px' }}>
                  {map(tableProps.table, (header, index) => {
                    let titleFormat = 'AnalysisTitle '
                    let columnSize = ''
                    columnSize += !isUndefined(header['columnSize']) && header['columnSize']
                    titleFormat += format && format[key].title[index]
                    return (
                      <TableHeaderColumn
                        className={format && format[key].tableHeader[index]}
                        style={{ textAlign: 'center', padding: '24px 1%', width: columnSize }}
                        key={index}
                      >
                        <p className={titleFormat} style={{ color: fontColor }}>
                          {trl[header.text]}
                        </p>
                      </TableHeaderColumn>
                    )
                  })}
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={false}
                style={{ borderBottom: tableBorderBottom, borderLeft: tableBorderLeft, borderRight: tableBorderRight }}
              >
                {map(table, (row, idx) => (
                  <TableRow style={{ borderBottom: '0', height: 30 }} key={idx}>
                    {map(tableProps.table, (header, index) => {
                      let columnSize = ''
                      columnSize += !isUndefined(header['columnSize']) && header['columnSize']
                      return (
                        <TableRowColumn
                          style={{ textAlign: 'center', height: 'auto !important', width: columnSize }}
                          className={format && format[key].tableBody[index]}
                          key={index}
                        >
                          {header.format === 'text' && <span>{trl[row[index]] ? trl[row[index]] : row[index]}</span>}
                        </TableRowColumn>
                      )
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )
      })}
    </div>
  ) // end return
} // end function

StatsTable.propTypes = {
  data: PropTypes.array.isRequired
}

export default StatsTable
