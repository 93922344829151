////////LIBRARY/////////
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
///////COMPONENTS///////
import { rootReducer } from '../reducers'

const initialState = {}

export default function configureStore() {
  let store

  if (module.hot) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(thunkMiddleware, logger),
        //applyMiddleware(thunkMiddleware),
        window.devToolsExtension ? window.devToolsExtension() : (f) => f
      )
    )
  } else
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(thunkMiddleware), (f) => f)
    )
  return store
}
