////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import StaticVariable from '../ColumnHelpers/StaticVariable'
import Round from '../ColumnHelpers/Round'
import PriceWithCurrency from '../ColumnHelpers/PriceWithCurrency'
import OrderDetailFollowUpActions from '../Actions/OrderDetailFollowUpActions'
import ShowImageAction from '../ColumnHelpers/ShowImage'
import { TableContainer } from '../../styledComponents'
import { translate } from '../../../containers/HOC/Translation'
import NoDataComponent from '../NoDataComponent'

const columns = [
  { key: 'iD_Commande', header: translate('orderDetail_TableTitle_Image'), Component: ShowImageAction },
  { key: 'iD_Commande_Article', hidden: true, primaryKey: true },
  { key: 'iD_Article_Externe_Enseigne', header: translate('orderDetail_TableTitle_BrandItem') },
  { key: 'iD_Article_Externe_SAP', header: translate('orderDetail_TableTitle_CTCItem') },
  { key: 'article_Name', header: translate('orderDetail_TableTitle_Designation') },
  { key: 'article_Fixe', header: translate('orderDetail_TableTitle_ItemType'), Component: StaticVariable },
  { key: 'qte_Totale', header: translate('orderDetail_TableTitle_Quantity') },
  { key: 'price', header: translate('orderDetail_TableTitle_Price'), Component: PriceWithCurrency },
  { key: 'total_Price', header: translate('orderDetail_TableTitle_TotalPrice'), Component: Round },
  { key: 'actions', header: translate('orderDetail_TableTitle_Action'), Component: OrderDetailFollowUpActions }
]

const config = {
  showPageSize: false,
  showFilter: false,
  defaultPageSize: 10000
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

///////////////////////////////// RENDER ///////////////////////////////////////

const OrderConfirmTable = props => (
  <TableContainer className="order-confirm-table">
    <Table className="table table-borderBottom" {...props} columns={columns} NoDataComponent={NoDataComponent}/>
  </TableContainer>
)

OrderConfirmTable.propTypes = propTypes
export default sematable('orderConfirmTable', OrderConfirmTable, columns, config)
