////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, initialize } from 'redux-form'
import styled from 'styled-components'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'
import { renderInput } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import { P1, theme } from '../../../components/styledComponents'

/////////STYLED/////////
const OKbutton = styled.button`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transform: translateY(-1px);
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  transform: translateY(16px);
  &:hover {
    transition: 0.2s;
    color: white;
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }
  margin-left: 7px;
  @media (max-width: 1500px) {
    transform: translateY(-10px);
  }
`
const Form = styled.form`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
const QuantityWrapper = styled.div`
  display: flex;
`
/////////STYLED/////////

const validate = (values, props) => {
  const { trl, orders, orderId, idCommandeArticle } = props
  const order = orders[orderId].commande_Articles[idCommandeArticle]
  let errors = {}
  if (values.qte && (values.qte / order.article_Conditionnement) % 1 !== 0) {
    errors.qte = `${trl.common__article_qty_multiple_error} ${order.article_Conditionnement}`
  }
  if (values.qte && values.qte < order.article_Order_Min) {
    errors.qte = `${trl.common__article_qty_minimum_error} ${order.article_Order_Min}`
  }
  return errors
}

class ArticleFixeForm extends Component {

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    const { orderId, idCommandeArticle, orders, dispatch } = this.props
    dispatch(initialize('ArticleFixeForm', orders[orderId].commande_Articles[idCommandeArticle]))
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit = formValues => {
    const { orderId, idCommandeArticle } = this.props
    const userModel = {
      ID_Commande_Article: idCommandeArticle,
      Qte: formValues.qte,
      Order_Article_Validated: true
    }
    return this.props.apiUpdateOrderArticleStatic(orderId, idCommandeArticle, userModel)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, handleSubmit, submitting, isDisabled } = this.props
    return (
      <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <P1 style={{ fontWeight: 'bold' }}>{trl.order_EditFixItem_Text_EnterQuantity} </P1>
        <QuantityWrapper>
          <Field
            name="qte"
            type="number"
            component={renderInput}
            fullWidth={false}
            disabled={isDisabled}
            label={trl.common__article_qty || ' '}
          />
          <div style={{ top: '3rem', position: 'relative' }}>
            {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
            {!submitting && (
              <OKbutton type="submit" disabled={submitting || isDisabled}>
                {trl.common__ok}
              </OKbutton>
            )}
          </div>
          <div />
        </QuantityWrapper>
      </Form>
    )
  }
}

ArticleFixeForm.propTypes = {
  trl: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  idCommandeArticle: PropTypes.string.isRequired,
  orders: PropTypes.object.isRequired,
  apiUpdateOrderArticleStatic: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

const mapStateToProps = ({ orders }) => ({ orders })

ArticleFixeForm = reduxForm({
  form: 'ArticleFixeForm',
  validate,
  touchOnBlur: false,
  errors: {}
})(ArticleFixeForm)

ArticleFixeForm = connect(mapStateToProps)(ArticleFixeForm)

export default ArticleFixeForm
