////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
///////COMPONENTS///////
import { PageHeader } from '../../components/sharedComponents'
import { PageGutter } from '../../components/styledComponents'
import { apiGetOrderArticle, apiUpdateOrderDeliveryDetails, apiUpdateOrderStatusCancel, apiOrderSapSimulate } from '../../actions/orders'
import {  apiGetCarrierData } from '../../actions/delivery'
import { apiGetCompanies, apiCreateCarrier } from '../../actions/companies'
import OrderDeliveryDetailsForm from '../Forms/OrderDeliveryDetailsForms/OrderDeliveryDetailsForm'
import DeliveryMethodForm from '../Forms/OrderDeliveryDetailsForms/DeliveryMethodForm'
import * as c from '../../constants'
import { hasOwnerShipIfFac } from '../../utils/apiUtils'
import OrderDetailInformation from '../OrderDetail/OrderDetailInformation'

class OrderDeliveryDetails extends Component {
  constructor(props) {
    super(props)
    this.getOrder = this.getOrder.bind(this)
    this.getCarrier = this.getCarrier.bind(this)
    this.state = {
      showDeliveryMethodForm: false,
      showCancelOrderConfirmation: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    console.log('UN')
    this.getOrder()
    this.getCarrier()
  }
  componentWillUnmount() {
    
  }

  componentWillReceiveProps(nextProps) {
    const apiRoute = c.apiCarrier
    const { orders } = nextProps
    if (!_.isEmpty(orders) ) {
   
      if (
        orders.feedBack &&
        orders.feedBack.success &&
        this.props.orders.feedBack.success !== orders.feedBack.success
      ) {
        console.log('DEUX')
        this.setState({ showDeliveryMethodForm: false })
        this.getOrder()
        //this.getCarrier()
      }
      if( !_.isEmpty(this.props.companies) ) {
        console.log('ROIS')
        if( !_.isEqual(nextProps.companies, this.props.companies) ){
          this.setState({ showDeliveryMethodForm: false })
          this.getOrder()
          //this.getCarrier()
        }
      }
    }    
    // if awaiting response, and then next is lastUpdated not null and didInvalidated false, that means call successful and we can hide form
    if (
      this.props.apiCallStateManager[apiRoute] &&
      this.props.apiCallStateManager[apiRoute].awaitingResponse === true &&
      nextProps.apiCallStateManager[apiRoute].awaitingResponse === false &&
      nextProps.apiCallStateManager[apiRoute].didInvalidate === false &&
      nextProps.apiCallStateManager[apiRoute].lastUpdated
    ) {
      console.log('QUATRE')
      this.setState({ showDeliveryMethodForm: false })
    }
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  getOrder() {
    this.props.apiGetOrderArticle(this.props.routeParams.orderId)
  }

  getCarrier() {
    console.log('PASSAGE')
    this.props.apiGetCarrierData()
  }

  handleDeliveryModeDisplay = () => this.setState({ showDeliveryMethodForm: true })

  handleCancelOrder = () => {
    this.props.apiUpdateOrderStatusCancel(this.props.routeParams.orderId)
    this.toggleCancelOrder()
  }

  toggleCancelOrder = () => this.setState({ showCancelOrderConfirmation: !this.state.showCancelOrderConfirmation })

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, orders, routeParams, myRole, isLoading , carrierList} = this.props
    const order = orders[routeParams.orderId]
    const allowOwnCarriers = _.get(order, 'available_DeliveryModes.allowOwnCarriers', false)
   
    if (_.isEmpty(orders) || !order || isLoading) {
      return null
    }
    const ownerShip = hasOwnerShipIfFac(order)
    return (
      <div style={{}}>
      { order && (
        <span>
        <PageHeader
          title={`PO ${order.iD_Commande} - ${trl.order_delivery__title}`}
          base64Logo={order ? order.brandLogo : null}
          subtitle={trl.order_delivery__subtitle}
        />
        <PageGutter>
          <OrderDetailInformation trl={trl} order={order} myRole={myRole} handleCancelOrder={this.handleCancelOrder} />
      
          <OrderDeliveryDetailsForm
            trl={trl}
            isDisabled={order.status.idStatus > 8 || !ownerShip}
            handleDeliveryModeDisplay={this.handleDeliveryModeDisplay}
            order={order}
            apiUpdateOrderDeliveryDetails={this.props.apiUpdateOrderDeliveryDetails}
            showDeliveryMethodForm={this.state.showDeliveryMethodForm}
            orderId={this.props.routeParams.orderId}
          />
          {/* Delivery Form Method will be shown here */}
          <div style={{ position: 'relative' , backgroundColor:'red'}}>
            {allowOwnCarriers && this.state.showDeliveryMethodForm && (
              <DeliveryMethodForm trl={trl} idFaconnier={order.iD_Faconnier} carrierList={carrierList}/>
            )}
          </div>
        </PageGutter>
        </span>)}
      </div>
    )
  }
}
const mapStateToProps = ({ orders, companies, apiCallStateManager, auth, delivery }) => ({
  orders,
  companies,
  apiCallStateManager,
  myRole: auth.user_role,
  carrierList: delivery.carrierList,
})

OrderDeliveryDetails.propTypes = {
  trl: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  apiCallStateManager: PropTypes.object.isRequired,
  apiCreateCarrier: PropTypes.func.isRequired,
  apiGetCompanies: PropTypes.func.isRequired,
  apiGetOrderArticle: PropTypes.func.isRequired,
  apiUpdateOrderDeliveryDetails: PropTypes.func.isRequired,
  apiUpdateOrderStatusCancel: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  {
    apiCreateCarrier,
    apiGetOrderArticle,
    apiUpdateOrderDeliveryDetails,
    apiGetCompanies,
    apiUpdateOrderStatusCancel,
    apiOrderSapSimulate,
    apiGetCarrierData
  }
)(OrderDeliveryDetails)
