////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
///////COMPONENTS///////
import FlatButton from 'material-ui/FlatButton'
import Flag from 'material-ui/svg-icons/content/flag'
import LanguagesTable from '../../components/Sematable/Tables/LanguagesTable'
import { fetchLanguages } from '../../actions/languages'
import PageLoad from '../../components/PageLoad'
import * as c from '../../constants'
import { checkRoleHasRightsToFunction } from '../misc/allowedRolesPerFunction'
import * as SC from '../../components/styledComponents'
import { PageHeader } from '../../components/sharedComponents'

class LanguageList extends Component {

  //////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.fetchLanguages()
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  displayAddLanguage() {
    const { me, trl } = this.props
    if (me.role && checkRoleHasRightsToFunction(me.role[0], c.addLanguage)) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', textDecoration: 'none', marginBottom: 50 }}>
          <Link to={c.languageCreate}>
            <FlatButton
              label={trl.admin_languages__add_new_language_btn}
              labelPosition="before"
              primary={true}
              icon={<Flag />}
            />
          </Link>
        </div>
      )}
    return null
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { languages, trl } = this.props
    if (!languages.length || !languages) {
      return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    }
    return (
      <div>
        <PageHeader title={trl.admin_languages__title} subtitle={trl.admin_languages__subtitle} />

        <SC.PageGutter>
          <h2 className={'h2--centered'}>{ }</h2>
          {this.displayAddLanguage()}
          <LanguagesTable data={languages} trl={trl} />
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ languages, auth, me }) {
  return {
    languages: languages.languages,
    role: auth.user_role,
    me
  }
}

LanguageList.propTypes = {
  languages: PropTypes.array.isRequired,
  auth: PropTypes.object,
  trl: PropTypes.object.isRequired,
  fetchLanguages: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchLanguages })(LanguageList)

