import { theme } from '../../components/styledComponents'

export default {
  blackButton: {
    color: theme.colors.white,
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: 66,
    paddingRight: 66,
    lineHeight: '40px'
  },
  greyButton: {
    color: theme.colors.black,
    fontSize: 20,
    fontWeight: 'bold',
    paddingLeft: 66,
    paddingRight: 66,
    lineHeight: '40px'
  },
  datePicker: {
    padding: '5px 10px',
    bottom: 0,
    height: '85%',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    fontSize: "12px",
  },
  hintStyle: {
    padding: '5px 10px',
    top: '6px',
    fontSize: "12px",
  },
  searchInput: {
    width: 120,
    border: '1px solid #ced4da',
    borderRadius: '4px',
    height: '39px',
    paddingLeft: '10px',
    fontSize: "12px",
  },
  labelSearch: { padding: '0px 30px 10px 10px', transform: 'translateY(6px)' },
  select: {
    borderRadius: '4px',
    border: '1px solid #ced4da',
    padding: '10px 12px',
    width: '100%',
    height: '56%',
    position: 'relative',
    top: '4px',
    lineHeight: 'inherit',
    fontSize: "12px",
  },
  h2: {
    textAlign: 'center',
    marginBottom: -39,
    color: '#ee5651',
    textTransform: 'uppercase',
    fontSize: 19
  },
  input: {
    border: '1px solid rgb(206, 212, 218)',
    borderRadius: 4,
    fontSize: "12px",
  }
}
