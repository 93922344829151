////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
///////COMPONENTS///////
import { PageHeader } from '../../components/sharedComponents'
import ShipmentHistoryTable from '../../components/Sematable/Tables/ShipmentHistoryTable'
import * as SC from '../../components/styledComponents'
import { theme } from '../../components/styledComponents'
import { apiShippementHistory } from '../../actions/shippementHistory'

/////////STYLED/////////
const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin-bottom: 60px;
  background-color: ${ theme.colors.lightGrey };
  padding: 30px;
  width: max-content;
`
const ColumnContent = styled.div`
  display: flex;
  align-items: center;
`
const ColumnText = styled.span`
  font-size: 13px !important;
  line-height: 20px !important;
  text-transform: ${ props => props.upper ? 'uppercase' : 'inherit' };
  margin: 0;
`
/////////STYLED/////////

class ShipmentHistory extends Component {
  constructor( props ) {
    super( props )
    this.state = {
      myAddresses: []
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillMount() {
    const {
      routeParams: { orderId }
    } = this.props
    this.props.apiShippementHistory( { orderId } )
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading, shippementHistory } = this.props
    const { iD_JPIOrder, iD_BrandOrder, iD_PurchaserOrder, purchaserName, deliveries = [] } = shippementHistory
    if ( isLoading ) return null
    return (
      <div>
        <PageHeader title={ trl.shipHistory_Title }/>
        <SC.PageGutter>
          <TableWrapper>
            <ColumnContent >
              <ColumnText upper>
                { trl.shipHistory_JPIPO }
              </ColumnText>
            </ColumnContent>
            <ColumnContent>
              <ColumnText>
                { iD_JPIOrder }
              </ColumnText>
            </ColumnContent>
            <ColumnContent >
              <ColumnText upper>
                { trl.shipHistory_brandPO }
              </ColumnText>
            </ColumnContent>
            <ColumnContent>
              <ColumnText>
                { iD_BrandOrder }
              </ColumnText>
            </ColumnContent>
            <ColumnContent >
              <ColumnText upper>
                { trl.shipHistory_gmPO }
              </ColumnText>
            </ColumnContent>
            <ColumnContent>
              <ColumnText>
                { iD_PurchaserOrder }
              </ColumnText>
            </ColumnContent>
            <ColumnContent >
              <ColumnText upper>
                { trl.shipHistory_gmName }
              </ColumnText>
            </ColumnContent>
            <ColumnContent>
              <ColumnText>
                { purchaserName }
              </ColumnText>
            </ColumnContent>
          </TableWrapper>
        </SC.PageGutter>
        <SC.PageGutter>
          <div>
            { deliveries.map( deliverie => (
              <div key={ deliverie.iD_PackingList }>
                <div id="deliveryFollowUpTable">
                  <ShipmentHistoryTable
                    data={ deliverie }
                    handleOpenEdit={ this.handleEditModal }
                    className="OrdersTable"
                    trl={ trl }
                  />
                </div>
              </div>
            ) ) }
          </div>
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps( { auth, shippementHistory } ) {
  return {
    role: auth.user_role,
    shippementHistory: shippementHistory.item
  }
}

ShipmentHistory.propTypes = {
  role: PropTypes.string,
  trl: PropTypes.object.isRequired,
  shippementHistory: PropTypes.object
}
ShipmentHistory.defaultProps = {
  shippementHistory: {}
}

export default connect(
  mapStateToProps,
  { apiShippementHistory }
)( ShipmentHistory )
