////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
import { connect } from 'react-redux'
///////COMPONENTS///////
import { TableContainer } from '../../styledComponents'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'
import ShowImage from '../ColumnHelpers/ShowImage'
import ViewStockActionsProductCatalog from '../Actions/ViewStockActionsProductCatalog'
import PageLoadTable from '../../PageLoadTable'

const columns = [
  { key: 'idItem', primaryKey: true, hidden: true },
  { key: 'idItemExternSAP', header: translate('productCatalogue_tableTitle_CTCItem'), sortable: true },
  { key: 'itemType', header: translate('productCatalogue_tableTitle_itemType'), sortable: true },
  { key: 'designation', header: translate('productCatalogue_tableTitle_itemDesignation'), sortable: true },
  { key: 'itemImageName', header: translate('productCatalogue_tableTitle_itemVisual'), Component: props => <ShowImage {...props} imageKey="itemImageName" /> , sortable: true },
  { key: 'packing', header: translate('productCatalogue_tableTitle_itemPacking'), sortable: true },
  { key: 'moq', header: translate('productCatalogue_tableTitle_itemMOQ'), sortable: true }
]

const config = {
  defaultPageSize: 50,
  showPageSize: true,
  showFilter: false
}

const propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired
}

class ProductCatalogTable extends Component {

	///////////////////////////////// HANDLE ///////////////////////////////////////

  addViewStockHandler = (columns) => {
    const { myRole, myCompanyType } = this.props
    // for role Eadmin and Eac switch buyer profil
    // exclude FAC and FACA
    if ( (myRole === 'FACA' || myRole === 'FAC') && myCompanyType !== 'BR' ) return
    else { columns.push({ key: 'actions', header: translate('productCatalogue_tableTitle_viewStocks'), Component: ViewStockActionsProductCatalog })
    }
  }

  Loader = () => (<PageLoadTable text={this.props.trl.common__loading} size={80} thickness={9} />)

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { data, isLoading } = this.props
    const prices =
      data &&
      data.reduce((acc, item) => {
        item.prices.map(price => {
          item[price.zoneGeo] = price.price
          acc[price.zoneGeo] = price.incoterms
        })
        return acc
      }, {})

    const computedColumns = [
      ...columns,
      ...Object.keys(prices).map(key => ({
        key,
        header: (
          <div>
            {key}
            <br />
            {prices[key]}
          </div>
        ),
      }))
    ]

    const computedData = data.map(item => {
      Object.keys(prices).forEach(key => {
        if (!item[key]) return item[key] = '-' 
      })
      return item
    })

    this.addViewStockHandler(computedColumns)
    
    return (
      <TableContainer style={{ border: '1px solid black' }}>
        <Table {...this.props} data={isLoading ? [] : computedData} columns={computedColumns} NoDataComponent={isLoading ? this.Loader : NoDataComponent} />
      </TableContainer>
    )
  }
}

ProductCatalogTable.propTypes = propTypes

const mapStateToProps = ({ me, auth, }) => ({
  myRole: auth.user_role,
  myCompanyType: me.company_type,
})

export default
  connect(
    mapStateToProps, null
  )
    (sematable('productCatalogTable', ProductCatalogTable, columns, config))