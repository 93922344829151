import * as c from '../constants'

const initialState = {
  selectedBrandId: null,
  availableByParameters: null,
  inputBy: null,
  inputSearch: null,
  lastUpdateTime: null,
  stocksToReturn: null,
  availableBrands: null,
  xlsx: null,
  isLoading: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API_GET_STOCK_FOLLOW_UP:
      action.payload.filtersSettings.availableByParameters.splice(0, 0, 'common__all')
      action.payload.filtersSettings.availableBrands.splice(0, 0, { key: 0, value: 'common__pleaseSelect' })
      return {
        ...state,
        availableBrands: action.payload.filtersSettings.availableBrands,
        selectedBrandId: action.payload.filtersSettings.selectedBrandId,
        availableByParameters: action.payload.filtersSettings.availableByParameters,
        inputBy: action.payload.filtersSettings.inputBy,
        inputSearch: action.payload.filtersSettings.inputSearch,
        lastUpdateTime: action.payload.lastUpdateTime,
        stocksToReturn: action.payload.stocksToReturn
      }
    case c.API_GET_STOCK_FOLLOW_UP_ID:
      return {
        ...state,
        lastUpdateTime: action.payload.lastUpdateTime,
        stocksToReturn: action.payload.stocksToReturn,
        isLoading: false
      }
    case c.API_GET_STOCK_FOLLOW_UP_ID_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case c.API_GET_STOCK_FOLLOW_UP_ID_FAILURE:
      return {
        ...state,
        isLoading: false
      }

    case c.API_EXPORT_XL_STOCK_FOLLOW_UP_REQUEST:
      return {
        ...state,
        xlsx: null
      }
    case c.API_EXPORT_XL_STOCK_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        xlsx: action.payload
      }
    default:
  }
  return state
}
