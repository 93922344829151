////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import * as c from '../../../constants'

class DateFormatterCtc extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {  row } = this.props;
    const date2000 = '2000-01-01T00:00:01.087';
    return (
      row && new Date(row.ctC_Date_Confirmed) > new Date(date2000) && row.iD_Status_Commande > 8 ?  
      ( <span style={{ minWidth: 63, display: 'block' }}>
          {dateFormat(new Date(row.ctC_Date_Confirmed), c.dateDMY)}
        </span> ) :  null
    )
  }
}

DateFormatterCtc.propTypes = {
  row: PropTypes.object
}

DateFormatterCtc.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default DateFormatterCtc
