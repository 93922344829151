////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sematable, { Table } from 'sematable'
///////COMPONENTS///////
import { TableContainer } from '../../styledComponents'
import CountryLabellistActions from '../Actions/CountryLabellistActions'
import NoDataComponent from '../NoDataComponent'
import { translate } from '../../../containers/HOC/Translation'

const config = {
  defaultPageSize: 5000000000,
  showPageSize: false,
  showFilter: false
}

const Labellist = ({ children }) => <span>{children}</span>

const columns = [
  { key: 'country_alpha_2_code', header: 'ID', hidden: true, primaryKey: true },
  { key: 'companies', header: translate('company_table_tableTitle__companies'), hidden: true },
  { key: 'country_name', header: translate('company_table_tableTitle__name') },
  { key: 'labelliste_name', header: translate('company_table_tableTitle__labelling'), Component: Labellist },
  { key: 'actions', header: translate('company_table_tableTitle__actions'), Component: CountryLabellistActions }
]

class CompaniesTable extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    return (
      <TableContainer>
        <Table {...this.props} columns={columns} NoDataComponent={NoDataComponent} />
      </TableContainer>
    )
  }
}

CompaniesTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  primaryKey: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired
}

export default sematable('CompaniesTable', CompaniesTable, columns, config)
