///////COMPONENTS///////
import * as c from '../constants'
import * as h from '../utils/headers'
import * as u from '../utils/apiUtils'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

// GET PRODUCT FOLLOWUP
function apiGetProductFollowUpRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_GET_PRODUCTION_FOLLOW_UP_REQUEST, endpointPath })
  }
}
function apiGetProductFollowUpSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_PRODUCTION_FOLLOW_UP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_GET_PRODUCTION_FOLLOW_UP,
      payload: payload
    })
  }
}
function apiGetProductFollowUpFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_GET_PRODUCTION_FOLLOW_UP_FAILURE, endpointPath })
  }
}
export function apiGetProductionFollowUp(data) {
  return u.getValueFromStore(
    c.apiGetProductionFollowUp,
    h.headerPutWithToken(data),
    apiGetProductFollowUpRequest,
    apiGetProductFollowUpSuccess,
    apiGetProductFollowUpFailure
  )
}

// PUT PRODUCT FOLLOWUP RESET OP
function apiPutProductionFollowUpResetOPRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_PRODUCTION_FOLLOW_UP_RESET_OP_REQUEST, endpointPath })
  }
}
function apiPutProductionFollowUpResetOPSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PRODUCTION_FOLLOW_UP_RESET_OP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_PRODUCTION_FOLLOW_UP_RESET_OP,
      payload: payload
    })
  }
}
function apiPutProductionFollowUpResetOPFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PRODUCTION_FOLLOW_UP_RESET_OP_FAILURE, endpointPath })
  }
}
export function apiPutProductionFollowUpResetOP(data) {
  return u.getValueFromStore(
    `${c.apiGetProductionFollowUpResetOP}/${data.idOp}/reset`,
    h.headerPutWithToken(data),
    apiPutProductionFollowUpResetOPRequest,
    apiPutProductionFollowUpResetOPSuccess,
    apiPutProductionFollowUpResetOPFailure
  )
}

// PUT PRODUCT FOLLOWUP CLOSE OP
function apiPutProductionFollowUpCloseOPRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_PRODUCTION_FOLLOW_UP_CLOSE_OP_REQUEST, endpointPath })
  }
}
function apiPutProductionFollowUpCloseOPSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PRODUCTION_FOLLOW_UP_CLOSE_OP_SUCCESS, endpointPath })
    dispatch({
      type: c.API_PRODUCTION_FOLLOW_UP_CLOSE_OP,
      payload: payload
    })
  }
}
function apiPutProductionFollowUpCloseOPFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_PRODUCTION_FOLLOW_UP_CLOSE_OP_FAILURE, endpointPath })
  }
}
export function apiPutProductionFollowUpCloseOP(data) {
  return u.getValueFromStore(
    `${c.apiGetProductionFollowUpCloseOP}/${data.idOp}/close`,
    h.headerPutWithToken(data),
    apiPutProductionFollowUpCloseOPRequest,
    apiPutProductionFollowUpCloseOPSuccess,
    apiPutProductionFollowUpCloseOPFailure
  )
}

// EXPORT PRODUCT FOLLOWUP
function apiProductionFollowUpExportRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_PRODUCTION_FOLLOW_UP_REQUEST, endpointPath })
    dispatch(addAppShowLoading(true))
  }
}
function apiProductionFollowUpExportSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_PRODUCTION_FOLLOW_UP_SUCCESS, payload: res })
    dispatch(addAppHideLoading(true))
  }
}
function apiProductionFollowUpExportFailure(endpointPath, err) {
  return (dispatch) => {
    dispatch({ type: c.API_EXPORT_XL_PRODUCTION_FOLLOW_UP_FAILURE, endpointPath })
    dispatch(addAppHideLoading(true))
  }
}
export function apiProductionFollowUpExport(params) {
  return u.getValueFromStore(
    `${c.apiGetProductionFollowUpExport}`,
    h.headerPutWithToken(params),
    apiProductionFollowUpExportRequest,
    apiProductionFollowUpExportSuccess,
    apiProductionFollowUpExportFailure,
    true
  )
}
