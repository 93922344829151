///////COMPONENTS///////
import { grey500, white } from 'material-ui/styles/colors'

export const styles = {
  question: {
    color: '#555',
    fontSize: 24,
    fontWeight: 300,
    marginTop: 0,
    marginBottom: 20,
    textAlign: 'center'
  },
  description: {
    color: '#555',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: 354
  },
  instruction: {
    fontSize: 14
  },
  topIcon: {
    fill: '#BDBDBD',
    marginBottom: 10,
    height: 96,
    width: 96,
    backgroundColor: '#E1E1E1',
    borderRadius: '50%',
    display: 'block',
    margin: '30 auto 20'
  },
  icon: {
    success: {
      fill: '#009688'
    },
    fail: {
      fill: '#e14441'
    }
  },
  successMessage: {
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      width: 80,
      height: 80,
      fill: '#009688'
    },
    message: {
      paddingLeft: 12,
      fontSize: 15,
      marginBottom: 0
    }
  },
  title: {
    textAlign: 'center',
    clear: 'both'
  },
  pageBackground: {
    backgroundColor: '#efefef',
    minHeight: '100vh',
    minWidth: '100vw'
  },
  loginContainer: {
    // minWidth: 320,
    // maxWidth: 400,
    // height: 'auto',
    // position: 'absolute',
    // top: '20%',
    // left: 0,
    // right: 0,
    // margin: 'auto'
    minWidth: 320,
    maxWidth: 400,
    margin: 'auto',
    padding: '100px 0 50px'
  },
  paper: {
    padding: 40,
    overflow: 'auto'
  },
  buttonsDiv: {
    textAlign: 'center',
    padding: 10
  },
  flatButton: {
    color: grey500,
  },
  checkRemember: {
    style: {
      float: 'left',
      maxWidth: 180,
      paddingTop: 5,
      fontSize: 12,
    },
    labelStyle: {
      color: grey500
    },
    iconStyle: {
      color: grey500,
      borderColor: grey500,
      fill: grey500
    }
  },
  loginBtn: {
    float: 'right',
    margin: '20px 0 60px'
  },
  loginBtnSuccess: {
    color: white,
    backgroundColor: '#a4c639'
  },
  btn: {
    background: '#4f81e9',
    color: white,
    padding: 7,
    borderRadius: 2,
    margin: 2,
    fontSize: 13
  },
  btnFacebook: {
    background: '#4f81e9'
  },
  btnGoogle: {
    background: '#e14441'
  },
  btnSpan: {
    marginLeft: 5
  },
  loginError: {
    color: 'rgb(244, 67, 54)',
    float: 'left',
    width: '100%',
    textAlign: 'left',
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '7px'
  },
  circularProgress: {
    float: 'left'
  },
  select: {
    width: '100%'
  },
  labelWrapperToggle: { maxWidth: 350, display: 'block', marginBottom: '10px', clear: 'both', position: 'relative' },
  labelWrapper: { maxWidth: 480, display: 'block', marginBottom: '10px', overflow: 'hidden', clear: 'both' },
  delivreyLabel: {
    float: 'left',
    position: 'relative',
    display: 'block',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    width: '305px',

  },
  deliveryValue: {
    marginLeft: '8px',
    padding: '4px 0px 6px 2px',
    float: 'left',
  },
  P2: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  boxWithBorder: {
    maxWidth: '500px',
    //border:' 1px solid black',
    padding: '14px',
  }
}
