/**
 * Returns the company permission's value
 *
 * @param {array} company_permissions
 * @param {string} enum_name
 * @returns {boolean}
 */
const getPermissionValue = (company, enum_name) => {
  const perm = company.permissions.filter((c) => c.enum_name === enum_name)[0]
  if (perm) {
    return perm.value
  }

  return false
}

export default getPermissionValue
