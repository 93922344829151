////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dateFormat from 'dateformat'
import _ from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import { checkRoleHasRightsToFunction } from '../misc/allowedRolesPerFunction'
import { apiUpdateOrderPoFac, apiUpdateWishedExpeditionDate } from '../../actions/orders'
import WishedExpeditionDate from '../Forms/WishedExpeditionDate'
import IdExtFaconnier from '../Forms/IdExtFaconnier'
import * as c from '../../constants'
import * as SC from '../../components/styledComponents'

/////////STYLED/////////
const ProcessOrderColumnStyled = styled.div`
  ${props =>
    props.showOnlyProcess &&
    `
    max-width: 50% !important;
    @media (max-width: 1000px){
      max-width: initial !important;
    }
  `};
`
/////////STYLED/////////

class DisplayActionsInformation extends Component {

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const {trl,order,myRole,apiUpdateOrderPoFac,apiUpdateWishedExpeditionDate,showOnlyProcess,ownerShip} = this.props
    const formatType = c.dateDMY
    let hasUnqualifiedArticle = null
    let hasArticlesAdded = false
    const hasArticles = order.commande_Articles && _.toArray(order.commande_Articles).length > 0
    if (hasArticles) {
      // id status == 29 -> means articles that are not yet qualified
      if (order.commande_Articles) {
        const orderStatusDetails = _.toArray(order.commande_Articles).filter(commande_Article => {
          return !(commande_Article.status.idStatus < 29 || commande_Article.status.idStatus === 32)
        })
        _.toArray(order.commande_Articles).map(commande_Article => {
          if(commande_Article.status.idStatus == 29) hasArticlesAdded = true
        })
        hasUnqualifiedArticle = orderStatusDetails.length > 0 ? false : true
        //filtering all articles. If they are not all equaled to 29(pre-qualified), then will have length in array and button disabled, so don't have button to go to order delivery details page
      }
    } else {
      hasUnqualifiedArticle = true
    }

    return (
      <div className="row">
        {!showOnlyProcess && (
          <div className="col">
            <p>
              {trl.common__external_id_est}: {order.iD_Commande_Externe_Enseigne}
            </p>
            {checkRoleHasRightsToFunction(myRole, c.funcUpdateId) ? (
              <IdExtFaconnier
                trl={trl}
                orderId={order.iD_Commande}
                order={order}
                apiUpdateOrderPoFac={apiUpdateOrderPoFac}
              />
            ) : (
              <p>
                {trl.common__external_id_fac}: {order.iD_Commande_Externe_Faconnier}
              </p>
            )}
            <p>
              {trl.common__status}: {trl[order.status_Traduction_Key]}
            </p>
          </div>
        )}
        {!showOnlyProcess && (
          <div className="col">
            <p>
              {trl.common__date_created}: {order.date_Created && dateFormat(new Date(order.date_Created), formatType)}
            </p>
            <p>
              {trl.common__date_updated}: {order.date_Updated && dateFormat(new Date(order.date_Updated), formatType)}
            </p>
            {(order.status.idStatus === 3 ||
              order.status.idStatus === 4 ||
              order.status.idStatus === 5 ||
              order.status.idStatus === 7 ||
              order.status.idStatus === 8) &&
            checkRoleHasRightsToFunction(myRole, c.updateWishedExpeditionDate) ? (
              <div>
                <WishedExpeditionDate
                  trl={trl}
                  initialValues={order}
                  orderId={order.iD_Commande.toString()}
                  apiUpdateWishedExpeditionDate={apiUpdateWishedExpeditionDate}
                />
              </div>
            ) : (
              <p>
                {trl.common__date_exp_wished}: {dateFormat(new Date(order.date_Expedition_Souhaitee), formatType)}
              </p>
            )}
          </div>
        )}
        <ProcessOrderColumnStyled className="col" showOnlyProcess={showOnlyProcess}>
          {hasArticles && order.status.idStatus <= 8 || hasArticlesAdded  ? (
            <div>
              <SC.ButtonPA_L
                disabled={hasUnqualifiedArticle || order.article_status_PRE30_PRE50_count > 0 || !order.hasOwnerShip}
                onClick={() => this.context.router.push(`${c.orderDetail}${order.iD_Commande}${c.orderDeliveryDetails}`)}
              >
                {trl.order__continue_btn}
              </SC.ButtonPA_L>
              <p>
                {hasUnqualifiedArticle ? `${trl.order__continue_subtitle}` : `${trl.order__continue_subtitle_valid}`}
              </p>
            </div>
          ) : (order.status.idStatus === 2 || order.status.idStatus === 11) && (
            <p>
              {trl.order__cancelation_date}: {dateFormat(new Date(order.date_Cancelled), formatType)}
            </p>
          )}
          {hasArticles && (order.status.idStatus > 8 && !hasArticlesAdded) && (
            <div>
              <SC.ButtonPA_L
                disabled={order.article_status_PRE30_PRE50_count > 0}
                onClick={() => this.context.router.push(`${c.orderDetail}${order.iD_Commande}${c.orderDeliveryDetails}`)}
              >
                {trl.order__review_btn}
              </SC.ButtonPA_L>
              <p>{trl.order__continue_subtitle_valid}</p>
            </div>
          )}
        </ProcessOrderColumnStyled>
      </div>
    )
  }
}

DisplayActionsInformation.propTypes = {
  trl: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  myRole: PropTypes.string.isRequired,
  apiUpdateOrderPoFac: PropTypes.func.isRequired,
  apiUpdateWishedExpeditionDate: PropTypes.func.isRequired,
  showOnlyProcess: PropTypes.bool
}

DisplayActionsInformation.contextTypes = {
  router: PropTypes.object.isRequired
}

export default connect(
  null,
  { apiUpdateOrderPoFac, apiUpdateWishedExpeditionDate }
)(DisplayActionsInformation)
