////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { cloneDeep, filter, get, orderBy, some, isUndefined } from 'lodash'
///////COMPONENTS///////
import BrandsTable, { searchDomaine, columns } from '../../components/Sematable/Tables/BrandsTable'
import { apiGetCompanies } from '../../actions/companies'
import * as SC from '../../components/styledComponents'
import { PageHeader } from '../../components/sharedComponents'
import { TextField } from 'material-ui'

/////////STYLED/////////
const SearchContainerStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
`
const TextLeftStyled = styled.p`
  padding-right: 15px;
  transform: translateY(6px);
`
const FormsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  & > * {
    flex: 1 1 25%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1460px) {
    & > * {
      flex: 1 1 100%;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
  }
`
const FormWrapped = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media (max-width: 1460px) {
    justify-content: center;
  }
`
/////////STYLED/////////

class BrandList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      companiesState: [],
      searchTerm: ''
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentDidMount() {
    this.props.apiGetCompanies()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.companies.companiesList !== this.props.companiesList) this.setState({ companiesState: nextProps.companies.companiesList })
  }

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  handelSortable = index => {
    if (columns[index]) this.setState({ lastSortBy: this.state.sortBy, sortBy: index }, this.handleFilterTableItems)
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, this.handleFilterTableItems)
  }

  handleFilterTableItems() {
    const { companies } = this.props
    const { searchTerm, sortBy, lastSortBy, sortOrderBy } = this.state
    let companiesCp = cloneDeep(companies.companiesList)
    let companiesCpCache = cloneDeep(companiesCp)
    companiesCpCache.map(elem => {
      elem.name = elem.name.toLowerCase()
      elem.name_2 = elem.name_2.toLowerCase()/*  + elem.name_2.slice(1) */
      elem.name = elem.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      elem.name_2 = elem.name_2.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    })
    const term = searchTerm.toString().toLowerCase().normalize()
    // filter by search term
    let newSortByOrder = ['asc']
    if (term) {
      companiesCpCache = filter(companiesCpCache, o => {
        return some(searchDomaine, sc => {
          const getSearchTermDomain = get(o, sc.key, false)
          const searchableItem = getSearchTermDomain
          return searchableItem
            ? searchableItem
              .toString()
              .toLowerCase()
              .normalize()
              .includes(term)
            : false
        })
      })
    }
    if (!isUndefined(sortBy) > 0) {
      if (sortBy === lastSortBy) newSortByOrder = sortOrderBy[0] === 'asc' ? ['desc'] : ['asc']
    }
    if (!isUndefined(sortBy)) companiesCpCache = orderBy(companiesCpCache, columns[sortBy].key, newSortByOrder)
    companiesCp.map(elemCp => {
      companiesCpCache.map(elemCpCache => {
          if(elemCp.iD_Societe == elemCpCache.iD_Societe){
            elemCpCache.name = elemCp.name
            elemCpCache.name_2 = elemCp.name_2 
          }
      })})
    this.setState({ companiesState: companiesCpCache, sortOrderBy: newSortByOrder })
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoadingGlobal } = this.props
    const { companiesState } = this.state

    if (isLoadingGlobal) {
      return null
    }
    return (
      <div>
        <PageHeader title={trl.admin_brands__title} subtitle={trl.admin_brands__subtitle} />
        <SC.PageGutter>
          <SC.Sky grey>
            <SC.PageGutter>
              {/* ADVANCED SEARCH AND FILTER */}
              {/* 5-1,2 SEARCH & DOMAIN CHOICE */}
              <SearchContainerStyled>
                <FormsWrapper>
                  <FormWrapped onKeyDown={(e) => { if (e.key === "Enter" || e.keyCode === 13) { e.preventDefault(); } }}>
                    <TextLeftStyled>{trl.common__search} :</TextLeftStyled>
                    <div>
                      <TextField
                        type="text"
                        style={{ width: 300, fontSize: 12 }}
                        name="searchTerm"
                        value={this.state.searchTerm}
                        onChange={e => this.handleChange(e)}
                      //onBlur={() => this.handleFilterTableItems()}
                      />
                    </div>
                  </FormWrapped>
                </FormsWrapper>
              </SearchContainerStyled>
            </SC.PageGutter>
          </SC.Sky>
        </SC.PageGutter>
        <SC.PageGutter>{companiesState && companiesState.length > 0 &&
          <BrandsTable data={companiesState} trl={trl} handelSortable={this.handelSortable} />}
        </SC.PageGutter>
      </div>
    )
  }
}

function mapStateToProps({ companies, appAlerts }) {
  return {
    companies: companies,
    isLoadingGlobal: appAlerts.loading && appAlerts.loading.isLoading,
  }
}

BrandList.propTypes = {
  companies: PropTypes.object.isRequired,
  trl: PropTypes.object.isRequired,
  apiGetCompanies: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { apiGetCompanies })(BrandList)
