////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
///////COMPONENTS///////
import Snackbar from 'material-ui/Snackbar'
import Dialog from 'material-ui/Dialog'
import * as SC from '../../components/styledComponents'
import { deleteAlert, deleteDialogAlert } from '../../actions/appAlerts'
import PageLoad from '../../components/PageLoad'

export default function (ComposedComponent) {
  class RenderMessages extends Component {
    constructor(props) {
      super(props)
      this.state = {
        snackbarMessage: '',
        dialogMessage: '',
        snackbarOpen: false,
        dialogOpen: false
      }
      this.handleRequestClose = this.handleRequestClose.bind(this)
    }

    ///////////////////////////////// CONFIG ///////////////////////////////////////

    componentDidMount() {
      // show app alerts if any!
      const { snackbarAlert } = this.props
      if (snackbarAlert.length) this.displayMessage(snackbarAlert[0].message)
    }
    componentWillReceiveProps(nextProps) {
      const { snackbarAlert, dialogAlert } = nextProps
      if (snackbarAlert[0] && snackbarAlert[0].updateNow) this.displayMessage(snackbarAlert[0].message)
      if (dialogAlert && dialogAlert.updateNow) this.handleDialogOpen(dialogAlert.message)
    }

   	///////////////////////////////// HANDLE ///////////////////////////////////////
 
    displayMessage(snackbarMessage) {
      this.setState({ snackbarOpen: true, snackbarMessage })
      this.props.deleteAlert()
    }

    handleRequestClose = () => {
      this.setState({ snackbarOpen: false })
    }

    handleDialogOpen = dialogMessage => {
      this.setState({ dialogOpen: true, dialogMessage })
      this.props.deleteDialogAlert()
    }

    handleDialogClose = () => {
      this.setState({ dialogOpen: false })
    }

    ///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
      const { snackbarMessage, snackbarOpen, dialogMessage, dialogOpen } = this.state
      const { trl, isLoading, overDialog } = this.props
      const actions = [
        <SC.ButtonPA_S onClick={this.handleDialogClose}>
          {trl.common__ok}
        </SC.ButtonPA_S>
      ]
      return (
        <div>
          <Snackbar
            open={snackbarOpen}
            message={snackbarMessage}
            autoHideDuration={6000}
            onRequestClose={this.handleRequestClose}
          />
          <Dialog actions={actions} modal={false} open={dialogOpen} onRequestClose={this.handleDialogClose}>
            {trl[dialogMessage] || dialogMessage}
          </Dialog>
          {isLoading && (
            <div
              style={{
                height: '90vh',
                width: '100vh',
                backgroundColor: 'white',
                zIndex: overDialog ? 999990 : 2101,
              }}
            >
              <PageLoad text={trl.common__loading} size={80} thickness={9} />
            </div>
          )}
          <ComposedComponent {...this.props} />
        </div>
      )
    }
  }

  const mapStateToProps = ({ appAlerts }) => ({
    snackbarAlert: appAlerts.snackbar,
    dialogAlert: appAlerts.dialog,
    isLoading: appAlerts.loading.isLoading,
    overDialog: appAlerts.loading.overDialog
  })

  RenderMessages.propTypes = {
    appAlerts: PropTypes.array,
    isLoading: PropTypes.bool,
    overDialog: PropTypes.bool
  }
  RenderMessages.defaultProps = {
    isLoading: false,
    overDialog: false,
    appAlerts: []
  }

  return connect(
    mapStateToProps,
    { deleteAlert, deleteDialogAlert }
  )(RenderMessages)
}
