////////LIBRARY/////////
import { hashHistory } from 'react-router'
///////COMPONENTS///////
import * as c from '../constants'
import * as u from '../utils/apiUtils'
import * as h from '../utils/headers'
import { addAppShowLoading, addAppHideLoading } from './appAlerts'

/// UPDATE VISIBLE SKU
export function updateVisibleSku(skuId) {
  return (dispatch) => {
    dispatch({ type: c.UPDATE_VISIBLE_SKU, payload: skuId })
  }
}

// DELETE SKU
function apiDeleteSkuRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_DELETE_SKU_REQUEST, endpointPath })
  }
}
function apiDeleteSkuSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELETE_SKU_SUCCESS, endpointPath })
    dispatch({ type: c.API_DELETE_SKU, payload })
  }
}
function apiDeleteSkuFailure(endpointPath) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_DELETE_SKU_FAILURE, endpointPath })
  }
}
export function apiDeleteSku(orderId, articleId, idsku) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiSku}${idsku}`,
    h.headerDeleteWithToken(),
    apiDeleteSkuRequest,
    apiDeleteSkuSuccess,
    apiDeleteSkuFailure,
    true
  )
}

// EDIT SKU
function apiEditSkuRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_EDIT_SKU_REQUEST, endpointPath })
  }
}
function apiEditSkuSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_EDIT_SKU_SUCCESS, endpointPath })
    dispatch({ type: c.API_EDIT_SKU, payload })
  }
}
function apiEditSkuFailure(endpointPath, error) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_EDIT_SKU_FAILURE, endpointPath })
    dispatch({ type: c.API_EDIT_SKU, error })
  }
}
export function apiEditSku(orderId, articleId, idsku, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiSku}${idsku}`,
    h.headerPutWithTokenAndModel(m),
    apiEditSkuRequest,
    apiEditSkuSuccess,
    apiEditSkuFailure,
    true
  )
}

// ADD SKU
function apiAddSkuRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_ADD_SKU_REQUEST, endpointPath })
  }
}
function apiAddSkuSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_ADD_SKU_SUCCESS, endpointPath })
    dispatch({
      type: c.API_ADD_SKU,
      payload: payload
    })
  }
}
function apiAddSkuFailure(endpointPath) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_ADD_SKU_FAILURE, endpointPath })
  }
}
export function apiAddSku(orderId, articleId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiSku}`,
    h.headerPostWithTokenAndModel(m),
    apiAddSkuRequest,
    apiAddSkuSuccess,
    apiAddSkuFailure,
    true
  )
}

// QUALIFY ALL
function apiQualifyAllRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_QUALIFY_ALL_REQUEST, endpointPath })
  }
}
function apiQualifyAllSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_QUALIFY_ALL_SUCCESS, endpointPath, payload: res })
  }
}
function apiQualifyAllFailure(endpointPath) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_QUALIFY_ALL_FAILURE, endpointPath })
  }
}
export function apiQualifyAll(orderId, articleId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiQualifyAll}`,
    h.headerPutWithTokenAndModel(),
    apiQualifyAllRequest,
    apiQualifyAllSuccess,
    apiQualifyAllFailure,
    true
  )
}

// CONFIRM BAT
function apiConfirmbatRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_CONFIRMBAT_REQUEST, endpointPath })
  }
}
function apiConfirmbatSuccess(res) {
  let cdeId = res.split('/')[3]
  hashHistory.push(`${c.orderDetail}${cdeId}`)
  return (dispatch) => {
    dispatch({ type: c.API_CONFIRMBAT_SUCCESS, payload: res })
  }
}
function apiConfirmbatFailure(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_CONFIRMBAT_FAILURE, endpointPath })
  }
}
export function apiConfirmbat(orderId, articleId) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiConfirmbat}`,
    h.headerPutWithTokenAndModel(),
    apiConfirmbatRequest,
    apiConfirmbatSuccess,
    apiConfirmbatFailure,
    true
  )
}

// PUT COMPO
function apiCompoRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_ORDER_ARTICLE_COMPO_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiCompoSuccess(endpointPath, res) {
  return (dispatch) => {
    dispatch({ type: c.API_ORDER_ARTICLE_COMPO_SUCCESS, endpointPath, payload: res })
    dispatch(addAppHideLoading())
  }
}
function apiCompoFailure(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_ORDER_ARTICLE_COMPO_FAILURE, endpointPath })
    dispatch(addAppHideLoading())
  }
}
export function apiCompo(orderId, articleId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiCompo}`,
    h.headerPutWithTokenAndModel(m),
    apiCompoRequest,
    apiCompoSuccess,
    apiCompoFailure,
    true
  )
}

// PUT DVCs
function apiDvcsRequest(endpointPath) {
  return (dispatch) => {
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_DVCS_REQUEST, endpointPath })
    dispatch(addAppShowLoading())
  }
}
function apiDvcsSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_DVCS_SUCCESS, endpointPath })
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_DVCS, payload: payload })
  }
}
function apiDvcsFailure(endpointPath) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_UPDATE_ORDER_ARTICLE_DVCS_FAILURE, endpointPath })
  }
}
export function apiDvcs(orderId, articleId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiDvc}`,
    h.headerPutWithTokenAndModel(m),
    apiDvcsRequest,
    apiDvcsSuccess,
    apiDvcsFailure,
    true
  )
}

// PUT COMMON DATA
function apiDvOnfocusoutRequest(endpointPath) {
  return { type: c.API_UPDATE_DVC_ONFOCUSOUT_REQUEST, endpointPath }
}
function apiDvOnfocusoutSuccess(endpointPath, res) {
  return { type: c.API_UPDATE_DVC_ONFOCUSOUT_SUCCESS, endpointPath, payload: res }
}
function apiDvOnfocusoutFailure(endpointPath) {
  return { type: c.API_UPDATE_DVC_ONFOCUSOUT_FAILURE, endpointPath }
}
export function apiDvOnfocusout(orderId, articleId, m) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${c.apiDvOonfocusout}`,
    h.headerPutWithTokenAndModel(m),
    apiDvOnfocusoutRequest,
    apiDvOnfocusoutSuccess,
    apiDvOnfocusoutFailure,
    true
  )
}

// PUT MODIFY ALL SKUs
function apiModifyAllSkuRequest(endpointPath) {
  return (dispatch) => {
    dispatch(addAppShowLoading())
    dispatch({ type: c.API_MODIFY_ALL_SKU_REQUEST, endpointPath })
  }
}
function apiModifyAllSkuSuccess(endpointPath, payload) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_MODIFY_ALL_SKU_SUCCESS, endpointPath })
    dispatch({ type: c.API_MODIFY_ALL_SKU, payload })
  }
}
function apiModifyAllSkuFailure(endpointPath, error) {
  return (dispatch) => {
    dispatch(addAppHideLoading())
    dispatch({ type: c.API_MODIFY_ALL_SKU_FAILURE, endpointPath, payload: error })
  }
}
export function apiModifyAllSku(orderId, articleId, m, modifyAllSkuType) {
  return u.getValueFromStore(
    `${c.apiOrderDetail}${orderId}${c.apiItem}${articleId}${
      modifyAllSkuType === 'number' ? c.apiModifyAllSkuNumber : c.apiModifyAllSkuPercent
    }`,
    h.headerPutWithTokenAndModel(m),
    apiModifyAllSkuRequest,
    apiModifyAllSkuSuccess,
    apiModifyAllSkuFailure,
    true
  )
}
