////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

class Sku extends Component {

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, modelKey } = this.props
    const labelText = get(row, `listDVSObj[${modelKey}].value`, '')
    return (
      <span>
        {labelText ? labelText : '-'}
      </span>
    )
  }
}

Sku.propTypes = {
  row: PropTypes.object.isRequired
}

Sku.contextTypes = {
  store: React.PropTypes.object.isRequired
}

export default Sku
