////////LIBRARY/////////
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isNull } from 'lodash'
import dateFormat from 'dateformat'
///////COMPONENTS///////
import { H2, theme } from '../../styledComponents'
import './AddItemActions.css'
import Dialog from 'material-ui/Dialog'
import { apiGetStockFollowUpId } from '../../../actions/stockFollowUp'
import StockFollowUpTable from '../Tables/StockFollowUpTable'
import PageLoadTable from '../../PageLoadTable'
/////////ASSETS/////////
import ZoomIn from 'material-ui/svg-icons/action/zoom-in'

/////////STYLED/////////
const TopRightStock = styled.div`
`
const H2Styled = H2.extend`
  color: ${theme.colors.lightGrey};
  background-color: ${theme.colors.darkGrey};
  padding: 14px;
  font-size: 19px;
`
const LegendTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const LegendTableText = styled.span`
  font-weight: bold;
  color: red;
  margin: 0 0 5px 0;
  font-size: 10px !important;
`
const LastUpdateTime = styled.span`
  color: black;
  font-size: 12px;
`
/////////STYLED/////////

class ViewStockActionsProductCatalog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  queryData = () => {
    this.props.apiGetStockFollowUpId(this.props.row.idItem)
  }

	///////////////////////////////// HANDLE ///////////////////////////////////////

  handleOpen = () => {
    this.queryData()
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  addViewStockButtonHandler = () => {
    const { myRole, myCompanyType, row } = this.props
    // for role Eadmin and Eac switch buyer profil
    // exclude FAC and FACA
    return !((myRole === 'FACA' || myRole === 'FAC') &&
      myCompanyType !== 'BR') && row.isFix
  }

	///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { row, trl, stockFollowUp, lastUpdateTime, isLoading } = this.props
    return (
      <div>
        <Dialog
          contentStyle={{
            maxWidth: '100vw',
            width: '80vw',
            marginLeft: '10vw',
            padding: 0
          }}
          style={{ width: '80vw', fontSize: theme.p1.ftz, padding: 0 }}
          bodyStyle={{ padding: '10px' }}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          {
            isLoading
              ? <PageLoadTable text={trl.common__loading} size={80}
                thickness={9} />
              :
              <div>
                <H2Styled>{
                  stockFollowUp &&
                  `${trl.stofu_title} - ${row.idItemExternSAP}`
                }</H2Styled>
                {lastUpdateTime && (
                  <LastUpdateTime>
                    {trl.common__date_updated} {dateFormat(new Date(lastUpdateTime), 'dd/mm/yy - h:MM tt')}
                  </LastUpdateTime>
                )}

                <StockFollowUpTable
                  data={isNull(stockFollowUp) || isLoading ? [] : stockFollowUp}
                  style={{ overflow: 'scroll' }}
                  isLoading={isLoading}
                  trl={trl}
                />
                <LegendTableWrapper
                  style={{
                    fontSize: '10px !important'
                  }}
                >
                  <LegendTableText
                    style={{
                      fontSize: '10px !important'
                    }}
                  >
                    {trl.stofu_tableLegend_preparationTime}
                  </LegendTableText>
                  <LegendTableText
                    style={{
                      fontSize: '10px !important'
                    }}
                  >
                    {trl.stofu_tableLegend_qtyConsigned}
                  </LegendTableText>
                  <LegendTableText
                    style={{
                      fontSize: '10px !important'
                    }}
                  >
                    {trl.stofu_tableLegend_qtyAvailable}
                  </LegendTableText>
                </LegendTableWrapper>
              </div>
          }
        </Dialog>
        {
          this.addViewStockButtonHandler() &&
          <TopRightStock>
            <ZoomIn onClick={this.handleOpen}
              style={{ cursor: 'pointer' }} />
          </TopRightStock>
        }
      </div>
    )
  }
}

ViewStockActionsProductCatalog.propTypes = {
  row: PropTypes.object.isRequired
}

const mapStateToProps = ({
  me,
  auth,
  languages,
  stockFollowUp
}) => ({
  myRole: auth.user_role,
  myCompanyType: me.company_type,
  trl: languages.siteLanguage.keys,
  stockFollowUp: stockFollowUp.stocksToReturn,
  lastUpdateTime: stockFollowUp.lastUpdateTime,
  isLoading: stockFollowUp.isLoading
})

export default connect(
  mapStateToProps,
  { apiGetStockFollowUpId }
)(ViewStockActionsProductCatalog)
