////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
///////COMPONENTS///////
import CircularProgress from 'material-ui/CircularProgress'
import { renderInput } from '../misc/FormHelpers'
import { styles } from '../misc/formStyles'
import { createLanguage } from '../../../actions/languages'
import * as SC from '../../../components/styledComponents'

const validate = (values, props) => {
  const errors = {}
  const { trl } = props
  if (!values.name) errors.name = trl.admin_language_create__defaultname_required_error || ' '
  if (!values.localName) errors.localName = trl.admin_language_create__localname_required_error || ' '
  return errors
}

class LanguageCreate extends Component {

  ///////////////////////////////// HANDLE ///////////////////////////////////////

  onSubmit(f) {
    return this.props.createLanguage({ name: f.name, localName: f.localName })
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { pristine, handleSubmit, submitting, error, submitSucceeded, trl } = this.props
    return (
      <div style={styles.loginContainer}>
        <h2 style={{ ...styles.title }}>{trl.admin_language_create__title}</h2>
        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Field name="name" type="text" component={renderInput} label={trl.common__language_name_default} />
          <Field name="localName" type="text" component={renderInput} label={trl.common__language_name_local} />
          {error && <p style={styles.loginError}>{error}</p>}
          <div>
            {submitting && <CircularProgress style={styles.circularProgress} size={36} thickness={4} />}
            <SC.ButtonPA_L
              style={{
                ...styles.loginBtn,
                'margin': '1rem 0'
              }}
              disabled={submitting || pristine || (submitSucceeded && pristine)}
              type="submit"
            >
              {trl.admin_language_create__create_btn || ' '}
            </SC.ButtonPA_L>
          </div>
        </form>
      </div>
    )
  }
}

LanguageCreate.propTypes = {
  trl: PropTypes.object.isRequired,
  createLanguage: PropTypes.func.isRequired
}

LanguageCreate = reduxForm({
  form: 'UserCreate',
  validate,
  touchOnBlur: false
})(LanguageCreate)

export default connect(null, { createLanguage })(LanguageCreate)
